//Action types constants
export const userActions = {
  LOGIN: 'LOGIN',
  SET_USERNAME: 'SET_USERNAME',
  SET_PASSWORD: 'SET_PASSWORD',
  CHANGING_PASSWORD: 'CHANGING_PASSWORD',
  CHECKING_LOGIN_STATUS: 'CHECKING_LOGIN_STATUS',
  SAVING_USER_INFO: 'SAVING_USER_INFO',
  SAVE_USER_AGENCY_INFO: 'SAVE_USER_AGENCY_INFO',
  CREATING_NEW_USER: 'CREATING_NEW_USER',
  CREATING_AGENCY_USER: 'CREATING_AGENCY_USER',
  UPDATE_USER: 'UPDATE_USER',
  GET_ALL_AGENCIES: 'GET_ALL_AGENCIES',
  SAVE_ALL_AGENCIES: 'SAVE_ALL_AGENCIES',
  CHECKING_CREATE_NEW_USER_STATUS: 'CHECKING_CREATE_NEW_USER_STATUS',
  CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',
  GETTING_ERROR_MESSAGE: 'GETTING_ERROR_MESSAGE',
  CLEAR_SUCCESS_MESSAGE: 'CLEAR_SUCCESS_MESSAGE',
  GETTING_SUCCESS_MESSAGE: 'GETTING_SUCCESS_MESSAGE',
  GETTING_TWO_FACTOR_AUTH_MESSAGE: 'GETTING_TWO_FACTOR_AUTH_MESSAGE',
  LOAD_EMAIL: 'LOAD_EMAIL',
  CHECKING_SUPERUSER: 'CHECKING_SUPERUSER',
  LOGGING_IN_USING_TOKEN: 'LOGGING_IN_USING_TOKEN',
  GET_USER_LIST: 'GET_USER_LIST',
  USER_LIST: 'USER_LIST',
  STORE_COMMITTEES: 'STORE_COMMITTEES',
  STORE_DIVISION: 'STORE_DIVISION',
  LOGGING_OUT: 'LOGGING_OUT',
  EMAIL_PASSWORD_RESET: 'EMAIL_PASSWORD_RESET',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_BY_TOKEN: 'CHANGE_PASSWORD_BY_TOKEN',
  LOADING: 'LOADING',
  SAVE_PASS_DUE_REPORTS_INFO: 'SAVE_PASS_DUE_REPORTS_INFO',
  SAVE_UPCOMING_REPORTS_INFO: 'SAVE_UPCOMING_REPORTS_INFO',
  SAVE_RECENTLY_UPLOADED_REPORTS_INFO: 'SAVE_RECENTLY_UPLOADED_REPORTS_INFO',
  LOAD_MORE_USER_REPORTS: 'LOAD_MORE_USER_REPORTS',
  ADD_MORE_REPORTS: 'ADD_MORE_REPORTS',
  SAVE_NEW_USER_ID: 'SAVE_NEW_USER_ID',
  CHECKING_CHANGE_PASSWORD_STATUS: 'CHECKING_CHANGE_PASSWORD_STATUS',
  SAVE_SELECTED_USER: 'SAVE_SELECTED_USER',
  SET_REPORT_LOADING: 'SET_REPORT_LOADING',
  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  SAVE_NOTIFICATIONS: 'SAVE_NOTIFICATIONS',
  UPDATE_USER_PREFERENCES: 'UPDATE_USER_PREFERENCES',
  SAVE_USER_PREFERENCE_VALUES: 'SAVE_USER_PREFERENCE_VALUES',
  SAVE_USER_PREFERENCE_LABELS: 'SAVE_USER_PREFERENCE_LABELS',
  FIND_FACE_FROM_PHOTO: 'FIND_FACE_FROM_PHOTO',
  SAVE_FACE_SQUARE: 'SAVE_FACE_SQUARE',
  SAVE_PROFILE_PIC: 'SACE_PROFILE_PIC',
  SAVE_TRAINING_LINKS: 'SAVE_TRAINING_LINKS',
  UPDATE_USER_BIO: 'UPDATE_USER_BIO',
  ADD_TOAST_INFO: 'ADD_TOAST_INFO',
};

// Action for sending out login api request
export const login = (
  username,
  password,
  remember,
  location,
  twoFactorAuth,
  otp
) => {
  let payload = { username, password, remember, location };
  payload = twoFactorAuth ? { ...payload, twoFactorAuth } : payload;
  payload = otp ? { ...payload, otp } : payload;
  return { type: userActions.LOGIN, ...payload };
};
// Action for setting username
export const setUsername = username => {
  return { type: userActions.SET_USERNAME, username };
};
// Action for setting password
export const setPassword = password => {
  return { type: userActions.SET_PASSWORD, password };
};
export const changingPassword = status => {
  return { type: userActions.CHANGING_PASSWORD, status };
};
// Action for checking whether user successfully logged in or not
export const checkingLoginStatus = status => {
  return { type: userActions.CHECKING_LOGIN_STATUS, status };
};
// Action for saving user information
//expecting: id, username, first_name, last_name, role, email, phone
export const savingUserInfo = user => {
  return { type: userActions.SAVING_USER_INFO, user };
};
// Action for saving agency user information
//expecting: user id, agency id
export const saveAgencyUserInfo = agencyUser => {
  return { type: userActions.SAVE_USER_AGENCY_INFO, agencyUser };
};
// Action for creating a new user
//Expecting: first_name, last_name, email, password, phone, role, committee
export const creatingNewUser = user => {
  return { type: userActions.CREATING_NEW_USER, user };
};
// Action for creating an agency new user
//Expecting: first_name, last_name, email, password, phone, role, committee
export const creatingAgencyUser = user => {
  return { type: userActions.CREATING_AGENCY_USER, user };
};
//Action for updating a user
export const updateUser = (id, user, isOwnProfile) => {
  return { type: userActions.UPDATE_USER, id, user, isOwnProfile };
};
// Action for getting all the agencies
export const getAllAgencies = () => {
  return { type: userActions.GET_ALL_AGENCIES };
};
// Action for saving all the agencies
export const saveAllAgencies = agencies => {
  return { type: userActions.SAVE_ALL_AGENCIES, agencies };
};
// Action for checking whether new user created successfully or not
export const checkingCreateNewUserStatus = status => {
  return { type: userActions.CHECKING_CREATE_NEW_USER_STATUS, status };
};

export const clearErrorMessage = () => {
  return { type: userActions.CLEAR_ERROR_MESSAGE };
};
// Action for getting error message
export const gettingErrorMessage = message => {
  return { type: userActions.GETTING_ERROR_MESSAGE, message };
};
export const clearSuccessMessage = () => {
  return { type: userActions.CLEAR_SUCCESS_MESSAGE };
};
// Action for getting success message
export const gettingSuccessMessage = message => {
  return { type: userActions.GETTING_SUCCESS_MESSAGE, message };
};

export const gettingTwoFactorAuthMessage = message => {
  return { type: userActions.GETTING_TWO_FACTOR_AUTH_MESSAGE, message };
};
// Action for checking if the email has been sent
export const loadEmail = bool => {
  return { type: userActions.LOAD_EMAIL, bool };
};
// Action for checking if changing password has been successful or not
export const checkingChangePasswordStatus = status => {
  return { type: userActions.CHECKING_CHANGE_PASSWORD_STATUS, status };
};
// Action for checking if the current user is superuser
export const checkingSuperuser = status => {
  return { type: userActions.CHECKING_SUPERUSER, status };
};
// Action for using token saved in the local storage
export const loggingInUsingToken = (token, type) => {
  return { type: userActions.LOGGING_IN_USING_TOKEN, token, userType: type };
};
export const getUserList = () => {
  return { type: userActions.GET_USER_LIST };
};
export const userList = userList => {
  return { type: userActions.USER_LIST, userList };
};
export const storeCommittees = committees => {
  return { type: userActions.STORE_COMMITTEES, committees };
};
export const storeDivision = division => {
  return { type: userActions.STORE_DIVISION, division };
};
// Action for user log out
export const loggingOut = () => {
  return { type: userActions.LOGGING_OUT };
};
//Action for changing the password
export const emailPasswordReset = username => {
  return { type: userActions.EMAIL_PASSWORD_RESET, username };
};
export const changePassword = (id, newPassword, currentPassword = null) => {
  return {
    type: userActions.CHANGE_PASSWORD,
    id,
    newPassword,
    currentPassword,
  };
};
export const changePasswordByToken = (token, password) => {
  return { type: userActions.CHANGE_PASSWORD_BY_TOKEN, token, password };
};
export const loading = bool => {
  return { type: userActions.LOADING, bool };
};
// Action for saving due reports information
export const savePassDueReportsInfo = info => {
  return { type: userActions.SAVE_PASS_DUE_REPORTS_INFO, info };
};
export const saveUpcomingReportsInfo = info => {
  return { type: userActions.SAVE_UPCOMING_REPORTS_INFO, info };
};
export const saveRecentlyUploadedReportsInfo = info => {
  return { type: userActions.SAVE_RECENTLY_UPLOADED_REPORTS_INFO, info };
};
// Save the id for newly created user
export const saveNewUserID = value => {
  return { type: userActions.SAVE_NEW_USER_ID, value };
};
export const loadMoreReports = url => {
  return { type: userActions.LOAD_MORE_USER_REPORTS, url };
};
export const addMoreReports = (reports, next) => {
  return { type: userActions.ADD_MORE_REPORTS, reports, next };
};
export const setReportLoading = bool => {
  return { type: userActions.SET_REPORT_LOADING, bool };
};
export const getNotifications = (notificationTypes = []) => {
  return { type: userActions.GET_NOTIFICATIONS, notificationTypes };
};
export const saveNotifications = (notifications = []) => {
  return { type: userActions.SAVE_NOTIFICATIONS, notifications };
};
export const updateUserPreferences = (
  id,
  userPreferences,
  setToastText,
  setToastVisible
) => {
  return {
    type: userActions.UPDATE_USER_PREFERENCES,
    id,
    userPreferences,
    setToastText,
    setToastVisible,
  };
};
export const findFaceFromPhoto = photo => {
  return { type: userActions.FIND_FACE_FROM_PHOTO, photo };
};
export const saveFaceSquare = square => {
  return { type: userActions.SAVE_FACE_SQUARE, square };
};
export const saveProfilePic = (pic, square, username) => {
  return { type: userActions.SAVE_PROFILE_PIC, pic, square, username };
};
export const savingTrainingLinks = links => {
  return { type: userActions.SAVE_TRAINING_LINKS, links };
};
export const updateUserBio = bio => {
  return { type: userActions.UPDATE_USER_BIO, bio };
};
export const addToastInfo = toastInfo => {
  return { type: userActions.ADD_TOAST_INFO, toastInfo };
};
