import React, { useEffect } from 'react';
import {
  loadPage,
  loadPageHistory,
  loadSubjects,
  revertPage,
  revertPageFail,
  storePageHistory,
  selectRevision,
  subjectPageError,
  wikiPageError,
} from '../../actions/wikiPageAction';
import { Link, useParams } from 'react-router-dom';
import styles from '../../styles/wiki/PageHistory.module.scss';
import { UncontrolledTooltip } from 'reactstrap';
import ErrorPage from './ErrorPage';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { connect } from 'react-redux';
import {
  ChevronCompactLeftIcon,
  ShortLeftArrowIcon,
} from '../../services/SvgLibrary';

const PageHistory = props => {
  const {
    archives,
    diffs,
    drafters,
    fail,
    loadPage,
    loadPageHistory,
    loadSubjects,
    match,
    page_error,
    page_id,
    pages,
    permissions,
    revertPage,
    revertPageFail,
    selectedRevision,
    selectIndex,
    selectRevision,
    subject_error,
    subjectPageError,
    title,
    userProfile,
    wikiPageError,
  } = props;

  const params = useParams();

  /* Error: match was deprecated and replaced with useParams hook */
  /** useEffect hook for component mount and unmount */
  useEffect(() => {
    loadSubjects(params.sid, 'nav');
    loadPage(params.pid, '');
    loadPageHistory(params.pid);
    subjectPageError(false);
    wikiPageError(false);
    selectRevision('', -1);

    return () => {
      revertPageFail('');
    };
  }, [
    loadPage,
    loadPageHistory,
    loadSubjects,
    params.sid,
    params.pid,
    revertPageFail,
    subjectPageError,
    wikiPageError,
    selectRevision,
  ]);

  function pageInSubject() {
    let check = pages.find(page => {
      return page.id === page_id;
    });
    return check ? true : false;
  }

  const displayText = () => {
    if (selectedRevision) {
      return <div>{Parser(selectedRevision.diff)}</div>;
    } else {
      if (selectIndex === -1) {
        return (
          <div className={styles['display-scroll-text']}>Select a revision</div>
        );
      } else if (selectIndex > diffs.length - 1) {
        return (
          <div className={styles['display-scroll-text']}>
            Revision not stored
          </div>
        );
      } else {
        return (
          <div className={styles['display-scroll-text']}>
            Revision has no text changes exluding spacing, styling and/or list
            changes
          </div>
        );
      }
    }
  };

  const diffCalculation = (oldField, oldIds, newField, newIds, param) => {
    let equal = true;

    let oldList = oldField.map((item, idx) => {
      if (newIds.includes(item.id)) {
        return (
          <span key={idx} className={styles['item']}>
            {item[param]}
          </span>
        );
      } else {
        equal = false;
        return (
          <span key={idx} className={`${styles['item']} ${styles['removed']}`}>
            {item[param]}
          </span>
        );
      }
    });

    let newList = newField.map((item, idx) => {
      if (!oldIds.includes(item.id)) {
        equal = false;
        return (
          <span key={idx} className={`${styles['item']} ${styles['added']}`}>
            {item[param]}
          </span>
        );
      } else return null;
    });
    return {
      'jsx': (
        <div>
          {oldList}
          {newList}
        </div>
      ),
      'equal': equal,
    };
  };

  let inSubject = true;
  let committees = '';
  let committeesEqual = true;
  let tags = '';
  let tagsEqual = true;

  if (pages.length > 0) {
    setTimeout(() => (inSubject = pageInSubject()), 1000);
  }

  const archivesList = archives.map((revision, idx) => {
    let dateTime = new Date(revision.update_time);
    return (
      <div
        className={styles['archive']}
        id={`table-column-tooltip-${idx}`}
        style={selectIndex === idx ? { background: 'gainsboro' } : {}}
        onClick={() => selectRevision(diffs[idx], idx)}
        key={idx}>
        <div>
          <div>{dateTime.toLocaleString()} (EST)</div>
          <div className={styles['edited-by']}>
            Edited by <span>{revision.user}</span>
          </div>
        </div>
        {idx === 0 && (
          <div className={styles['current-version-text']}>Current</div>
        )}
        {(permissions.update_all_page || drafters.includes(userProfile.id)) &&
          idx < diffs.length &&
          idx !== 0 && (
            <button
              className={styles['revert-button']}
              onClick={() =>
                revertPage(
                  { 'id': params.pid, 'subject': params.sid },
                  revision
                )
              }>
              Revert
            </button>
          )}
        <UncontrolledTooltip
          placement='bottom'
          target={`table-column-tooltip-${idx}`}>
          Click to view
        </UncontrolledTooltip>
      </div>
    );
  });

  const parserOptions = {
    replace: domNode => {
      if (domNode.children) {
        return domToReact(domNode.children, parserOptions);
      }
    },
  };

  if (selectedRevision) {
    let newPage = selectedRevision;
    let newComIds = newPage.committees.map(com => com.id);
    let newTagIds = newPage.tags.map(com => com.id);

    if (selectIndex < diffs.length - 1) {
      let oldPage = diffs[selectIndex + 1];
      let oldComIds = oldPage.committees.map(com => com.id);
      let oldTagIds = oldPage.tags.map(com => com.id);

      let diffCom = diffCalculation(
        oldPage.committees,
        oldComIds,
        newPage.committees,
        newComIds,
        'committee_name'
      );
      committees = diffCom.jsx;
      committeesEqual = diffCom.equal;

      let diffTag = diffCalculation(
        oldPage.tags,
        oldTagIds,
        newPage.tags,
        newTagIds,
        'name'
      );
      tags = diffTag.jsx;
      tagsEqual = diffTag.equal;
    } else {
      committees = newPage.committees.map((com, idx) => (
        <span key={idx} className={`${styles['item']} ${styles['added']}`}>
          {com.committee_name}
        </span>
      ));
      tags = newPage.tags.map((tag, idx) => (
        <span key={idx} className={`${styles['item']} ${styles['added']}`}>
          {tag.name}
        </span>
      ));
    }
  }

  return (
    <>
      {archivesList.length === 0 && (
        <div>
          <div className={styles['page-no-history-wrapper']}>
            <img src='/img/page-history-hourglass.png' alt='Page History' />
            <h1>No History</h1>
            <Link
              className={styles['return-button']}
              to={`/CLEX/subject/${params.sid}/page/${params.pid}`}>
              <ShortLeftArrowIcon /> Return to {title}
            </Link>
          </div>
        </div>
      )}
      {archivesList.length > 0 && (
        <div className='container'>
          <div className='row mt-4 mb-5'>
            <div className='col'>
              <Link
                className={styles['return-button']}
                to={`/CLEX/subject/${params.sid}/page/${params.pid}`}>
                <ChevronCompactLeftIcon
                  styleProp={{ verticalAlign: '-.225em' }}
                />
                Return to {title}
              </Link>
              <h1 className={styles['archived-history-heading']}>
                Archived History
              </h1>
              {fail && (
                <div className='alert alert-danger' role='alert'>
                  {fail}
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4 overflow-auto h-50'>
              <div className={styles['scroll-select']}>{archivesList}</div>
            </div>
            <div className='col-md-6 col-lg-8'>
              <div className={styles['article-text-edits-wrapper']}>
                {displayText()}
                {!committeesEqual && (
                  <>
                    <h2>Committees:</h2>
                    {committees}
                    <br />
                  </>
                )}
                {!tagsEqual && (
                  <>
                    <h2>Tags:</h2>
                    {tags}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {(page_error || subject_error || !inSubject) && (
        <ErrorPage type='page' idError={!inSubject} />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    archives: state.pageHistoryReducer.archives,
    diffs: state.pageHistoryReducer.diffs,
    drafters: state.wikiPageReducer.drafters,
    fail: state.pageHistoryReducer.fail,
    page_error: state.wikiPageReducer.page_error,
    page_id: state.wikiPageReducer.page_id,
    pages: state.wikiSubjectReducer.pages,
    permissions: state.userReducer.userProfile.permissions.wiki,
    selectedRevision: state.pageHistoryReducer.selectedRevision,
    selectIndex: state.pageHistoryReducer.selectIndex,
    subject_error: state.wikiSubjectReducer.subject_error,
    title: state.wikiPageReducer.title,
    userProfile: state.userReducer.userProfile,
  };
};

const mapDispatchToProps = {
  loadPage,
  loadPageHistory,
  loadSubjects,
  revertPage,
  revertPageFail,
  selectRevision,
  storePageHistory,
  subjectPageError,
  wikiPageError,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHistory);
