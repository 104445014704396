import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/citation-request/CitationTable.module.scss';
import { connect } from 'react-redux';
import * as actions from '../../actions/citationRequestAction';
import {
  PlusIcon,
  SearchIcon,
  ChevronUpDownArrowsIcon,
  CloseButtonCircleIcon,
  PreviewEyeOutlineIcon,
  CheckIcon,
} from '../../services/SvgLibrary';
import { rolesUnderCouncilMembers } from '../../services/constants';

const CitationTable = props => {
  const [citationRequestSearchString, setCitationRequestSearchString] =
    useState('');
  const [selectedFilter, setSelectedFilter] = useState('Show All');
  const [selectedSortBy, setSelectedSortBy] = useState(
    rolesUnderCouncilMembers.includes(props.userProfile.role)
      ? 'Date of Event'
      : 'Council Member'
  );

  const tableContainerRef = useRef(null);
  const [isLoadingCitations, setIsLoadingCitations] = useState(false);
  const [selectedSortOrder, setSelectedSortOrder] = useState('Ascending');
  const [openCard, setOpenCard] = useState(null);

  const currentSearch = 'searchAll';
  const searchResultsDisplay = false;
  const screenWidth = window.innerWidth;

  useEffect(() => {
    const onScroll = e => {
      const scrollPosition = e.target.scrollHeight - e.target.scrollTop;

      if (scrollPosition < 1000 && props.nextAPI && !isLoadingCitations) {
        setIsLoadingCitations(true);
        props.getCitationRequests(props.nextAPI, {
          sort_by: props.citationRequestSortBy,
          sort_direction: props.citationRequestSortDirection,
          filter: props.citationRequestFilter,
          search_term: citationRequestSearchString,
        });
        setIsLoadingCitations(false);
      }
    };

    const tableContainerNode = tableContainerRef.current;
    if (tableContainerNode) {
      tableContainerRef.current.addEventListener('scroll', onScroll, false);
    }
    return () => {
      tableContainerRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [props.nextAPI]);

  const sortBy = (sortField, e) => {
    setSelectedSortBy(e);
    props.getCitationRequests(null, {
      sort_by: sortField,
      sort_direction: props.citationRequestSortDirection,
      filter: props.citationRequestFilter,
      search_term: citationRequestSearchString,
    });
  };

  const changeDirection = () => {
    props.getCitationRequests(null, {
      sort_by: props.citationRequestSortBy,
      sort_direction:
        props.citationRequestSortDirection === 'asc' ? 'desc' : 'asc',
      filter: props.citationRequestFilter,
      search_term: citationRequestSearchString,
    });
  };

  const clearSearch = () => {
    setCitationRequestSearchString('');
    props.getCitationRequests(null, {
      sort_by: props.citationRequestSortBy,
      sort_direction:
        props.citationRequestSortDirection === 'asc' ? 'desc' : 'asc',
      filter: props.citationRequestFilter,
      search_term: '',
    });
  };

  const filterByStatus = (filter, e) => {
    setSelectedFilter(e);
    props.getCitationRequests(null, {
      sort_by: props.citationRequestSortBy,
      sort_direction: props.citationRequestSortDirection,
      filter: filter,
      search_term: citationRequestSearchString,
    });
  };

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onGetSearchResult();
    }
  };

  const onGetSearchResult = () => {
    props.getCitationRequests(null, {
      sort_by: props.citationRequestSortBy,
      sort_direction: props.citationRequestSortDirection,
      filter: props.citationRequestFilter,
      search_term: citationRequestSearchString,
    });
  };

  const editCitationRequest = citationRequest => {
    props.selectTargetDayForCitationRequests([citationRequest]);
    props.selectCitationRequest(citationRequest);
    props.changeCitationRequestDisplay('detail');
  };

  const generateSearchBox = () => {
    return (
      <div className={styles['search-box']}>
        <SearchIcon
          classNameProp={styles['search-button']}
          width='21'
          height='21'
          onClickFunction={onGetSearchResult}
        />
        {currentSearch === 'searchAll' && (
          <input
            type='text'
            value={citationRequestSearchString}
            placeholder='Search All Fields'
            onChange={e => {
              e.preventDefault();
              setCitationRequestSearchString(e.target.value);
            }}
            onKeyDown={onKeyPress}
          />
        )}
        {citationRequestSearchString !== '' && (
          <div onClick={clearSearch}>
            <CloseButtonCircleIcon classNameProp={styles['search-button']} />
          </div>
        )}

        {searchResultsDisplay && !props.isLoading && (
          <CloseButtonCircleIcon
            classNameProp={styles['search-button']}
            // onClickFunction={onClearFilter}
          />
        )}
      </div>
    );
  };

  const { isLoading, filteredCitationRequests, userProfile } = props;
  const isCMStaff = rolesUnderCouncilMembers.includes(userProfile.role);
  let sortDirection = props.citationRequestSortDirection;

  const displayFields = [
    {
      title: 'Show All',
      field: 'all',
    },
    {
      title: 'Pending',
      field: 'pending',
    },
    {
      title: 'Approved',
      field: 'approved',
    },
    {
      title: 'In-Progress',
      field: 'in-progress',
    },
    {
      title: 'Completed',
      field: 'completed',
    },
    {
      title: 'Delivered',
      field: 'delivered',
    },
    {
      title: 'Withdrawn',
      field: 'withdrawn',
    },
  ];
  const sortFieldsAll = [
    {
      title: 'Honoree',
      field: 'honoree',
    },
    {
      title: 'Date Needed',
      field: 'date_citation_needed',
    },
    {
      title: 'Date of Event',
      field: 'date_of_event',
    },
    {
      title: 'Date Submitted',
      field: 'created_at',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Requested By',
      field: 'requested_by',
    },
  ];
  let sortFields = [...sortFieldsAll];
  if (!isCMStaff) {
    sortFields = [
      {
        title: 'Council Member',
        field: 'council_member',
      },
      ...sortFields,
    ];
  }

  const citationRequestDisplay =
    filteredCitationRequests &&
    filteredCitationRequests.map((citationRequest, idx) => {
      const contact = props.userList.find(user => {
        return user.id === citationRequest.contact_person;
      });

      let citationRequestTitle = citationRequest.id;
      if (screenWidth > 795) {
        citationRequestTitle = citationRequest.title;
        if (
          screenWidth < 992 &&
          citationRequestTitle &&
          citationRequestTitle.length > 115
        ) {
          citationRequestTitle = citationRequestTitle.substring(0, 115) + '...';
        } else if (
          screenWidth < 1200 &&
          citationRequestTitle &&
          citationRequestTitle.length > 175
        ) {
          citationRequestTitle = citationRequestTitle.substring(0, 175) + '...';
        }
      }

      return (
        <div
          key={idx}
          className={styles['citation-block']}
          onClick={() => {
            setOpenCard(citationRequest.id);
            document.body.style.overflow = 'hidden';
          }}>
          <div
            className='card shadow-sm border-secondary border-0 border-start border-3 overflow-hidden'
            onClick={() => {
              if (citationRequest.id === openCard) {
                setOpenCard(null);
              }
            }}>
            <button
              title='Edit citation request'
              className={styles['edit-citation-button']}
              onClick={() => editCitationRequest(citationRequest)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                fill='currentColor'
                className={`${styles['edit']} bi bi-pencil-square`}
                viewBox='0 0 16 16'>
                <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                <path
                  fillRule='evenodd'
                  d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
                />
              </svg>
            </button>
            <div
              className='citation-card card-header rounded-1 collapsible overflow-hidden accordion-button shadow-none bg-white text-dark d-flex flex-column align-items-start justify-content-center px-3 collapsed'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target={`#kt_docs_card_collapsible-${citationRequest.id}`}>
              <div className='container-fluid p-0'>
                <div className='row'>
                  <div className='col-5'>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        marginBottom: '0.5rem',
                      }}
                      title='Name of honoree'>
                      <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                        Honoree:
                      </span>
                      <span className='text-dark' style={{ lineHeight: '1.1' }}>
                        {citationRequest.honoree}
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        marginBottom: '0.5rem',
                      }}
                      title='Status of citation request'>
                      <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                        Status:
                      </span>
                      <span className='text-dark' style={{ lineHeight: '1.1' }}>
                        {citationRequest.status}
                      </span>
                    </div>
                  </div>
                  <div className='col-7 text-start'>
                    {isCMStaff ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                          marginBottom: '0.5rem',
                        }}
                        title='Date citation is needed'>
                        <span
                          style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                          Date of Event:
                        </span>
                        <span
                          className='text-dark'
                          style={{ lineHeight: '1.1' }}>
                          {citationRequest.date_of_event}
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                          marginBottom: '0.5rem',
                        }}
                        title='Date citation is needed'>
                        <span
                          style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                          Date needed:
                        </span>
                        <span
                          className='text-dark'
                          style={{ lineHeight: '1.1' }}>
                          {citationRequest.date_citation_needed}
                        </span>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                      }}
                      title='Council Member'>
                      <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                        Council Member:
                      </span>
                      <span className='text-dark' style={{ lineHeight: '1.1' }}>
                        {citationRequest.council_member.full_name}
                      </span>
                    </div>
                  </div>
                  <button
                    title='Edit citation request'
                    className={styles['edit-citation-button']}
                    onClick={() => editCitationRequest(citationRequest)}>
                    <PreviewEyeOutlineIcon
                      width='18'
                      height='18'
                      classNameProp={styles['edit']}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div
              id={`kt_docs_card_collapsible-${citationRequest.id}`}
              className={`collapse${
                citationRequest.id === openCard ? ' show' : ''
              }`}>
              <div className='card-body'>
                <p className={`mb-0 ${styles['citation-reason-text']}`}>
                  {citationRequest.reason}
                </p>
              </div>

              <div className='card-footer border-top border-0 bg-white overflow-auto'>
                <div className='container-fluid p-0'>
                  <div className='row'>
                    <h4 className={styles['probo']}>
                      Requested by:
                      <span
                        className={`badge bg-light text-dark ${styles['cm']}`}>
                        {citationRequest.requested_by.full_name}
                      </span>
                    </h4>
                  </div>
                  <div className='row'>
                    <div className='col-5'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-person-fill'
                        viewBox='0 0 16 16'>
                        <path d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z' />
                      </svg>
                      <span className={styles['probo']} title='Contact name'>
                        {citationRequest.contact_person.full_name}
                      </span>
                      <br />
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-calendar'
                        viewBox='0 0 16 16'>
                        <path d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z' />
                      </svg>
                      <span className={styles['probo']} title='Date submitted'>
                        {citationRequest.created_at.slice(0, 10)}
                      </span>
                    </div>
                    <div className='col-7'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-telephone-fill'
                        viewBox='0 0 16 16'>
                        <path
                          fillRule='evenodd'
                          d='M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z'
                        />
                      </svg>
                      <span className={styles['probo']} title='Phone number'>
                        {citationRequest.contact_person.phone}
                      </span>
                      <br />
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-envelope'
                        viewBox='0 0 16 16'>
                        <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z' />
                      </svg>
                      <span className={styles['probo']} title='Email address'>
                        {citationRequest.contact_person.email}
                      </span>
                    </div>
                  </div>
                  {isCMStaff && (
                    <div className='row'>
                      <div className='col-5'>
                        <span className={styles['probo']} title='Notes'>
                          {citationRequest.notes &&
                            `Notes: ${citationRequest.notes}`}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  // display the search area
  return (
    <>
      <div className={styles['search-filter-wrapper']}>
        {generateSearchBox()}
        <div className=' pt-3 gx-5 d-flex justify-content-end align-items-center'>
          <span className={styles['filter-label']}>Status</span>
          <div className='dropdown'>
            <button
              className={`btn dropdown-toggle d-flex align-items-center ${styles['dropdown-toggle']}`}
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {selectedFilter}
              <ChevronUpDownArrowsIcon
                width='15'
                height='15'
                classNameProps={styles['chevron-icon']}
              />
            </button>
            <ul
              className={`dropdown-menu ${styles['dropdown-menu']}`}
              aria-labelledby='dropdownMenuButton1'>
              <li>
                {displayFields.map((c, idx) => (
                  <a
                    key={idx}
                    className={`dropdown-item ${styles['status-dropdown-item']}`}
                    onClick={() => filterByStatus(c.field, c.title)}>
                    {c.title}
                    {selectedFilter === c.title && (
                      <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                    )}
                  </a>
                ))}
              </li>
            </ul>
          </div>
          <span className={styles['filter-label']}>Sort by</span>
          <div className='dropdown'>
            <button
              className={`btn dropdown-toggle d-flex align-items-center ${styles['dropdown-toggle']}`}
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {selectedSortBy}
              <ChevronUpDownArrowsIcon
                width='15'
                height='15'
                classNameProps={styles['chevron-icon']}
              />
            </button>
            <ul
              className={`dropdown-menu ${styles['dropdown-menu']} ${styles['filter']}`}
              aria-labelledby='dropdownMenuButton1'>
              {sortFields.map((c, idx) => (
                <li key={idx}>
                  <a
                    className={`dropdown-item ${styles['status-dropdown-item']}`}
                    onClick={() => sortBy(c.field, c.title)}>
                    {c.title}
                    {selectedSortBy === c.title && (
                      <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                    )}
                  </a>
                </li>
              ))}
              <li>
                <hr className='dropdown-divider' />
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles['status-dropdown-item']}`}
                  onClick={() => {
                    changeDirection();
                    setSelectedSortOrder('Ascending');
                  }}>
                  Ascending
                  {selectedSortOrder === 'Ascending' && (
                    <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                  )}
                </a>
              </li>
              <li>
                <a
                  className={`dropdown-item ${styles['status-dropdown-item']}`}
                  onClick={() => {
                    changeDirection();
                    setSelectedSortOrder('Descending');
                  }}>
                  Descending
                  {selectedSortOrder === 'Descending' && (
                    <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                  )}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        ref={tableContainerRef}
        className={styles['citation-request-list-container-tasks']}>
        {filteredCitationRequests?.length > 0 && citationRequestDisplay}
      </div>
      {isLoading && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {!filteredCitationRequests && (
        <h3 className={styles['no-valid-result-message']}>No valid result</h3>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    isLoading: state.citationRequestReducer.isLoading,
    nextAPI: state.citationRequestReducer.nextAPI,
    allCitationRequests: state.citationRequestReducer.allCitationRequests,
    filteredCitationRequests:
      state.citationRequestReducer.filteredCitationRequests,
    searchString: state.citationRequestReducer.citationRequestSearchString,
    citationRequestFilter: state.citationRequestReducer.citationRequestFilter,
    citationRequestSortBy: state.citationRequestReducer.citationRequestSortedBy,
    citationRequestSortDirection:
      state.citationRequestReducer.citationRequestSortDirection,
  };
};

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CitationTable);
