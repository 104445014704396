import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/ReportServicePopup.module.scss';
import {
  changeProcRequestPopupWindow,
  changeProcRequestDisplay,
} from '../../actions/procRequestAction';
import { downloadFileStatus } from '../../actions/fileDownloadAction';
import Modal from 'react-modal';
import CreateNewProcRequest from './CreateNewProcRequest';
import UpdateProcRequest from './UpdateProcRequest';
import ProcRequestsForDay from './ProcRequestsForDay';

const ProcRequestServicePopup = props => {
  // Initial all the status
  const onCloseWindow = () => {
    props.changeProcRequestPopupWindow(false);
    props.changeProcRequestDisplay('list');
    props.downloadFileStatus(null);
    document.body.style.overflow = 'auto';
  };

  const onBackToList = () => {
    props.changeCitationRequestDisplay('list');
  };

  return ReactDOM.createPortal(
    <Modal
      isOpen={props.openProcRequestPopupStatus}
      className={styles['report-modal-style']}
      overlayClassName={styles.modalOverlay}
      onRequestClose={onCloseWindow}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}>
      {['list', 'detail', 'update'].includes(props.currentDisplay) && (
        <ProcRequestsForDay
          selectedDay={props.selectedDay}
          currentMode={props.currentMode}
          onCloseWindow={onCloseWindow}
          onBackToService={onBackToList}
          shownStatuses={props.shownStatuses}
        />
      )}
      {props.currentDisplay === 'create' && (
        <CreateNewProcRequest onCloseWindow={onCloseWindow} />
      )}
      {props.currentDisplay === 'update' && (
        <UpdateProcRequest onCloseWindow={props.onCloseWindow} />
      )}
    </Modal>,
    document.getElementById('portal-root')
  );
};

const mapStateToProps = state => {
  return {
    openProcRequestPopupStatus:
      state.procRequestReducer.openProcRequestPopupStatus,
    currentDisplay: state.procRequestReducer.currentDisplay,
    procRequestDetail: state.procRequestReducer.selectedProcRequest,
  };
};

const mapDispatchToProps = {
  changeProcRequestPopupWindow,
  changeProcRequestDisplay,
  downloadFileStatus,
};

// export default ProcRequestServicePopup

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcRequestServicePopup);
