import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  user: '',
  page_id: 0,
  subject: 0,
  subject_of_page: true,
  title: '',
  author: '',
  drafters: [],
  memo_regarding: '',
  tags: [],
  description: '',
  content: [],
  tableOfContent: [],
  comments: [],
  review: false,
  page_error: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.LOAD_PAGE:
      return { ...state };

    case wikiPageActions.STORE_PAGE:
      return {
        ...state,
        page_id: action.page.id,
        subject: action.page.subject,
        title: action.page.title,
        description: action.page.description,
        tags: action.page.tags,
        content: action.page.content,
        tableOfContent: action.page.content,
        review: action.page.review_lock ? true : false,
        author: action.page.author,
        memo_regarding: action.page.memo_regarding,
        drafters: action.page?.drafters || [],
      };

    case wikiPageActions.STORE_PAGE_DIFF:
      return {
        ...state,
        page_id: action.page.id,
        subject: action.page.subject,
        title: action.page.diff.title,
        description: action.page.diff.description,
        tags: action.page.tags,
        content: action.page.diff.content,
        tableOfContent: action.page.content,
        review: action.page.review_lock ? true : false,
        author: action.page.author,
        memo_regarding: action.page.diff.memo_regarding,
        drafters: action.page?.drafters || [],
      };

    case wikiPageActions.LOAD_COMMENTS:
      return { ...state };

    case wikiPageActions.STORE_COMMENTS:
      return { ...state, comments: action.comments.results };

    case wikiPageActions.ADD_COMMENT:
      return { ...state };

    case wikiPageActions.WIKI_PAGE_ERROR:
      return { ...state, page_error: action.status };

    case wikiPageActions.PAGE_IN_SUBJECT:
      return { ...state, subject_of_page: action.bool };

    default:
      return state;
  }
}
