import React, { useCallback, useEffect, useMemo } from 'react';
import {
  loadMyPageReviews,
  loadAllPageReviews,
} from '../../../actions/myTasksAction';
import styles from '../../../styles/user/ReviewList.module.scss';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ReviewList = props => {
  const {
    history,
    loadAllPageReviews,
    loadingAllReviews,
    loadingMyReviews,
    loadMyPageReviews,
    my_pages,
    un_reviewed,
    userList,
    userProfile,
  } = props;
  const isUserAStaffReviewer = useMemo(() => {
    const staffReviewers = userList.filter(user => user.can_review_wiki);
    staffReviewers.find(reviewer => reviewer.username === userProfile.username);
  }, [userProfile.username, userList]);
  const userisADeputyDirector = useMemo(
    () =>
      userProfile.role === 'Deputy Director' ||
      userProfile.role === 'Assistant Deputy Director',
    [userProfile.role]
  );
  const checkAuth = useCallback(() => {
    if (isUserAStaffReviewer || userisADeputyDirector) {
      return true;
    } else {
      return false;
    }
  }, [isUserAStaffReviewer, userisADeputyDirector]);
  const navigate = useNavigate();

  /** component mount */
  useEffect(() => {
    if (checkAuth()) {
      loadAllPageReviews();
    } else {
      loadMyPageReviews();
    }
  }, [checkAuth, loadAllPageReviews, loadMyPageReviews]);

  const pageReviewToList = pages => {
    return pages.map((page, idx) => {
      let committees = page.committees.map((com, idx) => (
        <span key={idx} className={styles['item']}>
          {com.committee_name}
        </span>
      ));
      let updated = new Date(page.updated_at);

      return (
        <tr
          key={idx}
          onClick={() => {
            navigate(`/myTasks/myCLEXReviews/${page.id}`);
          }}>
          <td>{page.page ? 'Update' : 'Create'}</td>
          <td>{page.title}</td>
          <td>{page.editor}</td>
          <td>{`${updated.toLocaleDateString()} ${updated.toLocaleTimeString()} (EST)`}</td>
          <td>{committees}</td>
        </tr>
      );
    });
  };
  const inReview =
    my_pages && my_pages.length > 0 ? pageReviewToList(my_pages) : null;
  const toBeReviewed =
    un_reviewed && un_reviewed.length > 0
      ? pageReviewToList(un_reviewed)
      : null;
  const reviewAuth = checkAuth();

  return (
    <>
      {!loadingMyReviews && !loadingAllReviews && (
        <div className='container mt-5'>
          <div className='row'>
            <div className='col'>
              <table
                className={
                  styles['review-table'] + ' table-hover table-bordered'
                }>
                <thead>
                  <tr className='table-headers'>
                    <th>Type</th>
                    <th>Title</th>
                    <th>Drafter</th>
                    <th>Last Updated</th>
                    <th>Committees</th>
                  </tr>
                </thead>
                <tbody>
                  {reviewAuth ? toBeReviewed : inReview}
                  {((!reviewAuth && my_pages.length === 0) ||
                    (reviewAuth && un_reviewed.length === 0)) && (
                    <tr>
                      <td colSpan='5' style={{ textAlign: 'center' }}>
                        No pages under review
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {(loadingAllReviews || loadingMyReviews) && (
                <div className='loading-container'>
                  <img
                    className='loading-image'
                    src='/img/newLoadingAnimation.gif'
                    alt='Loading animation'
                    align='middle'
                  />
                  <h3>
                    Loading <span className='dot1'>.</span>
                    <span className='dot2'>.</span>
                    <span className='dot3'>.</span>
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    un_reviewed: state.reviewListReducer.un_reviewed,
    my_pages: state.reviewListReducer.my_pages,
    loadingMyReviews: state.reviewListReducer.loadingMyReviews,
    loadAllPageReviews: state.reviewListReducer.loadingAllReviews,
  };
};

const mapDispatchtoProps = {
  loadAllPageReviews,
  loadMyPageReviews,
};

export default connect(mapStateToProps, mapDispatchtoProps)(ReviewList);
