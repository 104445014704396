import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/PageForm.module.scss';
import * as actions from '../../actions/wikiPageAction';
import {
  Collapse,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Alert,
} from 'reactstrap';
import FormTextEditor from './FormTextEditor';
import { FastField, FieldArray, ErrorMessage, getIn } from 'formik';
import {
  TrashIcon,
  PlusIcon,
  ShortArrowDownIcon,
  ShortArrowUpIcon,
} from '../../services/SvgLibrary';

const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <Input
        onKeyPress={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        type='text'
        name={field.name}
        {...props}
        {...field}
      />
      <ErrorMessage
        name={field.name}
        render={msg => <Alert color='danger'>{msg}</Alert>}
      />
    </>
  );
};

const CustomQuillComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <FormTextEditor
        {...props}
        name={field.name}
        defaultValue={field.value}
        onChange={content => {
          field.onChange({
            target: {
              type: typeof content,
              id: field.name,
              value: content,
              name: field.name,
            },
          });
        }}
        className={styles['quillInput']}
      />
      {errors[field.name]}
      <ErrorMessage
        name={field.name}
        render={msg => <Alert color='danger'>{msg}</Alert>}
      />
    </>
  );
};

const SectionFormBar = props => {
  const [collapse, setCollapse] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const {
    arrayHelpers,
    divisionId,
    errors,
    index,
    onDelete,
    section,
    sectionId,
    subSectionId,
    touched,
    type,
    values,
  } = props;

  //Get the position of this section
  function getPosition(index) {
    let pos = [];
    if (typeof sectionId != 'undefined') {
      pos.push(sectionId);
      if (typeof subSectionId != 'undefined') {
        pos.push(subSectionId);
        if (typeof divisionId != 'undefined') {
          pos.push(divisionId);
        }
      }
    }
    if (typeof index != 'undefined') {
      pos.push(index);
    }
    return pos;
  }

  //Get the current levels of sections
  function getSections(section, index) {
    let indexes = getPosition(index);

    let nextSection = (section, indexes, inOfIn) => {
      if (inOfIn < indexes.length - 1) {
        return nextSection(
          section.sections[indexes[inOfIn]],
          indexes,
          inOfIn + 1
        );
      } else {
        return section.sections;
      }
    };
    return nextSection(section, indexes, 0);
  }

  //Get the length of the current level of the sections
  function getLength(index) {
    return getSections(values, index).length;
  }

  //Propogate out the data of the section being clicked
  function onBarClick(e) {
    e.preventDefault();
    setCollapse(!collapse);
  }

  const sectionContentValidation = (value, section, type) => {
    let error = '';
    let msg =
      'Content can only be empty if it is a top level section with no sub-sections or it has lower level separations with content .';

    if (value === '' || value === '<p><br></p>') {
      if (type === 'Section' && section.sections.length === 0) {
        value = '<p>Not Applicable</p>';
      } else if (type !== 'SubDivision' && section.sections.length > 0) {
        //Figure out the specifics
        let empty = false;
        section.sections.map(subSection => {
          if (
            subSection.content === '' ||
            subSection.content === '<p><br></p>'
          ) {
            empty = true;
          }
          return null;
        });
        if (empty) {
          error = msg;
        }
      } else {
        error = msg;
      }
    }
    return error;
  };

  //Check if errors exist
  function hasError(errors, touched, name) {
    const titleError = getIn(errors, `${name}title`);
    const titleTouched = getIn(touched, `${name}title`);
    const contentError = getIn(errors, `${name}content`);
    const contentTouched = getIn(touched, `${name}content`);

    if ((titleError && titleTouched) || (contentError && contentTouched)) {
      return true;
    }
    return false;
  }

  function checkValues(values, name) {
    const titleValue = getIn(values, `${name}title`);
    const contentValue = getIn(values, `${name}content`);
    const sectionsValue = getIn(values, `${name}sections`);
    return (
      titleValue ||
      (contentValue && contentValue !== '<p><br></p>') ||
      sectionsValue.length > 0
    );
  }

  //Use the position to input the form values
  let position = getPosition(index);
  section.position = position.join('.');

  let fieldName = ``;
  for (let i = 0; i < position.length; i++) {
    fieldName += `sections[${position[i]}]`;
  }

  let displayPosition = position.map(i => {
    return i + 1;
  });
  let next = '';

  let sections = getSections(values, index)[index].sections;

  switch (type) {
    case 'Section':
      next = 'SubSection';
      break;
    case 'SubSection':
      next = 'Division';
      break;
    case 'Division':
      next = 'SubDivision';
      break;
    case 'SubDivision':
      next = '';
      break;
    default:
      break;
  }

  return (
    <div
      className={styles['form-border']}
      style={
        hasError(errors, touched, fieldName) ? { 'borderColor': 'red' } : {}
      }>
      <div className={styles['sectionBar']} onClick={e => onBarClick(e)}>
        <span>{`${type} ${displayPosition.join('.')}: ${
          section.title ? section.title : ''
        }`}</span>
        <div className={styles['right']}>
          <Button
            className={styles['sectionBarButton']}
            disabled={index === 0}
            size='sm'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              arrayHelpers.move(index, index - 1);
            }}>
            <ShortArrowUpIcon />
          </Button>
          <Button
            className={styles['sectionBarButton']}
            disabled={index === getLength(index) - 1}
            size='sm'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              arrayHelpers.move(index, index + 1);
            }}>
            <ShortArrowDownIcon />
          </Button>
          <Button
            className={styles['sectionBarButton']}
            disabled={type === 'Section' && getLength(index) === 1}
            size='sm'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (checkValues(values, fieldName)) {
                setDeleteMessage(true);
              } else {
                arrayHelpers.remove(index);
              }
            }}>
            <TrashIcon />
          </Button>
        </div>
      </div>

      <Collapse className={styles['collapse-border']} isOpen={collapse}>
        <FormGroup>
          <>
            <Label>Title:</Label>
            <FastField
              name={`${fieldName}title`}
              component={CustomInputComponent}
              type='text'
            />
          </>
          <>
            <Label>Content:</Label>
            <FastField
              name={`${fieldName}content`}
              validate={e => sectionContentValidation(e, section, type)}
              component={CustomQuillComponent}
            />
          </>
        </FormGroup>
      </Collapse>
      {next && (
        <FieldArray
          name={`${fieldName}sections`}
          render={arrayHelpers2 => {
            return (
              <div className={styles['page-indent-1']}>
                <div className={styles['div-row']}>
                  <div
                    onClick={e => {
                      arrayHelpers2.push({
                        position: '',
                        title: '',
                        content: '',
                        errors: '',
                        sections: [],
                      });
                    }}>
                    <PlusIcon /> {next}
                  </div>
                </div>
                {sections && sections.length > 0 ? (
                  sections.map((subSection, index2) => {
                    return (
                      <div key={index2}>
                        <SectionFormBar
                          type={next}
                          index={index2}
                          section={subSection}
                          sectionId={
                            Number.isInteger(sectionId) ? sectionId : index
                          }
                          subSectionId={
                            position.length >= 2
                              ? parseInt(
                                  `${subSectionId ? subSectionId : index}`
                                )
                              : undefined
                          }
                          divisionId={
                            position.length >= 3 || type === 'Division'
                              ? parseInt(`${divisionId ? divisionId : index}`)
                              : undefined
                          }
                          subDivisionId={
                            type === 'SubDivision' ? index : undefined
                          }
                          values={values}
                          errors={errors}
                          touched={touched}
                          arrayHelpers={arrayHelpers2}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div />
                )}
              </div>
            );
          }}
        />
      )}
      {deleteMessage && (
        <Modal
          isOpen={deleteMessage}
          toggle={e => {
            setDeleteMessage(e.target.value);
          }}
          backdrop='static'>
          <ModalHeader>Warning</ModalHeader>
          <ModalBody>
            Are you sure you want to delete this section? This will delete
            everything inside.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                arrayHelpers.remove(index);
                if (onDelete) onDelete();
                setDeleteMessage(false);
              }}>
              Delete
            </Button>
            <Button
              onClick={() => {
                setDeleteMessage(false);
              }}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default SectionFormBar;
