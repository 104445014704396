import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  keywords: '',
  filters: {
    page: [],
    subject: [],
    tag: [],
    committee: false,
  },
  loading: false,
  subjects: [],
  pages: [],
  tags: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.LOAD_WIKI_SEARCH_RESULTS:
      return { loading: true };
    case wikiPageActions.STORE_WIKI_SEARCH_RESULTS:
      return {
        ...state,
        subjects: action.results.subjects,
        pages: action.results.pages,
        tags: action.results.tags,
        loading: false,
      };
    case wikiPageActions.STORE_URL_SEARCH_PARAMS:
      return { ...state, keywords: action.keywords, filters: action.filters };
    default:
      return state;
  }
}
