import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from '../../styles/ls-request/LsHistory.module.scss';
import styles2 from '../../styles/wiki/PageHistory.module.scss';
import {
  getLsRequestHistory,
  storeLsRequestHistory,
  selectLsRevision,
} from '../../actions/lsRequestAction';
import { UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import {
  lsModelTextFields,
  rolesUnderCouncilMembers,
  nonLegDivisionRoles,
} from '../../services/constants';
import parse from 'html-react-parser';
import File from '../library/File';
import { formatDateTime } from '../../utils/helper';
import { LsFields } from '../../services/LsFields';
import {
  ChevronCompactLeftIcon,
  ClockHistoryIcon,
} from '../../services/SvgLibrary';
import { getFileAndDownload } from '../../actions/fileDownloadAction';

const lsFieldList = JSON.parse(JSON.stringify(LsFields));

/**
 * LsInfoDisplay component: displays all information about a version of LS history
 * @returns LsInfoDisplay component
 */
const LsInfoDisplay = ({
  currentLS,
  isCurrent,
  version = null,
  userProfile,
  getFileAndDownload,
  fieldsToHighlight,
}) => {
  const cmRole = rolesUnderCouncilMembers.includes(userProfile.role);
  let filteredField = lsFieldList.filter(
    field =>
      !(
        cmRole &&
        (field.viewability === 'staffOnly' || field.viewability === 'none')
      )
  );
  let waivedConfidentiality = filteredField.find(
    x => x.field === 'make_first_prime_public'
  );
  waivedConfidentiality.title = 'Waived Confidentiality?';
  let excludedArray = [
    'ls_number',
    'first_prime',
    'date_received',
    'new_status',
    'new_status_requestor',
  ];
  let displayLsPart = filteredField.map((ls, idx) => {
    let text = '';
    if (
      (currentLS[ls.field] ||
        ls.type === 'checkbox' ||
        ls.field === 'operational_requirement' ||
        ls.field === 'reintroduction') &&
      !excludedArray.includes(ls.field) &&
      !(ls.type === 'multi-select')
    ) {
      if (ls.field === 'date_received') {
        text = formatDateTime(currentLS[ls.field]);
      } else if (ls.type === 'file') {
        if (currentLS[ls.field].length > 0) {
          const files = currentLS[ls.field];
          text = (
            <div key={idx}>
              {files.map((file, index) => (
                <File
                  id={`lsr_${ls.field}-${index}`}
                  file={file}
                  getFileAndDownload={getFileAndDownload}
                  key={index}
                />
              ))}
            </div>
          );
        } else {
          text = 'N/A';
        }
      } else if (ls.field === 'committee') {
        text = parse(
          currentLS[ls.field] ? currentLS[ls.field].committee_name : ''
        );
      } else if (['first_prime', 'staff', 'submitted_by'].includes(ls.field)) {
        text = parse(currentLS[ls.field] ? currentLS[ls.field].full_name : '');
      } else if (ls.field === 'contact_person') {
        text = currentLS['contact_person'] ? (
          <>
            {parse(currentLS['contact_person']['full_name'])}
            <br />
            {parse(currentLS['contact_person']['phone'])}
            <br />
            {parse(currentLS['contact_person']['email'])}
          </>
        ) : (
          ''
        );
      } else if (lsModelTextFields.includes(ls.field)) {
        currentLS[ls.field] = currentLS[ls.field]
          .replace(new RegExp('&lt;', 'gi'), match => '<')
          .replace(new RegExp('&gt;', 'gi'), match => '>');
        text =
          currentLS[ls.field] && currentLS[ls.field] !== 'undefined' ? (
            <pre className={styles['pre']}>{parse(currentLS[ls.field])}</pre>
          ) : (
            <pre className={styles['pre']}>N/A</pre>
          );
      } else if (
        ls.type === 'checkbox' ||
        ls.field === 'operational_requirement' ||
        ls.field === 'reintroduction'
      ) {
        text = currentLS[ls.field] ? 'Yes' : 'No';
      } else if (['overlapping_ls', 'related_ls'].includes(ls.field)) {
        text =
          currentLS[ls.field].length > 0
            ? currentLS[ls.field].map((ls_num, idx) => (
                <React.Fragment key={idx}>
                  <a
                    href={`${window.location.origin}/lsRequestList/${ls_num.ls_number}`}
                    onClick={e => {
                      e.preventDefault();
                      window.open(
                        `${window.location.origin}/lsRequestList/${ls_num.ls_number}`
                      );
                    }}>
                    {ls_num.ls_number}
                  </a>
                  {idx === currentLS[ls.field].length - 1 ? '' : ', '}
                </React.Fragment>
              ))
            : 'N/A';
      } else if (ls.field === 'associated_report_tracking') {
        text =
          currentLS[ls.field].length > 0
            ? currentLS[ls.field].map((report, idx) => (
                <React.Fragment key={idx}>
                  <a
                    href={`${window.location.origin}/opReqs/${report.id}`}
                    onClick={e => {
                      e.preventDefault();
                      window.open(
                        `${window.location.origin}/opReqs/${report.id}`
                      );
                    }}>
                    {report.title}
                  </a>
                  {idx === currentLS[ls.field].length - 1 ? '' : ', '}
                </React.Fragment>
              ))
            : 'N/A';
      } else {
        text = <div>{currentLS[ls.field]}</div>;
      }

      return (
        <fieldset
          className={`${styles['fieldset-styles']} ${
            fieldsToHighlight.includes(ls.field) && styles['highlight']
          }`}
          id={`${ls.field}-${isCurrent ? 'left' : 'right'}`}
          key={idx}>
          <legend className={styles['fieldset-legend-styles']}>
            {ls.title}:
          </legend>
          <span className={styles['fieldset-content-styles']}>{text}</span>
        </fieldset>
      );
    } else if (!excludedArray.includes(ls.field)) {
      return (
        <fieldset
          className={`${styles['fieldset-styles']} ${
            fieldsToHighlight.includes(ls.field) && styles['highlight']
          }`}
          id={`${ls.field}-${isCurrent ? 'left' : 'right'}`}
          key={idx}>
          <legend className={styles['fieldset-legend-styles']}>
            {ls.title}:
          </legend>
          <span className={styles['fieldset-content-styles']}>N/A</span>
        </fieldset>
      );
    }
    return null;
  });
  return displayLsPart;
};

/**
 * LsHistory component
 * @param {*} props : props passed down from parent component(s)
 * @returns LsHistory Component
 */
const LsHistory = props => {
  const [fieldsToHighlight, setFieldsToHighlight] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let { ls_num } = useParams();

  // useEffect hook for component did mount
  useEffect(() => {
    props.getLsRequestHistory(ls_num);
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  function compareArrays(first, second) {
    first.sort();
    second.sort();
    return first.reduce((acc, ele, idx) => {
      return ele === second[idx] && acc;
    }, true);
  }

  function compareVersions(currentLs, selectedLsHistory) {
    if (!(selectedLsHistory || currentLs)) {
      return;
    }
    const nonLeg = nonLegDivisionRoles.includes(props.userProfile.role);
    let filteredField = lsFieldList.filter(
      field => !(nonLeg && field.viewability === 'staffOnly')
    );
    let updatedFieldstoHighlight = fieldsToHighlight;
    for (let fieldObj of filteredField) {
      const field = fieldObj.field;
      const currentFieldValue = currentLs[field];
      const selectedFieldValue = selectedLsHistory[field];
      // check arrays for deep equality (if value is array and they are not equal length or have same objects)
      if (Array.isArray(currentFieldValue)) {
        if (Array.isArray(selectedFieldValue) && currentFieldValue.length) {
          if (
            !compareArrays(
              currentFieldValue.map(ele => ele.id),
              selectedFieldValue.map(ele => ele.id)
            )
          ) {
            updatedFieldstoHighlight.push(field);
          }
        }
      } else if (
        typeof currentFieldValue === 'object' &&
        currentFieldValue !== null
      ) {
        if (
          typeof selectedFieldValue === 'object' &&
          currentFieldValue !== null &&
          currentFieldValue.id !== selectedFieldValue.id
        ) {
          updatedFieldstoHighlight.push(field);
        } else if (typeof selectedFieldValue !== 'object') {
          updatedFieldstoHighlight.push(field);
        }
      } else if (currentFieldValue !== selectedFieldValue) {
        updatedFieldstoHighlight.push(field);
      }
    }
    setFieldsToHighlight(updatedFieldstoHighlight);
  }

  function handleHistorySelections(ls_rev, idx) {
    props.selectLsRevision(ls_rev, idx);
    compareVersions(props.currentLs, ls_rev);

    if (idx === 0) {
      setFieldsToHighlight([]);
    }
  }

  function resizeRows() {
    for (let field of LsFields) {
      if (document.getElementById(`${field.field}-left`)) {
        const left = document.getElementById(`${field.field}-left`);
        const right = document.getElementById(`${field.field}-right`);
        if (left.offsetHeight < right.offsetHeight) {
          left.style.height = `${right.offsetHeight}px`;
        } else {
          right.style.height = `${left.offsetHeight}px`;
        }
      }
    }
  }

  // const [scrollTop, scrollProps] = useScrollTop();
  let lsLastUpdated =
    props.currentLs && new Date(props.currentLs['updated_at']);
  let archives = props.ls_history.map((ls_rev, idx) => {
    let dateTime = new Date(ls_rev.revision_date);
    return (
      <div
        className={
          props.selectLsIndex === idx
            ? `${styles['selected']} ${styles['archive']}`
            : styles['archive']
        }
        id={`table-column-tooltip-${idx}`}
        data-bs-dismiss={screenWidth <= 924 && `offcanvas`}
        onClick={() => handleHistorySelections(ls_rev, idx)}
        key={idx}>
        <div>
          <div>{dateTime.toLocaleString()} (EST)</div>
          <div className={styles['edited-by']}>
            Edited by <span>{ls_rev.user}</span>
          </div>
        </div>
        {idx === 0 && (
          <div className={styles['current-version-text']}>Current</div>
        )}
        <UncontrolledTooltip
          placement='bottom'
          target={`table-column-tooltip-${idx}`}>
          Click to view
        </UncontrolledTooltip>
      </div>
    );
  });

  return (
    <div className='main-container d-flex'>
      {props.currentLs && props.ls_history.length > 1 && (
        <div className={styles['lsr-version-review-container']}>
          <div className='d-flex flex-row justify-content-between px-2'>
            {/* LSR top section with back button */}
            <Link
              className={styles['return-button']}
              to={`${window.location.pathname
                .split('/')
                .slice(0, -2)
                .join('/')}/${ls_num}`}>
              <ChevronCompactLeftIcon
                styleProp={{ verticalAlign: '-.225em' }}
              />
              Return to LS Requests Table
            </Link>
            {screenWidth <= 924 && (
              <>
                <button
                  className='btn'
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#revisionHistory'
                  aria-controls='revisionHistory'>
                  <ClockHistoryIcon />
                </button>
                <div
                  className={`offcanvas offcanvas-end ${styles['lsr-history-panel']}`}
                  data-bs-backdrop='false'
                  tabIndex='-1'
                  id='revisionHistory'
                  aria-labelledby='revisionHistoryLabel'>
                  <div className='offcanvas-header'>
                    <h5 className='offcanvas-title' id='offcanvasRightLabel'>
                      Revision History
                    </h5>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='offcanvas'
                      aria-label='Close'></button>
                  </div>
                  <div className='offcanvas-body p-0'>
                    <div className={styles['history-select-wrapper']}>
                      {archives}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <h1 className={styles2['archived-history-heading']}>
            LS #{ls_num} - {props.currentLs['ls_type']}
          </h1>
          <div className='text-center mb-3'>
            <span className='d-inline-block me-2'>
              {props.currentLs['staff']['full_name']}
            </span>
            <span className='d-inline-block'>
              {lsLastUpdated.toDateString()}&nbsp;
              <small>{lsLastUpdated.toLocaleTimeString()}</small>
            </span>
          </div>
          {/* END LSR top section with back button */}
          {/* Column header only for current and selected lsr */}
          {props.ls_history.length > 1 && (
            <>
              <div className='d-flex'>
                <h3
                  className={
                    props.selectedLsRevision && styles['border-right']
                  }>
                  Current Version
                </h3>
                {props.selectedLsRevision && props.selectLsIndex !== 0 && (
                  <h3
                    className={
                      props.selectedLsRevision && styles['border-left']
                    }>
                    Selected Version
                  </h3>
                )}
              </div>
              <div className={styles['ls-fields-container']}>
                <div className={styles['ls-sidebar-fields']}>
                  <LsInfoDisplay
                    currentLS={props.currentLs}
                    isCurrent={true}
                    version={true}
                    userProfile={props.userProfile}
                    getFileAndDownload={props.getFileAndDownload}
                    fieldsToHighlight={fieldsToHighlight}
                  />
                </div>
                {props.selectedLsRevision && props.selectLsIndex !== 0 && (
                  <div className={styles['ls-sidebar-fields']}>
                    <LsInfoDisplay
                      currentLS={props.selectedLsRevision}
                      isCurrent={false}
                      version={true}
                      userProfile={props.userProfile}
                      getFileAndDownload={props.getFileAndDownload}
                      fieldsToHighlight={fieldsToHighlight}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {/* END Column header only for current and selected lsr */}
        </div>
      )}
      {props.ls_history.length <= 1 && (
        <div className={styles['no-history-lsr']}>
          <img src='/img/page-history-hourglass.png' alt='Page History' />
          <h3>This LS Request has no further history.</h3>
          <Link
            // className={styles["return-button"]}
            to={`${window.location.pathname
              .split('/')
              .slice(0, -2)
              .join('/')}/${ls_num}`}>
            <ChevronCompactLeftIcon styleProp={{ verticalAlign: '-.225em' }} />
            Return to LS Requests Table
          </Link>
        </div>
      )}
      {props.ls_history.length > 1 && screenWidth > 924 && (
        <div className={styles['history-select-wrapper']}>
          <h5>Revision History</h5>
          {archives}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    currentLs: state.lsRequestsReducer.lsHistoryCurrent,
    ls_history: state.lsRequestsReducer.lsHistoryList,
    selectedLsRevision: state.lsRequestsReducer.selectedLsHistory,
    selectLsIndex: state.lsRequestsReducer.selectLsHistoryIndex,
  };
};

const mapDispatchToProps = {
  getLsRequestHistory,
  storeLsRequestHistory,
  selectLsRevision,
  getFileAndDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsHistory);
