import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/SubjectForm.module.scss';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { url } from '../../config';
import TagsDisplay from '../report-tracking/TagsDisplay';
import { useNavigate } from 'react-router-dom';
import * as actions from '../../actions/wikiPageAction';
import * as Yup from 'yup';

function withNavigation(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class SubjectForm extends Component {
  state = {
    clear: false,
    addTopic: false,
  };

  goBack = () => {
    this.props.navigate(-1);
  };

  componentDidMount() {
    //Load all the subjects to be used within the form
    this.props.loadAllSubjects(true);
    this.props.loadRootSubjects('order');
    this.props.storeLockStatus(false);

    //Clear title input on create's mount
    let path = window.location.pathname;
    let type = path.slice(path.lastIndexOf('/') + 1);

    if (type === 'create') {
      this.setState({ clear: true });
    }
  }

  componentDidUpdate(nextProps) {
    //Recalculate subject paths when the subjects are updated
    if (nextProps.all_subjects !== this.props.all_subjects) {
      let subjects = this.subjectTreeClawer(this.props.all_subjects, [], '');
      this.props.storeFlatSubjectTree(subjects);
    }
  }

  componentWillUnmount() {
    //Reset form and unlock any locked subject
    if (this.props.parentSubject && this.props.displaySubject > 0) {
      this.props.subjectUpdate(
        { id: this.props.displaySubject, user: false },
        false
      );
    }
    this.props.displaySubjectUpdate(-1);
    this.props.subjectFormFail('');
    this.props.subjectFormSuccess('');
  }

  //Errors in the subject creation fields
  errorCheck(errors) {
    if (errors.subject || errors.title) {
      return true;
    }
    return false;
  }

  //Get the specific subject of the input id
  getSubject(id) {
    let subject = {};
    if (id >= 0) {
      for (let i = 0; i < this.props.subject_tree.length; i++) {
        if (this.props.subject_tree[i].id === parseInt(id)) {
          subject = this.props.subject_tree[i];
          break;
        }
      }
    }

    return subject;
  }

  inputSubjectData(values, subject) {
    values.title = subject.title;
    values.linkedSubjects = this.props.subject_tree.filter(sub =>
      subject.connections.includes(sub.id)
    );
  }

  //Updates the subject being displayed
  onDisplaySubjectChange(id, values) {
    this.props.displaySubjectUpdate(id);
    this.props.subjectFormSuccess('');
  }

  //Checks if the current order is the same as the original order
  //Not needed for current functionality but it could be used for editing
  orderIsEqual() {
    let equal = true;
    this.props.children.forEach((child, idx) => {
      if (
        this.props.originalChildren[idx] &&
        child.id !== this.props.originalChildren[idx].id
      ) {
        equal = false;
      }
    });
    return equal;
  }

  //Update the new order of the subjects
  onOrderSubmit(values) {
    let path = window.location.pathname;
    let type = path.slice(path.lastIndexOf('/') + 1);

    let childIds = this.props.children.map(child => {
      return child.id;
    });

    let linkedIds = values.linkedSubjects.map(subject => {
      return subject.id;
    });

    //If in main subject
    if (this.props.displaySubject < 0) {
      //If in main subjects update the order of the existing main subjects and create the new subject
      childIds.forEach((id, index) => {
        if (id < 0) {
          this.props.createSubject(null, values.title, index, true, linkedIds);
        } else {
          let subject = this.getSubject(id);
          if (subject.order !== index + 1) {
            subject.order = index + 1;
            this.props.subjectUpdate({ id: id, order: index + 1 }, false);
          }
        }
      });

      //Reload sidebar subjects
      setTimeout(() => {
        this.props.loadRootSubjects('nav');
      }, 1000);
    } else {
      //Create or Update
      if (type === 'create') {
        this.props.createSubject(
          this.props.displaySubject,
          values.title,
          0,
          true,
          linkedIds
        );
      } else {
        this.props.subjectUpdate({
          id: this.props.displaySubject,
          title: values.title,
          connections: linkedIds,
          user: false,
        });
      }
    }
  }

  //Drag and drop functions
  onDragStart = (e, index, list) => {
    this.draggedItem = list[index];
    e.dataTransfer.effectAllowed = 'copyMove';
    e.dataTransfer.setData('text/html', e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
  };

  onDragOver = (e, index, list) => {
    const draggedOverItem = list[index];

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    e.dataTransfer.effectAllowed = 'grab';

    // filter out the currently dragged item
    let items = list.filter(item => item !== this.draggedItem);

    // add the dragged item after the dragged over item
    items.splice(index, 0, this.draggedItem);

    this.props.reorderChildSubjects(items);
  };

  onDragEnd = () => {
    this.draggedIdx = null;
  };

  onDragEnter = e => {
    e.dataTransfer.effectAllowed = 'grab';
  };

  onAddSelectedInArray = (selected, values) => {
    let duplicate = values.find(value => value.id === selected.id);
    if (duplicate === undefined) values.push(selected);
  };

  onDeleteTargetInArray = (targetIdx, values) => {
    values.splice(targetIdx, 1);
    this.setState({});
  };

  //Lock the currently selected subject
  lockSubject(lock) {
    if (this.props.displaySubject > 0) {
      this.props.subjectUpdate(
        { id: this.props.displaySubject, user: lock },
        lock
      );
    } else {
      this.props.storeLockStatus(lock);
    }
  }

  //Construct the path for every subject using the subject tree
  subjectTreeClawer(subjects, list, path) {
    if (subjects) {
      subjects.forEach(subject => {
        let pathSplit = path.split('/');
        if (pathSplit.length > 7) {
          pathSplit.splice(1, 2, '...');
        }
        if (!path || path.length) {
          list.push({
            title: subject.title,
            id: subject.id,
            path: pathSplit.join('/') + subject.title,
          });
        } else {
          list.push({
            title: subject.title,
            id: subject.id,
            path: pathSplit.join('/') + subject.title + '/',
          });
        }
        if (subject.children_data) {
          this.subjectTreeClawer(
            subject.children_data,
            list,
            pathSplit.join('/') + subject.title + '/'
          );
        }
      });
    }
    return list;
  }

  //Display Page
  render() {
    //Find the current form time and load the form accordingly
    let path = window.location.pathname;
    let type = path.slice(path.lastIndexOf('/') + 1);

    let options = this.props.subject_tree
      ? this.props.subject_tree.map((subject, idx) => {
          return (
            <option key={idx} value={subject.id}>
              {subject.path}
            </option>
          );
        })
      : '';

    //Contruct draggable subjects
    let children = '';
    if (this.props.children) {
      children = this.props.children.map((child, index) => (
        <div
          key={index}
          className={styles['drag']}
          onDragOver={e => this.onDragOver(e, index, this.props.children)}>
          <div
            className={`${styles['div-row']} ${styles['item-tab']}`}
            draggable
            onDragStart={e => {
              this.onDragStart(e, index, this.props.children);
            }}
            onDragEnter={this.onDragEnter}
            onDragEnd={this.onDragEnd}>
            <strong>{child.title}</strong>
          </div>
        </div>
      ));
    }

    //Select initial subject if Update Subject was clicked
    let initSubject = this.getSubject(this.props.displaySubject);
    let initTitle = this.props.displaySubject >= 0 ? initSubject.title : '';

    return (
      <div className={styles['page']}>
        {!this.props.isLoading && (
          <Formik
            initialValues={{
              subject: -1,
              title: initTitle,
              order: 1,
              linkedSubjects: [],
            }}
            validationSchema={SectionSchema}
            onSubmit={(values, actions) => {
              if (type !== 'delete') {
                //Create a copy of the children so as to not to affect the original
                this.onOrderSubmit(values);
              }

              actions.setSubmitting(false);
            }}
            render={({ values, touched, errors, handleBlur, handleChange }) => {
              //Clear old title when mounted
              if (this.state.clear) {
                values.title = '';
                this.setState({ clear: false });
              }

              return (
                <>
                  <div className='container py-4'>
                    <div className='row'>
                      <div className='col'>
                        <h1>{type[0].toUpperCase() + type.substr(1)} Topic</h1>
                      </div>
                    </div>
                  </div>
                  <div className={styles['form-wrapper']}>
                    <Form>
                      <div className='row mb-3'>
                        <div className='col-sm-12 col-lg-8'>
                          <label htmlFor='title'>
                            <strong>
                              {type === 'create' && 'Parent '}Topic
                            </strong>
                          </label>
                          {this.props.fail && (
                            <div className='alert alert-danger' role='alert'>
                              {this.props.fail}
                            </div>
                          )}
                          {this.props.success && (
                            <div className='alert alert-success' role='alert'>
                              {this.props.success}
                            </div>
                          )}
                          <select
                            disabled={this.props.parentSubject}
                            className='form-select'
                            aria-label='Parent Subject Select'
                            value={this.props.displaySubject}
                            onChange={e => {
                              this.onDisplaySubjectChange(
                                e.target.value,
                                values
                              );
                            }}
                            type='seslect'>
                            <option value={-1}>Main Topics</option>
                            {options}
                          </select>
                        </div>
                      </div>
                      {type === 'create' && (
                        <>
                          <div className='row mb-3'>
                            <div className='col-sm-12 col-lg-8'>
                              <label htmlFor='title'>
                                <strong>Title</strong>
                              </label>
                              <input
                                disabled={this.props.parentSubject}
                                className='form-control'
                                type='text'
                                name={'title'}
                                value={values.title}
                                onChange={handleChange}>
                                {touched['title'] && errors['title'] && (
                                  <div
                                    className='alert alert-danger'
                                    role='alert'>
                                    {errors['title']}
                                  </div>
                                )}
                              </input>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <div className='col-sm-12 col-lg-8'>
                              <label htmlFor='title'>
                                <strong>Linked Topics:</strong>
                              </label>
                              <TagsDisplay
                                reference={React.createRef()}
                                currentList={values.linkedSubjects}
                                onAddTarget={e => {
                                  this.onAddSelectedInArray(
                                    e,
                                    values.linkedSubjects
                                  );
                                }}
                                onDeleteTarget={e => {
                                  this.onDeleteTargetInArray(
                                    e,
                                    values.linkedSubjects
                                  );
                                }}
                                warningMessage='No subjects have been selected.'
                                param='path'
                                dropdownList={this.props.subject_tree}
                              />
                            </div>
                          </div>
                          {this.props.displaySubject < 0 && (
                            <button
                              type='button'
                              className='submit-button'
                              style={{ height: '100%' }}
                              disabled={
                                this.props.parentSubject === 0 ||
                                errors.title ||
                                !values.title ||
                                this.state.addTopic
                              }
                              onClick={e => {
                                e.preventDefault();
                                let newArray = JSON.parse(
                                  JSON.stringify(this.props.children)
                                );
                                if (this.props.fail) {
                                  newArray.pop();
                                  this.props.subjectFormFail('');
                                }
                                newArray.push({ id: -1, title: values.title });
                                this.props.reorderChildSubjects(newArray);
                                this.setState({ addTopic: true });
                                this.lockSubject(true);
                              }}>
                              Add Topic
                            </button>
                          )}
                          {this.props.displaySubject > 0 && (
                            <button
                              className='submit-button'
                              disabled={
                                this.props.parentSubject === 0 ||
                                errors.title ||
                                !values.title
                              }
                              onClick={e => {
                                e.stopPropagation();
                              }}
                              type='submit'>
                              Save Topic
                            </button>
                          )}
                          <button
                            type='button'
                            className='cancel-button-text'
                            onClick={e => {
                              e.preventDefault();
                              this.goBack();
                            }}>
                            Cancel
                          </button>
                        </>
                      )}
                      {type === 'edit' && (
                        <button
                          type='button'
                          className='submit-button'
                          style={{ height: '100%' }}
                          disabled={this.props.parentSubject}
                          onClick={e => {
                            e.preventDefault();
                            this.props.subjectFormFail('');
                            this.props.subjectFormSuccess('');

                            if (this.props.displaySubject > 0) {
                              axios
                                .get(
                                  `${url}/api/wiki/subjects/${this.props.displaySubject}/`
                                )
                                .then(response => {
                                  let subject = response.data;
                                  this.inputSubjectData(values, subject);
                                  this.lockSubject(true);
                                })
                                .catch(error => {
                                  console.log(error);
                                  this.props.pageFormFail(
                                    `Failed to get page, ${error}`
                                  );
                                });
                            } else {
                              this.lockSubject(true);
                            }
                          }}>
                          Edit Topic
                        </button>
                      )}
                      {type === 'delete' && (
                        <>
                          <button
                            type='button'
                            className='btn btn-danger'
                            disabled={this.props.displaySubject < 1}
                            onClick={e => {
                              e.preventDefault();
                              this.props.subjectFormFail('');
                              this.lockSubject(true, e);
                            }}>
                            Delete Topic
                          </button>
                          {
                            <div
                              className='modal'
                              tabIndex='-1'
                              isopen={this.props.deleteModal}
                              backdrop='static'
                              toggle={e => {
                                e.preventDefault();
                                this.props.deleteSubjectModal(false);
                                this.lockSubject(false);
                              }}>
                              <div className='modal-dialog'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h5 className='modal-title'>
                                      Delete Topic
                                    </h5>
                                    <button
                                      type='button'
                                      className='btn-close'
                                      data-bs-dismiss='modal'
                                      aria-label='Close'></button>
                                  </div>
                                  <div className='modal-body'>
                                    Are you sure you want to delete the topic
                                    {this.props.currentSubject.title}? You can't
                                    delete a topic that isn't empty.
                                  </div>
                                  <div className='modal-footer'>
                                    <button
                                      type='button'
                                      className='btn btn-danger'
                                      onClick={e => {
                                        e.preventDefault();
                                        this.props.deleteSubject(
                                          this.props.displaySubject
                                        );
                                        this.props.deleteSubjectModal(false);
                                      }}>
                                      Delete
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-danger'
                                      onClick={e => {
                                        e.preventDefault();
                                        this.props.deleteSubjectModal(false);
                                        this.lockSubject(false);
                                      }}>
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </>
                      )}
                    </Form>
                    {this.props.parentSubject && type !== 'delete' && (
                      <Form>
                        {type === 'edit' &&
                          this.props.displaySubject >= 0 &&
                          values.title && (
                            <>
                              <div className='row mb-3'>
                                <div className='col-sm-12 col-lg-8'>
                                  <label htmlFor='title'>Title</label>
                                  <input
                                    className='form-control me-3'
                                    type='text'
                                    name={'title'}
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyUp={e => {
                                      if (e.key === 'Enter') e.preventDefault();
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-sm-12 col-lg-8'>
                                  <label htmlFor='title'>Linked Topics</label>
                                  <TagsDisplay
                                    reference={React.createRef()}
                                    currentList={values.linkedSubjects}
                                    onAddTarget={e => {
                                      this.onAddSelectedInArray(
                                        e,
                                        values.linkedSubjects
                                      );
                                    }}
                                    onDeleteTarget={e => {
                                      this.onDeleteTargetInArray(
                                        e,
                                        values.linkedSubjects
                                      );
                                    }}
                                    warningMessage='No subjects have been selected.'
                                    param='path'
                                    dropdownList={this.props.subject_tree.filter(
                                      sub => {
                                        return (
                                          sub.id !==
                                          parseInt(this.props.displaySubject)
                                        );
                                      }
                                    )}
                                  />
                                  {touched['title'] && errors['title'] && (
                                    <div
                                      className='alert alert-danger'
                                      role='alert'>
                                      {errors['title']}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        {this.props.displaySubject < 0 && (
                          <>
                            <div className={styles['div-row']}>
                              <h3 className={styles['inputMargin']}>
                                Order Topic
                              </h3>
                              <div>(Drag and Drop)</div>
                            </div>
                            <br />
                            {this.props.children &&
                            this.props.children.length > 0 ? (
                              <div>{children}</div>
                            ) : (
                              <div />
                            )}
                          </>
                        )}
                        <>
                          <button
                            // className={`btn btn-primary ${styles["row-button"]}`}
                            className='submit-button'
                            disabled={!this.props.parentSubject}
                            onClick={e => {
                              e.stopPropagation();
                              if (this.props.displaySubject < 0) {
                                e.preventDefault();
                                this.onOrderSubmit(values);
                              }
                            }}
                            type='submit'>
                            Save Topic
                          </button>
                          <button
                            // className={`btn btn-primary ${styles["row-button"]}`}
                            className='cancel-button-text'
                            onClick={e => {
                              e.preventDefault();
                              this.props.reorderChildSubjects(
                                this.props.originalChildren
                              );
                              this.setState({ addTopic: false });
                              this.lockSubject(false, e);
                              this.goBack();
                            }}
                            disabled={!this.props.parentSubject}>
                            Cancel
                          </button>
                        </>
                      </Form>
                    )}
                  </div>
                </>
              );
            }}
          />
        )}
        {this.props.isLoading && (
          <div className='loading-container'>
            <img
              className='loading-image'
              src='/img/newLoadingAnimation.gif'
              alt='Loading animation'
              align='middle'
            />
            <h3>
              Loading <span className='dot1'>.</span>
              <span className='dot2'>.</span>
              <span className='dot3'>.</span>
            </h3>
          </div>
        )}
      </div>
    );
  }
}

const SectionSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'The title has to be greater than 3 characters')
    .max(50, 'Too Long!')
    .required('The topic title is required'),
});

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    permissions: state.userReducer.userProfile.permissions.wiki,
    displaySubject: state.subjectFormReducer.displaySubject,
    currentSubject: state.subjectFormReducer.currentSubject,
    all_subjects: state.subjectFormReducer.all_subjects,
    subject_tree: state.subjectFormReducer.subject_tree,
    rootSubjects: state.wikiReducer.rootSubjects,
    parentSubject: state.subjectFormReducer.parentSubject,
    children: state.subjectFormReducer.children,
    originalChildren: state.subjectFormReducer.originalChildren,
    subject: state.subjectFormReducer.subject,
    title: state.subjectFormReducer.title,
    fail: state.subjectFormReducer.fail,
    success: state.subjectFormReducer.success,
    deleteModal: state.subjectFormReducer.deleteModal,
    isLoading: state.subjectFormReducer.isLoading,
  };
};

export default connect(mapStateToProps, actions)(withNavigation(SubjectForm));
