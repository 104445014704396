import { citationRequestActions } from '../actions/citationRequestAction';

const initialState = {
  openCitationRequestPopupStatus: false,
  allCitationRequestsForDays: [],
  allCitationRequests: [],
  filteredCitationRequests: [],
  newCitationStatus: null,
  updateCitationStatus: null,
  citationRequestSearchString: '',
  citationRequestSortedBy: 'council_member',
  citationRequestSortDirection: 'asc',
  citationRequestFilter: 'all',
  selectedCitationRequest: {},
  currentDisplay: 'list',
  selectedDayCitationRequests: [],
  savedProgress: null,
  cosigners: [],
  isLoading: false,
  isCalendarLoading: false,
  nextAPI: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case citationRequestActions.CHANGE_DISPLAY:
      return { ...state, currentDisplay: action.displayString };
    case citationRequestActions.SAVE_CITATION_REQUESTS_CURRENT_MONTH_AND_YEAR:
      return { ...state, allCitationRequestsForDays: action.citationRequests };
    case citationRequestActions.CHANGE_CITATION_REQUEST_POPUP_WINDOW:
      return { ...state, openCitationRequestPopupStatus: action.status };
    case citationRequestActions.SAVE_PROGRESS:
      return { ...state, savedProgress: action.citationRequest };
    case citationRequestActions.SAVE_TARGET_CITATION_REQUESTS:
      return { ...state, selectedDayCitationRequests: action.citationRequests };
    case citationRequestActions.SELECT_CITATION_REQUEST:
      return {
        ...state,
        selectedCitationRequest: { ...action.citationRequest },
      };
    case citationRequestActions.GET_ALL_CITATION_REQUESTS:
      return {
        ...state,
        nextAPI: action.nextApi,
        citationRequestSortedBy: action.params.sort_by,
        citationRequestSortDirection: action.params.sort_direction,
        citationRequestFilter: action.params.filter,
        citationRequestSearchString: action.params.search_term,
      };
    case citationRequestActions.SAVE_ALL_CITATION_REQUESTS:
      const allCitationRequests = action.nextAPI
        ? {
            ...state,
            allCitationRequests: [
              ...state.allCitationRequests,
              ...action.citationRequests,
            ],
          }
        : {
            ...state,
            filteredCitationRequests: [...action.citationRequests],
          };
      return allCitationRequests;
    case citationRequestActions.FILTER_CITATION_REQUESTS:
      const filteredCitationRequests = action.nextAPI
        ? {
            ...state,
            filteredCitationRequests: [
              ...state.filteredCitationRequests,
              ...action.citationRequests,
            ],
          }
        : {
            ...state,
            filteredCitationRequests: [...action.citationRequests],
          };
      return filteredCitationRequests;
    case citationRequestActions.SET_SEARCH_STRING:
      return { ...state, citationRequestSearchString: action.searchString };
    case citationRequestActions.IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case citationRequestActions.IS_CALENDAR_LOADING:
      return { ...state, isCalendarLoading: action.isLoading };
    case citationRequestActions.CREATE_NEW_CITATION_REQUEST_STATUS:
      return { ...state, newCitationStatus: action.status };
    case citationRequestActions.UPDATE_CITATION_REQUEST_STATUS:
      return { ...state, updateCitationStatus: action.status };
    case citationRequestActions.APPLY_SORT:
      return {
        ...state,
        citationRequestSortedBy: action.sortBy,
        citationRequestSortDirection: action.sortDirection,
      };
    case citationRequestActions.SAVE_NEXT_API:
      return { ...state, nextAPI: action.nextApi };
    default:
      return state;
  }
}
