import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Login from './components/user/Login';
import { connect } from 'react-redux';
import { isChrome, isFirefox, isMobile } from 'react-device-detect';
import Homepage from './containers/Homepage';
import LsRequestsList from './components/ls-requests/LsRequestsList';
import NavBar from './components/NavBar';
import Wiki from './containers/Wiki';
import ReportTracking from './containers/ReportTracking';
import UserProfile from './components/user/myProfile/UserProfile';
import PasswordForm from './components/user/PasswordForm';
import BackIcon from './components/BackIcon';
import NotificationsHistory from './components/user/myProfile/NotificationsHistory';
import CentralStaff from './components/user/myProfile/CentralStaff';
import * as actions from './actions/userAction';
import ReviewList from './components/user/myTasks/ReviewList';
import ReviewPage from './components/user/myTasks/ReviewPage';
import AgencyForm from './components/report-tracking/AgencyView';
import { logout } from './utils/helper';
import { attorneyRoles, legDivisionRoles } from './services/constants';
import { IdleTimerProvider } from 'react-idle-timer';
import styles from './styles/App.module.scss';
import { Alert } from 'reactstrap';
import MyTasks from './containers/MyTasks';
import ProclamationRequest from './containers/ProclamationRequest';
import CitationRequests from './containers/CitationRequests';
import { PrivateRoute } from './utils/router-helper';
import UserManual from './components/UserManual';
import VideoTutorials from './components/VideoTutorials.js';
import Sidebar from './components/Sidebar';
import version from '../package.json';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';
import '../node_modules/bootstrap/scss/bootstrap.scss';
import LsHistory from './components/ls-requests/LsHistory';
import LsDupSearch from './components/ls-requests/LsDupSearch';
// import LsRequestStatus from './components/ls-requests/LsRequestStatus';
import SeatingChart from './components/user/myProfile/SeatingChart';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayBackIcon: false,
      isIE: false,
      dismissedWarning:
        document.cookie.indexOf('password_warning_dismissed=true') !== -1
          ? true
          : false,
      isMenuOpen: true,
      windowSize: window.innerWidth,
    };
    this.idleTimer = 60000 * 60; // 60 minutes
    this.onAction = e => {};
    this.onActive = e => {};
    this.onIdle = e => {
      logout();
    };
    this.acceptWarning = e => {
      this.setState({ dismissedWarning: true });
      let exp = new Date();
      exp.setMinutes(59);
      exp.setHours(23);
      exp.setSeconds(59);
      document.cookie =
        'password_warning_dismissed=true;expires=' + exp + ';path=/';
    };
    this.toggleMainMenu = () => {
      this.setState(
        prevState => ({ isMenuOpen: !prevState.isMenuOpen }),
        () => {
          let pixels = this.state.isMenuOpen ? '0px' : '-230px';
          document.getElementById(styles['sidebar']).style.marginLeft = pixels;
        }
      );
    };
  }

  resizeHandler = () => {
    const { isMenuOpen, windowSize } = this.state;
    let closeMenu = isMenuOpen;
    // sidebar open/close logic for citations/procs
    if (
      window.location.pathname === '/citation-requests' ||
      window.location.pathname === '/proclamation-requests'
    ) {
      if (
        window.innerWidth < 1124 &&
        // window.innerWidth > 894 &&
        window.innerWidth < windowSize &&
        isMenuOpen
      ) {
        closeMenu = false;
        // Open sidebar only at 900px breakpoint, when browser size in increasing, and the sidebar is already closed
      } else if (
        window.innerWidth >= 1124 &&
        window.innerWidth >= windowSize &&
        !isMenuOpen
      ) {
        closeMenu = true;
      }
    } else {
      // Close side bar only at 900px breakpoint, when browser size is decreasing, and the sidebar is already open
      if (
        window.innerWidth < 900 &&
        window.innerWidth < windowSize &&
        isMenuOpen
      ) {
        closeMenu = false;
        // Open sidebar only at 900px breakpoint, when browser size in increasing, and the sidebar is already closed
      } else if (
        window.innerWidth >= 900 &&
        window.innerWidth >= windowSize &&
        !isMenuOpen
      ) {
        closeMenu = true;
      }
    }
    this.setState({ isMenuOpen: closeMenu, windowSize: window.innerWidth });
  };
  // Check if the user has authenticated token in local storage
  // If the token is expired, end user will require to login using username and pwd
  // If the token is valid, user profile info will be stored
  componentDidMount() {
    if ((isChrome || isFirefox) && !isMobile) {
      // No need to run this block is user isn't using an acceptable browser
      if (sessionStorage.getItem('token')) {
        //Try to parse the token
        try {
          const obj = JSON.parse(sessionStorage.getItem('token'));
          this.props.loggingInUsingToken(obj.token, obj.type);
        } catch (e) {
          //If you fail the token parse delete the token and refresh the page
          logout();
        }
      } else {
        this.props.checkingLoginStatus('fail');
        this.props.gettingErrorMessage('');
      }
    }
    window.addEventListener('resize', this.resizeHandler);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  render() {
    if (!(isChrome || isFirefox) || isMobile) {
      return (
        <div
          className='container-fluid'
          style={{
            backgroundImage: 'linear-gradient(180deg, #9894FC, #FFF)',
            height: '100vh',
          }}>
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <div className='browser-message' style={{ marginTop: '7em' }}>
                <h1 style={{ padding: '5px 10px' }}>
                  {isMobile
                    ? 'This site is currently not compatible with mobile devices.'
                    : 'This site is not compatible with your browser.'}
                </h1>
                <p style={{ fontSize: '1.2rem', padding: '5px 10px' }}>
                  {isMobile
                    ? 'Please use a Chrome or Firebox browser on your desktop.'
                    : 'Please use Chrome or Firefox to access this site.'}
                </p>
                <p style={{ padding: '0px 10px' }}>
                  If you believe you are seeing this message in error, please
                  contact the
                  <a
                    style={{ textDecoration: 'none' }}
                    href='mailto:webdevelopmentunit@council.nyc.gov'>
                    Web Development Unit
                  </a>
                  .
                </p>
              </div>
              <div
                className='footer-lockup'
                style={{
                  bottom: '0',
                  left: '0',
                  margin: '20px auto',
                  padding: '0px 10px',
                  position: 'absolute',
                  right: '0',
                }}>
                <img
                  src='./img/Logo/iwomm logo (login).png'
                  alt='IWOMM logo'
                  style={{
                    maxWidth: '200px',
                    marginBottom: '20px',
                    width: '90%',
                  }}
                />
                <p>
                  IWOMM v {version.version} <br />
                  <small>
                    Powered by New York City Council's Web Development Unit
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
    const { userLoginStatus, userProfile, userLoading } = this.props;
    let dateChange = new Date(userProfile.password_last_changed);
    let dateExpire = new Date(
      dateChange.getFullYear(),
      dateChange.getMonth() + 3,
      dateChange.getDate()
    );
    let dateNow = new Date();
    let timeDiff = dateExpire.getTime() - dateNow.getTime();
    let dateDiff = timeDiff / (1000 * 3600 * 24);
    //For logged in routes
    if (userLoginStatus === 'success' && !userLoading) {
      return (
        <IdleTimerProvider
          timeout={this.idleTimer}
          onPresenceChange={this.onPresenceChange}
          onPrompt={this.onPrompt}
          onIdle={this.onIdle}
          onActive={this.onActive}
          onAction={this.onAction}>
          <Router onUpdate={() => window.scrollTo(0, 0)}>
            <div className={styles['wrapper']}>
              {!window.location.pathname.includes('print') &&
                !window.location.pathname.includes('LsDupSearch') && (
                  <Sidebar isOpen={this.state.isMenuOpen} />
                )}
              <div id={styles['main']}>
                {!window.location.pathname.includes('print') && (
                  <>
                    <NavBar
                      menuOpen={this.state.isMenuOpen}
                      toggleMainMenu={this.toggleMainMenu}
                    />
                    {dateDiff <= 14 && !this.state.dismissedWarning && (
                      <div id={styles['password-warning']}>
                        <Alert color='danger' style={{ borderRadius: '0' }}>
                          Your password will expire in{' '}
                          <strong>{Math.ceil(dateDiff)}</strong> day(s).&nbsp;
                          <Link
                            onClick={this.acceptWarning}
                            style={{
                              textDecoration: 'underline',
                              color: '#721C24',
                            }}
                            to='/changePassword'>
                            Click here update your password!
                          </Link>
                          <span
                            onClick={this.acceptWarning}
                            style={{ cursor: 'pointer', float: 'right' }}>
                            <strong>Dismiss</strong>
                          </span>
                        </Alert>
                      </div>
                    )}
                    {/* <BackIcon /> */}
                  </>
                )}
                <Routes>
                  <Route exact path='/' element={<Homepage />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/profile' element={<UserProfile />} />
                  <Route
                    path='/notificationHistory'
                    element={<NotificationsHistory />}
                  />
                  <Route path='/staff/:id?' element={<CentralStaff />} />
                  <Route path='/myTasks/*' element={<MyTasks />} />
                  <Route path='/userManual' element={<UserManual />} />
                  <Route path='/video-tutorials' element={<VideoTutorials />} />
                  <Route
                    path='/changePassword'
                    element={
                      <PasswordForm
                        {...this.props}
                        needCurrentPassword={true}
                      />
                    }
                  />
                  <Route
                    path='/seating'
                    exact
                    element={
                      <PrivateRoute condition={userProfile.desk_number}>
                        <SeatingChart />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/lsRequestList/:ls_num?'
                    exact
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.lsRequest_api.view_lsrequest
                        }>
                        <LsRequestsList />
                      </PrivateRoute>
                    }
                  />
                  {/* <Route
                    path='/lsRequestStatus'
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.lsRequest_api
                            .approve_status_change
                        }>
                        <LsRequestStatus />
                      </PrivateRoute>
                    }
                  /> */}
                  <Route
                    path='/lsRequestList/LsDupSearch/:id?'
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.lsRequest_api.view_lsrequest
                        }>
                        <LsDupSearch />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/lsRequestList/:ls_num/history'
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.lsRequest_api.view_lsrequest
                        }>
                        <LsHistory />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/opReqs/:id?'
                    exact
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.reportTracking.view_report &&
                          [...attorneyRoles, ...legDivisionRoles].includes(
                            userProfile.role
                          )
                        }>
                        <ReportTracking />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/proclamation-requests'
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.proclamationRequest
                            .view_proclamationrequest
                        }>
                        <ProclamationRequest />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/citation-requests'
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.citationRequest
                            .view_citationrequest
                        }>
                        <CitationRequests />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/CLEX/*'
                    element={
                      <PrivateRoute
                        condition={userProfile.permissions.wiki.view_subject}>
                        <Wiki />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/myTasks/myCLEXReviews'
                    exact
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.wiki.view_pagereview
                        }>
                        <ReviewList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path='/myTasks/myCLEXReviews/:id'
                    element={
                      <PrivateRoute
                        condition={
                          userProfile.permissions.wiki.view_pagereview
                        }>
                        <ReviewPage />
                      </PrivateRoute>
                    }
                  />
                  {/* <Route condition={} path="/billDrafting" component={BillDraft} /> */}
                  <Route path='*' element={<Navigate to='/' replace />} />
                </Routes>
              </div>
            </div>
          </Router>
        </IdleTimerProvider>
      );
    } else if (userLoginStatus === 'agency-success') {
      return (
        <IdleTimerProvider
          timeout={this.idleTimer}
          onPresenceChange={this.onPresenceChange}
          onPrompt={this.onPrompt}
          onIdle={this.onIdle}
          onActive={this.onActive}
          onAction={this.onAction}>
          <AgencyForm />
        </IdleTimerProvider>
      );
    } else if (userLoading) {
      return (
        <div className='loading-container'>
          <img
            className='loading-logo'
            src='/img/Logo/iwomm logo (login).png'
            width='120px'
            alt='IWOMM-logo'
          />
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      );
    } else if (
      userLoginStatus === 'forgotPassword' ||
      userLoginStatus === 'register'
    ) {
      return (
        <Login
          forgotPassword={true}
          location={window.location.pathname}
          {...this.props}
        />
      );
    } else if (userLoginStatus === 'fail') {
      //For routes when you aren't logged in
      const location = window.location.pathname + window.location.search;
      return (
        <Router>
          <div>
            <Routes>
              <Route
                exact
                path='/'
                element={<Login {...this.props} location={location} />}
              />

              <Route path='/login' component={Login} />
              <Route
                path='/changePassword'
                element={<PasswordForm {...this.props} reset={true} />}
              />
              <Route path='*' element={<Navigate to='/' replace />} />
            </Routes>
          </div>
        </Router>
      );
    } else {
      return;
    }
  }
}

const mapStateToProps = state => {
  return {
    userLoginStatus: state.userReducer.userLoginStatus,
    userLoading: state.userReducer.loading,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(App);
