import React, { Component } from 'react';
import Dashboard from './Dashboard';

class Homepage extends Component {
  // Homepage components to different sections within the system
  render() {
    return <Dashboard />;
  }
}

export default Homepage;
