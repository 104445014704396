import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import styles from '../styles/proclamation-request/ProclamationTable.module.scss';
import Calendar from '../components/proclamation-request/Calendar';
import ProclamationTable from '../components/proclamation-request/ProclamationTable';
import {
  getProcRequestCurrentMonthAndYear,
  changeProcRequestDisplay,
  changeProcRequestPopupWindow,
  getProcRequests,
} from '../actions/procRequestAction';
import { generateSafeDateFromMonthYear } from '../utils/helper';

const ProclamationRequestContainer = props => {
  // const [displayMode, setDisplayMode] = useState("calendar")
  const [isCalendarFullScreen, setIsCalendarFullScreen] = useState(true);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth) // screen width not used yet, will be later
  const [rowContainerHeight, setRowContainerHeight] = useState('40vh');

  // const toggleCalendarFullScreen = () => {
  //   setIsCalendarFullScreen(!isCalendarFullScreen)
  // }

  const resizeHandler = () => {
    const { innerWidth } = window;
    // setScreenWidth(innerWidth)

    if (innerWidth > 854) {
      if (isCalendarFullScreen) {
        setIsCalendarFullScreen(false);
        setRowContainerHeight('');
      }
    } else {
      if (!isCalendarFullScreen) {
        setIsCalendarFullScreen(true);
        setRowContainerHeight('40vh');
      }
    }
  };
  useEffect(() => {
    let currentDate;
    if (localStorage.getItem('procRequestSelectedMonth') !== null) {
      currentDate = generateSafeDateFromMonthYear(
        localStorage.getItem('procRequestSelectedMonth')
      );
    } else {
      currentDate = new Date();
    }

    props.getProcRequestCurrentMonthAndYear(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
    props.getProcRequests(props.nextAPI, props.params);
    const trackerId = queryString.parse(window.location.search).id;
    if (trackerId) {
      props.openNotificationDetailReport(trackerId);
      props.changeReportTrackingDisplay('detail');
    }
    // window.addEventListener("resize", ()=>{this.setState({screenWidth:  window.innerWidth})});
    window.addEventListener('resize', resizeHandler);
    resizeHandler(); // call it initially to set the proper state

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  // const toCalendarView = () => {
  //   setDisplayMode('calendar')
  // }

  // const showProclamationTable = !isCalendarFullScreen || screenWidth > 854;
  return (
    <div className='main-container'>
      <div
        style={{
          display: 'flex',
          flexDirection: window.innerWidth > 896 ? 'row' : 'column',
        }}>
        <div className='col flex-grow-1 p-0'>
          <Calendar
            // toggleCalendarFullScreen={toggleCalendarFullScreen}
            isCalendarFullScreen={isCalendarFullScreen}
            rowContainerHeight={rowContainerHeight}
          />
        </div>
        <div className={`col flex-grow-0 p-0 ${styles['proc-list-container']}`}>
          <ProclamationTable
            changeProcRequestDisplay={props.changeProcRequestDisplay}
            changeProcRequestPopupWindow={props.changeProcRequestPopupWindow}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    nextAPI: state.procRequestReducer.nextAPI,
    params: {
      sort_direction: state.procRequestReducer.procRequestSortDirection,
      sort_by: state.procRequestReducer.procRequestSortedBy,
      filter: state.procRequestReducer.procRequestFilter,
    },
  };
};
const mapDispatchToProps = {
  getProcRequestCurrentMonthAndYear,
  changeProcRequestDisplay,
  changeProcRequestPopupWindow,
  getProcRequests,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProclamationRequestContainer);
