import { combineReducers } from 'redux';
import lsRequestsReducer from './lsRequestsReducer';
import reportTrackingReducer from './reportTrackingReducer';
import userReducer from './userReducer';
import pageFormReducer from './pageFormReducer';
import pageHistoryReducer from './pageHistoryReducer';
import subjectFormReducer from './subjectFormReducer';
import reviewListReducer from './reviewListReducer';
import tagFormReducer from './tagFormReducer';
import wikiPageReducer from './wikiPageReducer';
import wikiSubjectReducer from './wikiSubjectReducer';
import wikiTagReducer from './wikiTagReducer';
import wikiSearchResultsReducer from './wikiSearchResultsReducer';
import wikiReducer from './wikiReducer';
import fileDownloadReducer from './fileDownloadReducer';
import myTasksReducer from './myTasksReducer';
import procRequestReducer from './procRequestReducer';
import citationRequestReducer from './citationRequestReducer';
import eventReducer from './eventReducer';

export default combineReducers({
  lsRequestsReducer,
  reportTrackingReducer,
  userReducer,
  pageFormReducer,
  pageHistoryReducer,
  reviewListReducer,
  subjectFormReducer,
  tagFormReducer,
  wikiPageReducer,
  wikiSubjectReducer,
  wikiTagReducer,
  wikiSearchResultsReducer,
  wikiReducer,
  fileDownloadReducer,
  myTasksReducer,
  procRequestReducer,
  citationRequestReducer,
  eventReducer,
});
