import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  all_subjects: 0,
  subject_tree: {},
  subject: -1,
  pages: 0,
  page: -1,
  committees: [],
  edit_lock: false,
  title: '',
  description: '',
  sections: [],
  fail: '',
  success: '',
  deleteModal: false,
  submit: false,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.UPDATE_SECTIONS:
      return { ...state, sections: action.sections };

    case wikiPageActions.LOAD_ALL_SUBJECTS:
      return { ...state };

    case wikiPageActions.STORE_ALL_SUBJECTS:
      return { ...state, all_subjects: action.all_subjects };

    case wikiPageActions.STORE_FLAT_SUBJECT_TREE:
      return { ...state, subject_tree: action.flatTree };

    case wikiPageActions.STORE_SELECT_PAGES:
      return { ...state, pages: action.pages };

    case wikiPageActions.STORE_ALL_PAGES:
      return { ...state, pages: action.pages.results };

    case wikiPageActions.STORE_EDIT_LOCK:
      return { ...state, edit_lock: action.lock };

    case wikiPageActions.STORE_CURRENT_PAGE:
      return { ...state, page: action.page };

    case wikiPageActions.STORE_PAGE_COMMITTEES:
      return { ...state, committees: action.committees };

    case wikiPageActions.PAGE_FORM_FAIL:
      return { ...state, fail: action.message };

    case wikiPageActions.PAGE_SUBMIT_STATUS:
      return { ...state, submit: action.status };

    case wikiPageActions.DELETE_PAGE_MODAL:
      return { ...state, deleteModal: action.display };

    case wikiPageActions.PAGE_FORM_SUCCESS:
      return { ...state, success: action.message };

    case wikiPageActions.PAGE_FORM_LOAD:
      return { ...state, isLoading: action.status };

    default:
      return state;
  }
}
