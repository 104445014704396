import React, { useState, useEffect } from 'react';
import {
  loadWikiSearchResults,
  storeUrlSearchParams,
} from '../../actions/wikiPageAction';
import { Link } from 'react-router-dom';
import styles from '../../styles/wiki/SubjectPage.module.scss';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const WikiSearchResults = props => {
  const {
    loading,
    loadWikiSearchResults,
    match,
    pages,
    storeUrlSearchParams,
    subjects,
    tags,
  } = props;

  const [keywords, setKeywords] = useState(useParams().keywords);

  const [filters, setFilters] = useState(useParams().filters);

  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    let params = { keywords: keywords, filters: filters };
    storeUrlSearchParams(keywords, filters);
    setKeywords(params.keywords);
    setFilters(params.filters);
    loadWikiSearchResults(params);
  }, [filters, keywords, loadWikiSearchResults, storeUrlSearchParams]);

  const pageList =
    pages && pages.length > 0
      ? pages.map((page, idx) => {
          let descript = page.description.replace(/(&lt;)/gi, '<');
          descript = descript.replace(/(&gt;)/gi, '>');
          descript = descript.replace(/(<([^>]+)>)/gi, '');
          descript = descript.substring(0, 250) + '...';

          return (
            <div className='col-sm-12 py-2' key={idx}>
              <div className={`${styles['card-body-gray']} card h-100`}>
                <div className='card-body'>
                  <h4 className='card-title'>{page.title}</h4>
                  <p className='card-text'>{descript}</p>
                  <Link
                    className='btn btn-outline-light'
                    to={`/CLEX/subject/${page.subject[0]}/page/${page.id}`}>
                    View more
                  </Link>
                </div>
              </div>
            </div>
          );
        })
      : '';

  const subjectList =
    subjects && subjects.length > 0
      ? subjects.map((subject, idx) => {
          return (
            <div className='col-sm-4 py-2' key={idx}>
              <div className='card h-100'>
                <Link
                  className='btn btn-dark'
                  to={`/CLEX/subject/${subject.id}`}>
                  {subject.title}
                </Link>
              </div>
            </div>
          );
        })
      : '';

  const tagList =
    tags && tags.length > 0
      ? tags.map((tag, idx) => {
          return (
            <div className='col-sm-4 py-2' key={idx}>
              <div className='card h-100'>
                <Link className='btn btn-dark' to={`/CLEX/tag/${tag.id}`}>
                  {tag.name}
                </Link>
              </div>
            </div>
          );
        })
      : '';

  return (
    <div className={styles['page']}>
      <div className={styles['search-bar-and-title-wrapper']}>
        <h2>Search Results</h2>
        <div className={styles['search-bar-container']}>Search: {keywords}</div>
      </div>
      <div className={styles['search-results-container']}>
        {subjectList && (
          <div className={styles['search-results-wrapper']}>
            <h3>
              Topics
              <span className={styles['results-count']}>{subjects.length}</span>
            </h3>
            {subjectList}
          </div>
        )}
        {tagList && (
          <div className={styles['search-results-wrapper']}>
            <h3>
              Tags
              <span className={styles['results-count']}>{tags.length}</span>
            </h3>
            {tagList}
          </div>
        )}
        {pageList && (
          <div className={styles['search-results-wrapper']}>
            <h3>
              Pages
              <span className={styles['results-count']}>{pages.length}</span>
            </h3>
            {pageList}
          </div>
        )}
        {pages &&
          pages.length === 0 &&
          subjects &&
          subjects.length === 0 &&
          tags &&
          tags.length === 0 && (
            <div className={styles['no-valid-result-container']}>
              <img
                src='/../../../img/no-results-found.svg'
                alt='No Valid Results'></img>
              <p>No results</p>
            </div>
          )}
        {loading && (
          <div className='loading-container'>
            <img
              className='loading-image'
              src='/img/newLoadingAnimation.gif'
              alt='Loading animation'
            />
            <h3>
              Loading <span className='dot1'>.</span>
              <span className='dot2'>.</span>
              <span className='dot3'>.</span>
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStatetoProps = state => {
  return {
    loading: state.wikiSearchResultsReducer.loading,
    pages: state.wikiSearchResultsReducer.pages,
    subjects: state.wikiSearchResultsReducer.subjects,
    tags: state.wikiSearchResultsReducer.tags,
  };
};

const mapDispatchToProps = {
  loadWikiSearchResults,
  storeUrlSearchParams,
};

export default connect(mapStatetoProps, mapDispatchToProps)(WikiSearchResults);
