import { reportTrackingActions } from '../actions/reportTrackingAction';
// This is the initial state that created and stored in the redux store
const initialState = {
  openReportTrackingPopupStatus: false,
  dueReports: [],
  isLoadingDueReportsCalendar: null,
  selectedDayDueReports: [],
  newReportStatus: null,
  isLoading: null,
  reportUpdateStatus: null,
  allReports: [],
  allAgencies: [],
  allAgencyUsers: [],
  currentDisplay: 'list',
  savedProgress: null,
  message: '',
  nextAPI: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case reportTrackingActions.CHANGE_DISPLAY:
      return { ...state, currentDisplay: action.displayString };
    case reportTrackingActions.SAVE_PROGRESS:
      return { ...state, savedProgress: action.report };
    case reportTrackingActions.SET_MESSAGE:
      return { ...state, message: action.message };
    case reportTrackingActions.ADD_MORE_REPORTS:
      return { ...state, allReports: [...state.allReports, ...action.reports] };
    case reportTrackingActions.CHANGE_REPORT_TRACKING_POPUP_WINDOW:
      return { ...state, openReportTrackingPopupStatus: action.status };
    case reportTrackingActions.SAVE_NEXT_API:
      return { ...state, nextAPI: action.url };
    case reportTrackingActions.SAVE_REPORTS_DUE_CURRENT_MONTH_AND_YEAR:
      return { ...state, dueReports: action.reports };
    case reportTrackingActions.SAVE_TARGET_DUE_REPORTS:
      return { ...state, selectedDayDueReports: action.reports };
    case reportTrackingActions.IS_LOADING_STATUS:
      return { ...state, isLoading: action.status };
    case reportTrackingActions.CHECKING_REQUEST_IS_LOADING:
      return { ...state, loadingRequest: action.status };
    case reportTrackingActions.CREATING_NEW_REPORT_DEADLINE_STATUS:
      return { ...state, newReportStatus: action.status };
    case reportTrackingActions.GET_REPORT_UPDATE_STATUS:
      return { ...state, reportUpdateStatus: action.status };
    case reportTrackingActions.SAVE_ALL_REPORTS:
      return { ...state, allReports: action.reports };
    case reportTrackingActions.SAVE_ALL_AGENCIES:
      return { ...state, allAgencies: action.agencies };
    case reportTrackingActions.SAVE_ALL_AGENCY_USERS:
      return { ...state, allAgencyUsers: action.agencyUsers };
    default:
      return state;
  }
}
