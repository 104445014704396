import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from '../../styles/wiki/SideBar.module.scss';
import * as actions from '../../actions/wikiPageAction';
import WikiSearch from './WikiSearch';
import TableOfContent from './TableOfContent';
import { TrashIcon, EditPageIcon, PlusIcon } from '../../services/SvgLibrary';

const SideBar = props => {
  const {
    loadPage,
    permissions,
    pid,
    rootSubjects,
    storePage,
    subjectPageError,
    table,
    wikiPageError,
  } = props;
  /**
   * useEffect hook for component mount and unmount
   */
  useEffect(() => {
    if (pid) loadPage(pid, 'diff');
    subjectPageError(false);
    wikiPageError(false);

    return () => {
      storePage({ id: 0, content: '' });
    };
  }, [loadPage, storePage, subjectPageError, wikiPageError, pid]);

  const subjectLinks =
    rootSubjects.length > 0
      ? rootSubjects.map((subject, idx) => (
          <Link
            to={'/CLEX/subject/' + subject.id}
            className={styles['link-subject']}
            key={idx}>
            {subject.title}
          </Link>
        ))
      : 'Loading....';

  const isCreatePage = window.location.pathname.includes('/create')
    ? true
    : false;

  return (
    <div className={styles['side']}>
      <div className={styles['clex-logo-wrapper']}>
        <Link to='/CLEX' title='CLEX homepage'>
          <img
            src='/img/Logo/clex logo white with purple box.png'
            alt='Council Legislative Exchange logo'
            width='100'
          />
        </Link>
      </div>
      <WikiSearch placement='side' />
      {table.length !== 0 && <TableOfContent content={table} />}
      {!isCreatePage && permissions.add_page && (
        <Link to='/CLEX/page/create' className={`${styles['create-new']} `}>
          <PlusIcon /> Create New Article
        </Link>
      )}
      <div className={styles['model-crud-container']}>
        <div className={styles['model-crud-buttons']}>Topics</div>
        <div className={styles['button-options']}>
          {permissions.add_subject && (
            <Link
              to='/CLEX/subject/create'
              className={styles['link-subject']}
              title='Create a topic'>
              <PlusIcon />
            </Link>
          )}
          {permissions.change_subject && (
            <Link
              to='/CLEX/subject/edit'
              className={styles['link-subject']}
              title='Edit a topic'>
              <EditPageIcon />
            </Link>
          )}
          {permissions.delete_subject && (
            <Link
              to='/CLEX/subject/delete'
              className={styles['link-subject']}
              title='Delete a topic'>
              <TrashIcon />
            </Link>
          )}
        </div>
      </div>
      <div
        className={`${styles['scroll-subjects']} ${
          table.length === 0 && styles['scroll-sub-full']
        }`}>
        {subjectLinks}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    permissions: state.userReducer.userProfile.permissions.wiki,
    rootSubjects: state.wikiReducer.rootSubjects,
  };
};

export default connect(mapStateToProps, actions)(SideBar);
