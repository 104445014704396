import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../../styles/user/SeatingChartStyles.module.scss';
import svgStyles from '../../../styles/user/SvgDeskCanvas.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserProfileCircleIcon } from '../../../services/SvgLibrary';
import FireIconComponent from './FlameIcon';
import { Tooltip } from 'reactstrap';
import { FootstepsAnimationMain } from './FootstepsAnimation';

const SeatingChart = props => {
  const location = useLocation();
  const fullStaffList = props.fullStaffList;

  //Getting only the staff who had a desk number:
  const filteredStaffDesks = fullStaffList.filter(staff => staff.desk_number);

  const loggedinUser = props.userProfile;

  /* Used useNavigate hook to manage the browser history.
	It returns an object that contains various properties and methods related to the history of
	the browser. I used push(URLpath):in handleClick on line 163 to push a new entry (staff.id) onto the history stack, navigating to the selctedStaff profile .*/
  const navigate = useNavigate();

  //The useRef hook returns a mutable ref object that have the `current` propert. After attaching the `ref` attribute to the SVG elemnet,
  //you can use svgRef.current to access the underlying SVG DOM element's cordinations X and Y:
  const svgRef = useRef(null);
  const containerRef = useRef(null);

  //Variables to control wheel zooming:
  const zoomFactor = 10; // Zoom factor for scaling
  const minScale = 1; // Minimum scale value
  const maxScale = 10; // Maximum scale value

  //States:

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const [stepsTooltipOpen, setStepsTooltipOpen] = useState(false);
  const [printerTooltips, setPrinterTooltips] = useState({});
  const [calorieTooltipOpen, setCalorieTooltipOpen] = useState(false);
  const [currentSelectedStaff, setCurrentSelectedStaff] =
    useState(loggedinUser);
  const [hoveredStaff, setHoveredStaff] = useState(loggedinUser);
  const [hoveredRectIndex, setHoveredRectIndex] = useState(null);
  const [totalAverageSteps, setTotalAverageSteps] = useState(0);

  const [components, setComponents] = useState([]);
  const [renderedIndex, setRenderedIndex] = useState([]);

  /* Array of objects defined and needed to rendering office svg canvas, 
  different footsteps sets, and printers */

  // Office 1484 and office 1470 spectial shapes and svg cordinations
  const vertices1484 = [
    { x: 60.5, y: 24 },
    { x: 64, y: 24 },
    { x: 64, y: 26.0 },
    { x: 67, y: 27.6 },
    { x: 67, y: 31 },
    { x: 60.5, y: 31 },
  ];

  const vertices1470 = [
    { x: 60.4, y: 24 },
    { x: 57.4, y: 24 },
    { x: 57.4, y: 25.5 },
    { x: 54.7, y: 25.5 },
    { x: 54.7, y: 31 },
    { x: 60.4, y: 31 },
  ];

  const pathDataDesk_1484 = `M ${vertices1484[0].x},${vertices1484[0].y} L ${vertices1484[1].x},${vertices1484[1].y} L ${vertices1484[2].x},${vertices1484[2].y} L ${vertices1484[3].x},${vertices1484[3].y} L ${vertices1484[4].x},${vertices1484[4].y} L ${vertices1484[5].x},${vertices1484[5].y} Z`;
  const pathDataDesk_1470 = `M ${vertices1470[0].x},${vertices1470[0].y} L ${vertices1470[1].x},${vertices1470[1].y} L ${vertices1470[2].x},${vertices1470[2].y} L ${vertices1470[3].x},${vertices1470[3].y} L ${vertices1470[4].x},${vertices1470[4].y} L ${vertices1470[5].x},${vertices1470[5].y} Z`;

  // Array of Footsteps Animation attributes objects
  const footstepsConfigs = [
    {
      lowerClass: 'lower',
      upperClass: 'upper',
      footstepClass: 'footstep',
      wrap: 'wrap-left-to-right-1',
      arrayOfSteps: 20,
      rotation: 'rotate(0deg)',
    },
    {
      lowerClass: 'lower',
      upperClass: 'upper',
      footstepClass: 'footstep',
      wrap: 'wrap-left-to-right-2',
      arrayOfSteps: 24,
      rotation: 'rotate(0deg)',
    },
    {
      lowerClass: 'lower',
      upperClass: 'upper',
      footstepClass: 'footstep',
      wrap: 'wrap-top-to-bottom-1',
      arrayOfSteps: 13,
      rotation: 'rotate(90deg)',
    },
    {
      lowerClass: 'lower',
      upperClass: 'upper',
      footstepClass: 'footstep',
      wrap: 'wrap-bottom-to-top-1',
      arrayOfSteps: 9,
      rotation: 'rotate(-90deg)',
    },
    {
      lowerClass: 'lower',
      upperClass: 'upper',
      footstepClass: 'footstep',
      wrap: 'wrap-right-to-left-1',
      arrayOfSteps: 22,
      rotation: 'rotate(-180deg)',
    },
    {
      lowerClass: 'lower',
      upperClass: 'upper',
      footstepClass: 'footstep',
      wrap: 'wrap-right-to-left-2',
      arrayOfSteps: 22,
      rotation: 'rotate(-180deg)',
    },
  ];

  // Printers info:
  const printers = [
    { printerName: 'GOV-Xerox-1', ipAddress: '10.250.14.18' },
    { printerName: 'GOV-PRT-2', ipAddress: '10.250.14.15' },
    { printerName: 'GOV-PRT-1', ipAddress: '10.250.14.14' },
    { printerName: 'INV_PRT_2', ipAddress: '10.250.14.13' },
    { printerName: 'HUM-XEROX-1', ipAddress: '10.250.14.16' },
    { printerName: 'HUM-PRT-1', ipAddress: '10.250.14.10' },
    { printerName: 'HUM-PRT-2', ipAddress: '10.250.14.12' },
  ];

  useEffect(() => {
    if (location.state && location.state.selectedStaff) {
      setCurrentSelectedStaff(location.state.selectedStaff);
      setHoveredStaff(location.state.selectedStaff);
    }
  }, [location.state]);

  const handleHover = staff => {
    // Update the hoveredRectIndex when hovering over a rect
    setHoveredRectIndex(staff.id);
    setHoveredStaff(staff);
  };

  const handleLeave = () => {
    // Reset the hoveredRectIndex when the hover leaves a rect
    setHoveredRectIndex(null);
    setHoveredStaff(currentSelectedStaff);
  };

  // Search bar event handler
  const onSearchByInput = event => {
    const { value } = event.target;
    const filteredResults = filteredStaffDesks.filter(oneStaff =>
      oneStaff.full_name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
    );
    setSearchResults(filteredResults);
    setSearchQuery(value);
  };

  const handleHoveredStaff = staffMember => {
    setHoveredStaff(staffMember);
  };

  // Fetching X and Y screen pixel cordinates to update footsteps and calories calculations
  const fetchXandYcordinations = () => {
    if (loggedinUser && hoveredStaff) {
      const dynamicClassName_1 = svgStyles[`D${loggedinUser.desk_number}`];
      const dynamicClassName_2 = svgStyles[`D${hoveredStaff.desk_number}`];
      const dynamicClassName_1450 = svgStyles['D1450'];
      const dynamicClassName_1405 = svgStyles['D1405'];

      // Attempting to get elements either by ID or by class name
      /* Also, special shape desks 1470 and 1484 doesn't have a className because they have been
      positioned with on svg canvas using the path 'd' vertices attribute, So both will be
      selected by their Id instead */
      const rectElement1 =
        document.getElementById(`staff_${loggedinUser.id}`) ||
        document.querySelector(`.${dynamicClassName_1}`);
      const rectElement2 =
        document.getElementById(`staff_${hoveredStaff.id}`) ||
        document.querySelector(`.${dynamicClassName_2}`);
      const rectElement_1450 = document.querySelector(
        `.${dynamicClassName_1450}`
      );
      const rectElement_1405 = document.querySelector(
        `.${dynamicClassName_1405}`
      );

      // Ensuring all elements exist before attempting to get their properties
      if (
        rectElement1 &&
        rectElement2 &&
        rectElement_1450 &&
        rectElement_1405
      ) {
        const rectElementRect1 = rectElement1.getBoundingClientRect();
        const rectElementRect2 = rectElement2.getBoundingClientRect();
        const rectElementRect_1450 = rectElement_1450.getBoundingClientRect();
        const rectElementRect_1405 = rectElement_1405.getBoundingClientRect();

        /* Capturing pixels between two offices to maintain
            the ratio of distance-pixal value consistent if screen width changes */
        const middlePointx1 =
          rectElementRect1.left + rectElementRect1.width / 2;
        const middlePointy1 =
          rectElementRect1.top + rectElementRect1.height / 2;
        const middlePointx2 =
          rectElementRect2.left + rectElementRect2.width / 2;
        const middlePointy2 =
          rectElementRect2.top + rectElementRect2.height / 2;
        const middlePointx_1450 =
          rectElementRect_1450.left + rectElementRect_1450.width / 2;
        const middlePointy_1450 =
          rectElementRect_1450.top + rectElementRect_1450.height / 2;
        const middlePointx_1405 =
          rectElementRect_1405.left + rectElementRect_1405.width / 2;
        const middlePointy_1405 =
          rectElementRect_1405.top + rectElementRect_1405.height / 2;

        // Continue with calculations after successfully getting the properties
        handleCalc(
          middlePointx1,
          middlePointy1,
          middlePointx2,
          middlePointy2,
          middlePointx_1450,
          middlePointy_1450,
          middlePointx_1405,
          middlePointy_1405
        );
      } else {
        console.error('One or more elements were not found.');
        return;
      }
    }
  };

  //Calculating steps
  const handleCalc = (x1, y1, x2, y2, x1450, y1450, x1405, y1405) => {
    if (x2 >= 0) {
      const deltaX = Math.abs(Math.abs(x2) - Math.abs(x1));
      const deltaY = Math.abs(Math.abs(y2) - Math.abs(y1));
      const deltaXAJ = Math.abs(Math.abs(x1450 - 30) - Math.abs(x1405));
      const deltaYAJ = Math.abs(Math.abs(y1450) - Math.abs(y1405));
      const distanceInPixels = deltaX + deltaY;

      const distanceInPixelsAJ = deltaXAJ + deltaYAJ;
      const distanceInFeet = (distanceInPixels * 175) / distanceInPixelsAJ; //(3.5 / 24);
      const averageStep = distanceInFeet / 3.2;
      setTotalAverageSteps(averageStep);
    }
  };

  const handleStaffSelection = staff => {
    setCurrentSelectedStaff(staff);
    setSearchQuery('');
  };

  /* handleClick handles the onClick event listener, which is mounted on top of every desk svg canvas
	to change the URL to go to the corresponding profile for that desk */
  const handleClick = staff => {
    navigate(`/staff/${staff.id}`);
  };

  useEffect(() => {
    //variables to handle zooming points
    const svgElement = svgRef.current;
    const containerElement = containerRef.current;
    let isDragging = false;
    let prevX = 0;
    let prevY = 0;
    let offsetX = 0;
    let offsetY = 0;
    let scale = 1;

    const handleScroll = event => {
      /*This line prevents the default scrolling behavior of the browser, ensuring that the
			scroll event is handled manually. */
      event.preventDefault();

      /*This line determines the direction of the scroll. If the deltaY property of the scroll event is less than 0 (scrolling away from you),
			it sets delta to -1 (zoom in). Otherwise, it sets delta to 1 (zoom out). */
      const delta = event.deltaY < 0 ? -1 : 1;

      // Calculate new scale value
      const newScale = scale + (delta * zoomFactor) / 100;

      //Sets limits to zoom in and out:
      if (newScale >= minScale && newScale <= maxScale) {
        scale = newScale;

        /* The getBoundingClientRect() method returns a DOMRect object that contains properties
				such as `top`, `left`, `right`, `bottom`, `width`, and `height`. These properties give you
				precise coordinate values and dimensions of the element relative to the viewport.Exactky
				like the `.current` obj but more percise. */
        const rect = svgElement.getBoundingClientRect();

        //getting the cursor position X and Y inside the SVG element
        const xPos = event.clientX - rect.left;
        const yPos = event.clientY - rect.top;

        //getting the cursor position persentage inside the SVG
        const xPercent = (xPos / rect.width) * 100;
        const yPercent = (yPos / rect.height) * 100;

        //getting the cursor position X and Y inside the parent container of the SVG
        const containerRect = containerElement.getBoundingClientRect();
        const containerXPos = event.clientX - containerRect.left;
        const containerYPos = event.clientY - containerRect.top;

        //The offsetX and offsetY variables calculate the difference of the previous and current
        //cursor position and re-write the prevX and prevY
        offsetX = (containerXPos - prevX) / containerRect.width;
        offsetY = (containerYPos - prevY) / containerRect.height;

        prevX = containerXPos;
        prevY = containerYPos;

        /* Executing the zooming functionality by modifying the SVG style
				This line of code is responsible for changing the origin point (pivot point of scalling) 
				of the transformation applied to the SVG element */
        svgElement.style.transformOrigin = `${xPercent}% ${yPercent}%`;

        /* This line make sure to change the mouse position related to the SVG
				to update it's cordination to reflect the new cursor position after zoom so it's x and y can
				stay in sync with the zoomed svg */
        svgElement.style.transform = `translate(${offsetX * 100}%, ${
          offsetY * 100
        }%) scale(${scale})`;
      }
    };

    // Handling pressing down the mouse wheel to grab the the floor plan
    const handleMouseDown = e => {
      isDragging = true;
      svgElement.style.cursor = 'grabbing';
      if (e.detail > 1) {
        e.preventDefault();
      }
    };

    // Handling releasing the mouse wheel to grab the the floor plan
    const handleMouseUp = e => {
      e.preventDefault();
      isDragging = false;
      svgElement.style.cursor = 'grab';
    };

    // Handling moving the mouse while grabbing the floor plan image
    const handleMouseMove = event => {
      if (!isDragging) return;

      const containerRect = containerElement.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const containerHeight = containerRect.height;

      const movementX = event.movementX / containerWidth;
      const movementY = event.movementY / containerHeight;

      offsetX += movementX;
      offsetY += movementY;

      svgElement.style.transformOrigin = 'center';
      svgElement.style.transform = `translate(${offsetX * 100}%, ${
        offsetY * 100
      }%) scale(${scale})`;
    };

    const handleMouseLeave = () => {
      isDragging = false;
      svgElement.style.cursor = 'grab';
    };

    containerElement.addEventListener('wheel', handleScroll);
    containerElement.addEventListener('mousedown', handleMouseDown);
    containerElement.addEventListener('mouseup', handleMouseUp);
    containerElement.addEventListener('mousemove', handleMouseMove);
    containerElement.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      containerElement.removeEventListener('wheel', handleScroll);
      containerElement.removeEventListener('mousedown', handleMouseDown);
      containerElement.removeEventListener('mouseup', handleMouseUp);
      containerElement.removeEventListener('mousemove', handleMouseMove);
      containerElement.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  //Watching for a desk hovering event to update name, steps, and calories calculations.
  useEffect(() => {
    fetchXandYcordinations();
  }, [hoveredStaff]);

  // Dropdown names from search bar
  const staffDisplay = searchResults.map(staffMember => {
    return (
      <tr key={staffMember.id} className={styles['user-row']}>
        <td className={styles['user-name-email']}>
          <button
            onClick={() => {
              handleHoveredStaff(staffMember);
              handleStaffSelection(staffMember);
            }}>
            {staffMember.full_name}
          </button>
        </td>
      </tr>
    );
  });

  /* Footsteps rendering logic */
  // Shuffling the array of footsteps
  function shuffleArray(array) {
    const shuffledArray = [...array]; // Create a copy of the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const randomShuffleIndex = Math.floor(Math.random() * (i + 1));

      // Swap elements at randomShuffleIndex and i
      [shuffledArray[i], shuffledArray[randomShuffleIndex]] = [
        shuffledArray[randomShuffleIndex],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  // Shuffled array:
  const shuffledFootstepsConfigs = shuffleArray(footstepsConfigs);

  // Getting a random index to render footsteps randomely on different map location
  function getRandomIndex(array) {
    return Math.floor(Math.random() * array.length);
  }

  // Rendering Footsteps components with different attributes
  const renderComponentsWithDelay = currentIndex => {
    if (currentIndex < shuffledFootstepsConfigs.length) {
      if (!renderedIndex.includes(currentIndex)) {
        setRenderedIndex(prev => [...prev, currentIndex]);

        const config = shuffledFootstepsConfigs[currentIndex];

        setComponents(
          <FootstepsAnimationMain
            key={currentIndex}
            lowerClass={config.lowerClass}
            upperClass={config.upperClass}
            footstepClass={config.footstepClass}
            wrap={config.wrap}
            arrayOfSteps={config.arrayOfSteps}
            rotation={config.rotation}
          />
        );

        // Adding a delay in between components render
        setTimeout(() => {
          renderComponentsWithDelay(getRandomIndex(footstepsConfigs));
          /* Rendering the next footsteps component with a delay of 40 seconds between the previous 
          footsteps set and the upcomping one. Also noticed that when the time increased 
          above 40 seconds, the footsteps don't show up */
        }, 40000);
      } else {
        setRenderedIndex([]);
        renderComponentsWithDelay(getRandomIndex(footstepsConfigs));
      }
    }
  };

  useEffect(() => {
    renderComponentsWithDelay(getRandomIndex(footstepsConfigs));
  }, []);

  // Printers tooltip handler
  const toggleTooltip = printerName => {
    setPrinterTooltips(prevState => ({
      ...prevState,
      [printerName]: !prevState[printerName],
    }));
  };

  return (
    <div className={styles['outer-main-div-class']}>
      <div className={styles['diplay-info-bar-search-steps']}>
        <div style={{ paddingLeft: '25px', paddingTop: '10px' }}>
          <input
            type='text'
            className={`form-control ${styles['user-search-input']} ${styles['search-bar']}`}
            id='searchCouncilStaff'
            placeholder='Find My Colleague . . . . .'
            value={searchQuery}
            onChange={onSearchByInput}
          />

          {searchQuery && (
            <div className={styles['user-profile-container']}>
              <div className={styles['user-profile-main']}>
                <div>
                  <table className={`col-12 ${styles['user-table']}`}>
                    <tbody>{staffDisplay}</tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles['info-display']}>
          {hoveredStaff
            ? hoveredStaff.full_name
            : currentSelectedStaff.full_name}
        </div>
        {true && (
          <>
            <div id='totalAverageSteps' className={styles['info-display']}>
              {Math.floor(totalAverageSteps) * 2} 👣
              <Tooltip
                isOpen={stepsTooltipOpen}
                placement='bottom'
                target='totalAverageSteps'
                fade={false}
                toggle={() => setStepsTooltipOpen(!stepsTooltipOpen)}>
                Footsteps taken to and from location
              </Tooltip>
            </div>
            <div id='calorie' className={styles['calories-info']}>
              {(totalAverageSteps * 0.04).toFixed(1) * 2}
              {<FireIconComponent />}
              <Tooltip
                isOpen={calorieTooltipOpen}
                placement='bottom'
                target='calorie'
                fade={false}
                toggle={() => setCalorieTooltipOpen(!calorieTooltipOpen)}>
                Approximate calories burned walking to and from location
              </Tooltip>
            </div>
          </>
        )}
      </div>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            position: 'relative',
            height: '80%px',
            width: '80%',
          }}>
          <div
            className={styles['floor-plan-svg-main-container']}
            ref={containerRef}>
            <div
              className={styles['floor-plan-svg-main-container-inner']}
              style={{ position: 'relative' }}
              ref={svgRef}>
              {components ? components : shuffledFootstepsConfigs}
              <svg
                viewBox='0 7 85 70'
                preserveAspectRatio='none'
                overflow='hidden'>
                <image
                  xlinkHref='/img/FloorPlan/zoning-pl-model-4.jpg'
                  alt='Seating Chart'
                  width='100%'
                  height='100%'
                  style={{ position: 'relative' }}
                  y='10%'
                  x='0%'
                  preserveAspectRatio='none'
                />

                {filteredStaffDesks.map((staff, index) => {
                  const deskProps = {
                    onDoubleClick: e => {
                      handleClick(staff);
                    },
                    onMouseEnter: () => handleHover(staff),
                    onMouseLeave: handleLeave,
                    style: {
                      fill: currentSelectedStaff
                        ? hoveredRectIndex === staff.id &&
                          currentSelectedStaff !== staff &&
                          loggedinUser !== staff
                          ? 'red'
                          : /* 
                          *** Error:
                          -colors for different desk selections (current user or selected staff) weren't 
                          render as they supposed to. 
                          Yellow wasn't showing up so I refactored the code to make it more percise.
                          */
                          currentSelectedStaff.id === staff.id &&
                            currentSelectedStaff.id !== loggedinUser.id
                          ? 'yellow'
                          : loggedinUser.id === staff.id
                          ? 'green'
                          : '#289dba'
                        : '#289dba',
                      opacity:
                        (currentSelectedStaff.id === staff.id &&
                          currentSelectedStaff.id !== loggedinUser.id) ||
                        loggedinUser.id === staff.id
                          ? 1
                          : 0.5,
                      outline: '2px solid rgba(0, 0, 0, 0)',
                    },
                    className: `${svgStyles['D' + staff.desk_number]}`,
                  };

                  return (
                    <React.Fragment key={staff.id}>
                      <Tooltip
                        key={staff.id}
                        placement={
                          Number(staff.desk_number) > 1440 ? 'bottom' : 'top'
                        }
                        isOpen={hoveredRectIndex === staff.id} // Show the Tooltip when hovering over the staff
                        target={`staff_${staff.id}`}
                        fade={false}
                        style={{
                          transform:
                            Number(staff.desk_number) > 1440
                              ? 'translateX(-55%) translateY(-5%)'
                              : 'translateX(-55%) translateY(5%)',
                        }}>
                        <div className={styles['desk-tooltip-info']}>
                          {staff.profile_pic ? (
                            <img
                              src={staff.profile_pic.file}
                              alt={staff.full_name}
                              className={styles['desk-profile-pic']}
                            />
                          ) : (
                            <UserProfileCircleIcon
                              className={styles['desk-profile-pic-icon']}
                            />
                          )}
                          <br />
                          <strong>{staff.full_name}</strong> <br />
                          <strong>
                            {staff.role.split(' ').slice(0, 2).join(' ')}
                          </strong>
                          <br />
                          {staff.phone && (
                            <strong
                              style={{
                                color: 'transparent',
                                textShadow: '0 0 0 white',
                              }}>
                              &#128222; {staff.phone}
                            </strong>
                          )}
                          <br />
                          {staff.email}
                          <br />
                          {`office# ${staff.desk_number}`}
                        </div>
                      </Tooltip>
                      {staff.desk_number === '1484' ? (
                        <path
                          id={`staff_${staff.id}`}
                          d={pathDataDesk_1484}
                          {...deskProps}
                        />
                      ) : staff.desk_number === '1470' ? (
                        <path
                          id={`staff_${staff.id}`}
                          d={pathDataDesk_1470}
                          {...deskProps}
                        />
                      ) : (
                        <rect id={`staff_${staff.id}`} {...deskProps} />
                      )}
                    </React.Fragment>
                  );
                })}
              </svg>
              {printers.map(printer => (
                <div key={printer.printerName}>
                  <Tooltip
                    isOpen={printerTooltips[printer.printerName]}
                    placement='bottom'
                    target={`${printer.printerName}-council`}
                    fade={false}
                    toggle={() => toggleTooltip(printer.printerName)}>
                    <div className={styles['printer-tooltip-info']}>
                      {`Printer's Name: `}
                      <br />
                      {printer.printerName}
                      <br />
                      {`IP Address: `}
                      <br />
                      {printer.ipAddress}
                    </div>
                  </Tooltip>
                  <img
                    src='/img/FloorPlan/printer.png'
                    id={`${printer.printerName}-council`}
                    alt='not found'
                    className={styles[`${printer.printerName}`]}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <img
          src='/img/FloorPlan/Broadway.jpg'
          alt='not found'
          className={styles['broadway-st-img']}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fullStaffList: state.userReducer.userProfile.permissions.auth.change_user
      ? state.userReducer.allUserList
      : state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(SeatingChart);
