import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { url } from '../../config';
import { connect } from 'react-redux';
import * as actions from '../../actions/userAction';
import { councilMembers } from '../../utils/helper';
import UserForm from './UserForm';

const initUserFields = {
  inputUsername: '',
  inputFirstName: '',
  inputMiddleName: '',
  inputLastName: '',
  inputSuffix: '',
  inputEmail: '',
  inputAgency: '',
  warningMessage: '',
  inputPhone: '',
  inputAlternativePhone: '',
  inputRole: '',
  inputCommittee: '',
  inputDivision: '',
  inputBio: '',
  underCouncilMember: null,
  inputIsActive: false,
  isLocked: null,
};

const initPermissions = {
  Auth: {
    add_user: false,
    change_user: false,
  },
  LS_Request: {
    view_ls_requests: false,
    add_ls_requests: false,
    change_ls_requests: false,
  },
  Report_Tracking: {
    view_reports: false,
    add_reports: false,
    change_reports: false,
    view_agency: false,
    add_agency: false,
    change_agency: false,
    view_agencyuser: false,
    add_agencyuser: false,
    change_agencyuser: false,
  },
  Wiki: {
    view_pages: false,
    add_pages: false,
    update_all_page: false,
    update_committee_page: false,
    update_own_page: false,
    delete_pages: false,
    view_subjects: false,
    add_subjects: false,
    change_subjects: false,
    delete_subjects: false,
    view_comments: false,
    add_comments: false,
    change_comments: false,
    delete_comments: false,
    view_reply: false,
    add_reply: false,
    change_reply: false,
    delete_reply: false,
    view_versiondiff: false,
    add_versiondiff: false,
    change_versiondiff: false,
    delete_versiondiff: false,
    view_pagereview: false,
    add_pagereview: false,
    change_pagereview: false,
    delete_pagereview: false,
  },
  Proclamation_Request: {
    view_proclamationrequest: false,
    add_proclamationrequest: false,
    change_proclamationrequest: false,
  },
  Citation_Request: {
    view_citationrequest: false,
    add_citationrequest: false,
    change_citationrequest: false,
  },
};

const districtRoles = [
  'District Chief of Staff',
  'District Legislative Director',
  'District Legislative Fellow',
  'District Legislative/Policy Associate',
  'District Proc/Citation Liaison',
];

const EditUserForm = props => {
  const [user, setUser] = useState(null);
  const [userFields, setUserFields] = useState(initUserFields);
  const [permissions, setPermissions] = useState(initPermissions);
  const [warningMessage, setWarningMessage] = useState('');
  const {
    allUsersList,
    committees,
    division,
    getAllAgencies,
    id,
    newUserCreationStatus,
    updateUser,
    usersList,
  } = props;
  /** component mount */
  useEffect(() => {
    getAllAgencies();
  }, [getAllAgencies]);

  /** get user data for id when id changes */
  useEffect(() => {
    function getUserData(id) {
      //I felt doing this in redux would be more trouble than it is worth.
      axios
        .get(`${url}/api/users/info/${id}/`)
        .then(response => {
          const userData = response.data;
          setUser(userData);
          setUserFields({
            inputUsername: userData.username,
            inputFirstName: userData.first_name,
            inputLastName: userData.last_name,
            inputMiddleName: userData.middle_name,
            inputSuffix: userData.suffix,
            inputEmail: userData.email,
            inputPhone: userData.phone,
            inputAlternativePhone: userData.alternative_phone,
            inputRole: userData.role,
            inputAgency: userData.agency,
            inputIsActive: userData.is_active,
            isLocked: userData.is_locked,
            inputCommittee: userData.committees
              .map(committee => committee.id)
              .join(','),
            inputDivision: userData.division
              .map(division => division.id)
              .join(','),
            inputBio: userData.bio,
            underCouncilMember: userData.under_council_member
              ? allUsersList.find(u => u.id === userData.under_council_member)
                  .full_name
              : null,
          });
          setPermissions({
            Auth: {
              add_user: userData.permissions.auth.add_user,
              change_user: userData.permissions.auth.change_user,
            },
            Report_Tracking: {
              view_reports: userData.permissions.reportTracking.view_report,
              add_reports: userData.permissions.reportTracking.add_report,
              change_reports: userData.permissions.reportTracking.change_report,
              view_agency: userData.permissions.reportTracking.view_agency,
              add_agency: userData.permissions.reportTracking.add_agency,
              change_agency: userData.permissions.reportTracking.change_agency,
              view_agencyuser:
                userData.permissions.reportTracking.view_agencyuser,
              add_agencyuser:
                userData.permissions.reportTracking.add_agencyuser,
              change_agencyuser:
                userData.permissions.reportTracking.change_agencyuser,
            },
            LS_Request: {
              view_ls_requests:
                userData.permissions.lsRequest_api.view_lsrequest,
              add_ls_requests: userData.permissions.lsRequest_api.add_lsrequest,
              change_ls_requests:
                userData.permissions.lsRequest_api.change_lsrequest,
            },
            Wiki: {
              view_pages: userData.permissions.wiki.view_page,
              add_pages: userData.permissions.wiki.add_page,
              update_all_page: userData.permissions.wiki.update_all_page,
              update_committee_page:
                userData.permissions.wiki.update_committee_page,
              update_own_page: userData.permissions.wiki.update_own_page,
              // delete_pages: userData.permissions.wiki.delete_page,
              view_subjects: userData.permissions.wiki.view_subject,
              add_subjects: userData.permissions.wiki.add_subject,
              change_subjects: userData.permissions.wiki.change_subject,
              // delete_subjects: userData.permissions.wiki.delete_subject,
              view_comments: userData.permissions.wiki.view_comment,
              add_comments: userData.permissions.wiki.add_comment,
              change_comments: userData.permissions.wiki.change_comment,
              delete_comments: userData.permissions.wiki.delete_comment,
              view_tag: userData.permissions.wiki.view_tag,
              add_tag: userData.permissions.wiki.add_tag,
              change_tag: userData.permissions.wiki.change_tag,
              // delete_tag: userData.permissions.wiki.delete_tag,
              view_reply: userData.permissions.wiki.view_reply,
              add_reply: userData.permissions.wiki.add_reply,
              change_reply: userData.permissions.wiki.change_reply,
              delete_reply: userData.permissions.wiki.delete_reply,
              view_versiondiff: userData.permissions.wiki.view_versiondiff,
              add_versiondiff: userData.permissions.wiki.add_versiondiff,
              change_versiondiff: userData.permissions.wiki.change_versiondiff,
              // delete_versiondiff: userData.permissions.wiki.delete_versiondiff,
              view_pagereview: userData.permissions.wiki.view_pagereview,
              add_pagereview: userData.permissions.wiki.add_pagereview,
              change_pagereview: userData.permissions.wiki.change_pagereview,
              // delete_pagereview: userData.permissions.wiki.delete_pagereview,
            },
            Proclamation_Request: {
              view_proclamationrequest:
                userData.permissions.proclamationRequest
                  .view_proclamationrequest,
              add_proclamationrequest:
                userData.permissions.proclamationRequest
                  .add_proclamationrequest,
              change_proclamationrequest:
                userData.permissions.proclamationRequest
                  .change_proclamationrequest,
            },
            Citation_Request: {
              view_citationrequest:
                userData.permissions.citationRequest.view_citationrequest,
              add_citationrequest:
                userData.permissions.citationRequest.add_citationrequest,
              change_citationrequest:
                userData.permissions.citationRequest.change_citationrequest,
            },
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
    getUserData(id);
  }, [id, allUsersList]);

  function unlockUser() {
    const formData = new FormData();
    formData.append('username', user.username);
    axios
      .patch(`${url}/api/users/unlock/`, formData)
      .then(res => {
        setUserFields({ ...userFields, isLocked: false });
      })
      .catch(e => {
        console.log(e);
      });
  }

  function toggleActive(e) {
    setUserFields({ ...userFields, inputIsActive: !userFields.inputIsActive });
  }

  function onUserClick(e) {
    setUserFields({ ...userFields, underCouncilMember: e.target.innerText });
  }

  // For detecting the input box
  function onDetectInputChange(e) {
    switch (e.target.name) {
      case 'inputRole':
        if (districtRoles.includes(e.target.value)) {
          let unassignedDivision = division.filter(
            div => div.division_name === 'Unassigned'
          );
          // If the role is changed to a CM related role, division should be auto-set to 'Unassigned'
          setUserFields({
            ...userFields,
            [e.target.name]: e.target.value,
            inputDivision: unassignedDivision[0].id.toString(),
          });
        } else {
          // Otherwise, the Associated CM field should be cleared
          setUserFields({
            ...userFields,
            [e.target.name]: e.target.value,
            underCouncilMember: null,
          });
        }
        break;
      default:
        setUserFields({ ...userFields, [e.target.name]: e.target.value });
    }
  }

  // On submit to create a new user
  function onSubmit(e) {
    e.preventDefault();
    const {
      inputUsername,
      inputFirstName,
      inputLastName,
      inputEmail,
      inputPhone,
      inputAlternativePhone,
      inputRole,
      inputDivision,
      inputBio,
      inputCommittee,
      inputMiddleName,
      inputSuffix,
      inputAgency,
      inputIsActive,
      underCouncilMember,
    } = userFields;
    let cleanedPhone = inputPhone.replace(/[^0-9]/g, '');
    let cleanedAlternativePhone = inputAlternativePhone.replace(/[^0-9]/g, '');
    if (inputEmail === '') {
      setWarningMessage('Email cannot be empty!');
    } else if (inputFirstName === '') {
      setWarningMessage('First name cannot be empty!');
    } else if (inputLastName === '') {
      setWarningMessage('Last name cannot be empty!');
    } else if (
      inputRole !== 'Agency' &&
      !inputEmail.match(
        /^(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._-]+(?<![_.-])@(?:council|advocate|pubadvocate)\.nyc\.gov$/
      )
    ) {
      setWarningMessage('Must be a Council or Public Advocate email!');
    } else if (
      inputRole === 'Agency' &&
      !inputEmail.match(
        /^(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._-]+(?<![_.-])@(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._-]+(?<![_.-])\.nyc\.gov$/
      )
    ) {
      setWarningMessage('Must be a government email!');
    } else if (inputPhone === '' && inputRole !== 'Agency') {
      setWarningMessage('Phone number cannot be empty!');
    } else if (inputRole === '') {
      setWarningMessage('Role cannot be empty!');
    } else if (
      inputCommittee === '' &&
      inputRole !== 'Agency' &&
      !districtRoles.includes(inputRole)
    ) {
      setWarningMessage('Committee cannot be empty!');
    } else if (inputDivision === '') {
      setWarningMessage('Division cannot be empty!');
    } else if (
      inputPhone.replace(/\D/g, '').length !== 10 &&
      inputRole !== 'Agency'
    ) {
      setWarningMessage('Phone number must be 10 digits!');
    } else if (
      inputAlternativePhone !== '' &&
      inputAlternativePhone.replace(/\D/g, '').length !== 10 &&
      inputRole !== 'Agency'
    ) {
      setWarningMessage('Alternative phone number must be 10 digits!');
    } else if (districtRoles.includes(inputRole) && underCouncilMember === '') {
      setWarningMessage('This role must be under a Council Member!');
    } else if (
      districtRoles.includes(inputRole) &&
      !councilMembers(usersList)
        .map(user => user.full_name)
        .includes(underCouncilMember)
    ) {
      setWarningMessage('Not a valid council Member!');
    } else if (inputRole === 'Agency' && inputAgency === '') {
      setWarningMessage('Agency not input');
    } else {
      const user = {
        username: inputUsername,
        first_name: inputFirstName,
        last_name: inputLastName,
        middle_name: inputMiddleName,
        suffix: inputSuffix,
        email: inputEmail,
        agency: inputAgency,
        phone: cleanedPhone,
        alternative_phone: cleanedAlternativePhone,
        role: inputRole,
        committees: inputCommittee,
        division: inputDivision,
        bio: inputBio,
        is_active: inputIsActive,
        permissions: {
          //Auth
          add_user: permissions.Auth.add_user,
          change_user: permissions.Auth.change_user,
          // LSR
          view_lsrequest: permissions.LS_Request.view_ls_requests,
          add_lsrequest: permissions.LS_Request.add_ls_requests,
          change_lsrequest: permissions.LS_Request.change_ls_requests,
          // Operational Requirements
          view_report: permissions.Report_Tracking.view_reports,
          add_report: permissions.Report_Tracking.add_reports,
          change_report: permissions.Report_Tracking.change_reports,
          view_agency: permissions.Report_Tracking.view_agency,
          add_agency: permissions.Report_Tracking.add_agency,
          change_agency: permissions.Report_Tracking.change_agency,
          view_agencyuser: permissions.Report_Tracking.view_agencyuser,
          add_agencyuser: permissions.Report_Tracking.add_agencyuser,
          change_agencyuser: permissions.Report_Tracking.change_agencyuser,
          //Wiki
          view_comment: permissions.Wiki.view_comments,
          add_comment: permissions.Wiki.add_comments,
          change_comment: permissions.Wiki.change_comments,
          delete_comment: permissions.Wiki.delete_comments,
          view_subject: permissions.Wiki.view_subjects,
          add_subject: permissions.Wiki.add_subjects,
          change_subject: permissions.Wiki.change_subjects,
          // delete_subject: permissions.Wiki.delete_subjects,
          view_page: permissions.Wiki.view_pages,
          add_page: permissions.Wiki.add_pages,
          update_all_page: permissions.Wiki.update_all_page,
          update_committee_page: permissions.Wiki.update_committee_page,
          update_own_page: permissions.Wiki.update_own_page,
          // delete_page: permissions.Wiki.delete_pages,
          view_tag: permissions.Wiki.view_tag,
          add_tag: permissions.Wiki.add_tag,
          change_tag: permissions.Wiki.change_tag,
          // delete_tag: permissions.Wiki.delete_tag,
          view_reply: permissions.Wiki.view_reply,
          add_reply: permissions.Wiki.add_reply,
          change_reply: permissions.Wiki.change_reply,
          delete_reply: permissions.Wiki.delete_reply,
          view_versiondiff: permissions.Wiki.view_versiondiff,
          add_versiondiff: permissions.Wiki.add_versiondiff,
          change_versiondiff: permissions.Wiki.change_versiondiff,
          // delete_versiondiff: permissions.Wiki.delete_versiondiff,
          view_pagereview: permissions.Wiki.view_pagereview,
          add_pagereview: permissions.Wiki.add_pagereview,
          change_pagereview: permissions.Wiki.change_pagereview,
          // delete_pagereview: permissions.Wiki.delete_pagereview,
          view_proclamationrequest:
            permissions.Proclamation_Request.view_proclamationrequest,
          add_proclamationrequest:
            permissions.Proclamation_Request.add_proclamationrequest,
          change_proclamationrequest:
            permissions.Proclamation_Request.change_proclamationrequest,
          view_citationrequest:
            permissions.Citation_Request.view_citationrequest,
          add_citationrequest: permissions.Citation_Request.add_citationrequest,
          change_citationrequest:
            permissions.Citation_Request.change_citationrequest,
        },
      };
      if (inputRole === 'Agency') {
        let unassignedCommittee = committees.filter(
          com => com.committee_name === 'Unassigned'
        );
        let unassignedDivision = division.filter(
          div => div.division_name === 'Unassigned'
        );
        user.committees = unassignedCommittee[0].id.toString();
        user.division = unassignedDivision[0].id.toString();
        user.agency = inputAgency;
      }
      if (underCouncilMember) {
        user.under_council_member = usersList.find(
          u => u.full_name === underCouncilMember
        ).id;
      } else {
        user.under_council_member = null;
      }
      user.division = inputDivision;
      updateUser(id, user, true);
    }
  }

  // On detect multiple options for committee
  // The committees are seprated by comma
  function onDetectCommittee(e) {
    const options = e.target.options;
    let ids = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        ids.push(options[i].value);
      }
    }
    setUserFields({ ...userFields, inputCommittee: ids.join(',') });
  }
  function onDetectDivision(e) {
    const options = e.target.options;
    let ids = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        ids.push(options[i].value);
      }
    }
    setUserFields({ ...userFields, inputDivision: ids.join(',') });
  }

  function onTogglePermission(app, operation, e) {
    let value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    //Feel free to write this better with object spread or however
    let newOpp = Object.assign({}, permissions[app], { [operation]: value });
    setPermissions({ ...permissions, [app]: newOpp });
  }

  return (
    <UserForm
      formTitle={'Edit user'}
      editId={id}
      inputUsername={userFields.inputUsername}
      inputFirstName={userFields.inputFirstName}
      inputMiddleName={userFields.inputMiddleName}
      inputLastName={userFields.inputLastName}
      inputSuffix={userFields.inputSuffix}
      inputEmail={userFields.inputEmail}
      warningMessage={warningMessage}
      inputPhone={userFields.inputPhone}
      inputAlternativePhone={userFields.inputAlternativePhone}
      inputAgency={userFields.inputAgency}
      inputRole={userFields.inputRole}
      inputCommittee={userFields.inputCommittee}
      inputDivision={userFields.inputDivision}
      inputBio={userFields.inputBio}
      permissions={permissions}
      newUserCreationStatus={props.newUserCreationStatus}
      committees={props.committees}
      onDetectInputChange={onDetectInputChange}
      onSubmit={onSubmit}
      onDetectCommittee={onDetectCommittee}
      onDetectDivision={onDetectDivision}
      onTogglePermission={onTogglePermission}
      isLocked={userFields.isLocked}
      unlockUser={unlockUser}
      inputIsActive={userFields.inputIsActive}
      toggleActive={toggleActive}
      search={userFields.underCouncilMember}
      handleClick={onUserClick}
      onUserChange={onDetectInputChange}
    />
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.userReducer.userProfile.role,
    newUserCreationStatus: state.userReducer.newUserCreationStatus,
    committees: state.userReducer.committees,
    division: state.userReducer.division,
    userProfile: state.userReducer.userProfile,
    usersList: state.userReducer.userList,
    allUsersList: state.userReducer.allUserList,
  };
};

export default connect(mapStateToProps, actions)(EditUserForm);
