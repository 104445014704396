import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  archives: [],
  diffs: [],
  selectedRevision: null,
  selectIndex: -1,
  fail: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.STORE_PAGE_HISTORY:
      return {
        ...state,
        archives: action.archives.version_history,
        diffs: action.archives.diffs,
      };
    case wikiPageActions.SELECT_REVISION:
      return {
        ...state,
        selectedRevision: action.revision,
        selectIndex: action.index,
      };
    case wikiPageActions.REVERT_PAGE_FAIL:
      return { ...state, fail: action.message };

    default:
      return state;
  }
}
