import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/ls-request/LsRequestsList.module.scss';
import { UncontrolledTooltip } from 'reactstrap';
import parse from 'html-react-parser';
import {
  ChevronUpFillIcon,
  ChevronDownFillIcon,
} from '../../services/SvgLibrary';

const renderSort = field => {
  return field.includes('-') ? (
    <ChevronDownFillIcon classNameProp={styles['alignSortDown']} />
  ) : (
    <ChevronUpFillIcon classNameProp={styles['alignSortUp']} />
  );
};

const TableHeader = props => {
  return (
    <th onClick={props.sortColumn}>
      <span
        className={props.description && styles['table-column-tooltip']}
        id={`table-column-tooltip-${props.toolTipId}`}>
        {props.title}
      </span>
      {props.description && (
        <UncontrolledTooltip
          className={styles['column-tooltip-container']}
          placement='top'
          target={`table-column-tooltip-${props.toolTipId}`}
          fade={false}>
          {parse(props.description)}
        </UncontrolledTooltip>
      )}
      {props.showSort && <>&nbsp;{renderSort(props.field)}</>}
    </th>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  field: PropTypes.string,
  showSort: PropTypes.bool,
  sortColumn: PropTypes.func,
  description: PropTypes.string,
  toolTipId: PropTypes.number,
};

export default TableHeader;
