import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/ls-request/LsRequestsList.module.scss';
import { connect } from 'react-redux';
import {
  activateLsRequest,
  clickedLsRequest,
  openOrCloseLsRequestServicePopup,
  updateTargetLsRequest,
} from '../../actions/lsRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { formatDateTime, isConfidential } from '../../utils/helper';
import File from '../library/File';
import parse from 'html-react-parser';
import TableHeader from '../library/TableHeader';
import DuplicatesTooltip from './DuplicatesTooltip';
import {
  activeStatuses,
  adminRoles,
  fourthTierRoles,
  rolesUnderCouncilMembers,
  superAdminRoles,
} from '../../services/constants';
import { ThreeDotsVertical } from '../../services/SvgLibrary';
import CellEditModal from './CellEditModal';
import ColumnResizer from 'react-table-column-resizer';

const LsRequestsTable = props => {
  const tableContainerRef = useRef(null);
  const tableRef = useRef(null);
  const [lsRequests, setLsRequests] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorImage, setErrorImage] = useState('');
  const [altTag, setAltTag] = useState('');
  const [editModalType, setEditModalType] = useState('');
  const [editModalValue, setEditModalValue] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFile, setIsFile] = useState(false);
  const {
    currentSearchType,
    dupArray,
    duplicateCheckResults,
    invalidSearch,
    isLoading,
    loadMoreLsRequests,
    lsRequests: lsRequestsProps,
    onChangeToUpdate,
    activateLsRequest,
    clickedLsRequest,
    columns,
    currentDupsearchLsNum,
    getFileAndDownload,
    id,
    markNotDuplicate,
    markOverlapping,
    markRelated,
    onChangeCreateSubLS,
    onChangeToBillHistory,
    onGetLsRequest,
    onOpenOrCloseSidebar,
    onSortByHeader,
    openOrCloseLsRequestServicePopup,
    overlapDuplicates,
    relatedDuplicates,
    searchTerms,
    sortedBy,
    userProfile,
  } = props;

  useEffect(() => {
    function scrollBarDetect(e) {
      const scrollPosition = e.target.scrollHeight - e.target.scrollTop;
      if (
        scrollPosition < 1500 &&
        currentSearchType !== 'Automated' &&
        !isLoading
      ) {
        loadMoreLsRequests();
      }
    }

    const tableContainerNode = tableContainerRef.current;
    if (tableContainerNode) {
      tableContainerRef.current.addEventListener(
        'scroll',
        scrollBarDetect,
        false
      );
    }

    return () => {
      if (tableContainerNode) {
        tableContainerNode.removeEventListener(
          'scroll',
          scrollBarDetect,
          false
        );
      }
    };
  }, [isLoading, currentSearchType, loadMoreLsRequests]);

  // Set error messages on change to loading, search, and/or ls request
  useEffect(() => {
    if (!isLoading && invalidSearch) {
      setErrorMessage('Invalid syntax found in search term(s)');
      setErrorImage('/../img/warning.svg');
      setAltTag('Invalid syntax warning');
    } else if (!isLoading && lsRequestsProps.length === 0) {
      setErrorMessage('No valid result');
      setErrorImage('/../img/no-results-found.svg');
      setAltTag('No Valid Results');
    } else {
      setErrorMessage('');
      setErrorImage('');
      setAltTag('');
    }
  }, [isLoading, invalidSearch, lsRequestsProps]);

  /**
   * useEffect hook for when currentSearchType changes, update lsRequests to be displayed as well
   */
  useEffect(() => {
    if (currentSearchType === 'Automated' && duplicateCheckResults) {
      setLsRequests(duplicateCheckResults);
    } else if (currentSearchType === 'Review') {
      setLsRequests(dupArray);
    } else {
      setLsRequests(lsRequestsProps);
    }
  }, [currentSearchType, duplicateCheckResults, lsRequestsProps, dupArray]);

  // Open update popup window
  function onOpenUpdate(ls) {
    onChangeToUpdate();
    onGetLsRequest(ls);
    document.body.style.overflow = 'hidden';
  }

  /* 
  function onOpenSubLS () {
    onChangeCreateSubLS();
    openOrCloseLsRequestServicePopup(true);
    onGetLsRequest();
    document.body.style.overflow = "hidden";
  }

  // Open bill history popup
  function onOpenBillHistory () {
    onChangeToBillHistory();
    openOrCloseLsRequestServicePopup(true);
    onGetLsRequest();
    document.body.style.overflow = "hidden";
  }
 */
  function onOpenDuplicateCheck(id) {
    window.open(
      `${window.location.origin}/lsRequestList/LsDupSearch/${id.toString()}`
    );
  }

  function onOpenSidebar(ls) {
    onOpenOrCloseSidebar(true);
    clickedLsRequest(ls, 'clicked');
    document.body.style.overflow = 'hidden';
  }

  function requestActivation(ls) {
    const userInput = window.confirm(
      'An activation request will be submitted and reviewed internally.\nYou will be notified upon activation of any LS Request.\n\nDo you want to proceed?'
    );
    if (userInput) {
      const toast = {
        showToast: true,
        toastTitle: 'LSR Activation Requested',
        toastBody: `Activation Request for LS Request #${ls.ls_number} was successful.`,
        toastApp: '/img/Navbar/lsr.png',
        toastAppAlt: 'LSR Request Icon',
      };
      localStorage.setItem('toastInfo', JSON.stringify(toast));
      activateLsRequest(ls.ls_number);
    }
  }

  const lsRowColor = ls_num => {
    if (overlapDuplicates.includes(ls_num)) {
      return '#FBD9D3';
    } else if (relatedDuplicates.includes(ls_num)) {
      return '#FFFCC9';
    }
  };

  const openEditModal = (
    e,
    ls,
    editModalType,
    editModalValue,
    isFile = false
  ) => {
    const cmEditFields = [
      'attachment_descriptions',
      'contact_person',
      'cm_attachment',
      'matters',
      'make_first_prime_public',
    ];
    const staffEditFields = [
      'affirmation',
      'attachment_descriptions',
      'bill_matrix',
      'bill',
      'cm_attachment',
      'cm_notes',
      'committee',
      'confidential_comments',
      'contact_person',
      'coversheet',
      'division',
      'first_in_time',
      'first_prime',
      'hearing_report',
      'hearing_summary',
      'legal_memo',
      'matters',
      'ls_type',
      'make_first_prime_public',
      'plain_language_summary',
      'prev_sess_ls_number',
      'reintroduction',
      'operational_requirement',
      'resource_letter',
      'staff',
      'status',
    ];
    // Do nothing if any of these conditionas are true when right clicking on a cell
    if (
      // If CM-related only allow edit of waiver if not previously checked/True
      (editModalType === 'make_first_prime_public' &&
        editModalValue === true &&
        rolesUnderCouncilMembers.includes(userProfile.role)) ||
      // If CM-related and field is NOT in CM editable field
      (rolesUnderCouncilMembers.includes(userProfile.role) &&
        !cmEditFields.includes(editModalType)) ||
      // If NOT CM-related and field is NOT in staff editable field
      (!rolesUnderCouncilMembers.includes(userProfile.role) &&
        !staffEditFields.includes(editModalType)) ||
      // If LS belongs to a former CM and is "withdrawn"
      ls.status === 'Withdrawn - Former CM'
    ) {
      return;
    }

    e.preventDefault();
    setIsFile(isFile);
    clickedLsRequest(ls, 'clicked');
    setEditModalType(editModalType);
    setEditModalValue(editModalValue);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalType('');
    setEditModalValue('');
    setIsEditModalOpen(false);
  };

  function replaceAtIndex(_string, _index, _keyword) {
    let endingCharIndex = _string
      .slice(_index + _keyword.length)
      .search(/[\s\b.,/#!$%^&*;:{}=\-_`~()]/g);
    let endOfKeyword =
      endingCharIndex > -1
        ? _index + _keyword.length + endingCharIndex
        : _index + _keyword.length;
    let newValue = `<span class='highlight'>${_string.slice(
      _index,
      endOfKeyword
    )}</span>`;
    return `${_string.substring(0, _index)}${newValue}${_string.substring(
      endOfKeyword
    )}`;
  }

  function highlightTerms(text) {
    if (!searchTerms) return text; // nothing to highlight

    text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    for (let term of searchTerms) {
      let termRegex = new RegExp(`\\b(${term})\\w*\\b`, 'gi');
      let indexMatches = [];
      let matchFound;

      while ((matchFound = termRegex.exec(text)) != null) {
        indexMatches.unshift(matchFound.index);
      }

      for (let i = 0; i < indexMatches.length; i++) {
        text = replaceAtIndex(text, indexMatches[i], term);
      }
    }

    return text;
  }

  /** Table Headers */
  // Display the table header
  const tableHeaders = columns.map((column, idx) => {
    if (
      !(
        rolesUnderCouncilMembers.includes(userProfile.role) &&
        column.field === 'confidential_comments'
      )
    ) {
      return (
        (column.show || column.field === 'ls_number') && (
          <React.Fragment key={idx}>
            <TableHeader
              sortColumn={() => {
                onSortByHeader(
                  column.field === 'days_past_60'
                    ? 'last_activated'
                    : column.field
                );
              }}
              title={column.title}
              field={sortedBy}
              showSort={sortedBy.includes(
                column.field === 'days_past_60'
                  ? 'last_activated'
                  : column.field
              )}
              key={idx}
              description={column.description}
              toolTipId={idx}
            />
            <ColumnResizer className={styles['resizer']} />
          </React.Fragment>
        )
      );
    }
    return null;
  });
  /** End Table Headers */

  /** Table Content (Rows) */
  // Display table rows -> lsRequests state mapped
  const tableRows = lsRequests.map((ls, idx) => {
    // If the ls number is the one currently being reviewed, exclude from table
    if (ls.ls_number !== currentDupsearchLsNum) {
      // All other ls requests
      let rowClassName =
        ls.status === 'Withdrawn - Former CM'
          ? `${styles['archived-ls']} `
          : '';
      if (overlapDuplicates && overlapDuplicates.includes(ls.ls_number)) {
        rowClassName += 'table-tr-overlapping';
      } else if (
        relatedDuplicates &&
        relatedDuplicates.includes(ls.ls_number)
      ) {
        rowClassName += 'table-tr-related';
      } else {
        rowClassName += 'table-tr';
      }
      return (
        <tr
          className={rowClassName}
          key={idx}
          style={
            window.location.pathname.includes('LsDupSearch')
              ? { backgroundColor: lsRowColor(ls.ls_number) }
              : {}
          }>
          {columns.map((column, idx1) => {
            let cell;
            let field = column.field;
            let value = ls[field] || '';

            if (field === 'ls_number') {
              cell = <td key={idx1}>{value}</td>;
            } else if (column.show) {
              if (field === 'date_received') {
                cell = <td key={idx1}>{formatDateTime(ls.date_received)}</td>;
              } else if (column.type === 'file') {
                const files = value;
                cell = (
                  <td
                    key={idx1}
                    onContextMenu={e =>
                      openEditModal(e, ls, field, value.id, true)
                    }>
                    {files.map((file, index) => (
                      <File
                        id={`lsr_${field}-${idx}-${idx1}-${index}`}
                        file={file}
                        getFileAndDownload={getFileAndDownload}
                        key={index}
                      />
                    ))}
                  </td>
                );
              } else if (field === 'committee') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openEditModal(e, ls, field, value.id)}>
                    {parse(highlightTerms(value.committee_name))}
                  </td>
                );
              } else if (field === 'associated_report_tracking') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openEditModal(e, ls, field, value.id)}>
                    {parse(
                      value ? value.map(report => report.title).join(', ') : ''
                    )}
                  </td>
                );
              } else if (
                ['first_prime', 'staff', 'submitted_by'].includes(field)
              ) {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openEditModal(e, ls, field, value.id)}>
                    {parse(highlightTerms(value.full_name))}
                  </td>
                );
              } else if (field === 'contact_person') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openEditModal(e, ls, field, value.id)}
                    id={`${ls.ls_number}-${field}`}>
                    <pre className={styles['pre']}>
                      {parse(highlightTerms(value.full_name))}
                      <br />
                      {parse(value.phone)}
                      <br />
                      {parse(value.email)}
                    </pre>
                  </td>
                );
              } else if (
                [
                  'description_problem',
                  'description_legislative_solution',
                  'description_related_discussions',
                  'cm_notes',
                  'division',
                  'ls_type',
                  'first_in_time',
                  'status',
                  'confidential_comments',
                  'background',
                  'background_inspiration',
                  'background_existing_laws',
                  'attachment_descriptions',
                ].includes(field)
              ) {
                if (field === 'confidential_comments') {
                  value = value ? value : '';
                }
                cell = (
                  <td
                    className={styles['ls-' + field.replace(/_/g, '-')]}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e =>
                      openEditModal(e, ls, field, parse(value))
                    }
                    key={idx1}>
                    <div>{parse(highlightTerms(value))}</div>
                  </td>
                );
              } else if (field === 'reintroduction') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => openEditModal(e, ls, field, value)}
                    key={idx1}>
                    {value === true ? 'Yes' : value === false ? 'No' : 'N/A'}
                  </td>
                );
              } else if (field === 'prev_sess_ls_number') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e =>
                      openEditModal(e, ls, field, parse(value))
                    }
                    key={idx1}>
                    {value ? value : 'N/A'}
                  </td>
                );
              } else if (
                [
                  'operational_requirement',
                  'make_first_prime_public',
                  'duplicate_check',
                  'activation_requested',
                ].includes(field)
              ) {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => openEditModal(e, ls, field, value)}
                    key={idx1}>
                    {value ? 'True' : 'False'}
                  </td>
                );
              } else if (['overlapping_ls', 'related_ls'].includes(field)) {
                let lsArray = value.map(ls => ls.ls_number);
                cell = (
                  <td
                    className={styles['ls-duplicates']}
                    onClick={() => onOpenSidebar(ls)}
                    key={`ls-${field}-${ls['ls_number']}`}>
                    {lsArray.length < 1 ? null : (
                      <DuplicatesTooltip
                        index={`ls-${field}-${ls['ls_number']}`}
                        dupes={lsArray}
                        field={field.replace(/_/g, ' ').toUpperCase()}
                      />
                    )}
                  </td>
                );
              } else if (field === 'last_activated') {
                let sixtyText = null;
                if (value) {
                  let activated = new Date(value);
                  activated.setDate(activated.getDate() + 1);
                  let today = new Date();
                  let daysDifference = Math.ceil(
                    Math.abs(today - activated) / (1000 * 60 * 60 * 24)
                  ); // ms * s * min * hour
                  if (daysDifference <= 60) {
                    sixtyText = (
                      <span
                        style={{
                          color: '#4A5F96',
                          fontSize: '.9em',
                          fontWeight: 'bold',
                        }}>{`${
                        60 - daysDifference
                      } day(s) UNTIL 60 days`}</span>
                    );
                  } else {
                    sixtyText = (
                      <span
                        style={{
                          color: '#CA4A4A',
                          fontSize: '.9em',
                          fontWeight: 'bold',
                        }}>{`${daysDifference - 60} day(s) PAST 60 days`}</span>
                    );
                  }
                }
                cell = (
                  <td
                    className={styles['ls-duplicates']}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={() => openEditModal(ls)}
                    key={`ls-${field}-${ls['ls_number']}`}>
                    {parse(value)}
                    {!rolesUnderCouncilMembers.includes(userProfile.role) &&
                      activeStatuses.includes(ls['status']) && (
                        <>
                          <br />
                          {sixtyText}
                        </>
                      )}
                  </td>
                );
              } else if (field === 'matters') {
                let matterArray = value.map(matter => {
                  if (matter.law_number) {
                    return matter.law_number;
                  } else {
                    return matter.intro;
                  }
                });

                cell = (
                  <td
                    className={styles['ls-duplicates']}
                    onClick={() => onOpenSidebar(ls)}
                    key={`ls-${field}-${ls['ls_number']}`}>
                    {matterArray.length < 1 ? null : matterArray.join(', ')}
                  </td>
                );
              } else {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openEditModal(e, ls, field, value.id)}>
                    {parse(value)}
                  </td>
                );
              }
            }
            return (
              <React.Fragment key={idx1}>
                {cell}
                {cell && <td className={styles['resizer']} />}
              </React.Fragment>
            );
          })}
          <td>
            {(ls.status !== 'Withdrawn - Former CM' ||
              [...superAdminRoles, ...adminRoles, ...fourthTierRoles].includes(
                props.userProfile.role
              )) && (
              <div className='dropdown dropstart'>
                <button
                  className={`btn ${styles['column-menu']}`}
                  type='button'
                  id={`ls-options-dropdown-${ls.ls_number}`}
                  data-bs-toggle='dropdown'
                  data-bs-offset='0,11'
                  data-bs-reference='parent'
                  aria-expanded='false'>
                  <ThreeDotsVertical />
                </button>
                <ul
                  className={`dropdown-menu ${styles['dropdown-menu-adjusted']}`}
                  aria-labelledby={`ls-options-dropdown-${ls.ls_number}`}>
                  {!window.location.pathname.includes('LsDupSearch') && (
                    <>
                      {ls.status === 'Low-Priority Hold' &&
                        !ls.activation_requested && (
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => requestActivation(ls)}>
                              Request Activation
                            </button>
                          </li>
                        )}
                      {userProfile.permissions.lsRequest_api
                        .change_lsrequest && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => onOpenUpdate(ls)}>
                            Update LS Request
                          </button>
                        </li>
                      )}
                      {/* Create Sub LS */}
                      {/* {
                        userProfile.permissions.lsRequest_api.add_lsrequest && [...legDivisionRoles, ...attorneyRoles].includes(userProfile.role) &&
                          <li>
                            <button className="dropdown-item" type="button" onClick={onOpenSubLS}>
                              Create Sub LS
                            </button>
                          </li>
                      } */}
                      {/* END Create Sub LS */}
                      {userProfile.permissions.lsRequest_api.view_lsrequest && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              let path = window.location.pathname.includes(
                                'myTask'
                              )
                                ? window.location.pathname
                                    .split('/')
                                    .slice(0, 3)
                                    .join('/')
                                : window.location.pathname
                                    .split('/')
                                    .slice(0, 2)
                                    .join('/');
                              window.location.href = `${path}/${ls.ls_number}/history`;
                            }}>
                            View Change History
                          </button>
                        </li>
                      )}
                      {/*
                      <li>
                        <button className="dropdown-item" type="button" onClick={onOpenBillHistory} >
                          View Bill History
                        </button>
                      </li> */}
                      {!rolesUnderCouncilMembers.includes(userProfile.role) &&
                        ls.status !== 'Withdrawn - Former CM' && (
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => {
                                onOpenDuplicateCheck(ls.ls_number);
                              }}>
                              Duplicate Search
                            </button>
                          </li>
                        )}
                    </>
                  )}
                  {window.location.pathname.includes('LsDupSearch') && (
                    <>
                      {!overlapDuplicates.includes(ls.ls_number) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              markOverlapping(ls);
                            }}>
                            Mark Overlapping
                          </button>
                        </li>
                      )}
                      {!relatedDuplicates.includes(ls.ls_number) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              markRelated(ls);
                            }}>
                            Mark Related
                          </button>
                        </li>
                      )}
                      {(overlapDuplicates.includes(ls.ls_number) ||
                        relatedDuplicates.includes(ls.ls_number)) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              markNotDuplicate(ls);
                            }}>
                            Unmark Duplicate
                          </button>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
            )}
          </td>
        </tr>
      );
    }
    return null;
  });
  /** End Table Content */
  return (
    <>
      {isEditModalOpen && (
        <CellEditModal
          cellType={editModalType}
          cellValue={editModalValue}
          closeModal={closeEditModal}
          isFile={isFile}
        />
      )}
      {isEditModalOpen && (
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 98,
          }}
          onClick={closeEditModal}
        />
      )}
      <div
        ref={tableContainerRef}
        id={id}
        className={styles['table-container']}>
        <table
          ref={tableRef}
          id='lsrTable'
          cellSpacing='0'
          className={`${styles['list-table']} list-table`}>
          <thead>
            <tr className='table-headers'>
              {tableHeaders}
              <th className={styles['checkbox-header']}></th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
        {!isLoading && (lsRequestsProps.length === 0 || invalidSearch) && (
          <div
            className={`w-100 text-center p-3 ${styles['no-valid-result-container']}`}>
            <img src={errorImage} alt={altTag}></img>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>

      {(isLoading ||
        (window.location.pathname.includes('LsDupSearch') &&
          (duplicateCheckResults === undefined ||
            duplicateCheckResults.length === 0))) && (
        <div
          className={`spinner-grow ${styles['spinner-animation']}`}
          role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    committeeList: state.userReducer.committees,
    invalidSearch: state.lsRequestsReducer.invalidSearch,
    isLoading: state.lsRequestsReducer.isLoading,
    lsRequests: state.lsRequestsReducer.lsRequests,
    selectedLs: state.lsRequestsReducer.updateTargetLs,
    nextApi: state.lsRequestsReducer.nextApi,
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    duplicateCheckResults: state.lsRequestsReducer.duplicateCheckResults,
    searchTerms: state.lsRequestsReducer.searchTerms,
  };
};

const mapDispatchToProps = {
  activateLsRequest,
  clickedLsRequest,
  getFileAndDownload,
  openOrCloseLsRequestServicePopup,
  updateTargetLsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsRequestsTable);
