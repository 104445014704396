import React, { useState, useEffect } from 'react';
import { Spinner, Table } from 'reactstrap';
import styles from '../../../styles/user/dashboard/MyTasks.module.scss';

const LsRequestTable = props => {
  const [columns, setColumns] = useState([
    {
      title: 'LSR #',
      field: 'ls_number',
    },
    {
      title: 'Division',
      field: 'division',
    },
    {
      title: 'Committee',
      field: 'committee',
    },
    {
      title: 'First Prime',
      field: 'first_prime',
    },
    {
      title: 'LSR Type',
      field: 'ls_type',
    },
  ]);

  /** component mount */
  useEffect(() => {
    props.tableType === 'mine'
      ? setColumns(prevColumns => [
          ...prevColumns,
          { title: '60 Days', field: 'last_activated' },
        ])
      : setColumns(prevColumns => [
          ...prevColumns,
          { title: 'Status', field: 'status' },
          { title: 'Date Received', field: 'date_received' },
        ]);
  }, [props.tableType]);

  const displayTableHeaders = columns.map((column, idx) => (
    <th className={styles['ls-dashboard-table-headers']} key={idx}>
      {column.title}
    </th>
  ));
  // filter myLs
  let lsList = props.myLs;
  const myLsDisplay =
    lsList &&
    lsList.map((ls, idx) => {
      let date = ls.date_received.substring(0, 10).split('-');
      const time = new Date(date[0], parseInt(date[1]) - 1, date[2]);
      let days_60 = new Date(ls.last_activated);
      days_60.setDate(days_60.getDate() + 60);
      return (
        <tr
          onClick={() =>
            window.open(
              `${window.location.origin}/myTasks/myActiveLs/${ls.ls_number}`
            )
          }
          key={idx}>
          {columns.map((column, idx1) => {
            if (column.field === 'ls_number') {
              return <td key={idx1}>{ls[column.field]}</td>;
            } else if (column.field === 'date_received') {
              return <td key={idx1}>{time.toLocaleDateString()}</td>;
            } else if (column.field === 'last_activated') {
              return <td key={idx1}>{days_60.toLocaleDateString()}</td>;
            } else if (column.field === 'committee') {
              return (
                <td key={idx1}>
                  {ls[column.field] ? ls[column.field].committee_name : ''}
                </td>
              );
            } else if (
              ['first_prime', 'staff', 'submitted_by'].includes(column.field)
            ) {
              return <td key={idx1}>{ls[column.field].full_name}</td>;
            } else {
              return <td key={idx1}>{ls[column.field]}</td>;
            }
          })}
        </tr>
      );
    });

  return (
    <Table
      className={`${styles['ls-dashboard-table']} ${styles['flex-table']}`}
      responsive
      hover>
      <thead>
        <tr>{displayTableHeaders}</tr>
      </thead>
      <tbody>
        {myLsDisplay}
        {props.loading && (
          <tr className='loading-container' style={{ textAlign: 'center' }}>
            <td colSpan={columns.length}>
              <Spinner color='secondary' />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default LsRequestTable;
