export const wikiPageActions = {
  LOAD_WIKI_PAGE: 'LOAD_WIKI_PAGE',
  LOAD_SUBJECT_PAGE: 'LOAD_SUBJECT_PAGE',
  LOAD_ROOT_SUBJECTS: 'LOAD_ROOT_SUBJECTS',
  LOAD_SIDEBAR_SUBJECTS: 'LOAD_SIDEBAR_SUBJECTS',
  LOAD_BREADCRUMB_SUBJECTS: 'LOAD_BREADCRUMB_SUBJECTS',
  LOAD_SUBJECTS: 'LOAD_SUBJECTS',
  STORE_ROOT_SUBJECTS: 'STORE_ROOT_SUBJECTS',
  STORE_PARENT_SUBJECTS: 'STORE_PARENT_SUBJECTS',
  CHECKING_SUBJECTS: 'CHECKING_SUBJECTS',
  STORE_SUBJECTS: 'STORE_SUBJECTS',
  STORE_SUBJECT: 'STORE_SUBJECT',
  CREATE_SUBJECT: 'CREATE_SUBJECT',
  LOAD_ALL_SUBJECTS: 'LOAD_ALL_SUBJECTS',
  STORE_ALL_SUBJECTS: 'STORE_ALL_SUBJECTS',
  STORE_FLAT_SUBJECT_TREE: 'STORE_FLAT_SUBJECT_TREE',
  LOAD_PAGES: 'LOAD_PAGES',
  CHECKING_PAGES: 'CHECKING_PAGES',
  STORE_PAGES: 'STORE_PAGES',
  STORE_ALL_PAGES: 'STORE_ALL_PAGES',
  CREATE_PAGE: 'CREATE_PAGE',
  LOAD_PAGE: 'LOAD_PAGE',
  STORE_PAGE: 'STORE_PAGE',
  STORE_PAGE_DIFF: 'STORE_PAGE_DIFF',
  PAGE_UPDATE: 'PAGE_UPDATE',
  REVERT_PAGE: 'REVERT_PAGE',
  CREATE_PAGE_REVIEW: 'CREATE_PAGE_REVIEW',
  PAGE_REVIEW_UPDATE: 'PAGE_REVIEW_UPDATE',
  DELETE_PAGE_REVIEW: 'DELETE_PAGE_REVIEW',
  LOAD_PAGE_HISTORY: 'LOAD_PAGE_HISTORY',
  STORE_PAGE_HISTORY: 'STORE_PAGE_HISTORY',
  LOAD_RECENT_PAGES: 'LOAD_RECENT_PAGES',
  STORE_RECENT_PAGES: 'STORE_RECENT_PAGES',
  SELECT_REVISION: 'SELECT_REVISION',
  CHECKING_COMMENTS: 'CHECKING_COMMENTS',
  STORE_COMMENTS: 'STORE_COMMENTS',
  LOAD_COMMENTS: 'LOAD_COMMENTS',
  ADD_COMMENT: 'ADD_COMMENT',
  UPDATE_SECTIONS: 'UPDATE_SECTIONS',
  UPDATE_PAGE_FORM: 'UPDATE_PAGE_FORM',
  LOAD_PAGE_FORM_MODE: 'LOAD_PAGE_FORM_MODE',
  LOAD_SUBJECT_FORM_MODE: 'LOAD_SUBJECT_FORM_MODE',
  LOAD_ALL_PAGES: 'LOAD_ALL_PAGES',
  STORE_ORDER_ROOT_SUBJECTS: 'STORE_ORDER_ROOT_SUBJECTS',
  STORE_ORDER_CHILD_SUBJECTS: 'STORE_ORDER_CHILD_SUBJECTS',
  REORDER_CHILD_SUBJECTS: 'REORDER_CHILD_SUBJECTS',
  SUBJECT_UPDATE: 'SUBJECT_UPDATE',
  STORE_LOCK_STATUS: 'STORE_LOCK_STATUS',
  DISPLAY_SUBJECT_UPDATE: 'DISPLAY_SUBJECT_UPDATE',
  STORE_CURRENT_SUBJECT: 'STORE_CURRENT_SUBJECT',
  STORE_SELECT_PAGES: 'STORE_SELECT_PAGES',
  STORE_EDIT_LOCK: 'STORE_EDIT_LOCK',
  STORE_CURRENT_PAGE: 'STORE_CURRENT_PAGE',
  STORE_PAGE_COMMITTEES: 'STORE_PAGE_COMMITTEES',
  DELETE_SUBJECT: 'DELETE_SUBJECT',
  DELETE_PAGE: 'DELETE_PAGE',
  PAGE_FORM_FAIL: 'PAGE_FORM_FAIL',
  SUBJECT_FORM_FAIL: 'SUBJECT_FORM_FAIL',
  TAG_FORM_FAIL: 'TAG_FORM_FAIL',
  PAGE_SUBMIT_STATUS: 'PAGE_SUBMIT_STATUS',
  DELETE_PAGE_MODAL: 'DELETE_PAGE_MODAL',
  DELETE_SUBJECT_MODAL: 'DELETE_SUBJECT_MODAL',
  SUBJECT_FORM_SUCCESS: 'SUBJECT_FORM_SUCCESS',
  PAGE_FORM_SUCCESS: 'PAGE_FORM_SUCCESS',
  TAG_FORM_SUCCESS: 'TAG_FORM_SUCCESS',
  SUBJECT_FORM_LOAD: 'SUBJECT_FORM_LOAD',
  PAGE_FORM_LOAD: 'PAGE_FORM_LOAD',
  SUBJECT_PAGE_ERROR: 'SUBJECT_PAGE_ERROR',
  WIKI_PAGE_ERROR: 'WIKI_PAGE_ERROR',
  REVERT_PAGE_FAIL: 'REVERT_PAGE_FAIL',
  PAGE_IN_SUBJECT: 'PAGE_IN_SUBJECT',
  LOAD_ALL_TAGS: 'LOAD_ALL_TAGS',
  LOAD_TAG: 'LOAD_TAG',
  LOAD_TAGGED_PAGES: 'LOAD_TAGGED_PAGES',
  STORE_ALL_TAGS: 'STORE_ALL_TAGS',
  STORE_TAG: 'STORE_TAG',
  STORE_TAGGED_PAGES: 'STORE_TAGGED_PAGES',
  ADD_TAG: 'CREATE_TAG',
  TAG_UPDATE: 'TAG_UPDATE',
  DELETE_TAG: 'DELETE_TAG',
  SELECT_TAG: 'SELECT_TAG',
  LOAD_WIKI_SEARCH_RESULTS: 'LOAD_WIKI_SEARCH_RESULTS',
  STORE_WIKI_SEARCH_RESULTS: 'STORE_WIKI_SEARCH_RESULTS',
  STORE_URL_SEARCH_PARAMS: 'STORE_URL_SEARCH_PARAMS',
  STORE_DIRECTOR: 'STORE_DIRECTOR',
};

//Load and Store Wiki Information
export const loadWikiPage = page => {
  return { type: wikiPageActions.LOAD_WIKI_PAGE, page };
};

export const loadSubjectPage = subject => {
  return { type: wikiPageActions.LOAD_SUBJECT_PAGE, subject };
};

export const loadSidebarSubjects = subjects => {
  return { type: wikiPageActions.LOAD_SIDEBAR_SUBJECTS, subjects };
};

export const loadBreadcrumbSubjects = (subject_id, location) => {
  return {
    type: wikiPageActions.LOAD_BREADCRUMB_SUBJECTS,
    subject_id,
    location,
  };
};

export const loadRootSubjects = location => {
  return { type: wikiPageActions.LOAD_ROOT_SUBJECTS, location };
};

export const storeRootSubjects = subjects => {
  return { type: wikiPageActions.STORE_ROOT_SUBJECTS, subjects };
};

export const storeParentSubjects = parents => {
  return { type: wikiPageActions.STORE_PARENT_SUBJECTS, parents };
};

//Subject: Load, Check, and Store
export const loadSubjects = (subject_id, location) => {
  return { type: wikiPageActions.LOAD_SUBJECTS, subject_id, location };
};

export const checkingSubjects = status => {
  return { type: wikiPageActions.CHECKING_SUBJECTS, status };
};

export const storeSubject = subject => {
  return { type: wikiPageActions.STORE_SUBJECT, subject };
};

export const storeSubjects = subjects => {
  return { type: wikiPageActions.STORE_SUBJECTS, subjects };
};

export const loadAllSubjects = tree => {
  return { type: wikiPageActions.LOAD_ALL_SUBJECTS, tree };
};

export const storeAllSubjects = all_subjects => {
  return { type: wikiPageActions.STORE_ALL_SUBJECTS, all_subjects };
};

export const storeFlatSubjectTree = flatTree => {
  return { type: wikiPageActions.STORE_FLAT_SUBJECT_TREE, flatTree };
};

export const createSubject = (subject, title, order, user, connections) => {
  return {
    type: wikiPageActions.CREATE_SUBJECT,
    subject,
    title,
    order,
    user,
    connections,
  };
};

//Page: Load, Check, and Store
export const loadPages = (subject_id, location) => {
  return { type: wikiPageActions.LOAD_PAGES, subject_id, location };
};

export const loadAllPages = () => {
  return { type: wikiPageActions.LOAD_ALL_PAGES };
};

export const checkingPages = status => {
  return { type: wikiPageActions.CHECKING_PAGES, status };
};

export const storePages = pages => {
  return { type: wikiPageActions.STORE_PAGES, pages };
};

export const storeAllPages = pages => {
  return { type: wikiPageActions.STORE_ALL_PAGES, pages };
};

export const loadPage = (page_id, diff, subject) => {
  return { type: wikiPageActions.LOAD_PAGE, page_id, diff, subject };
};

export const storePage = page => {
  return { type: wikiPageActions.STORE_PAGE, page };
};

export const storePageDiff = page => {
  return { type: wikiPageActions.STORE_PAGE_DIFF, page };
};

export const createWikiPage = (
  author,
  subject,
  title,
  memo_regarding,
  committees,
  tags,
  description,
  content,
  review
) => {
  return {
    type: wikiPageActions.CREATE_PAGE,
    author,
    subject,
    title,
    memo_regarding,
    committees,
    tags,
    description,
    content,
    review,
  };
};

export const pageUpdate = (page, lock, review) => {
  return { type: wikiPageActions.PAGE_UPDATE, page, lock, review };
};

export const createPageReview = (
  page,
  author,
  subject,
  title,
  memo_regarding,
  committees,
  tags,
  description,
  content,
  messages
) => {
  return {
    type: wikiPageActions.CREATE_PAGE_REVIEW,
    page,
    author,
    subject,
    title,
    memo_regarding,
    committees,
    tags,
    description,
    content,
    messages,
  };
};

export const pageReviewUpdate = pageReview => {
  return { type: wikiPageActions.PAGE_REVIEW_UPDATE, pageReview };
};

export const deletePageReview = (pageReview, redirectPath = null) => {
  return { type: wikiPageActions.DELETE_PAGE_REVIEW, pageReview, redirectPath };
};

export const loadPageHistory = page_id => {
  return { type: wikiPageActions.LOAD_PAGE_HISTORY, page_id };
};

export const storePageHistory = archives => {
  return { type: wikiPageActions.STORE_PAGE_HISTORY, archives };
};

export const loadRecentPages = () => {
  return { type: wikiPageActions.LOAD_RECENT_PAGES };
};

export const storeRecentPages = pages => {
  return { type: wikiPageActions.STORE_RECENT_PAGES, pages };
};

export const selectRevision = (revision, index) => {
  return { type: wikiPageActions.SELECT_REVISION, revision, index };
};

export const revertPage = (page, revision) => {
  return { type: wikiPageActions.REVERT_PAGE, page, revision };
};

export const revertPageFail = message => {
  return { type: wikiPageActions.REVERT_PAGE_FAIL, message };
};

export const pageInSubject = bool => {
  return { type: wikiPageActions.PAGE_IN_SUBJECT, bool };
};

//Comment: Load, Check, Store, and Add
export const loadComments = page_id => {
  return { type: wikiPageActions.LOAD_COMMENTS, page_id };
};

export const checkingComments = status => {
  return { type: wikiPageActions.CHECKING_COMMENTS, status };
};

export const storeComments = comments => {
  return { type: wikiPageActions.STORE_COMMENTS, comments };
};

export const addComment = (page_id, content) => {
  return { type: wikiPageActions.ADD_COMMENT, page_id, content };
};

//Create and Update actions
export const updateSections = sections => {
  return { type: wikiPageActions.UPDATE_SECTIONS, sections };
};

export const storeOrderRootSubjects = subjects => {
  return { type: wikiPageActions.STORE_ORDER_ROOT_SUBJECTS, subjects };
};

export const storeOrderChildSubjects = subjects => {
  return { type: wikiPageActions.STORE_ORDER_CHILD_SUBJECTS, subjects };
};

export const reorderChildSubjects = subjects => {
  return { type: wikiPageActions.REORDER_CHILD_SUBJECTS, subjects };
};

export const subjectUpdate = (subject, lock) => {
  return { type: wikiPageActions.SUBJECT_UPDATE, subject, lock };
};

export const storeLockStatus = lock => {
  return { type: wikiPageActions.STORE_LOCK_STATUS, lock };
};

export const displaySubjectUpdate = subject => {
  return { type: wikiPageActions.DISPLAY_SUBJECT_UPDATE, subject };
};

export const storeCurrentSubject = subject => {
  return { type: wikiPageActions.STORE_CURRENT_SUBJECT, subject };
};

export const storeSelectPages = pages => {
  return { type: wikiPageActions.STORE_SELECT_PAGES, pages };
};

export const storeEditLock = lock => {
  return { type: wikiPageActions.STORE_EDIT_LOCK, lock };
};

export const storeCurrentPage = page => {
  return { type: wikiPageActions.STORE_CURRENT_PAGE, page };
};

export const storePageCommittees = committees => {
  return { type: wikiPageActions.STORE_PAGE_COMMITTEES, committees };
};

export const deleteSubject = subject => {
  return { type: wikiPageActions.DELETE_SUBJECT, subject };
};

export const deletePage = page => {
  return { type: wikiPageActions.DELETE_PAGE, page };
};

//Form actions
export const pageFormFail = message => {
  return { type: wikiPageActions.PAGE_FORM_FAIL, message };
};

export const subjectFormFail = message => {
  return { type: wikiPageActions.SUBJECT_FORM_FAIL, message };
};

export const pageSubmitStatus = status => {
  return { type: wikiPageActions.PAGE_SUBMIT_STATUS, status };
};

export const deletePageModal = display => {
  return { type: wikiPageActions.DELETE_PAGE_MODAL, display };
};

export const deleteSubjectModal = display => {
  return { type: wikiPageActions.DELETE_SUBJECT_MODAL, display };
};

export const subjectFormSuccess = message => {
  return { type: wikiPageActions.SUBJECT_FORM_SUCCESS, message };
};

export const pageFormSuccess = message => {
  return { type: wikiPageActions.PAGE_FORM_SUCCESS, message };
};

export const pageFormLoad = status => {
  return { type: wikiPageActions.PAGE_FORM_LOAD, status };
};

export const subjectFormLoad = status => {
  return { type: wikiPageActions.SUBJECT_FORM_LOAD, status };
};

export const subjectPageError = status => {
  return { type: wikiPageActions.SUBJECT_PAGE_ERROR, status };
};

export const wikiPageError = status => {
  return { type: wikiPageActions.WIKI_PAGE_ERROR, status };
};
//Tag actions
export const loadTag = tag => {
  return { type: wikiPageActions.LOAD_TAG, tag };
};

export const loadAllTags = location => {
  return { type: wikiPageActions.LOAD_ALL_TAGS, location };
};

export const loadTaggedPages = tag => {
  return { type: wikiPageActions.LOAD_TAGGED_PAGES, tag };
};

export const storeAllTags = tags => {
  return { type: wikiPageActions.STORE_ALL_TAGS, tags };
};

export const storeTag = tag => {
  return { type: wikiPageActions.STORE_TAG, tag };
};

export const storeTaggedPages = pages => {
  return { type: wikiPageActions.STORE_TAGGED_PAGES, pages };
};

export const tagFormFail = message => {
  return { type: wikiPageActions.TAG_FORM_FAIL, message };
};

export const tagFormSuccess = message => {
  return { type: wikiPageActions.TAG_FORM_SUCCESS, message };
};

export const addTag = tag => {
  return { type: wikiPageActions.ADD_TAG, tag };
};

export const tagUpdate = tag => {
  return { type: wikiPageActions.TAG_UPDATE, tag };
};

export const deleteTag = tag => {
  return { type: wikiPageActions.DELETE_TAG, tag };
};

export const selectTag = tag => {
  return { type: wikiPageActions.SELECT_TAG, tag };
};

//Search actions
export const loadWikiSearchResults = params => {
  return { type: wikiPageActions.LOAD_WIKI_SEARCH_RESULTS, params };
};

export const storeWikiPageSearchResults = results => {
  return { type: wikiPageActions.STORE_WIKI_SEARCH_RESULTS, results };
};

export const storeUrlSearchParams = (keywords, filters) => {
  return { type: wikiPageActions.STORE_URL_SEARCH_PARAMS, keywords, filters };
};

export const storeDirector = director => {
  return { type: wikiPageActions.STORE_DIRECTOR, director };
};
