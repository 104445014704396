import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/CreateNewReportDdl.module.scss';
import citationRequestFields from '../../services/CitationRequestFields';
import File from '../library/File';
import * as actions from '../../actions/citationRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { acceptedFileTypes, councilMembers } from '../../utils/helper';
import TagsDisplay from '../report-tracking/TagsDisplay';
import {
  ExclamationPointDiamondIcon,
  LargeCheckMarkIcon,
  FileDownloadIcon,
} from '../../services/SvgLibrary';

const citationRequestDefaults = {
  citation_custom_text_attachment: [],
  co_signers: [],
  contact_person: '',
  council_member: '',
  date_citation_needed: '',
  date_of_event: '',
  final_citation: [],
  honoree: '',
  is_speaker_citation: false,
  notes: '',
  other: '',
  sample_used: '',
};

const CreateNewCitationRequest = props => {
  const customTextFileInputRef = React.useRef(null);
  const citationFileInputRef = React.useRef(null);
  const [citationRequest, setCitationRequest] = useState({
    ...citationRequestDefaults,
  });
  const [contactPersonList, setContactPersonList] = useState([]);
  const [userGroups, setUserGroups] = useState({});
  const [page, setPage] = useState(0);
  const [fileLimitExceeded, setFileLimitExceeded] = useState('');
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [errorField, setErrorField] = useState({});
  const [errorsPresent, setErrorsPresent] = useState(false);
  const [refs, setRefs] = useState({});
  const [hoveredTags, setHoveredTags] = useState(new Map());
  const [speaker, setSpeaker] = useState(null);
  const [isUploadSelected, setIsUploadSelected] = useState(false);

  // create the reference for each of the field in the map
  useEffect(() => {
    registerRefs();
    props.userList.forEach(ele => {
      if (ele.role.length > 0 && ele.role.includes('Speaker/Council Member')) {
        setSpeaker(ele.id);
      }
    });
    let associated_cm = props.userList.filter(
      user =>
        user.id ===
        (props.userProfile.under_council_member
          ? props.userProfile.under_council_member
          : props.userProfile.id)
    )[0];
    if (rolesUnderCouncilMembers.includes(props.userProfile.role)) {
      let availableContacts = props.userList.filter(user => {
        if (user.under_council_member) {
          return user.under_council_member === associated_cm.id;
        } else {
          return user.id === associated_cm.id;
        }
      });

      setContactPersonList(availableContacts);
    }
    const groupsMap = {};

    for (let user in props.userList) {
      let userGroups = props.userList[user].groups;
      for (let i in userGroups) {
        if (groupsMap[userGroups[i].name]) {
          groupsMap[userGroups[i].name].push(props.userList[user]);
        } else {
          groupsMap[userGroups[i].name] = [props.userList[user]];
        }
      }
    }
    setUserGroups(groupsMap);

    const { role, under_council_member, full_name } = props.userProfile;
    if (role.includes('Council Member')) {
      setCitationRequest({
        ...citationRequest,
        council_member: full_name,
        status: 'Pending',
      });
    }
    if (under_council_member !== null) {
      const cm = props.userList.find(
        ele => ele.id === under_council_member
      ).full_name;
      setCitationRequest({
        ...citationRequest,
        council_member: cm,
        status: 'Pending',
      });
    }

    return () => {
      props.saveProgress(citationRequest);
    };
  }, []);

  const scrollToTheTop = () => {
    document.getElementsByClassName(styles['report-fields'])[0].scrollTo(0, 0);
  };

  const handleMouseEnter = key => {
    setHoveredTags(hoveredTags.set(key, true));
  };

  const handleMouseLeave = key => {
    setHoveredTags(hoveredTags.set(key, false));
  };

  // arr.reduce((accumulator, current)) to generate an object of refs
  const registerRefs = () => {
    const refs = citationRequestFields.reduce((acc, current) => {
      const ref = React.createRef();
      acc[current.field] = ref;
      return acc;
    }, {});
    setRefs({ ...refs });
  };

  const checkFieldValidation = fields => {
    let isValid = true;
    let errors = {};
    const { userProfile } = props;

    //Date Error Validation start <= next <= end
    let today = new Date();

    const getBusinessDaysCount = (startDate, endDate) => {
      let count = 0;
      const curDate = new Date(startDate.getTime());
      while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    };
    let numOfDaysUntilNeeded =
      citationRequest['date_citation_needed'].length > 0
        ? getBusinessDaysCount(
            today,
            new Date(citationRequest['date_citation_needed'])
          )
        : getBusinessDaysCount(
            today,
            new Date(citationRequest['date_of_event'])
          );
    let numOfDaysUntilEvent = getBusinessDaysCount(
      today,
      new Date(citationRequest['date_of_event'])
    );

    const isCMStaff = rolesUnderCouncilMembers.includes(userProfile.role);

    for (let i = 0; i < fields.length; i++) {
      const curt = fields[i];
      //If the current field is required and if it is empty or an empty array
      if (
        curt.required &&
        (citationRequest[curt.field] === 'None' ||
          (typeof citationRequest[curt.field] !== 'boolean' &&
            !citationRequest[curt.field]) ||
          (Array.isArray(citationRequest[curt.field]) &&
            citationRequest[curt.field].length < 1))
      ) {
        errors[curt.field] = {
          'is_invalid': true,
          'error_message': 'Required fields (*) cannot be empty!',
        };
        isValid = false;
      }

      if (isCMStaff && numOfDaysUntilNeeded < 5) {
        if (citationRequest['date_citation_needed'].length > 0) {
          errors['date_citation_needed'] = {
            'is_invalid': true,
            'error_message': 'Must be at least 5 business days from today',
          };
        } else {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must be at least 5 business days from today',
          };
        }
        isValid = false;
      }

      if (
        isCMStaff &&
        new Date(citationRequest['date_citation_needed']) >
          new Date(citationRequest['date_of_event'])
      ) {
        errors['date_citation_needed'] = {
          'is_invalid': true,
          'error_message': 'Must be before date of event',
        };
        isValid = false;
      }
    }
    setErrorField(errors);
    setErrorsPresent(!isValid);
    return isValid;
  };

  // On click submit for sending out the request
  const onSubmit = (e, fields) => {
    e.preventDefault();
    let filteredFields = fields.filter(field => {
      return isCMStaff ? field.cmStaffCreateViewable : true;
    });
    const isValid = checkFieldValidation(filteredFields);
    if (isValid) {
      props.createNewCitationRequest({
        ...citationRequest,
        requested_by: props.userProfile,
      });
    }
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetCouncilMember = targetIdx => {
    let councilMembers = citationRequest['co_signers'];
    councilMembers.splice(targetIdx, 1);
    setCitationRequest({ ...citationRequest, co_signers: councilMembers });
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedCouncilMember = selectedCouncilMember => {
    let councilMembers = citationRequest['co_signers'];
    let hasDuplicate = false;
    for (let i = 0; i < councilMembers.length; i++) {
      if (councilMembers[i].id === selectedCouncilMember.id) {
        hasDuplicate = true;
        break;
      }
    }

    if (!hasDuplicate) {
      councilMembers.push(selectedCouncilMember);
    }

    councilMembers.sort((a, b) =>
      a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
    );
    setCitationRequest({ ...citationRequest, co_signers: councilMembers });
  };

  const onDeleteNewAttachment = (e, id, field) => {
    const newFileArr = citationRequest[field];
    newFileArr.splice(id, 1);
    setCitationRequest({ ...citationRequest, [field]: newFileArr });
    e.stopPropagation();
  };

  const generateFields = citationRequestFields => {
    const obj = {
      co_signers: {
        inputTitle: 'Council Members Co-signing',
        required: false,
        param: 'full_name',
        warningMessage: 'No council member has been selected',
        onAddTarget: onAddSelectedCouncilMember,
        onDeleteTarget: onDeleteTargetCouncilMember,
        dropdownList: councilMembers(props.userList),
      },
    };

    const { under_council_member, role } = props.userProfile;
    const isCMStaff = under_council_member || role.includes('Council Member');
    const newCitationRequestDisplay = citationRequestFields
      .filter(field => {
        return isCMStaff ? field.cmStaffCreateViewable : true;
      })
      .map((field, idx) => {
        if (field.field !== 'requested_by') {
          if (field.type === 'radio-select') {
            return (
              <div className='mb-3' key={idx}>
                <p className={styles['input-text-title']}>{field.title}</p>
                <div className={styles['custom-text-options-container']}>
                  {field.options.map((opt, optIdx) => {
                    return (
                      <div
                        className={`${styles['radio-buttons']} ${styles['custom-text-radio-buttons']}`}
                        key={optIdx}>
                        <input
                          type='radio'
                          name='custom-text'
                          id={styles[`custom-text-${optIdx}`]}
                          onClick={() => {
                            if (optIdx === 4) {
                              setIsUploadSelected(true);
                              setCitationRequest({
                                ...citationRequest,
                                sample_used: '',
                              });
                            } else {
                              setIsUploadSelected(false);
                              setCitationRequest({
                                ...citationRequest,
                                sample_used: opt.text,
                              });
                            }
                          }}
                        />
                        <label
                          htmlFor={styles[`custom-text-${optIdx}`]}
                          className={styles['custom-text-radio-button-label']}>
                          {opt.download_link && (
                            <button
                              type='button'
                              className={`btn btn-sm`}
                              onClick={() => {
                                window.open(
                                  `${window.location.origin}${opt.download_link}`
                                );
                              }}>
                              <FileDownloadIcon width='15px' height='15px' />
                            </button>
                          )}
                          <div
                            className={styles['custom-text-upload-container']}>
                            <span>{opt.text}</span>
                            {isUploadSelected && optIdx === 4 && (
                              <div className='mb-3'>
                                {citationRequest[field.field].length
                                  ? citationRequest[field.field].map(
                                      (citationRequestFile, idx) => {
                                        return (
                                          <File
                                            key={idx}
                                            file={citationRequestFile}
                                            id={`new-${idx}`}
                                            getFileAndDownload={() =>
                                              getFileAndDownload()
                                            }
                                            onDelete={e => {
                                              onDeleteNewAttachment(
                                                e,
                                                idx,
                                                field.field
                                              );
                                              customTextFileInputRef.current.value =
                                                '';
                                            }}
                                            deletePermission={true}
                                          />
                                        );
                                      }
                                    )
                                  : null}
                                {fileLimitExceeded === field.field && (
                                  <span className={styles['file-error']}>
                                    File limit exceeded
                                  </span>
                                )}
                                <p className={styles['file-padding']}>
                                  Upload a new file
                                </p>
                                <input
                                  ref={customTextFileInputRef}
                                  type={opt.type}
                                  name={field.field}
                                  accept={acceptedFileTypes}
                                  multiple
                                  onChange={e => {
                                    let error = [];
                                    for (
                                      let i = 0;
                                      i < e.target.files.length;
                                      i++
                                    ) {
                                      //If file size is greater than 250MB
                                      if (e.target.files[i].size > 250000000) {
                                        error.push(
                                          e.target.files[i].name +
                                            ' is too large'
                                        );
                                      }
                                    }
                                    if (error.length) {
                                      e.target.value = null;
                                      setFileLimitExceeded(field.field);
                                      setFileErrorMessage(error.join(', '));
                                    } else {
                                      let files = e.target.files;
                                      let newCitationRequest = {
                                        ...citationRequest,
                                      };
                                      for (let i = 0; i < files.length; i++) {
                                        files[i].file_name = files[i].name;
                                        error.push(
                                          files[i].name + 'is too large'
                                        );
                                        newCitationRequest[field.field].push(
                                          files[i]
                                        );
                                      }

                                      setCitationRequest(newCitationRequest);
                                      return {
                                        citationRequest: {
                                          ...citationRequest,
                                          [field.field]:
                                            newCitationRequest[field.field],
                                        },
                                      };
                                    }
                                  }}
                                />
                                {errorField[field.field] && (
                                  <p className={styles['warning-message']}>
                                    {errorField[field.field]['error_message']}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          } else if (field.type === 'file') {
            return (
              <div className='mb-3' key={`${page}-${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                <br />
                <span className={styles['file-size-text']}>
                  File Size Limit 250MB
                </span>
                {citationRequest[field.field].length
                  ? citationRequest[field.field].map((citationRequest, idx) => {
                      return (
                        <File
                          key={idx}
                          file={citationRequest}
                          id={`new-${idx}`}
                          getFileAndDownload={() => getFileAndDownload()}
                          onDelete={e => {
                            onDeleteNewAttachment(e, idx, field.field);
                            citationFileInputRef.current.value = '';
                          }}
                          deletePermission={true}
                        />
                      );
                    })
                  : null}
                {fileLimitExceeded === field.field && (
                  <span className={styles['file-error']}>
                    File limit exceeded
                  </span>
                )}
                <p className={styles['file-padding']}>Upload a new file</p>
                <input
                  type={field.type}
                  name={field.field}
                  accept={acceptedFileTypes}
                  multiple
                  ref={citationFileInputRef}
                  onChange={e => {
                    let error = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                      //If file size is greater than 250MB
                      if (e.target.files[i].size > 250000000) {
                        error.push(e.target.files[i].name + ' is too large');
                      }
                    }
                    if (error.length) {
                      e.target.value = null;
                      setFileLimitExceeded(field.field);
                      setFileErrorMessage(error.join(', '));
                    } else {
                      let files = e.target.files;
                      let newCitationRequest = { ...citationRequest };
                      for (let i = 0; i < files.length; i++) {
                        files[i].file_name = files[i].name;
                        error.push(files[i].name + 'is too large');
                        newCitationRequest[field.field].push(files[i]);
                      }

                      setCitationRequest(newCitationRequest);
                      return {
                        citationRequest: {
                          ...citationRequest,
                          [field.field]: newCitationRequest[field.field],
                        },
                      };
                    }
                  }}
                />
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          } else if (['co_signers'].includes(field.field)) {
            return (
              <div className='mb-3' key={`${page}-${idx}`}>
                <TagsDisplay
                  className={
                    !errorField[field.field]
                      ? styles['input-text-area']
                      : styles['input-text-area-warning']
                  }
                  currentList={citationRequest[field.field]}
                  onAddTarget={obj[field.field].onAddTarget}
                  onDeleteTarget={obj[field.field].onDeleteTarget}
                  inputTitle={obj[field.field].inputTitle}
                  required={obj[field.field].required}
                  warningMessage={obj[field.field].warningMessage}
                  param={obj[field.field].param}
                  reference={refs[field.field]}
                  dropdownList={obj[field.field].dropdownList}
                  allowNew={field.field === 'submitting_agency'}
                  number={obj[field.field].number}
                />
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          } else if (field.type === 'select') {
            let disable = isCMStaff ? !field.cmStaffCreateEditable : false;
            if (field.field === 'council_member') {
              field.options = [
                'Select Council Member',
                ...councilMembers(props.userList).map(cm => cm['full_name']),
              ];
              disable = citationRequest.is_speaker_citation;
            }
            if (field.field === 'contact_person') {
              field.options = [
                'Select Contact Person',
                ...contactPersonList.map(user => user['full_name']),
              ];
            }
            if (field.field === 'status') {
              field.options = ['Select Status', ...field.options];
            }
            return (
              <div className='mb-3' key={`${page}-${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                <select
                  className={
                    !errorField[field.field]
                      ? 'form-control'
                      : styles['input-text-area-warning']
                  }
                  value={
                    citationRequest[field.field]
                      ? citationRequest[field.field].toString()
                      : ''
                  }
                  ref={refs[field.field]}
                  disabled={disable}
                  onChange={e => {
                    if (field.field === 'council_member') {
                      let associated_cm = props.userList.find(
                        user => user.full_name === e.target.value
                      );
                      let availableContacts = props.userList.filter(user => {
                        if (user.under_council_member) {
                          return user.under_council_member === associated_cm.id;
                        } else {
                          return user.id === associated_cm.id;
                        }
                      });

                      setContactPersonList(availableContacts);
                      setCitationRequest({
                        ...citationRequest,
                        council_member: e.target.value,
                      });
                    } else {
                      setCitationRequest({
                        ...citationRequest,
                        [field.field]: e.target.value,
                      });
                    }
                  }}
                  multiple={field.multiple}>
                  {field.options &&
                    field.options.map((op, idx1) => {
                      if (
                        [
                          'Select Contact Person',
                          'Select Council Member',
                          'Select Status',
                        ].includes(op)
                      ) {
                        return (
                          <option key={idx1} value={null} hidden>
                            {op}
                          </option>
                        );
                      } else {
                        return (
                          <option key={idx1} value={op}>
                            {op.toString()}
                          </option>
                        );
                      }
                    })}
                </select>
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          } else if (field.type === 'checkbox') {
            return (
              <div className='mb-3' key={`${page}-${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                <div className={styles['radio-buttons']}>
                  <div className='mb-3' name={field.field}>
                    <input
                      type='radio'
                      id={`yes-${field.field}`}
                      name={field.field}
                      checked={citationRequest[field.field]}
                      onChange={e => {
                        setContactPersonList([props.userProfile]);
                        setCitationRequest({
                          ...citationRequest,
                          [field.field]: true,
                          council_member: props.userList.find(ele =>
                            ele.role.includes('Speaker')
                          ).full_name,
                        });
                        setContactPersonList(userGroups['Citation Team']);
                      }}
                      className={styles['checkbox']}
                    />
                    <label htmlFor={`yes-${field.field}`}>Yes</label>
                    <input
                      type='radio'
                      id={`no-${field.field}`}
                      name={field.field}
                      checked={
                        !citationRequest[field.field] &&
                        typeof citationRequest[field.field] !== 'undefined'
                      }
                      onChange={e => {
                        setCitationRequest({
                          ...citationRequest,
                          [field.field]: false,
                          council_member: '',
                        });
                        setContactPersonList([]);
                      }}
                      className={styles['checkbox']}
                    />
                    <label htmlFor={`no-${field.field}`}>No</label>
                    {errorField[field.field] && (
                      <p className={styles['warning-message']}>
                        {errorField[field.field]['error_message']}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          } else {
            let disable = isCMStaff ? !field.cmStaffCreateEditable : false;
            let customTitle = '';
            if (field.field === 'date_citation_needed' && isCMStaff) {
              customTitle = `Date Citation is Needed (Must be 5 business days from today)`;
            }
            return (
              <div className='mb-3' key={`${page}-${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {customTitle.length > 0 ? customTitle : field.title}
                      <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : customTitle.length > 0 ? (
                    customTitle
                  ) : (
                    field.title
                  )}
                </p>
                <input
                  className={`form-control ${
                    !errorField[field.field]
                      ? styles['input-text-area']
                      : styles['input-text-area-warning']
                  }`}
                  type={field.type}
                  name={field.field}
                  value={citationRequest[field.field] || ''}
                  maxlength={
                    (['honoree', 'other'].includes(field.field) && 255) ||
                    (field.field === 'notes' && 1600)
                  }
                  onChange={e =>
                    setCitationRequest({
                      ...citationRequest,
                      [field.field]: e.target.value,
                    })
                  }
                  disabled={disable}
                  ref={refs[field.field]}
                />
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          }
        } else return null;
      });
    return newCitationRequestDisplay;
  };

  let isCMStaff =
    props.userProfile.role.includes('Council Member') ||
    props.userProfile.under_council_member;

  let leftSideLast = citationRequestFields
    .map(ele => ele.field)
    .indexOf('co_signers');

  let leftFields = citationRequestFields.slice(0, leftSideLast);
  let rightFields = citationRequestFields.slice(leftSideLast);

  return (
    <form>
      <div
        className={`${styles['modal-header']} ${styles['modal-header-citations']}`}>
        <div>
          <span className='popup-detail-title'>New Citation Request</span>
        </div>
      </div>
      {props.newCitationStatus === 'fail' && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <p>Failed to create this citation request</p>
        </div>
      )}
      {errorsPresent && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <div>
            <p>There was an error with your form submission.</p>
            <p>Please correct the highlighted field(s) below</p>
          </div>
        </div>
      )}
      {props.isLoading === true && (
        <div className={styles['fixed-loading-container']}>
          <img
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            className='loading-image'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {props.newCitationStatus !== 'success' && (
        <>
          <div className={styles['form-container']}>
            <div className={styles['report-fields']}>
              <div className={styles['report-field-column']}>
                {generateFields(leftFields)}
              </div>
              <div className={styles['report-field-column']}>
                {generateFields(rightFields)}
              </div>
            </div>
            <p className={styles['disclaimer']}>
              Event and Production Services will provide a high-res PDF for
              printing by Council Member staff in their respective offices. E&PS
              can provide guidance on selecting the correct paper for printing.
              Please contact Administrative Services for red citation folders.
            </p>
          </div>
          <div className={styles['modal-footer']}>
            <button
              type='button'
              className={`submit-button ${styles['cancel-button']}`}
              onClick={() => {
                props.changeCitationRequestDisplay('list');
                props.changeCitationRequestPopupWindow(false);
              }}>
              Cancel
            </button>
            <button
              type='button'
              className={`submit-button ${styles['footer-button']}`}
              onClick={e => onSubmit(e, citationRequestFields)}>
              Submit
            </button>
          </div>
        </>
      )}
      {props.newCitationStatus === 'success' && (
        <div
          className={`alert alert-success ${styles['alert-danger-banner']} ${styles['success']}`}
          role='alert'>
          <LargeCheckMarkIcon />
          <div>
            <p>The citation request has been successfully updated!</p>
            <p>Page will refresh shortly.</p>
          </div>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    committees: state.userReducer.committees,
    newCitationStatus: state.citationRequestReducer.newCitationStatus,
    userList: state.userReducer.userList,
    savedProgress: state.citationRequestReducer.savedProgress,
    isLoading: state.citationRequestReducer.isLoading,
  };
};

export default connect(mapStateToProps, actions)(CreateNewCitationRequest);
