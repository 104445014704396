import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { flattenObject } from '../../../../utils/helper';
import { updateUserPreferences } from '../../../../actions/userAction';
import { userPreferenceLabels } from '../../../../services/constants';
import styles from '../../../../styles/user/UserProfile.module.scss';

const UserprofileEmailNotifPrefWidget = props => {
  const {
      updateUserPreferences,
      userProfile,
  } = props;

  // flattens the nested User Preference states for local use
  // from this => preferenceState:{[application]: {[category]: {[item]: {value: bool, visible: bool }}}}
  // to this => preferenceState: {[`${application}.${category}.${item}.value`]: bool}
  const flattenedUserPreferenceStates = useMemo(
    () => flattenObject(userProfile.preferences),
    [userProfile.preferences]
  );

  const [userPreferenceToggleStates, setUserPreferenceToggleStates] = useState(
    flattenedUserPreferenceStates
  );
  
  const [toastText, setToastText] = useState('');
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(() => {
    if (toastVisible) {
      setTimeout(() => {
        setToastVisible(false);
      }, 3000); // autohide after 3 seconds
    }
  }, [toastVisible]);

    const toggleUserPreferenceSwitch = (application, subCatergory, item) => {
      setUserPreferenceToggleStates(prevState => {
        const toggledState = !prevState[`${application}.${subCatergory}.${item}`];
        return {
          ...prevState,
          [`${application}.${subCatergory}.${item}`]: toggledState,
        };
      });
    };
  
    const sendUpdatedUserPreferences = () => {
      let updatedPreferences = userProfile.preferences;
      let application;
      let subCategory;
      let item;
      let arr;
      for (const key in userPreferenceToggleStates) {
        arr = key.split('.');
        application = arr[0];
        subCategory = arr[1];
        item = arr[2];
        updatedPreferences[application][subCategory][item].value =
          userPreferenceToggleStates[key];
      }
      updateUserPreferences(
        userProfile.id,
        updatedPreferences,
        setToastText,
        setToastVisible
      );
    };
  
  return (
      <>
        <div
          className={`card border-0 rounded-3 ${styles['user-profile-pref-container']}`}>
          <div className='card-body'>
            <h2 className='mb-0'>User Preferences</h2>
            <table id={styles['profile-table']}>
              <tbody>
                {Object.entries(userPreferenceToggleStates).length &&
                  // Start of Preferences by Application
                  Object.entries(userPreferenceLabels).map(
                    ([appKey, appValue], id) => (
                      <Fragment key={`${appKey}${id}`}>
                        <tr key={appKey}>
                          <td>
                            <h4 className='mb-3 fs-5'>
                              {userPreferenceLabels[appKey].title}
                            </h4>
                            {Object.entries(
                              userPreferenceLabels[appKey].items
                            ).map(([categoryKey, categoryValue]) => {
                              const categoryIsVisible =
                                userProfile.preferences[appKey][categoryKey]
                                  .visible;
                              return (
                                categoryIsVisible &&
                                categoryValue.title !==
                                  'Operational Requirements' && (
                                  // Where the Preference categories for Applications are rendered
                                  <div
                                    key={categoryKey}
                                    className='d-flex flex-column flex-xl-row gap-2 gap-xl-5 mb-4 border-bottom pb-3'>
                                    <h5 className='d-inline fw-bold fs-6 col-100 col-xl-4'>
                                      {categoryValue.title}
                                    </h5>
                                    <ul
                                      style={{ listStyleType: 'none' }}
                                      className='ps-0 flex-fill'>
                                      {Object.entries(categoryValue.items).map(
                                        ([switchKey, switchLabel]) => {
                                          const switchIsVisible =
                                            userProfile.preferences[appKey][
                                              categoryKey
                                            ][switchKey].visible;
                                          // Where the preference toggle switches are rendered
                                          return (
                                            switchIsVisible && (
                                              <li key={switchKey}>
                                                <div className='d-flex justify-content-between'>
                                                  <label
                                                    className={`form-check-label me-2 ${styles['label-max-width']}`}
                                                    htmlFor={`${switchKey}-${switchLabel.heading}-switch`}>
                                                    <span>{switchLabel.heading}</span>
                                                    <span>{switchLabel.description}</span>
                                                  </label>
                                                  <div className='form-check form-switch'>
                                                    <input
                                                      className={`form-check-input ${styles['user-email-pref-toggle']}`}
                                                      type='checkbox'
                                                      role='switch'
                                                      id={`${switchKey}-${switchLabel.heading}-switch`}
                                                      checked={
                                                        userPreferenceToggleStates[
                                                          `${appKey}.${categoryKey}.${switchKey}`
                                                        ]
                                                      }
                                                      onChange={() =>
                                                        toggleUserPreferenceSwitch(
                                                          appKey,
                                                          categoryKey,
                                                          switchKey
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                )
                              );
                            })}
                          </td>
                        </tr>
                        {id < Object.keys(userPreferenceLabels).length - 1 && (
                          <tr>
                            <td colSpan='100%'>
                              <hr
                                style={{
                                  height: '0.09375rem',
                                  backgroundColor: 'black',
                                  color: 'black',
                                  border: 'none',
                                  opacity: '35%',
                                }}
                              />
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    )
                  )}
                <tr>
                  <td className='text-end'>
                    <button
                      className='submit-button'
                      onClick={sendUpdatedUserPreferences}>
                      Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* toast for User Preferences */}
        {toastVisible && (
          <div className='toast-container position-fixed bottom-0 end-0 p-3'>
            <div className='toast show'>
              <div className='toast-header'>
                <strong className='me-auto'>IWOMM</strong>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setToastVisible(false)}></button>
              </div>
              <div className='toast-body'>{toastText}</div>
            </div>
          </div>
        )}
      </>
    );
};
const mapDispatchToProps = {
  updateUserPreferences,
};
const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserprofileEmailNotifPrefWidget);