import { call, put, takeEvery } from 'redux-saga/effects';
import { getTargetFiles, delay } from '../utils/helper';
import axios from 'axios';
import { url } from '../config';
import {
  citationRequestActions,
  saveCitationRequestCurrentMonthAndYear,
  saveTargetCitationRequests,
  changeCitationRequestPopupWindow,
  createNewCitationRequestStatus,
  updateCitationRequestStatus,
  setIsLoading,
  setIsCalendarLoading,
  saveAllCitationRequests,
  filterCitationRequest,
  saveProgress,
  saveNextAPI,
} from '../actions/citationRequestAction';

// HTTP.GET to get reports due selected month and year
// Will return a list of objects
// Assume for each month we will have 31 days
function sendCitationRequestByDateRequest(month, year) {
  return axios.get(url + '/api/citationRequest/citationRequestByDate/', {
    params: { month: month, year: year },
  });
}

// function for saving the static form data for report
function saveCitationRequestStaticData(citationRequest) {
  const formData = new FormData();
  formData.append('council_member', citationRequest.council_member);
  formData.append('contact_person', citationRequest.contact_person);
  formData.append('honoree', citationRequest.honoree);
  formData.append('other', citationRequest.other);
  formData.append('date_of_event', citationRequest.date_of_event);
  formData.append(
    'date_citation_needed',
    citationRequest.date_citation_needed.length > 0
      ? citationRequest.date_citation_needed
      : citationRequest.date_of_event
  );
  formData.append(
    'co_signers',
    citationRequest.co_signers.map(ele => ele.full_name).join()
  ); // MAP
  formData.append('notes', citationRequest.notes);
  formData.append('status', citationRequest.status || 'Pending');
  formData.append('requested_by_id', citationRequest.requested_by.id);
  citationRequest.is_archived
    ? formData.append('is_archived', true)
    : formData.append('is_archived', false);
  formData.append('is_speaker_citation', citationRequest.is_speaker_citation);
  formData.append('sample_used', citationRequest.sample_used);

  return formData;
}

// function for finish the promise of zipping the file
function* saveFileData(citationRequest, formData) {
  const citation_custom_text_attachment = yield call(
    getTargetFiles,
    citationRequest.citation_custom_text_attachment
  );
  const final_citation = yield call(
    getTargetFiles,
    citationRequest.final_citation
  );

  if (citation_custom_text_attachment) {
    citation_custom_text_attachment.forEach((file, idx) => {
      if (idx) {
        formData.append(`citation_custom_text_attachment-${idx}`, file);
      } else {
        formData.append('citation_custom_text_attachment', file);
      }
    });
  } else {
    formData.append('citation_custom_text_attachment', '');
  }
  if (final_citation) {
    final_citation.forEach((file, idx) => {
      if (idx) {
        formData.append(`final_citation-${idx}`, file);
      } else {
        formData.append('final_citation', file);
      }
    });
  } else {
    formData.append('final_citation', '');
  }
  return formData;
}

// HTTP.POST for creating a new report
function sendCreateNewCitationRequest(citationRequest) {
  return axios.post(
    url + '/api/citationRequest/citationRequest/',
    citationRequest
  );
}

function sendUpdateCitationRequest(citationRequest, id) {
  return axios.put(
    url + `/api/citationRequest/citationRequest/${id}/`,
    citationRequest
  );
}

// HTTP.GET for getting all the reports
function getAllCitationRequestsRequest(nextAPI, params) {
  let paramString = params.sort_by
    ? Object.keys(params).reduce((acc, curr, idx) => {
        return (
          acc +
          `${curr}=${params[curr]}` +
          (idx !== Object.keys(params).length - 1 ? '&' : '')
        );
      }, '')
    : '';
  if (nextAPI) {
    return axios.get(url + `/api/${nextAPI.split('/api/')[1]}&` + paramString);
  } else {
    return axios.get(
      url + '/api/citationRequest/citationRequest/?limit=100&' + paramString
    );
  }
}

function deleteFileRequest(file, field) {
  return axios.delete(
    `${url}/api/citationRequest/deleteFile/${file}/?field=${field}`
  );
}

// Get the due reports and save them in the redux store
function* getCitationRequestsResponse(action) {
  if (action !== undefined) {
    try {
      yield put(setIsCalendarLoading(true));
      // Get the response of certain month and year
      const response = yield call(
        sendCitationRequestByDateRequest,
        action.month,
        action.year
      );
      const { data } = response;
      yield put(saveCitationRequestCurrentMonthAndYear(data));
      yield put(setIsCalendarLoading(false));
    } catch (e) {
      console.log(e);
    }
  }
}

// Save the due reports on selected date
// Save the data into the redux store before open the service winodw
function* saveSelectedCitationRequests(action) {
  if (action !== undefined) {
    try {
      yield put(changeCitationRequestPopupWindow(false));
      yield put(saveTargetCitationRequests(action.citationRequests));
      yield put(changeCitationRequestPopupWindow(true));
    } catch (e) {
      yield put(changeCitationRequestPopupWindow(false));
      yield put(setIsLoading(false));
      console.log(e);
    }
  }
}

// Create a new citation request
// Provides an object with all the parameters
function* createNewCitationRequest(action) {
  if (action !== undefined) {
    try {
      yield put(setIsLoading(true));
      const formData = yield call(
        saveCitationRequestStaticData,
        action.citationRequest
      );
      yield call(saveFileData, action.citationRequest, formData);
      yield call(sendCreateNewCitationRequest, formData);
      yield put(saveProgress(null));
      yield put(createNewCitationRequestStatus('success'));
      yield put(setIsLoading(false));
      yield call(delay);
      window.location.assign('/citation-requests');
    } catch (e) {
      yield put(createNewCitationRequestStatus('fail'));
      yield put(setIsLoading(false));
      console.log(e);
    }
  }
}

function* updateCitationRequest(action) {
  if (action !== undefined) {
    try {
      yield put(setIsLoading(true));
      const formData = yield call(
        saveCitationRequestStaticData,
        action.citationRequest
      );
      yield call(saveFileData, action.citationRequest, formData);
      yield call(
        sendUpdateCitationRequest,
        formData,
        action.citationRequest.id
      );
      yield put(saveProgress(null));
      yield put(updateCitationRequestStatus('success'));
      yield put(setIsLoading(false));
      yield call(delay);
      window.location.assign('/citation-requests');
    } catch (e) {
      yield put(updateCitationRequestStatus('fail'));
      yield put(setIsLoading(false));
      console.log(e);
    }
  }
}

// Get all the reports
function* getAllCitationRequestsList(action) {
  if (action !== undefined) {
    try {
      yield put(setIsLoading(true));
      const { data } = yield call(
        getAllCitationRequestsRequest,
        action.nextAPI,
        action.params
      );

      yield put(saveAllCitationRequests(data.results, action.nextAPI));
      yield put(filterCitationRequest(data.results, action.nextAPI));
      yield put(saveNextAPI(data.next));
      yield put(setIsLoading(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* deleteFileResponse(action) {
  if (action !== undefined) {
    try {
      yield call(deleteFileRequest, action.id, action.field);
    } catch (e) {
      console.log(e);
    }
  }
}

function* citationRequestAPI() {
  yield takeEvery(
    citationRequestActions.GET_CITATION_REQUEST_CURRENT_MONTH_AND_YEAR,
    getCitationRequestsResponse
  );
  yield takeEvery(
    citationRequestActions.GET_ALL_CITATION_REQUESTS,
    getAllCitationRequestsList
  );
  yield takeEvery(
    citationRequestActions.CREATE_NEW_CITATION_REQUEST,
    createNewCitationRequest
  );
  yield takeEvery(
    citationRequestActions.UPDATE_CITATION_REQUEST,
    updateCitationRequest
  );
  yield takeEvery(
    citationRequestActions.SELECT_TARGET_DAY_FOR_CITATION_REQUESTS,
    saveSelectedCitationRequests
  );
  yield takeEvery(
    citationRequestActions.DELETE_ATTACHMENT_FROM_DB,
    deleteFileResponse
  );
}
export default citationRequestAPI;
