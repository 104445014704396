import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/CreateNewReportDdl.module.scss';
import tagStyles from '../../styles/report-tracking/TagsDisplay.module.scss';
import ProcRequestFields from '../../services/ProcRequestFields';
import File from '../library/File';
import * as actions from '../../actions/procRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { acceptedFileTypes, councilMembers } from '../../utils/helper';
import TagsDisplay from '../report-tracking/TagsDisplay';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
  divisionList,
  eventToolTipText,
  honoreeToolTipText,
  otherToolTipText,
  reasonToolTipText,
  rolesUnderCouncilMembers,
} from '../../services/constants';
import {
  ToolTipIcon,
  ExclamationPointDiamondIcon,
  LargeCheckMarkIcon,
  PlusCircleDottedIcon,
  PlusCircleFilledIcon,
} from '../../services/SvgLibrary';

const procRequestDefaults = {
  requested_by: null,
  council_member: '',
  honoree: '',
  reason: '',
  other: '',
  status: '',
  date_of_event: '',
  date_proc_needed: '',
  co_signers: [],
  notes: '',
  proclamation_draft: [],
  event_info_attachment: [],
  honoree_info_attachment: [],
};

const CreateNewProcRequest = props => {
  const [procRequest, setProcRequest] = useState({ ...procRequestDefaults });
  const [userGroups, setUserGroups] = useState({});
  const [fileLimitExceeded, setFileLimitExceeded] = useState('');
  const [errorField, setErrorField] = useState({});
  const [errorsPresent, setErrorsPresent] = useState(false);
  const [refs, setRefs] = useState({});
  const [hoveredTags, setHoveredTags] = useState(new Map());

  // create the reference for each of the field in the map
  useEffect(() => {
    registerRefs();
    let associated_cm = props.userList.filter(
      user =>
        user.id ===
        (props.userProfile.under_council_member
          ? props.userProfile.under_council_member
          : props.userProfile.id)
    )[0];

    const groupsMap = {};

    for (let user in props.userList) {
      let testing = props.userList[user].groups;

      for (let i in testing) {
        if (groupsMap[testing[i].name]) {
          groupsMap[testing[i].name].push(props.userList[user]);
        } else {
          groupsMap[testing[i].name] = [props.userList[user]];
        }
      }
    }

    setUserGroups(groupsMap);

    const { role, under_council_member, full_name } = props.userProfile;
    if (role.includes('Council Member')) {
      setProcRequest({
        ...procRequest,
        council_member: full_name,
        status: 'Pending',
      });
    }
    if (under_council_member !== null) {
      const cm = props.userList.find(ele => ele.id === under_council_member);
      setProcRequest({
        ...procRequest,
        council_member: cm.full_name,
        status: 'Pending',
      });
    }

    return () => {
      props.saveProgress(procRequest);
    };
  }, []);

  const scrollToTheTop = () => {
    document.getElementsByClassName(styles['report-fields'])[0].scrollTo(0, 0);
  };

  const handleMouseEnter = key => {
    setHoveredTags(hoveredTags.set(key, true));
  };

  const handleMouseLeave = key => {
    setHoveredTags(hoveredTags.set(key, false));
  };

  // arr.reduce((accumulator, current)) to generate an object of refs
  const registerRefs = () => {
    const refs = ProcRequestFields.reduce((acc, current) => {
      const ref = React.createRef();
      acc[current.field] = ref;
      return acc;
    }, {});
    setRefs({ ...refs });
  };

  const checkFieldValidation = fields => {
    let isValid = true;
    let errors = {};
    const { userProfile } = props;

    //Date Error Validation start <= next <= end
    let today = new Date();

    const getBusinessDaysCount = (startDate, endDate) => {
      let count = 0;
      const curDate = new Date(startDate.getTime());
      while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    };
    let numOfDaysUntilNeeded =
      procRequest['date_proc_needed'].length > 0
        ? getBusinessDaysCount(today, new Date(procRequest['date_proc_needed']))
        : getBusinessDaysCount(today, new Date(procRequest['date_of_event']));
    let numOfDaysUntilEvent = getBusinessDaysCount(
      today,
      new Date(procRequest['date_of_event'])
    );

    const isCMStaff = rolesUnderCouncilMembers.includes(userProfile.role);

    for (let i = 0; i < fields.length; i++) {
      const curt = fields[i];
      //If the current field is required and if it is empty or an empty array
      if (
        curt.required &&
        (procRequest[curt.field] === 'None' ||
          (typeof procRequest[curt.field] !== 'boolean' &&
            !procRequest[curt.field]) ||
          (Array.isArray(procRequest[curt.field]) &&
            procRequest[curt.field].length < 1))
      ) {
        errors[curt.field] = {
          'is_invalid': true,
          'error_message': 'Required fields (*) cannot be empty!',
        };
        isValid = false;
      }

      if (procRequest['date_proc_needed'].length === 0) {
        if (isCMStaff && new Date() > new Date(procRequest['date_of_event'])) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must be before date of event',
          };
          isValid = false;
        }

        if (isCMStaff && numOfDaysUntilNeeded < 5) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must be at least 5 business days from today',
          };
          isValid = false;
        }

        if (
          isCMStaff &&
          numOfDaysUntilNeeded < 15 &&
          numOfDaysUntilNeeded >= 5 &&
          procRequest.proclamation_draft.length === 0
        ) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message':
              'Must submit a proclamation draft as the Proclamation is needed within 15 business days',
          };
          errors['proclamation_draft'] = {
            'is_invalid': true,
            'error_message':
              'Must submit a proclamation draft as the Proclamation is needed within 15 business days',
          };
          isValid = false;
        }
        if (
          isCMStaff &&
          numOfDaysUntilNeeded >= 15 &&
          procRequest.event_info_attachment.length === 0
        ) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must submit honoree and event information',
          };
          errors['event_info_attachment'] = {
            'is_invalid': true,
            'error_message': 'Must submit event information',
          };
          isValid = false;
        }
        if (
          isCMStaff &&
          numOfDaysUntilNeeded >= 15 &&
          procRequest.honoree_info_attachment.length === 0
        ) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must submit honoree and event information',
          };
          errors['honoree_info_attachment'] = {
            'is_invalid': true,
            'error_message': 'Must submit honoree information',
          };
          isValid = false;
        }

        if (isCMStaff && numOfDaysUntilNeeded > numOfDaysUntilEvent) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must be after date needed',
          };
          isValid = false;
        }
        if (
          procRequest.reason === 'Other (Explain below)' &&
          procRequest.other === ''
        ) {
          errors['other'] = {
            'is_invalid': true,
            'error_message': 'Must not be blank as reason selected is "Other"',
          };
          isValid = false;
        }
      } else {
        if (
          isCMStaff &&
          new Date(procRequest['date_proc_needed']) >
            new Date(procRequest['date_of_event'])
        ) {
          errors['date_proc_needed'] = {
            'is_invalid': true,
            'error_message': 'Must be before date of event',
          };
          isValid = false;
        }

        if (isCMStaff && numOfDaysUntilNeeded < 5) {
          errors['date_proc_needed'] = {
            'is_invalid': true,
            'error_message': 'Must be at least 5 business days from today',
          };
          isValid = false;
        }

        if (
          isCMStaff &&
          numOfDaysUntilNeeded < 15 &&
          numOfDaysUntilNeeded >= 5 &&
          procRequest.proclamation_draft.length === 0
        ) {
          errors['date_proc_needed'] = {
            'is_invalid': true,
            'error_message':
              'Must submit a proclamation draft as the Proclamation is needed within 15 business days',
          };
          errors['proclamation_draft'] = {
            'is_invalid': true,
            'error_message':
              'Must submit a proclamation draft as the Proclamation is needed within 15 business days',
          };
          isValid = false;
        }
        if (
          isCMStaff &&
          numOfDaysUntilNeeded >= 15 &&
          procRequest.event_info_attachment.length === 0
        ) {
          errors['date_proc_needed'] = {
            'is_invalid': true,
            'error_message': 'Must submit honoree and event information',
          };
          errors['event_info_attachment'] = {
            'is_invalid': true,
            'error_message': 'Must submit event information',
          };
          isValid = false;
        }
        if (
          isCMStaff &&
          numOfDaysUntilNeeded >= 15 &&
          procRequest.honoree_info_attachment.length === 0
        ) {
          errors['date_proc_needed'] = {
            'is_invalid': true,
            'error_message': 'Must submit honoree and event information',
          };
          errors['honoree_info_attachment'] = {
            'is_invalid': true,
            'error_message': 'Must submit honoree information',
          };
          isValid = false;
        }

        if (isCMStaff && numOfDaysUntilNeeded > numOfDaysUntilEvent) {
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must be before event',
          };
          errors['date_of_event'] = {
            'is_invalid': true,
            'error_message': 'Must be after date needed',
          };
          isValid = false;
        }
        if (
          procRequest.reason === 'Other (Explain below)' &&
          procRequest.other === ''
        ) {
          errors['other'] = {
            'is_invalid': true,
            'error_message': 'Must not be blank as reason selected is "Other"',
          };
          isValid = false;
        }
      }
    }
    setErrorField(errors);
    setErrorsPresent(!isValid);
    return isValid;
  };

  // On click submit for sending out the request
  const onSubmit = (e, fields) => {
    e.preventDefault();
    let filteredFields = fields.filter(field => {
      return isCMStaff ? field.cmStaffCreateViewable : true;
    });
    const isValid = checkFieldValidation(filteredFields);
    if (isValid) {
      props.createNewProcRequest({
        ...procRequest,
        requested_by: props.userProfile,
      });
    }
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetCouncilMember = targetIdx => {
    let councilMembers = procRequest['co_signers'];
    councilMembers.splice(targetIdx, 1);
    setProcRequest({ ...procRequest, co_signers: councilMembers });
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedCouncilMember = selectedCouncilMember => {
    let councilMembers = procRequest['co_signers'];
    let hasDuplicate = false;
    for (let i = 0; i < councilMembers.length; i++) {
      if (councilMembers[i].id === selectedCouncilMember.id) {
        hasDuplicate = true;
        break;
      }
    }

    if (!hasDuplicate) {
      councilMembers.push(selectedCouncilMember);
    }

    councilMembers.sort((a, b) =>
      a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
    );
    setProcRequest({ ...procRequest, co_signers: councilMembers });
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state

  const onAddSelectedCaucus = caucus => {
    userGroups[caucus.name].forEach(ele => onAddSelectedCouncilMember(ele));
  };
  const onAddSelectedBorough = borough => {
    userGroups[borough.name + ' Delegation'].forEach(ele =>
      onAddSelectedCouncilMember(ele)
    );
  };

  const onDeleteNewAttachment = (e, id, field) => {
    const newFileArr = procRequest[field];
    newFileArr.splice(id, 1);
    setProcRequest({ ...procRequest, [field]: newFileArr });
    e.stopPropagation();
  };

  const generateFields = procRequestFields => {
    const obj = {
      co_signers: {
        inputTitle: 'Which CMs, if any, should be invited to co-sign?',
        required: false,
        param: 'full_name',
        warningMessage: 'No council member has been selected',
        onAddTarget: onAddSelectedCouncilMember,
        onDeleteTarget: onDeleteTargetCouncilMember,
        dropdownList: councilMembers(props.userList),
      },
      caucuses: {
        required: false,
        param: 'name',
        warningMessage: 'No caucus has been selected',
        onAddTarget: onAddSelectedCaucus,
        dropdownList: divisionList,
      },
      boroughs: {
        required: false,
        param: 'name',
        warningMessage: 'No borough delegation has been selected',
        onAddTarget: onAddSelectedBorough,
        dropdownList: [
          { name: 'Bronx' },
          { name: 'Brooklyn' },
          { name: 'Manhattan' },
          { name: 'Queens' },
          { name: 'Staten Island' },
        ],
      },
    };

    const { under_council_member, role } = props.userProfile;
    const isCMStaff = under_council_member || role.includes('Council Member');
    const newProcRequestDisplay = procRequestFields
      .filter(field => {
        return isCMStaff ? field.cmStaffCreateViewable : true;
      })
      .map((field, idx) => {
        if (field.field !== 'requested_by') {
          if (field.type === 'file') {
            return (
              <div className='mb-3' key={`${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                {field.field === 'event_info_attachment' && (
                  <>
                    <ToolTipIcon
                      classNameProp={styles['user-guide-field-icon-procs']}
                      idProp='event_info_attachment'
                    />
                    <UncontrolledPopover
                      innerClassName={styles['popover-container']}
                      className={styles['popover-outer-container']}
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='event_info_attachment'>
                      <PopoverBody>{eventToolTipText}</PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
                {field.field === 'honoree_info_attachment' && (
                  <>
                    <ToolTipIcon
                      classNameProp={styles['user-guide-field-icon-procs']}
                      idProp='honoree_info_attachment'
                    />
                    <UncontrolledPopover
                      innerClassName={styles['popover-container']}
                      className={styles['popover-outer-container']}
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='honoree_info_attachment'>
                      <PopoverBody>{honoreeToolTipText}</PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
                <br />
                <span className={styles['file-size-text']}>
                  File Size Limit 250MB
                </span>
                {procRequest[field.field].length
                  ? procRequest[field.field].map((procRequest, idx) => {
                      return (
                        <File
                          key={idx}
                          file={procRequest}
                          id={`new-${idx}`}
                          getFileAndDownload={getFileAndDownload}
                          onDelete={e => {
                            onDeleteNewAttachment(e, idx, field.field);
                            refs[field.field].current.value = '';
                          }}
                          deletePermission={true}
                        />
                      );
                    })
                  : null}
                {fileLimitExceeded === field.field && (
                  <span className={styles['file-error']}>
                    File limit exceeded
                  </span>
                )}
                <p className={styles['file-padding']}>Upload a new file</p>
                <input
                  type={field.type}
                  name={field.field}
                  accept={acceptedFileTypes}
                  multiple
                  ref={refs[field.field]}
                  onChange={e => {
                    let error = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                      //If file size is greater than 250MB
                      if (e.target.files[i].size > 250000000) {
                        error.push(e.target.files[i].name + ' is too large');
                      }
                    }
                    if (error.length) {
                      e.target.value = null;
                      setFileLimitExceeded(field.field);
                      // setFileErrorMessage(error.join(', '))
                    } else {
                      let files = e.target.files;

                      let newProcRequest = { ...procRequest };
                      for (let i = 0; i < files.length; i++) {
                        files[i].file_name = files[i].name;
                        error.push(files[i].name + 'is too large');
                        newProcRequest[field.field].push(files[i]);
                      }
                      setProcRequest(newProcRequest);
                      return {
                        procRequest: {
                          ...procRequest,
                          [field.field]: newProcRequest[field.field],
                        },
                      };
                    }
                  }}
                />
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          } else if (['co_signers'].includes(field.field)) {
            return (
              <div className='mb-3' key={`${idx}`}>
                <TagsDisplay
                  className={
                    !errorField[field.field]
                      ? styles['input-text-area']
                      : styles['input-text-area-warning']
                  }
                  currentList={procRequest[field.field]}
                  onAddTarget={obj[field.field].onAddTarget}
                  onDeleteTarget={obj[field.field].onDeleteTarget}
                  inputTitle={obj[field.field].inputTitle || null}
                  required={obj[field.field].required}
                  warningMessage={obj[field.field].warningMessage}
                  param={obj[field.field].param}
                  reference={refs[field.field]}
                  dropdownList={obj[field.field].dropdownList}
                  allowNew={field.field === 'submitting_agency'}
                  number={obj[field.field].number}
                />
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          } else if (['caucuses', 'boroughs'].includes(field.field)) {
            return (
              <div className='mb-3' key={`${idx}`}>
                {field.field === 'caucuses' && (
                  <p>OR which Caucuses and/or Delegations should co-sign?</p>
                )}
                <p className={styles['input-tag-title']}>
                  {obj[field.field].inputTitle}
                </p>
                {obj[field.field].dropdownList.map((ele, idx) => {
                  const keyTag = `${field.field}-${idx}`;
                  return (
                    <div
                      key={keyTag}
                      className={tagStyles['tag']}
                      onClick={() => obj[field.field].onAddTarget(ele)}
                      onMouseEnter={() => handleMouseEnter(keyTag)}
                      onMouseLeave={() => handleMouseLeave(keyTag)}>
                      {hoveredTags.get(keyTag) ? (
                        <PlusCircleFilledIcon
                          width='20'
                          height='20'
                          classNameProp={'pe-1'}
                        />
                      ) : (
                        <PlusCircleDottedIcon
                          width='20'
                          height='20'
                          classNameProp={'pe-1'}
                        />
                      )}
                      <span>{ele.name}</span>
                    </div>
                  );
                })}
              </div>
            );
          } else if (field.type === 'select') {
            if (field.field === 'council_member') {
              field.options = [
                'Select Council Member',
                ...councilMembers(props.userList).map(cm => cm['full_name']),
              ];
            }
            if (field.field === 'contact_person') {
              return null;
            }
            if (field.field === 'reason') {
              field.options = ['Select Reason', ...field.options];
            }
            if (field.field === 'status') {
              field.options = ['Select Status', ...field.options];
            }
            let disable = isCMStaff ? !field.cmStaffCreateEditable : false;
            return (
              <div className='mb-3' key={`${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                {field.field === 'reason' && (
                  <>
                    <ToolTipIcon
                      classNameProp={styles['user-guide-field-icon-procs']}
                      idProp='reason'
                    />
                    <UncontrolledPopover
                      innerClassName={`${styles['popover-container']}`}
                      className={styles['popover-outer-container']}
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='reason'>
                      <PopoverBody className={`${styles['popover-body']}`}>
                        {reasonToolTipText}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
                <select
                  className={
                    !errorField[field.field]
                      ? 'form-control'
                      : styles['input-text-area-warning']
                  }
                  value={
                    procRequest[field.field]
                      ? procRequest[field.field].toString()
                      : ''
                  }
                  ref={refs[field.field]}
                  disabled={disable}
                  onChange={e => {
                    if (field.field === 'council_member') {
                      let associated_cm = props.userList.filter(user => {
                        return user.full_name === e.target.value;
                      })[0];

                      let availableContacts = props.userList.filter(user => {
                        if (user.under_council_member) {
                          return user.under_council_member === associated_cm.id;
                        } else {
                          return user.id === associated_cm.id;
                        }
                      });

                      setProcRequest({
                        ...procRequest,
                        council_member: e.target.value,
                      });
                    } else if (field.field === 'reason') {
                      setProcRequest({
                        ...procRequest,
                        reason: e.target.value,
                        other: '',
                      });
                    } else {
                      setProcRequest({
                        ...procRequest,
                        [field.field]: e.target.value,
                      });
                    }
                  }}
                  multiple={field.multiple}>
                  {field.options &&
                    field.options.map((op, idx1) => {
                      if (
                        [
                          'Select Contact Person',
                          'Select Council Member',
                          'Select Reason',
                          'Select Status',
                        ].includes(op)
                      ) {
                        return (
                          <option key={idx1} value={null} hidden>
                            {op}
                          </option>
                        );
                      } else {
                        return (
                          <option
                            key={idx1}
                            value={op}
                            disabled={
                              op.toString() ===
                              'Stated Mtg Ceremonial (Currently Suspended)'
                            }>
                            {op.toString()}
                          </option>
                        );
                      }
                    })}
                </select>
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          } else if (field.type === 'checkbox') {
            return (
              <div className='mb-3' key={`${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                <div className={styles['radio-buttons']}>
                  <div className='mb-3' name={field.field}>
                    <input
                      type='radio'
                      id={`yes-${field.field}`}
                      name={field.field}
                      checked={procRequest[field.field]}
                      onChange={e => {
                        setProcRequest({
                          ...procRequest,
                          [field.field]: true,
                          council_member: props.userList.find(ele =>
                            ele.role.includes('Speaker')
                          ).full_name,
                        });
                      }}
                      className={styles['checkbox']}
                    />
                    <label htmlFor={`yes-${field.field}`}>Yes</label>
                    <input
                      type='radio'
                      id={`no-${field.field}`}
                      name={field.field}
                      checked={
                        !procRequest[field.field] &&
                        typeof procRequest[field.field] !== 'undefined'
                      }
                      onChange={e => {
                        setProcRequest({
                          ...procRequest,
                          [field.field]: false,
                          council_member: '',
                        });
                      }}
                      className={styles['checkbox']}
                    />
                    <label htmlFor={`no-${field.field}`}>No</label>
                    {errorField[field.field] && (
                      <p className={styles['warning-message']}>
                        {errorField[field.field]['error_message']}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          } else {
            let disable = isCMStaff ? !field.cmStaffCreateEditable : false;
            if (
              isCMStaff &&
              field.field === 'other' &&
              procRequest.reason !== 'Other (Explain below)'
            ) {
              disable = true;
            }
            return (
              <div className='mb-3' key={`${idx}`}>
                <p className={styles['input-text-title']}>
                  {field.required ? (
                    <>
                      {field.title} <span style={{ color: '#DC6C6C' }}>*</span>
                    </>
                  ) : (
                    field.title
                  )}
                </p>
                {field.field === 'other' && (
                  <>
                    <ToolTipIcon
                      classNameProp={styles['user-guide-field-icon-procs']}
                      idProp='other'
                    />
                    <UncontrolledPopover
                      innerClassName={`${styles['popover-container']} ${styles['popover-wide']}`}
                      className={styles['popover-outer-container']}
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='other'>
                      <PopoverBody className={`${styles['popover-body']}`}>
                        {otherToolTipText}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
                <input
                  className={`form-control ${
                    !errorField[field.field]
                      ? styles['input-text-area']
                      : styles['input-text-area-warning']
                  }`}
                  type={field.type}
                  name={field.field}
                  value={procRequest[field.field] || ''}
                  maxlength={
                    (['honoree', 'other'].includes(field.field) && 255) ||
                    (field.field === 'notes' && 1600)
                  }
                  onChange={e => {
                    setProcRequest({
                      ...procRequest,
                      [field.field]: e.target.value,
                    });
                  }}
                  disabled={disable}
                  ref={refs[field.field]}
                />
                {errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            );
          }
        } else return null;
      });
    return newProcRequestDisplay;
  };

  let isCMStaff = rolesUnderCouncilMembers.includes(props.userProfile.role);
  let fieldsPerRow = 10;
  let fieldsPerPage = fieldsPerRow * 2;
  let procRequestFieldsFiltered = [];
  if (ProcRequestFields) {
    procRequestFieldsFiltered = [...ProcRequestFields];
  }
  let totalLength = procRequestFieldsFiltered.reduce((acc, curr) => {
    if (
      ((isCMStaff && curr.cmStaffCreateViewable) || !isCMStaff) &&
      curr.field !== 'requested_by'
    ) {
      return curr.vertSize ? curr.vertSize + acc : 1 + acc;
    } else {
      return acc;
    }
  }, 0);

  let pageLength = Math.ceil(procRequestFieldsFiltered.length / fieldsPerPage);
  let start = 0;
  let pageFields = procRequestFieldsFiltered.slice(
    start,
    start + fieldsPerPage
  );

  let leftSideLast = procRequestFieldsFiltered
    .map(ele => ele.title)
    .indexOf('Proclamation Draft');

  let leftFields = procRequestFieldsFiltered.slice(0, leftSideLast);
  let rightFields = procRequestFieldsFiltered.slice(leftSideLast);

  return (
    <form>
      <div className={styles['modal-header']}>
        <div>
          <span className='popup-detail-title'>New Proclamation Request</span>
        </div>
      </div>
      {props.newProcStatus === 'fail' && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <p>Failed to create this proclamation request</p>
        </div>
      )}
      {errorsPresent && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <div>
            <p>There was an error with your form submission.</p>
            <p>Please correct the highlighted field(s) below</p>
          </div>
        </div>
      )}
      {props.isLoading === true && (
        <div className={styles['fixed-loading-container']}>
          <img
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            className='loading-image'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {props.newProcStatus !== 'success' && (
        <>
          <div className={styles['report-fields']}>
            <div className={styles['report-field-column']}>
              {generateFields(leftFields)}
            </div>
            <div className={styles['report-field-column']}>
              {generateFields(rightFields)}
            </div>
          </div>
          <div className={styles['modal-footer']}>
            <button
              type='button'
              className={`submit-button ${styles['cancel-button']}`}
              onClick={() => {
                props.changeProcRequestDisplay('list');
                props.changeProcRequestPopupWindow(false);
              }}>
              Cancel
            </button>
            {0 === pageLength - 1 && (
              <button
                type='button'
                className={`submit-button ${styles['footer-button']}`}
                onClick={e => onSubmit(e, pageFields)}>
                Submit
              </button>
            )}
          </div>
        </>
      )}
      {props.newProcStatus === 'success' && (
        <div
          className={`alert alert-success ${styles['alert-danger-banner']} ${styles['success']}`}
          role='alert'>
          <LargeCheckMarkIcon />
          <div>
            <p>The proclamation request has been successfully updated!</p>
            <p>Page will refresh shortly.</p>
          </div>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    committees: state.userReducer.committees,
    newProcStatus: state.procRequestReducer.newProcStatus,
    userList: state.userReducer.userList,
    savedProgress: state.procRequestReducer.savedProgress,
    isLoading: state.procRequestReducer.isLoading,
  };
};

export default connect(mapStateToProps, actions)(CreateNewProcRequest);
