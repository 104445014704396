import { call, takeEvery, put } from 'redux-saga/effects';
import {
  fileDownloadAction,
  downloadFileStatus,
} from '../actions/fileDownloadAction';

// Send HTTP.GET to get a file previously uploaded from the frontend
function getFileAndDownloadRequest(path) {
  // Download the file under the same window
  // window.location.href = path;

  // Download file from a different window
  setTimeout(() => {
    const response = { file: path };
    window.open(response.file);
  }, 100);
}

// Get the target file in the backend and download to local machine
// End user should be able to download either a single file or a .zip file
function* downloadingTargetFile(action) {
  if (action !== undefined) {
    try {
      yield call(getFileAndDownloadRequest, action.path);
    } catch (e) {
      yield put(downloadFileStatus(false));
      console.log(e);
    }
  }
}

function* fileDownloadAPI() {
  yield takeEvery(
    fileDownloadAction.GET_FILE_AND_DOWNLOAD,
    downloadingTargetFile
  );
}

export default fileDownloadAPI;
