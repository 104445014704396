import React from 'react';
import { connect } from 'react-redux';
import styles from '../styles/VideoTutorials.module.scss';
import VideoSection from './VideoSection';

const VideoTutorials = (props) => {

  const { trainingVideos } = props;

  return (
    <div className='main-container'>
      <div
        className={`container-fluid text-white mb-5 py-4 text-center ${styles['welcome-section']}`}>
        <div className='row'>
          <h2 className='fs-5'>Explore IWOMM's video tutorial library</h2>
          <div className='col'>
            <div className='mt-3'>
              <video
                width='100%'
                height='auto'
                controls
                className='rounded-3 shadow-lg'>
                <source
                  src={`${trainingVideos[1]['videos'][0]['src']}`}
                  type='video/mp4'
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
      <div className='container-xl px-md-5 px-sm-2'>
        { trainingVideos.slice(1).map((section, sectionIndex) => <VideoSection key={sectionIndex} section={section} />) }
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    trainingVideos: state.userReducer.trainingLinks,
  };
};
export default connect(mapStateToProps)(VideoTutorials);
