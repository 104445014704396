import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/report-tracking/TagsDisplay.module.scss';
import { FormGroup } from 'reactstrap';
import { CloseButtonCircleFillIcon } from '../../services/SvgLibrary';
/**
 * FilterInput component
 * Specifically for the FilterMenu, the component provides the input options for filtering
 * Adapted from TagsDisplay
 * @param {type} prop: Type of input (LSR Number is a text input, all others are dropdown)
 * @param {field} prop: Which filter
 * @param {selectedOptions} prop: Any selected options to display as tags
 * @param {dropdownOptions} prop: Options to display in dropdown menu
 * @param {onAddFilter} prop: Callback function when a filter is added
 * @param {onRemoveFilter} prop: Callback function when a filter is removed
 * @param {inputTitle} prop: Title of dropdown menu
 * @param {disabled} prop: Boolean value for disabled property
 * @param {userMap} prop: A map with key as id and value as user full name
 * @param {committeeMap} prop: A map with key as id and value as committee full name
 * @returns a functional component
 */
const FilterInput = ({
  type,
  field,
  selectedOptions,
  dropdownOptions,
  onAddFilter,
  onRemoveFilter,
  inputTitle,
  disabled,
  userMap,
  committeeMap,
  category,
  placeholder,
}) => {
  const [searchContent, setSearchContent] = useState('');
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const inputRef = useRef(null);
  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    document.addEventListener('mouseup', handleOutsideClick, false);
    return () => {
      document.removeEventListener('mouseup', handleOutsideClick, false);
    };
  }, []);

  /**
   * handleOutsideClick: function to detect when user clicks outside dropdown menu, close it
   * @param e: click event
   */
  function handleOutsideClick(e) {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setDisplayDropdown(false);
    }
  }

  /**
   * Generate tags for selected options with delete tag functionality
   */
  const tags =
    selectedOptions &&
    selectedOptions.map((option, idx) => {
      let tagDescription = '';
      if (category === 'committee') {
        tagDescription = committeeMap.get(option);
      } else if (category === 'users') {
        tagDescription = userMap.get(option);
      } else if (category === 'other' || category === 'ls_number') {
        tagDescription = option;
      }

      if (!disabled) {
        return (
          <div key={idx} className={styles['tag']}>
            <span>{tagDescription}</span>
            <CloseButtonCircleFillIcon
              width='12'
              height='12'
              classNameProp={styles['delete-icon']}
              onClickFunction={() => onRemoveFilter(field, option)}
            />
          </div>
        );
      }
      return null;
    });
  // const tags = () => {}

  // Generate the dropdown search list
  // The dropdown list only appears when the input text area is not empty
  const filterDropdown = dropdownOptions.filter(options =>
    options.name.toLowerCase().includes(searchContent.toLowerCase())
  );

  if (filterDropdown.length === 0) {
    filterDropdown.push({ 'name': 'No valid result' });
  }

  return (
    <FormGroup className={styles['tag-form-group']}>
      <p className={styles['input-tag-title']}>
        <b>{inputTitle}</b>
      </p>

      <div>{tags}</div>
      <>
        {type === 'dropdown' && (
          <input
            type='text'
            className={`form-control field-size ${styles['input-text-area']}`}
            value={searchContent}
            placeholder={
              placeholder ? placeholder : 'Type and search in dropdown list'
            }
            ref={inputRef}
            disabled={disabled}
            onChange={e => {
              setSearchContent(e.target.value);
            }}
            onFocus={() => {
              setDisplayDropdown(true);
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') e.preventDefault();
            }}
          />
        )}

        {type === 'text' && (
          <input
            type='text'
            className={`form-control field-size ${styles['input-text-area']}`}
            value={searchContent}
            placeholder={'Enter an LSR number or LSR numbers separated by , '}
            ref={inputRef}
            disabled={disabled}
            onChange={e => {
              //Only take in positive number inputs
              let val = e.target.value.replace(/[^0-9\<\>\,\=\.]/g, '');
              setSearchContent(val);
            }}
            onBlur={e => {
              onAddFilter(field, e.target.value);
              setSearchContent('');
            }}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                onAddFilter(field, e.target.value);
                setSearchContent('');
              }
            }}
          />
        )}

        {displayDropdown && (
          <ul className={styles['search-dropdown']}>
            {filterDropdown.map((option, idx) => (
              <li
                key={idx}
                onMouseDown={() => {
                  if (option.name.toLowerCase() !== 'no valid result') {
                    onAddFilter(field, option.value);
                  }
                  setSearchContent('');
                  setDisplayDropdown(false);
                }}>
                {option.name}
              </li>
            ))}
          </ul>
        )}
      </>
    </FormGroup>
  );
};

export default FilterInput;
