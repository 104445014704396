import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import LsRequestTable from './LsRequestTable';
import { getMyActiveLs } from '../../../actions/myTasksAction';
import styles from '../../../styles/user/dashboard/MyTasks.module.scss';
import { ShortRightArrowIcon } from '../../../services/SvgLibrary';

const MyActiveLs = props => {
  const { getMyActiveLs, isLoading, myActiveLs } = props;
  // /** component mount */
  useEffect(() => {
    if (!myActiveLs) {
      getMyActiveLs();
    }
  }, [myActiveLs, getMyActiveLs]);

  return (
    <div className={styles['widget-container']}>
      <div className={styles['table-header-container']}>
        <h3>My Active LS Requests</h3>
        <a href='/myTasks/myActiveLs'>
          View All
          <ShortRightArrowIcon />
        </a>
      </div>
      <LsRequestTable loading={isLoading} myLs={myActiveLs} tableType='mine' />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.myTasksReducer.isLoading,
    myActiveLs: state.myTasksReducer.myActiveLs,
  };
};

const mapDispatchToProps = {
  getMyActiveLs,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyActiveLs);
