import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from '../../styles/library/file.module.scss';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const Prompt = props => {
  return ReactDOM.createPortal(
    <Modal className={styles['delete-modal']} isOpen={props.isOpen}>
      <ModalBody>{props.message}</ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={props.onConfirmation}>
          Yes
        </Button>
        <Button color='primary' onClick={() => props.setIsOpen(false)}>
          No
        </Button>
      </ModalFooter>
    </Modal>,
    document.getElementById('portal-root')
  );
};

Prompt.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
};

export default Prompt;
