import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createEvent } from '../../../../actions/eventAction';

const CreateEventWidget = props => {
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    location: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    link: '',
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setEventData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const { startDate, startTime, endDate, endTime, ...otherData } = eventData;

    const date_start = new Date(startDate + ' ' + startTime);
    const date_end = new Date(endDate + ' ' + endTime);
    const errors = ["Errors:"]
    if (date_start >= date_end) {
      errors.push("Start date must be before end date")
    }
    
    if (date_start < new Date()) {
      errors.push("Start date/time must be in the future")
    }

    for (let field in eventData) {
      if (field === "link") {
        continue
      } else {
        if (!eventData[field]) {
          if (field === "startDate") {
            errors.push(`Must provide value for start date`)
          } else if (field === "startTime") {
            errors.push(`Must provide value for start time`)     
          } else if (field === "endDate") {
            errors.push(`Must provide value for end date`)
          } else if (field === "endTime") {
            errors.push(`Must provide value for end time`)
          } else {
            errors.push(`Must provide value for ${field}`)
          }
        }
      }
    }

    const formData = {
      ...otherData,
      date_start,
      date_end,
    };


    if (errors.length > 1) {
      alert(errors.join('\n'))
    } else {
      props.createEvent(formData);
    }
  };

  return (
    <div className='card border-0 h-100 rounded-3'>
      <div className='card-body'>
        <h2 className='fs-4 mb-3'>Create Event</h2>
        <form onSubmit={handleSubmit}>
          <div className='form-group row mb-3'>
            <label htmlFor='eventTitle' className='col-sm-3 col-form-label'>
              Event Title
            </label>
            <div className='col-sm-9'>
              <input
                type='text'
                className='form-control'
                id='eventTitle'
                name='title'
                value={eventData.title}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label
              htmlFor='eventDescription'
              className='col-sm-3 col-form-label'>
              Description
            </label>
            <div className='col-sm-9'>
              <textarea
                className='form-control'
                id='eventDescription'
                rows='3'
                name='description'
                value={eventData.description}
                onChange={handleChange}></textarea>
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label htmlFor='eventLocation' className='col-sm-3 col-form-label'>
              Location
            </label>
            <div className='col-sm-9'>
              <input
                type='text'
                className='form-control'
                id='eventLocation'
                name='location'
                value={eventData.location}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label htmlFor='startDate' className='col-sm-3 col-form-label'>
              Start Date
            </label>
            <div className='col-sm-9'>
              <input
                type='date'
                className='form-control'
                id='startDate'
                name='startDate'
                value={eventData.startDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label htmlFor='startTime' className='col-sm-3 col-form-label'>
              Start Time
            </label>
            <div className='col-sm-9'>
              <input
                type='time'
                className='form-control'
                id='startTime'
                name='startTime'
                value={eventData.startTime}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label htmlFor='endDate' className='col-sm-3 col-form-label'>
              End Date
            </label>
            <div className='col-sm-9'>
              <input
                type='date'
                className='form-control'
                id='endDate'
                name='endDate'
                value={eventData.endDate}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label htmlFor='endTime' className='col-sm-3 col-form-label'>
              End Time
            </label>
            <div className='col-sm-9'>
              <input
                type='time'
                className='form-control'
                id='endTime'
                name='endTime'
                step='300'
                value={eventData.endTime}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='form-group row mb-3'>
            <label htmlFor='eventLink' className='col-sm-3 col-form-label'>
              Link
            </label>
            <div className='col-sm-9'>
              <input
                type='url'
                className='form-control'
                id='eventLink'
                name='link'
                value={eventData.link}
                onChange={handleChange}
                placeholder='http://'
              />
            </div>
          </div>

          <button type='submit' className='submit-button'>
            Create Event
          </button>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  createEvent,
};

const mapStateToProps = state => {
  return {
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    newUserCreationStatus: state.userReducer.newUserCreationStatus,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEventWidget);
