import React, { useState, useEffect } from 'react';
import styles from '../../../styles/user/dashboard/MyTasks.module.scss';
import { ShortRightArrowIcon } from '../../../services/SvgLibrary';

const ReportsTable = props => {
  const [columns, setColumns] = useState([
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Agency',
      field: 'submitting_agency',
    },
    {
      title: 'Division',
      field: 'division',
    },
    {
      title: 'Committee(s)',
      field: 'committees',
    },
  ]);

  const { allReports, isLoading, tableType } = props;

  /** component mount */
  useEffect(() => {
    tableType === 'past_due' || tableType === 'upcoming'
      ? setColumns(prevColumns => [
          ...prevColumns,
          { title: 'Next Due', field: 'next_due' },
        ])
      : setColumns(prevColumns => [
          ...prevColumns,
          { title: 'Last Received', field: 'last_received' },
        ]);
  }, [tableType]);

  const displayTableHeaders = columns.map((column, idx) => (
    <th className={styles['report-dashboard-table-headers']} key={idx}>
      {column.title}
    </th>
  ));

  let reportList = allReports;
  const subReports = reportList.results?.slice(0, 15);
  const myReportDisplay =
    reportList.results &&
    subReports.map((report, idx) => {
      return (
        <tr key={idx}>
          {columns.map((column, idx1) => {
            if (
              column.field === 'next_due' ||
              column.field === 'last_received'
            ) {
              if (
                report[column.field] === null ||
                report[column.field] === ''
              ) {
                return <td key={idx1}>N/A</td>;
              } else {
                let date = report[column.field].substring(0, 10).split('-');
                const time = new Date(date[0], parseInt(date[1]) - 1, date[2]);
                return <td key={idx1}>{time.toLocaleDateString()}</td>;
              }
            } else if (column.field === 'committees') {
              return (
                <td key={idx1}>
                  {report[column.field]
                    .map(committee => committee.committee_name)
                    .join(', ')}
                </td>
              );
            } else if (column.field === 'submitting_agency') {
              return (
                <td key={idx1}>
                  {report[column.field].map(agency => agency.name).join(', ')}
                </td>
              );
            } else {
              return <td key={idx1}>{report[column.field]}</td>;
            }
          })}
        </tr>
      );
    });

  return (
    <div className={styles['widget-container']}>
      <div className={styles['table-header-container']}>
        <h3>Recent Op Reqs</h3>
        <a href='/myTasks/myOpReqs'>
          View All
          <ShortRightArrowIcon />
        </a>
      </div>
      <table className={`table table-hover ${styles['flex-table']}`}>
        <thead>
          <tr className='table-headers'>{displayTableHeaders}</tr>
        </thead>
        <tbody>
          {myReportDisplay}
          {isLoading && (
            <tr className='loading-container'>
              <td colSpan={columns.length}>
                <div className='spinner-border text-secondary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ReportsTable;
