import { userActions } from '../actions/userAction';

const initialState = {
  loading: null,
  emailLoad: false,
  newUserID: null,
  userLoginStatus: '',
  inputUsername: '',
  inputPassword: '',
  newUserCreationStatus: '',
  changingPasswordStatus: false,
  passwordChangeStatus: '',
  errorMessage: '',
  successMessage: '',
  twoFactorAuthMessage: '',
  isSuperUser: false,
  hasDefaultPassword: false,
  userList: [],
  allUserList: [],
  allAgencies: [],
  committees: [],
  division: [],
  userProfile: {
    id: '',
    username: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    suffix: '',
    full_name: '',
    role: '',
    email: '',
    phone: '',
    alternative_phone: '',
    desk_number: '',
    preferences: {},
    under_council_member: '',
    password_last_changed: '',
    groups: [],
    bio: '',
    permissions: {
      auth: {
        add_user: false,
        change_user: false,
        delete_user: false,
        view_user: false,
      },
      lsRequest_api: {
        add_lsrequest: false,
        change_lsrequest: false,
        delete_lsrequest: false,
        view_lsrequest: false,
      },
      reportTracking: {
        add_agency: false,
        add_agencyuser: false,
        add_report: false,
        change_agency: false,
        change_agencyuser: false,
        change_report: false,
        delete_agency: false,
        delete_agencyuser: false,
        delete_report: false,
        delete_attachment: false,
        delete_milestoneattachment: false,
        view_agency: false,
        view_agencyuser: false,
        view_report: false,
      },
      userLogin: {
        add_userprofile: false,
        change_userprofile: false,
        delete_userprofile: false,
        view_userprofile: false,
      },
      wiki: {
        add_comment: false,
        add_page: false,
        add_pagereview: false,
        add_reply: false,
        add_subject: false,
        add_tag: false,
        add_versiondiff: false,
        change_comment: false,
        change_page: false,
        change_pagereview: false,
        change_reply: false,
        change_subject: false,
        change_tag: false,
        change_versiondiff: false,
        delete_comment: false,
        delete_page: false,
        delete_pagereview: false,
        delete_reply: false,
        delete_subject: false,
        delete_tag: false,
        delete_versiondiff: false,
        update_all_page: false,
        update_committee_page: false,
        update_own_page: false,
        view_comment: false,
        view_page: false,
        view_pagereview: false,
        view_reply: false,
        view_subject: false,
        view_tag: false,
        view_versiondiff: false,
      },
      events: {
        add_event: false,
        update_event: false,
        view_event: false,
      },
    },
  },
  agencyUser: null,
  dueReportsNotification: {},
  upcomingReportsNotification: {},
  recentReportsNotification: {},
  loadingReports: false,
  notifications: [],
  square: [],
  trainingLinks: [],
  toastInfo: {
    showToast: false,
    toastTitle: '',
    toastBody: '',
    toastApp: '',
    toastAppAlt: '',
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case userActions.CHECKING_LOGIN_STATUS:
      return { ...state, userLoginStatus: action.status };
    case userActions.SET_USERNAME:
      return { ...state, inputUsername: action.username };
    case userActions.SET_PASSWORD:
      return { ...state, inputPassword: action.password };
    case userActions.CHANGING_PASSWORD:
      return { ...state, changingPasswordStatus: action.status };
    case userActions.CHECKING_CREATE_NEW_USER_STATUS:
      return { ...state, newUserCreationStatus: action.status };
    case userActions.CLEAR_ERROR_MESSAGE:
      return { ...state, errorMessage: '' };
    case userActions.GETTING_ERROR_MESSAGE:
      return { ...state, errorMessage: action.message };
    case userActions.CLEAR_SUCESS_MESSAGE:
      return { ...state, successMessage: '' };
    case userActions.GETTING_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.message };
    case userActions.GETTING_TWO_FACTOR_AUTH_MESSAGE:
      return { ...state, twoFactorAuthMessage: action.message };
    case userActions.LOAD_EMAIL:
      return { ...state, emailLoad: action.bool };
    case userActions.CHECKING_SUPERUSER:
      return { ...state, isSuperUser: action.status };
    case userActions.SAVING_USER_INFO:
      const {
        id,
        username,
        full_name,
        first_name,
        last_name,
        role,
        email,
        phone,
        alternative_phone,
        desk_number,
        preferences,
        permissions,
        committees,
        under_council_member,
        password_last_changed,
        groups,
        profile_pic,
        profile_pic_icon,
        bio,
        division,
      } = action.user;
      let user = {
        id: id,
        username: username,
        first_name: first_name,
        last_name: last_name,
        full_name: full_name,
        role: role,
        email: email,
        phone: phone,
        alternative_phone: alternative_phone,
        desk_number: desk_number,
        preferences: preferences,
        permissions: permissions,
        committees: committees,
        division: division,
        bio: bio,
        under_council_member: under_council_member,
        password_last_changed: password_last_changed,
        groups: groups,
        profile_pic,
        profile_pic_icon,
      };

      return { ...state, userProfile: user };
    case userActions.UPDATE_USER_BIO:
      return {
        ...state,
        userProfile: { ...state.userProfile, bio: action.bio },
      };
    case userActions.SAVE_USER_AGENCY_INFO:
      return { ...state, agencyUser: action.agencyUser };
    case userActions.USER_LIST:
      return {
        ...state,
        userList: action.userList.filter(user => user.is_active === true),
        allUserList: action.userList,
      };
    case userActions.STORE_COMMITTEES:
      return { ...state, committees: action.committees };
    case userActions.STORE_DIVISION:
      return { ...state, division: action.division };
    case userActions.DEFAULT_PASSWORD_BOOLEAN:
      return { ...state, hasDefaultPassword: action.bool };
    case userActions.LOADING:
      return { ...state, loading: action.bool };
    case userActions.SAVE_PASS_DUE_REPORTS_INFO:
      return { ...state, dueReportsNotification: action.info };
    case userActions.SAVE_UPCOMING_REPORTS_INFO:
      return { ...state, upcomingReportsNotification: action.info };
    case userActions.SAVE_RECENTLY_UPLOADED_REPORTS_INFO:
      return { ...state, recentReportsNotification: action.info };
    case userActions.SAVE_NEW_USER_ID:
      return { ...state, newUserID: action.value };
    case userActions.CHECKING_CHANGE_PASSWORD_STATUS:
      return { ...state, passwordChangeStatus: action.status };
    // case userActions.ADD_MORE_REPORTS:
    //   return {...state, dueReportsNotification: {...state.dueReportsNotification, next: action.next, results: [...state.dueReportsNotification.results,...action.reports]}}
    case userActions.SET_REPORT_LOADING:
      return { ...state, loadingReports: action.bool };
    case userActions.SAVE_ALL_AGENCIES:
      return { ...state, allAgencies: action.agencies };
    case userActions.SAVE_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };
    case userActions.UPDATE_USER_PREFERENCES:
      return { ...state, userPreferences: action.userPreferences };
    case userActions.SAVE_FACE_SQUARE:
      return { ...state, square: action.square };
    case userActions.SAVE_TRAINING_LINKS:
      return { ...state, trainingLinks: action.links };
    case userActions.ADD_TOAST_INFO:
      return { ...state, toastInfo: action.toastInfo };
    default:
      return state;
  }
}
