import React, { useEffect } from 'react';
import styles from '../../../styles/UserManual.module.scss';

function CMUserManual() {
  const addZoom = target => {
    // (A) FETCH CONTAINER + IMAGE
    let container = document.getElementById(target);
    let imgsrc =
      container.currentStyle || window.getComputedStyle(container, false);
    imgsrc = imgsrc.backgroundImage.slice(4, -1).replace(/"/g, '');
    let img = new Image();
    // (B) LOAD IMAGE + ATTACH ZOOM
    img.src = imgsrc;
    img.addEventListener('load', () => {
      let imgWidth = img.naturalWidth;
      let imgHeight = img.naturalHeight;
      let ratio = imgHeight / imgWidth;
      // (C) ZOOM ON MOUSE MOVE
      container.addEventListener('mousemove', e => {
        let boxWidth = container.clientWidth;
        let rect = e.target.getBoundingClientRect();
        let xPos = e.clientX - rect.left;
        let yPos = e.clientY - rect.top;
        let xPercent = xPos / (boxWidth / 100) + '%';
        let yPercent = yPos / ((boxWidth * ratio) / 100) + '%';
        Object.assign(container.style, {
          backgroundPosition: xPercent + ' ' + yPercent,
          backgroundSize: imgWidth / 2 + 'px',
        });
      });
      // (D) RESET ON MOUSE LEAVE
      container.addEventListener('mouseleave', () => {
        Object.assign(container.style, {
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        });
      });
    });
  };

  useEffect(() => {
    let images = [
      'activate',
      'activate-page',
      'iwomm-main-sidebar',
      'iwomm-sidebar-open',
      'iwomm-sidebar-closed',
      'create-new-lsr',
      'three-dots-lsr-table',
      'my-account-dropdown',
      'help-feedback-dropdown',
      'user-manual-dropdown',
    ];
    for (let i = 0; i < images.length; i++) {
      addZoom(images[i]);
    }
  }, []);

  return (
    <div className={styles['body-container']}>
      <div className={styles['user-manual-sidebar-container']}>
        <ul className='nav flex-column'>
          <li className='nav-item'>
            <a
              className={`${styles['pointer']} nav-link`}
              data-bs-toggle='collapse'
              href='#iwommIntroCollapse'
              aria-expanded='true'
              aria-controls='iwommIntroCollapse'>
              Intro to Navigating IWOMM
            </a>
            <div
              className={`collapse ${styles['user-manual-sub-nav']} show`}
              aria-expanded='true'
              id='iwommIntroCollapse'>
              <ul>
                <li>
                  <a href='#logging-in'>Logging in</a>
                </li>
                <li>
                  <a href='#dash-banner'>Dashboard and Banner</a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#accountCollapse'
                    aria-expanded='true'
                    aria-controls='accountCollapse'>
                    My Account
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='accountCollapse'>
                    <ul>
                      <li>
                        <a href='#profile'>Profile</a>
                      </li>
                      <li>
                        <a href='#help-feedback'>Help &amp; Feedback</a>
                      </li>
                      <li>
                        <a href='#user-manual'>User Manual</a>
                      </li>
                      <li>
                        <a href='#logout'>Logout</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className='nav-item'>
            <a className={`${styles['pointer']} nav-link`} href='#my-tasks'>
              My Tasks
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`${styles['pointer']} nav-link`}
              data-bs-toggle='collapse'
              href='#lsRequestCollapse'
              aria-expanded='true'
              aria-controls='lsRequestCollapse'>
              LS Requests
            </a>
            <div
              className={`collapse ${styles['user-manual-sub-nav']} show`}
              aria-expanded='true'
              id='lsRequestCollapse'>
              <ul>
                <li>
                  <a href='#ls-request-faqs'>LS Request FAQs</a>
                </li>
                <li>
                  <a href='#create-ls-request'>Creating a New LS Request</a>
                </li>
                <li>
                  <a href='#update-ls-request'>Updating an LS Request</a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#searchingLsrCollapse'
                    aria-expanded='true'
                    aria-controls='searchingLsrCollapse'>
                    Searching LS Requests
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='searchingLsrCollapse'>
                    <ul>
                      <li>
                        <a href='#ls-request-filters'>Filters</a>
                      </li>
                      <li>
                        <a href='#ls-request-search-bar'>Search bar</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href='#export-ls-request'>Export to Excel</a>
                </li>
              </ul>
            </div>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#staff-directory'>
              Staff Directory
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#tech-support'>
              Technical Support
            </a>
          </li>
        </ul>
      </div>

      {/***** 
			*******
			*******
			*******

			USER MANUAL CONTENT BELOW

			*******
			*******
			*******
			*****/}

      <div className={styles['user-manual-content-container']}>
        <div className='container fluid'>
          <div className='row align-items-center'>
            <div className='col-sm-12 col-lg-4'>
              <img
                className={styles['user-manual-iwomm-logo']}
                alt='IWOMM Logo'
                src='/img/Logo/iwomm white logo.png'
              />
            </div>
            <div className='col-sm-12 col-lg-8'>
              <p>
                This document provides information for Central Staff to use the
                IWOMM web applications to access information and manage
                workflow. Much of the information contained in this document can
                also be obtained through the info icons located throughout
                IWOMM.
              </p>
            </div>
          </div>
        </div>
        {/* Logging in Section Start */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='logging-in'>
                <h2>Intro to Navigating IWOMM</h2>
                <h3>Logging in</h3>
                <p>To access IWOMM follow these steps:</p>
                <ol>
                  <li>
                    Visit
                    <a href='https://iwomm.council.nyc.gov'>
                      iwomm.council.nyc.gov
                    </a>
                  </li>
                  <li>
                    Click on <strong>Activate</strong>
                  </li>
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='activate'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/login-activate-link.png')",
                    }}></div>
                  <li>
                    Enter your <em>username</em>, which will be the beginning of
                    your NYC council email address:
                    <br />
                    <div style={{ textIndent: '50px' }}>
                      Ex: ntriantaphilides@council.nyc.gov would have the
                      username “ntriantaphilides”
                    </div>
                  </li>
                  <li>
                    Click on the <strong>Activate</strong> button
                  </li>
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='activate-page'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/login-activate-button.png')",
                    }}></div>
                  <li>
                    If your username has been entered correctly, you should
                    receive an email with a link to change your password
                  </li>
                </ol>
                <p>
                  Contact the Web Development Team
                  (WebDevelopmentUnit@council.nyc.gov) if you have difficulty
                  setting up an account after following the instructions above.
                </p>
              </section>
            </div>
          </div>
        </div>
        {/* Logging in Section End */}
        {/* Dashboard and Banner Section Start */}
        <div className={`container ${styles['container']}`} id='dash-banner'>
          <div className='row'>
            <div className='col'>
              <h3>Dashboard and Banner</h3>
              <p>
                When you successfully log in to IWOMM, the first page you should
                see is your Dashboard. This page highlights your active and/or
                relevant items from each IWOMM application in one convenient
                hub. The Banner on the left side of the page allows you to
                navigate through the IWOMM applications (My Tasks, LS Requests,
                Proclamation Requests and Staff Directory). Click a title listed
                on the Banner to visit that application.
              </p>
              {/* Infographic of sidebar */}
              <div
                className={styles['user-manual-screen-captures']}
                id='iwomm-main-sidebar'
                style={{
                  backgroundImage:
                    "url('../img/User manual/iwomm-main-sidebar.png')",
                }}></div>
              <p>
                Click the <strong>IWOMM</strong> icon at the top of the Banner
                to return to the Dashboard at any time. On any page, click the
                <strong>X</strong> next to the page title (when on the homepage
                the page title is “Dashboard”) to hide the Banner.
              </p>
              {/* Infographic of the X */}
              <div
                className={styles['user-manual-screen-captures']}
                id='iwomm-sidebar-open'
                style={{
                  backgroundImage:
                    "url('../img/User manual/iwomm-sidebar-open.png')",
                }}></div>
              <p>
                If the Banner is hidden, click the three lines next to the page
                title to reveal the banner.
              </p>
              {/* Infographic of the three lines */}
              <div
                className={styles['user-manual-screen-captures']}
                id='iwomm-sidebar-closed'
                style={{
                  backgroundImage:
                    "url('../img/User manual/iwomm-sidebar-closed.png')",
                }}></div>
            </div>
          </div>
        </div>
        {/* Dashboard and Banner Section End */}
        {/* My Account Section Start */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section>
                <h3>My Account</h3>
                <p>
                  Click on the <strong>My Account</strong> dropdown on the top
                  right of the IWOMM page for the following features:
                </p>
              </section>
              <section id='profile'>
                <h4>Profile</h4>
                <p>
                  Visit the Profile page to see the contact information other
                  users may find for you through the Staff Directory. If any
                  information in your profile needs to be updated, submit a
                  request through <strong>Help &amp; Feedback </strong>
                  (see below). You can also update your account password through
                  this page by clicking <strong>Change Password</strong>.
                </p>
              </section>
              <section id='help-feedback'>
                <h4>Help &amp; Feedback</h4>
                <p>
                  Visit Help &amp; Feedback to submit a request for technical
                  support, report a bug, or provide feedback and feature
                  requests. Complete the prompts and click <strong>Send</strong>
                  . Someone from the Web Development Team will respond to your
                  request as quickly as possible.
                </p>
              </section>
              <section id='user-manual'>
                <h4>User Manual</h4>
                <p>Clicking User Manual will access this User Manual.</p>
              </section>
              <section id='logout'>
                <h4>Logout</h4>
                <p>Clicking logout will immediately log you out of IWOMM.</p>
              </section>
            </div>
          </div>
        </div>
        {/* Account Section End */}
        {/* My Tasks Section Start */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='my-tasks'>
                <h2>My Tasks</h2>
                <p>
                  My Tasks is a shortcut to your action-items with access to
                  more information and functionality than the Dashboard. Click
                  the <strong>My Tasks</strong> dropdown menu on the left side
                  banner to see an expanded view of your active and/or relevant
                  items from each IWOMM application. You can access more
                  information from the titles in the menu, as follows:
                </p>
                <p>
                  <em>
                    Note: more information about the functionality of each item
                    is found under the heading of that application later in this
                    User Manual.
                  </em>
                </p>
                <p>
                  Click <strong>My Active LS Requests</strong> to display a
                  table view of all your active Legislative Service Requests
                  (“LSRs”)
                </p>
                <p>
                  Click <strong>All My LS Requests</strong> to display a table
                  view of all of your LSRs, whether active or inactive.
                </p>
              </section>
            </div>
          </div>
        </div>
        {/* My Tasks Section Ends */}
        {/* LS Requests Section Begins */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section>
                <h2>LS Requests</h2>
                <p>
                  Legislative Service Requests (“LSRs”) may be submitted by
                  Council Members, the Public Advocate and Council staff to
                  track the legislative drafting process. Throughout this
                  document, “LS Requests” refers to the IWOMM application or to
                  IWOMM functionality, and “LSR” refers to a request for
                  legislative services.
                </p>
                <p>
                  LS Requests is an application designed to manage the LSR
                  intake process and the evolution of an LSR into a local law,
                  resolution or street co-naming. The application facilitates
                  correspondence between Council Members/the Public Advocate and
                  Central Staff, and displays information about each LSR
                  pertinent to the individual user. The application is designed
                  to be customizable to each user’s unique preferences.
                </p>
                <p>
                  To visit the LS Requests application click on
                  <strong>LS Requests</strong> in the left side banner, or click
                  on an LSR highlighted on your Dashboard page.
                </p>
              </section>

              <section id='ls-request-faqs'>
                <h3>LS Requests FAQs</h3>
                <div className='accordion accordion-flush' id='LsrFAQ'>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqLsrHeaderOne'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqLsrCollapseOne'
                        aria-expanded='false'
                        aria-controls='flush-faqLsrCollapseOne'>
                        What is the LSR Table?
                      </button>
                    </div>
                    <div
                      id='flush-faqLsrCollapseOne'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqLsrHeaderOne'
                      data-bs-parent='#LsrFAQ'>
                      <div className='accordion-body'>
                        The “LSR Table” refers to the listed view of LSRs as
                        seen on the main LS Requests application page, whether
                        it’s one LSR, the full log, or any other combination of
                        LSRs. Clicking a column heading will sort the LSRs in
                        alphabetical order using the information in that column.
                        Clicking the column heading again will reverse the
                        sorting from Z to A.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqLsrHeaderTwo'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqLsrCollapseTwo'
                        aria-expanded='false'
                        aria-controls='flush-faqLsrCollapseTwo'>
                        How can I view more or less information on my LSR Table?
                      </button>
                    </div>
                    <div
                      id='flush-faqLsrCollapseTwo'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqLsrHeaderTwo'
                      data-bs-parent='#LsrFAQ'>
                      <div className='accordion-body'>
                        Click the <strong>Columns</strong> dropdown below the
                        search bar on the top left of the page to select which
                        columns to view or hide.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item mb-3'>
                    <div
                      className='accordion-header'
                      id='flush-faqLsrHeaderThree'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqLsrCollapseThree'
                        aria-expanded='false'
                        aria-controls='flush-faqLsrCollapseThree'>
                        How can I customize my LSR Table?
                      </button>
                    </div>
                    <div
                      id='flush-faqLsrCollapseThree'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqLsrHeaderThree'
                      data-bs-parent='#LsrFAQ'>
                      <div className='accordion-body'>
                        To save your column selections, use the preset options
                        in the
                        <strong> Columns</strong> dropdown as follows:
                        {/* Indent Ordered List */}
                        <ol>
                          <li>
                            Click the <strong>Columns</strong> dropdown on the
                            top left of the page
                          </li>
                          <li>
                            Select one of the presets (i.e., Default, Preset 1,
                            etc.)
                          </li>
                          <li>Select the columns you want to be visible</li>
                          <li>
                            IWOMM will automatically save the columns you have
                            selected for that preset
                          </li>
                          <li>
                            Selecting the preset in the future will apply those
                            column selections
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Underline text below */}
              <p style={{ textDecoration: 'underline' }}>
                Explanation of Columns in the LSR Table:
              </p>
              <div className={styles['column-definitions-container']}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong>60-Day Date</strong>: The date 60 days after the
                      LSR was last activated.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong>Attachment Description</strong>: A description of
                      attachments the CM submitted, if any.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong>Change of Status Letter</strong>: The letter
                      transmitted by Central Staff to the CM noting more
                      information is needed from the CM in order to proceed, the
                      LSR will take more than 60 days to complete, or Central
                      Staff is recommending withdrawal of the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong>CM Attachment</strong>: This where any attachment
                      files submitted by the CM can be accessed.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>CM Notes</b>: Notes for the CM from Central Staff.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Co Prime(s)</b>: The co-prime sponsors of the LSR; this
                      field should not be updated until co-sponsorship
                      agreements have been confirmed by all parties.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Committee</b>: The committee initially designated for
                      the LSR for drafting purposes; this designation may change
                      upon introduction of the legislation.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Committee Report</b>: The Committee Report or Briefing
                      Paper prepared for a hearing on the introduced
                      legislation.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Contact Email</b>: The email address for the point
                      person in the CM’s office.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Contact Name</b>: The name of the point person in the
                      CM’s office.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Contact Phone</b>: The phone number for the point
                      person in the CM’s office.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Coversheet</b>: The pdf of the signed coversheet
                      transmitted to the CM.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Date Received</b>: The date IWOMM received the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Describe The Problem</b>: CM’s description of the
                      problem to be solved by the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Division</b>: The Central Staff division assigned the
                      LSR for drafting purposes; this designation may change
                      upon introduction of the legislation.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Duplicate Checked</b>: Whether Central Staff has
                      searched the log to check for duplicate LSRs and to
                      determine whether this LSR is first-in-time.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Existing Laws</b>: Any existing laws, regulations, or
                      rules noted by the CM and related to the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>First In Time</b>: Whether this LSR appears to be the
                      earliest-in-time LSR submitted for this proposed
                      legislation.
                      <br />
                      <em>
                        Note: The First In Time determination may change as more
                        information is obtained.
                      </em>
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>In Review</b>: Whether staff have transmitted drafts to
                      supervisors for review.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Inspiration For LS Request</b>: Background information
                      provided by the CM submitting the LSR, including whether
                      the LSR was inspired by a news or law review article, a
                      hearing, a constituent complaint or some other source.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Operational Requirement</b>: Whether the LSR will
                      result in the submission of a report or milestone.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Last Actived</b>: The most recent date the LSR was
                      changed from an inactive status to an active status,
                      meaning the LSR is actively being worked on and the 60-day
                      clock is running.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Legislative Solution</b>: The CM’s description for how
                      legislation will address the problem.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>LSR #</b>: The serial number assigned to the
                      Legislative Service Request by IWOMM.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>LSR Type</b>: Whether the CM requests a local law,
                      resolution or co-naming.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Plain Language Summary</b>: The plain-language summary
                      transmitted to the CM by Central Staff (bills only).
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Prepared Legislation</b>: The legislation (local law or
                      resolution) transmitted to the CM by Central Staff for
                      introduction
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Reintroduction</b>: Whether the LSR is a resubmission
                      of an LSR or introduction from a prior session.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Related Discussions</b>: Any parties the CM notes upon
                      submission for Central Staff to discuss more information
                      about the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Sponsor</b>: The CM who submitted this LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Staff</b>: The member of Central Staff assigned to this
                      LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Status</b>: The current status of the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Submitted By</b>: The IWOMM user account that submitted
                      the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Waived Confidentiality</b>: Whether the CM has waived
                      confidentiality of this LSR for co-sponsorship purposes.
                    </div>
                  </li>
                </ul>
              </div>

              <section id='create-ls-request'>
                <h3>Submitting a New LS request </h3>
                <p>To submit a new LSR follow these steps:</p>
                <ol>
                  <li>
                    Click the purple circle with a plus sign, which can be found
                    in the bottom right corner of the page
                  </li>
                  {/* Infograph of the purple circle with a plus sign */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='create-new-lsr'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/create-new-lsr.png')",
                    }}></div>
                  <li>A pop up containing information fields will appear.</li>
                  <li>
                    Complete the fields of information as comprehensively as
                    possible.
                    <br />
                    <em>
                      
                      Note: An information field with an asterisk must be
                      completed in order to proceed
                    </em>
                  </li>
                  <li>
                    When all information fields on a page are complete click
                    <strong>Next </strong>
                    in the top right corner of the pop up.
                  </li>
                  <li>
                    When the last page of information is complete click
                    <strong>Submit </strong>
                    to create a new LSR.
                  </li>
                  <li>
                    Click <strong>Cancel</strong> at any time during this
                    process to stop creating the new LSR.
                    <em>
                      
                      Note: All changes will be lost if you choose to cancel
                      your LSR submission.
                    </em>
                  </li>
                </ol>
              </section>

              <section id='update-ls-request'>
                <h3>Updating an LS request </h3>
                <p>
                  Once an LSR has been submitted, the only fields that can be
                  updated by the CM’s office are the <em>CM Attachment</em>,
                  <em>Attachment Description</em>, <em>Contact Name</em>,
                  <em>Contact Phone</em> and <em>Contact Email</em>. To update
                  an existing LSR follow these steps:
                </p>
                <ol>
                  <li>
                    Click on the <em>three dots</em> on the far right end of the
                    LSR as it appears in the LSR Table.
                  </li>
                  {/* Infographic of the three dots */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='three-dots-lsr-table'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/three-dots-lsr-table.png')",
                    }}></div>
                  <li>
                    Click <strong>Update LS Request</strong>.
                  </li>
                  <li>
                    Click <strong>Next</strong> on the top right corner to find
                    relevant information fields, and update the information by
                    clicking on the field and inputting the new information.
                  </li>
                  <li>
                    Click <strong>Next</strong> on the top right corner to find
                    additional information fields
                  </li>
                  <li>
                    Click <strong>Save</strong> to preserve your updates, or
                    <strong>Cancel </strong>
                    to revert the LSR back to the last saved information.
                  </li>
                </ol>
              </section>

              <section id='ls-request-filters'>
                <h3>Searching LS Requests</h3>
                <p>There are two ways to search for an LSR:</p>
                <ol>
                  <li>Using filters, and</li>
                  <li>Using the search bar</li>
                </ol>
                <h4>Filters</h4>
                <p>
                  Applying filters to one or more columns will narrow the LSRs
                  listed in the LSR Table to LSRs that match specific criteria.
                </p>
                <p>To search using filters:</p>
                <ol>
                  <li>
                    Click on the <strong>Filter</strong> dropdown on the top
                    left side of the page (next to <strong>Columns</strong>)
                  </li>
                  <li>Enter information into any of the fields </li>
                  <li>
                    Click <strong>APPLY FILTER(S)</strong>
                  </li>
                </ol>
              </section>

              <section id='ls-request-search-bar'>
                <h4>Search bar</h4>
                <p>
                  The search bar located above the LSR Table can be used just as
                  any other conventional search bar, by entering a word or
                  phrase to be searched and clicking the magnifying glass or
                  hitting the return key. Search for any terms (excluding the
                  symbols listed below) to return LSRs that contain those terms
                  in any location within the LSR. To search the entire LSR log
                  make sure no filters are applied.
                </p>
                <p>
                  In addition, the search bar can operate on Boolean logic,
                  using guidance as described below:
                </p>
              </section>

              <section>
                <div className={`container ${styles['sub-list-container']}`}>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        AND: <code>&amp;</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Using the <code>&amp;</code> symbol to show results that
                        are related to both terms. For example, typing
                        <em>transportation</em> &amp; <em>bridges</em> will show
                        results that contain both the words
                        <em>transportation</em> and <em>bridges</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        AND/OR: <code>|</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Using the <code>|</code> symbol to show results related
                        to both terms or each term individually. For example,
                        <em>transportation</em> <code>|</code> <em>bridges</em>
                        will show results that contain <em>transportation</em>
                        alone, <em>bridges</em> alone, or both
                        <em>transportation</em> and <em>bridges</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        NOT: <code>!</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Using the <code>!</code> symbol will exclude a word,
                        phrase, or parenthetical from the search. For example,
                        <em>!transportation</em> will show results that do not
                        contain any reference to <em>transportation</em>. Using
                        the <strong>&amp;</strong> symbol and the
                        <strong>!</strong> symbol will show results that contain
                        some terms but not others. For example,
                        <em>transportation &amp; !bridges</em> will show results
                        that contain
                        <em> transportation</em> but not <em>bridges</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        Parentheses: <code>( )</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Putting a search term between parentheses ensures that
                        it is prioritized before any commands outside of the
                        parentheticals. Use them as you would use parentheses in
                        a mathematical expression. For example,
                        <em>tolls &amp; (bridges | tunnels)</em> as a search
                        query will show LSRs that contain the terms
                        <em>bridges</em> AND/OR <em>tunnels</em> as well as the
                        word
                        <em> tolls</em>. In other words, <em>tolls</em> AND
                        <em>bridges</em> as well as <em>tolls</em> AND
                        <em> tunnels</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        Quotations: <code>" "</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Putting a search term between quotation marks ensures
                        the results contain the term exactly as entered, spacing
                        included. For example, searching for
                        <em>“for hire vehicle”</em> within quotes will only
                        return LSRs in which the exact term
                        <em>“for hire vehicle”</em> is found, in that order.
                        Searching for <em>for hire vehicle</em> without
                        quotations will return LSRs that contain the words
                        <em>for</em>, <em>hire</em>, or <em>vehicle</em>,
                        regardless of these words’ order or location within the
                        LSR.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id='export-ls-request'>
                <h3>Export to Excel</h3>
                <p>To export the LSR Table to an Excel spreadsheet:</p>
                <ol>
                  <li>
                    Click the <strong>Export</strong> dropdown on the top right
                    side of the page
                  </li>
                  <li>Choose one of the following:</li>
                  <ol type='a'>
                    <li>
                      Click <strong>Full Log</strong> to email a spreadsheet
                      containing the full log to the email associated with your
                      user account. <br />
                      Note: Due to the size of the list, this may take a few
                      minutes.
                    </li>
                    <li>
                      Click <strong>Current View</strong> to download the LSRs
                      that are the result of the currently applied search
                      filters. <br />
                      Note: This download appears as a pop-up, not by email
                    </li>
                  </ol>
                </ol>
                <p>
                  Whether you export the full log or the current view, all
                  columns will be downloaded.
                </p>
              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='staff-directory'>
                <h2>Staff Directory</h2>
                <p>
                  Visit the Staff Directory to search and view Council Members’
                  and staff members’ contact information. Use the search bar to
                  find an individual by name. Clicking on a name will show that
                  person’s contact information and affiliated committees.
                </p>
              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='tech-support'>
                <h2>Technical Support</h2>
                <ol>
                  <li>
                    To request support from the Web Development team, including
                    to submit notice of a bug in the system, click on the
                    <strong>My Account</strong> dropdown on the top right of the
                    page and click <strong>Help &amp; Feedback</strong> to
                    submit a request for technical support. Complete the
                    prompts, click <strong>Send</strong>, and someone from the
                    Web Development Team will respond to your request.
                  </li>
                  {/* Infographic of My Account button in dropdwon */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='my-account-dropdown'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/my-account-dropdown.png')",
                    }}></div>
                  {/* Infographic of Help and Feedback button in dropdwon */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='help-feedback-dropdown'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/help-feedback.png')",
                    }}></div>
                  <li>
                    Click on the <strong>My Account</strong> dropdown on the top
                    right of the page and click <strong>User Manual</strong> to
                    access this User Manual.
                  </li>
                  {/* Infographic of User manual button in dropdwon */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='user-manual-dropdown'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/user-manual.png')",
                    }}></div>
                </ol>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CMUserManual;
