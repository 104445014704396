export const fileDownloadAction = {
  GET_FILE_AND_DOWNLOAD: 'GET_FILE_AND_DOWNLOAD',
  DOWNLOAD_FILE_STATUS: 'DOWNLOAD_FILE_STATUS',
};

// Action for triggering download when click on file icon
export const getFileAndDownload = path => {
  return { type: fileDownloadAction.GET_FILE_AND_DOWNLOAD, path };
};

// Action for detecting if the file has been downloaded successfully
export const downloadFileStatus = status => {
  return { type: fileDownloadAction.DOWNLOAD_FILE_STATUS, status };
};
