import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from '../../styles/wiki/SubjectPage.module.scss';
import ErrorPage from './ErrorPage';
import * as actions from '../../actions/wikiPageAction';
import { EditPageIcon } from '../../services/SvgLibrary';
import { useParams } from 'react-router-dom';

const SubjectPage = props => {
  const [searchText, setSearchText] = useState('');
  const {
    connections,
    displaySubjectUpdate,
    loadSubjects,
    match,
    page_loading,
    pages,
    permissions,
    subject_error,
    subject_id,
    subjectPageError,
    subjects,
    title,
  } = props;

  const { id } = useParams();

  /** useEffect hook for component mount */
  useEffect(() => {
    if (id) {
      loadSubjects(id, 'nav');
      subjectPageError(false);
    }
  }, [loadSubjects, subjectPageError, id]);

  /** useEffect hook for when id is different */
  useEffect(() => {
    if (match) {
      if (id !== subject_id) {
        loadSubjects(id, 'nav');
        subjectPageError(false);
        setSearchText('');
      }
    }
  }, [match, id, loadSubjects, subjectPageError, subject_id]);

  function handleSearchChange(html) {
    setSearchText(html);
  }

  function clearSearch(e) {
    e.preventDefault();
    setSearchText('');
  }

  function updateSubject() {
    displaySubjectUpdate(subject_id);
    loadSubjects(subject_id, 'order');
  }

  const filteredSubjects = subjects.filter(subject => {
    return subject.title.toLowerCase().includes(searchText.toLowerCase());
  });

  const subjectList = filteredSubjects.map((item, idx) => (
    <div className='col-sm-6 py-2' key={idx}>
      <div className={`card h-100`} key={idx}>
        <Link
          className={`btn ${styles['topic-list-item']}`}
          to={'/CLEX/subject/' + item.id}>
          {item.title}
        </Link>
      </div>
    </div>
  ));

  const filteredPages = pages.filter(page => {
    return page.title.toLowerCase().includes(searchText.toLowerCase());
  });

  //Creates list of pages
  const pageList = !page_loading ? (
    filteredPages.map((item, idx) => {
      let descript =
        item.description
          .replace(/(&lt;)/gi, '<')
          .replace(/(&gt;)/gi, '>')
          .replace(/(<([^>]+)>)/gi, '')
          .substring(0, 250) + '...';

      return (
        <div className='col-sm-12 col-lg-6 py-2' key={idx}>
          <div className={`${styles['card-body-gray']} card h-100`} key={idx}>
            <h4 className='card-title'>{item.title}</h4>
            <p className='card-text'>{descript}</p>
            <Link
              className='btn btn-outline-light'
              to={'/CLEX/subject/' + id + '/page/' + item.id}>
              View more
            </Link>
          </div>
        </div>
      );
    })
  ) : (
    <div>Loading Pages...</div>
  );

  const filteredConnections = connections.filter(con => {
    return con.title.toLowerCase().includes(searchText.toLowerCase());
  });

  //Creates list of related topics
  const connectionList = filteredConnections.map((item, idx) => {
    return (
      <div className='col-sm-12 col-lg-6 py-2' key={idx}>
        <div className='card h-100' key={idx}>
          <Link
            className={`btn ${styles['topic-list-item']}`}
            to={'/CLEX/subject/' + item.id}>
            {item.title}
          </Link>
        </div>
      </div>
    );
  });

  return (
    <div className={styles['page']}>
      {!subject_error && (
        <>
          <div className='container mb-5'>
            <div className='row align-items-center'>
              <div className='col-12 p-0'>
                <h2 className={styles['title']}>
                  {title}
                  <span>
                    {permissions.change_subject && (
                      <Link
                        onClick={() => updateSubject()}
                        className={styles['edit-subject-page']}
                        to='/CLEX/subject/edit'>
                        <EditPageIcon />
                      </Link>
                    )}
                  </span>
                </h2>
              </div>
              <div className='col-xl-6 col-12 p-0'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className={`form-control`}
                    placeholder='Filter through each topic, tag or page...'
                    aria-label='Filter through each topic, tag or page...'
                    value={searchText}
                    onChange={e => handleSearchChange(e.target.value)}
                  />
                  <button
                    onClick={e => clearSearch(e)}
                    className='btn btn-outline-secondary'
                    type='button'
                    id='button-addon2'>
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
          {filteredSubjects.length > 0 && (
            <div className='container mb-5'>
              <h3>
                Topics
                <span className={styles['results-count']}>
                  {filteredSubjects.length}
                </span>
              </h3>
              <div className='row'>{subjectList}</div>
            </div>
          )}
          {/* Related topics section */}
          {filteredConnections.length > 0 && (
            <div className='container mb-5'>
              <h3>
                Related Topics
                <span className={styles['results-count']}>
                  {filteredConnections.length}
                </span>
              </h3>
              <div className='row'>{connectionList}</div>
            </div>
          )}
          {/* Pages section */}
          {filteredPages.length > 0 && (
            <div className='container'>
              <h3>
                Articles
                <span className={styles['results-count']}>
                  {filteredPages.length}
                </span>
              </h3>
              <div className='row'>{pageList}</div>
            </div>
          )}
        </>
      )}
      {!subject_error && !subject_id && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            align='middle'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {subject_error && <ErrorPage type='subject' />}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    connections: state.wikiSubjectReducer.connections,
    pages: state.wikiSubjectReducer.pages,
    permissions: state.userReducer.userProfile.permissions.wiki,
    subject_error: state.wikiSubjectReducer.subject_error,
    subject_id: state.wikiSubjectReducer.subject_id,
    subjects: state.wikiSubjectReducer.subjects,
    title: state.wikiSubjectReducer.title,
  };
};

export default connect(mapStateToProps, actions)(SubjectPage);
