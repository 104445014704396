import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import styles from '../../styles/user/Login.module.scss';

const items = [
  {
    src: 'img/login-carousel-about-iwomm.png',
    altText: "Cartoon image of a person marking off a 'to do' list",
    header: 'The New IWOMM',
    caption: 'IWOMM is designed to automate day-to-day manual work',
  },
  {
    src: 'img/login-carousel-complete-system.png',
    altText: 'A clutter of documents and files being scanned by a search bar',
    header: 'A Robust System',
    caption:
      'Track reports, create LS requests, view contact information, access your tasks, enhanced search capabilities, and much more',
  },
  {
    src: 'img/login-carousel-contact.png',
    altText: 'Questions marks for help and feedback',
    header: 'Support',
    caption:
      'For assistance with IWOMM, please contact us by clicking on Accounts, then Help & Feedback in the dropdown ',
  },
];

const LoginCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  function onExiting() {
    setAnimating(true);
  }

  function onExited() {
    setAnimating(false);
  }

  function next() {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  function previous() {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  function goToIndex(newIndex) {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item, index) => (
    <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
      <div className={styles['ImgCont']}>
        <img src={item.src} alt={item.altText} />
      </div>
      <div className={styles['TextCont']}>
        <CarouselCaption
          captionHeader={item.header}
          captionText={item.caption}
        />
      </div>
    </CarouselItem>
  ));

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      {/* <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      /> */}
    </Carousel>
  );
};

export default LoginCarousel;
