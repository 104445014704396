import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/wiki/WikiSearch.module.scss';
import { connect } from 'react-redux';
import { CloseButtonCircleIcon, SearchIcon } from '../../services/SvgLibrary';

const WikiSearch = props => {
  const { loading, placement, userProfile } = props;
  const node = useRef();
  let filterCache = localStorage.getItem(
    `wiki-search-view-${userProfile.username}`
  );
  if (!filterCache) {
    localStorage.setItem(
      `wiki-search-view-${userProfile.username}`,
      'subjectValue,pageTitleValue,pageDescriptionValue,pageContentValue,committeeValue,tagValue'
    );
    filterCache =
      'subjectValue,pageTitleValue,pageDescriptionValue,pageContentValue,committeeValue,tagValue';
  }
  const [checkboxes, setCheckboxes] = useState({
    subjectValue: filterCache.indexOf('subjectValue') !== -1 ? true : false,
    pageTitleValue: filterCache.indexOf('pageTitleValue') !== -1 ? true : false,
    pageDescriptionValue:
      filterCache.indexOf('pageDescriptionValue') !== -1 ? true : false,
    pageContentValue:
      filterCache.indexOf('pageContentValue') !== -1 ? true : false,
    committeeValue: filterCache.indexOf('committeeValue') !== -1 ? true : false,
    tagValue: filterCache.indexOf('tagValue') !== -1 ? true : false,
  });
  const [toggleState, setToggleState] = useState({ open: false });
  const [search, setSearch] = useState('');

  const subjectHandler = value => {
    setCheckboxes({ ...checkboxes, subjectValue: value });
  };
  const pageTitleHandler = value => {
    setCheckboxes({ ...checkboxes, pageTitleValue: value });
  };
  const pageDescriptionHandler = value => {
    setCheckboxes({ ...checkboxes, pageDescriptionValue: value });
  };
  const pageContentHandler = value => {
    setCheckboxes({ ...checkboxes, pageContentValue: value });
  };
  const committeeHandler = value => {
    setCheckboxes({ ...checkboxes, committeeValue: value });
  };
  const tagHandler = value => {
    setCheckboxes({ ...checkboxes, tagValue: value });
  };

  const saveWikiFilter = () => {
    let filters = [];
    for (let key in checkboxes) {
      if (checkboxes[key]) filters.push(key);
    }
    let filterString = filters.join(',');
    localStorage.setItem(
      `wiki-search-view-${userProfile.username}`,
      filterString
    );
  };

  useEffect(() => {
    const handleClick = e => {
      if (!(node.current && node.current.contains(e.target)) && toggleState) {
        setToggleState(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [toggleState]);

  const handleSubmit = e => {
    e.preventDefault();
    const params = {
      keywords: '',
      filters: {
        'page': [],
        'subject': [],
        'tag': [],
        'committee': false,
      },
    };
    saveWikiFilter();

    if (search) {
      for (let key in checkboxes) {
        if (key.includes('subject') && checkboxes[key]) {
          params['filters']['subject'].push('title');
        } else if (key.includes('tag') && checkboxes[key]) {
          params['filters']['tag'].push('name');
        } else if (key.includes('committee') && checkboxes[key]) {
          params['filters']['committee'] = true;
        } else if (key.includes('pageTitle') && checkboxes[key]) {
          params['filters']['page'].push('title');
        } else if (key.includes('pageDescription') && checkboxes[key]) {
          params['filters']['page'].push('description');
        } else if (key.includes('pageContent') && checkboxes[key]) {
          params['filters']['page'].push('content');
        }
      }
      params['keywords'] = search;
    }
    window.location.href = `/CLEX/search/${encodeURIComponent(
      search
    )}/${encodeURIComponent(JSON.stringify(params['filters']))}`;
  };

  const onEnter = e => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (e.key === 'Enter' && search) {
      e.stopPropagation();
      handleSubmit(e);
    }
  };
  const formClear = () => {
    setCheckboxes({
      subjectValue: true,
      pageTitleValue: true,
      pageDescriptionValue: true,
      pageContentValue: true,
      committeeValue: true,
      tagValue: true,
    });
    localStorage.setItem(
      `wiki-search-view-${userProfile.username}`,
      'subjectValue,pageTitleValue,pageDescriptionValue,pageContentValue,committeeValue,tagValue'
    );
    setSearch('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div ref={node}>
        <div className={`input-group mb-3 ${styles['search-bar-container']}`}>
          <button
            type='button'
            disabled={!search}
            onClick={handleSubmit}
            className={`btn ${styles['search-by-button']}`}>
            {placement === 'side' ? (
              loading ? (
                <div className='spinner-border spinner-border-sm' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              ) : (
                <SearchIcon width='18' height='18' />
              )
            ) : (
              'Search by'
            )}
          </button>
          <button
            type='button'
            className={`btn dropdown-toggle dropdown-toggle-split ${styles['search-by-dropdown']}`}
            data-bs-toggle='dropdown'
            aria-expanded='false'>
            <span className='visually-hidden'>Toggle Dropdown</span>
          </button>
          <ul className='dropdown-menu p-2'>
            <li>
              <div
                className='form-check'
                onClick={subjectHandler.bind(this, !checkboxes.subjectValue)}>
                <input
                  className='form-check-input'
                  name='wiki_filter'
                  type='checkbox'
                  readOnly
                  checked={checkboxes.subjectValue}
                  id='subject-search'
                />
                <label className='form-check-label' htmlFor='subject-search'>
                  Topics
                </label>
              </div>
            </li>
            <li>
              <div
                className='form-check'
                onClick={pageTitleHandler.bind(
                  this,
                  !checkboxes.pageTitleValue
                )}>
                <input
                  className='form-check-input'
                  name='wiki_filter'
                  type='checkbox'
                  readOnly
                  checked={checkboxes.pageTitleValue}
                  id='article-title-search'
                />
                <label
                  className='form-check-label'
                  htmlFor='article-title-search'>
                  Article Titles
                </label>
              </div>
            </li>
            <li>
              <div
                className='form-check'
                onClick={pageDescriptionHandler.bind(
                  this,
                  !checkboxes.pageDescriptionValue
                )}>
                <input
                  className='form-check-input'
                  name='wiki_filter'
                  type='checkbox'
                  readOnly
                  checked={checkboxes.pageDescriptionValue}
                  id='article-description-search'
                />
                <label
                  className='form-check-label'
                  htmlFor='article-description-search'>
                  Article Descriptions
                </label>
              </div>
            </li>
            <li>
              <div
                className='form-check'
                onClick={pageContentHandler.bind(
                  this,
                  !checkboxes.pageContentValue
                )}>
                <input
                  className='form-check-input'
                  name='wiki_filter'
                  type='checkbox'
                  readOnly
                  checked={checkboxes.pageContentValue}
                  id='article-content-search'
                />
                <label
                  className='form-check-label'
                  htmlFor='article-content-search'>
                  Article Content
                </label>
              </div>
            </li>
            <li>
              <div
                className='form-check'
                onClick={committeeHandler.bind(
                  this,
                  !checkboxes.committeeValue
                )}>
                <input
                  className='form-check-input'
                  name='wiki_filter'
                  type='checkbox'
                  readOnly
                  checked={checkboxes.committeeValue}
                  id='committee-search'
                />
                <label className='form-check-label' htmlFor='committee-search'>
                  Committees
                </label>
              </div>
            </li>
            <li>
              <div
                className='form-check'
                onClick={tagHandler.bind(this, !checkboxes.tagValue)}>
                <input
                  className='form-check-input'
                  name='wiki_filter'
                  type='checkbox'
                  readOnly
                  checked={checkboxes.tagValue}
                  id='tag-search'
                />
                <label className='form-check-label' htmlFor='tag-search'>
                  Tags
                </label>
              </div>
            </li>
          </ul>
          <input
            type='text'
            className={`form-control ${
              placement === 'side'
                ? styles['clex-search-bar-wrapper-side']
                : styles['clex-search-bar-wrapper-top']
            }`}
            aria-label='Text input with segmented dropdown button'
            value={search}
            id='keywords'
            onChange={e => setSearch(e.target.value)}
            onKeyUp={onEnter}
            placeholder='Search CLEX'
            required
          />
          {search && (
            <span className={styles['clear-search']}>
              <CloseButtonCircleIcon
                classNameProp={styles['clex-search-bar-icons']}
                onClickFunction={formClear}
              />
            </span>
          )}
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.wikiSearchResultsReducer.loading,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(WikiSearch);
