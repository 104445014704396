import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/SubjectPage.module.scss';
import { Link, useParams } from 'react-router-dom';
import { Input, InputGroup } from 'reactstrap';
import * as actions from '../../actions/wikiPageAction';

const TagPage = props => {
  const [searchText, setSearchText] = useState('');
  const {
    loadTag,
    loadTaggedPages,
    match,
    name,
    pages,
    permissions,
    selectTag,
    tag_id,
  } = props;

  const { id } = useParams();

  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    if (id) {
      loadTag(id);
      loadTaggedPages(id);
    }
  }, [id, loadTag, loadTaggedPages]);

  function handleSearchChange(html) {
    setSearchText(html);
  }

  function clearSearch(e) {
    e.preventDefault();
    setSearchText('');
  }

  function updateTag() {
    selectTag(tag_id);
  }

  let pageList = <></>;
  if (pages) {
    let filteredPages = pages.filter(page =>
      page.title.toLowerCase().includes(searchText.toLowerCase())
    );
    pageList = filteredPages.map((item, idx) => (
      <li key={idx}>
        <Link to={'/CLEX/subject/' + item.subject[0] + '/page/' + item.id}>
          {item.title}
        </Link>
      </li>
    ));
  }

  return (
    <div className={styles['page']}>
      <div className={styles['div-row']}>
        <h2 className={styles['title']}>#{name}</h2>
        {permissions.change_subject && (
          <Link
            className='btn btn-primary float-right'
            onClick={updateTag()}
            to='/CLEX/tag/update'>
            Update Tag
          </Link>
        )}
      </div>

      <InputGroup>
        <Input
          type='text'
          value={searchText}
          onChange={e => handleSearchChange(e.target.value)}
        />
        <button className='btn btn-primary' onClick={e => clearSearch(e)}>
          Clear Search
        </button>
      </InputGroup>

      <br />
      {pages && (
        <>
          <h4> Pages </h4>
          <ul>{pageList}</ul>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    name: state.wikiTagReducer.name,
    pages: state.wikiTagReducer.pages,
    permissions: state.userReducer.userProfile.permissions.wiki,
    tag_id: state.wikiTagReducer.tag_id,
  };
};

export default connect(mapStateToProps, actions)(TagPage);
