import React, { useEffect, useRef } from 'react';
import styles from '../../styles/ls-request/CheckboxMenu.module.scss';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import {
  nonLegDivisionRoles,
  rolesUnderCouncilMembers,
} from '../../services/constants';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

const CheckboxMenu = props => {
  const { filterFlag } = props;
  const outsideClickRef = useRef(null);
  // useEffect hook for component did mount
  useEffect(() => {
    function handleOutsideClick(e) {
      let columnMenuButton = document.getElementById('columnMenuToggle');
      if (
        outsideClickRef.current &&
        !outsideClickRef.current.contains(e.target)
      ) {
        if (columnMenuButton && !columnMenuButton.contains(e.target)) {
          props.toggleColumnMenu();
        }
      }
    }

    document.addEventListener('mouseup', handleOutsideClick, false);

    // component did unmount
    return () => {
      document.removeEventListener('mouseup', handleOutsideClick, false);
    };
  }, []);

  let alphaLsFields = Array.from(props.columns.slice(1));
  alphaLsFields.sort((a, b) => {
    let lsTitle1 = a.title.toLowerCase();
    let lsTitle2 = b.title.toLowerCase();

    if (lsTitle1 < lsTitle2) return -1;
    if (lsTitle1 > lsTitle2) return 1;
    return 0;
  });

  const dropDownColumnMenu = alphaLsFields
    .filter(
      alphaField =>
        !(
          nonLegDivisionRoles.includes(props.userProfile.role) &&
          alphaField.field === 'confidential_comments'
        ) && alphaField.viewability !== 'none'
    )
    .map((column, idx) => {
      return (
        <li key={idx} className={styles['column-checkbox-container']}>
          <input
            className={styles['column-checkbox']}
            id={`cbx-${idx}`}
            type='checkbox'
            checked={column.show}
            readOnly
          />
          <label
            className={styles['column-checkbox-label']}
            htmlFor={`cbx-${idx}`}
            onClick={() => {
              props.toggleColumn(column.title);
            }}></label>
          <label
            id={`column-tooltip-${idx}`}
            className={`${styles['column-checkbox-text-label']} ${
              column.description && styles['column-tooltip']
            }`}
            htmlFor={`cbx-${idx}`}
            onClick={() => {
              props.toggleColumn(column.title);
            }}>
            {column.title}
          </label>
          {column.description && (
            <UncontrolledPopover
              innerClassName={styles['popover-container']}
              className={styles['popover-outer-container']}
              placement='right'
              target={`column-tooltip-${idx}`}
              fade={false}
              trigger='hover'>
              <PopoverBody>{parse(column.description)}</PopoverBody>
            </UncontrolledPopover>
          )}
        </li>
      );
    });

  const checkAllOption = (
    <div className={`${styles['column-checkbox-container']} col-12`}>
      <input
        className={styles['column-checkbox']}
        id='cbx-all'
        type='checkbox'
        checked={props.checkAllColumns}
        readOnly
      />
      <label
        className={styles['column-checkbox-label']}
        htmlFor='cbx-all'
        onClick={props.toggleAllColumns}></label>
      <label
        className={styles['column-checkbox-text-label']}
        htmlFor='cbx-all'
        onClick={props.toggleAllColumns}>
        Check All
      </label>
    </div>
  );

  const presetsOptions = [
    props.userProfile.username,
    `${props.userProfile.username}-preset-1`,
    `${props.userProfile.username}-preset-2`,
    `${props.userProfile.username}-preset-3`,
  ].map((value, idx) => {
    return (
      <div
        key={`${idx}_${value}`}
        className={`${styles['column-checkbox-container']} col-6 col-sm-3`}>
        <input
          className={styles['column-checkbox']}
          id={
            value === props.userProfile.username
              ? 'no-preset'
              : `column-${value}`
          }
          type='radio'
          name='presets'
          value={`column-${value}`}
          checked={props.columnPreset === `column-${value}`}
          readOnly
        />
        <label
          className={styles['column-checkbox-label']}
          htmlFor={
            value === props.userProfile.username
              ? 'no-preset'
              : `column-${value}`
          }
          onClick={() => props.changeColumnPreset(`column-${value}`)}
        />
        <label
          className={styles['column-checkbox-text-label']}
          htmlFor={
            value === props.userProfile.username
              ? 'no-preset'
              : `column-${value}`
          }
          onClick={() => props.changeColumnPreset(`column-${value}`)}>
          {value === `${props.userProfile.username}-preset-3` &&
          !rolesUnderCouncilMembers.includes(props.userProfile.role)
            ? 'DUP SEARCH'
            : value === props.userProfile.username
            ? 'DEFAULT'
            : value
                .replace(`${props.userProfile.username}-`, '')
                .split('-')
                .join(' ')
                .toUpperCase()}
        </label>
      </div>
    );
  });

  return (
    <div
      ref={outsideClickRef}
      className={`${styles['column-selector-container']} ${
        !filterFlag && styles['status']
      } row`}>
      {presetsOptions}
      <hr className='col' style={{ margin: '.25rem 0 .75rem 0' }} />
      <div className={styles['column-selector-wrapper']}>
        <ul>
          {checkAllOption}
          {dropDownColumnMenu}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(CheckboxMenu);
