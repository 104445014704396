import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { url } from '../config';
import {
  myTasksActionType,
  saveMyLs,
  isLoadingLs,
  saveMyActiveLs,
  storePageReview,
  storeMyPageReviews,
  storeAllPageReviews,
  isLoadingMyPageReviews,
  isLoadingAllPageReviews,
  isLoadingPageReview,
  pageReviewError,
} from '../actions/myTasksAction';
import {
  checkLsIsLoading,
  storeLsRequests,
  storePaginationNext,
  storePaginationPrev,
} from '../actions/lsRequestAction';

// HTTP.GET for all my ls requests
// Based on submitted_by -> council member
// Based on staff -> central staff
function getMyTasksRequest(activeOrAll) {
  return axios.get(url + '/api/users/tasks/', {
    params: { table: activeOrAll },
  });
}

function getPageReview(page_review_id) {
  return axios.get(`${url}/api/wiki/pageReviews/${page_review_id}/`);
}

function getMyPageReviews() {
  return axios.get(`${url}/api/wiki/pageReviews/`);
}

function getAllPageReviews() {
  return axios.get(`${url}/api/wiki/allPageReviews/`);
}
function getFilteredLs(days) {
  return axios.get(`${url}/api/lsRequest/lsRequest/?day=${days}&sortedBy=-`);
}
function* loadFilteredLs(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingLs(true));
      const response = yield call(getFilteredLs, action.days);
      yield put(saveMyLs(response.data.results));
      yield put(isLoadingLs(false));
    } catch (e) {
      console.log(e);
      yield put(isLoadingLs(false));
    }
  }
}
function* loadAllMyLs(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingLs(true));
      const response = yield call(getMyTasksRequest, 'all');
      const { data } = response;
      let { results, next, previous } = data;
      yield put(storeLsRequests(results));
      yield put(saveMyLs(results));
      // yield put(checkLsIsLoading(false));
      yield put(isLoadingLs(false));
      yield put(storePaginationNext(next));
      yield put(storePaginationPrev(previous));
    } catch (e) {
      console.log(e);
      // yield put(isLoadingLs(false));
      yield put(checkLsIsLoading(false));
    }
  }
}

function* loadAllMyActiveLs(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingLs(true));
      const response = yield call(getMyTasksRequest, 'active');
      const { data } = response;
      let { results, next, previous } = data;
      yield put(storeLsRequests(results));
      yield put(saveMyActiveLs(results));
      yield put(isLoadingLs(false));
      yield put(storePaginationNext(next));
      yield put(storePaginationPrev(previous));
    } catch (e) {
      console.log(e);
      yield put(isLoadingLs(false));
    }
  }
}

function* getPageReviewResponse(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingPageReview(true));
      const response = yield call(getPageReview, action.page_review_id);
      yield put(storePageReview(response.data));
      yield put(isLoadingPageReview(false));
    } catch (e) {
      console.log(e);
      yield put(isLoadingPageReview(false));
      let message = 'Failed to find review';
      if (e.response.statusText === 'Locked') {
        message = e.response.data;
      }
      yield put(pageReviewError(message));
    }
  }
}

function* getMyPageReviewsResponse(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingMyPageReviews(true));
      const response = yield call(getMyPageReviews, action.pageReviews);
      yield put(storeMyPageReviews(response.data.results));
      yield put(isLoadingMyPageReviews(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getAllPageReviewsResponse(action) {
  if (action !== undefined) {
    try {
      yield put(isLoadingAllPageReviews(true));
      const response = yield call(getAllPageReviews, action.pageReviews);
      yield put(storeAllPageReviews(response.data.results));
      yield put(isLoadingAllPageReviews(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* myTasksAPI() {
  yield takeEvery(myTasksActionType.LS_FILTER_BY_DAYS, loadFilteredLs);
  yield takeEvery(myTasksActionType.GET_ALL_MY_LS, loadAllMyLs);
  yield takeEvery(myTasksActionType.GET_MY_ACTIVE_LS, loadAllMyActiveLs);
  yield takeEvery(myTasksActionType.LOAD_PAGE_REVIEW, getPageReviewResponse);
  yield takeEvery(
    myTasksActionType.LOAD_MY_PAGE_REVIEWS,
    getMyPageReviewsResponse
  );
  yield takeEvery(
    myTasksActionType.LOAD_ALL_PAGE_REVIEWS,
    getAllPageReviewsResponse
  );
}

export default myTasksAPI;
