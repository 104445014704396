import React, { useState, useEffect, useRef, Fragment } from 'react';
import { url } from '../../../config';
import axios from 'axios';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { getNotifications } from '../../../actions/userAction';
import { notificationsInfo } from '../../../services/notifications';
import styles from '../../../styles/NavBar.module.scss';
import { getHumanTime } from '../../../utils/helper';
import {
  filterNotificationStates,
  filterNotificationTypesByCategory,
} from '../../../services/constants';

const Notifications = props => {
  const { getNotifications, notifications, userProfile } = props;
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [newNotificationsSeen, setNewNotificationsSeen] = useState(false);
  const [filteredNotifications, setFilteredNotifications] = useState(
    filterNotificationStates
  );
  const [notificationTypesToFilter, setNotificationTypesToFilter] = useState(
    []
  );
  const dropdownRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(null);
  const toggleToolTip = notifId =>
    setTooltipOpen(prevId => (prevId !== notifId ? notifId : null));

const handleOutsideClick = e => {
  if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
    setNotificationsOpen(false);
    setNewNotificationsSeen(true);
  }
};
  useEffect(() => {
    if (notificationsOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      // Clean up the event listener when the component unmounts or the dropdown closes
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [notificationsOpen]);
  useEffect(() => {
    let localStorageNotifications = JSON.parse(
      localStorage.getItem('visibleNotifications')
    );

    if (localStorageNotifications) {
      let newLocalNotifState = {};
      let newNotifTypesFilterList = [];
      Object.keys(filterNotificationStates).map(notif => {
        if (
          localStorageNotifications[notif] &&
          localStorageNotifications[notif].label ===
            filterNotificationStates[notif].label &&
          localStorageNotifications[notif].show !==
            filterNotificationStates[notif].show
        ) {
          newLocalNotifState[notif] = localStorageNotifications[notif];
        } else {
          newLocalNotifState[notif] = filterNotificationStates[notif];
        }
        if (newLocalNotifState[notif].show) {
          newNotifTypesFilterList.push(
            ...filterNotificationTypesByCategory[notif]
          );
        }
      });
      localStorageNotifications = newLocalNotifState;

      setNotificationTypesToFilter(newNotifTypesFilterList);
      localStorage.setItem(
        'visibleNotifications',
        JSON.stringify(localStorageNotifications)
      );
    }

    const visibleNotifications =
      JSON.parse(localStorage.getItem('visibleNotifications')) ||
      filterNotificationStates;
    setFilteredNotifications(visibleNotifications);
  }, []);

  useEffect(() => {
    // When filteredNotifications changes, serialize and store it
    localStorage.setItem(
      'visibleNotifications',
      JSON.stringify(filteredNotifications)
    );
  }, [filteredNotifications]);  
  
  useEffect(() => {
    getNotifications();
    const interval = setInterval(getNotifications, 30000); // Every 30 seconds, the the front end will send a new request
    return () => {
      clearInterval(interval);
    };
  }, [getNotifications]);

  useEffect(() => {
    const notificationsSeen = async () => {
      return await axios
        .patch(url + '/api/notification/notifications/0/')
        .then(await getNotifications())
        .catch(e => {
          console.log(e);
        });
    }
    if (!notificationsOpen && newNotificationsSeen) {
      notificationsSeen();
      setNewNotificationsSeen(false);
    }
  }, [notificationsOpen, getNotifications, newNotificationsSeen]);

  const handleCheckboxChange = notifId => {
    let newFilterTypeArr = []
    const updatedFilters = {
      ...filteredNotifications,
      [notifId]: {
        ...filteredNotifications[notifId],
        show: !filteredNotifications[notifId].show,
      },
    };
    Object.keys(updatedFilters).map(notif => {
      if (updatedFilters[notif].show) {
        newFilterTypeArr.push(...filterNotificationTypesByCategory[notif]);
      }
    })

    setNotificationTypesToFilter(newFilterTypeArr);
    setFilteredNotifications(updatedFilters);
    getNotifications();
  };

  let newNotifications = notifications.filter(
    notification => !notification.seen && !notification.read
  );
  let newCounter = newNotifications.length;
  newNotifications = newNotifications.map((notification, idx) => {
    let icon = notificationsInfo[notification.type].icon;
    let app = notificationsInfo[notification.type].app(notification);
    let redirectUrl = notificationsInfo[notification.type].redirectUrl(
      userProfile.role,
      notification
    );
    let notificationText =
      notificationsInfo[notification.type].notificationText(notification);
    return (
      <li className='p-2' key={`new-${idx}`}>
        <div
          className={`list-group-item list-group-item-action py-3 ${styles['list-item-notification']} ${styles['new']}`}
          onClick={() => {
            axios
              .put(`${url}/api/notification/notifications/${notification.id}/`)
              .then(getNotifications())
              .finally(() => {
                window.location.assign(redirectUrl);
              })
              .catch(e => {
                console.log(e);
              });
          }}>
          <div className={styles['list-item-type']}>{icon}</div>
          <div className='d-flex w-100 justify-content-between'>
            <h5 className='mb-1 me-4'>{app}</h5>
            <small className='text-muted text-nowrap'>
              {getHumanTime(notification.timestamp)} ago
            </small>
          </div>
          <p className='mb-1'>{notificationText}</p>
          {!notification.read && <div className={styles['read-indicator']} />}
        </div>
      </li>
    );
  });
  let earlierNotifications = notifications.filter(
    notification => notification.seen
  );
  earlierNotifications = earlierNotifications.filter(notification =>
    !notificationTypesToFilter.length
      ? notification
      : notificationTypesToFilter.includes(notification.type)
  );
  earlierNotifications = earlierNotifications.slice(0,50);
  earlierNotifications = earlierNotifications.map((notification, idx) => {
    let icon = notificationsInfo[notification.type].icon;
    let app = notificationsInfo[notification.type].app(notification);
    let redirectUrl = notificationsInfo[notification.type].redirectUrl(
      userProfile.role,
      notification
    );
    let notificationText =
      notificationsInfo[notification.type].notificationText(notification);
    return (
      <li className='p-2' key={`earlier-${idx}`}>
        <div
          className={`list-group-item list-group-item-action py-3 ${styles['list-item-notification']} ${styles['earlier']}`}
          onClick={() => {
             axios
              .put(`${url}/api/notification/notifications/${notification.id}/`)
              .then(getNotifications())
              .finally(() => {
                window.location.assign(redirectUrl);
              })
              .catch(e => {
                console.log(e);
              });
          }}>
          <div className={styles['list-item-type']}>{icon}</div>
          <div className='d-flex w-100 justify-content-between'>
            <h5 className='mb-1 me-4'>{app}</h5>
            <small className='text-muted text-nowrap'>
              {getHumanTime(notification.timestamp)} ago
            </small>
          </div>
          <p className='mb-1'>{notificationText}</p>
          {!notification.read && <div className={styles['read-indicator']} />}
        </div>
      </li>
    );
  });

  return (
    <div className='dropdown' ref={dropdownRef}>
      <button
        className={`btn ${styles['account-button-dropdown']}`}
        type='button'
        id='notification-dropdown'
        data-bs-toggle='dropdown'
        aria-expanded='false'
        onClick={() => {
          setNotificationsOpen(!notificationsOpen);
          if (newNotifications.length && notificationsOpen) {
            setNewNotificationsSeen(true);
          } else if (!notificationsOpen) {
            getNotifications();
          }
        }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          fill='currentColor'
          className='bi bi-bell'
          viewBox='0 0 16 16'>
          <path d='M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z' />
        </svg>
        {newCounter > 0 && (
          <span className={styles['notification-count']}>{newCounter}</span>
        )}
      </button>
      <div
        className={`dropdown-menu dropdown-menu-end ${styles['account-dropdown-menu']} ${styles['notification']}`}
        aria-labelledby='notification-dropdown'>
        <h3>Notifications</h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center', 
            gap: '10px', 
          }}>
          <ul>
            {Object.keys(filteredNotifications).map(notifId => (
              <li
                key={notifId}
                id={`${notifId}`}
                className='filter-button'
                style={{
                  display: 'inline-block',
                  padding: '0.3125rem 0.625rem',
                  margin: '0.3125rem 0.1875rem',
                  border: '1px solid #ccc',
                  borderRadius: '0.5rem',
                  cursor: 'pointer',
                  userSelect: 'none',
                  backgroundColor: filteredNotifications[notifId].show
                    ? '#4A5E96'
                    : 'transparent',
                  color: filteredNotifications[notifId].show
                    ? 'white'
                    : 'initial',
                  borderColor: filteredNotifications[notifId].show
                    ? 'white'
                    : 'initial',
                }}
                onClick={e => {
                  e.stopPropagation(); // Prevent modal from closing when clicking a filter
                  handleCheckboxChange(notifId);
                }}>
                {filteredNotifications[notifId].label}{' '}
                <input
                  type='checkbox'
                  checked={filteredNotifications[notifId].show}
                  readOnly
                  style={{ display: 'none' }}
                />{' '}
                <Tooltip
                  key={`${notifId}1`}
                  placement='top'
                  isOpen={tooltipOpen === notifId}
                  target={`${notifId}`}
                  fade={false}
                  toggle={() => {
                    toggleToolTip(notifId);
                  }}>
                  {filteredNotifications[notifId].fullName}
                </Tooltip>
              </li>
            ))}
          </ul>
        </div>
        <div
          className='list-group overflow-auto'
          style={{ maxHeight: '20rem' }}>
          {newNotifications.length > 0 && (
            <>
              <h4>New</h4>
              <ul>{newNotifications}</ul>
            </>
          )}
          {earlierNotifications.length > 0 && (
            <>
              <h4>Earlier</h4>
              <ul>{earlierNotifications}</ul>
            </>
          )}
          {earlierNotifications.length === 0 &&
            newNotifications.length === 0 && (
              <div className={styles['no-unread-notifcations-container']}>
                <img
                  src='../img/notifications-all-seen.png'
                  alt='No unread notifications'></img>
                <p>No unread notifications</p>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    notifications: state.userReducer.notifications,
  };
};

const mapDispatchToProps = {
  getNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
