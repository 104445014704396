import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { url } from '../config';
import * as wikiActions from '../actions/wikiPageAction';
// import { addToastInfo } from '../utils/helper';
//import { isRegExp } from "util";

function getWikiRootSubjects() {
  return axios.get(`${url}/api/wiki/subjects/`);
}

function getAllWikiSubjects(tree) {
  return axios.get(
    `${url}/api/wiki/allSubjects/${tree ? '?view_full_tree=true' : ''}`
  );
}

function getWikiChildrenSubjects(subject) {
  return axios.get(`${url}/api/wiki/subjects/${subject}/`);
}

function getWikiChildrenPages(parentSubject) {
  return axios.get(`${url}/api/wiki/pages/?subject_id=${parentSubject}`);
}

function getWikiPage(page_id, diff = '') {
  return axios.get(
    `${url}/api/wiki/pages/${page_id}/${diff ? '?diff=true' : ''}`
  );
}

function getAllWikiPages() {
  return axios.get(`${url}/api/wiki/allPages/`);
}

function getWikiComments(page) {
  return axios.get(`${url}/api/wiki/comments/?page_id=${page}`);
}

function getWikiTag(id) {
  return axios.get(`${url}/api/wiki/tags/${id}/`);
}

function getAllWikiTags() {
  return axios.get(`${url}/api/wiki/tags/`);
}

function getTaggedPages(tag) {
  return axios.get(`${url}/api/wiki/tagged/${tag}/`);
}

function getPageHistory(page) {
  return axios.get(`${url}/api/wiki/page/history/${page}/`);
}

function getRecentPages() {
  return axios.get(`${url}/api/wiki/recentPages/`);
}

function getPageReview(page_review_id) {
  return axios.get(`${url}/api/wiki/pageReviews/${page_review_id}/`);
}

function getWikiSearchResults(params) {
  return axios.get(url + '/api/wiki/search/', {
    params: params,
  });
}

function createSubjectPage(subject, title, order, user, connections) {
  let new_subject = {
    parent: subject,
    title: title,
    order: order,
    user: user,
    tags: [],
    connections: connections,
  };

  return axios.post(url + '/api/wiki/subjects/', new_subject);
}

function createWikiPage(
  author,
  subject,
  title,
  memo_regarding,
  tags,
  committees,
  description,
  content,
  review
) {
  return axios.post(`${url}/api/wiki/pages/`, {
    author: author,
    subject_id: subject,
    title: title,
    memo_regarding,
    tags: tags,
    committees: committees,
    description: description,
    content: content,
    review: review,
  });
  // return addToastInfo(
  //   'CLEX Article has been created',
  //   `CLEX Article has been created for ${title}`,
  //   '/img/Navbar/clex.png',
  //   'CLEX icon'
  // );
}

function createPageReview(
  page,
  author,
  subject,
  title,
  memo_regarding,
  committees,
  tags,
  description,
  content
) {
  let pageReview = {
    page: page,
    author: author,
    subject_id: subject,
    title: title,
    memo_regarding: memo_regarding,
    tags: tags,
    committees: committees,
    description: description,
    content: content,
  };

  return axios.post(`${url}/api/wiki/pageReviews/`, pageReview);
}

function createWikiComment(page_id, comment) {
  return axios.post(`${url}/api/wiki/comments/`, {
    page_id: page_id,
    content: comment,
  });
}

function createWikiTag(title) {
  return axios.post(`${url}/api/wiki/tags/`, {
    tag_name: title,
  });
}

function updateSubject(subject) {
  return axios.put(`${url}/api/wiki/subjects/${subject.id}/`, subject);
}

function updatePage(page) {
  return axios.put(`${url}/api/wiki/pages/${page.id}/`, page);
}

function revertPage(page, revision) {
  return axios.put(`${url}/api/wiki/page/history/${page}/`, {
    version_history_id: revision.pk,
  });
}

function updatePageReview(pageReview) {
  return axios.put(`${url}/api/wiki/pageReviews/${pageReview.id}/`, pageReview);
}

function updateTag(tag) {
  return axios.put(`${url}/api/wiki/tags/${tag.id}/`, tag);
}

function deleteSubject(subject) {
  return axios.delete(`${url}/api/wiki/subjects/${subject}/`);
}

function deletePage(page) {
  return axios.delete(`${url}/api/wiki/pages/${page}/`);
}

function deletePageReview(pageReview, redirectPath = null) {
  if (redirectPath) {
    return axios
      .delete(`${url}/api/wiki/pageReviews/${pageReview}/`)
      .finally(() => {
        window.location.href = redirectPath;
      });
  } else {
    return axios.delete(`${url}/api/wiki/pageReviews/${pageReview}/`);
  }
}

function deleteTag(tag) {
  return axios.delete(`${url}/api/wiki/tags/${tag}/`);
}

function* getSubjectRootResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getWikiRootSubjects);
      const { data } = response;
      if (action.location === 'nav') {
        yield put(wikiActions.storeRootSubjects(data));
      } else if (action.location === 'order') {
        yield put(wikiActions.storeOrderRootSubjects(data));
      }
    } catch (e) {
      console.log(e);
    }
  }
}

function* getAllSubjectResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getAllWikiSubjects, action.tree);
      const { data } = response;
      let path = window.location.pathname;
      yield put(wikiActions.storeAllSubjects(data));

      if (path.includes('subject') && action.tree) {
        yield put(wikiActions.subjectFormLoad(false));
      } else if (
        (path.includes('page') || path.includes('myCLEXReviews')) &&
        action.tree
      ) {
        yield put(wikiActions.pageFormLoad(false));
      }
    } catch (e) {
      console.log(e);
    }
  }
}

function* getSubjectResponse(action) {
  if (action !== undefined) {
    try {
      //yield put(checkingSubjects(true));
      const response = yield call(getWikiChildrenSubjects, action.subject_id);
      const { data } = response;
      //According to the location action store data in specific reducer
      switch (action.location) {
        case 'nav':
          yield put(wikiActions.storeSubject(data));
          yield put(wikiActions.storeParentSubjects(data.parent_data));
          break;
        case 'breadcrumb':
          yield put(wikiActions.loadSidebarSubjects(data.children_data));
          break;
        case 'order':
          yield put(wikiActions.storeCurrentSubject(data));
          yield put(wikiActions.storeOrderChildSubjects(data.children_data));
          yield put(wikiActions.reorderChildSubjects(data.children_data));
          break;
        default:
          break;
      }
    } catch (e) {
      if (action.location === 'nav') {
        yield put(wikiActions.subjectPageError(true));
      }
      console.log(e);
    }
  }
}

function* getPageChildrenResponse(action) {
  if (action !== undefined) {
    try {
      //yield put(checkingPages(true));
      const response = yield call(getWikiChildrenPages, action.subject_id);
      const { data } = response;
      switch (action.location) {
        case 'nav':
          yield put(wikiActions.storePages(data.results));
          break;
        case 'update':
          yield put(wikiActions.storeSelectPages(data.results));
          break;
        default:
          break;
      }

      //yield put(checkingPages(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getPageResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getWikiPage, action.page_id, action.diff);
      const { data } = response;
      if (action.subject) {
        if (data.subject.includes(parseInt(action.subject))) {
          yield put(wikiActions.loadSubjects(action.subject, 'nav'));
          yield put(wikiActions.pageInSubject(true));
        } else {
          if (action.diff === 'diffR') {
            if (data.subject.length === 0) {
              window.location.href = `/CLEX/page/${data.id}`;
            } else {
              window.location.href = `/CLEX/subject/${data.subject[0]}/page/${data.id}`;
            }
          }
          yield put(wikiActions.pageInSubject(false));
        }
      }
      if (data.diff) {
        yield put(wikiActions.storePageDiff(data));
      } else {
        yield put(wikiActions.storePage(data));
      }
    } catch (e) {
      console.log(e);
      yield put(wikiActions.wikiPageError(true));
    }
  }
}

function* getAllPagesResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getAllWikiPages);
      const { data } = response;
      yield put(wikiActions.storeAllPages(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getCommentsResponse(action) {
  if (action !== undefined) {
    try {
      // On first load action.page_id is an integer, but after posting a comment it becomes a JSON object w/ all of the Page's metadata
      // This needs to get figured out instead of using this "bandaid" if statement below.
      let pageID = isNaN(parseInt(action.page_id))
        ? action.page_id.id
        : action.page_id;
      const response = yield call(getWikiComments, pageID);
      const { data } = response;
      yield put(wikiActions.storeComments(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getTagResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getWikiTag, action.tag);
      const { data } = response;
      yield put(wikiActions.storeTag(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getAllTagsResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getAllWikiTags);
      const { data } = response;
      yield put(wikiActions.storeAllTags(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getTaggedPagesResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getTaggedPages, action.tag);
      const { data } = response;
      yield put(wikiActions.storeTaggedPages(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getPageHistoryResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getPageHistory, action.page_id);
      const { data } = response;
      yield put(wikiActions.storePageHistory(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* getRecentPagesResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getRecentPages);
      const { data } = response;
      yield put(wikiActions.storeRecentPages(data));
    } catch (e) {
      console.log(e);
    }
  }
}

// function* getPageReviewResponse(action) {
// 	if (action !== undefined) {
// 		try {
// 			const response = yield call(getPageReview, action.page_review_id);
// 			const {data} = response;
// 			//yield put(storePageReview(data));
// 		} catch (e) {
// 			console.log(e);
// 		}
// 	}
// }

function* getWikiSearchResultsResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getWikiSearchResults, action.params);
      const { data } = response;
      yield put(wikiActions.storeWikiPageSearchResults(data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* createCommentResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(
        createWikiComment,
        action.page_id,
        action.content
      );
      yield put(wikiActions.loadComments(response.data.page));
    } catch (e) {
      console.log(e);
    }
  }
}

function* createSubjectResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(
        createSubjectPage,
        action.subject,
        action.title,
        action.order,
        action.user,
        action.connections
      );
      const { data } = response;
      //yield put(wikiActions.loadAllSubjects())
      window.location.href = '/CLEX/subject/' + data.id;
    } catch (e) {
      console.log(e.response.data);
      yield put(
        wikiActions.subjectFormFail(
          'Subject creation failed, ' + e.response.statusText
        )
      );
    }
  }
}

function* createPageResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(
        createWikiPage,
        action.author,
        action.subject,
        action.title,
        action.memo_regarding,
        action.committees,
        action.tags,
        action.description,
        action.content,
        action.review
      );
      const { data } = response;
      if (action.review) {
        yield put(
          deletePageReview(
            action.review,
            `/CLEX/subject/${data.subject[0]}/page/${data.id}`
          )
        );
      }
      // window.location.href = "/CLEX/subject/" + data.subject[0] + "/page/" + data.id;
    } catch (e) {
      console.log(e.response);
      yield put(
        wikiActions.pageFormFail(
          'Page creation failed, ' + e.response.statusText
        )
      );
    }
  }
}

function* createPageReviewResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(
        createPageReview,
        action.page,
        action.author,
        action.subject,
        action.title,
        action.memo_regarding,
        action.committees,
        action.tags,
        action.description,
        action.content
      );
      const { data } = response;

      window.location.href = '/myTasks/myCLEXReviews/' + data.id;
    } catch (e) {
      console.log(e.response);
      yield put(
        wikiActions.pageFormFail(
          'Page creation failed, ' + e.response.statusText
        )
      );
    }
  }
}

function* createTagResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(createWikiTag, action.tag);
      const { data } = response;
      yield put(wikiActions.tagFormSuccess('Tag ' + data.name + ' created'));
    } catch (e) {
      console.log(e.response.data);
      yield put(
        wikiActions.tagFormFail('Tag creation failed, ' + e.response.statusText)
      );
    }
  }
}

function* updateSubjectResponse(action) {
  if (action !== undefined) {
    try {
      yield call(updateSubject, action.subject);
      yield put(wikiActions.storeLockStatus(action.lock));

      let path = window.location.pathname;
      let type = path.slice(path.lastIndexOf('/') + 1);
      if (type === 'delete' && action.lock)
        yield put(wikiActions.deleteSubjectModal(true));
      if (action.subject.title) {
        window.location.href = '/CLEX/subject/' + action.subject.id;
      }
    } catch (e) {
      console.log(e);
      yield put(wikiActions.storeLockStatus(false));
      let message = 'Subject update failed, ' + e.response.statusText;
      if (e.response.statusText === 'Locked') message = e.response.data;
      yield put(wikiActions.subjectFormFail(message));
    }
  }
}

function* updatePageResponse(action) {
  if (action !== undefined) {
    try {
      yield call(updatePage, action.page);
      let keys = Object.keys(action.page);
      let check = false;
      keys.forEach(key => {
        if (!['edit_lock', 'review_lock', 'id'].includes(key)) {
          check = true;
        }
      });
      if (action.review) {
        if (check) {
          yield put(
            deletePageReview(
              action.review,
              `/CLEX/subject/${action.page.subject[0]}/page/${action.page.id}`
            )
          );
          // window.location.href = "/CLEX/subject/" + action.page.subject[0] + "/page/" + action.page.id;
        } else {
          yield put(deletePageReview(action.review));
        }
      }
      yield put(wikiActions.storeEditLock(action.lock));
    } catch (e) {
      console.log(e);
      yield put(wikiActions.storeEditLock(false));
      let message = 'Page update failed, ' + e.response.statusText;
      if (e.response.statusText === 'Locked') {
        message = e.response.data;
      }
      yield put(wikiActions.pageFormFail(message));
    }
  }
}

function* revertPageResponse(action) {
  if (action !== undefined) {
    try {
      yield call(revertPage, action.page.id, action.revision);
      window.location.href =
        '/CLEX/subject/' + action.page.subject + '/page/' + action.page.id;
    } catch (e) {
      console.log(e);
      let message = 'Page revision failed, ' + e.response.statusText;
      if (e.response.statusText === 'Locked') {
        message = e.response.data;
      }
      yield put(wikiActions.revertPageFail(message));
    }
  }
}

function* updatePageReviewResponse(action) {
  if (action !== undefined) {
    try {
      yield call(updatePageReview, action.pageReview);
      if (action.pageReview.title) {
        window.location.href = '/myTasks/myCLEXReviews/' + action.pageReview.id;
      }
    } catch (e) {
      console.log(e);
      let message = 'Page Review update failed, ' + e.response.statusText;
      if (e.response.statusText === 'Locked') {
        message = e.response.data;
      }
      yield put(wikiActions.pageFormFail(message));
    }
  }
}

function* updateTagResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(updateTag, action.tag);
      yield put(wikiActions.loadAllTags());
      yield put(
        wikiActions.tagFormSuccess('Tag updated to ' + response.data.name)
      );
    } catch (e) {
      console.log(e);
      let message = 'Tag update failed, ' + e.response.statusText;
      yield put(wikiActions.tagFormFail(message));
    }
  }
}

function* deleteSubjectResponse(action) {
  if (action !== undefined) {
    try {
      yield call(deleteSubject, action.subject);
      yield put(wikiActions.storeLockStatus(false));
      yield put(wikiActions.subjectFormSuccess('Subject successfully deleted'));
      yield put(wikiActions.loadAllSubjects(true));
      yield put(wikiActions.loadRootSubjects('nav'));
    } catch (e) {
      let message = 'Subject delete failed, ' + e.response.statusText;
      if (e.response.statusText === 'Conflict') message = e.response.data;
      yield put(wikiActions.subjectFormFail(message));
      yield put(
        wikiActions.subjectUpdate({ id: action.subject, user: false }, false)
      );
      yield put(wikiActions.storeLockStatus(false));
      console.log(e);
    }
  }
}

function* deletePageResponse(action) {
  if (action !== undefined) {
    try {
      yield call(deletePage, action.page);
      yield put(wikiActions.storeEditLock(false));
      yield put(wikiActions.pageFormSuccess('Page successfully  deleted'));
      yield put(wikiActions.loadAllPages(true));
    } catch (e) {
      let message = 'Subject delete failed, ' + e.response.statusText;
      if (e.response.statusText === 'Conflict') message = e.response.data;
      yield put(wikiActions.pageFormFail(message));
      yield put(
        wikiActions.pageUpdate({ id: action.page, edit_lock: false }, false)
      );
      console.log(e);
    }
  }
}

function* deletePageReviewResponse(action) {
  if (action !== undefined) {
    try {
      if (action.redirectPath) {
        yield call(deletePageReview, action.pageReview, action.redirectPath);
      } else {
        yield call(deletePageReview, action.pageReview);
      }
    } catch (e) {
      //let message = 'Subject delete failed, ' + e.response.statusText;
      //if(e.response.statusText === "Conflict") message = e.response.data;
      //yield put(wikiActions.pageFormFail(message));
      //yield put(wikiActions.pageUpdate({id: action.page, edit_lock: false}, false))
      console.log(e);
    }
  }
}

function* deleteTagResponse(action) {
  if (action !== undefined) {
    try {
      yield call(deleteTag, action.tag);
      yield put(wikiActions.tagFormSuccess('Tag successfully  deleted'));
      yield put(wikiActions.loadAllTags());
    } catch (e) {
      let message = 'Subject delete failed, ' + e.response.statusText;
      if (e.response.statusText === 'Conflict') message = e.response.data;
      yield put(wikiActions.loadAllTags());
      yield put(wikiActions.tagFormFail(message));
      console.log(e);
    }
  }
}

function* wikiAPI() {
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_ROOT_SUBJECTS,
    getSubjectRootResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_SUBJECTS,
    getSubjectResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_ALL_SUBJECTS,
    getAllSubjectResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_PAGES,
    getPageChildrenResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.LOAD_PAGE, getPageResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_ALL_PAGES,
    getAllPagesResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_COMMENTS,
    getCommentsResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.LOAD_TAG, getTagResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_ALL_TAGS,
    getAllTagsResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_TAGGED_PAGES,
    getTaggedPagesResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_PAGE_HISTORY,
    getPageHistoryResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_RECENT_PAGES,
    getRecentPagesResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.CREATE_PAGE_REVIEW,
    createPageReviewResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.LOAD_WIKI_SEARCH_RESULTS,
    getWikiSearchResultsResponse
  );
  yield takeEvery(
    wikiActions.wikiPageActions.ADD_COMMENT,
    createCommentResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.ADD_TAG, createTagResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.CREATE_SUBJECT,
    createSubjectResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.CREATE_PAGE, createPageResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.SUBJECT_UPDATE,
    updateSubjectResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.PAGE_UPDATE, updatePageResponse);
  yield takeEvery(wikiActions.wikiPageActions.REVERT_PAGE, revertPageResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.PAGE_REVIEW_UPDATE,
    updatePageReviewResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.TAG_UPDATE, updateTagResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.DELETE_SUBJECT,
    deleteSubjectResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.DELETE_PAGE, deletePageResponse);
  yield takeEvery(
    wikiActions.wikiPageActions.DELETE_PAGE_REVIEW,
    deletePageReviewResponse
  );
  yield takeEvery(wikiActions.wikiPageActions.DELETE_TAG, deleteTagResponse);
}

export default wikiAPI;
