import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  subjects: [],
  rootSubjects: [],
  subject: null,
  page: null,
  parents: [],
  recentPages: [],
  director: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.LOAD_ROOT_SUBJECTS:
      return { ...state };

    case wikiPageActions.LOAD_SIDEBAR_SUBJECTS:
      return { ...state, subjects: action.subjects };

    case wikiPageActions.LOAD_BREADCRUMB_SUBJECTS:
      return { ...state };

    case wikiPageActions.STORE_ROOT_SUBJECTS:
      return {
        ...state,
        subjects: action.subjects.results,
        rootSubjects: action.subjects.results,
      };

    case wikiPageActions.LOAD_SUBJECT_PAGE:
      return { ...state, subject: action.subject, page: null };

    case wikiPageActions.STORE_PARENT_SUBJECTS:
      return { ...state, parents: action.parents };

    case wikiPageActions.LOAD_WIKI_PAGE:
      return { ...state, page: action.page };

    case wikiPageActions.LOAD_RECENT_PAGES:
      return { ...state };

    case wikiPageActions.STORE_RECENT_PAGES:
      return { ...state, recentPages: action.pages.results };

    case wikiPageActions.STORE_DIRECTOR:
      return { ...state, director: action.director };

    case wikiPageActions.WIKI_CHANGE:
      return { ...state, subject: null, page: null };

    default:
      return state;
  }
}
