import React, { Component } from 'react';
import { rolesUnderCouncilMembers } from '../services/constants';
import CSUserManual from './user/userManuals/CSUserManual';
import CMUserManual from './user/userManuals/CMUserManual';
import { connect } from 'react-redux';

class UserManual extends Component {
  render() {
    return (
      <>
        {rolesUnderCouncilMembers.includes(this.props.userProfile.role) && (
          <CMUserManual />
        )}
        {!rolesUnderCouncilMembers.includes(this.props.userProfile.role) && (
          <CSUserManual />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(UserManual);
