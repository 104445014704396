import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/WikiPage.module.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import CommentBox from './CommentBox';
import domToReact from 'html-react-parser/lib/dom-to-react';
import * as actions from '../../actions/wikiPageAction';
import ErrorPage from './ErrorPage';
import { shortDisclaimer, fullDisclaimer } from '../../services/constants';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import {
  ClockHistoryIcon,
  EditPageIcon,
  PrinterIcon,
} from '../../services/SvgLibrary';

const WikiPage = props => {
  const [isShortDisclaimer, setIsShortDisclaimer] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDrafters, setShowDrafters] = useState(false);
  const {
    author,
    comments,
    content,
    description,
    drafters,
    loadComments,
    loadPage,
    location,
    match,
    memo_regarding,
    page_error,
    page_id,
    review,
    storeCurrentPage,
    storePage,
    subject_error,
    subject_of_page,
    subjectPageError,
    tags,
    title,
    userProfile,
    users,
    wikiPageError,
  } = props;
  const { sid, pid } = useParams();
  const { search } = useLocation();
  /**
   * useEffect hook for component mount and unmount
   */
  useEffect(() => {
    loadComments(pid);
    if (sid) {
      let diff = search === '?ref=true' ? 'diffR' : 'diff';
      loadPage(pid, diff, sid);
    } else {
      loadPage(pid, 'diff');
    }
    subjectPageError(false);
    wikiPageError(false);

    return () => {
      storePage({ id: 0, content: '', drafters: [] });
    };
  }, [
    loadComments,
    loadPage,
    storePage,
    subjectPageError,
    wikiPageError,
    search,
    sid,
    pid,
  ]);

  function updatePage() {
    storeCurrentPage(page_id);
  }

  function toggle() {
    setDropdownOpen(!dropdownOpen);
  }

  function getLegDirector() {
    let director = users.filter(user => {
      return user.role === 'Director of Legislative Division' && user.is_active;
    });
    if (director.length === 1) {
      return director[0].full_name.toUpperCase();
    } else {
      return '';
    }
  }

  const parserOptions = {
    replace: domNode => {
      if (domNode.attribs && domNode.attribs.id) {
        switch (domNode.name) {
          case 'h1':
            return (
              <h1 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h1>
            );
          case 'h2':
            return (
              <h2 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h2>
            );
          case 'h3':
            return (
              <h3 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h3>
            );
          case 'h4':
            return (
              <h4 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h4>
            );
          case 'h5':
            return (
              <h5 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h5>
            );
          default:
            if (domNode.attribs.id.includes('foot-')) {
              return (
                <span id={domNode.attribs.id} className='anchor ql-size-small'>
                  {domToReact(domNode.children, parserOptions)}
                </span>
              );
            }
            return domNode;
        }
      }
    },
  };

  let tagDisplay = <div />;
  if (tags) {
    tagDisplay = tags.map((tag, idx) => {
      return (
        <Link
          className={styles['tag-link']}
          key={idx}
          to={'/CLEX/tag/' + tag.id}>
          #{tag.name}
        </Link>
      );
    });
  }

  let draftersList = users.filter(user => drafters.includes(user.id));
  let drafterNames = draftersList.map(draft => draft.full_name);
  let draftersUI = drafterNames.join(', ');

  return (
    <div className={styles['page']}>
      {review && (
        <div className='alert alert-warning' role='alert'>
          This page is currently in review. All highlighted changes have not
          been approved yet.
        </div>
      )}
      {page_id && content && !subject_error && subject_of_page ? (
        <div
          className={
            review
              ? `${styles['watermark']} ${styles['reduce-height']}`
              : styles['watermark']
          }>
          <div className={styles['disclaimer']}>
            {isShortDisclaimer && shortDisclaimer}
            {!isShortDisclaimer && fullDisclaimer}
            <button
              className={styles['disclaimer-button']}
              onClick={() => {
                setIsShortDisclaimer(!isShortDisclaimer);
              }}>
              {isShortDisclaimer
                ? 'Show Full Disclaimer'
                : 'Show Short Disclaimer'}
            </button>
          </div>
          <div className={styles['wiki-header']}>
            <div>PRIVILEGED MEMORANDUM</div>
            <div className={styles['memo-message']}>To: {getLegDirector()}</div>
            <div className={styles['memo-message']}>
              From: {author.full_name.toUpperCase()} (as updated subsequently
              per log)
              <span
                className={styles['drafter-button']}
                onClick={() => setShowDrafters(!showDrafters)}>
                {showDrafters ? 'Hide' : 'Show'}
              </span>
            </div>
            <div
              className={`${styles['drafter-names']} ${styles['memo-message']}`}>
              {showDrafters && draftersUI}
            </div>
            <div className={styles['memo-message']}>
              Re: {memo_regarding ? Parser(memo_regarding) : Parser(title)}
            </div>
          </div>
          <div className={styles['div-row']}>
            <h1 className={styles['title']}>{Parser(title)}</h1>
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret className={styles['edit-page-button']}>
                Edits
              </DropdownToggle>
              <DropdownMenu className={styles['dropdown-menu-wrapper']}>
                {
                  // if page is authored by current user and has permission to update own page OR can update all pages
                  (((author.id === userProfile.id ||
                    drafters.some(users => users.id === userProfile.id)) &&
                    userProfile.permissions.wiki.update_own_page) ||
                    userProfile.permissions.wiki.update_all_page) && (
                    <Link
                      tabIndex='0'
                      role='menuitem'
                      className='dropdown-item'
                      onClick={e => {
                        updatePage(e);
                      }}
                      to='/CLEX/page/edit'>
                      <EditPageIcon /> Edit Article
                    </Link>
                  )
                }
                {userProfile.permissions.wiki.view_versiondiff && (
                  <Link
                    tabIndex='0'
                    role='menuitem'
                    className='dropdown-item'
                    to={`/CLEX/subject/${sid}/page/${pid}/history`}>
                    <ClockHistoryIcon /> Page History
                  </Link>
                )}
                <hr style={{ margin: '.25rem' }}></hr>
                <button
                  tabIndex='0'
                  role='menuitem'
                  className='dropdown-item'
                  onClick={() => {
                    window.open(
                      `${window.location.origin}/CLEX/subject/${sid}/page/${pid}/print`
                    );
                  }}>
                  <PrinterIcon /> Printer Friendly
                </button>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <div>{tagDisplay}</div>
          <br />
          <div>{Parser(description)}</div>
          <br />
          {Parser(content, parserOptions)}
          {userProfile.permissions.wiki.view_comment && (
            <CommentBox
              id='#comments'
              className='anchor'
              page_id={page_id}
              comments={comments}
            />
          )}
        </div>
      ) : (
        ''
      )}
      {!page_id && !page_error && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            align='middle'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {(page_error || subject_error || !subject_of_page) && (
        <ErrorPage type='page' idError={!subject_of_page} />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    author: state.wikiPageReducer.author,
    comments: state.wikiPageReducer.comments,
    content: state.wikiPageReducer.content,
    description: state.wikiPageReducer.description,
    drafters: state.wikiPageReducer.drafters,
    memo_regarding: state.wikiPageReducer.memo_regarding,
    page_error: state.wikiPageReducer.page_error,
    page_id: state.wikiPageReducer.page_id,
    review: state.wikiPageReducer.review,
    subject_error: state.wikiSubjectReducer.subject_error,
    subject_of_page: state.wikiPageReducer.subject_of_page,
    tags: state.wikiPageReducer.tags,
    title: state.wikiPageReducer.title,
    userProfile: state.userReducer.userProfile,
    users: state.userReducer.userList,
  };
};

export default connect(mapStateToProps, actions)(WikiPage);
