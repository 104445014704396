import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import File from '../library/File';
import CitationRequestTile from './CitationRequestTile';
import CitationRequestFields from '../../services/CitationRequestFields';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import * as actions from '../../actions/citationRequestAction';
import { formatDate } from '../../utils/helper';
import styles from '../../styles/report-tracking/DueReportsDetails.module.scss';
import {
  CloseButtonNormalIcon,
  ArrowCircleFillLeftIcon,
  BoxAndArrowIcon,
} from '../../services/SvgLibrary';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { useParams } from 'react-router-dom';

const CitationRequestsForDay = props => {
  const [citationRequestDetail, setCitationRequestDetail] = useState({});

  const {
    selectedDayCitationRequests,
    changeCitationRequestDisplay,
    selectedCitationRequest,
    allCitationRequests,
    shownStatuses,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    if (props.selectedDayCitationRequests.length === 1) {
      props.changeCitationRequestDisplay('detail');
      setCitationRequestDetail({ ...props.selectedCitationRequest });
    }
  }, [
    selectedDayCitationRequests,
    changeCitationRequestDisplay,
    selectedCitationRequest,
  ]);

  const onTileClick = citationRequest => {
    props.selectCitationRequest(citationRequest);
    props.changeCitationRequestDisplay('detail');
  };

  // Generate the citationRequest details
  const displayCitationRequestDetail = citationRequestFields => {
    let citationRequestDetail = {};
    if (props.selectedDayCitationRequests.length === 0) return;

    if (props.selectedDayCitationRequests.length === 1) {
      citationRequestDetail = { ...props.selectedDayCitationRequests[0] };
    } else {
      citationRequestDetail = { ...props.selectedCitationRequest };
    }

    const details =
      citationRequestDetail &&
      citationRequestFields.map((field, idx) => {
        // Display a list of assigned contacts
        if (field.field.includes('_contact')) {
          const arr = citationRequestDetail[field.field];
          let contactLength = arr.length - 1;
          let links = arr.map((contact, idx) => {
            return (
              <span key={idx}>
                <a
                  href={`/staff/${contact.id}`}
                  style={{
                    textDecoration: 'none',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '0.2rem',
                  }}>
                  {contact.full_name} <BoxAndArrowIcon width='12' height='12' />
                </a>
                {contactLength === idx ? '' : ', '}
              </span>
            );
          });
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{links}</div>
            </div>
          );
        } else if (
          field.field === 'requested_by' ||
          field.field === 'contact_person' ||
          field.field === 'council_member'
        ) {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{citationRequestDetail[field.field].full_name}</div>
            </div>
          );
        } else if (field.field === 'co_signers') {
          const cosigners = props.users
            .filter(ele => citationRequestDetail.co_signers.includes(ele.id))
            .map(ele => ele.full_name)
            .join(', ');
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{cosigners.length ? cosigners : 'None'}</div>
            </div>
          );
        } else if (
          field.type === 'file' ||
          (field.field === 'citation_custom_text_attachment' &&
            !citationRequestDetail.sample_used)
        ) {
          let files;
          if (citationRequestDetail[field.field]) {
            files = citationRequestDetail[field.field];
          } else {
            files = [];
          }

          return (
            <div key={idx}>
              <p>{field.title}</p>
              {files.map((file, index) => (
                <File
                  file={file}
                  id={`citation_request_${field.field}-${index}`}
                  getFileAndDownload={props.getFileAndDownload}
                  downloadStatus={props.downloadStatus}
                  key={index}
                />
              ))}
              {citationRequestDetail[field.field].length >= 1 ? '' : 'None'}
            </div>
          );
        } else if (
          field.field === 'citation_custom_text_attachment' &&
          citationRequestDetail.sample_used
        ) {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{citationRequestDetail.sample_used}</div>
            </div>
          );
        } else if (typeof citationRequestDetail[field.field] === 'boolean') {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{citationRequestDetail[field.field] ? 'True' : 'False'}</div>
            </div>
          );
        } else if (field.type === 'date') {
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>
                {citationRequestDetail[field.field]
                  ? formatDate(citationRequestDetail[field.field])
                  : 'None'}
              </div>
            </div>
          );
        } else if (field.field === 'notes') {
          citationRequestDetail[field.field] = citationRequestDetail[
            field.field
          ]
            ? citationRequestDetail[field.field]
            : 'None';
          if (!rolesUnderCouncilMembers.includes(props.userProfile.role)) {
            return (
              <div key={idx}>
                <p>{field.title}</p>
                <div>{citationRequestDetail[field.field]}</div>
              </div>
            );
          } else {
            return null;
          }
        } else if (field.field === 'other') {
          return null;
        } else {
          citationRequestDetail[field.field] = citationRequestDetail[
            field.field
          ]
            ? citationRequestDetail[field.field]
            : 'None';
          return (
            <div key={idx}>
              <p>{field.title}</p>
              <div>{citationRequestDetail[field.field]}</div>
            </div>
          );
        }
      });

    return <div className={styles['report-field-column']}>{details}</div>;
  };

  // const onBackToCitationRequest = () => {
  //   props.changeCitationRequestDisplay("detail");
  // };

  // Get the target due citationRequests on selected day
  const keyMap = {
    'Approved': 'approved',
    'Completed': 'completed',
    'Delivered': 'delivered',
    'In-Progress': 'in-progress',
    'Pending': 'pending',
    'Withdrawn': 'withdrawn',
  };
  const filteredSelectedDayCitationRequests =
    selectedDayCitationRequests.filter(
      ele => shownStatuses[keyMap[ele.status]]
    );
  let citationRequests = props.selectedDay
    ? allCitationRequests[props.selectedDay.getDate()]
    : selectedDayCitationRequests;
  const citationRequestsList =
    filteredSelectedDayCitationRequests &&
    citationRequests
      .filter(ele => shownStatuses[keyMap[ele.status]])
      .map((citationRequest, idx) => {
        return (
          <CitationRequestTile
            key={idx}
            status={props.selectedDay ? citationRequest[0] : null}
            citationRequest={citationRequest}
            onTileClick={onTileClick}
          />
          //  {idx !== citationRequests.length-1 && <hr style={{width:'90%'}}/>}
        );
      });

  let halfOfFields = CitationRequestFields.length / 2;
  let firstHalfOfFields = CitationRequestFields.slice(0, halfOfFields);
  let secondHalfOfFields = CitationRequestFields.slice(halfOfFields);

  // Generate the content in the popup window
  // By default it will be a list of citation requests with title and committees
  // Once the end users click the title, it will change to the details of that citation requests
  return (
    <>
      {props.currentDisplay === 'list' && (
        <div
          className={`${styles['modal-header']} ${styles['modal-header-citations']}`}>
          <h3 className='popup-detail-title'>Citation Requests List</h3>
          <CloseButtonNormalIcon
            classNameProp='popup-close-icon'
            onClickFunction={props.onCloseWindow}
            alt='close-icon'
          />
        </div>
      )}
      {props.currentDisplay === 'list' && (
        <div style={{ height: '50vh', overflow: 'auto', padding: '1rem' }}>
          {citationRequestsList}
        </div>
      )}
      {props.currentDisplay === 'detail' && (
        <>
          <div
            className={`${styles['modal-header']} ${styles['modal-header-citations']}`}>
            {props.selectedDayCitationRequests.length > 1 &&
              !(id && props.init) && (
                <button
                  className='back-icon'
                  onClick={() => {
                    if (props.currentMode === 'list') {
                      props.onBackToList();
                    } else {
                      props.changeCitationRequestDisplay('list');
                    }
                  }}>
                  <ArrowCircleFillLeftIcon /> <span>Back</span>
                </button>
              )}
            <h3 className='popup-detail-title'>Citation Request Detail</h3>
            <CloseButtonNormalIcon
              classNameProp='popup-close-icon'
              onClickFunction={props.onCloseWindow}
              alt='close-icon'
            />
          </div>
          <div className={styles['report-fields']}>
            {displayCitationRequestDetail(firstHalfOfFields)}
            {displayCitationRequestDetail(secondHalfOfFields)}
          </div>
          {rolesUnderCouncilMembers.includes(props.userProfile.role) ? (
            <div className={styles['modal-footer']}>
              <div className={styles['modal-footer-button-container']}>
                <button
                  className={`submit-button ${styles['cancel-button']}`}
                  onClick={props.onCloseWindow}>
                  Cancel
                </button>
                <button
                  className={`submit-button ${styles['footer-button']}`}
                  onClick={() => {
                    props.changeCitationRequestDisplay('update');
                  }}>
                  Update Request
                </button>
              </div>
              <span>
                To upload a citation request document, update this citation
                request.
              </span>
            </div>
          ) : (
            <div className={styles['modal-footer']}>
              <div className={styles['modal-footer-button-container']}>
                <button
                  className={`submit-button ${styles['cancel-button']} ${
                    props.isLoading ? styles['disabled'] : ''
                  }`}
                  onClick={() => {
                    props.updateCitationRequest({
                      ...props.selectedCitationRequest,
                      is_archived: true,
                    });
                  }}>
                  Delete Request
                </button>
                <button
                  className={`submit-button ${styles['footer-button']}`}
                  onClick={() => {
                    props.changeCitationRequestDisplay('update');
                  }}>
                  Update Request
                </button>
              </div>
              <span>
                To upload a citation request document, update this citation
                request.
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    allCitationRequests:
      state.citationRequestReducer.allCitationRequestsForDays,
    selectedDayCitationRequests:
      state.citationRequestReducer.selectedDayCitationRequests,
    selectedCitationRequest:
      state.citationRequestReducer.selectedCitationRequest,
    downloadStatus: state.fileDownloadReducer.downloadStatus,
    currentDisplay: state.citationRequestReducer.currentDisplay,
    userProfile: state.userReducer.userProfile,
    users: state.userReducer.userList,
    isLoading: state.citationRequestReducer.isLoading,
  };
};

const mapDispatchToProps = {
  ...actions,
  getFileAndDownload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CitationRequestsForDay);
