import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  tag_id: 0,
  name: '',
  pages: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.STORE_TAG:
      return { ...state, tag_id: action.tag.id, name: action.tag.name };

    case wikiPageActions.STORE_TAGGED_PAGES:
      return { ...state, pages: action.pages.results };

    default:
      return state;
  }
}
