import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from '../../styles/user/Login.module.scss';
import * as actions from '../../actions/userAction';

const PasswordForm = props => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [password1, setPassword1] = useState('');
  const [password1Validation, setPassword1Validation] = useState({
    passwordSimilar: false,
    passwordPrevious: false,
    passwordCommon: false,
    passwordTen: false,
    passwordUpper: false,
    passwordLower: false,
    passwordNumber: false,
    passwordSpecial: false,
  });
  const [password2, setPassword2] = useState('');
  const [password2Validation, setPassword2Validation] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const {
    changePassword,
    changePasswordByToken,
    changingPasswordStatus,
    clearErrorMessage,
    clearSuccessMessage,
    closePasswordForm,
    errorMessage,
    location,
    needCurrentPassword,
    passwordChangeStatus,
    reset,
    successMessage,
    userProfile,
  } = props;
  /** useEffect hook for component mount */
  useEffect(() => {
    if (reset && !queryString.parse(window.location?.search).token) {
      window.location.assign('/');
    }
  }, [window.location?.search, reset]);

  // update the password fields
  function onPasswordChange(e) {
    if (e.target.name === 'currentPassword') {
      setCurrentPassword(e.target.value);
    }
    if (e.target.name === 'password1') {
      let validator = {
        passwordTen: false,
        passwordUpper: false,
        passwordLower: false,
        passwordNumber: false,
        passwordSpecial: false,
      };
      validator['passwordTen'] = e.target.value.length >= 10 ? true : false;
      validator['passwordUpper'] = /[A-Z]/.test(e.target.value) ? true : false;
      validator['passwordLower'] = /[a-z]/.test(e.target.value) ? true : false;
      validator['passwordNumber'] = /[0-9]/.test(e.target.value) ? true : false;
      validator['passwordSpecial'] = /[@_!#$%^&*()<>?/|}{~:]/.test(
        e.target.value
      )
        ? true
        : false;

      setPassword1(e.target.value);
      setPassword1Validation(validator);
      setPassword2Validation(e.target.value === password2);
    }
    if (e.target.name === 'password2') {
      setPassword2(e.target.value);
      setPassword2Validation(e.target.value === password1);
    }
  }

  // Detect if both passwords are the same and not empty
  // Then makes an API call to change the password
  function onSubmit(e) {
    e.preventDefault();
    clearErrorMessage();
    clearSuccessMessage();
    if (
      (needCurrentPassword && currentPassword === '') ||
      password1 === '' ||
      password2 === '' ||
      password1 !== password2 ||
      password1 === userProfile.last_name.toLowerCase() + '250' ||
      !captcha ||
      Object.values(password1Validation).indexOf('false') >= 0
    ) {
      setWarningMessage(true);
    } else {
      setWarningMessage(false);
      if (!reset) {
        changePassword(userProfile.id, password1, currentPassword);
      } else if (queryString.parse(window.location?.search).token) {
        changePasswordByToken(
          queryString.parse(window.location?.search).token,
          password1
        );
      }
    }
  }

  function onBack(e) {
    e.preventDefault();
    setCurrentPassword('');
    setPassword1('');
    setPassword1Validation({
      passwordTen: false,
      passwordUpper: false,
      passwordLower: false,
      passwordNumber: false,
      passwordSpecial: false,
    });
    setPassword2('');
    setPassword2Validation(false);
    setWarningMessage(false);
    setCaptcha(null);
    closePasswordForm();
  }

  // Handle event when the end user press enter
  function onHandlePressEnter(e) {
    if (e.key === 'Enter') e.preventDefault();
  }

  return (
    <div className='card mb-4 border-0 rounded-3'>
      <div className='card-body'>
        <h5 className='card-title'>Change Password</h5>
        <div
          style={{
            backgroundColor: '#F0F1FF',
            margin: '0 auto 15px',
            padding: '15px',
            border: `${warningMessage ? '2px solid #dc3545' : 'none'}`,
          }}>
          <p className='m-0'>New passwords:</p>
          <ul id={styles['password-constraints']}>
            <li
              className={
                password1Validation.passwordTen
                  ? styles['pass']
                  : styles['fail']
              }>
              Must have at least 10 characters.
            </li>
            <li
              className={
                password1Validation.passwordUpper
                  ? styles['pass']
                  : styles['fail']
              }>
              Must have at least one uppercase letter.
            </li>
            <li
              className={
                password1Validation.passwordLower
                  ? styles['pass']
                  : styles['fail']
              }>
              Must have at least one lowercase letter.
            </li>
            <li
              className={
                password1Validation.passwordNumber
                  ? styles['pass']
                  : styles['fail']
              }>
              Must have at least one number.
            </li>
            <li
              className={
                password1Validation.passwordSpecial
                  ? styles['pass']
                  : styles['fail']
              }>
              Must have at least one special character.
            </li>
            <li
              className={password2Validation ? styles['pass'] : styles['fail']}>
              Must match the password confirmation.
            </li>
            <li className={styles['standard']}>
              Cannot contain your username.
            </li>
            <li className={styles['standard']}>
              Cannot be any previously used passwords
            </li>
          </ul>
        </div>
        <form onKeyUp={onHandlePressEnter}>
          {/* Error messages for frontend validation BEFORE it hits the backend */}
          {warningMessage && (
            <div className={`alert alert-danger`} role='alert'>
              There was an error saving your password, please make sure your new
              password satisfies ALL conditions above.
              <br />
              If you believe you are seeing this message in error, please
              contact the{' '}
              <a href='mailto:WebDevelopmentUnit@council.nyc.gov'>
                Web Development Unit.
              </a>
            </div>
          )}
          {/* Error messages from the backend */}
          {errorMessage && (
            <div
              className={`alert error-message alert-${
                passwordChangeStatus === 'success' ? 'success' : 'danger'
              }`}
              role='alert'>
              <ul className='m-0 p-0'>
                {errorMessage.map((error, i) => {
                  return <li key={i}>{error}</li>;
                })}
              </ul>
            </div>
          )}
          {successMessage && (
            <div className={`alert success-message alert-success`} role='alert'>
              {successMessage}
            </div>
          )}
          <>
            {needCurrentPassword && (
              <>
                <div className='form-floating mb-3'>
                  <input
                    autoComplete='off'
                    type='password'
                    placeholder='Current Password'
                    maxLength='256'
                    id='currentPassword'
                    className={`form-control ${
                      warningMessage &&
                      needCurrentPassword &&
                      currentPassword === '' &&
                      'is-invalid'
                    }`}
                    name='currentPassword'
                    value={currentPassword}
                    onChange={onPasswordChange}
                    required
                  />
                  <label htmlFor='currentPassword'>Current Password</label>
                  {warningMessage &&
                    needCurrentPassword &&
                    currentPassword === '' && (
                      <div className='invalid-feedback'>
                        You must to enter your current password
                      </div>
                    )}
                </div>
              </>
            )}
            <hr />
            <div className='form-floating mb-3'>
              <input
                autoComplete='off'
                type='password'
                placeholder='New Password'
                maxLength='256'
                className={`form-control ${
                  warningMessage &&
                  Object.values(password1Validation).indexOf('false') &&
                  'is-invalid'
                }`}
                name='password1'
                id='password1'
                value={password1}
                onChange={onPasswordChange}
              />
              <label htmlFor='password1'>New Password</label>
            </div>
            <div className='form-floating mb-3'>
              <input
                autoComplete='off'
                type='password'
                placeholder='Confirm New Password'
                maxLength='256'
                className={`form-control ${
                  warningMessage &&
                  (password1 !== password2 || password2 === '') &&
                  'is-invalid'
                }`}
                name='password2'
                id='password2'
                value={password2}
                onChange={onPasswordChange}
              />
              <label htmlFor='password2'>Confirm New Password</label>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={value => setCaptcha(value)}
            />
            {warningMessage && !captcha && (
              <div className='invalid-feedback d-block'>
                Must check the CAPTCHA!
              </div>
            )}
            <div className='buttons-container'>
              <button className='cancel-button' onClick={onBack}>
                Cancel
              </button>
              <button
                className='submit-button'
                onClick={onSubmit}
                disabled={changingPasswordStatus}>
                {changingPasswordStatus && (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'></span>
                )}
                &nbsp;Change password
              </button>
            </div>
          </>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    successMessage: state.userReducer.successMessage,
    errorMessage: state.userReducer.errorMessage,
    changingPasswordStatus: state.userReducer.changingPasswordStatus,
    passwordChangeStatus: state.userReducer.passwordChangeStatus,
  };
};

export default connect(mapStateToProps, actions)(PasswordForm);
