import React, { useCallback, useEffect, useMemo } from 'react';
import {
  loadMyPageReviews,
  loadAllPageReviews,
} from '../../../actions/myTasksAction';
import styles from '../../../styles/user/dashboard/ReviewList.module.scss';
import { connect } from 'react-redux';
import { ShortRightArrowIcon } from '../../../services/SvgLibrary';

const ReviewList = props => {
  // destructed props for React Hooks
  const { loadAllPageReviews, loadMyPageReviews, userList } = props;
  // Staff List Memoized
  const userIsAStaffReviewer = useMemo(() => {
    const staffReviewers = userList.filter(user => user.can_review_wiki);
    return (
      staffReviewers.find(
        reviewer => reviewer.username === props.user.username
      ) !== undefined
    );
  }, [userList, props.user.username]);

  const checkAuth = () => {
    if (
      userIsAStaffReviewer ||
      props.user.role === 'Deputy Director' ||
      props.user.role === 'Assistant Deputy Director'
    ) {
      return true;
    } else {
      return false;
    }
  };
  const checkAuthOnLoad = useCallback(checkAuth, [props.user.role]);
  useEffect(() => {
    if (checkAuthOnLoad()) {
      loadAllPageReviews();
    } else {
      loadMyPageReviews();
    }
  }, [loadAllPageReviews, loadMyPageReviews, checkAuthOnLoad]);

  const pageReviewToList = pages => {
    return pages.map((page, idx) => {
      let committees = page.committees.map((com, idx) => (
        <li key={idx} className={styles['item']}>
          {com.committee_name}
        </li>
      ));
      let updated = new Date(page.updated_at);
      let minutes = updated.getMinutes();
      return (
        <tr
          key={idx}
          onClick={() =>
            (window.location.href = `/myTasks/myCLEXReviews/${page.id}`)
          }>
          <td>{page.page ? 'Updated' : 'Created'}</td>
          <td>{page.title}</td>
          <td>
            {updated.toLocaleDateString()}
            <br />
            <small>
              {updated.getHours() % 12 ? updated.getHours() % 12 : '12'}:
              {minutes < 10 ? `0${minutes}` : minutes}
              {updated.getHours() < 12 ? 'AM' : 'PM'}
            </small>
          </td>
          <td>
            <ul style={{ 'padding': '0px', 'listStyle': 'none' }}>
              {committees}
            </ul>
          </td>
        </tr>
      );
    });
  };

  const inReview =
    props.my_pages && props.my_pages.length > 0
      ? pageReviewToList(props.my_pages)
      : null;
  const toBeReviewed =
    props.un_reviewed && props.un_reviewed.length > 0
      ? pageReviewToList(props.un_reviewed)
      : null;
  const reviewAuth = checkAuth();

  return (
    <div className={styles['widget-container']}>
      <div className={styles['table-header-container']}>
        <h3>{reviewAuth ? 'CLEX Review Required' : 'My CLEX Pages'}</h3>
        <a href='/myTasks/myCLEXReviews'>
          Visit All
          <ShortRightArrowIcon />
        </a>
      </div>
      <table
        className={`table table-hover align-middle ${styles['clex-dashboard-table']}`}>
        <thead>
          <tr className='table-headers'>
            <th className={styles['clex-dashboard-table-headers']}>Action</th>
            <th className={styles['clex-dashboard-table-headers']}>Title</th>
            <th className={styles['clex-dashboard-table-headers']}>
              Last Updated
            </th>
            <th className={styles['clex-dashboard-table-headers']}>
              Committees
            </th>
          </tr>
        </thead>
        <tbody>
          {reviewAuth ? toBeReviewed : inReview}
          {((!reviewAuth && props.my_pages.length === 0) ||
            (reviewAuth && props.un_reviewed.length === 0)) && (
            <tr>
              <td colSpan='5' style={{ textAlign: 'center' }}>
                No pages under review
              </td>
            </tr>
          )}
          {props.loadingMyReviews && (
            <tr className='loading-container' style={{ textAlign: 'center' }}>
              <td colSpan='5'>
                <div className='spinner-border text-secondary' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    un_reviewed: state.reviewListReducer.un_reviewed,
    my_pages: state.reviewListReducer.my_pages,
    loadingMyReviews: state.reviewListReducer.loadingMyReviews,
    loadAllPageReviews: state.reviewListReducer.loadingAllReviews,
  };
};

const mapDispatchtoProps = {
  loadAllPageReviews,
  loadMyPageReviews,
};

export default connect(mapStateToProps, mapDispatchtoProps)(ReviewList);
