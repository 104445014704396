import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/CreateNewReportDdl.module.scss';
import citationRequestFields from '../../services/CitationRequestFields';
import File from '../library/File';
import * as actions from '../../actions/citationRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { acceptedFileTypes, councilMembers } from '../../utils/helper';
import TagsDisplay from '../report-tracking/TagsDisplay';
import {
  ExclamationPointDiamondIcon,
  LargeCheckMarkIcon,
  FileDownloadIcon,
} from '../../services/SvgLibrary';

const citationRequestDefaults = {
  citation_custom_text_attachment: [],
  co_signers: [],
  contact_person: '',
  council_member: '',
  date_citation_needed: '',
  date_of_event: '',
  final_citation: [],
  honoree: '',
  is_speaker_citation: false,
  notes: '',
  other: '',
  sample_used: '',
};

const UpdateCitationRequest = props => {
  const customTextFileInputRef = React.useRef(null);
  const citationFileInputRef = React.useRef(null);
  const [citationRequest, setCitationRequest] = useState({
    ...citationRequestDefaults,
  });
  const [contactPersonList, setContactPersonList] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [originalDate, setOriginalDate] = useState(null);
  const [page, setPage] = useState(0);
  const [fileLimitExceeded, setFileLimitExceeded] = useState('');
  const [errorField, setErrorField] = useState({});
  const [errorsPresent, setErrorsPresent] = useState(false);
  const [refs, setRefs] = useState({});
  const [hoveredTags, setHoveredTags] = useState(new Map());
  const [isUploadSelected, setIsUploadSelected] = useState(false);

  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const {
    allUserList,
    saveProgress,
    selectedCitationRequest,
    userList,
    userProfile,
  } = props;

  // create the reference for each of the field in the map
  useEffect(() => {
    registerRefs();
    let { council_member, contact_person } = selectedCitationRequest;
    let availableContacts = allUserList.filter(user =>
      user.under_council_member
        ? user.under_council_member === council_member.id
        : user.id === council_member.id
    );
    let co_signers = allUserList.filter(ele => {
      if (selectedCitationRequest.co_signers.includes(ele.id)) {
        return { id: ele.id, full_name: ele.full_name };
      }
    });

    setContactPersonList(availableContacts);
    const newCitationRequest = {
      ...selectedCitationRequest,
      council_member: council_member.full_name,
      contact_person: contact_person.full_name,
      co_signers: co_signers,
    };

    setCitationRequest(newCitationRequest);
    if (!newCitationRequest.sample_used) {
      setIsUploadSelected(true);
    }
    setOriginalDate(props.selectedCitationRequest.date_citation_needed);

    const groupsMap = {};

    for (let user in props.userList) {
      let userGroups = props.userList[user].groups;
      for (let i in userGroups) {
        if (groupsMap[userGroups[i].name]) {
          groupsMap[userGroups[i].name].push(props.userList[user]);
        } else {
          groupsMap[userGroups[i].name] = [props.userList[user]];
        }
      }
    }

    setUserGroups(groupsMap);

    return () => {
      props.saveProgress(citationRequest);
    };
  }, []);

  const handleMouseEnter = key => {
    hoveredTags.set(key, true);
    setHoveredTags(hoveredTags);
  };

  const handleMouseLeave = key => {
    hoveredTags.set(key, false);
    setHoveredTags(hoveredTags);
  };

  const scrollToTheTop = () => {
    document.getElementsByClassName(styles['report-fields'])[0].scrollTo(0, 0);
  };

  // arr.reduce((accumulator, current)) to generate an object of refs
  const registerRefs = () => {
    const refs = citationRequestFields.reduce((acc, current) => {
      const ref = React.createRef();
      acc[current.field] = ref;
      return acc;
    }, {});
    setRefs({ ...refs });
  };

  const checkFieldValidation = fields => {
    let isValid = true;
    let errors = {};
    const { userProfile } = props;

    let today = new Date();

    const getBusinessDatesCount = (startDate, endDate) => {
      let count = 0;
      const curDate = new Date(startDate);
      while (curDate < endDate) {
        const dayOfWeek = curDate.getDay();
        if (dayOfWeek !== 5 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    };
    let numOfDaysUntilNeeded = getBusinessDatesCount(
      today,
      new Date(citationRequest['date_citation_needed'])
    );

    const isCMStaff = rolesUnderCouncilMembers.includes(userProfile.role);

    for (let i = 0; i < fields.length; i++) {
      const curt = fields[i];
      //If the current field is required and if it is empty or an empty array
      if (
        curt.required &&
        (citationRequest[curt.field] === 'None' ||
          (typeof citationRequest[curt.field] !== 'boolean' &&
            !citationRequest[curt.field]) ||
          (Array.isArray(citationRequest[curt.field]) &&
            citationRequest[curt.field].length < 1))
      ) {
        errors[curt.field] = {
          'is_invalid': true,
          'error_message': 'Required fields (*) cannot be empty!',
        };
        isValid = false;
      }

      if (isCMStaff && numOfDaysUntilNeeded < 5) {
        if (
          !(
            new Date(originalDate) <=
            new Date(citationRequest['date_citation_needed'])
          )
        ) {
          errors['date_citation_needed'] = {
            'is_invalid': true,
            'error_message': 'Must be at least 5 business days from today',
          };
          isValid = false;
        }
      }

      if (
        isCMStaff &&
        numOfDaysUntilNeeded < 5 &&
        new Date(originalDate) >
          new Date(citationRequest['date_citation_needed'])
      ) {
        errors['date_citation_needed'] = {
          'is_invalid': true,
          'error_message': `Must be at least 5 business days from today or at date later than or equal to original date (${originalDate})`,
        };
        isValid = false;
      }
    }
    setErrorField(errors);
    setErrorsPresent(!isValid);
    return isValid;
  };

  // On click submit for sending out the request
  const onSubmit = (e, fields) => {
    e.preventDefault();
    let isValid = checkFieldValidation(fields); // TODO add field validation
    if (isValid) {
      props.updateCitationRequest(citationRequest);
    }
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetCouncilMember = targetIdx => {
    let councilMembers = citationRequest['co_signers'];
    councilMembers.splice(targetIdx, 1);
    setCitationRequest({ ...citationRequest, co_signers: councilMembers });
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedCouncilMember = selectedCouncilMember => {
    let councilMembers = citationRequest['co_signers'];
    let hasDuplicate = false;
    for (let i = 0; i < councilMembers.length; i++) {
      if (councilMembers[i].id === selectedCouncilMember.id) {
        hasDuplicate = true;
        break;
      }
    }

    if (!hasDuplicate) {
      councilMembers.push(selectedCouncilMember);
    }

    councilMembers = councilMembers.sort((a, b) =>
      a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
    );
    setCitationRequest({ ...citationRequest, co_signers: councilMembers });
  };

  const onDeleteAttachment = (e, citation, field) => {
    props.deleteAttachmentFromDB(citation.id, field);
    setCitationRequest({
      ...citationRequest,
      [field]: citationRequest[field].filter(item => item.id !== citation.id),
    });
    e.stopPropagation();
  };

  const onDeleteNewAttachment = (e, id, field) => {
    const newFileArr = citationRequest[field];
    newFileArr.splice(id, 1);
    setCitationRequest({ ...citationRequest, [field]: newFileArr });
    e.stopPropagation();
  };

  const generateFields = citationRequestFields => {
    const obj = {
      co_signers: {
        inputTitle: 'Council Members Co-signing',
        required: false,
        param: 'full_name',
        warningMessage: 'No council member has been selected',
        onAddTarget: onAddSelectedCouncilMember,
        onDeleteTarget: onDeleteTargetCouncilMember,
        dropdownList: councilMembers(props.userList),
      },
    };

    const { under_council_member, role } = props.userProfile;
    const isCMStaff = under_council_member || role.includes('Council Member');
    const newDdlDisplay = citationRequestFields
      .filter(field => (isCMStaff ? field.cmStaffUpdateViewable : true))
      .map((field, idx) => {
        let customTitle = '';
        if (field.field === 'date_of_event') {
          customTitle = 'Date of Event (Email Citations team to change date).';
        }
        if (field.field === 'date_citation_needed') {
          customTitle =
            'Date Citation Needed (Email Citations team to change date).';
        }
        if (field.field === 'requested_by') {
          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <p className={styles['input-text-title']}>
                {field.title}
                {field.required && <span>*</span>}
              </p>
              <input
                className={`form-control ${
                  !errorField[field.field]
                    ? styles['input-text-area']
                    : styles['input-text-area-warning']
                }`}
                type={field.type}
                name={field.field}
                value={
                  citationRequest[field.field]
                    ? citationRequest[field.field].full_name
                    : ''
                }
                onChange={e => {
                  setCitationRequest({
                    ...citationRequest,
                    [field.field]: e.target.value,
                  });
                }}
                disabled
                ref={refs[field.field]}
              />
              {errorField[field.field] && (
                <p className={styles['warning-message']}>
                  {errorField[field.field]['error_message']}
                </p>
              )}
            </div>
          );
        }
        if (field.type === 'radio-select') {
          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <p className={styles['input-text-title']}>{field.title}</p>
              <div className={styles['custom-text-options-container']}>
                {field.options.map((opt, optIdx) => {
                  const checked =
                    (opt.type === 'sample' &&
                      opt.text === citationRequest.sample_used) ||
                    (opt.type === 'file' && !citationRequest.sample_used);
                  return (
                    <div
                      className={`${styles['radio-buttons']} ${styles['custom-text-radio-buttons']}`}
                      key={optIdx}>
                      <input
                        type='radio'
                        name='custom-text'
                        id={styles[`custom-text-${optIdx}`]}
                        checked={checked}
                        onChange={() => {
                          if (optIdx === 4) {
                            setIsUploadSelected(true);
                            setCitationRequest({
                              ...citationRequest,
                              sample_used: '',
                            });
                          } else {
                            setIsUploadSelected(false);
                            setCitationRequest({
                              ...citationRequest,
                              sample_used: opt.text,
                            });
                          }
                        }}
                      />

                      <label
                        htmlFor={styles[`custom-text-${optIdx}`]}
                        className={styles['custom-text-radio-button-label']}>
                        {opt.download_link && (
                          <button
                            type='button'
                            className={`btn btn-sm`}
                            onClick={() => {
                              window.open(
                                `${window.location.origin}${opt.download_link}`
                              );
                            }}>
                            <FileDownloadIcon width='15px' height='15px' />
                          </button>
                        )}
                        <div className={styles['custom-text-upload-container']}>
                          <span>{opt.text}</span>
                          {isUploadSelected && optIdx === 4 && (
                            <div className='mb-3' key={`${page}-${idx}`}>
                              {citationRequest[field.field].length
                                ? citationRequest[field.field].map(
                                    (citationRequestFile, idx) => {
                                      return (
                                        <File
                                          key={idx}
                                          file={citationRequestFile}
                                          id={`new-${idx}`}
                                          getFileAndDownload={() =>
                                            getFileAndDownload()
                                          }
                                          onDelete={e => {
                                            if (citationRequestFile.id) {
                                              onDeleteAttachment(
                                                e,
                                                citationRequestFile,
                                                field.field
                                              );
                                            } else {
                                              onDeleteNewAttachment(
                                                e,
                                                idx,
                                                field.field
                                              );
                                            }
                                            customTextFileInputRef.current.value =
                                              '';
                                          }}
                                          deletePermission={true}
                                        />
                                      );
                                    }
                                  )
                                : null}
                              {fileLimitExceeded === field.field && (
                                <span className={styles['file-error']}>
                                  File limit exceeded
                                </span>
                              )}
                              <p className={styles['file-padding']}>
                                Upload a new file
                              </p>
                              <input
                                ref={customTextFileInputRef}
                                type={opt.type}
                                name={field.field}
                                accept={acceptedFileTypes}
                                multiple
                                onChange={e => {
                                  let error = [];
                                  for (
                                    let i = 0;
                                    i < e.target.files.length;
                                    i++
                                  ) {
                                    //If file size is greater than 250MB
                                    if (e.target.files[i].size > 250000000) {
                                      error.push(
                                        e.target.files[i].name + ' is too large'
                                      );
                                    }
                                  }
                                  if (error.length) {
                                    e.target.value = null;
                                    setFileLimitExceeded(field.field);
                                    setFileErrorMessage(error.join(', '));
                                  } else {
                                    let files = e.target.files;
                                    let newCitationRequest = {
                                      ...citationRequest,
                                    };
                                    for (let i = 0; i < files.length; i++) {
                                      files[i].file_name = files[i].name;
                                      error.push(
                                        files[i].name + 'is too large'
                                      );
                                      newCitationRequest[field.field].push(
                                        files[i]
                                      );
                                    }

                                    setCitationRequest(newCitationRequest);
                                    return {
                                      citationRequest: {
                                        ...citationRequest,
                                        [field.field]:
                                          newCitationRequest[field.field],
                                      },
                                    };
                                  }
                                }}
                              />
                              {errorField[field.field] && (
                                <p className={styles['warning-message']}>
                                  {errorField[field.field]['error_message']}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
        if (field.type === 'file') {
          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <p className={styles['input-text-title']}>
                {field.title}
                {field.required && <span>*</span>}
              </p>
              <br />
              <span className={styles['file-size-text']}>
                File Size Limit 250MB
              </span>
              {citationRequest[field.field] &&
                citationRequest[field.field] !== 'None' &&
                citationRequest[field.field].map((citationRequestFile, idx) => {
                  return (
                    <File
                      key={idx}
                      file={citationRequestFile}
                      id={`new-${idx}`}
                      getFileAndDownload={props.getFileAndDownload}
                      onDelete={(e, id, fieldName = field.field) => {
                        if (citationRequestFile.id) {
                          onDeleteAttachment(e, citationRequestFile, fieldName);
                        } else {
                          onDeleteNewAttachment(e, idx, fieldName);
                        }
                        citationFileInputRef.current.value = '';
                      }}
                      deletePermission={!isCMStaff}
                    />
                  );
                })}
              <br />
              {fileLimitExceeded && (
                <span className={styles['file-error']}>
                  File limit exceeded
                </span>
              )}
              <p className={styles['file-padding']}>Upload a new file</p>
              <input
                type={field.type}
                name={field.field}
                accept={acceptedFileTypes}
                multiple
                ref={citationFileInputRef}
                onChange={e => {
                  let error = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    //If file size is greater than 250MB
                    if (e.target.files[i].size > 250000000) {
                      error.push(e.target.files[i].name + ' is too large');
                    }
                  }
                  if (error.length) {
                    e.target.value = null;
                    setFileLimitExceeded(field.field);
                    // setFileErrorMessage(error.join(', '))
                  } else {
                    let files = e.target.files;
                    for (let i = 0; i < files.length; i++) {
                      files[i].file_name = files[i].name;
                      error.push(files[i].name + 'is too large');
                      citationRequest[field.field].push(files[i]);
                    }
                    setCitationRequest({
                      ...citationRequest,
                      [field.field]: citationRequest[field.field],
                    });
                  }
                }}
              />
            </div>
          );
        } else if (['co_signers'].includes(field.field)) {
          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <TagsDisplay
                className={
                  !errorField[field.field]
                    ? styles['input-text-area']
                    : styles['input-text-area-warning']
                }
                currentList={citationRequest.co_signers}
                onAddTarget={obj[field.field].onAddTarget}
                onDeleteTarget={obj[field.field].onDeleteTarget}
                inputTitle={obj[field.field].inputTitle}
                required={obj[field.field].required}
                warningMessage={obj[field.field].warningMessage}
                param={obj[field.field].param}
                reference={refs[field.field]}
                dropdownList={obj[field.field].dropdownList}
                allowNew={field.field === 'submitting_agency'}
                number={obj[field.field].number}
              />
              {errorField[field.field] && (
                <p className={styles['warning-message']}>
                  {errorField[field.field]['error_message']}
                </p>
              )}
            </div>
          );
        } else if (field.type === 'select') {
          let disable = isCMStaff ? !field.cmStaffUpdateEditable : false;
          if (field.field === 'reason') {
            disable = true;
          }
          if (field.field === 'council_member') {
            field.options = councilMembers(props.userList).map(
              cm => cm.full_name
            );
          }
          if (field.field === 'contact_person') {
            field.options = contactPersonList.map(user => user.full_name);
          }
          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <p className={styles['input-text-title']}>
                {field.title}
                {field.required && <span>*</span>}
              </p>
              <select
                className={
                  !errorField[field.field]
                    ? 'form-control'
                    : styles['input-text-area-warning']
                }
                value={
                  citationRequest[field.field]
                    ? citationRequest[field.field]
                    : ''
                }
                ref={refs[field.field]}
                disabled={disable}
                onChange={e => {
                  if (field.field === 'council_member') {
                    let associated_cm = props.userList.filter(
                      user => user.full_name === e.target.value
                    )[0];
                    let availableContacts = props.userList.filter(user => {
                      if (user.under_council_member) {
                        return user.under_council_member === associated_cm.id;
                      } else {
                        return user.id === associated_cm.id;
                      }
                    });

                    if (
                      availableContacts.every(
                        ele => ele.id !== props.userProfile.id
                      )
                    ) {
                      availableContacts.push(props.userProfile);
                    }

                    setContactPersonList(availableContacts);
                    setCitationRequest({
                      ...citationRequest,
                      council_member: e.target.value,
                    });
                  } else if (field.field === 'contact_person') {
                    setCitationRequest({
                      ...citationRequest,
                      [field.field]: e.target.value,
                    });
                  } else {
                    setCitationRequest({
                      ...citationRequest,
                      [field.field]: e.target.value,
                    });
                  }
                }}
                multiple={field.multiple}>
                {field.options &&
                  field.options.map((op, idx1) => (
                    <option key={idx1} value={op.full_name}>
                      {op.toString()}
                    </option>
                  ))}
              </select>
              {errorField[field.field] && (
                <p className={styles['warning-message']}>
                  {errorField[field.field]['error_message']}
                </p>
              )}
            </div>
          );
        } else if (field.type === 'checkbox') {
          let { id, under_cm = under_council_member } = props.userProfile,
            speaker = props.userList.find(
              ele => ele.role === 'Speaker/Council Member'
            ).id;
          if (
            field.field === 'is_speaker_citation' &&
            speaker !== id &&
            under_cm !== null &&
            speaker !== under_cm
          )
            return null;
          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <p className={styles['input-text-title']}>
                {field.title}
                {field.required && <span>*</span>}
              </p>
              <div className={styles['radio-buttons']}>
                <div className='mb-3' name={field.field}>
                  <input
                    type='radio'
                    id={`yes-${field.field}`}
                    name={field.field}
                    checked={citationRequest[field.field]}
                    onChange={e => {
                      setCitationRequest({
                        ...citationRequest,
                        [field.field]: true,
                      });
                    }}
                    className={styles['checkbox']}
                  />
                  <label htmlFor={`yes-${field.field}`}>Yes</label>
                  <input
                    type='radio'
                    id={`no-${field.field}`}
                    name={field.field}
                    checked={
                      !citationRequest[field.field] &&
                      typeof citationRequest[field.field] !== 'undefined'
                    }
                    onChange={e => {
                      setCitationRequest({
                        ...citationRequest,
                        [field.field]: false,
                      });
                    }}
                    className={styles['checkbox']}
                  />
                  <label htmlFor={`no-${field.field}`}>No</label>
                  {errorField[field.field] && (
                    <p className={styles['warning-message']}>
                      {errorField[field.field]['error_message']}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        } else {
          let disable = isCMStaff ? !field.cmStaffUpdateEditable : false;
          if (field.field === 'honoree') {
            disable = true;
          }

          return (
            <div className='mb-3' key={`${page}-${idx}`}>
              <p className={styles['input-text-title']}>
                {customTitle.length > 0 ? customTitle : field.title}
                {field.required && <span>*</span>}
              </p>
              <input
                className={`form-control ${
                  !errorField[field.field]
                    ? styles['input-text-area']
                    : styles['input-text-area-warning']
                }`}
                type={field.type}
                name={field.field}
                value={citationRequest[field.field] || ''}
                maxlength={
                  (['honoree', 'other'].includes(field.field) && 255) ||
                  (field.field === 'notes' && 1600)
                }
                onChange={e => {
                  setCitationRequest({
                    ...citationRequest,
                    [field.field]: e.target.value,
                  });
                }}
                disabled={disable}
                ref={refs[field.field]}
              />
              {errorField[field.field] && (
                <p className={styles['warning-message']}>
                  {errorField[field.field]['error_message']}
                </p>
              )}
            </div>
          );
        }
      });
    return newDdlDisplay;
  };

  let leftSideLast = citationRequestFields
    .map(ele => ele.field)
    .indexOf('co_signers');

  let leftFields = citationRequestFields.slice(0, leftSideLast);
  let rightFields = citationRequestFields.slice(leftSideLast);

  return (
    <form>
      <div
        className={`${styles['modal-header']} ${styles['modal-header-citations']}`}>
        <div>
          <span className='popup-detail-title'>Update Citation Request</span>
        </div>
      </div>
      {props.citationRequestStatus === 'fail' && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <p>Failed to update this citation request</p>
        </div>
      )}
      {errorsPresent && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <div>
            <p>There was an error with your form submission.</p>
            <p>Please correct the highlighted field(s) below</p>
          </div>
        </div>
      )}
      {props.isLoading === true && (
        <div className={styles['fixed-loading-container']}>
          <img
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            className='loading-image'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {props.updateCitationStatus !== 'success' && (
        <>
          <div className={styles['form-container']}>
            <div className={styles['report-fields']}>
              <div className={styles['report-field-column']}>
                {generateFields(leftFields)}
              </div>
              <div className={styles['report-field-column']}>
                {generateFields(rightFields)}
              </div>
            </div>
            <p className={styles['disclaimer']}>
              Event and Production Services will provide a high-res PDF for
              printing by Council Member staff in their respective offices. E&PS
              can provide guidance on selecting the correct paper for printing.
              Please contact Administrative Services for red citation folders.
            </p>
          </div>
          <div className={styles['modal-footer']}>
            <button
              type='button'
              className={`submit-button ${styles['cancel-button']}`}
              onClick={() => {
                props.changeCitationRequestDisplay('detail');
                // props.changeCitationRequestPopupWindow(false);
              }}>
              Cancel
            </button>
            <button
              type='button'
              className={`submit-button ${styles['footer-button']}`}
              onClick={e => onSubmit(e, citationRequestFields)}>
              Submit
            </button>
          </div>
        </>
      )}
      {props.updateCitationStatus === 'success' && (
        <div
          className={`alert alert-success ${styles['alert-danger-banner']} ${styles['success']}`}
          role='alert'>
          <LargeCheckMarkIcon />
          <div>
            <p>The citation request has been successfully updated!</p>
            <p>Page will refresh shortly.</p>
          </div>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    committees: state.userReducer.committees,
    userList: state.userReducer.userList,
    allUserList: state.userReducer.allUserList,
    savedProgress: state.citationRequestReducer.savedProgress,
    selectedCitationRequest:
      state.citationRequestReducer.selectedCitationRequest,
    updateCitationStatus: state.citationRequestReducer.updateCitationStatus,
    isLoading: state.citationRequestReducer.isLoading,
  };
};

const mapDispatchToProps = {
  ...actions,
  getFileAndDownload,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateCitationRequest);
