import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import * as actions from '../../actions/lsRequestAction';
import Modal from 'react-modal';
import UpdateLsRequestPopup from './UpdateLsRequestPopup';
import CreateNewLsRequest from './CreateNewLsRequest';
import BillHistory from './BillHistory';
import styles from '../../styles/ls-request/LsRequestServicesPopup.module.scss';
import LsRequestStatus from './LsRequestStatus';

const LsRequestServicesPopup = props => {
  const [isClosing, setIsClosing] = useState(false);

  // This function is called when the modal is requested to close
  const onClose = () => {
    setIsClosing(true); // Start closing animation
    // Adjust the body overflow after the closing animation completes
    setTimeout(() => {
      setIsClosing(false); // Reset closing state
      props.openOrCloseLsRequestServicePopup(false); // Close the modal
      document.body.style.overflow = 'auto'; // Restore body scroll
    }, 300); // Ensure this matches the CSS animation duration
  };

  // When the modal is opened or closed, adjust the body overflow accordingly
  if (props.lsRequestServicePopupStatus) {
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
  } else if (!isClosing) {
    document.body.style.overflow = 'auto'; // Allow scrolling when modal is fully closed
  }

  const getModalStyle = modalType => {
    // Select the appropriate modal style based on the type of modal to be displayed
    if (modalType === 'duplicate') {
      return styles['duplicate-checker-style'];
    } else if (
      ['create', 'statusChange', 'createSubLS', 'update'].includes(modalType)
    ) {
      return styles['create-new-lsr-style'];
    } else {
      return styles['custom-styles'];
    }
  };

  // Apply the appropriate class based on the modal's current state (opening or closing)
  const modalClassName = `${styles['default-style']} ${getModalStyle(
    props.currentDisplay
  )} ${isClosing ? styles.modalSlideDown : styles.modalSlideUp}`;
  // Render the modal
  return createPortal(
    <Modal
      isOpen={props.lsRequestServicePopupStatus}
      onRequestClose={onClose}
      className={modalClassName}
      overlayClassName={styles.modalOverlay}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}>
      {props.currentDisplay === 'update' && (
        <UpdateLsRequestPopup onClose={onClose} />
      )}
      {props.currentDisplay === 'create' && (
        <CreateNewLsRequest onClose={onClose} />
      )}
      {props.currentDisplay === 'createSubLS' && (
        <CreateNewLsRequest onClose={onClose} subLS />
      )}
      {props.currentDisplay === 'history' && <BillHistory onClose={onClose} />}
      {props.currentDisplay === 'statusChange' && (
        <LsRequestStatus onClose={onClose} />
      )}
    </Modal>,
    document.getElementById('portal-root')
  );
};

const mapStateToProps = state => ({
  lsRequestServicePopupStatus:
    state.lsRequestsReducer.lsRequestServicePopupStatus,
});

export default connect(mapStateToProps, actions)(LsRequestServicesPopup);
