import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  subject_id: 0,
  subject_error: false,
  title: '',
  subjects: [],
  connections: [],
  subject_loading: true,
  pages: [],
  page_loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.LOAD_SUBJECTS:
      return { ...state, subject_id: action.subject_id };

    case wikiPageActions.CHECKING_SUBJECTS:
      return { ...state, subject_loading: action.status };

    case wikiPageActions.STORE_SUBJECT:
      return {
        ...state,
        title: action.subject.title,
        subjects: action.subject.children_data,
        pages: action.subject.page_data,
        connections: action.subject.connection_data,
      };

    case wikiPageActions.STORE_SUBJECTS:
      return { ...state, subjects: action.subjects.results };

    case wikiPageActions.CHECKING_PAGES:
      return { ...state, page_loading: action.status };

    case wikiPageActions.STORE_PAGES:
      return { ...state, pages: action.pages };

    case wikiPageActions.SUBJECT_PAGE_ERROR:
      return { ...state, subject_error: action.status };

    default:
      return state;
  }
}
