export const myTasksActionType = {
  GET_ALL_MY_LS: 'GET_ALL_MY_LS',
  SAVE_MY_LS: 'SAVE_MY_LS',
  IS_LOADING_LS: 'IS_LOADING_LS',
  GET_MY_ACTIVE_LS: 'GET_MY_ACTIVE_LS',
  SAVE_MY_ACTIVE_LS: 'SAVE_MY_ACTIVE_LS',
  CREATE_PAGE_REVIEW: 'CREATE_PAGE_REVIEW',
  LOAD_PAGE_REVIEW: 'LOAD_PAGE_REVIEW',
  LOAD_ALL_PAGE_REVIEWS: 'LOAD_PAGE_REVIEWS',
  LOAD_MY_PAGE_REVIEWS: 'LOAD_MY_PAGE_REVIEWS',
  STORE_PAGE_REVIEW: 'STORE_PAGE_REVIEW',
  STORE_ALL_PAGE_REVIEWS: 'STORE_ALL_PAGE_REVIEWS',
  STORE_MY_PAGE_REVIEWS: 'STORE_MY_PAGE_REVIEWS',
  DELETE_PAGE_REVIEW: 'DELETE_PAGE_REVIEW',
  IS_LOADING_MY_PAGE_REVIEWS: 'IS_LOADING_MY_REVIEWS',
  IS_LOADING_ALL_PAGE_REVIEWS: 'IS_LOADING_ALL_PAGE_REVIEWS',
  IS_LOADING_PAGE_REVIEW: 'IS_LOADING_PAGE_REVIEW',
  PAGE_REVIEW_ERROR: 'PAGE_REVIEW_ERROR',
  LS_FILTER_BY_DAYS: 'LS_FILTER_BY_DAYS',
};
export const filterByDay = days => {
  return { type: myTasksActionType.LS_FILTER_BY_DAYS, days };
};
// Action for fetching all my ls requests
export const getAllMyLs = () => {
  return { type: myTasksActionType.GET_ALL_MY_LS };
};

// Action for saving all my ls
export const saveMyLs = ls => {
  return { type: myTasksActionType.SAVE_MY_LS, ls };
};

// Action for fetching all my active requests
export const getMyActiveLs = () => {
  return { type: myTasksActionType.GET_MY_ACTIVE_LS };
};

// Action for saving all my active requests
export const saveMyActiveLs = ls => {
  return { type: myTasksActionType.SAVE_MY_ACTIVE_LS, ls };
};

//Action for checking it is under loading right now
export const isLoadingLs = status => {
  return { type: myTasksActionType.IS_LOADING_LS, status };
};

export const loadPageReview = page_review_id => {
  return { type: myTasksActionType.LOAD_PAGE_REVIEW, page_review_id };
};

export const loadAllPageReviews = () => {
  return { type: myTasksActionType.LOAD_ALL_PAGE_REVIEWS };
};

export const loadMyPageReviews = () => {
  return { type: myTasksActionType.LOAD_MY_PAGE_REVIEWS };
};

export const storePageReview = pageReview => {
  return { type: myTasksActionType.STORE_PAGE_REVIEW, pageReview };
};

export const storeAllPageReviews = pageReviews => {
  return { type: myTasksActionType.STORE_ALL_PAGE_REVIEWS, pageReviews };
};

export const storeMyPageReviews = pageReviews => {
  return { type: myTasksActionType.STORE_MY_PAGE_REVIEWS, pageReviews };
};

export const isLoadingMyPageReviews = status => {
  return { type: myTasksActionType.IS_LOADING_MY_PAGE_REVIEWS, status };
};

export const isLoadingAllPageReviews = status => {
  return { type: myTasksActionType.IS_LOADING_ALL_PAGE_REVIEWS, status };
};

export const isLoadingPageReview = status => {
  return { type: myTasksActionType.IS_LOADING_PAGE_REVIEW, status };
};

export const pageReviewError = error => {
  return { type: myTasksActionType.PAGE_REVIEW_ERROR, error };
};
