import { divisionList } from './constants';

const ProcRequestFields = [
  {
    title: 'Council Member',
    field: 'council_member',
    type: 'select',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: true,
  },
  {
    title: 'Requested By',
    field: 'requested_by',
    type: 'text',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: false,
  },
  {
    title: 'Contact Person',
    field: 'contact_person',
    type: 'select',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Honoree',
    field: 'honoree',
    type: 'text',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title: 'Reason',
    field: 'reason',
    type: 'select',
    options: [
      'Retirement',
      'Birthdays (75+)',
      'Anniversaries (ending in 0 or 5)',
      // 'Stated Mtg Ceremonial (Currently Suspended)',
      'City Council Chamber Event',
      'Other (Explain below)',
    ],
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title: 'Other',
    field: 'other',
    type: 'textarea',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Notes',
    field: 'notes',
    type: 'textarea',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: false,
  },
  {
    title: 'Status',
    field: 'status',
    type: 'select',
    options: [
      'Pending',
      'Approved',
      'In-Progress',
      'Completed',
      'Delivered',
      'Withdrawn',
    ],
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title: 'Date of Event',
    field: 'date_of_event',
    type: 'date',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title:
      'Date Proclamation is Needed (if different from Event Date, otherwise leave blank)',
    field: 'date_proc_needed',
    type: 'date',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Proclamation Draft',
    field: 'proclamation_draft',
    type: 'file',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Event Info Attachment',
    field: 'event_info_attachment',
    type: 'file',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Honoree Info Attachment',
    field: 'honoree_info_attachment',
    type: 'file',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Which CMs, if any, should be invited to co-sign?',
    field: 'co_signers',
    type: 'multi-select',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
    multiple: true,
  },
  {
    title: 'Caucuses',
    field: 'caucuses',
    type: 'multi-select',
    options: divisionList.map(caucus => caucus.name),
    vertSize: 4,
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
    multiple: true,
  },
  {
    title: 'Borough Delegations',
    field: 'boroughs',
    type: 'multi-select',
    options: ['Manhattan', 'Brooklyn', 'Bronx', 'Queens', 'Staten Island'],
    vertSize: 2,
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
    multiple: true,
  },
];

export default ProcRequestFields;
