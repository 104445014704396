import { myTasksActionType } from '../actions/myTasksAction';

const initialState = {
  un_reviewed: [],
  my_pages: [],
  current_review: null,
  review_diff: '',
  loadingMyReviews: null,
  loadingAllReviews: null,
  loadingPageReview: null,
  error: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case myTasksActionType.STORE_ALL_PAGE_REVIEWS:
      return { ...state, un_reviewed: action.pageReviews };

    case myTasksActionType.STORE_MY_PAGE_REVIEWS:
      return { ...state, my_pages: action.pageReviews };

    case myTasksActionType.STORE_PAGE_REVIEW:
      return {
        ...state,
        current_review: action.pageReview.page_review,
        review_diff: action.pageReview.review_diff,
      };

    case myTasksActionType.IS_LOADING_MY_PAGE_REVIEWS:
      return { ...state, loadingMyReviews: action.status };

    case myTasksActionType.IS_LOADING_ALL_PAGE_REVIEWS:
      return { ...state, loadingAllReviews: action.status };

    case myTasksActionType.IS_LOADING_PAGE_REVIEW:
      return { ...state, loadingPageReview: action.status };

    case myTasksActionType.PAGE_REVIEW_ERROR:
      return { ...state, error: action.error };
    default:
      return state;
  }
}
