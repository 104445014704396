import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  tags: [],
  tag_id: 0,
  fail: '',
  success: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.STORE_ALL_TAGS:
      return { ...state, tags: action.tags.results };

    case wikiPageActions.TAG_FORM_FAIL:
      return { ...state, fail: action.message };

    case wikiPageActions.TAG_FORM_SUCCESS:
      return { ...state, success: action.message };

    case wikiPageActions.SELECT_TAG:
      return { ...state, tag_id: action.tag };

    default:
      return state;
  }
}
