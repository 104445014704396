import { divisionList } from './constants';

const days = [];
for (let i = 1; i < 32; i++) {
  days.push(i + '');
}

const CitationRequestFields = [
  {
    title: 'Council Member',
    field: 'council_member',
    type: 'select',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: true,
  },
  {
    title: 'Citation Is On Behalf Of Speaker',
    field: 'is_speaker_citation',
    type: 'checkbox',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: false,
  },
  {
    title: 'Requested By',
    field: 'requested_by',
    type: 'text',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: false,
  },
  {
    title: 'Contact Person',
    field: 'contact_person',
    type: 'select',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: true,
  },

  {
    title: 'Honoree',
    field: 'honoree',
    type: 'text',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title: 'Other',
    field: 'other',
    type: 'textarea',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: false,
  },
  {
    title: 'Notes',
    field: 'notes',
    type: 'textarea',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: false,
    required: false,
  },
  {
    title: 'Status',
    field: 'status',
    type: 'select',
    options: [
      'Pending',
      'Approved',
      'In-Progress',
      'Completed',
      'Delivered',
      'Withdrawn',
    ],
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title: 'Date of Event',
    field: 'date_of_event',
    type: 'date',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: true,
  },
  {
    title: 'Date Citation is Needed',
    field: 'date_citation_needed',
    type: 'date',
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Council Member(s) Co-signing',
    field: 'co_signers',
    type: 'multi-select',
    vertSize: 4,
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
    multiple: true,
  },
  {
    title: 'Citation Custom Text',
    field: 'citation_custom_text_attachment',
    type: 'radio-select',
    options: [
      {
        'type': 'sample',
        'text': 'Item #4a-General Citation Sample',
        'download_link':
          '/documents/Item%20%234a-General%20Citation%20Sample.pdf',
      },
      {
        'type': 'sample',
        'text': 'Item #1a–Graduation Certificate Sample',
        'download_link':
          '/documents/Item%20%231a–Graduation%20Certificate%20Sample.pdf',
      },
      {
        'type': 'sample',
        'text': 'Item #2a–Recognition Certificate Sample',
        'download_link':
          '/documents/Item%20%232a–Recognition%20Certificate%20Sample.pdf',
      },
      {
        'type': 'sample',
        'text': 'Item #9-Organization Citation Sample',
        'download_link':
          '/documents/Item%20%239-Organization%20Citation%20Sample.pdf',
      },
      {
        'type': 'file',
        'text':
          'Upload custom text file. Must be under 200 words. See sample #4a for format - citation language must include a "Whereas," and "Resolved," sections.',
      },
    ],
    cmStaffCreateEditable: true,
    cmStaffCreateViewable: true,
    cmStaffUpdateEditable: true,
    cmStaffUpdateViewable: true,
    required: false,
  },
  {
    title: 'Citation',
    field: 'final_citation',
    type: 'file',
    cmStaffCreateEditable: false,
    cmStaffCreateViewable: false,
    cmStaffUpdateEditable: false,
    cmStaffUpdateViewable: true,
    required: false,
  },
];

export default CitationRequestFields;
