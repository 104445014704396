import React, { useEffect, useRef, Component } from 'react';
import styles from '../../styles/report-tracking/CommitteeCheckboxMenu.module.scss';
import { connect } from 'react-redux';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

// class CommitteeCheckboxMenu extends Component {
const CommitteeCheckboxMenu = props => {
  const {
    checkAll,
    committees,
    onCheckOrUncheckAllCheckbox,
    preset,
    toggleCommittee,
    toggleCommitteeMenu,
    togglePreset,
    userProfile,
  } = props;
  const outsideClickRef = useRef(null);
  // useEffect hook for component did mount
  useEffect(() => {
    function handleClick(e) {
      let columnMenuButton = document.getElementById('PopoverCommittees');
      if (
        outsideClickRef.current &&
        !outsideClickRef.current.contains(e.target)
      ) {
        if (columnMenuButton && !columnMenuButton.contains(e.target)) {
          props.toggleCommitteeMenu();
        }
      }
    }

    document.addEventListener('mouseup', handleClick, false);
    // component did unmount
    return () => {
      document.removeEventListener('mouseup', handleClick, false);
    };
  }, []);

  const presetsOptions = () => {
    return [
      `${userProfile.username}`,
      `${userProfile.username}-preset-1`,
      `${userProfile.username}-preset-2`,
      `${userProfile.username}-preset-3`,
    ].map((value, idx) => {
      return (
        <div
          key={`${idx}_${value}`}
          className={`${styles['column-checkbox-container']} col-6 col-sm-3`}>
          <input
            className={styles['column-checkbox']}
            id={
              value === userProfile.username
                ? 'no-preset'
                : `opReq-committee-${value}`
            }
            type='radio'
            name='presets'
            value={`opReq-committee-${value}`}
            checked={preset === `opReq-committee-${value}`}
            readOnly
          />
          <label
            className={styles['column-checkbox-label']}
            htmlFor={
              value === userProfile.username
                ? 'no-preset'
                : `opReq-committee-${value}`
            }
            onClick={() => togglePreset(`opReq-committee-${value}`)}
          />
          <label
            className={styles['column-checkbox-text-label']}
            htmlFor={
              value === userProfile.username
                ? 'no-preset'
                : `opReq-committee-${value}`
            }
            onClick={() => togglePreset(`opReq-committee-${value}`)}>
            {value === userProfile.username
              ? 'DEFAULT'
              : value
                  .replace(`${userProfile.username}-`, '')
                  .split('-')
                  .join(' ')
                  .toUpperCase()}
          </label>
        </div>
      );
    });
  };

  const dropDownCommitteeMenu = committees.map((com, idx) => {
    return (
      <li key={idx} className={styles['column-checkbox-container']}>
        <input
          className={styles['column-checkbox']}
          id={`cbx-${idx}`}
          type='checkbox'
          checked={com.show}
          readOnly
        />
        <label
          className={styles['column-checkbox-label']}
          htmlFor={`cbx-${idx}`}
          onClick={() => {
            toggleCommittee(com.committee_name);
          }}></label>
        <label
          id={`column-tooltip-${idx}`}
          className={`${styles['column-checkbox-text-label']}`}
          htmlFor={`cbx-${idx}`}
          onClick={() => {
            toggleCommittee(com.committee_name);
          }}>
          {com.committee_name.slice(0, 20) +
            (com.committee_name.length > 20 ? '...' : '')}
        </label>

        {com.committee_name.length > 20 && (
          <UncontrolledPopover
            innerClassName={styles['popover-container']}
            className={styles['popover-outer-container']}
            placement='right'
            target={`column-tooltip-${idx}`}
            fade={false}
            trigger='hover'>
            <PopoverBody>{com.committee_name}</PopoverBody>
          </UncontrolledPopover>
        )}
      </li>
    );
  });

  const checkAllOption = (
    <div className={`${styles['column-checkbox-container']} col-12`}>
      <input
        className={styles['column-checkbox']}
        id='cbx-all'
        type='checkbox'
        checked={checkAll}
        readOnly
      />
      <label
        className={styles['column-checkbox-label']}
        htmlFor='cbx-all'
        onClick={onCheckOrUncheckAllCheckbox}></label>
      <label
        className={styles['column-checkbox-text-label']}
        htmlFor='cbx-all'
        onClick={onCheckOrUncheckAllCheckbox}>
        Check All
      </label>
    </div>
  );

  return (
    <div ref={outsideClickRef} className={`${styles['column-selector-container']} row`}>
      {presetsOptions()}
      <hr className='col' style={{ margin: '.25rem 0 .75rem 0' }} />
      <div className={styles['column-selector-wrapper']}>
        <ul>
          {checkAllOption}
          {dropDownCommitteeMenu}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    committeeList: state.userReducer.committees,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(CommitteeCheckboxMenu);
