import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/WikiPage.module.scss';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';
import { shortDisclaimer } from '../../services/constants';
import domToReact from 'html-react-parser/lib/dom-to-react';
import * as actions from '../../actions/wikiPageAction';
import ErrorPage from './ErrorPage';
import { useParams } from 'react-router-dom';

const PrinterFriendlyPage = props => {
  const {
    author,
    content,
    description,
    drafters,
    loadPage,
    match,
    memo_regarding,
    page_error,
    page_id,
    pages,
    review,
    storePage,
    subject_error,
    tags,
    title,
    users,
  } = props;

  const { pid } = useParams();
  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    loadPage(pid, 'diff');

    return () => {
      storePage({ id: 0, content: '' });
    };
  }, [loadPage, storePage, pid]);

  function pageInSubject() {
    let check = pages.find(page => {
      return page.id === page_id;
    });
    return check ? true : false;
  }

  function getLegDirector() {
    let director = users.filter(user => {
      return user.role === 'Director of Legislative Division' && user.is_active;
    });
    if (director.length === 1) {
      return director[0].full_name.toUpperCase();
    } else {
      return '';
    }
  }

  let inSubject = true;
  if (pages.length > 0) {
    setTimeout(() => (inSubject = pageInSubject()), 1000);
  }

  const parserOptions = {
    replace: domNode => {
      if (domNode.attribs && domNode.attribs.id && !domNode.class) {
        switch (domNode.name) {
          case 'h1':
            return (
              <h1 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h1>
            );
          case 'h2':
            return (
              <h2 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h2>
            );
          case 'h3':
            return (
              <h3 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h3>
            );
          case 'h4':
            return (
              <h4 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h4>
            );
          case 'h5':
            return (
              <h5 id={domNode.attribs.id} className='anchor'>
                {domToReact(domNode.children, parserOptions)}
              </h5>
            );
          default:
            return domNode;
        }
      }
    },
  };

  let tagDisplay = <div />;
  if (tags) {
    tagDisplay = tags.map((tag, idx) => {
      return (
        <Link
          className={styles['tag-link']}
          key={idx}
          to={'/CLEX/tag/' + tag.id}>
          #{tag.name}
        </Link>
      );
    });
  }

  let draftersList = users.filter(user => drafters.includes(user.id));
  let drafterNames = draftersList.map(draft => draft.full_name);
  let draftersUI = drafterNames.join(',');

  return (
    <div
      className={`${styles['page']}`}
      style={{ margin: '0 auto', padding: '0 10px' }}>
      <style>{`@media print {body{display: block !important;}.main-container{height:auto !important;}}`}</style>
      {page_id && content && !subject_error && inSubject ? (
        <div className={styles['watermark']} style={{ height: 'inherit' }}>
          <div className={styles['disclaimer']}>{shortDisclaimer}</div>
          {review && (
            <div className='alert alert-warning' role='alert'>
              This page is currently in review. All highlighted changes have not
              been approved yet.
            </div>
          )}
          <div className={styles['wiki-header']}>
            <div>PRIVILEGED MEMORANDUM</div>
            <div className={styles['memo-message']}>To: {getLegDirector()}</div>
            <div className={styles['memo-message']}>
              From: {author.full_name.toUpperCase()}, {author.role} (as updated
              subsequently per log)
            </div>
            <div
              className={`${styles['drafter-names']} ${styles['memo-message']}`}>
              {draftersUI}
            </div>
            <div className={styles['memo-message']}>
              Re: {memo_regarding ? Parser(memo_regarding) : title}
            </div>
          </div>
          <div className={styles['div-row']}>
            <h1 className={styles['title']}>{title}</h1>
          </div>
          <div>{tagDisplay}</div>
          <br />
          <div>{Parser(description)}</div>
          <br />
          <div>{Parser(content, parserOptions)}</div>
        </div>
      ) : (
        ''
      )}
      {!page_id && !page_error && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            align='middle'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {(page_error || subject_error || !inSubject) && (
        <ErrorPage type='page' idError={!inSubject} />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    author: state.wikiPageReducer.author,
    content: state.wikiPageReducer.content,
    description: state.wikiPageReducer.description,
    drafters: state.wikiPageReducer.drafters,
    page_error: state.wikiPageReducer.page_error,
    page_id: state.wikiPageReducer.page_id,
    pages: state.wikiSubjectReducer.pages,
    review: state.wikiPageReducer.review,
    subject_error: state.wikiSubjectReducer.subject_error,
    tags: state.wikiPageReducer.tags,
    title: state.wikiPageReducer.title,
    users: state.userReducer.userList,
  };
};

export default connect(mapStateToProps, actions)(PrinterFriendlyPage);
