import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/CommentBox.module.scss';
import * as actions from '../../actions/wikiPageAction';
import { Collapse, Form, FormGroup, Input } from 'reactstrap';

const CommentBox = props => {
  const [collapse, setCollapse] = useState(false);
  const [content, setContent] = useState('');

  function onCommentSubmit(e) {
    e.preventDefault();
    props.addComment(props.page_id, content);
    setContent('');
  }

  function onCommentChange(e) {
    e.preventDefault();
    setContent(e.target.value);
  }

  function toggle() {
    setCollapse(!collapse);
  }

  const comments = props.comments.map((comment, idx) => {
    let date = new Date(comment.created_at);
    let options = { dateStyle: 'full', timeStyle: 'long' };
    return (
      <div key={idx} className={styles['comment']}>
        <p className={styles['comment-content']}>
          <strong className={styles['comment-user']}>
            <a href={`/staff/${comment.userProfile.id}`}>
              {comment.userProfile.full_name}
            </a>
          </strong>
          <br />
          {comment.content}
        </p>
        <small className={styles['comment-timestamp']}>
          {date.toLocaleString('en-US', options)}
        </small>
      </div>
    );
  });

  const hideShowComments = collapse ? 'Hide Comments' : 'Display Comments';

  return (
    <>
      <div className={styles['commentRow']} id='comments'>
        <h2>Comments</h2>
        <button
          className={`btn btn-primary ${styles['comments-button']}`}
          onClick={() => toggle()}>
          {hideShowComments}
        </button>
      </div>

      <hr />

      <Collapse isOpen={collapse}>
        <div className={styles['comments']}>
          <>{comments}</>
          <br />
          {props.userProfile.permissions.wiki.add_comment && (
            <Form onSubmit={e => onCommentSubmit(e)}>
              <FormGroup>
                <Input
                  value={content}
                  onChange={e => onCommentChange(e)}
                  type='text'></Input>
              </FormGroup>
              <input className='btn btn-primary' type='submit' />
            </Form>
          )}
        </div>
      </Collapse>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    page_id: state.wikiPageReducer.page_id,
  };
};

export default connect(mapStateToProps, actions)(CommentBox);
