import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/PageForm.module.scss';
import { Input } from 'reactstrap';
import { Formik, Form, Field, FieldArray } from 'formik';
import axios from 'axios';
import { url } from '../../config';
import SectionFormBar from './SectionFormBar';
import FormTextEditor from './FormTextEditor';
import TagsDisplay from '../report-tracking/TagsDisplay';
import Parser from 'html-react-parser';
import * as actions from '../../actions/wikiPageAction';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { PreviewEyeIcon } from '../../services/SvgLibrary';

//Todo: Make the custom input components their own file
const CustomInputComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <>
      <Input
        className={styles['inputMargin']}
        onKeyDown={e => {
          if (e.key === 'Enter') e.preventDefault();
        }}
        type='text'
        name={field.name}
        {...props}
        {...field}
      />
      {touched[field.name] && errors[field.name] && (
        <div className='alert alert-danger' role='alert'>
          {errors[field.name]}
        </div>
      )}
    </>
  );
};

const CustomQuillComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <FormTextEditor
      {...props}
      name={field.name}
      defaultValue={field.value}
      onChange={content => {
        field.onChange({
          target: {
            type: typeof content,
            id: field.name,
            value: content,
            name: field.name,
          },
        });
      }}
      className={styles['quillInput']}
    />
  );
};

const PageForm = props => {
  const [state, setState] = useState({
    sectionHover: false,
    subSectionHover: false,
    review_lock: 0,
    title: '',
    preview: false,
    review: false,
    confirm: false,
    discard: false,
    dirty: false,
    screenWidth: window.innerWidth,
  });

  const navigate = useNavigate();

  const dirtyRef = useRef(false);
  const valuesRef = useRef(null);

  const tagsDisplayRef = useRef(null);

  const unlockPage = () => {
    if (props.edit_lock && props.page > 0) {
      props.pageUpdate({ id: props.page, edit_lock: false }, false);
    } else if (props.review) {
      props.pageReviewUpdate({
        id: props.review.id,
        edit_lock: false,
      });
    }
    if (state.review_lock) {
      props.pageReviewUpdate({
        id: state.review_lock,
        edit_lock: false,
      });
    }
  };

  // Replicating componentDidMount, componentDidUpdate, and componentWillUnmount using useEffect
  useEffect(() => {
    // componentDidMount logic
    props.loadAllSubjects(true);
    props.loadAllTags();
    props.loadAllPages();
    props.wikiPageError(false);
    if (props.review) {
      props.storeEditLock(true);
      if (props.all_subjects !== []) {
        let subjects = subjectTreeClawer(props.all_subjects, {}, '');
        props.storeFlatSubjectTree(subjects);
      }
    }
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', exitCheck);
    window.addEventListener('resize', handleResize);

    // componentWillUnmount logic
    return () => {
      unlockPage();
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', exitCheck);
      window.removeEventListener('resize', handleResize);
      props.storeCurrentPage(-1);
      props.pageFormFail('');
      props.pageFormSuccess('');
      props.storeEditLock(false);
    };
  }, []);

  // Replicating componentDidUpdate logic for specific props changes
  useEffect(() => {
    if (props.all_subjects) {
      let subjects = subjectTreeClawer(props.all_subjects, {}, '');
      props.storeFlatSubjectTree(subjects);
    }
  }, [props.all_subjects]);

  const exitCheck = () => {
    let path = window.location.pathname;
    const crudType = path.slice(path.lastIndexOf('/') + 1);
    if (props.edit_lock || (crudType === 'create' && state.dirty)) {
      unlockPage();
    }
  };

  const alertUser = e => {
    e.returnValue = '';
  };

  const handleResize = () => {
    setState(prevState => ({ ...prevState, screenWidth: window.innerWidth }));
  };

  const onPreview = e => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, preview: !prevState.preview }));
  };

  const errorCheck = errors => {
    if (
      errors.subject ||
      errors.title ||
      errors.committees ||
      errors.description ||
      errors.sections
    ) {
      return true;
    }
    return false;
  };

  const formToHtmlText = (form, full) => {
    let pageText = `<h1>${form.title}</h1>
    <hr/>
    ${
      form.description && form.description !== '<p><br></p>'
        ? form.description
        : ''
    }
    <br/>
    <div>Table of Content</div>
    <br/>`;

    //A recursive function that converts each level of text
    let formParser = (sections, type) => {
      //Sets the current tag and the next type with the current tag
      let tag = '';
      let next = '';
      switch (type) {
        case 'section':
          tag = 'h2';
          next = 'subSection';
          break;
        case 'subSection':
          tag = 'h3';
          next = 'division';
          break;
        case 'division':
          tag = 'h4';
          next = 'subDivision';
          break;
        case 'subDivision':
          tag = 'h5';
          next = '';
          break;
        default:
          break;
      }
      let sectionsText = '';

      if (sections.length > 0) {
        sectionsText = sections
          .map((section, idx) => {
            let subSectionText = formParser(section.sections, next);
            return `<${tag} key=${idx} id=${`section-${section.position}`}>${
              section.title
            }</${tag}>
            ${type === 'section' ? '<hr>' : ''}
            ${
              section.content && section.content !== '<p><br></p>'
                ? section.content
                : type === 'section' && section.sections.length === 0
                ? '<p>Not Applicable</p>'
                : ''
            }
            <br/>
            ${subSectionText && subSectionText}`;
          })
          .join('');
      }
      return sectionsText;
    };

    let sectionText = formParser(form.sections, 'section');

    //Choose between converting just the content or the entire page
    if (full) {
      return pageText + sectionText;
    } else {
      return sectionText;
    }
  };

  const onPageSelect = e => {
    props.storeCurrentPage(e.target.value);
  };

  const inputValueData = (values, selectedPage) => {
    values.subject = selectedPage.subject.map(topic => {
      return props.subject_tree[topic];
    });
    values.author = selectedPage.author.id;
    values.title = selectedPage.title;
    values.memo_regarding = selectedPage.memo_regarding;
    values.committees = selectedPage.committees;
    values.tags = selectedPage.tags;
    values.description = selectedPage.description;

    let content = Parser(selectedPage.content);

    let parsedSections = [];
    parsePageContent(parsedSections, content);
    values.sections = parsedSections;
    props.pageFormSuccess('');

    if (selectedPage.pagereview) {
      props.pageReviewUpdate({
        id: selectedPage.pagereview,
        edit_lock: true,
      });
    }
    if (props.review) {
      props.pageReviewUpdate({
        id: props.review.id,
        edit_lock: true,
      });
    } else {
      props.pageUpdate({ id: props.page, edit_lock: true }, true);
    }
  };

  const onPageLock = (e, values) => {
    e.preventDefault();
    axios
      .get(`${url}/api/wiki/pages/${props.page}/`)
      .then(response => {
        let selectedPage = response.data;
        if (selectedPage.pagereview) {
          setState(prev => ({ ...prev, review_lock: selectedPage.pagereview }));
        }
        if (selectedPage.review_lock && selectedPage.pagereview) {
          inputValueData(values, selectedPage);
        } else if (!selectedPage.review_lock && !selectedPage.pagereview) {
          setState(prev => ({ ...prev, review_lock: 0 }));
          inputValueData(values, selectedPage);
        } else {
          //TODO: Come up with a better solution than not allowing the page to be edited when this error comes up
          if (selectedPage.review_lock) {
            props.pageFormFail(
              'Page Review error, review lock placed without existing page review.'
            );
          } else {
            props.pageFormFail(
              'Page Review error, review exists but no review lock was placed.'
            );
          }
        }
      })
      .catch(error => {
        console.log(error);
        props.pageFormFail(`Failed to get page, ${error}`);
      });
  };

  //Parse through the database content
  const parsePageContent = (sections, content) => {
    let level = 0;
    let headers = ['h1', 'h2', 'h3', 'h4', 'h5'];
    let headerPrev = false;

    content.forEach(tag => {
      if (headers.includes(tag.type)) {
        level = tag.type[1] - 1;
        let currentLevel = getSections(sections, level);
        currentLevel.push({
          title: tag.props.children,
          content: '',
          sections: [],
        });
        headerPrev = true;
      } else {
        if (tag.type !== 'hr' || !headerPrev) {
          let currentLevel = getSections(sections, level);
          currentLevel[currentLevel.length - 1].content +=
            ReactDOMServer.renderToStaticMarkup(tag);
        }
        if (tag.type === 'hr') {
          headerPrev = false;
        }
      }
    });
  };

  //Recursively run through the sections
  const getSections = (sections, level) => {
    if (level <= 1) {
      return sections;
    } else {
      if (sections) {
        return getSections(sections[sections.length - 1].sections, level - 1);
      }
    }
  };

  const onAddSelectedInArray = (selected, values) => {
    let duplicate = values.find(value => value.id === selected.id);
    if (duplicate === undefined) values.push(selected);
  };

  const onDeleteTargetInArray = (targetIdx, values) => {
    values.splice(targetIdx, 1);
    setState({});
  };

  //Update committee list array on change
  const onCommitteeClick = (committee, checked, values) => {
    if (checked) {
      values.committees.push(parseInt(committee));
    } else {
      let index = values.committees.indexOf(parseInt(committee));
      if (index >= 0) {
        values.committees.splice(index, 1);
      }
    }
  };

  //Compute the subject path for each subject
  const subjectTreeClawer = (subjects, list, path) => {
    if (Array.isArray(subjects)) {
      subjects.forEach(subject => {
        let pathSplit = path.split('/');
        if (pathSplit.length > 7) {
          pathSplit.splice(1, 2, '...');
        }
        if (!path || path.length) {
          list[subject.id] = {
            title: subject.title,
            id: subject.id,
            path: pathSplit.join('/') + subject.title,
          };
        } else {
          list[subject.id] = {
            title: subject.title,
            id: subject.id,
            path: pathSplit.join('/') + subject.title + '/',
          };
        }
        if (subject.children_data) {
          subjectTreeClawer(
            subject.children_data,
            list,
            pathSplit.join('/') + subject.title + '/'
          );
        }
      });
    }
    return list;
  };

  const deleteErrorMessage = () => {
    if (state.review_lock) {
      return "This page is currently being reviewed, you can't delete the page in this state.";
    } else if (props.fail) {
      return props.fail;
    } else {
      return 'Are you sure you want to delete this page?';
    }
  };

  //Generate the modal that displays the preview
  const generateModal = html => {
    return (
      <div
        className='modal fade'
        id='articlePreview'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                Article Preview
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <div className='modal-body'>{Parser(html)}</div>
          </div>
        </div>
      </div>
    );
  };

  let path = window.location.pathname;
  let type = '';
  if (props.review) {
    type = 'edit';
  } else {
    type = path.slice(path.lastIndexOf('/') + 1);
  }

  let authorSelect = props.users.map(user => {
    return (
      <option key={user.id} value={parseInt(user.id)}>
        {user.full_name}
      </option>
    );
  });

  let pageTitles = [];

  let { pages } = props;
  if (pages) {
    if (type === 'edit') {
      let currentId = props.page;
      if (props.review && props.review.page) {
        currentId = props.review.page.id;
      }
      pages = pages.filter(page => page.id !== parseInt(currentId));
    }
    pageTitles = pages.map(page => page.title);
  }

  const SectionSchema = Yup.object().shape({
    type: Yup.string(),
    author: Yup.number().when('type', (type, schema) =>
      type === 'create' ? schema.min(1, 'You need to select an author') : schema
    ),
    subject: Yup.array()
      .min(1, 'You need a Subject to place the page.')
      .required('You need a Subject to place the page.'),
    title: Yup.string()
      .min(3, 'The title has to be greater than 3 characters.')
      .max(50, 'Too Long!')
      .required('The page title is required.')
      .when('type', (type, schema) =>
        type === 'create' || type === 'edit'
          ? schema.notOneOf(
              pageTitles,
              'A page with this title already exists.'
            )
          : schema
      ),
    memo_regarding: Yup.string()
      .min(3, 'Title must have more than 3 characters.')
      .max(100, 'Title exceeds 100 character limit.'),
    committees: Yup.array().min(
      1,
      'Every page must have at least one committee. If you do not want an associated committee add the Unassigned committee.'
    ),
    description: Yup.string().min(10, 'Too Short!'),
    sections: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(
          'Every section and lower level separarion must have a title.'
        ),
        content: Yup.string(),
        sections: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().required(
              'Every section and lower level separarion must have a title.'
            ),
            content: Yup.string(),
            sections: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required(
                  'Every section and lower level separarion must have a title.'
                ),
                content: Yup.string(),
                sections: Yup.array().of(
                  Yup.object().shape({
                    title: Yup.string().required(
                      'Every section and lower level separarion must have a title.'
                    ),
                    content: Yup.string(),
                  })
                ),
              })
            ),
          })
        ),
      })
    ),
  });

  //Create section forms
  const sectionForms = ({ values, errors, touched }) => (
    <>
      <div className='sectionForm'>
        <FieldArray
          name='sections'
          render={arrayHelpers => {
            return (
              <>
                <div className={styles['div-row']}>
                  <strong>Sections:</strong>
                </div>
                {values.sections && values.sections.length > 0 ? (
                  values.sections.map((section, index) => {
                    return (
                      <div key={index} className={styles['sectionArray']}>
                        <SectionFormBar
                          type='Section'
                          index={index}
                          section={section}
                          values={values}
                          errors={errors}
                          touched={touched}
                          arrayHelpers={arrayHelpers}
                          // sectionIndex={sectionIndex}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div />
                )}
                <button
                  type='button'
                  className='btn btn-secondary'
                  onClick={e => {
                    arrayHelpers.push({
                      position: '',
                      title: '',
                      content: '',
                      errors: '',
                      collapse: false,
                      sections: [],
                    });
                  }}>
                  Add Section
                </button>
              </>
            );
          }}
        />
      </div>
      <br />
    </>
  );

  let pagesHTML = props.pages
    ? props.pages.map((subject, idx) => {
        return (
          <option key={idx} value={subject.id}>
            {subject.title}
          </option>
        );
      })
    : '';

  return (
    <div className={props.review ? '' : styles['page']}>
      {!props.isLoading && (
        <Formik
          initialValues={{
            type: type,
            subject: [],
            author: props.userProfile.id,
            title: '',
            committees: [],
            tags: [],
            description: '',
            sections: [
              {
                collapse: true,
                title: 'Analysis',
                content: '',
                errors: '',
                sections: [],
              },
              {
                collapse: false,
                title: 'Key Cases',
                content: '',
                errors: '',
                sections: [],
              },
              {
                collapse: false,
                title: 'Key Statutes/Regulations',
                content: '',
                errors: '',
                sections: [],
              },
              {
                collapse: false,
                title: 'Key Legislative History',
                content: '',
                errors: '',
                sections: [],
              },
              {
                collapse: false,
                title: 'Related Legal Topics',
                content: '',
                errors: '',
                sections: [],
              },
              {
                collapse: false,
                title: 'Additional Background',
                content: '',
                errors: '',
                sections: [],
              },
            ],
          }}
          validationSchema={SectionSchema}
          onSubmit={(values, actions) => {
            //Form submission
            let subjects = values.subject.map(com => {
              return com?.id;
            });
            let committees = values.committees.map(com => {
              return com.id;
            });
            let tags = values.tags.map(tag => tag.id);
            // Only run without errors to only remove the modal event listener on submissions
            try {
              window.removeEventListener('beforeunload', alertUser);
              if (type === 'create') {
                // props.createWikiPage(values.author, subjects, values.title, committees, tags, values.description, formToHtmlText(values, false));
                props.createPageReview(
                  null,
                  values.author,
                  subjects,
                  values.title,
                  values.memo_regarding,
                  committees,
                  tags,
                  values.description,
                  formToHtmlText(values, false)
                );
              } else {
                let locks = {
                  id: props.page,
                  edit_lock: false,
                };

                let pageContent = formToHtmlText(values, false);

                if (!state.review_lock && !props.review) {
                  locks.review_lock = true;
                  props.pageUpdate(locks, false);
                  props.createPageReview(
                    props.page,
                    values.author,
                    subjects,
                    values.title,
                    values.memo_regarding,
                    committees,
                    tags,
                    values.description,
                    pageContent,
                    []
                  );
                } else {
                  //Update Page Review
                  let pageReview = {
                    subject: subjects,
                    title: values.title,
                    memo_regarding: values.memo_regarding,
                    committees: committees,
                    tags: tags,
                    description: values.description,
                    content: pageContent,
                    messages: values.messages,
                    edit_lock: false,
                  };
                  if (props.review) {
                    if (props.review.page) {
                      locks.id = props.review.page.id;
                      props.pageUpdate(locks, false);
                    }
                    pageReview.id = props.review.id;
                  } else {
                    props.pageUpdate(locks, false);
                    pageReview.id = state.review_lock;
                  }

                  props.pageReviewUpdate(pageReview);
                }
              }
            } catch (e) {
              console.log(e);
            }

            actions.setSubmitting(false);
          }}>
          {({ values, errors, touched, dirty, submitCount }) => {
            if (!state.dirty && dirty) {
              setState(prevState => ({ ...prevState, dirty: true }));
            }
            if (props.review) {
              props.getErrors(errorCheck(errors));
            }

            if (
              props.review &&
              typeof props.subject_tree === 'object' &&
              Object.keys(props.subject_tree).length > 0 &&
              !state.review
            ) {
              setState(prevState => ({ ...prevState, review: true }));
              inputValueData(values, props.review);
            }

            //Run form submission from outside the form
            if (props.review && props.confirm && !state.confirm) {
              //Only run when there is no error, so the popup event listener is only removed on submission
              try {
                setState(prevState => ({ ...prevState, confirm: true }));
                window.removeEventListener('beforeunload', alertUser);
                let subjects = values.subject.map(com => {
                  return com.id;
                });
                let committees = values.committees.map(com => {
                  return com.id;
                });
                let tags = values.tags.map(tag => tag.id);
                let pageContent = formToHtmlText(values, false);

                let page = {
                  subject: subjects,
                  title: values.title,
                  memo_regarding: values.memo_regarding,
                  committees: committees,
                  tags: tags,
                  description: values.description,
                  content: pageContent,
                  messages: values.messages,
                };

                if (props.review.page) {
                  page.id = props.review.page.id;
                  page.review_lock = false;
                  props.pageUpdate(page, false, props.review.id);
                } else {
                  props.createWikiPage(
                    props.review.author.id,
                    subjects,
                    values.title,
                    values.memo_regarding,
                    committees,
                    tags,
                    values.description,
                    pageContent,
                    props.review.id
                  );
                }
              } catch (e) {
                console.log(e);
              }
            }

            //Discard Page Review from outside the form
            if (props.review && props.discard && !state.discard) {
              //Only run when there is no error, so the event listener is only removed on submission
              setState(prevState => ({ ...prevState, discard: true }));
              try {
                window.removeEventListener('beforeunload', alertUser);
                props.deletePageReview(props.discard, '/myTasks/myCLEXReviews');
              } catch (e) {
                console.log(e);
              }
            }

            let isMyTasksPage =
              window.location.pathname.split('/')[1].toLowerCase() === 'mytasks'
                ? true
                : false;
            return (
              <div
                className={
                  isMyTasksPage
                    ? `${styles['form-wrapper']} ${styles['mytasks']}`
                    : 'main-container'
                }>
                {isMyTasksPage !== true && (
                  <div
                    className={`container-fluid pt-4 pb-3 sticky-top ${styles['form-heading-container']}`}>
                    <div className='row'>
                      <div className='col'>
                        <h1 style={{ fontSize: '1.4rem' }}>
                          {type[0].toUpperCase() + type.substr(1)} Article
                        </h1>
                      </div>
                    </div>
                  </div>
                )}
                <div className={isMyTasksPage ? '' : styles['form-wrapper']}>
                  {errorCheck(errors) && submitCount > 0 && props.submit && (
                    <div className='alert alert-danger' role='alert'>
                      Error in the form. Check for error messages below.
                    </div>
                  )}
                  {props.fail && (
                    <div className='alert alert-danger' role='alert'>
                      {props.fail}
                    </div>
                  )}
                  {props.success && (
                    <div className='alert alert-success' role='alert'>
                      {props.success}
                    </div>
                  )}
                  {type !== 'create' && !props.review && (
                    <Form
                      className={
                        type === 'edit'
                          ? styles['edit-form-select-container']
                          : ''
                      }>
                      <>
                        <strong>Article:</strong>
                        <div className='form-floating'>
                          <select
                            className={`form-select ${styles['inputMargin']}`}
                            id='articleSelect'
                            aria-label='Select an article'
                            disabled={props.edit_lock}
                            value={props.page}
                            name='page'
                            onChange={e => {
                              onPageSelect(e);
                            }}>
                            <option value={-1}>None Selected</option>
                            {pagesHTML}
                          </select>
                          <label htmlFor='articleSelect'>
                            Select an article
                          </label>
                        </div>
                        <br />
                        {type === 'delete' && (
                          <>
                            <button
                              type='button'
                              className='btn btn-secondary'
                              disabled={props.page < 0 || props.edit_lock}
                              onClick={e => {
                                props.pageFormFail('');
                                props.pageFormSuccess('');
                                props.deletePageModal(true);
                                onPageLock(e, values);
                              }}>
                              Delete Article
                            </button>
                            {
                              <div
                                className='modal fade'
                                id='deleteModal'
                                data-bs-backdrop='static'
                                data-bs-keyboard='false'
                                tabIndex='-1'
                                aria-labelledby='deleteModalLabel'
                                aria-hidden='true'
                                toggle={e => {
                                  e.preventDefault();
                                  props.deleteSubjectModal(false);
                                  props.pageUpdate(
                                    { id: props.page, edit_lock: false },
                                    false
                                  );
                                }}>
                                <div className='modal-dialog'>
                                  <div className='modal-content'>
                                    <div className='modal-header'>
                                      <h5
                                        className='modal-title'
                                        id='deleteModalLabel'>
                                        Delete Article
                                      </h5>
                                      <button
                                        type='button'
                                        className='btn-close'
                                        data-bs-dismiss='modal'
                                        aria-label='Close'></button>
                                    </div>
                                    <div className='modal-body'>
                                      {deleteErrorMessage()}
                                    </div>
                                    <div className='modal-footer'>
                                      <button
                                        type='button'
                                        className='btn btn-secondary'
                                        disabled={
                                          props.fail || state.review_lock
                                        }
                                        onClick={e => {
                                          e.preventDefault();
                                          props.deletePage(props.page);
                                          props.deletePageModal(false);
                                        }}>
                                        Delete
                                      </button>
                                      <button
                                        type='button'
                                        className='btn btn-secondary'
                                        onClick={e => {
                                          props.deletePageModal(false);
                                          props.pageUpdate(
                                            {
                                              id: props.page,
                                              edit_lock: false,
                                            },
                                            false
                                          );
                                        }}>
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </>
                        )}
                        {type === 'edit' && (
                          <button
                            type='button'
                            className='btn btn-secondary'
                            disabled={props.page < 0 || props.edit_lock}
                            onClick={e => {
                              onPageLock(e, values);
                              props.pageSubmitStatus(false);
                              props.pageFormFail('');
                            }}>
                            Edit Article
                          </button>
                        )}
                      </>
                      <br />
                    </Form>
                  )}
                  {(type === 'create' ||
                    (type === 'edit' && props.edit_lock)) && (
                    <Form>
                      <>
                        {/* commented this code block out for now until I find a replacment as Prompt was deprecated*/}
                        {/* <Prompt
                        when={props.page && dirty}
                        message='If you leave the page you will lose all your progress. Do you still want to leave?'
                      /> */}
                        {props.all_subjects.length === 0 && (
                          <div className='alert alert-danger' role='alert'>
                            No subjects found. You need to create a subject
                            first.
                          </div>
                        )}
                        {type === 'create' && (
                          <>
                            <strong>Author:</strong>
                            <Field
                              name='author'
                              render={({ field }) => (
                                <div className='form-floating'>
                                  <select
                                    className='form-select'
                                    {...field}
                                    id='authorSelect'
                                    aria-label='Select an author'>
                                    <option value={0}>Select Author</option>
                                    {authorSelect}
                                  </select>
                                  <label htmlFor='authorSelect'>
                                    Select an author
                                  </label>
                                </div>
                              )}></Field>
                            {touched['author'] && errors['author'] && (
                              <div className='alert alert-danger' role='alert'>
                                {errors['author']}
                              </div>
                            )}
                            <br />
                          </>
                        )}
                        {typeof props.subject_tree === 'object' &&
                          Object.keys(props.subject_tree).length > 0 && (
                            <>
                              <strong>Topics:</strong>
                              <TagsDisplay
                                currentList={values.subject}
                                onAddTarget={e => {
                                  onAddSelectedInArray(e, values.subject);
                                }}
                                onDeleteTarget={e => {
                                  onDeleteTargetInArray(e, values.subject);
                                }}
                                warningMessage='No subjects have been selected.'
                                param='path'
                                dropdownList={Object.values(props.subject_tree)}
                                reference={tagsDisplayRef}
                              />

                              {touched['subject'] && errors['subject'] && (
                                <div
                                  className='alert alert-danger'
                                  role='alert'>
                                  {errors['subject']}
                                </div>
                              )}

                              <strong>Title:</strong>
                              <Field
                                name='title'
                                component={CustomInputComponent}
                              />
                              <br />

                              <strong>Memo Regarding:</strong>
                              <Field
                                name='memo_regarding'
                                component={CustomInputComponent}
                              />

                              <br />

                              <strong>Committees:</strong>
                              <TagsDisplay
                                currentList={values.committees}
                                onAddTarget={e => {
                                  onAddSelectedInArray(e, values.committees);
                                }}
                                onDeleteTarget={e => {
                                  onDeleteTargetInArray(e, values.committees);
                                }}
                                warningMessage='No committees have been selected.'
                                param='committee_name'
                                dropdownList={props.allCommittees}
                                reference={tagsDisplayRef}
                              />

                              {touched['committees'] &&
                                errors['committees'] && (
                                  <div
                                    className='alert alert-danger'
                                    role='alert'>
                                    {errors['committees']}
                                  </div>
                                )}

                              <strong>Tags:</strong>
                              <TagsDisplay
                                currentList={values.tags}
                                onAddTarget={e => {
                                  onAddSelectedInArray(e, values.tags);
                                }}
                                onDeleteTarget={e => {
                                  onDeleteTargetInArray(e, values.tags);
                                }}
                                warningMessage='No tags have been selected.'
                                param='name'
                                dropdownList={props.tags}
                                reference={tagsDisplayRef}
                              />

                              {touched['tags'] && errors['tags'] && (
                                <div
                                  className='alert alert-danger'
                                  role='alert'>
                                  {errors['tags']}
                                </div>
                              )}

                              <strong>Introduction:</strong>
                              <Field
                                name='description'
                                component={CustomQuillComponent}
                              />

                              {sectionForms({ values, errors, touched })}

                              {props.review && (
                                <>
                                  <div>
                                    {props.review.messages !== [] &&
                                      props.review.messages.map((mes, idx) => {
                                        return <div key={idx}>{mes}</div>;
                                      })}
                                  </div>
                                  <strong>Messages:</strong>
                                  <Field
                                    name='messages'
                                    component={CustomInputComponent}
                                  />
                                  <br />
                                </>
                              )}
                            </>
                          )}
                        {typeof props.subject_tree === 'object' &&
                          Object.keys(props.subject_tree).length === 0 && (
                            <div className='loading-container'>
                              <img
                                className='loading-image'
                                src='/img/newLoadingAnimation.gif'
                                alt='Loading animation'
                                align='middle'
                              />
                              <h3>
                                Loading <span className='dot1'>.</span>
                                <span className='dot2'>.</span>
                                <span className='dot3'>.</span>
                              </h3>
                            </div>
                          )}
                        <div
                          className={`${styles['article-footer-container']} ${
                            isMyTasksPage
                              ? `${styles['article-footer-container']} ${styles['mytasks']}`
                              : {}
                          }`}>
                          <div>
                            <button
                              type='submit'
                              className='submit-button'
                              onClick={() => {
                                props.pageSubmitStatus(true);
                                window.scrollTo(0, 0);
                              }}>
                              {props.review ? 'Save Draft' : 'Submit'}
                            </button>
                            {props.review && state.screenWidth < 771 && (
                              <button
                                type='button'
                                className={`btn ${styles['preview-button']}`}
                                data-bs-toggle='modal'
                                data-bs-target='#articlePreview'>
                                <PreviewEyeIcon /> Preview
                              </button>
                            )}
                            {!props.review && (
                              <button
                                type='button'
                                className={`btn ${styles['preview-button']}`}
                                data-bs-toggle='modal'
                                data-bs-target='#articlePreview'>
                                <PreviewEyeIcon /> Preview
                              </button>
                            )}
                          </div>
                          {type === 'create' && (
                            <button
                              type='button'
                              className='cancel-button'
                              onClick={e => {
                                e.preventDefault();
                                navigate(-1);
                              }}>
                              Cancel
                            </button>
                          )}
                          {type === 'edit' && !props.review && (
                            <button
                              type='button'
                              className='cancel-button'
                              disabled={props.page < 0 || !props.edit_lock}
                              onClick={e => {
                                props.storeEditLock(false);
                                props.pageUpdate(
                                  { id: props.page, edit_lock: false },
                                  false
                                );
                                props.pageSubmitStatus(false);
                                props.pageFormFail('');
                                if (state.review_lock) {
                                  props.pageReviewUpdate({
                                    id: state.review_lock,
                                    edit_lock: false,
                                  });
                                }
                                navigate(-1);
                              }}>
                              Cancel
                            </button>
                          )}
                        </div>
                        {generateModal(formToHtmlText(values, true))}
                      </>
                    </Form>
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
      )}
      {props.isLoading && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            align='middle'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    page_id: state.wikiPageReducer.page_id,
    pages: state.pageFormReducer.pages,
    page: state.pageFormReducer.page,
    edit_lock: state.pageFormReducer.edit_lock,
    allCommittees: state.userReducer.committees,
    committees: state.pageFormReducer.committees,
    all_subjects: state.pageFormReducer.all_subjects,
    subject: state.pageFormReducer.subject,
    subject_tree: state.pageFormReducer.subject_tree,
    title: state.pageFormReducer.title,
    tags: state.tagFormReducer.tags,
    description: state.pageFormReducer.description,
    sections: state.pageFormReducer.sections,
    fail: state.pageFormReducer.fail,
    submit: state.pageFormReducer.submit,
    success: state.pageFormReducer.success,
    deleteModal: state.pageFormReducer.deleteModal,
    isLoading: state.pageFormReducer.isLoading,
    users: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(PageForm);
