import React from 'react';
import styles from '../../styles/wiki/TableOfContent.module.scss';
import Parser from 'html-react-parser';

const TableOfContent = props => {
  const compileContent = (content, id = '') => {
    return content.map((element, idx) => {
      if (element.subSubjects && element.subSubjects.length > 0) {
        return (
          <div key={id + idx}>
            <ol>{compileContent(element.subSubjects, idx + '-')}</ol>
          </div>
        );
      } else {
        return element.subject;
      }
    });
  };

  //Renders 3 levels within a section, this should be replaced with a function iterating through
  //any existing level
  const parserOptions = {
    replace: ({ attribs, children }) => {
      if (attribs && attribs.id) {
        if (attribs.id.indexOf('section') === 0) {
          return (
            <li key={attribs.key}>
              <a href={'#' + attribs.id}>{children[0].data}</a>
            </li>
          );
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
  };

  const parsedContent = Parser(props.content, parserOptions);
  let nestedSubjects = [];
  parsedContent.forEach(tag => {
    if (tag.type === 'li') {
      let position = tag.props.children.props.href.slice(9);
      let ids = position.split('.');

      let currentList = nestedSubjects;
      for (let i = 0; i < ids.length; i++) {
        if (i < ids.length - 1) {
          currentList = currentList[ids[i]].subSubjects;
        } else {
          let subjectList = {
            subject: tag,
            subSubjects: [],
          };
          currentList[ids[i]] = subjectList;
        }
      }
    }
  });

  const compiledList = compileContent(nestedSubjects);

  return (
    <div className={styles['table']}>
      <div className={styles['table-of-content-heading-wrapper']}>Contents</div>
      <ol className={styles['tableOfContent']}>{compiledList}</ol>
    </div>
  );
};

export default TableOfContent;
