import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import File from '../library/File';
import ReportTile from './ReportTile';
import ReportFields from '../../services/ReportFields';
import UpdateDueReport from './UpdateDueReport';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { changeReportTrackingDisplay } from '../../actions/reportTrackingAction';
import { formatDate } from '../../utils/helper';
import styles from '../../styles/report-tracking/DueReportsDetails.module.scss';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
  ToolTipIcon,
  ArrowCircleFillLeftIcon,
  CloseButtonNormalIcon,
  BoxAndArrowIcon,
} from '../../services/SvgLibrary';

const DueReportsDetails = props => {
  const [reportDetail, setReportDetail] = useState({});
  const [dueDate, setDueDate] = useState(
    props.selectedDay ? props.selectedDay.toLocaleDateString() : ''
  );
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    let trackerId = '';
    if (props.params && props.params.id) {
      trackerId = props.params.id;
    }
    if (props.currentMode === 'list' || (trackerId && !reportDetail.id)) {
      const dueDateDict = getDueDateDict(props.selectedDayDueReports[0]);
      const dateKeys = Object.keys(dueDateDict);
      let lastDate = '';

      if (!dueDate) {
        lastDate = dateKeys[dateKeys.length - 1];
      }
      if (props.currentMode === 'list') {
        props.changeReportTrackingDisplay('detail');
        props.setHeaderColor(props.selectedDayDueReports[0].status);
      }
      setReportDetail(props.selectedDayDueReports[0]);
      setDueDate(dueDate ? dueDate : lastDate);
    }
    // }, [props, reportDetail.id, dueDate]);
  }, [reportDetail.id, dueDate]);

  const onTileClick = report => {
    props.changeReportTrackingDisplay('detail');
    setReportDetail(report);
    props.onChangeCurrentHead('');
  };

  const compareDict = (a, b) => {
    if (parseInt(a.num) < parseInt(b.num)) {
      return -1;
    }
    if (parseInt(a.num) > parseInt(b.num)) {
      return 1;
    }
    return 0;
  };

  const getDueDateDict = attachments => {
    let dueDateDict = {};
    attachments.attachment.forEach(report => {
      let dateString = new Date(report.due_date).toLocaleDateString();
      if (dueDateDict[dateString] && report.file) {
        dueDateDict[dateString].push(report);
      } else {
        if (report.file) {
          dueDateDict[dateString] = [report];
        } else {
          dueDateDict[dateString] = [];
        }
      }
    });
    return dueDateDict;
  };

  const localLawSort = lawArray => {
    let yearDict = {};
    let newLawArray = [];

    lawArray.forEach(ll => {
      // console.log(ll)
      if(ll['law_number']){
        if (ll['law_number'].includes(' ')) {
          let numbers = ll['law_number'].split(' ')[1];
          let splitNumber = numbers.split('/');
          ll['num'] = splitNumber[0];
  
          if (yearDict[splitNumber[1]]) {
            yearDict[splitNumber[1]].push(ll);
          } else {
            yearDict[splitNumber[1]] = [ll];
          }
        }
      }
    });

    Object.keys(yearDict).forEach(key => {
      let sortedYearLocalLaws = yearDict[key].sort(compareDict);
      newLawArray = newLawArray.concat(sortedYearLocalLaws);
    });
    return newLawArray;
  };

  // Generate the operational requirement details
  const displayReportDetail = reportFields => {
    let details = reportFields.map((field, idx) => {
      // Display a list of committees
      if (field.field === 'committees') {
        const arr = reportDetail['committees'];
        let committees = '';
        for (let i = 0; i < arr.length; i++) {
          committees += arr[i].committee_name;
          committees += ', ';
        }
        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div>{committees.substring(0, committees.length - 2)}</div>
          </div>
        );
      }
      // Display a list of assigned contacts
      else if (field.field.includes('_contact')) {
        const arr = reportDetail[field.field];
        let contactLength = arr.length - 1;
        let links = arr.map((contact, idx) => {
          return (
            <span key={idx}>
              <a
                href={`${window.location.origin}/staff/${
                  contact.user_profile ? contact.user_profile : contact.id
                }`}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `${window.location.origin}/staff/${
                      contact.user_profile ? contact.user_profile : contact.id
                    }`
                  );
                }}
                style={{
                  textDecoration: 'none',
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '0.2rem',
                }}>
                {contact.full_name} <BoxAndArrowIcon width='12' height='12' />
              </a>
              {contactLength === idx ? '' : ', '}
            </span>
          );
        });
        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div>{links}</div>
          </div>
        );
      } else if (field.field === 'ls_requests') {
        let lsrsLength = reportDetail['ls_requests'].length - 1;
        const associated_lsrs = reportDetail['ls_requests'].map((ls, idx) => {
          return (
            <span key={ls.ls_number}>
              <a
                href={`${window.location.origin}/lsRequestList/${ls.ls_number}`}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `${window.location.origin}/lsRequestList/${ls.ls_number}`
                  );
                }}>
                {ls.ls_number}
              </a>
              {lsrsLength === idx ? '' : ', '}
            </span>
          );
        });
        return (
          <React.Fragment key={idx}>
            <div key={idx}>
              <p>Associated {field.title}</p>
              <div>{associated_lsrs}</div>
            </div>
          </React.Fragment>
        );
      } else if (field.field === 'local_law') {
        let lsrLegistar = reportDetail['ls_requests'].filter(ls => ls.matters.length > 0);
        let lsrLocalLaw = []
          lsrLegistar.forEach(lsr => {
            lsrLocalLaw = lsrLocalLaw.concat(lsr.matters)
          });
        // let lsrLocalLaw = lsrLegistar.map(lsr => {
        //   return { 'link': lsr.legistar, 'law_number': lsr.local_law };
        // });

        let allLocalLaws = lsrLocalLaw.concat(reportDetail['local_law']);
        let linkLength = allLocalLaws.length - 1;
        let sortedLocalLaws = localLawSort(allLocalLaws);

        let allLocalLawLink = sortedLocalLaws.map((ll, idx2) => {
          return (
            <span key={`${ll.law_number}l`}>
              <a href={ll.link} target='_blank' rel='noopener noreferrer'>
                {ll.law_number}
              </a>
              {linkLength === idx2 ? '' : ', '}
            </span>
          );
        });

        return (
          <React.Fragment key={idx}>
            <div>
              <p>{field.title}</p>
              <div>{allLocalLawLink}</div>
            </div>
          </React.Fragment>
        );
      } else if (field.field === 'legacy_local_law') {
        let linkLength = reportDetail['legacy_local_law'].length -1;
        let allLocalLawLink = reportDetail['legacy_local_law'].map((ll, idx2) => {
          return (
            <span key={`${ll.law_number}l`}>
              {ll.law_number}
              {linkLength === idx2 ? '' : ', '}
            </span>
          );
        });

        return (
          <React.Fragment key={idx}>
            <div>
              <p>{field.title}</p>
              <div>{allLocalLawLink}</div>
            </div>
          </React.Fragment>
        );
      } else if (field.field === 'frequency_unit') {
        return (
          <div key={idx}>
            <p className={styles['input-text-title']}>{field.title}</p>
            <ToolTipIcon
              classNameProp={styles['user-guide-field-icon']}
              idProp='unit'
            />
            <UncontrolledPopover
              innerClassName={styles['popover-container']}
              className={styles['popover-outer-container']}
              fade={false}
              trigger='hover'
              placement='right'
              target='unit'>
              <PopoverBody>
                <div>
                  Reports will be requested by the system every Period unit.
                  <br></br>
                  <br></br>
                  The <b>Unit</b> specifies the time-unit used under the
                  <em>Period</em> field.
                  <em>
                    For example, a "Unit” of <b>Months</b>, and a “Period” of
                    <b>3</b> indicates that a report is due every 3 months.
                  </em>
                  The system accepts “Once” or “As needed” as unit options as
                  well.
                </div>
              </PopoverBody>
            </UncontrolledPopover>
            <div className='capitalize'>
              {reportDetail[field.field].replace(/_/g, ' ')}
            </div>
          </div>
        );
      } else if (field.field === 'frequency_quantity') {
        reportDetail[field.field] = reportDetail[field.field]
          ? reportDetail[field.field]
          : 'None';
        return (
          <div key={idx}>
            <p className={styles['input-text-title']}>{field.title}</p>
            <ToolTipIcon
              classNameProp={styles['user-guide-field-icon']}
              idProp='period'
            />
            <UncontrolledPopover
              innerClassName={styles['popover-container']}
              className={styles['popover-outer-container']}
              fade={false}
              trigger='hover'
              placement='right'
              target='period'>
              <PopoverBody>
                <div>
                  Reports will be requested by the system every Period unit.
                  <br></br>
                  <br></br>
                  The <b>Unit</b> specifies the time-unit used under the
                  <em>Period</em> field.
                  <em>
                    For example, a "Unit” of <b>Months</b>, and a “Period” of
                    <b>3</b> indicates that a report is due every 3 months.
                  </em>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
            <div>{reportDetail[field.field]}</div>
          </div>
        );
      } else if (field.field === 'tracker_type') {
        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div className='capitalize'>{reportDetail[field.field]}</div>
          </div>
        );
      } else if (field.field === 'submitting_agency') {
        const arr = reportDetail['submitting_agency'];
        let agencies = '';
        for (let i = 0; i < arr.length; i++) {
          agencies += arr[i].name + ', ';
        }

        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div>{agencies.substring(0, agencies.length - 2)}</div>
          </div>
        );
      }
      // Display file
      else if (field.type === 'file') {
        let files, dueDateDict;
        if (field.field === 'attachment') {
          files = reportDetail.attachment;
          dueDateDict = getDueDateDict(reportDetail);
        } else {
          files = reportDetail.milestone_attachment;
          dueDateDict = [];
        }

        let getOrderedDates = Object.keys(dueDateDict).sort((a, b) => {
          a = new Date(a);
          b = new Date(b);
          return a > b ? -1 : a < b ? 1 : 0;
        });

        return (
          <div key={idx}>
            <p>{field.title}</p>
            {getOrderedDates.length > 0 && (
              <>
                <div>
                  <select
                    value={dueDate}
                    className={styles['file-dropdown']}
                    onChange={e => {
                      setDueDate(e.target.value);
                    }}>
                    <option value=''>Select Due Date</option>
                    {getOrderedDates.map((date, index) => {
                      let empty =
                        dueDateDict[date].length > 0 ? '' : ' (empty)';
                      let displayDate = new Date(date).toLocaleDateString();
                      return (
                        <option value={date} key={index}>
                          {displayDate + empty}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <br />
                {dueDate && (
                  <div className={styles['report-field-files']}>
                    {dueDateDict[dueDate] &&
                      dueDateDict[dueDate].map((file, idx) => {
                        return (
                          <File
                            date={file.timestamp}
                            key={idx}
                            file={file}
                            id={`old-files-${idx}`}
                            getFileAndDownload={props.getFileAndDownload}
                            downloadStatus={props.downloadStatus}
                          />
                        );
                      })}
                  </div>
                )}
              </>
            )}
            {field.field === 'milestone_attachment' &&
              files.map((file, idx) => {
                return (
                  <File
                    date={file.timestamp}
                    key={idx}
                    file={file}
                    id={`milestone-file-${idx}`}
                    getFileAndDownload={props.getFileAndDownload}
                    downloadStatus={props.downloadStatus}
                  />
                );
              })}
            {reportDetail[field.field] ? '' : 'None'}
          </div>
        );
      } else if (typeof reportDetail[field.field] === 'boolean') {
        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div>{reportDetail[field.field] ? 'True' : 'False'}</div>
          </div>
        );
      } else if (field.type === 'date') {
        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div>
              {reportDetail[field.field]
                ? formatDate(reportDetail[field.field])
                : 'None'}
            </div>
          </div>
        );
      } else {
        reportDetail[field.field] = reportDetail[field.field]
          ? reportDetail[field.field]
          : 'None';
        return (
          <div key={idx}>
            <p>{field.title}</p>
            <div style={field.type === 'textarea' ? {"whiteSpace": "pre-wrap"}: {}}>{reportDetail[field.field]}</div>
          </div>
        );
      }
    });

    return <div className={styles['report-field-column']}>{details}</div>;
  };

  const onBackToReport = () => {
    props.changeReportTrackingDisplay('detail');
  };

  const {
    changeReportTrackingDisplay,
    currentDisplay,
    currentMode,
    init,
    setInit,
    params,
    onBackToService,
    onChangeCurrentHead,
    onCloseWindow,
    selectedDay,
    selectedDayDueReports,
    setHeaderColor,
    tileClickHandler,
    titleContainerStyle,
    userProfile,
  } = props;
  const isCMStaff =
    userProfile.role.includes('Council Member') ||
    userProfile.under_council_member;
  const reportsList =
    selectedDayDueReports &&
    selectedDayDueReports.map((report, idx) => (
      <div key={idx}>
        <ReportTile
          day={selectedDay}
          status={report.op_status}
          report={report}
          onTileClick={onTileClick}
          onChangeCurrentHead={onChangeCurrentHead}
          onTileClickHandler={tileClickHandler}
          setHeaderColor={setHeaderColor}
        />
        {idx !== selectedDayDueReports.length - 1 && (
          <hr style={{ width: '90%' }} />
        )}
      </div>
    ));

  let displayedFields = [];
  if (currentDisplay === 'detail' && reportDetail.id) {
    let type = reportDetail.tracker_type;
    displayedFields = ReportFields.filter(tracker => {
      return (
        (tracker.tracker === 'Both' ||
          tracker.tracker.toLowerCase() === type) &&
        !(tracker.field == 'errors' && isCMStaff)
      );
    });
  }

  let halfOfFields = displayedFields.length / 2;
  let firstHalfOfFields = displayedFields.slice(0, halfOfFields);
  let secondHalfOfFields = displayedFields.slice(halfOfFields);

  // Generate the content in the popup window
  // By default it will be a list of reports with title and committees
  // Once the end users click the title, it will change to the details of that reports
  return (
    <>
      {currentDisplay === 'list' && (
        <div style={{ height: '50vh', overflow: 'auto', padding: '1rem' }}>
          {reportsList}
        </div>
      )}
      {currentDisplay === 'detail' && (
        <>
          <div className={styles['modal-header']} style={titleContainerStyle}>
            {currentMode === 'calendar' && !(params && params.id && init) && (
              <button
                className='btn back-icon'
                onClick={() => {
                  if (currentMode === 'list') {
                    onBackToService();
                  } else {
                    onChangeCurrentHead('list');
                    changeReportTrackingDisplay('list');
                  }
                }}>
                <ArrowCircleFillLeftIcon /> Back
              </button>
            )}
            <span className='popup-detail-title'>
              Operational Requirement Detail
            </span>
            <CloseButtonNormalIcon
              classNameProp='popup-close-icon'
              onClickFunction={() => {
                setInit(false);
                onCloseWindow();
              }}
              alt='close-icon'
            />
          </div>
          <div className={styles['report-fields']}>
            {displayReportDetail(firstHalfOfFields)}
            {displayReportDetail(secondHalfOfFields)}
          </div>
          {userProfile.permissions.reportTracking.change_report && (
            <div className={styles['modal-footer']}>
              <button
                type='button'
                className={`submit-button ${styles['footer-button']}`}
                onClick={() => {
                  changeReportTrackingDisplay('update');
                  onChangeCurrentHead('');
                }}>
                Update Op Req
              </button>
              <span>To upload a report document, update this report.</span>
            </div>
          )}
        </>
      )}
      {currentDisplay === 'update' && (
        <UpdateDueReport
          params={params}
          currentReport={reportDetail}
          onCloseWindow={onCloseWindow}
          onBackToReport={onBackToReport}
          selectedDay={selectedDay}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    dueReports: state.reportTrackingReducer.dueReports,
    selectedDayDueReports: state.reportTrackingReducer.selectedDayDueReports,
    downloadStatus: state.fileDownloadReducer.downloadStatus,
    currentDisplay: state.reportTrackingReducer.currentDisplay,
    userProfile: state.userReducer.userProfile,
  };
};

const mapDispatchToProps = {
  getFileAndDownload,
  changeReportTrackingDisplay,
};

export default connect(mapStateToProps, mapDispatchToProps)(DueReportsDetails);
