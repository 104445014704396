import React, { Component } from 'react';
import queryString from 'query-string';
import styles from '../../styles/report-tracking/ReportsTable.module.scss';
import { connect } from 'react-redux';
import * as actions from '../../actions/reportTrackingAction';
import ReportServicePopup from './ReportServicePopup';
import { formatDate } from '../../utils/helper';
import ReportFields from '../../services/ReportFields';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import ToolTip from '../library/ToolTip';
import {
  SearchIcon,
  CloseButtonCircleFillIcon,
  InfoCircleFilledIcon,
  CheckmarkIcon,
  HalfCirlcleIcon,
  FlagFilledIcon,
  ClockIcon,
  CheckIcon,
  ChevronUpDownArrowsIcon,
  ChevronRightIcon,
} from '../../services/SvgLibrary';

class ReportsTable extends Component {
  state = {
    currentSearch: 'searchAll',
    currentSearchTitle: 'Text Search', // set initial value according to the initial 'currentSearch'
    filterCombination: {
      completeFilter: true,
      displayMilestones: true,
      displayReports: true,
      pastDueFilter: true,
      receivedFilter: true,
      upcomingFilter: true,
    },
    isDropdownOpen: false,
    screenWidth: window.innerWidth,
    searchAgency: '',
    searchAll: '',
    searchCommittee: '',
    searchDivision: '',
    searchLaw: '',
    searchResultsDisplay: false,
    searchTitle: '',
    selectedSortBy: 'Last Received',
    selectedSortOrder: 'Ascending',
    sortBy: 'last_received',
    sortFields: [
      {
        title: 'Division',
        field: 'division',
      },
      {
        title: 'Errors',
        field: 'errors',
      },
      {
        title: 'Last Received',
        field: 'last_received',
      },
      {
        title: 'Next Due',
        field: 'next_due',
      },
      {
        title: 'Op Req Title',
        field: 'title',
      },
    ],
    toggleFilter: false,
  };

  componentDidMount() {
    // Get all the reports
    const urlReportFilter = queryString.parse(window.location.search);
    if (this.props.allReports.length === 0) {
      if (
        window.location.href.includes('/myTasks/myOpReqs') &&
        urlReportFilter.filter
      ) {
        if (urlReportFilter.filter === 'upcoming') {
          this.setState(
            {
              filterCombination: {
                pastDueFilter: false,
                completeFilter: false,
                upcomingFilter: true,
                receivedFilter: false,
              },
            },
            () => {
              this.onGetOpReqSearchResult();
            }
          );
        } else if (urlReportFilter.filter === 'past') {
          this.setState(
            {
              filterCombination: {
                pastDueFilter: true,
                completeFilter: false,
                upcomingFilter: false,
                receivedFilter: false,
              },
            },
            () => {
              this.onGetOpReqSearchResult();
            }
          );
        }
      } else {
        this.props.getAllReports(window.location.href.endsWith('/myOpReqs'));
      }
    }
    document
      .querySelector('#main-container')
      .addEventListener('scroll', this.onScroll, false);
    window.addEventListener('resize', () => {
      this.setState({ screenWidth: window.innerWidth });
    });
    window.addEventListener('mousedown', this.handleFilterClick);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('mousedown', this.handleFilterClick);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  compareDict(a, b) {
    if (parseInt(a.num) < parseInt(b.num)) return -1;
    if (parseInt(a.num) > parseInt(b.num)) return 1;
    return 0;
  }

  localLawSort(lawArray) {
    let yearDict = {};
    let newLawArray = [];

    lawArray.forEach(ll => {
      let numbers = ll['law_number'].split(' ')[1];
      if (numbers) {
        let splitNumber = numbers.split('/');
        ll['num'] = splitNumber[0];

        if (yearDict[splitNumber[1]]) {
          yearDict[splitNumber[1]].push(ll);
        } else {
          yearDict[splitNumber[1]] = [ll];
        }
      }
    });
    Object.keys(yearDict).forEach(key => {
      let sortedYearLocalLaws = yearDict[key].sort(this.compareDict);
      newLawArray = newLawArray.concat(sortedYearLocalLaws);
    });
    return newLawArray;
  }

  handleFilterClick = e => {
    let dropdown = document.getElementById('filter-dropdown');
    if (dropdown) {
      let isInDropdown = dropdown.contains(e.target);
      if (!isInDropdown && this.state.toggleFilter) {
        this.setState({ toggleFilter: false });
      }
    }
  };

  onScroll = e => {
    const scrollPosition =
      document.querySelector('#main-container').scrollHeight -
      document.querySelector('#main-container').scrollTop;
    if (scrollPosition < 3000 && this.props.nextAPI && !this.props.isLoading) {
      this.props.loadMoreReports(this.props.nextAPI);
    }
  };

  // determins the current sort direction and field, then toggling the sort direction,
  // updating the sortBy field, and finally updating the component state and triggering
  // a refresh of the data with the new sort parameters
  onColumnClick = (column, title) => {
    let selectedSortOrder = '';
    let sortBy = '';
    let selectedSortBy = '';
    if (title !== this.state.selectedSortBy) {
      selectedSortOrder = 'Ascending';
      sortBy = column;
      selectedSortBy = title;
    } else {
      const direction =
        this.state.sortBy[0] === '-' ? 'Descending' : 'Ascending';
      const field =
        this.state.sortBy[0] === '-'
          ? this.state.sortBy.substring(1)
          : this.state.sortBy;
      selectedSortOrder =
        direction === 'Descending' ? 'Ascending' : 'Descending';
      sortBy = direction === 'Descending' ? field : `-${field}`;
      selectedSortBy = title;
    }

    this.setState(
      {
        sortBy,
        selectedSortBy,
        selectedSortOrder,
        searchResultsDisplay: false,
      },
      () => {
        this.onGetOpReqSearchResult();
      }
    );
  };

  // toggles the isDropdownOpen state, which presumably controls whether
  // a dropdown menu is open or no
  toggleDropdown = () => {
    this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };

  // This function sets the isDropdownOpen state to false, effectively closing the dropdown.
  closeDropdown = () => {
    this.setState({ isDropdownOpen: false });
  };

  // designed to respond to clicks outside of a certain element. If a click
  // event occurs outside of the dropdown it will call closeDropdown to close the dropdown.
  handleClickOutside = event => {
    if (this.dropdownRef && !this.dropdownRef.contains(event.target)) {
      this.closeDropdown();
    }
  };

  // This function is counting the number of filters that are currently activated.
  countChecked = () => {
    const filterKeys = [
      'completeFilter',
      'receivedFilter',
      'upcomingFilter',
      'pastDueFilter',
    ];
    let count = 0;
    filterKeys.forEach(key => {
      if (this.state.filterCombination[key]) {
        count++;
      }
    });
    return count;
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.onGetOpReqSearchResult();
    }
  };

  onScroll = () => {
    const scrollPosition =
      document.querySelector('#main-container').scrollHeight -
      document.querySelector('#main-container').scrollTop;
    if (scrollPosition < 3000 && this.props.nextAPI && !this.props.isLoading) {
      this.props.loadMoreReports(this.props.nextAPI);
    }
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      this.onGetOpReqSearchResult();
    }
  };

  // Trigger the action to search based on committee or keyword
  onGetOpReqSearchResult = () => {
    const {
      sortBy,
      searchCommittee,
      searchTitle,
      searchDivision,
      searchAgency,
      searchLaw,
      searchAll,
      filterCombination,
    } = this.state;
    this.setState({ searchResultsDisplay: true });
    this.props.searchReports(
      sortBy,
      searchCommittee,
      searchTitle,
      searchDivision,
      searchAgency,
      searchLaw,
      searchAll,
      filterCombination,
      window.location.href.endsWith('/myOpReqs')
    );
  };

  onClearFilter = () => {
    this.setState({
      searchCommittee: '',
      searchAgency: '',
      searchDivision: '',
      searchTitle: '',
      searchLaw: '',
      searchAll: '',
      sortBy: 'last_received',
      searchResultsDisplay: false,
    });
    this.props.getAllReports(window.location.href.endsWith('/myOpReqs'));
  };

  generateSearchBox = () => {
    const { agencies, committees, isLoading, userProfile } = this.props;
    let user_committee_ids = userProfile.committees.map(com => com.id);
    let searchCommittees = committees;
    if (window.location.href.endsWith('/myOpReqs')) {
      searchCommittees = committees.filter(committee =>
        user_committee_ids.includes(committee.id)
      );
    }

    const divisions = ReportFields.find(
      item => item.field === 'division'
    ).options;

    const searchFields = [
      {
        title: 'Text Search',
        field: 'searchAll',
      },
      {
        title: 'Agency',
        field: 'agency',
      },
      {
        title: 'Committee',
        field: 'committee',
      },
      {
        title: 'Division',
        field: 'division',
      },
      {
        title: 'Op Req Title',
        field: 'title',
      },
    ];

    return (
      <div className={styles['report-search']}>
        <div className={styles['search-by']}>
          <span className='badge'>Search by</span>
          <div className='dropdown'>
            <button
              className={`dropdown-toggle ${styles['report-select']}`}
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {this.state.currentSearchTitle}
            </button>
            <ul
              className={`dropdown-menu ${styles['search-dropdown-menu']}`}
              aria-labelledby='dropdownMenuButton1'>
              {searchFields.map((c, idx) => (
                <li key={idx}>
                  <a
                    className={`dropdown-item ${styles['search-dropdown-menu-item']}`}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        currentSearch: c.field,
                        currentSearchTitle: c.title,
                        searchCommittee: '',
                        searchAgency: '',
                        searchDivision: '',
                        searchTitle: '',
                        searchLaw: '',
                        searchAll: '',
                      });
                    }}
                    href='#'>
                    {c.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles['search-box']}>
          <SearchIcon
            width='24'
            height='24'
            onClickFunction={this.onGetOpReqSearchResult}
            classNameProp={styles['search-button']}
          />
          {this.state.currentSearch === 'committee' && (
            <select
              className='form-select'
              aria-label='Select a committee form the dropdown'
              onChange={e => {
                this.setState({ searchCommittee: e.target.value }, () => {
                  this.onGetOpReqSearchResult();
                });
              }}
              value={this.state.searchCommittee}
              onKeyUp={this.onKeyPress}>
              {searchCommittees.map((c, idx) => (
                <option value={c.id} key={idx}>
                  {c.committee_name}
                </option>
              ))}
            </select>
          )}
          {this.state.currentSearch === 'agency' && (
            <select
              className='form-select'
              aria-label='Select an agency from the dropdown'
              onChange={e => {
                this.setState({ searchAgency: e.target.value }, () => {
                  this.onGetOpReqSearchResult();
                });
              }}
              value={this.state.searchAgency}
              onKeyUp={this.onKeyPress}>
              {agencies.map((agency, id) => (
                <option value={agency.id} key={id}>
                  {agency.name}
                </option>
              ))}
            </select>
          )}
          {this.state.currentSearch === 'title' && (
            <input
              className='form-control'
              type='text'
              value={this.state.searchTitle}
              placeholder='Search Op Req Titles'
              onChange={e => this.setState({ searchTitle: e.target.value })}
              onKeyUp={this.onKeyPress}
            />
          )}
          {this.state.currentSearch === 'division' && (
            <select
              className='form-select'
              aria-label='Select a division from the dropdown'
              onChange={e => {
                this.setState({ searchDivision: e.target.value }, () => {
                  this.onGetOpReqSearchResult();
                });
              }}
              value={this.state.searchDivision}
              onKeyUp={this.onKeyPress}>
              <option>Select a Division</option>
              {divisions.map((division, id) => (
                <option value={division} key={id}>
                  {division}
                </option>
              ))}
            </select>
          )}
          {this.state.currentSearch === 'searchAll' && (
            <input
              className='form-control'
              type='text'
              value={this.state.searchAll}
              placeholder='Search by Text'
              onChange={e => this.setState({ searchAll: e.target.value })}
              onKeyUp={this.onKeyPress}
            />
          )}
          {isLoading && (
            <span className={styles['search-loading']}>
              <div className='spinner-border text-secondary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </span>
          )}
          {this.state.searchResultsDisplay && !isLoading && (
            <CloseButtonCircleFillIcon
              width='16'
              height='16'
              fill='currentColor'
              onClickFunction={this.onClearFilter}
              classNameProp={`${styles['search-button']}`}
            />
          )}
        </div>
      </div>
    );
  };
  render() {
    const { allReports, isLoading, selectTargetDayForReports, userProfile } =
      this.props;
    const isCMStaff =
      userProfile.role.includes('Council Member') ||
      userProfile.under_council_member;

    if (isCMStaff) {
      this.state.sortFields = this.state.sortFields.filter(
        field => field.field != 'errors'
      );
    }

    const opReqsDisplay =
      allReports &&
      allReports.map((opReq, idx) => {
        let committees = opReq.committees.map((committee, idx) => (
          <p key={idx} className={styles['report-committee']}>
            {committee.committee_name}
          </p>
        ));
        let agencies = opReq.submitting_agency
          .map(agency => agency.name)
          .join(', ');
        let opReqTitle = opReq.title;

        if (this.state.screenWidth > 768) {
          opReqTitle = opReq.title;
          if (this.state.screenWidth < 992 && opReqTitle.length > 115) {
            opReqTitle = opReqTitle.substring(0, 115) + '...';
          } else if (this.state.screenWidth < 1200 && opReqTitle.length > 175) {
            opReqTitle = opReqTitle.substring(0, 175) + '...';
          }
        }

        let info = opReqTitle !== opReq.title;
        let localNextDue = formatDate(opReq.next_due);
        let nextDue = new Date(localNextDue).toDateString();
        let tileClass;
        switch (opReq.status.toLowerCase()) {
          case 'upcoming':
            tileClass = styles['upcoming'];
            break;
          case 'past_due':
            tileClass = styles['past-due'];
            break;
          case 'completed':
            tileClass = styles['completed'];
            break;
          case 'received':
            tileClass = styles['received'];
            break;
          default:
            tileClass = '';
            break;
        }
        let statusText = opReq.status.replace(/_/g, ' ');

        let lsrLegistar = opReq.ls_requests.filter(ls => ls.matters.length > 0);
        let lsrLocalLaw = []
        lsrLegistar.forEach(lsr => {
          lsrLocalLaw = lsrLocalLaw.concat(lsr.matters)
        });
        let allLocalLaws = lsrLocalLaw.concat(opReq.local_law);
        allLocalLaws = allLocalLaws.concat(opReq.legacy_local_law)
        let sortedLocalLaw = this.localLawSort(allLocalLaws)
          .map(ll => ll.law_number)
          .join(', ');

        return (
          <div
            key={idx}
            className={styles['report-block']}
            onClick={() => {
              selectTargetDayForReports([opReq]);
              document.body.style.overflow = 'hidden';
            }}>
            {this.state.screenWidth > 768 && (
              <>
                <div className={styles['report-first-block']}>
                  <div
                    className={`${styles['report-sidelabel-container']} ${tileClass}`}>
                    <p className={`${styles['report-sidelabel-text']}`}>
                      {statusText}
                    </p>
                  </div>
                  <div>
                    <div className={styles['report-sub-title']}>
                      Next Due Date
                    </div>
                    <div className={styles['report-last-date']}>
                      {opReq.next_due ? (
                        <>
                          <div>{nextDue.substr(4, 6).toUpperCase()}</div>
                          <div className={styles['report-last-date-year']}>
                            {nextDue.substr(11)}
                          </div>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles['report-middle-block']}>
                  <div className={styles['report-title']}>
                    {opReqTitle}
                    {info ? (
                      <ToolTip
                        id={`report-${opReq.id}`}
                        target={`report-${opReq.id}`}
                        text={opReq.title}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div>
                    {sortedLocalLaw && (
                      <>
                        <span>{sortedLocalLaw}</span>
                        <br />
                      </>
                    )}
                    <span className={styles['report-sub-title']}>Type: </span>
                    <span className='capitalize'>{opReq.tracker_type}</span>
                    <br />
                    <span className={styles['report-sub-title']}>Agency: </span>
                    <span>{agencies}</span>
                  </div>
                </div>
                <div className={styles['report-end-block']}>
                  <div className={styles['report-date-padding']}>
                    Last Received {opReq.last_received
                      ? formatDate(opReq.last_received)
                      : 'N/A'}
                  </div>
                  <div className={styles['report-committees']}>
                    <span>Committees: </span>
                    {committees}
                  </div>
                </div>
              </>
            )}
            {this.state.screenWidth <= 768 && (
              <>
                <div
                  className={`${styles['report-sidelabel-container']} ${tileClass}`}>
                  <p className={`${styles['report-sidelabel-text']}`}>
                    {statusText}
                  </p>
                </div>
                <div style={{ display: 'block', float: 'left' }}>
                  <div>
                    <div className={styles['report-sub-title-mobile']}>
                      Op Req Title:
                    </div>
                    <div className={styles['report-sub-field-mobile']}>
                      {opReqTitle}
                      {info ? (
                        <ToolTip
                          id={`report-${opReq.id}`}
                          target={`report-${opReq.id}`}
                          text={opReq.title}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div>
                    <div className={styles['report-sub-title-mobile']}>
                      Type:
                    </div>
                    <div className={styles['report-sub-field-mobile']}>
                      <span className='capitalize'>{opReq.tracker_type}</span>
                    </div>
                  </div>
                  {sortedLocalLaw && (
                    <div>
                      <div className={styles['report-sub-title-mobile']}>
                        Local Law:
                      </div>
                      <div className={styles['report-sub-field-mobile']}>
                        {sortedLocalLaw}
                      </div>
                    </div>
                  )}
                  <div>
                    <div className={styles['report-sub-title-mobile']}>
                      Agency:
                    </div>
                    <div className={styles['report-sub-field-mobile']}>
                      {agencies}
                    </div>
                  </div>
                  <div>
                    <div
                      className={styles['report-sub-title-mobile-committee']}>
                      Committee:
                    </div>
                    <div className={styles['report-sub-field-mobile']}>
                      {committees}
                    </div>
                  </div>
                  <div>
                    <div className={styles['mobile-date']}>
                      Last Received
                      <span>
                        {opReq.last_received
                          ? formatDate(opReq.last_received)
                          : 'N/A'}
                      </span>
                    </div>
                    <div className={styles['mobile-date']}>
                      Next Due Date
                      <span>
                        {opReq.next_due ? formatDate(opReq.next_due) : 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            <ChevronRightIcon
              width='20'
              height='20'
              classNameProp={styles['icon']}
            />
          </div>
        );
      });
    // display the search area
    return (
      <div className='main-container' id='main-container'>
        <div className={styles['reports-search-container']}>
          {this.generateSearchBox()}
          <div className={styles['report-sort-and-filter-container']}>
            <span style={{ display: 'flex' }}>
              Tracker List&nbsp;
              <InfoCircleFilledIcon
                classNameProp={styles['user-guide-field-icon']}
                idProp='tracker'
              />
              <UncontrolledPopover
                placement='left'
                target={'tracker'}
                fade={false}
                trigger='hover'>
                <PopoverBody>
                  A tracker monitors reports, or milestones, over all due dates
                  mandated by a law or part of a law.
                </PopoverBody>
              </UncontrolledPopover>
            </span>
            <span className={styles['sort-filter-flex']}>
              <span className={styles['filter-label']}>Sort by</span>
              <div
                className={`dropdown ${
                  this.state.isDropdownOpen ? 'show' : ''
                }`}
                ref={node => {
                  this.dropdownRef = node;
                }}>
                <button
                  className={`btn dropdown-toggle d-flex align-items-center ${styles['dropdown-toggle']}`}
                  type='button'
                  id='dropdownMenuButton1'
                  onClick={this.toggleDropdown}
                  aria-haspopup='true'
                  aria-expanded={this.state.isDropdownOpen}>
                  {this.state.selectedSortBy}
                  <ChevronUpDownArrowsIcon
                    width='15'
                    height='15'
                    classNameProps={styles['chevron-icon']}
                  />
                </button>
                <ul
                  className={`dropdown-menu ${
                    this.state.isDropdownOpen ? 'show' : ''
                  } ${styles['dropdown-menu']} ${styles['filter']}`}
                  aria-labelledby='dropdownMenuButton1'>
                  {this.state.sortFields.map((c, idx) => (
                    <li key={idx}>
                      <a
                        className={`dropdown-item ${styles['status-dropdown-item']}`}
                        href='#'
                        onClick={() => {
                          if (this.state.selectedSortBy !== c.title) {
                            this.onColumnClick(c.field, c.title);
                            this.toggleDropdown();
                          }
                        }}>
                        {c.title}
                        {this.state.selectedSortBy === c.title && (
                          <CheckIcon
                            classNameProp={styles['dropdown-checkmark']}
                          />
                        )}
                      </a>
                    </li>
                  ))}
                  <li>
                    <hr className='dropdown-divider' />
                  </li>
                  <li>
                    <a
                      className={`dropdown-item ${styles['status-dropdown-item']}`}
                      onClick={e => {
                        this.onColumnClick(
                          this.state.sortBy,
                          this.state.selectedSortBy
                        );
                      }}>
                      Ascending
                      {this.state.selectedSortOrder === 'Ascending' && (
                        <CheckIcon
                          classNameProp={styles['dropdown-checkmark']}
                        />
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      className={`dropdown-item ${styles['status-dropdown-item']}`}
                      onClick={e => {
                        this.onColumnClick(
                          this.state.sortBy,
                          this.state.selectedSortBy
                        );
                      }}>
                      Descending
                      {this.state.selectedSortOrder === 'Descending' && (
                        <CheckIcon
                          classNameProp={styles['dropdown-checkmark']}
                        />
                      )}
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <button
                  className={`btn dropdown-toggle ${styles['filter-label']}`}
                  id='report-list-status'
                  type='button'>
                  Status
                  <span
                    className='badge'
                    style={{ color: '#002164', backgroundColor: '#c0d1f3' }}>
                    {this.countChecked()}
                  </span>
                </button>
                <UncontrolledPopover
                  innerClassName={styles['popover-container']}
                  className={styles['popover-outer-container']}
                  trigger='legacy'
                  placement='bottom'
                  target='report-list-status'>
                  <PopoverBody
                    className={styles['list-filter-dropdown-wrapper']}>
                    <h6 className='dropdown-header pb-0'>Type</h6>
                    <label
                      className={`${styles['color-filter-container']} ${styles['completed']}`}>
                      Report
                      <input
                        type='checkbox'
                        checked={this.state.filterCombination.displayReports}
                        onChange={() => {
                          this.setState(prevState => {
                            prevState.filterCombination['displayReports'] =
                              !prevState.filterCombination['displayReports'];
                            return {
                              filterCombination: prevState.filterCombination,
                            };
                          });
                        }}
                      />
                      <span
                        className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                      <InfoCircleFilledIcon
                        classNameProp={styles['filter-popover-user-guide']}
                        idProp='report'
                      />
                    </label>

                    <label
                      className={`${styles['color-filter-container']} ${styles['completed']}`}>
                      Milestone
                      <input
                        type='checkbox'
                        checked={this.state.filterCombination.displayMilestones}
                        onChange={() => {
                          this.setState(prevState => {
                            prevState.filterCombination['displayMilestones'] =
                              !prevState.filterCombination['displayMilestones'];
                            return {
                              filterCombination: prevState.filterCombination,
                            };
                          });
                        }}
                      />
                      <span
                        className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                      <InfoCircleFilledIcon
                        classNameProp={styles['filter-popover-user-guide']}
                        idProp='milestone'
                      />
                    </label>

                    <UncontrolledPopover
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='report'>
                      <PopoverBody>
                        <div>
                          <b>Reports:</b> Reports are usually files mandated
                          often on a periodic basis by local law.
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>

                    <UncontrolledPopover
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='milestone'>
                      <PopoverBody>
                        <div>
                          <b>Milestones:</b> Milestones are one time goals that
                          are to be met.
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                    <hr className='dropdown-divider' />
                    <h6 className='dropdown-header pb-0'>Progress</h6>
                    <label
                      className={`${styles['color-filter-container']} ${styles['completed']}`}>
                      <CheckmarkIcon
                        width='30px'
                        height='20px'
                        classNameProp={styles['status-icons']}
                      />
                      Completed
                      <input
                        type='checkbox'
                        checked={this.state.filterCombination.completeFilter}
                        onChange={() => {
                          this.setState(prevState => {
                            prevState.filterCombination['completeFilter'] =
                              !prevState.filterCombination['completeFilter'];
                            return {
                              filterCombination: prevState.filterCombination,
                            };
                          });
                        }}
                      />
                      <span
                        className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                      <InfoCircleFilledIcon
                        classNameProp={styles['filter-popover-user-guide']}
                        idProp='completed'
                      />
                    </label>

                    <label
                      className={`${styles['color-filter-container']} ${styles['received']}`}>
                      <HalfCirlcleIcon
                        width='30px'
                        height='20px'
                        classNameProp={styles['status-icons']}
                      />
                      Received
                      <input
                        type='checkbox'
                        checked={this.state.filterCombination.receivedFilter}
                        onChange={() => {
                          this.setState(prevState => {
                            prevState.filterCombination['receivedFilter'] =
                              !prevState.filterCombination['receivedFilter'];
                            return {
                              filterCombination: prevState.filterCombination,
                            };
                          });
                        }}
                      />
                      <span
                        className={`${styles['color-filter-checkmark']} ${styles['received']}`}></span>
                      <InfoCircleFilledIcon
                        classNameProp={styles['filter-popover-user-guide']}
                        idProp='received'
                      />
                    </label>

                    <label
                      className={`${styles['color-filter-container']} ${styles['upcoming']}`}>
                      <FlagFilledIcon
                        width='30px'
                        height='20px'
                        classNameProp={styles['status-icons']}
                      />
                      Upcoming
                      <input
                        type='checkbox'
                        checked={this.state.filterCombination.upcomingFilter}
                        onChange={() => {
                          this.setState(prevState => {
                            prevState.filterCombination['upcomingFilter'] =
                              !prevState.filterCombination['upcomingFilter'];
                            return {
                              filterCombination: prevState.filterCombination,
                            };
                          });
                        }}
                      />
                      <span
                        className={`${styles['color-filter-checkmark']} ${styles['upcoming']}`}></span>
                      <InfoCircleFilledIcon
                        classNameProp={styles['filter-popover-user-guide']}
                        idProp='upcoming'
                      />
                    </label>

                    <label
                      className={`${styles['color-filter-container']} ${styles['past-due']}`}>
                      <ClockIcon
                        width='30px'
                        height='20px'
                        classNameProp={styles['status-icons']}
                      />
                      Past Due
                      <input
                        type='checkbox'
                        checked={this.state.filterCombination.pastDueFilter}
                        onChange={() => {
                          this.setState(prevState => {
                            prevState.filterCombination['pastDueFilter'] =
                              !prevState.filterCombination['pastDueFilter'];
                            return {
                              filterCombination: prevState.filterCombination,
                            };
                          });
                        }}
                      />
                      <span
                        className={`${styles['color-filter-checkmark']} ${styles['past-due']}`}></span>
                      <InfoCircleFilledIcon
                        classNameProp={styles['filter-popover-user-guide']}
                        idProp='past-due'
                      />
                    </label>
                    <button
                      className={styles['apply-button-filter']}
                      onClick={this.onGetOpReqSearchResult}>
                      Apply Filter
                    </button>
                    <UncontrolledPopover
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='completed'>
                      <PopoverBody>
                        <div>
                          <b>Completed:</b> A Operational Requirement whose
                          final report has been submitted with no further Op Req
                          due in the future, or a completed milestone.
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>

                    <UncontrolledPopover
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='received'>
                      <PopoverBody>
                        <div>
                          <b>Received:</b> A Operational Requirement with a
                          report due in the future which has already been
                          received.
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>

                    <UncontrolledPopover
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='upcoming'>
                      <PopoverBody>
                        <div>
                          <b>Upcoming: </b> A Operational Requirement whose most
                          recently due (past) report was received, with a future
                          report that has yet to be submitted, or a milestone
                          not yet due.
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>

                    <UncontrolledPopover
                      fade={false}
                      trigger='hover'
                      placement='bottom'
                      target='past-due'>
                      <PopoverBody>
                        <div>
                          <b>Past due:</b> A Operational Requirement whose most
                          recently due (past) report has not been submitted with
                          future Op Req not submitted, or an incomplete
                          milestone past its due date.
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </span>
          </div>
        </div>
        <div className={styles['report-list-container']}>
          {allReports.length > 0 && opReqsDisplay}
        </div>
        {isLoading && (
          <div className='loading-container'>
            <img
              className='loading-image'
              src='/img/newLoadingAnimation.gif'
              alt='Loading animation'
            />
            <h3>
              Loading <span className='dot1'>.</span>
              <span className='dot2'>.</span>
              <span className='dot3'>.</span>
            </h3>
          </div>
        )}
        {allReports.length === 0 && (
          <div className={styles['no-valid-result-container']}>
            <img
              src='/../img/no-results-found.svg'
              alt='No valid results'></img>
            <p>No valid result</p>
          </div>
        )}
        {(!this.props.mode || this.props.mode === 'list') && (
          <ReportServicePopup match={this.props.match} currentMode='list' />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  //Deep copy the arrays from the state
  const committees = state.userReducer.committees
    .slice(0)
    .filter(com => com.committee_name !== 'Unassigned');
  const agencies = state.reportTrackingReducer.allAgencies.slice(0);
  committees.unshift({ id: 0, committee_name: 'Select Committee' });
  agencies.unshift({ id: 0, name: 'Select Agency' });
  return {
    userProfile: state.userReducer.userProfile,
    isLoading: state.reportTrackingReducer.isLoading,
    nextAPI: state.reportTrackingReducer.nextAPI,
    allReports: state.reportTrackingReducer.allReports,
    committees: committees,
    agencies: agencies,
  };
};

export default connect(mapStateToProps, actions)(ReportsTable);
