import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  loadLsRequests,
  loadLsRequestStatusApprovalList,
  clearLsRequestArray,
  clickedLsRequest,
  fetchOneLsr,
  openOrCloseLsRequestServicePopup,
} from '../../actions/lsRequestAction';
import { LsFields } from '../../services/LsFields';
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import LsRequestServicesPopup from './LsRequestServicesPopup';
import NewLsMenu from './NewLsMenu';
import LsRequestsStatusTable from './LsRequestsStatusTable';
import Sidebar from 'react-sidebar';
import LsInfoDisplay from './LsInfoDisplay';
import { adminRoles, nonLegDivisionRoles } from '../../services/constants';
import { useParams } from 'react-router-dom';

const defaultColumns = [
  'LSR #',
  'Sponsor',
  'LSR Type',
  'Describe The Problem',
  'Inspiration For LSR',
  'Legislative Solution',
  'Status',
  window.location.href.includes('myActiveLs') && 'Last Activated',
].join(',');
const orderedFields = [...LsFields].sort((a, b) =>
  a.table_order > b.table_order ? 1 : -1
);
const make_first_prime_public_index = orderedFields.findIndex(
  item => item.field === 'make_first_prime_public'
);
orderedFields[make_first_prime_public_index].title = 'Waived Confidentiality';

const LsRequestStatus = props => {
  /** Column States */
  const [columnPreset, setColumnPreset] = useState(
    localStorage.getItem('active-table-column') ||
      `column-${props.userProfile.username}`
  );
  localStorage.setItem('active-table-column', columnPreset);

  const allColumns = nonLegDivisionRoles.includes(props.userProfile.role)
    ? orderedFields.filter(field => field.viewability !== 'staffOnly')
    : orderedFields;

  let savedColumns = localStorage.getItem(columnPreset) || defaultColumns;
  const presetColumns = allColumns.map(column => {
    if (
      savedColumns.includes(column.title) ||
      (column.title === 'Last Activated' &&
        window.location.href.includes('myActiveLs'))
    ) {
      return { ...column, show: true };
    } else {
      return { ...column, show: false };
    }
  });

  const [columns, setColumns] = useState(presetColumns);
  const [showColumnMenu, setShowColumnMenu] = useState(false);
  const checkAllColumns = columns.every(col => col.show);

  let columnsToSave = columns.reduce((acc, column) => {
    if (column.show) {
      acc.push(column.title);
    }
    return acc;
  }, []);
  localStorage.setItem(columnPreset, columnsToSave.join(','));

  /** Filter States */
  const defaultFilterValues = {
    'committee': { check: false, value: [], locked: false },
    'submitted_by': { check: false, value: [], locked: false },
    'division': { check: false, value: [], locked: false },
    'first_prime': {
      check: nonLegDivisionRoles.includes(props.userProfile.role)
        ? true
        : false,
      value: nonLegDivisionRoles.includes(props.userProfile.role)
        ? props.userProfile.under_council_member
          ? [props.userProfile.under_council_member.toString()]
          : [props.userProfile.id.toString()]
        : [],
      locked: nonLegDivisionRoles.includes(props.userProfile.role)
        ? true
        : false,
    },
    'first_in_time': { check: false, value: [], locked: false },
    'last_activated': {
      check: window.location.pathname.toLowerCase().includes('myactivels')
        ? true
        : false,
      value: window.location.pathname.toLowerCase().includes('myactivels')
        ? 'all'
        : '',
      locked: false,
    },
    'ls_number': { check: false, value: '', locked: false },
    'ls_type': { check: false, value: [], locked: false },
    'staff': {
      check:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? true
          : false,
      value:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? [props.userProfile.id.toString()]
          : [],
      locked:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? true
          : false,
    },
    'status': { check: false, value: [], locked: false },
  };

  let searchParams = new URLSearchParams(window.location.search);

  const [filterPreset, setFilterPreset] = useState(
    searchParams.has('lsr_batch')
      ? 'filter-lsr_batch'
      : localStorage.getItem('active-table-filter') ||
          `filter-${props.userProfile.username}`
  );

  let savedFilterValues =
    JSON.parse(localStorage.getItem(filterPreset)) || defaultFilterValues;

  savedFilterValues['last_activated'].check =
    window.location.pathname.includes('myActiveLs');
  savedFilterValues['last_activated'].value = 'all';

  if (filterPreset === 'filter-lsr_batch') {
    savedFilterValues['ls_number'].check = true;
    savedFilterValues['ls_number'].value = searchParams.get('lsr_batch');
  }

  const [filterValues, setFilterValues] = useState(savedFilterValues);

  if (filterPreset !== 'filter-lsr_batch') {
    localStorage.setItem('active-table-filter', filterPreset);
  }
  localStorage.setItem(filterPreset, JSON.stringify(filterValues));

  const [searchInput, setSearchInput] = useState('');
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  /** A flag for the filter to be conditionally rendered it depends on which parent */
  const [filterFlag, setFilterFlag] = useState(false);

  /** Other States */
  const [curtPopup, setCurtPopup] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  /** useEffect Props */
  const { fetchOneLsr } = props;
  const { ls_num } = useParams();

  const loadApprovalOnLoad = useCallback(
    props.loadLsRequestStatusApprovalList,
    []
  );

  /** Fired on component mount */
  useEffect(
    () => {
      if (props.userProfile.permissions.lsRequest_api.approve_status_change) {
        loadApprovalOnLoad();
      }
      try {
        if (ls_num) fetchOneLsr(ls_num, () => setSidebarOpen(true));
      } catch (e) {
        console.log(e);
      }
      if (filterFlag) {
        setFilterFlag(false);
      }
    },
    ls_num ? [ls_num, fetchOneLsr] : [fetchOneLsr]
  );

  /**
   * COLUMN HANDLING FUNCTIONS
   * * toggleColumn
   *   toggles a column to be shown or hidden
   * * setDefaultColumns
   *   reset selected columns to default columns
   * * changeColumnPreset
   *   change user selected column preset and select saved columns
   */
  function toggleColumn(title) {
    setColumns(cols =>
      cols.map(col => (col.title === title ? { ...col, show: !col.show } : col))
    );
  }

  function setDefaultColumns() {
    let columnView = allColumns.map(column => {
      if (defaultColumns.includes(column.title)) {
        return { ...column, show: true };
      } else {
        return { ...column, show: false };
      }
    });
    setColumns(columnView);
  }

  function changeColumnPreset(columnPresetOption) {
    localStorage.setItem('active-table-column', columnPresetOption);
    // If column preset already exists, set columns to preset values or default.
    if (localStorage.getItem(columnPresetOption)) {
      let savedColumns = localStorage.getItem(columnPresetOption).split(',');
      setColumns(cols =>
        cols.map(col => {
          col.show = savedColumns.includes(col.title);
          return col;
        })
      );
    } else {
      setDefaultColumns();
    }
    setColumnPreset(columnPresetOption);
  }

  // Check or uncheck all the checkbox
  function toggleAllColumns() {
    // If not already all checked, check all
    if (!checkAllColumns) {
      setColumns(cols => cols.map(col => ({ ...col, show: true })));
    } else {
      // otherwise, set to default
      setDefaultColumns();
    }
  }
  /**
   * * END COLUMN HANDLING FUNCTIONS
   */

  /**
   * onAddFilter
   * @param field: which field to add filter to
   * @param filter: value to be added to field value
   *
   * If the field is an LSR number, check if the number already exists first.
   *   If it doesn't exist and is not empty, add to field
   *
   * Otherwise, for other fields, check if the value already exists.
   *   If it doesn't exist, add filter
   *
   * If no filters previously existed for the field, activate check flag.
   * Calls state setter for filter values with updated filter values.
   */
  function onAddFilter(field, filter) {
    let values = { ...filterValues };
    if (field === 'ls_number' && filter.length > 0) {
      // prevent duplicates
      let ls_nums_to_add = filter.split(',');
      let current_ls_nums =
        values[field].value.length > 0 ? values[field].value.split(',') : [];
      ls_nums_to_add.forEach(ls_num => {
        if (!current_ls_nums.includes(ls_num) && ls_num.length > 0) {
          current_ls_nums.push(ls_num);
        }
      });
      values[field].value = current_ls_nums.join(',');
      if (!values[field].check) values[field].check = true;
    } else {
      if (!values[field].value.includes(filter)) {
        values[field].value.push(filter);
        if (!values[field].check) values[field].check = true;
      }
    }
    setFilterValues(values);
  }

  /**
   * * END FILTER HANDLING FUNCTIONS
   */

  // Save selected request information
  function onGetLsRequest(ls) {
    props.clickedLsRequest(ls || props.updateTargetLs, 'dropdown');
  }

  function onOpenOrCloseSidebar(isOpen) {
    setSidebarOpen(isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'auto';
    }
  }

  function onOpenCreate() {
    setCurtPopup('create');
    props.openOrCloseLsRequestServicePopup(true);
  }

  function loadMoreLsRequests() {
    if (props.nextApi) {
      props.loadLsRequestStatusApprovalList();
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center p-3 border-bottom fs-5 fw-bolder mb-2'>
        <span>Status Approval Requests</span>
        <button
          type='button'
          className={`btn-close ${props.subLS ? 'p-2 rounded-3' : ''}`}
          onClick={props.onClose}
          aria-label='Close'></button>
      </div>
      <NewLsMenu
        filterFlag={filterFlag}
        columns={columns}
        columnPreset={columnPreset}
        showColumnMenu={showColumnMenu}
        checkAllColumns={checkAllColumns}
        toggleColumn={toggleColumn}
        toggleColumnMenu={() => setShowColumnMenu(!showColumnMenu)}
        changeColumnPreset={changeColumnPreset}
        toggleAllColumns={toggleAllColumns}
        onOpenCreate={onOpenCreate}
        sidebarOpen={sidebarOpen}
        filterValues={filterValues}
        onAddFilter={onAddFilter}
      />
      {
        <LsRequestsStatusTable
          id='lsTable'
          columns={columns}
          onGetLsRequest={onGetLsRequest}
          loadMoreLsRequests={loadMoreLsRequests}
          onOpenOrCloseSidebar={e => onOpenOrCloseSidebar(e)}
        />
      }
      {/* {props.lsRequestServicePopupStatus && (
        <LsRequestServicesPopup currentDisplay={curtPopup} />
      )} */}
      {sidebarOpen && (
        <Sidebar
          onSetOpen={e => {
            onOpenOrCloseSidebar(e);
          }}
          open={sidebarOpen}
          pullRight={true}
          sidebar={
            <LsInfoDisplay
              infoLocation='sidebar'
              onChangeToUpdate={() => setCurtPopup('update')}
              onOpenOrCloseSidebar={e => onOpenOrCloseSidebar(e)}
              onGetLsRequest={onGetLsRequest}
            />
          }
          styles={{
            sidebar: {
              background: 'white',
              zIndex: 4,
              overflow: 'hidden',
              maxWidth: '350px',
              width: '100%',
              marginTop: '200px',
              marginRight: '30px',
              borderRadius: '8px 8px 0px 0px',
              bottom: '30px',
            },
            overlay: { zIndex: 3 },
          }}>
          <div style={{ display: 'none' }}></div>
        </Sidebar>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    lsRequests: state.lsRequestsReducer.lsRequests,
    lsrApproval: state.lsRequestsReducer.lsRequestStatusApprovals,
    userProfile: state.userReducer.userProfile,
    lsRequestServicePopupStatus:
      state.lsRequestsReducer.lsRequestServicePopupStatus,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    nextApi: state.lsRequestsReducer.nextApi,
  };
};

const mapDispatchToProps = {
  loadLsRequests,
  loadLsRequestStatusApprovalList,
  clearLsRequestArray,
  clickedLsRequest,
  fetchOneLsr,
  openOrCloseLsRequestServicePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsRequestStatus);
