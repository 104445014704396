import React from 'react';
import styles from '../styles/VideoTutorials.module.scss';

const VideoCard = props => {
    const { section } = props;
    let sectionVideos = section.videos;
    if (sectionVideos.length === 0) return <></>;
    return (
        <>
            <div className='row mb-1'>
                <div className='col w-100'>
                    <h2 className='fs-3'>{section.name}</h2>
                </div>
            </div>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 pb-5'>
                {
                    sectionVideos.map((video, videoIndex) => {
                        return (
                            <div key={videoIndex} className='col'>
                                <div
                                    className={`card h-100 p-3 ${styles['video-card-container']}`}>
                                    <video
                                        width='auto'
                                        height='auto'
                                        controls
                                        className='rounded-3'>
                                        <source src={video.src} type={video.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                    <div className='card-body'>
                                        <h5 className='card-title fw-bolder fs-5'>
                                            { video.title }
                                        </h5>
                                        <p className='card-text'>{ video.description }</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default VideoCard;
  
