import React from 'react';
import {
  LsRequestIcon,
  ReportTrackingIcon,
  ClexIcon,
  ProclamationRequestIcon,
  CitationRequestIcon,
} from './SvgLibrary';
import { LsFields } from './LsFields';
import ReportFields from './ReportFields';

export const notificationsInfo = {
  '1': {
    app: notification =>
      `New LS Request #${notification.content_object.ls_number} Created`,
    icon: <LsRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/lsRequestList/${notification.content_object.ls_number}`,
    notificationText: notification =>
      `LSR ${notification.content_object.ls_number} has been created.`,
  },
  '2': {
    app: notification =>
      `LS Request #${notification.content_object.ls_number} Updated`,
    icon: <LsRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/lsRequestList/${notification.content_object.ls_number}`,
    notificationText: notification =>
      notification.updated_fields && notification.updated_fields.length
        ? `LSR ${notification.content_object.ls_number} fields updated: ${
            notification.updated_fields &&
            notification.updated_fields
              .map(
                ele =>
                  LsFields.filter(field => field.field === ele)[0]
                    .notification_field_title
              )
              .sort()
              .join(', ')
          }.`
        : `LSR ${notification.content_object.ls_number} has been updated.`,
  },
  '3': {
    app: notification => 'New Operational Requirement Created',
    icon: <ReportTrackingIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/opReqs/${notification.content_object.id}`,
    notificationText: notification =>
      `Operational requirement for "${notification.content_object.title}" has been created.`,
  },
  '4': {
    app: notification => 'Operational Requirement Updated',
    icon: <ReportTrackingIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/opReqs/${notification.content_object.id}`,
    notificationText: notification =>
      notification.updated_fields && notification.updated_fields.length
        ? `Operational requirement for "${
            notification.content_object.title
          }" fields updated: ${
            notification.updated_fields &&
            notification.updated_fields
              .map(
                ele =>
                  ReportFields.filter(field => field.field === ele)[0]
                    .notification_field_title
              )
              .sort()
              .join(', ')
          }.`
        : `Operational requirement for "${notification.content_object.title}" has been updated.`,
  },
  '5': {
    app: notification => 'New CLEX Entry Created',
    icon: <ClexIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/myTasks/myCLEXReviews`,
    notificationText: notification =>
      `CLEX Subject/Page entitled "${notification.content_object.title}" has been created.`,
  },
  '6': {
    app: notification => 'CLEX Entry Updated',
    icon: <ClexIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/myTasks/myCLEXReviews`,
    notificationText: notification =>
      `CLEX Subject/Page entitled "${notification.content_object.title}" has been updated.`,
  },
  '7': {
    app: notification => 'LSR Activation Requested',
    icon: <LsRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/myTasks/allMyLs`,
    notificationText: notification =>
      `LSR ${notification.content_object.ls_number} activation has been requested.`,
  },
  '8': {
    app: notification => 'New Proclamation Request',
    icon: <ProclamationRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/proclamation-requests`,
    notificationText: notification =>
      `CM ${notification.content_object.council_member.full_name} has submitted a proclamation honoring ${notification.content_object.honoree} needed on ${notification.content_object.date_proc_needed}`,
  },
  '9': {
    app: notification => 'Proclamation Request Updated',
    icon: <ProclamationRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/proclamation-requests`,
    notificationText: notification =>
      `Proclamation request for "${notification.content_object.honoree}" on date ${notification.content_object.date_of_event} has been updated.`,
  },
  '10': {
    app: notification => 'New Citation Request',
    icon: <CitationRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/citation-requests`,
    notificationText: notification =>
      `CM ${notification.content_object.council_member.full_name} has submitted a citation honoring ${notification.content_object.honoree} needed on ${notification.content_object.date_citation_needed}`,
  },
  '11': {
    app: notification => 'Citation Request Updated',
    icon: <CitationRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) => `/citation-requests`,
    notificationText: notification =>
      `Citation request "${notification.content_object.honoree}" on date ${notification.content_object.date_of_event} has been updated.`,
  },
  '12': {
    app: notification => 'Approval Required: LSR Status Change',
    icon: <LsRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/lsRequestList/${notification.content_object.ls_number}`,
    notificationText: notification =>
      `LSR ${notification.content_object.ls_number} has a change in status that requires approval.`,
  },
  '13': {
    app: notification => 'LSR Status Change Approved',
    icon: <LsRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/lsRequestList/${notification.content_object.ls_number}`,
    notificationText: notification =>
      `LSR ${notification.content_object.ls_number}'s status change has been approved.`,
  },
  '14': {
    app: notification => 'LSR Status Change Rejected',
    icon: <LsRequestIcon height='18px' width='18px' />,
    redirectUrl: (role, notification) =>
      `/lsRequestList/${notification.content_object.ls_number}`,
    notificationText: notification =>
      `LSR ${notification.content_object.ls_number}'s status change has been rejected.`,
  },
};
