import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/ReportServicePopup.module.scss';
import {
  changeCitationRequestPopupWindow,
  changeCitationRequestDisplay,
} from '../../actions/citationRequestAction';
import { downloadFileStatus } from '../../actions/fileDownloadAction';
import Modal from 'react-modal';
import CreateNewCitationRequest from './CreateNewCitationRequest';
import UpdateCitationRequest from './UpdateCitationRequest';
import CitationRequestsForDay from './CitationRequestsForDay';

const CitationRequestServicePopup = props => {
  // Initial all the status
  const onCloseWindow = () => {
    props.changeCitationRequestPopupWindow(false);
    props.changeCitationRequestDisplay('list');
    props.downloadFileStatus(null);
    document.body.style.overflow = 'auto';
  };

  const onBackToList = () => {
    props.changeCitationRequestDisplay('list');
  };

  return ReactDOM.createPortal(
    <Modal
      isOpen={props.openCitationRequestPopupStatus}
      className={styles['report-modal-style']}
      overlayClassName={styles.modalOverlay}
      onRequestClose={onCloseWindow}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}>
      {['list', 'detail', 'update'].includes(props.currentDisplay) && (
        <CitationRequestsForDay
          selectedDay={props.selectedDay}
          currentMode={props.currentMode}
          onCloseWindow={onCloseWindow}
          onBackToService={onBackToList}
          shownStatuses={props.shownStatuses}
        />
      )}
      {props.currentDisplay === 'create' && (
        <CreateNewCitationRequest onCloseWindow={onCloseWindow} />
      )}
      {props.currentDisplay === 'update' && (
        <UpdateCitationRequest onCloseWindow={props.onCloseWindow} />
      )}
    </Modal>,
    document.getElementById('portal-root')
  );
};

const mapStateToProps = state => {
  return {
    openCitationRequestPopupStatus:
      state.citationRequestReducer.openCitationRequestPopupStatus,
    currentDisplay: state.citationRequestReducer.currentDisplay,
    citationRequestDetail: state.citationRequestReducer.selectedCitationRequest,
  };
};

const mapDispatchToProps = {
  changeCitationRequestPopupWindow,
  changeCitationRequestDisplay,
  downloadFileStatus,
};

// export default CitationRequestServicePopup

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CitationRequestServicePopup);
