import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from '../styles/report-tracking/ReportTracking.module.scss';
import Calendar from '../components/report-tracking/Calendar';
import ReportsTable from '../components/report-tracking/ReportsTable';
import {
  changeReportTrackingDisplay,
  changeReportTrackingPopupWindow,
  getAllReports,
  getReportsDueCurrentMonthAndYear,
  openNotificationDetailReport,
} from '../actions/reportTrackingAction';
import { CalendarIcon, ListIcon } from '../services/SvgLibrary';
import { UncontrolledTooltip } from 'reactstrap';

const ReportTracking = props => {
  const [displayMode, setDisplayMode] = useState(
    localStorage.getItem('opReqDisplayMode') || 'calendar'
  );
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [switched, setSwitched] = useState(false);
  const [closeInitiatedByButtonClick, setCloseInitiatedByButtonClick] =
    useState(false);
  const params = useParams();

  useEffect(() => {
    const currentDate = new Date();
    props.getReportsDueCurrentMonthAndYear(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
    props.getAllReports();
    if (params.id) {
      props.openNotificationDetailReport(params.id);
      props.changeReportTrackingDisplay('detail');
    }
    if (!localStorage.getItem('opReqDisplayMode')) {
      localStorage.setItem('opReqDisplayMode', 'list');
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('opReqDisplayMode') !== displayMode) {
      localStorage.setItem('opReqDisplayMode', displayMode);
    }
  }, [displayMode]);

  const toCalendarView = () => {
    setDisplayMode('calendar');
  };

  const handleShown = () => {
    setIsOffcanvasOpen(true);
    setDisplayMode('list');
  };

  const handleHidden = () => {
    setIsOffcanvasOpen(false);
    setDisplayMode('calendar');
  };

  const handleToggle = () => {
    setIsOffcanvasOpen(prevIsOffcanvasOpen => !prevIsOffcanvasOpen);
  };

  const handleClose = () => {
    setIsOffcanvasOpen(false);
    setDisplayMode('calendar');
    setCloseInitiatedByButtonClick(true);
  };

  return (
    <>
      {props.dueReports.length !== 0 && displayMode === 'calendar' && (
        <Calendar
          displayMode={displayMode}
          params={params}
          switched={switched}
          changeReportTrackingDisplay={props.changeReportTrackingDisplay}
          changeReportTrackingPopupWindow={
            props.changeReportTrackingPopupWindow
          }
          userProfile={props.userProfile}
        />
      )}
      {props.isLoading && displayMode === 'calendar' && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {displayMode === 'calendar' && (
        <UncontrolledTooltip placement='left' target='tracker-tab'>
          A tracker monitors reports, or milestones, over all due dates
          mandated by a law or part of a law.
        </UncontrolledTooltip>
      )}
      <div
        className={`offcanvas-container ${isOffcanvasOpen ? 'open' : ''}`}
        >
        <button
          id='tracker-tab'
          className={styles['display-mode-button']}
          onClick={() => {
            if (displayMode === 'calendar') {
              setDisplayMode('list');
            } else if (displayMode === 'list') {
              let currentDate = new Date();
              props.getReportsDueCurrentMonthAndYear(
                currentDate.getMonth() + 1,
                currentDate.getFullYear()
              );
              setDisplayMode('calendar');
              setSwitched(true);
            }
          }}>
          {displayMode === 'list' ? <CalendarIcon /> : <ListIcon />}
          {` ${displayMode === 'list' ? 'Calendar' : 'Tracker'} View`}
        </button>
      </div>
      {displayMode === 'list' && (
        <ReportsTable toCalendar={() => setDisplayMode('calendar')} />
      )}
      {/* <button 
          className={ [...legDivisionRoles, 'Finance Attorney', 'Land Use Attorney'].includes(this.props.userProfile.role) ? `${styles['create-report-button']}` : `${styles['non-leg-staff']} ${styles['create-report-button']}`}
          onClick={() => {
            this.props.changeReportTrackingDisplay('create');
            this.props.changeReportTrackingPopupWindow(true);
          }}
        >
          <PlusIcon/>
        </button>  */}
      {/* <div className={`offcanvas-container ${this.state.isOffcanvasOpen ? 'open' : ''}`}>
          <button 
            className={`${styles["display-mode-button"]} ${!this.state.isOffcanvasOpen && styles["enlarge"]}`}
            aria-controls="offcanvasReports"
            onClick={this.handleToggle}
          >
            { this.state.isOffcanvasOpen ? <ArrowCircleFillRightIcon classNameProp={styles["arrow"]}/> : <ArrowCircleFillLeftIcon classNameProp={styles["arrow"]}/> }
            { this.state.isOffcanvasOpen ? 'Hide Trackers' : 'Show Trackers' }
          </button>
          <div ref={this.offCanvasRef} className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasReports" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body">
              <ReportsTable mode={this.state.displayMode} toCalendar={()=>this.setState({displayMode: 'calendar'})} />
            </div>
          </div>
        </div> */}
    </>
  );
};

const mapStateToProps = state => {
  return {
    dueReports: state.reportTrackingReducer.dueReports,
    isLoading: state.reportTrackingReducer.isLoading,
    userProfile: state.userReducer.userProfile,
  };
};
const mapDispatchToProps = {
  changeReportTrackingPopupWindow,
  getAllReports,
  getReportsDueCurrentMonthAndYear,
  changeReportTrackingDisplay,
  openNotificationDetailReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTracking);
