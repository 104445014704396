import { call, put, takeEvery } from 'redux-saga/effects';
import { getTargetFiles, delay } from '../utils/helper';
import axios from 'axios';
import { url } from '../config';
import {
  procRequestActions,
  saveProcRequestCurrentMonthAndYear,
  saveTargetProcRequests,
  changeProcRequestPopupWindow,
  createNewProcRequestStatus,
  updateProcRequestStatus,
  setIsLoading,
  setIsCalendarLoading,
  saveAllProcRequests,
  filterProcRequest,
  saveProgress,
  saveNextAPI,
} from '../actions/procRequestAction';

// HTTP.GET to get reports due selected month and year
// Will return a list of objects
// Assume for each month we will have 31 days
function sendProcRequestByDateRequest(month, year, sortBy = true) {
  return axios.get(url + '/api/procRequest/procRequestByDate/', {
    params: { month: month, year: year, sort_by: sortBy ? 'True' : 'False' },
  });
}

// function for saving the static form data for report
function saveProcRequestStaticData(proclamationRequest) {
  const formData = new FormData();
  formData.append('council_member', proclamationRequest.council_member);
  formData.append('honoree', proclamationRequest.honoree);
  formData.append('reason', proclamationRequest.reason);
  formData.append('other', proclamationRequest.other);
  formData.append('date_of_event', proclamationRequest.date_of_event);
  formData.append(
    'date_proc_needed',
    proclamationRequest.date_proc_needed
      ? proclamationRequest.date_proc_needed
      : proclamationRequest.date_of_event
  );
  formData.append(
    'co_signers',
    proclamationRequest.co_signers.map(ele => ele.full_name).join()
  ); // MAP
  formData.append('notes', proclamationRequest.notes);
  formData.append('status', proclamationRequest.status || 'Pending');
  formData.append('requested_by_id', proclamationRequest.requested_by.id);
  proclamationRequest.is_archived
    ? formData.append('is_archived', true)
    : formData.append('is_archived', false);

  return formData;
}

// function for finish the promise of zipping the file
function* saveFileData(proclamationRequest, formData) {
  const proclamation_draft = yield call(
    getTargetFiles,
    proclamationRequest.proclamation_draft
  );
  const event_info_attachment = yield call(
    getTargetFiles,
    proclamationRequest.event_info_attachment
  );
  const honoree_info_attachment = yield call(
    getTargetFiles,
    proclamationRequest.honoree_info_attachment
  );

  if (proclamation_draft) {
    proclamation_draft.forEach((file, idx) => {
      if (idx) {
        formData.append(`proclamation_draft-${idx}`, file);
      } else {
        formData.append('proclamation_draft', file);
      }
    });
  } else {
    formData.append('proclamation_draft', '');
  }
  if (event_info_attachment) {
    event_info_attachment.forEach((file, idx) => {
      if (idx) {
        formData.append(`event_info_attachment-${idx}`, file);
      } else {
        formData.append('event_info_attachment', file);
      }
    });
  } else {
    formData.append('event_info_attachment', '');
  }
  if (honoree_info_attachment) {
    honoree_info_attachment.forEach((file, idx) => {
      if (idx) {
        formData.append(`honoree_info_attachment-${idx}`, file);
      } else {
        formData.append('honoree_info_attachment', file);
      }
    });
  } else {
    formData.append('honoree_info_attachment', '');
  }
  return formData;
}

// HTTP.POST for creating a new report
function sendCreateNewProcRequest(procRequest) {
  return axios.post(url + '/api/procRequest/procRequest/', procRequest);
}

function sendUpdateProcRequest(procRequest, id) {
  return axios.put(url + `/api/procRequest/procRequest/${id}/`, procRequest);
}

// HTTP.GET for getting all the reports
function getAllProcRequestsRequest(nextAPI, params) {
  let paramString = params.sort_by
    ? Object.keys(params).reduce((acc, curr, idx) => {
        return (
          acc +
          `${curr}=${params[curr]}` +
          (idx !== Object.keys(params).length - 1 ? '&' : '')
        );
      }, '')
    : '';
  if (nextAPI) {
    return axios.get(url + `/api/${nextAPI.split('/api/')[1]}&` + paramString);
  } else {
    return axios.get(
      url + '/api/procRequest/procRequest/?limit=100&' + paramString
    );
  }
}

function deleteFileRequest(file, field) {
  return axios.delete(
    `${url}/api/procRequest/deleteFile/${file}/?field=${field}`
  );
}

// Get the due reports and save them in the redux store
function* getProcRequestsResponse(action) {
  if (action !== undefined) {
    try {
      yield put(setIsCalendarLoading(true));
      // Get the response of certain month and year
      const response = yield call(
        sendProcRequestByDateRequest,
        action.month,
        action.year,
        action.sortBy
      );
      const { data } = response;
      yield put(saveProcRequestCurrentMonthAndYear(data));
      yield put(setIsCalendarLoading(false));
    } catch (e) {
      console.log(e);
    }
  }
}

// Save the due reports on selected date
// Save the data into the redux store before open the service winodw
function* saveSelectedProcRequests(action) {
  if (action !== undefined) {
    try {
      yield put(changeProcRequestPopupWindow(false));
      yield put(saveTargetProcRequests(action.procRequests));
      yield put(changeProcRequestPopupWindow(true));
    } catch (e) {
      yield put(changeProcRequestPopupWindow(false));
      yield put(setIsLoading(false));
      console.log(e);
    }
  }
}

// Create a new proc request
// Provides an object with all the parameters
function* createNewProcRequest(action) {
  if (action !== undefined) {
    try {
      yield put(setIsLoading(true));
      const formData = yield call(
        saveProcRequestStaticData,
        action.procRequest
      );
      yield call(saveFileData, action.procRequest, formData);
      yield call(sendCreateNewProcRequest, formData);
      yield put(saveProgress(null));
      yield put(createNewProcRequestStatus('success'));
      yield put(setIsLoading(false));
      yield call(delay);
      window.location.assign('/proclamation-requests');
    } catch (e) {
      yield put(createNewProcRequestStatus('fail'));
      yield put(setIsLoading(false));
      console.log(e);
    }
  }
}

function* updateProcRequest(action) {
  if (action !== undefined) {
    try {
      yield put(setIsLoading(true));
      const formData = yield call(
        saveProcRequestStaticData,
        action.procRequest
      );
      yield call(saveFileData, action.procRequest, formData);
      yield call(sendUpdateProcRequest, formData, action.procRequest.id);
      yield put(saveProgress(null));
      yield put(updateProcRequestStatus('success'));
      yield put(setIsLoading(false));
      yield call(delay);
      window.location.assign('/proclamation-requests');
    } catch (e) {
      yield put(updateProcRequestStatus('fail'));
      yield put(setIsLoading(false));
      console.log(e);
    }
  }
}

// Get all the reports
function* getAllProcRequestsList(action) {
  if (action !== undefined) {
    try {
      yield put(setIsLoading(true));
      const { data } = yield call(
        getAllProcRequestsRequest,
        action.nextAPI,
        action.params
      );
      yield put(saveAllProcRequests(data.results, action.nextAPI));
      yield put(filterProcRequest(data.results, action.nextAPI));
      yield put(saveNextAPI(data.next));
      yield put(setIsLoading(false));
    } catch (e) {
      console.log(e);
    }
  }
}

function* deleteFileResponse(action) {
  if (action !== undefined) {
    try {
      yield call(deleteFileRequest, action.id, action.field);
    } catch (e) {
      console.log(e);
    }
  }
}

function* procRequestAPI() {
  yield takeEvery(
    procRequestActions.GET_PROC_REQUEST_CURRENT_MONTH_AND_YEAR,
    getProcRequestsResponse
  );
  yield takeEvery(
    procRequestActions.GET_ALL_PROC_REQUESTS,
    getAllProcRequestsList
  );
  yield takeEvery(
    procRequestActions.CREATE_NEW_PROC_REQUEST,
    createNewProcRequest
  );
  yield takeEvery(procRequestActions.UPDATE_PROC_REQUEST, updateProcRequest);
  yield takeEvery(
    procRequestActions.SELECT_TARGET_DAY_FOR_PROC_REQUESTS,
    saveSelectedProcRequests
  );
  yield takeEvery(
    procRequestActions.DELETE_ATTACHMENT_FROM_DB,
    deleteFileResponse
  );
}
export default procRequestAPI;
