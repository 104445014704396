import { procRequestActions } from '../actions/procRequestAction';

const initialState = {
  openProcRequestPopupStatus: false,
  allProcRequestsForDays: [],
  allProcRequests: [],
  filteredProcRequests: [],
  newProcStatus: null,
  updateProcStatus: null,
  procRequestSearchString: '',
  procRequestSortedBy: 'council_member',
  procRequestSortDirection: 'asc',
  procRequestFilter: 'all',
  selectedProcRequest: {},
  currentDisplay: 'list',
  selectedDayProcRequests: [],
  savedProgress: null,
  cosigners: [],
  isLoading: false,
  nextAPI: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case procRequestActions.CHANGE_DISPLAY:
      return { ...state, currentDisplay: action.displayString };
    case procRequestActions.SAVE_PROC_REQUESTS_CURRENT_MONTH_AND_YEAR:
      return { ...state, allProcRequestsForDays: action.procRequests };
    case procRequestActions.CHANGE_PROC_REQUEST_POPUP_WINDOW:
      return { ...state, openProcRequestPopupStatus: action.status };
    case procRequestActions.SAVE_PROGRESS:
      return { ...state, savedProgress: action.procRequest };
    case procRequestActions.SAVE_TARGET_PROC_REQUESTS:
      return { ...state, selectedDayProcRequests: action.procRequests };
    case procRequestActions.SELECT_PROC_REQUEST:
      return { ...state, selectedProcRequest: { ...action.procRequest } };
    case procRequestActions.GET_ALL_PROC_REQUESTS:
      return {
        ...state,
        nextAPI: action.nextApi,
        procRequestSortedBy: action.params.sort_by,
        procRequestSortDirection: action.params.sort_direction,
        procRequestFilter: action.params.filter,
        procRequestSearchString: action.params.search_term,
      };
    case procRequestActions.SAVE_ALL_PROC_REQUESTS:
      const allProcRequests = action.nextAPI
        ? {
            ...state,
            allProcRequests: [...state.allProcRequests, ...action.procRequests],
          }
        : {
            ...state,
            filteredProcRequests: [...action.procRequests],
          };
      return allProcRequests;
    case procRequestActions.FILTER_PROC_REQUESTS:
      const filteredProcRequests = action.nextAPI
        ? {
            ...state,
            filteredProcRequests: [
              ...state.filteredProcRequests,
              ...action.procRequests,
            ],
          }
        : {
            ...state,
            filteredProcRequests: [...action.procRequests],
          };
      return filteredProcRequests;
    case procRequestActions.SET_SEARCH_STRING:
      return { ...state, procRequestSearchString: action.searchString };
    case procRequestActions.IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case procRequestActions.IS_CALENDAR_LOADING:
      return { ...state, isCalendarLoading: action.isLoading };
    case procRequestActions.CREATE_NEW_PROC_REQUEST_STATUS:
      return { ...state, newProcStatus: action.status };
    case procRequestActions.UPDATE_PROC_REQUEST_STATUS:
      return { ...state, updateProcStatus: action.status };
    case procRequestActions.APPLY_SORT:
      return {
        ...state,
        procRequestSortedBy: action.sortBy,
        procRequestSortDirection: action.sortDirection,
      };
    case procRequestActions.SAVE_NEXT_API:
      return { ...state, nextAPI: action.nextApi };
    default:
      return state;
  }
}
