import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/library/toggle.module.scss';

const Toggle = props => {
  return (
    <div className={styles[`${props.className}`]}>
      <label className={styles.switch}>
        <input
          type='checkbox'
          onChange={props.changeHandler}
          checked={props.value}
        />
        <span className={styles.slider}></span>
      </label>
      <label>{props.label}</label>
    </div>
  );
};
Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  label: PropTypes.string,
};
export default Toggle;
