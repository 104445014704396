import { all } from 'redux-saga/effects';
import lsRequestAPI from './lsRequest';
import userAPI from './user';
import wikiAPI from './wiki';
import reportTrackingAPI from './reportTracking';
import fileDownloadAPI from './fileDownload';
import myTasksAPI from './myTasks';
import procRequestAPI from './proclamationRequest';
import citationRequestAPI from './citationRequest';
import eventAPI from './event';

export default function* rootSaga() {
  yield all([
    lsRequestAPI(),
    userAPI(),
    wikiAPI(),
    citationRequestAPI(),
    procRequestAPI(),
    reportTrackingAPI(),
    fileDownloadAPI(),
    myTasksAPI(),
    eventAPI(),
  ]);
}
