import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import styles from '../../styles/ls-request/CreateNewLsRequest.module.scss';
import { ToolTipIcon } from '../../services/SvgLibrary';
const ToolTip = props => {
  return (
    <span data-toggle='tooltip'>
      <ToolTipIcon
        classNameProp={styles['user-guide-field-icon']}
        idProp={props.id}
      />
      <UncontrolledPopover
        innerClassName={styles['popover-container']}
        className={styles['popover-outer-container']}
        fade={false}
        trigger='hover'
        placement='right'
        target={props.target}>
        <PopoverBody>
          <div>{props.text}</div>
        </PopoverBody>
      </UncontrolledPopover>
    </span>
  );
};

ToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ToolTip;
