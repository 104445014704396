import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import styles from '../../../styles/user/FootstepsAnimation.module.scss';

export const FootstepsAnimationMain = ({
  upperClass,
  lowerClass,
  footstepClass,
  wrap,
  arrayOfSteps,
  rotation,
}) => {
  const containerStyle = {
    transform: rotation,
  };

  useEffect(() => {
    // Animation timeline
    const timeline = gsap.timeline();

    // Select all footsteps images in upper and lower sections
    const upperFootsteps = document.querySelectorAll(
      `.${styles[upperClass]} .${styles[footstepClass]}`
    );
    const lowerFootsteps = document.querySelectorAll(
      `.${styles[lowerClass]} .${styles[footstepClass]}`
    );

    // Calculate a delay factor for adjusting the delay between footsteps
    const delayFactor = 0.07;

    const totalFootsteps = Math.min(
      upperFootsteps.length,
      lowerFootsteps.length
    );

    for (let index = 0; index < totalFootsteps; index++) {
      animateFootstep(upperFootsteps[index], timeline, delayFactor * index);
      animateFootstep(lowerFootsteps[index], timeline, delayFactor * index);
    }
  }, []);

  const animateFootstep = (footstep, timeline, delay) => {
    const fadeInDuration = 0.005;
    const fadeOutDuration = 0.005;
    const totalStepDuration = fadeInDuration + fadeOutDuration;

    // Set initial opacity
    gsap.set(footstep, { opacity: 0 });

    // Calculate the total delay for each step
    const totalDelay = delay * totalStepDuration;

    // Add animations to the timeline
    timeline
      .to(footstep, {
        opacity: 1,
        duration: fadeInDuration,
        delay: totalDelay,
      })
      .to(footstep, {
        opacity: 0,
        duration: fadeOutDuration,
        delay: totalDelay + fadeInDuration + 0.5, // Add a fixed delay between fading in and fading out
      });

    setTimeout(() => {
      const upperFootsteps = document.querySelectorAll(
        `.${styles[upperClass]} .${styles[footstepClass]}`
      );
      const lowerFootsteps = document.querySelectorAll(
        `.${styles[lowerClass]} .${styles[footstepClass]}`
      );
      const fadeDuration = 1.07;

      for (let i = 0; i < upperFootsteps.length; i++) {
        const upperCurrentFootstep = upperFootsteps[i];
        const lowerCurrentFootstep = lowerFootsteps[i];
        const fadeInDelay = i * fadeDuration;

        // Upper Steps Trail
        if (i > 0) {
          const upperPreviousFootstep = upperCurrentFootstep;
          gsap.set(upperPreviousFootstep, { opacity: 0 });
          gsap.to(upperPreviousFootstep, {
            opacity: 0.4,
            duration: fadeDuration,
            delay: fadeInDelay,
          });

          gsap.to(upperPreviousFootstep, {
            opacity: 0,
            duration: fadeDuration,
            delay: fadeInDelay + 0.3,
          });
        }

        // Lower Steps Trail
        if (i > 0) {
          const lowerPreviousFootstep = lowerCurrentFootstep;
          gsap.set(lowerPreviousFootstep, { opacity: 0 });
          gsap.to(lowerPreviousFootstep, {
            opacity: 0.4,
            duration: fadeDuration,
            delay: fadeInDelay + 0.5,
          });
          gsap.to(lowerPreviousFootstep, {
            opacity: 0,
            duration: fadeDuration + 0.5,
            delay: fadeInDelay + 0.9,
          });
        }
      }
    }, totalDelay * 1000); // Convert to milliseconds
  };

  return (
    <div className={styles[wrap]} style={containerStyle}>
      <div className={styles[upperClass]}>
        {Array.from({ length: arrayOfSteps }).map((_, index) => (
          <img
            key={index}
            src={`${process.env.PUBLIC_URL}/img/floorPlanFootsteps/leftfoot.png`}
            alt={`footstep-${index}`}
            className={styles[footstepClass]}
          />
        ))}
      </div>
      <div className={styles[lowerClass]}>
        {Array.from({ length: arrayOfSteps }).map((_, index) => (
          <img
            key={index}
            src={`${process.env.PUBLIC_URL}/img/floorPlanFootsteps/rightfoot.png`}
            alt={`footstep-${index}`}
            className={styles[footstepClass]}
          />
        ))}
      </div>
    </div>
  );
};
