import React from 'react';
import version from '../../../package.json';
import UploadReport from './UploadReport';
import { logout } from '../../utils/helper';
import styles from '../../styles/report-tracking/Agency.module.scss';
import { Row, Col } from 'reactstrap';
import { ShortRightArrowIcon } from '../../services/SvgLibrary';

const AgencyView = () => {
  const onLogout = e => {
    logout();
  };
  return (
    <div>
      <div className={styles['header-container']}>
        <img
          className={styles['login-logo']}
          src='/img/Logo/iwomm white logo.png'
          alt='IWOMM logo'
          width='100'
        />
        <h1 className={styles['agency-heading']}>
          Agency Operational Requirements
        </h1>
        <button className={styles['logout']} onClick={onLogout}>
          Logout <ShortRightArrowIcon onClickFunction={onLogout} />
        </button>
      </div>
      <Row style={{ alignItems: 'center', height: '70vh' }}>
        <Col style={{ textAlign: 'center' }}>
          <img
            src='/img/agency-image.png'
            alt='Agency placeholder'
            width='85%'
          />
        </Col>
        <Col>
          <UploadReport />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px', height: '15vh', alignItems: 'center' }}>
        <Col>
          <h3 className={styles['login-foot-release-note']}>
            IWOMM (v {version})
          </h3>
          <p className={styles['login-foot-title']}>
            Powered By New York City Council Web Dev Unit
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default AgencyView;
