import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import styles from '../../styles/wiki/Wiki.module.scss';

const items = [
  {
    src: '',
    // altText: "Slide 1",
    header: 'A. Title of announcement or update',
    caption: 'Description of announcement or update',
  },
  {
    src: '',
    // altText: "Slide 2",
    header: 'B. Title of announcement or update',
    caption: 'Description of announcement or update',
  },
  {
    src: '',
    // altText: "Slide 3",
    header: 'C. Title of announcement or update',
    caption: 'Description of announcement or update',
  },
];

const FeaturedCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  function onExiting() {
    setAnimating(true);
  }

  function onExited() {
    setAnimating(false);
  }

  function next() {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  function previous() {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  function goToIndex(newIndex) {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item, index) => (
    <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
      <div className={styles['carousel-background']}>
        <img width='100%' src={item.src} alt={item.altText} />
        <CarouselCaption
          captionHeader={item.header}
          captionText={item.caption}
        />
      </div>
    </CarouselItem>
  ));

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction='prev'
        directionText='Previous'
        onClickHandler={previous}
      />
      <CarouselControl
        direction='next'
        directionText='Next'
        onClickHandler={next}
      />
    </Carousel>
  );
};

export default FeaturedCarousel;
