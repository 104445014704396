import React from 'react';
import { connect } from 'react-redux';
import EditUserForm from '../EditUserForm';
import EditPhoto from '../EditPhoto';
import {AssociationsWidget, BioWidget, CreateEventWidget, PreferenceWidget, ProfileWidget} from './profileWidgets';

const UserProfile = props => {
  const {
    userProfile,
  } = props;

  return (
    <div className='main-container'>
      <div className='container p-3'>
        <div className='row row-cols-1 row-cols-md-2 g-4'>
          <div className='col'>
            <div className='row row-cols-1 g-4 mt-0'>
              <div className='col mt-0'>
                <ProfileWidget />
              </div>
              <div className='col'>
                <BioWidget />
              </div>
              <div className='col'>
                <AssociationsWidget/>
              </div>
              {props.userProfile.permissions.event.add_event === true && (
                <div className='col'>
                  <CreateEventWidget />
                </div>
              )}
            </div>
          </div>
          {/* Start of User Preferences*/}
          <div className='col'>
            <PreferenceWidget/>
          </div>
        </div>
        {userProfile.permissions.auth.change_user && (
          <div
            className='modal fade'
            id='editUserProfile'
            tabIndex='-1'
            aria-labelledby='editUserProfile'
            aria-hidden='true'
            data-bs-backdrop='static'
            data-bs-keyboard='false'>
            <div className='modal-dialog modal-lg'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title' id='editUserProfile'>
                    Edit my profile
                  </h5>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-label='Close'></button>
                </div>
                <div className='modal-body' style={{ padding: '0px' }}>
                  <EditUserForm id={userProfile.id} />
                </div>
              </div>
            </div>
          </div>
        )}
        {<EditPhoto id={userProfile.id} />}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};
export default connect(mapStateToProps)(UserProfile);
