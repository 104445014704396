import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/proclamation-request/ProclamationTable.module.scss';
import { connect } from 'react-redux';
import * as actions from '../../actions/procRequestAction';
import {
  SearchIcon,
  ChevronUpDownArrowsIcon,
  CloseButtonCircleIcon,
  PreviewEyeOutlineIcon,
  CheckIcon,
  InfoCircleFilledIcon,
  BoxAndArrowIcon,
} from '../../services/SvgLibrary';
import { rolesUnderCouncilMembers } from '../../services/constants';

const ProclamationTable = props => {
  const [procRequestSearchString, setProcRequestSearchString] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Show All');
  const [selectedSortBy, setSelectedSortBy] = useState(
    rolesUnderCouncilMembers.includes(props.userProfile.role)
      ? 'Date of Event'
      : 'Council Member'
  );
  const [selectedSortOrder, setSelectedSortOrder] = useState('Ascending');
  const [openCard, setOpenCard] = useState(null);

  const currentSearch = 'searchAll';
  const [searchResultsDisplay, setSearchResultsDisplay] = useState(false);
  const screenWidth = window.innerWidth;

  const tableContainerRef = useRef(null);
  const [isLoadingProcs, setIsLoadingProcs] = useState(false);

  // const {isLoading: isLoadingProcs, loadMoreProcs} = props

  useEffect(() => {
    const onScroll = e => {
      const scrollPosition = e.target.scrollHeight - e.target.scrollTop;

      if (scrollPosition < 1000 && props.nextAPI && !isLoadingProcs) {
        setIsLoadingProcs(true);
        props.getProcRequests(props.nextAPI, {
          sort_by: props.procRequestSortBy,
          sort_direction: props.procRequestSortDirection,
          filter: props.procRequestFilter,
          search_term: procRequestSearchString,
        });
        setIsLoadingProcs(false);
      }
    };

    const tableContainerNode = tableContainerRef.current;
    if (tableContainerNode) {
      tableContainerRef.current.addEventListener('scroll', onScroll, false);
    }

    return () => {
      tableContainerRef.current?.removeEventListener('scroll', onScroll);
    };
  }, [props.nextAPI]);

  const sortBy = (sortField, e) => {
    setSelectedSortBy(e);
    props.getProcRequests(null, {
      sort_by: sortField,
      sort_direction: props.procRequestSortDirection,
      filter: props.procRequestFilter,
      search_term: procRequestSearchString,
    });
  };

  const changeDirection = () => {
    props.getProcRequests(null, {
      sort_by: props.procRequestSortBy,
      sort_direction: props.procRequestSortDirection === 'asc' ? 'desc' : 'asc',
      filter: props.procRequestFilter,
      search_term: procRequestSearchString,
    });
  };

  const clearSearch = () => {
    setProcRequestSearchString('');
    props.getProcRequests(null, {
      sort_by: props.procRequestSortBy,
      sort_direction: props.procRequestSortDirection === 'asc' ? 'desc' : 'asc',
      filter: props.procRequestFilter,
      search_term: '',
    });
  };

  const filterByStatus = (filter, e) => {
    setSelectedFilter(e);
    props.getProcRequests(null, {
      sort_by: props.procRequestSortBy,
      sort_direction: props.procRequestSortDirection,
      filter: filter,
      search_term: procRequestSearchString,
    });
  };

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      onGetSearchResult();
    }
  };

  const onGetSearchResult = () => {
    props.getProcRequests(null, {
      sort_by: props.procRequestSortBy,
      sort_direction: props.procRequestSortDirection,
      filter: props.procRequestFilter,
      search_term: procRequestSearchString,
    });
  };

  const editProcRequest = procRequest => {
    props.selectTargetDayForProcRequests([procRequest]);
    props.selectProcRequest(procRequest);
    props.changeProcRequestDisplay('detail');
  };

  const generateSearchBox = () => {
    return (
      <div className={styles['search-box']}>
        <SearchIcon
          classNameProp={styles['search-button']}
          width='21'
          height='21'
          onClickFunction={onGetSearchResult}
        />
        {currentSearch === 'searchAll' && (
          <input
            type='text'
            value={procRequestSearchString}
            placeholder='Search All Fields'
            onChange={e => {
              e.preventDefault();
              setProcRequestSearchString(e.target.value);
            }}
            onKeyDown={onKeyPress}
          />
        )}
        {procRequestSearchString !== '' && (
          <div onClick={clearSearch}>
            <CloseButtonCircleIcon classNameProp={styles['search-button']} />
          </div>
        )}
        {searchResultsDisplay && !props.isLoading && (
          <CloseButtonCircleIcon
            classNameProp={styles['search-button']}
            // onClickFunction={onClearFilter}
          />
        )}
      </div>
    );
  };

  const { isLoading, filteredProcRequests, userProfile } = props;
  const isCMStaff = rolesUnderCouncilMembers.includes(userProfile.role);

  const displayFields = [
    {
      title: 'Show All',
      field: 'all',
    },
    {
      title: 'Pending',
      field: 'pending',
    },
    {
      title: 'Approved',
      field: 'approved',
    },
    {
      title: 'In-Progress',
      field: 'in-progress',
    },
    {
      title: 'Completed',
      field: 'completed',
    },
    {
      title: 'Delivered',
      field: 'delivered',
    },
    {
      title: 'Withdrawn',
      field: 'withdrawn',
    },
  ];
  const sortFieldsAll = [
    {
      title: 'Honoree',
      field: 'honoree',
    },
    {
      title: 'Reason/Occasion',
      field: 'reason',
    },
    {
      title: 'Date Needed',
      field: 'date_proc_needed',
    },
    {
      title: 'Date of Event',
      field: 'date_of_event',
    },
    {
      title: 'Date Submitted',
      field: 'created_at',
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'Requested By',
      field: 'requested_by',
    },
  ];
  let sortFields = [...sortFieldsAll];
  if (!isCMStaff) {
    sortFields = [
      {
        title: 'Council Member',
        field: 'council_member',
      },
      ...sortFields,
    ];
  }

  const procRequestDisplay =
    filteredProcRequests &&
    filteredProcRequests.map((procRequest, idx) => {
      let procRequestTitle = procRequest.id;
      if (screenWidth > 795) {
        procRequestTitle = procRequest.title;
        if (
          screenWidth < 992 &&
          procRequestTitle &&
          procRequestTitle.length > 115
        ) {
          procRequestTitle = procRequestTitle.substring(0, 115) + '...';
        } else if (
          screenWidth < 1200 &&
          procRequestTitle &&
          procRequestTitle.length > 175
        ) {
          procRequestTitle = procRequestTitle.substring(0, 175) + '...';
        }
      }

      const arr = procRequest['contact_person'];
      let contactLinks = arr.map((contact, idx) => {
        return (
          <a
            className={styles['proclamation-contact-person']}
            href={`${window.location.origin}/staff/${
              contact.user_profile ? contact.user_profile : contact.id
            }`}
            key={idx}
            onClick={e => {
              e.preventDefault();
              window.open(
                `${window.location.origin}/staff/${
                  contact.user_profile ? contact.user_profile : contact.id
                }`
              );
            }}>
            {contact.full_name} <BoxAndArrowIcon width='12' height='12' />
          </a>
        );
      });
      return (
        <div
          key={idx}
          className={styles['proc-block']}
          onClick={() => {
            setOpenCard(procRequest.id);
            document.body.style.overflow = 'hidden';
          }}>
          <div
            className='card shadow-sm border-secondary border-0 border-start border-3 overflow-hidden'
            onClick={() => {
              if (procRequest.id === openCard) {
                setOpenCard(null);
              }
            }}>
            <button
              title='Edit proclamation request'
              className={styles['edit-proclamation-button']}
              onClick={() => editProcRequest(procRequest)}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                fill='currentColor'
                className={`${styles['edit']} bi bi-pencil-square`}
                viewBox='0 0 16 16'>
                <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                <path
                  fillRule='evenodd'
                  d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
                />
              </svg>
            </button>
            <div
              className='proc-card card-header rounded-1 collapsible overflow-hidden accordion-button shadow-none bg-white text-dark d-flex flex-column align-items-start justify-content-center px-3 collapsed'
              role='button'
              data-bs-toggle='collapse'
              data-bs-target={`#kt_docs_card_collapsible-${procRequest.id}`}>
              <div className='container-fluid p-0'>
                <div className='row'>
                  <div className='col-5'>
                    <div
                      className='d-flex flex-column text-start mb-1'
                      title='Name of honoree'>
                      <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                        Honoree:
                      </span>
                      <span className='text-dark' style={{ lineHeight: '1.1' }}>
                        {procRequest.honoree}
                      </span>
                    </div>
                    <div
                      className='d-flex flex-column text-start mb-1'
                      title='Status of proclamation request'>
                      <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                        Status:
                      </span>
                      <span className='text-dark' style={{ lineHeight: '1.1' }}>
                        {procRequest.status}
                      </span>
                    </div>
                  </div>
                  <div className='col-7 text-start'>
                    {isCMStaff ? (
                      <div
                        className='d-flex flex-column text-start mb-1'
                        title='Date proclamation is needed'>
                        <span
                          style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                          Date of Event:
                        </span>
                        <span
                          className='text-dark'
                          style={{ lineHeight: '1.1' }}>
                          {procRequest.date_of_event}
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                        }}
                        title='Council Member'>
                        <span
                          style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                          Date needed:
                        </span>
                        <span
                          className='text-dark'
                          style={{ lineHeight: '1.1' }}>
                          {procRequest.date_proc_needed}
                        </span>
                      </div>
                    )}
                    <div
                      className='d-flex flex-column text-start'
                      title='Council Member'>
                      <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                        Council Member:
                      </span>
                      <span className='text-dark' style={{ lineHeight: '1.1' }}>
                        {procRequest.council_member.full_name}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  title='Edit proclamation request'
                  className={styles['edit-proclamation-button']}
                  onClick={() => editProcRequest(procRequest)}>
                  <PreviewEyeOutlineIcon
                    width='18'
                    height='18'
                    classNameProp={styles['edit']}
                  />
                </button>
              </div>
            </div>
            <div
              id={`kt_docs_card_collapsible-${procRequest.id}`}
              className={`collapse${
                procRequest.id === openCard ? ' show' : ''
              }`}>
              <div className='card-body'>
                <p className={`mb-0 ${styles['proc-reason-text']}`}>
                  {procRequest.reason}
                </p>
              </div>

              <div className='card-footer border-top border-0 bg-white overflow-auto'>
                <div className='container-fluid p-0'>
                  <div
                    className='d-flex flex-column text-start mb-1'
                    title='Contact(s)'>
                    <span style={{ fontSize: '0.65rem', fontWeight: '400' }}>
                      {'Contact(s):'}
                    </span>
                    <div className='row'>{contactLinks}</div>
                  </div>
                  {isCMStaff && (
                    <div className='row'>
                      <div className='col-5'>
                        <span className={styles['probo']} title='Notes'>
                          {procRequest.notes && `Notes: ${procRequest.notes}`}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  // display the search area
  return (
    <>
      <div className={styles['search-filter-wrapper']}>
        <div className='d-flex justify-content-end mb-4'>
          <button
            type='button'
            className={`btn btn-sm ${styles['user-guide']}`}
            onClick={() => {
              window.open(
                `${window.location.origin}/documents/NYCC%20Proclamation%20Guidelines.pdf`
              );
            }}>
            <InfoCircleFilledIcon width='12px' /> Proclamation Guidelines
          </button>
        </div>

        {generateSearchBox()}
        <div className=' pt-3 gx-5 d-flex justify-content-end align-items-center'>
          <span className={styles['filter-label']}>Status</span>
          <div className='dropdown'>
            <button
              className={`btn dropdown-toggle d-flex align-items-center ${styles['dropdown-toggle']}`}
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {selectedFilter}
              <ChevronUpDownArrowsIcon
                width='15'
                height='15'
                classNameProps={styles['chevron-icon']}
              />
            </button>
            <ul
              className={`dropdown-menu ${styles['dropdown-menu']}`}
              aria-labelledby='dropdownMenuButton1'>
              <li>
                {displayFields.map((c, idx) => (
                  <button
                    key={idx}
                    className={`dropdown-item ${styles['status-dropdown-item']}`}
                    onClick={() => filterByStatus(c.field, c.title)}>
                    {c.title}
                    {selectedFilter === c.title && (
                      <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                    )}
                  </button>
                ))}
              </li>
            </ul>
          </div>

          <span className={styles['filter-label']}>Sort by</span>
          <div className='dropdown'>
            <button
              className={`btn dropdown-toggle d-flex align-items-center ${styles['dropdown-toggle']}`}
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'>
              {selectedSortBy}
              <ChevronUpDownArrowsIcon
                width='15'
                height='15'
                classNameProps={styles['chevron-icon']}
              />
            </button>
            <ul
              className={`dropdown-menu ${styles['dropdown-menu']} ${styles['filter']}`}
              aria-labelledby='dropdownMenuButton1'>
              {sortFields.map((c, idx) => (
                <li key={idx}>
                  <button
                    className={`dropdown-item ${styles['status-dropdown-item']}`}
                    onClick={() => {
                      sortBy(c.field, c.title);
                    }}>
                    {c.title}
                    {selectedSortBy === c.title && (
                      <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                    )}
                  </button>
                </li>
              ))}
              <li>
                <hr className='dropdown-divider' />
              </li>
              <li>
                <button
                  className={`dropdown-item ${styles['status-dropdown-item']}`}
                  onClick={() => {
                    changeDirection();
                    setSelectedSortOrder('Ascending');
                  }}>
                  Ascending
                  {selectedSortOrder === 'Ascending' && (
                    <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                  )}
                </button>
              </li>
              <li>
                <button
                  className={`dropdown-item ${styles['status-dropdown-item']}`}
                  onClick={() => {
                    changeDirection();
                    setSelectedSortOrder('Descending');
                  }}>
                  Descending
                  {selectedSortOrder === 'Descending' && (
                    <CheckIcon classNameProp={styles['dropdown-checkmark']} />
                  )}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        ref={tableContainerRef}
        id='proc-list'
        className={styles['proc-request-list-container-tasks']}>
        {filteredProcRequests?.length > 0 && procRequestDisplay}
      </div>
      {isLoading && ( // Needs to repositioned, causing overflow issues
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {!filteredProcRequests && (
        <h3 className={styles['no-valid-result-message']}>No valid result</h3>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    isLoading: state.procRequestReducer.isLoading,
    nextAPI: state.procRequestReducer.nextAPI,
    allProcRequests: state.procRequestReducer.allProcRequests,
    filteredProcRequests: state.procRequestReducer.filteredProcRequests,
    searchString: state.procRequestReducer.procRequestSearchString,
    procRequestFilter: state.procRequestReducer.procRequestFilter,
    procRequestSortBy: state.procRequestReducer.procRequestSortedBy,
    procRequestSortDirection: state.procRequestReducer.procRequestSortDirection,
  };
};

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProclamationTable);
