import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import styles from '../../styles/report-tracking/ReportServicePopup.module.scss';
import {
  changeReportTrackingPopupWindow,
  changeReportTrackingDisplay,
} from '../../actions/reportTrackingAction';
import { downloadFileStatus } from '../../actions/fileDownloadAction';
import { format } from 'date-fns';
import Modal from 'react-modal';
import DueReportsDetails from './DueReportsDetails';
import CreateNewReportDdl from './CreateNewReportDdl';
import { CloseButtonNormalIcon } from '../../services/SvgLibrary';

const ReportServicePopup = ({
  openReportTrackingPopupStatus,
  selectedDayDueReports,
  dueReports,
  currentDisplay,
  changeReportTrackingPopupWindow,
  changeReportTrackingDisplay,
  downloadFileStatus,
  params,
  selectedDay,
  currentMode,
  switched,
}) => {
  const [currentHead, setCurrentHead] = useState('list');
  const [init, setInit] = useState(true);
  const [titleContainerStyle, setTitleContainerStyle] = useState({});

  const onCloseWindow = () => {
    changeReportTrackingPopupWindow(false);
    changeReportTrackingDisplay('list');
    setCurrentHead('list');
    setInit(false);
    setTitleContainerStyle({});
    downloadFileStatus(null);
    document.body.style.overflow = 'auto';
  };

  const displayReportServices = () => (
    <div className={styles['popup-title-container']}>
      <div className={styles['popup-title-wrapper']}>
        {selectedDay && (
          <h1>
            {selectedDayDueReports.length}/
            {dueReports[selectedDay.getDate()].length} Trackers
          </h1>
        )}
        <h2>{format(selectedDay, 'MMMM do, yyyy')}</h2>
      </div>
      <CloseButtonNormalIcon
        classNameProp='popup-close-icon'
        onClickFunction={onCloseWindow}
      />
    </div>
  );

  const onBackToService = () => {
    changeReportTrackingDisplay('list');
    setCurrentHead('list');
    setTitleContainerStyle({});
  };

  const setHeaderColor = status => {
    let style;
    switch (status.toLowerCase()) {
      case 'upcoming':
        style = { backgroundColor: '#AF9308' };
        break;
      case 'past_due':
        style = { backgroundColor: '#d05d4e' };
        break;
      case 'completed':
        style = { backgroundColor: '#495057' };
        break;
      case 'received':
        style = { backgroundColor: '#1e7e34' };
        break;
      default:
        style = {};
        break;
    }
    setTitleContainerStyle(style);
  };

  let trackerId = '';
  if (params && params.id) {
    trackerId = params.id;
  }

  return ReactDOM.createPortal(
    <Modal
      isOpen={openReportTrackingPopupStatus}
      className={styles['report-modal-style']}
      overlayClassName={styles.modalOverlay}
      onRequestClose={onCloseWindow}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}>
      {currentMode === 'calendar' &&
        currentHead === 'list' &&
        currentDisplay !== 'create' &&
        (!trackerId || (trackerId && !(init && !switched))) &&
        displayReportServices()}
      {['list', 'detail', 'update'].includes(currentDisplay) && (
        <DueReportsDetails
          params={params}
          init={init}
          setInit={setInit}
          selectedDay={selectedDay}
          currentMode={currentMode}
          onCloseWindow={onCloseWindow}
          onBackToService={onBackToService}
          onChangeCurrentHead={setCurrentHead}
          setHeaderColor={setHeaderColor}
          titleContainerStyle={titleContainerStyle}
        />
      )}
      {currentDisplay === 'create' && (
        <CreateNewReportDdl onCloseWindow={onCloseWindow} />
      )}
    </Modal>,
    document.getElementById('portal-root')
  );
};

const mapStateToProps = state => ({
  openReportTrackingPopupStatus:
    state.reportTrackingReducer.openReportTrackingPopupStatus,
  selectedDayDueReports: state.reportTrackingReducer.selectedDayDueReports,
  dueReports: state.reportTrackingReducer.dueReports,
  currentDisplay: state.reportTrackingReducer.currentDisplay,
});

const mapDispatchToProps = {
  changeReportTrackingPopupWindow,
  changeReportTrackingDisplay,
  downloadFileStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportServicePopup);
