import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { addToastInfo, loggingOut } from '../actions/userAction';
import { openNotificationDetailReport } from '../actions/reportTrackingAction';

import {
  MyAccountIcon,
  MyProfileIcon,
  AddUserIcon,
  NewWindowIcon,
} from '../services/SvgLibrary';
import IwommToast from '../components/Toast';
import FeedbackForm from './FeedbackForm';
import NewUserCreation from './user/NewUserCreation.js';
import Notifications from './user/myProfile/Notifications';
import styles from '../styles/NavBar.module.scss';

const NavBar = ({
  loggingOut,
  userProfile,
  toggleMainMenu,
  menuOpen,
  toastInfo,
}) => {
  const location = useLocation();
  const [siteLocation, setSiteLocation] = useState('');
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleFeedbackModal = () => {
    setFeedbackModalOpen(!feedbackModalOpen);
  };

  const submitFeedbackHandler = () => {
    setFeedbackSubmitted(!feedbackSubmitted);
  };

  useEffect(() => {
    const updateSiteLocation = () => {
      switch (location.pathname.split('/')[1]?.toLowerCase()) {
        case 'mytasks':
          let locationSuffix = '';
          if (location.pathname.split('/')[2]?.toLowerCase() === 'myactivels') {
            locationSuffix = 'My Active LS Requests';
          } else if (
            location.pathname.split('/')[2]?.toLowerCase() === 'allmyls'
          ) {
            locationSuffix = 'All My LS Requests';
          } else if (
            location.pathname.split('/')[2]?.toLowerCase() === 'myreports'
          ) {
            locationSuffix = 'My Op Reqs';
          } else if (
            location.pathname.split('/')[2]?.toLowerCase() === 'myclexreviews'
          ) {
            locationSuffix = 'My CLEX Reviews';
          } else {
            locationSuffix = '';
          }
          setSiteLocation(
            `My Tasks${locationSuffix ? ' / ' + locationSuffix : ''}`
          );
          break;
        case 'opreqs':
          setSiteLocation('Operational Requirements');
          break;
        case 'clex':
          setSiteLocation('Council Legal Exchange');
          break;
        case 'lsrequestlist':
          if (window.location.pathname.toLowerCase().includes('history')) {
            setSiteLocation('Legislative Service Requests / LSR History');
          } else if (window.location.pathname.includes('LsDupSearch')) {
            setSiteLocation('LSR Duplicate Search');
          } else {
            setSiteLocation('Legislative Service Requests');
          }
          break;
        case 'billdrafting':
          setSiteLocation('Bill Drafting');
          break;
        case 'profile':
          setSiteLocation('My Profile');
          break;
        case 'newuser':
          setSiteLocation(' Create A New User');
          break;
        case 'edituser':
          setSiteLocation('Edit User');
          break;
        case 'createuser':
          setSiteLocation('User Account');
          break;
        case 'staff':
          setSiteLocation('Staff & Committee Directory');
          break;
        case 'seating':
          setSiteLocation('14th Floor Map');
          break;
        case 'usermanual':
          setSiteLocation('User Manual');
          break;
        case 'whatsnew':
          setSiteLocation('Change Log');
          break;
        case 'proclamation-requests':
          setSiteLocation('Proclamation Requests');
          break;
        case 'citation-requests':
          setSiteLocation('Citation Requests');
          break;
        case 'video-tutorials':
          setSiteLocation('Video Tutorials');
          break;
        case '':
          setSiteLocation('Dashboard');
          break;
        default:
          setSiteLocation('');
          break;
      }
    };

    updateSiteLocation();
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {toastInfo.showToast && <IwommToast toastInfo={toastInfo} />}
      <nav className='shadow-sm navbar navbar-expand sticky-top bg-light'>
        <div className='container-fluid'>
          <div className={styles['logo-breadcrumb-container']}>
            {!window.location.pathname.includes('LsDupSearch') && (
              <button
                className={`navbar-toggler d-flex flex-column justify-content-center ${styles['menu-button-container']}`}
                onClick={toggleMainMenu}
                type='button'
                data-bs-toggle='collapse'
                aria-controls='navbarToggler'
                aria-expanded='false'
                aria-label='Toggle navigation'>
                <div
                  className={
                    menuOpen ? styles['close-x-top'] : styles['close-line-top']
                  }></div>
                <div
                  className={
                    menuOpen
                      ? styles['close-x-middle']
                      : styles['close-line-middle']
                  }></div>
                <div
                  className={
                    menuOpen
                      ? styles['close-x-bottom']
                      : styles['close-line-bottom']
                  }></div>
              </button>
            )}
            <div className={styles['breadcrumb-header']}>{siteLocation}</div>
          </div>

          <div
            className='btn-group align-items-center'
            role='group'
            aria-label='Button group with nested dropdown'>
            <Notifications />
            <div className='btn-group' role='group'>
              <button
                className={`btn dropdown-toggle ${styles['account-button-dropdown']}`}
                type='button'
                id='account-dropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                {userProfile.profile_pic ? (
                  <img
                    alt='User Profile Icon'
                    src={
                      userProfile.profile_pic_icon.file
                        ? userProfile.profile_pic_icon.file
                        : MyProfileIcon
                    }
                  />
                ) : (
                  <MyAccountIcon />
                )}
                {screenWidth > 830 && <> My Account</>}
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-end ${styles['account-dropdown-menu']}`}
                aria-labelledby='account-dropdown'>
                <li>
                  <Link
                    className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                    to={`/profile`}>
                    <MyProfileIcon /> Profile
                  </Link>
                </li>
                {userProfile.permissions.auth.add_user && (
                  <li>
                    <button
                      className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                      data-bs-toggle='modal'
                      data-bs-target='#new-user'>
                      <AddUserIcon /> Add user
                    </button>
                  </li>
                )}
                <li>
                  <button
                    className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                    onClick={() => {
                      window.open(window.location.href);
                    }}>
                    <NewWindowIcon /> Open new tab
                  </button>
                </li>
                <li>
                  <hr className='dropdown-divider' />
                </li>
                <li>
                  <button
                    className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                    data-bs-toggle='modal'
                    data-bs-target='#feedbackModal'>
                    Help &amp; Feedback
                  </button>
                </li>
                <li>
                  <a
                    className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                    href={`/UserManual`}>
                    User manual
                  </a>
                </li>
                <li>
                  <button
                    className={`dropdown-item ${styles['account-dropdown-menu-item']}`}
                    onClick={loggingOut}>
                    Log out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`modal fade ${styles['navbar-modal']}`}
        id='feedbackModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='feedbackFormLabel'
        aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className={`modal-header ${styles['form-header-wrapper']}`}>
              <h5 className='modal-title' id='feedbackFormLabel'>
                Submit Your Feedback
              </h5>
              {!feedbackSubmitted && (
                <button
                  type='button'
                  className='btn-close btn-close-white'
                  data-bs-dismiss='modal'
                  aria-label='Close'></button>
              )}
            </div>
            <div className='modal-body p-0'>
              <FeedbackForm
                feedbackSent={feedbackSubmitted}
                feedbackHandler={submitFeedbackHandler}
                sender={userProfile.full_name}
                toggleModal={toggleFeedbackModal}
                isFeedbackOpen={feedbackModalOpen}
              />
            </div>
          </div>
        </div>
      </div>
      {userProfile.permissions.auth.add_user && (
        <div
          className={`modal fade ${styles['navbar-modal']}`}
          id='new-user'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex='-1'
          aria-labelledby='NewUserFormLabel'
          aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'>
              <div className={`modal-header ${styles['form-header-wrapper']}`}>
                <h5 className='modal-title' id='NewUserFormLabel'>
                  Create a New User
                </h5>
                <button
                  type='button'
                  className='btn-close btn-close-white'
                  data-bs-dismiss='modal'
                  aria-label='Close'></button>
              </div>
              <div className='modal-body' style={{ padding: '0px' }}>
                <NewUserCreation />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    toastInfo: state.userReducer.toastInfo,
    // dueReportsNotification: state.userReducer.dueReportsNotification.results
  };
};

const mapDispatchToProps = {
  addToastInfo,
  loggingOut,
  openNotificationDetailReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
