import React, { useEffect, useRef } from 'react';
import { Toast } from 'bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const IwommToast = props => {
  const toastRef = useRef();
  useEffect(() => {
    let myToast = toastRef.current;
    let bsToast = Toast.getOrCreateInstance(myToast);
    bsToast.show();
  }, []);

  return (
    <div className='toast-container position-static'>
      <div
        className='toast bottom-0 start-0 position-absolute ms-3 mb-3'
        style={{ zIndex: '1000', backgroundColor: '#11131a', color: '#fff' }}
        role='alert'
        aria-live='assertive'
        aria-atomic='true'
        ref={toastRef}>
        <div
          className='toast-header border-0 border-top border-5 border-success pb-0 bg-transparent justify-content-end'
          style={{ zIndex: '1000', color: '#fff' }}>
          <button
            type='button'
            className='btn-close btn-close-white'
            data-bs-dismiss='toast'
            aria-label='Close'></button>
        </div>
        <div className='toast-body p-3 pt-0 d-flex flex-row'>
          <img
            src={props.toastApp}
            style={{ maxHeight: '42px' }}
            className='rounded me-2 p-1'
            alt={props.toastAppAlt}
          />
          <div className='d-flex flex-column'>
            <strong className='me-auto'>{props.toastTitle}</strong>
            {props.toastText}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    toastTitle: state.userReducer.toastInfo.toastTitle,
    toastText: state.userReducer.toastInfo.toastBody,
    toastApp: state.userReducer.toastInfo.toastApp,
    toastAppAlt: state.userReducer.toastInfo.toastAppAlt,
  };
};

export default connect(mapStateToProps)(IwommToast);
