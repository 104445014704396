import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import lsStyles from '../../styles/ls-request/LsRequestsList.module.scss';
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import CheckboxMenu from './CheckboxMenu';
import FilterMenu from './FilterMenu';
import { exportLsRequest } from '../../actions/lsRequestAction';
import { csvHeaderCM, csvHeaderNonCM } from '../../services/LsFields';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { CSVLink } from 'react-csv';
import {
  PlusIcon,
  SaveIcon,
  OptionsGearIcon,
  SearchIcon,
  InfoCircleFilledIcon,
  ColumnsFilterIcon,
  SliderFilterIcon,
  CloseButtonCircleIcon,
} from '../../services/SvgLibrary';

const NewLsMenu = props => {
  /** COLUMN PROPS */
  const {
    columns,
    columnPreset,
    showColumnMenu,
    checkAllColumns,
    toggleColumn,
    toggleColumnMenu,
    changeColumnPreset,
    toggleAllColumns,
  } = props;

  /** FILTER PROPS */
  const {
    filterValues,
    filterPreset,
    searchInput,
    setSearchInput,
    showFilterMenu,
    onClearFilter,
    onClearSearch,
    changeFilterPreset,
    toggleFilterMenu,
    onAddFilter,
    onRemoveFilter,
    applyFilters,
    buttonFilterToggle,
    filterFlag,
    statusChangeRequestCounter,
  } = props;

  /** DUPLICATE CHECKER PROPS */
  const { currentSearchType, toggleSearchType, dupArray, saveDuplicates } =
    props;
  /** OTHER PROPS */
  const { onOpenCreate, onOpenStatusChange, sidebarOpen, userProfile } = props;
  //**state */
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollDown, setScrollDown] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [search, setSearch] = useState(searchInput);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Defining Scroll event "handler" to detect scroll direction and display/hide the 'add LS' button:
  const handleScroll = () => {
    const scrollWindow = document.getElementsByClassName(
      `${lsStyles['list-table']} list-table`
    )[0].children[1];

    // Logic to handle the scroll event
    const currentScrollPosition = scrollWindow.getBoundingClientRect().top;
    setScrollPosition(currentScrollPosition);
    setScrollDown(
      !(scrollWindow.getBoundingClientRect().top >= scrollPosition)
    );
  };
  const handleSaveDuplicate = e => {
    e.preventDefault();
    setIsButtonDisabled(true);
    saveDuplicates(e);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });

    // Adding Scroll event "listener" when the component mounts to detect scroll direction and display/hide the 'add LS' button:
    const scrollElement = document.getElementById('lsTable');
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    // Returning a cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('resize', () => {
        setScreenWidth(window.innerWidth);
      });
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollPosition]);

  const noLinebreakFunction = ls => {
    let lsCopy = Object.assign({}, ls);
    for (let [key, value] of Object.entries(lsCopy)) {
      if (typeof value === 'string') {
        // String values may have escaped whitespace or HTML encoding that need replacing
        lsCopy[key] = value
          .replace(/(\r\n|\n|\r)/gm, ' ')
          .replace(/"/g, "\\'")
          // .replace("<span class='highlight'>", '')
          // .replace('</span>', '')
          .replace('&#60;', '<')
          .replace('&#62;', '>');
      } else if (typeof value === 'object') {
        // If the value is an object that means it's one our relational fields
        if (['overlapping_ls', 'related_ls'].includes(key)) {
          // For the overlapping or related LS field
          lsCopy[key] = value.map(ls => ls.ls_number).join(', ');
        } else if (value?.full_name) {
          // For first prime, staff, contact person, and submitted by fields
          lsCopy[key] = value.full_name;
        } else if (Array.isArray(value) && value.length > 0) {
          // For the file fields like Affirmation, Operational Requirement/Milestone attachments, etc.
          if (key === 'associated_report_tracking') {
            let joinedReports = [];
            for (let report of value) {
              // 'value' when key is associated_report_tracking is an array of objects/reports
              let reportAttachmentLinks = report['attachment']
                .map(file => file.file)
                .join('\r\n');
              joinedReports.push(reportAttachmentLinks);
            }
            lsCopy[key] = joinedReports.join('\r\n');
          } else {
            lsCopy[key] = value.map(file => file.file).join('\r\n');
          }
        } else if (key === 'committee') {
          // For committee field
          lsCopy[key] = value.committee_name;
        }
      } else {
        // Anything else like boolean values, integers, etc.
        lsCopy[key] = value;
      }
    }
    return lsCopy;
  };

  const filterCountList = rolesUnderCouncilMembers.includes(
    props.userProfile.role
  )
    ? [
        'committee',
        'submitted_by',
        'division',
        'first_in_time',
        'ls_number',
        'ls_type',
        'staff',
        'status',
      ]
    : [
        'committee',
        'submitted_by',
        'division',
        'first_prime',
        'first_in_time',
        'ls_number',
        'ls_type',
        'staff',
        'status',
      ];
  const filterCount = filterCountList.filter(
    field => filterValues[field].check
  ).length;
  const columnCount = columns.filter(
    field => field.show && field.field !== 'ls_number'
  ).length;
  return (
    <div className={`container-fluid ${!filterFlag && 'gx-0'}`}>
      {filterFlag && (
        <div
          className={`d-flex align-items-center ${styles['search-menu-container']}`}>
          {window.location.pathname.includes('LsDupSearch') &&
            currentSearchType === 'Automated' && (
              <div style={{ position: 'absolute', left: '50%' }}>
                <h1
                  style={{
                    textAlign: 'center',
                    color: '#4a5f96',
                    marginBottom: '0px',
                  }}>
                  Automated Search
                </h1>
              </div>
            )}
          {window.location.pathname.includes('LsDupSearch') &&
            currentSearchType === 'Review' && (
              <div style={{ position: 'absolute', left: '50%' }}>
                <h1
                  style={{
                    textAlign: 'center',
                    color: '#4a5f96',
                    marginBottom: '0px',
                  }}>
                  Selected Duplicates
                </h1>
              </div>
            )}
          <div
            className={`d-flex mx-auto w-100 ${styles['search-menu-sub-container']}`}>
            {!window.location.pathname.includes('LsDupSearch') &&
              props.userProfile.permissions.lsRequest_api.add_lsrequest && (
                <button
                  title='Create New LSR'
                  className={`${styles['create-ls-button']} ${
                    sidebarOpen && styles['open-sidebar']
                  } ${scrollDown && styles['hide']}`}
                  id='create-ls-button'
                  onClick={onOpenCreate}>
                  <PlusIcon />
                  <span className={styles['create-new-text']}>
                    &nbsp; Create New LSR
                  </span>
                </button>
              )}
            {!(
              window.location.pathname.includes('LsDupSearch') &&
              !(currentSearchType === 'Manual')
            ) && (
              <>
                <div
                  className={`d-flex position-relative align-items-center overflow-hidden w-100 ${styles['input-search-wrapper']}`}>
                  {props.isLoading ? (
                    <div
                      className={`spinner-border ${
                        styles['ls-loading-spinner']
                      } ${filterCount > 0 && styles['filter']}`}
                      role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    <SearchIcon
                      width='24'
                      height='24'
                      classNameProp={`${styles['search-icon']} ${
                        filterCount > 0 && styles['filter']
                      }`}
                      onClickFunction={() => applyFilters()}
                    />
                  )}
                  {filterCount > 0 && (
                    <div
                      className={`d-flex align-items-center h-100 text-white ${styles['query-filter-search']}`}>
                      <span>Filter applied</span>
                    </div>
                  )}
                  <input
                    type='text'
                    className={`border-0 h-100 w-100 ${
                      styles['input-search-box']
                    } ${filterCount > 0 && styles['filter']}`}
                    placeholder='Search LS Requests with Phrases or Boolean Logic Operators'
                    value={search}
                    disabled={props.isLoading}
                    onChange={e => setSearch(e.target.value)}
                    onBlur={e => {
                      if (search !== searchInput) {
                        setSearchInput(search);
                        applyFilters(search);
                      }
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        setSearchInput(search);
                        applyFilters(search);
                      }
                    }}
                  />
                  {!props.isLoading && search !== '' && (
                    <CloseButtonCircleIcon
                      width='24'
                      height='24'
                      classNameProp={styles['clear-search-icon']}
                      onClickFunction={() => {
                        setSearch('');
                        onClearSearch();
                      }}
                    />
                  )}
                </div>
                <InfoCircleFilledIcon
                  classNameProp={styles['search-popover-icon']}
                  dataBsToggleProp='modal'
                  dataBsTargetProp='#searchGuideModal'
                />
              </>
            )}
          </div>
        </div>
      )}
      <div
        className={`modal fade ${styles['search-feature-guide-modal-container']}`}
        id='searchGuideModal'
        tabIndex='-1'
        aria-labelledby='searchGuideModalLabel'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='searchGuideModalLabel'>
                Searching for an LS Request
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <div
              className={`modal-body ${styles['search-feature-guide-modal-body']}`}>
              <p>
                The LS Request search bar can be used just as any other
                traditional search bar by typing a word or phrase to be
                searched. In addition, the search bar can operate on Boolean
                logic, following the normal rules of the mathematical orders of
                operations (which you may recall as PEMDAS). Without
                parenthetical guidance, the order of operations will default to
                perform a sequence of operations from left to right. The search
                will understand the following operators and conditional
                operators:
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h5>
                    AND: <code>&amp;</code>
                  </h5>
                  <p>
                    Using the <code>&amp;</code> operator will show results that
                    are related to both terms. For example, typing
                    <code>
                      transportation <b>&amp;</b> bridges
                    </code>
                    will show results of LS requests or other reports that
                    contain both the words <em>transportation</em> and
                    <em>bridges</em>.
                  </p>
                </li>
                <li>
                  <h5>
                    OR: <code>|</code>
                  </h5>
                  <p>
                    Using the <code>|</code> operator will show results related
                    to both terms or each term individually. For example,
                    <code>
                      transportation <b>|</b> bridges
                    </code>
                    will show results that contain either
                    <em>transportation </em>
                    alone, <em>bridges</em> alone, or both.
                  </p>
                </li>
                <li>
                  <h5>
                    NOT: <code>!</code>
                  </h5>
                  <p>
                    Using the <code>!</code> operator will exclude a keyword,
                    phrase, or parenthetical group from the search. For example,
                    <code>
                      transportation &amp; (<b>!</b>bridges)
                    </code>
                    , which in plain language would be stated as a search for
                    transportation and not bridges, will show results that
                    contain <em>transportation</em> but not <em>bridge</em>.
                  </p>
                </li>
                <li>
                  <h5>
                    Parentheses: <code>( )</code>
                  </h5>
                  <p>
                    Putting a search term between parentheses ensures that it is
                    evaluated before external operations are performed on the
                    encapsulated expression. Use them as you would use
                    parentheses in a mathematical expression. For example,
                    <code>
                      roads &amp; <b>(</b>"school bus" | transport<b>)</b>
                    </code>
                    as a search query will resolve the parentheses first, then
                    grouping ANDs (&amp;). The result will show LS requests or
                    reports that contain the terms "school bus" OR
                    <em>transport</em> as well as the word <em>roads</em>. In
                    other words, <em>roads </em>
                    AND <em>"school bus"</em> as well as the terms
                    <em>roads</em> AND <em>transport</em>.
                  </p>
                </li>
                <li>
                  <h5>
                    Quotations: <code>" "</code>
                  </h5>
                  <p>
                    Putting a search term between quotation marks ensures that
                    the application searches for the exact contents of the
                    search term, spacing included. For example, searching for
                    <code>“dogs look like foxes” </code> within quotes will only
                    return LS request in which the exact term
                    <em>“dogs look like foxes”</em> is found, in that order.
                    Searching for dog and fox without quotations will return LS
                    requests that contain the words <em>dogs</em>, <em>look</em>
                    , <em>like</em> and <em>foxes</em>, regardless of these
                    words’ order or location within the LS request.
                  </p>
                </li>
              </ul>
              <div className='alert alert-warning' role='alert'>
                <p>
                  <b>Note</b>: There are two search modes. The first is a
                  regular search as you may perform through any basic search
                  client. Words are separated by spaces, and no Boolean
                  operators are involved. The second mode uses Boolean logic.
                  This mode is invoked if any of the characters in your search
                  are quotations [" or '], parentheses [ ( or ) ], exclamations
                  marks [!], ampersands [&amp;] or pipes [|].
                </p>
                <p>
                  In this search mode, spaces outside of quotes will not be
                  interpreted as in the basic search. All spaces must be
                  accompanied by a logical operator. For instance,
                  <code> transportation !bridges</code>, will not return proper
                  results, if any, since there exists a space between the two
                  terms. Instead, type
                  <code> transportation &amp; (!bridges)</code>. As a good rule
                  of thumb, use the &amp; (AND) operator to replace spaces
                  between terms in your search query.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row g-0 px-2 justify-content-between mb-2'>
        <div className='col d-flex justify-content-start align-items-center'>
          {window.location.pathname.toLowerCase().includes('myactivels') &&
            screenWidth > 1233 && (
              <div
                className={`btn-group btn-group-sm ${styles['day-toggle-buttons-container']}`}
                role='group'
                aria-label='Day toggle buttons'>
                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle60'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '60')}
                />
                <label className='btn btn-primary' for='daytoggle60'>
                  &ge; 60 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle55'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '55')}
                />
                <label className='btn btn-primary' for='daytoggle55'>
                  &ge; 55 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle45'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '45')}
                />
                <label className='btn btn-primary' for='daytoggle45'>
                  &ge; 45 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle30'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '30')}
                />
                <label class='btn btn-primary' for='daytoggle30'>
                  &ge; 30 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='alldaytoggle'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, 'all')}
                />
                <label className='btn btn-primary' for='alldaytoggle'>
                  All
                </label>
              </div>
            )}
          {/* START Columns Filter Button */}
          <button
            id='columnMenuToggle'
            className={`btn me-2 d-flex align-items-center ${styles['table-filter-icon-btn']}`}
            onClick={() => {
              if (showFilterMenu && !showColumnMenu) {
                toggleFilterMenu();
              }
              toggleColumnMenu();
            }}>
            <div
              className={`position-relative d-inline-flex ${
                screenWidth > 768 && 'me-2'
              }`}>
              <ColumnsFilterIcon width='21' height='21' />
              {columnCount > 0 && (
                <span
                  className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${styles['counter']}`}>
                  {columnCount}
                  <span className='visually-hidden'>
                    columns filter selected
                  </span>
                </span>
              )}
            </div>
            {screenWidth > 768 && 'Columns'}
          </button>
          {/* END Columns Filter Button */}
          {!(
            window.location.pathname.includes('LsDupSearch') &&
            currentSearchType !== 'Manual'
          ) && (
            <button
              id='filterMenuToggle'
              className={`btn me-2 d-flex align-items-center ${
                !filterFlag && 'd-none'
              } ${styles['table-filter-icon-btn']}`}
              onClick={() => {
                if (showColumnMenu && !showFilterMenu) {
                  toggleColumnMenu();
                }
                toggleFilterMenu();
              }}>
              <div
                className={`position-relative d-inline-flex ${
                  screenWidth > 768 && 'me-2'
                }`}>
                <SliderFilterIcon width='21' height='21' />
                {filterCount > 0 && (
                  <span
                    className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${styles['counter']}`}>
                    {filterCount}
                    <span className='visually-hidden'>filter selected</span>
                  </span>
                )}
              </div>
              {screenWidth > 768 && 'Filters'}
            </button>
          )}
          {!props.isLoading &&
            !(
              window.location.pathname.includes('LsDupSearch') &&
              currentSearchType !== 'Manual'
            ) &&
            filterFlag && (
              <span className={`${styles['results-count']} text-nowrap`}>
                <strong>
                  {props.searchCount
                    ? Number(props.searchCount).toLocaleString()
                    : 0}
                </strong>{' '}
                Matching Results
              </span>
            )}
        </div>
        <div className='col d-flex justify-content-end align-items-center'>
          {window.location.pathname.includes('LsDupSearch') && (
            <>
              <button
                className={`btn text-white text-nowrap me-2 fw-bolder ${styles['lsr-action-btn']}`}
                type='button'
                onClick={e => handleSaveDuplicate(e)}
                disabled={isButtonDisabled}>
                {isButtonDisabled ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <SaveIcon height='19px' width='19px' />
                )}{' '}
                Save Duplicates
              </button>
              <div className='dropdown'>
                <button
                  className={`btn btn-secondary dropdown-toggle fw-bolder position-relative text-white border-0 ${styles['options-dropdown']}`}
                  type='button'
                  id='lsRequestExport'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <span>{currentSearchType}</span>
                </button>
                <ul
                  className={`dropdown-menu ${styles['lsr-options-dropdown-menu']}`}
                  aria-labelledby='lsRequestExport'>
                  {currentSearchType !== 'Automated' && (
                    <li
                      className='dropdown-item'
                      onClick={() => {
                        toggleSearchType('Automated');
                      }}>
                      Automated
                    </li>
                  )}
                  {currentSearchType !== 'Manual' && (
                    <li
                      className='dropdown-item'
                      onClick={() => {
                        toggleSearchType('Manual');
                      }}>
                      Manual
                    </li>
                  )}
                  {currentSearchType !== 'Review' && dupArray.length > 0 && (
                    <li
                      className='dropdown-item'
                      onClick={() => {
                        if (dupArray.length) toggleSearchType('Review');
                      }}>
                      Review {dupArray.length > 0 && `(${dupArray.length})`}
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}
          {!window.location.pathname.includes('LsDupSearch') && (
            <>
              {' '}
              {screenWidth > 768 &&
                userProfile.permissions.lsRequest_api.approve_status_change && (
                  <button
                    hidden={!filterFlag}
                    className={`btn text-white text-nowrap me-2 fw-bolder ${styles['lsr-action-btn']}`}
                    type='button'
                    onClick={onOpenStatusChange}>
                    <span className={`badge me-1 ${styles['badge-count']}`}>
                      {statusChangeRequestCounter}
                    </span>
                    Status Change Request
                    {statusChangeRequestCounter > 0 && 's'}
                  </button>
                )}
              <div className='dropdown'>
                <button
                  hidden={!filterFlag}
                  className={`btn dropdown-toggle fw-bolder text-white ${styles['options-dropdown']}`}
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <OptionsGearIcon /> Options
                  {screenWidth <= 768 && statusChangeRequestCounter > 0 && (
                    <span className='position-absolute top-0 start-100 translate-middle p-2 bg-warning border border-light rounded-circle'>
                      <span className='visually-hidden'>New alerts</span>
                    </span>
                  )}
                </button>
                <ul
                  className={`dropdown-menu ${styles['lsr-options-dropdown-menu']}`}>
                  {props.userProfile.permissions.lsRequest_api
                    .add_lsrequest && (
                    <>
                      <li className='dropdown-item' onClick={onOpenCreate}>
                        Create New LSR
                      </li>
                      {screenWidth < 768 && (
                        <li
                          className='dropdown-item'
                          onClick={onOpenStatusChange}>
                          Status Change Request
                          {statusChangeRequestCounter > 0 && 's'}
                          <span
                            className={`badge ms-2 ${styles['badge-count']}`}>
                            {statusChangeRequestCounter}
                          </span>
                        </li>
                      )}
                      <hr className='dropdown-divider' />
                    </>
                  )}
                  {window.location.pathname
                    .toLowerCase()
                    .includes('myactivels') &&
                    screenWidth < 1233 && (
                      <>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle60').click()
                          }>
                          <label
                            for='daylisttoggle60'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle60'
                              onClick={e => buttonFilterToggle(e, '60')}
                            />
                            View 60 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle55').click()
                          }>
                          <label
                            for='daylisttoggle55'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle55'
                              onClick={e => buttonFilterToggle(e, '55')}
                            />
                            View 55 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle45').click()
                          }>
                          <label
                            for='daylisttoggle45'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle45'
                              onClick={e => buttonFilterToggle(e, '45')}
                            />
                            View 45 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle30').click()
                          }>
                          <label
                            for='daylisttoggle30'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle30'
                              onClick={e => buttonFilterToggle(e, '30')}
                            />
                            View 30 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggleall').click()
                          }>
                          <label
                            for='daylisttoggleall'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggleall'
                              onClick={e => buttonFilterToggle(e, 'all')}
                            />
                            View All
                          </label>
                        </li>
                        <hr className='dropdown-divider' />
                      </>
                    )}
                  <li className='dropdown-item'>
                    <CSVLink
                      className='text-decoration-none'
                      headers={
                        rolesUnderCouncilMembers.includes(
                          props.userProfile.role
                        )
                          ? csvHeaderCM
                          : csvHeaderNonCM
                      }
                      data={props.lsRequests.map(l => noLinebreakFunction(l))}
                      filename={`LS-export.csv`}>
                      Export Current View
                    </CSVLink>
                  </li>
                  {document.cookie.indexOf('exported_recently=true') === -1 &&
                    !rolesUnderCouncilMembers.includes(
                      props.userProfile.role
                    ) && (
                      <li
                        className='dropdown-item'
                        onClick={props.exportLsRequest}>
                        Export Full Log
                      </li>
                    )}
                  {document.cookie.indexOf('exported_recently=true') !== -1 &&
                    !rolesUnderCouncilMembers.includes(
                      props.userProfile.role
                    ) && (
                      <li
                        className='dropdown-item'
                        style={{
                          cursor: 'not-allowed',
                          pointerEvents: '  auto',
                        }}
                        disabled>
                        Export Full Log
                      </li>
                    )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        aria-hidden={!props.exporting}
        aria-modal={props.exporting}
        className={`modal fade ${props.exporting ? 'show' : ''}`}
        role='dialog'
        style={{
          display: props.exporting ? 'block' : 'none',
          backgroundColor: props.exporting ? 'rgba(0,0,0,.4)' : 'transparent',
        }}
        tabIndex='-1'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Please wait...</h5>
            </div>
            <div className='modal-body'>
              <p>
                We are processing your request. This may take a few minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
      {showFilterMenu && (
        <FilterMenu
          filterValues={filterValues}
          filterPreset={filterPreset}
          changeFilterPreset={changeFilterPreset}
          toggleFilterMenu={toggleFilterMenu}
          onClearFilter={onClearFilter}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
          applyFilters={applyFilters}
        />
      )}
      {showColumnMenu && (
        <CheckboxMenu
          filterFlag={filterFlag}
          columns={columns}
          columnPreset={columnPreset}
          toggleColumn={toggleColumn}
          changeColumnPreset={changeColumnPreset}
          checkAllColumns={checkAllColumns}
          isOpen={showColumnMenu}
          toggleColumnMenu={toggleColumnMenu}
          toggleAllColumns={toggleAllColumns}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    searchCount: state.lsRequestsReducer.fullCount,
    lsRequests: state.lsRequestsReducer.lsRequests,
    userProfile: state.userReducer.userProfile,
    isLoading: state.lsRequestsReducer.isLoading,
    exporting: state.lsRequestsReducer.exporting,
  };
};

const mapDispatchToProps = {
  exportLsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLsMenu);
