import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  filterUsers,
  staff,
  councilMembers,
  cmStaffers,
} from '../../utils/helper';
import styles from '../../styles/UserSelect.module.scss';

/**
 * You will need to pass in the function that set's the selected value to the state
 */
const UserSelect = props => {
  let users;
  let cm = props.users.filter(user => user.full_name === props.sponsor);
  switch (props.typeOfUsers) {
    case 'staff':
      users = staff(props.users);
      break;
    case 'council members':
      users = councilMembers(props.users);
      break;
    case 'cm_staff':
      users = cm.length === 1 ? cmStaffers(props.users, cm[0], props.userProfile) : [];
      break;
    default:
      users = filterUsers(props.users);
  }
  
  //If the is a value or the value full matches a user in the user list then there is no need to show the list
  if (!props.focused || users.find(user => user.full_name === props.search)) {
    return <div />;
  }
  const filteredUsers = users.sort((a, b) => {
    // Convert names to lowercase for case-insensitive sorting
    let nameA = a.full_name.toLowerCase();
    let nameB = b.full_name.toLowerCase();

    // Compare names
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // Names are equal
    return 0;
  }).filter(user =>
      user.full_name.toLowerCase().includes(props.search.toLowerCase())
  );

  return (
    <ul className={styles['UserSelect']}>
      {filteredUsers.map((user, id) => (
        <li onClick={props.handleClick} key={id}>
          {user.full_name}
        </li>
      ))}
    </ul>
  );
};
const mapStateToProps = state => {
  return {
    users: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
  };
};
UserSelect.propTypes = {
  search: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  typeOfUsers: PropTypes.string,
};
export default connect(mapStateToProps)(UserSelect);
