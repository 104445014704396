import React, { useState, useEffect } from 'react';
import {
  loadPageReview,
  pageReviewError,
} from '../../../actions/myTasksAction';
import PageForm from '../../wiki/PageForm';
import Parser from 'html-react-parser';
import styles from '../../../styles/user/ReviewList.module.scss';
import { connect } from 'react-redux';
import { ArrowCircleFillLeftIcon } from '../../../services/SvgLibrary';
import { useParams } from 'react-router-dom';

const ReviewPage = props => {
  const [confirm, setConfirm] = useState(false);
  const [discard, setDiscard] = useState('');
  const [errors, setErrors] = useState(false);
  const {
    current_review,
    error,
    loadingPageReview,
    loadPageReview,
    pageReviewError,
    review_diff,
    subject_tree,
    userList,
    userProfile,
  } = props;
  const { id } = useParams();

  /** component mount and unmount */
  useEffect(() => {
    if (id) loadPageReview(id);
    return () => {
      pageReviewError('');
    };
  }, [id, loadPageReview, pageReviewError]);

  function diffCalculation(oldField, oldIds, newField, newIds, param) {
    let oldList = oldField.map((item, idx) => {
      if (newIds.includes(item.id)) {
        return (
          <span key={idx} className={styles['item']}>
            {item[param]}
          </span>
        );
      } else {
        return (
          <span key={idx} className={`${styles['item']} ${styles['removed']}`}>
            {item[param]}
          </span>
        );
      }
    });

    let newList = newField
      .filter(field => !oldIds.includes(field.id))
      .map((item, idx) => {
        return (
          <span key={idx} className={`${styles['item']} ${styles['added']}`}>
            {item[param]}
          </span>
        );
      });
    return (
      <div>
        {oldList}
        {newList}
      </div>
    );
  }

  function getErrors(err) {
    if (errors !== err) {
      setErrors(err);
    }
  }

  function checkAuth() {
    let staffReviewers = userList.filter(user => user.can_review_wiki);
    if (
      staffReviewers.find(
        reviewer => reviewer.username === userProfile.username
      )
    ) {
      return true;
    } else if (
      userProfile.role === 'Deputy Director' ||
      userProfile.role === 'Assistant Deputy Director' ||
      userProfile.role === 'Admin'
    ) {
      let reviewComIds = current_review.committees.map(com => com.id);
      let userComIds = userProfile.committees.map(com => com.id);
      return reviewComIds.some(com => userComIds.includes(com));
    } else {
      return false;
    }
  }

  let committees = '';
  let tags = '';
  let subjects = '';

  if (current_review && Object.keys(subject_tree).length > 0) {
    let newPage = current_review;
    let newComIds = newPage.committees.map(com => com.id);
    let newTagIds = newPage.tags.map(tag => tag.id);
    let newSubPaths = newPage.subject.map(topic => {
      return subject_tree[topic];
    });

    if (current_review.page) {
      let oldPage = current_review.page;
      let oldComIds = oldPage.committees.map(com => com.id);
      let oldTagIds = oldPage.tags.map(tag => tag.id);
      let oldSubPaths = oldPage.subject.map(topic => {
        return subject_tree[topic];
      });

      subjects = diffCalculation(
        oldSubPaths,
        oldPage.subject,
        newSubPaths,
        newPage.subject,
        'path'
      );
      committees = diffCalculation(
        oldPage.committees,
        oldComIds,
        newPage.committees,
        newComIds,
        'committee_name'
      );
      tags = diffCalculation(
        oldPage.tags,
        oldTagIds,
        newPage.tags,
        newTagIds,
        'name'
      );
    } else {
      subjects = newSubPaths.map((sub, idx) => (
        <span key={idx} className={styles['item']}>
          {sub.path}
        </span>
      ));
      committees = newPage.committees.map((com, idx) => (
        <span key={idx} className={styles['item']}>
          {com.committee_name}
        </span>
      ));
      tags = newPage.tags.map((tag, idx) => (
        <span key={idx} className={styles['item']}>
          {tag.name}
        </span>
      ));
    }
  }
  return (
    <>
      {!loadingPageReview && (
        <div className='main-container'>
          <a className={styles['back-button']} href='/myTasks/myCLEXReviews'>
            <ArrowCircleFillLeftIcon /> Back
          </a>
          {!error && current_review && (
            <>
              <div className={styles['page-review-hero-section']}>
                <h1>{current_review.title}</h1>
                <span>Original Author: </span>
                {current_review.author.full_name}
                <br />
                <span>Drafter: </span>
                {current_review.editor}
              </div>
              <div className={styles['review-clex-wrapper']}>
                <PageForm
                  review={current_review}
                  confirm={confirm}
                  discard={discard}
                  getErrors={errors => getErrors(errors)}
                />
                <div className={styles['page-preview-container']}>
                  <div className={styles['page-preview-wrapper']}>
                    <div>Topics: {subjects}</div>
                    <div>Title: {Parser(review_diff.title)}</div>
                    <div>Committees: {committees}</div>
                    <div>Tags: {tags}</div>
                    <div>Re: {Parser(review_diff.memo_regarding)}</div>
                  </div>
                  {Parser(review_diff.description)}
                  {Parser(review_diff.content)}
                </div>
              </div>
              <div className={styles['footer']}>
                <button
                  className='cancel-button'
                  onClick={e => {
                    setDiscard(current_review.id);
                  }}>
                  Discard Changes
                </button>
                {checkAuth() && (
                  <button
                    className='submit-button'
                    disabled={errors}
                    onClick={e => {
                      setConfirm(true);
                    }}>
                    Publish Review
                  </button>
                )}
              </div>
            </>
          )}
          {error && (
            <div className={styles['page-review-hero-section']}>
              <h3>{error}</h3>
            </div>
          )}
        </div>
      )}
      {loadingPageReview && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            align='middle'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
    </>
  );
};

const mapStatetoProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    current_review: state.reviewListReducer.current_review,
    review_diff: state.reviewListReducer.review_diff,
    subject_tree: state.pageFormReducer.subject_tree,
    loadingPageReview: state.reviewListReducer.loadingPageReview,
    error: state.reviewListReducer.error,
  };
};

const mapDispatchToProps = {
  loadPageReview,
  pageReviewError,
};

export default connect(mapStatetoProps, mapDispatchToProps)(ReviewPage);
