import React from 'react';
import { connect } from 'react-redux';
import styles from '../../../../styles/user/UserProfile.module.scss';

const UserprofileCmStaffDivCommWidget = props => {
  const {
    userList,
    userProfile,
  } = props;

  let associated_cm = userProfile.under_council_member
    ? userList.find(u => u.id === userProfile.under_council_member)
    : null;
    
  return (
    <div
      className={`card rounded-3 border-0 ${styles['user-profile-detail-right-column-wrapper']}`}>
      <div className='card-body'>
        <table id={styles['profile-table']}>
          <tbody>
            {userProfile.under_council_member && associated_cm && (
              <tr className='align-top'>
                <td>Associated CM:</td>
                <td>
                  <a href={`/staff/${associated_cm.id}`}>
                    {associated_cm.full_name}
                  </a>
                </td>
              </tr>
            )}
              {userProfile.role === 'Council Member' && (
                <tr className='align-top'>
                  <td>Staffers:</td>
                  <td>
                    <ul>
                      {userList
                        .filter(staff => staff.under_council_member === id)
                        .map((staffer, idx) => (
                          <li key={idx}>
                            <a href={`/staff/${staffer.id}`}>
                              {staffer.full_name}
                              <em>
                                (<small>{staffer.role}</small>)
                              </em>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </td>
                </tr>
              )}

              {userProfile.division && (
                <tr className='align-top'>
                  <td>Division/Teams:</td>
                  <td>
                    <ul>
                      {userProfile.division.map((div, idx) => (
                        <li key={idx}>{div.division_name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
              {userProfile.committees && (
                <tr className='align-top'>
                  <td>Committees</td>
                  <td>
                    <ul>
                      {userProfile.committees.map((committee, idx) => (
                        <li key={idx}>{committee.committee_name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
  
const mapStateToProps = state => {
  return {
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    newUserCreationStatus: state.userReducer.newUserCreationStatus,
  };
};

export default connect (mapStateToProps)(UserprofileCmStaffDivCommWidget);