import React, { Component } from 'react';
import styles from '../../styles/report-tracking/TagsDisplay.module.scss';
import { FormGroup } from 'reactstrap';
import { CloseButtonCircleFillIcon } from '../../services/SvgLibrary';

class TagsDisplay extends Component {
  state = {
    searchContent: '',
    displayDropdown: false,
  };

  listRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    if (this.listRef.current && !this.listRef.current.contains(event.target)) {
      this.setState({ displayDropdown: false });
    }
  };

  addInputText(e) {
    if (this.state.searchContent) {
      e.preventDefault();
      let addedInput = {};
      addedInput[this.props.param] = this.state.searchContent;
      let success = this.props.onAddTarget(addedInput);
      if (success) {
        this.setState({ searchContent: '' });
      }
    }
  }

  render() {
    // Get the current selected assigned contacts
    // Give the notice if no assigned contact has been assigned
    const {
      currentList,
      onAddTarget,
      onDeleteTarget,
      noList,
      inputTitle,
      required,
      warningMessage,
      param,
    } = this.props;
    // Generate current selected contacts by tags
    // Enable delete function
    const tags =
      currentList &&
      currentList.map((c, idx) => {
        let displayAbridged = String(c[param]).slice(0, 20);
        if (c[param].length > 20) {
          displayAbridged += '...';
        }
        return (
          <div
            className={styles['tag']}
            key={idx}
            onClick={() => onDeleteTarget(idx)}>
            <span>{displayAbridged}</span>
            {!this.props.disabled && (
              <CloseButtonCircleFillIcon
                width='12'
                height='12'
                classNameProp={styles['delete-icon']}
              />
            )}
          </div>
        );
      });

    // Generate the dropdown search list
    // The dropdown list only appears when the input text area is not empty
    const filterDropdown = this.props.dropdownList
      ? this.props.dropdownList.filter(c =>
          c[param]
            .toLowerCase()
            .includes(this.state.searchContent.toLowerCase())
        )
      : [];

    if (filterDropdown.length === 0) {
      if (this.props.allowNew) {
        filterDropdown.push({ [param]: this.state.searchContent });
      } else {
        filterDropdown.push({ [param]: 'No valid result' });
      }
    }

    // Generate the contacts component
    return (
      <FormGroup className={styles['tag-form-group']}>
        <p className={styles['input-tag-title']}>
          {required ? (
            <>
              {inputTitle} <span style={{ color: '#DC6C6C' }}>*</span>
            </>
          ) : (
            inputTitle
          )}
        </p>
        <div>
          {tags}
          {this.props.localLawHelper && tags.length === 0
            ? this.props.localLawHelper
            : null}
          {currentList.length === 0 && (
            <p style={{ 'marginBottom': 0 }}>{warningMessage}</p>
          )}
        </div>
        <>
          {!noList && (
            <input
              type='text'
              className={`form-control field-size ${
                this.props.className || styles['input-text-area']
              }`}
              value={this.state.searchContent}
              placeholder={
                this.props.placeholder || 'Type and search in dropdown list'
              }
              ref={this.props.reference}
              disabled={
                this.props.disabled ||
                (this.props.lengthLimit &&
                  this.props.lengthLimit <= this.props.currentList.length)
              }
              onChange={e => {
                this.setState({ searchContent: e.target.value });
                if (e.target.value !== '') {
                  this.setState({ displayDropdown: true });
                } else {
                  this.setState({ displayDropdown: false });
                }
              }}
              onFocus={() => {
                this.setState({ displayDropdown: true });
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') e.preventDefault();
              }}
            />
          )}
          {noList && (
            <input
              type='text'
              className={`form-control field-size ${
                this.props.className || styles['input-text-area']
              }`}
              value={this.state.searchContent}
              placeholder={this.props.placeholder}
              ref={this.props.reference}
              disabled={
                this.props.disabled ||
                (this.props.lengthLimit &&
                  this.props.lengthLimit <= this.props.currentList.length)
              }
              onChange={e => {
                //Only take in positive number inputs
                if (param !== 'full_name') {
                  this.setState({ searchContent: e.target.value });
                }
                if (
                  (e.target.value &&
                    !isNaN(e.target.value) &&
                    parseInt(e.target.value) > 0) ||
                  !e.target.value
                )
                  this.setState({ searchContent: e.target.value });
              }}
              onBlur={e => this.addInputText(e)}
              onKeyUp={e => {
                if (e.key === 'Enter') this.addInputText(e);
              }}
            />
          )}
          {this.state.displayDropdown && (
            <ul className={styles['search-dropdown']} ref={this.listRef}>
              {filterDropdown.map((c, idx) => (
                <li
                  key={idx}
                  onMouseDown={() => {
                    if (c[param].toLowerCase() !== 'no valid result') {
                      onAddTarget(filterDropdown[idx]);
                    }
                    this.setState({
                      searchContent: '',
                      displayDropdown: false,
                    });
                  }}>
                  {c[param]}
                </li>
              ))}
            </ul>
          )}
        </>
      </FormGroup>
    );
  }
}

export default TagsDisplay;
