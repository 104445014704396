import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import Delta from 'quill-delta';
import { UndoArrowIcon, RedoArrowIcon } from '../../services/SvgLibrary';

const BlockEmbed = Quill.import('blots/block/embed');
const Embed = Quill.import('blots/embed');
const Inline = Quill.import('blots/inline');
const Link = Quill.import('formats/link');
const Clipboard = Quill.import('modules/clipboard');

class TabLink extends Link {
  static create(value) {
    if (
      value.includes('/CLEX/subject/') &&
      value.includes('/page/') &&
      !value.endsWith('?ref=true')
    ) {
      value = value + '?ref=true';
    }
    let node = super.create(value);
    value = this.sanitize(value);
    node.setAttribute('href', value);
    if (value.startsWith('#')) {
      node.removeAttribute('target');
    }
    return node;
  }
}

class EmbededBlot extends Embed {
  static create(value) {
    const node = super.create();
    node.setAttribute('src', value.value);
    node.setAttribute('contenteditable', false);
    node.innerHTML = value.value;
    return node;
  }
  static value(node) {
    return {
      value: node.getAttribute('src'),
    };
  }
}

class FootBlot extends Inline {
  static create(id) {
    const node = super.create();
    node.setAttribute('id', id);
    return node;
  }
}

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain');
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, 'silent');
    this.quill.setSelection(index, length, 'silent');
    this.quill.scrollIntoView();
  }
}

let Parchment = Quill.import('parchment');

let idAttr = new Parchment.Attributor.Attribute('foot', 'id', {
  scope: Parchment.Scope.INLINE,
});
/*et styleAttr = new Parchment.Attributor.Style('foot','font-size', {
  whitelist: ['10px']
})*/

class DividerBlot extends BlockEmbed {}

DividerBlot.blotName = 'divider';
DividerBlot.tagName = 'hr';
Quill.register(DividerBlot);
Quill.register('formats/tablink', TabLink);

FootBlot.blotName = 'foot';
FootBlot.tagName = 'span';
Quill.register('formats/foot', FootBlot);
Quill.register('attributors/attribute/footId', idAttr);

EmbededBlot.blotName = 'embeded';
EmbededBlot.tagName = 'rect';
Quill.register('formats/embeded', EmbededBlot);

Quill.register('modules/clipboard', PlainClipboard, true);

let findNumberOfFootnotes = content => {
  let count = 0;
  content.forEach(op => {
    if (
      op.insert.embeded &&
      op.attributes &&
      op.attributes.script &&
      op.attributes.link &&
      op.attributes.link.includes('#foot')
    ) {
      count += 1;
    }
  });
  return count;
};

/*
quill: Quill API
lastIndex: The index after which the citation index numbers should be updated
footNumber: The starting number that indexes should be updated to
addOrDelete: If true the update was the result of an addtion, if false the update was a result of a deletion
*/
let updateCitationIndexes = (
  quill,
  editorName,
  lastIndex,
  footNumber,
  addOrDelete
) => {
  let contentAfter = quill.getContents(lastIndex);
  let offset = 0;
  let newContent = new Delta();
  let citationsAfter = false;
  let citationsAfterNum = 0;

  //For each op after the footnote number change
  contentAfter.forEach((ftNote, idx) => {
    //If the op is footnote number
    if (
      ftNote.attributes &&
      ftNote.attributes.script &&
      ftNote.insert.embeded &&
      ftNote.attributes.link &&
      ftNote.attributes.link.includes('#foot')
    ) {
      citationsAfter = true;
      citationsAfterNum += 1;
      let newNum = footNumber + offset;

      // Push update number instructions
      let removed =
        typeof ftNote.insert === 'string' ? ftNote.insert.length : 1;
      newContent.push({ delete: removed });
      ftNote.attributes.link = `#foot-${
        newNum + (addOrDelete ? 1 : 0)
      }-${editorName}`;
      ftNote.insert.embeded.value = `${newNum + (addOrDelete ? 1 : 0)}`;

      offset += 1;
      newContent.push(ftNote);
    } else {
      newContent.push({
        retain: typeof ftNote.insert === 'string' ? ftNote.insert.length : 1,
      });
    }
  });

  //Merge instructions an update
  let previousContent = new Delta().retain(lastIndex);
  let mergedContent = previousContent.concat(newContent);
  quill.updateContents(mergedContent);
  return [citationsAfter, citationsAfterNum];
};

/*
quill: Quill API
citationsAfterNum: The number of citations that need to be updated after the change
footNumber: The starting number that indexes should be updated to
addOrDelete: If true the update was the result of an addtion, if false the update was a result of a deletion
*/
let updateCitations = (
  quill,
  editorName,
  citationsAfter,
  footNumber,
  addOrDelete,
  numberOfFootnotes,
  deletedFoots = 0
) => {
  let newContent = quill.getContents();
  let deltaLength = newContent.ops.length - 1;
  let lastNewLine = quill.getLength();
  let citationsAfterNum = citationsAfter;

  //While there are citations that need to be updated
  while (citationsAfterNum > 0) {
    let footnote = newContent.ops[deltaLength];
    lastNewLine -=
      typeof footnote.insert === 'string' ? footnote.insert.length : 1;

    // Go to the last footnote citation
    while (
      !(
        footnote.attributes &&
        footnote.attributes.foot &&
        footnote.insert.embeded
      )
    ) {
      deltaLength -= 1;
      footnote = newContent.ops[deltaLength];
      lastNewLine -=
        typeof footnote.insert === 'string' ? footnote.insert.length : 1;
    }

    let tempDelLength = deltaLength - 1;
    let tempOp = newContent.ops[tempDelLength];
    while (
      tempOp &&
      !(typeof tempOp.insert === 'string' && tempOp.insert.includes('\n'))
    ) {
      lastNewLine -=
        typeof tempOp.insert === 'string' ? tempOp.insert.length : 1;
      tempDelLength -= 1;
      tempOp = newContent.ops[tempDelLength];
    }

    //Update id of footnotes
    let idSplit = footnote.attributes.foot.split('-');
    let number = footNumber + citationsAfterNum - (addOrDelete ? 0 : 1);

    footnote.attributes.foot = `${idSplit[0]}-${number}-${editorName}`;

    let footnoteText = newContent.ops[deltaLength + 1];
    let footnoteLength = 0;

    let delIndex = 0;
    while (footnoteText && footnoteText.insert !== '\n') {
      footnoteLength +=
        typeof footnoteText.insert === 'string'
          ? footnoteText.insert.length
          : 1;
      delIndex += 1;
      footnoteText = newContent.ops[deltaLength + 1 + delIndex];
    }

    footnote.insert.embeded.value = `${number}.`;

    let deleteSize = footnoteLength + (citationsAfterNum === 1 ? 2 : 1) + 1;

    newContent.ops.splice(deltaLength, 0, { delete: deleteSize });

    deltaLength -= 1;
    citationsAfterNum--;
  }

  newContent.ops.splice(0, deltaLength + 1);

  if (!deletedFoots) {
    if (footNumber === 1 && !addOrDelete) {
      lastNewLine -= 1;
    }
  }

  if (footNumber === 1) {
    lastNewLine += 1;
  }

  //Merge content
  let mergedContent = {
    'ops': [{ 'retain': lastNewLine }, ...newContent.ops],
  };
  quill.updateContents(mergedContent);

  return [lastNewLine - 1, deltaLength];
};

/*
quill: Quill API
updatedContent: The content updated after an index or indexes are deleted
lastNewLines: The normal index, and op index of the end of the citations that should be deleted
footNumber: The starting number that citations that should be deleted?
loops: Number of citations being deleted
*/
let deleteCitation = (
  quill,
  updatedContent,
  lastNewLines,
  footNum,
  loops,
  citationCount = 0
) => {
  let delCount = 0;
  let newContent = updatedContent.ops[lastNewLines[1]];

  for (let i = 0; i < loops; i++) {
    while (
      !(
        newContent &&
        newContent.insert &&
        typeof newContent.insert === 'string' &&
        newContent.insert.includes('\n')
      )
    ) {
      lastNewLines[1] -= 1;
      delCount +=
        typeof newContent.insert === 'string' ? newContent.insert.length : 1;
      newContent = updatedContent.ops[lastNewLines[1]];
    }
    delCount += 1;
    lastNewLines[1] -= 1;
    newContent = updatedContent.ops[lastNewLines[1]];
  }
  if (footNum === 1) delCount -= 1;
  let retainedIndex = lastNewLines[0] - delCount;

  if (citationCount) retainedIndex += 1;
  if (loops > 1 && footNum === 1) delCount += 1;
  let delCitation = {
    ops: [{ retain: retainedIndex }, { delete: delCount }],
  };
  quill.updateContents(delCitation);
};

/*
quill: Quill API
footNumber: The starting number that indexes should be deleted?
loops: Number of indexes being deleted
*/
let deleteCitationIndexes = (quill, footNum, loops) => {
  let content = quill.getContents().ops;
  let currentOp = 0;
  let currentIndex = 0;
  let delIndexes = [];
  let lastFootNum = footNum + loops;

  while (loops !== 0) {
    while (
      currentOp < content.length &&
      !(
        content[currentOp].attributes &&
        content[currentOp].insert.embeded &&
        content[currentOp].attributes.script &&
        content[currentOp].attributes.link &&
        content[currentOp].attributes.link.includes('#foot')
      )
    ) {
      if (loops === 0) {
        break;
      }
      if (content[currentOp].insert) {
        currentIndex +=
          (typeof content[currentOp].insert === 'string'
            ? content[currentOp].insert.length
            : 1) + 1;
      }
      currentOp += 1;
    }
    let num = parseInt(content[currentOp].insert.embeded.value);
    if (footNum <= num && num < lastFootNum) {
      delIndexes.push(currentIndex);
      loops -= 1;
    }

    currentOp += 1;
  }

  let delOps = {
    ops: [],
  };
  let prevIndex = 0;
  delIndexes.forEach(index => {
    delOps.ops.push({ retain: index - prevIndex - 1 });
    delOps.ops.push({ delete: 1 });
    prevIndex = index;
  });

  quill.updateContents(delOps);
  return delIndexes[delIndexes.length - 1] + 1;
};

let checkFootnotes = content => {
  let foot = false;
  let divider = false;
  let citation = false;

  content.forEach(op => {
    if (op.insert.divider) divider = true;
    else if (op.attributes) {
      if (
        op.attributes.script === 'super' &&
        op.attributes.link &&
        op.attributes.link.includes('foot-')
      ) {
        foot = true;
      } else if (op.insert.embeded && op.attributes.foot) {
        citation = true;
      }
    }
  });

  return foot && divider && citation;
};

let deleteFootnotes = quill => {
  let content = quill.getContents();
  let deleteOps = new Delta();

  content.forEach((op, idx) => {
    //If the op is footnote number
    if (
      op.insert.embeded ||
      op.insert.divider ||
      (op.attributes && op.attributes.foot)
    ) {
      // Push update number instructions
      let removed = typeof op.insert === 'string' ? op.insert.length : 1;
      deleteOps.push({ delete: removed });
    } else {
      deleteOps.push({
        retain: typeof op.insert === 'string' ? op.insert.length : 1,
      });
    }
  });
  quill.updateContents(deleteOps);
};

let FootnoteManager = function (quill, editorName) {
  this.quill = quill;

  quill.on('text-change', (delta, oldDelta, source) => {
    //TODO: Add case for updating footnotes when undo or redo is run. Maybe even create an handler for quill.

    if (source === 'user') {
      let footnotesExisted = checkFootnotes(oldDelta);
      let footnotesExistNow = checkFootnotes(quill.getContents());

      if (footnotesExisted && !footnotesExistNow) {
        deleteFootnotes(quill);
      }

      //If a delete if called at the end
      let retainedIndex = 0;
      let deleteIndex = 0;
      let deleteArray = [];

      //If a delete if called at the end
      if (delta.ops[delta.ops.length - 1].delete && footnotesExistNow) {
        while (delta.ops.length > deleteIndex) {
          if (delta.ops[deleteIndex].retain) {
            retainedIndex += delta.ops[deleteIndex].retain;
          } else if (delta.ops[deleteIndex].insert) {
            retainedIndex +=
              typeof delta.ops[deleteIndex].insert === 'string'
                ? delta.ops[deleteIndex].insert.length
                : 1;
          } else if (delta.ops[deleteIndex].delete) {
            deleteArray.push({
              'retain': retainedIndex,
              'opIndex': deleteIndex,
            });
          }
          deleteIndex += 1;
        }

        if (deleteArray.length === 1) {
          let numberOfFootnotes = findNumberOfFootnotes(oldDelta);
          let index = deleteArray[0].retain;
          let originalIndex = deleteArray[0].retain;

          let deltaIndex = 0;
          let currentOp = oldDelta.ops[deltaIndex].insert;
          let currentOpLength =
            typeof currentOp === 'string' ? currentOp.length : 1;

          //Go to the delta op of where the delete occured
          while (index > currentOpLength) {
            index -= currentOpLength;
            deltaIndex += 1;

            if (oldDelta.ops[deltaIndex]) {
              currentOp = oldDelta.ops[deltaIndex];
              currentOpLength =
                typeof currentOp.insert === 'string'
                  ? currentOp.insert.length
                  : 1;
            }
          }

          if (index === currentOpLength) {
            deltaIndex += 1;
            if (oldDelta.ops[deltaIndex]) {
              currentOp = oldDelta.ops[deltaIndex];
              currentOpLength =
                typeof currentOp.insert === 'string'
                  ? currentOp.insert.length
                  : 1;
            }
          } else if (index < currentOpLength) {
            currentOpLength = currentOpLength - index;
          }

          //If the delete only occurs in the current op
          if (currentOpLength >= delta.ops[deleteArray[0].opIndex].delete) {
            let nextOp = oldDelta.ops[deltaIndex + 1];

            // When clicking before the number that is to be deleted
            if (
              nextOp &&
              nextOp.insert.embeded &&
              nextOp.attributes &&
              nextOp.attributes.foot
            ) {
              let afterOp = oldDelta.ops[deltaIndex + 2];

              let delIndex = originalIndex;

              let deleteLength = 1;
              let dotIndex = nextOp.insert.embeded.value.indexOf('.');
              let num = parseInt(
                nextOp.insert.embeded.value.slice(0, dotIndex)
              );

              if (afterOp.attributes && afterOp.attributes.foot) {
                deleteLength += afterOp.insert.length;
              }

              let indexToUpdate = deleteCitationIndexes(quill, num, 1) - 1;

              let citations = updateCitationIndexes(
                quill,
                editorName,
                indexToUpdate,
                num,
                false,
                numberOfFootnotes,
                1
              );

              if (citations[0]) {
                updateCitations(
                  this.quill,
                  editorName,
                  citations[1],
                  num,
                  false,
                  numberOfFootnotes,
                  1
                );
              }

              quill.updateContents({
                ops: [{ retain: delIndex - 1 }, { delete: deleteLength }],
              });
            }

            // When clicking after the number that is to be deleted
            if (currentOp.attributes) {
              if (currentOp.attributes.foot) {
                if (currentOp.insert && currentOp.insert.embeded) {
                  let delIndex = originalIndex - index + 1;

                  let deleteLength = 1;
                  let dotIndex = currentOp.insert.embeded.value.indexOf('.');
                  let num = parseInt(
                    currentOp.insert.embeded.value.slice(0, dotIndex)
                  );

                  if (nextOp.attributes && nextOp.attributes.foot) {
                    deleteLength +=
                      typeof nextOp.insert === 'string'
                        ? nextOp.insert.length
                        : 1;
                  }

                  let indexToUpdate = deleteCitationIndexes(quill, num, 1) - 1;

                  let citations = updateCitationIndexes(
                    quill,
                    editorName,
                    indexToUpdate,
                    num,
                    false
                  );

                  quill.updateContents({
                    ops: [{ retain: delIndex - 1 }, { delete: deleteLength }],
                  });
                  if (citations[0]) {
                    updateCitations(
                      this.quill,
                      editorName,
                      citations[1],
                      num,
                      false,
                      numberOfFootnotes,
                      1
                    );
                  }
                }
              } else if (
                currentOp.attributes.script === 'super' &&
                currentOp.attributes.link &&
                currentOp.attributes.link.includes('foot-')
              ) {
                let footNum = parseInt(currentOp.attributes.link.split('-')[1]);
                let footIndexAfter = updateCitationIndexes(
                  this.quill,
                  editorName,
                  delta.ops[0].retain,
                  footNum,
                  false
                );

                //let citationsAfter = footIndexAfter[0];
                let citationsAfterNum = footIndexAfter[1];

                let lastNewLines = updateCitations(
                  this.quill,
                  editorName,
                  citationsAfterNum,
                  footNum,
                  false,
                  numberOfFootnotes
                );
                lastNewLines[1] -= 1;

                let updatedContent = this.quill.getContents();
                if (footNum === 1) lastNewLines[0] += 1;

                deleteCitation(
                  this.quill,
                  updatedContent,
                  lastNewLines,
                  footNum,
                  1
                );
              }
            }
          } else {
            //Get all the ops of footnote deletions
            let deleteLength = delta.ops[deleteArray[0].opIndex].delete;
            let deletedArray = [];

            while (deleteLength >= currentOpLength) {
              if (currentOp.attributes) {
                if (currentOp.attributes.foot) {
                  deletedArray.push(currentOp);
                } else if (
                  currentOp.attributes.script === 'super' &&
                  currentOp.attributes.link &&
                  currentOp.attributes.link.includes('foot-')
                ) {
                  deletedArray.push(currentOp);
                }
              }
              deltaIndex += 1;
              deleteLength -= currentOpLength;
              currentOp = oldDelta.ops[deltaIndex];
              if (currentOp) {
                currentOpLength =
                  typeof currentOp.insert === 'string'
                    ? currentOp.insert.length
                    : 1;
              } else {
                currentOpLength = 0;
              }
            }

            if (currentOp.attributes && deleteLength !== 0) {
              if (currentOp.attributes.foot) {
                deletedArray.push(currentOp);
              } else if (
                currentOp.attributes.script === 'super' &&
                currentOp.attributes.link &&
                currentOp.attributes.link.includes('foot-')
              ) {
                deletedArray.push(currentOp);
              }
            }

            let footIndex = false;
            let footCitation = false;
            let footNumber = 0;
            let delCount = 0;

            deletedArray.forEach(op => {
              if (op.attributes) {
                if (op.attributes.foot && op.insert.embeded) {
                  if (!footCitation)
                    footNumber = parseInt(
                      op.insert.embeded.value.split('.')[0]
                    );
                  delCount += 1;
                  footCitation = true;
                } else if (
                  op.attributes.script === 'super' &&
                  op.attributes.link &&
                  op.attributes.link.includes('foot-')
                ) {
                  footIndex = true;
                }
              }
            });

            if (!footIndex && footCitation) {
              //TODO: Update the footnotes when a highlight delete deletes citations

              let erasedCitations = 0;
              while (!deletedArray[erasedCitations].insert.embeded) {
                erasedCitations += 1;
              }
              let delIndex = originalIndex;

              let lastDelOP = deletedArray[deletedArray.length - 1];
              let nextOp = oldDelta.ops[deltaIndex];
              let multiDelOps = {
                ops: [{ retain: delIndex }, { delete: 1 }],
              };

              //If last op is the embedded citation, or before the embeded citation, or
              //the embeded text is the first to be deleted remove the associated text
              if (lastDelOP.insert.embeded) {
                if (nextOp.insert !== '\n') {
                  multiDelOps.ops[1].delete = nextOp.insert.length;
                  quill.updateContents(multiDelOps);
                }
              } else if (
                lastDelOP.attributes &&
                lastDelOP.attributes.foot &&
                deleteLength > 0
              ) {
                if (nextOp.attributes && nextOp.attributes.foot) {
                  multiDelOps.ops[1].delete =
                    nextOp.insert.length - deleteLength;
                }
                quill.updateContents(multiDelOps);
              } else {
                if (deletedArray[0].insert.embeded) {
                  quill.updateContents(multiDelOps);
                }
              }

              let indexToUpdate =
                deleteCitationIndexes(quill, footNumber, delCount) - 1;
              let citations = updateCitationIndexes(
                quill,
                editorName,
                indexToUpdate,
                footNumber,
                false
              );
              if (citations[0]) {
                updateCitations(
                  this.quill,
                  citations[1],
                  footNumber,
                  false,
                  numberOfFootnotes,
                  1
                );
              }
            } else if (footIndex && !footCitation) {
              let footNum = parseInt(
                deletedArray[0].attributes.link.split('-')[1]
              );
              let footIndexAfter = updateCitationIndexes(
                this.quill,
                editorName,
                delta.ops[0].retain,
                footNum,
                false
              );
              let citationsAfterNum = footIndexAfter[1];
              let deletedFootnoteIndexes =
                numberOfFootnotes - (footNum - 1) - citationsAfterNum;

              let lastNewLines = updateCitations(
                this.quill,
                editorName,
                citationsAfterNum,
                footNum,
                false,
                numberOfFootnotes,
                deletedFootnoteIndexes
              );
              lastNewLines[1] -= 1;

              if (citationsAfterNum !== 0) lastNewLines[0] -= 1;

              let updatedContent = this.quill.getContents();
              deleteCitation(
                this.quill,
                updatedContent,
                lastNewLines,
                footNum,
                deletedArray.length,
                citationsAfterNum
              );
            }
          }
        } else {
          let currentContent = this.quill.getContents();
          let newNumberOfFootnotes = findNumberOfFootnotes(currentContent);

          updateCitations(
            this.quill,
            editorName,
            newNumberOfFootnotes,
            1,
            false,
            newNumberOfFootnotes,
            1
          );
          updateCitationIndexes(this.quill, editorName, 0, 1, false);
        }
      } else if (footnotesExistNow) {
        let checkEmbeded = false;
        delta.forEach(op => {
          if (
            op.insert &&
            op.insert.embeded &&
            op.attributes &&
            (op.attributes.script || op.attributes.foot)
          ) {
            checkEmbeded = true;
          }
        });

        if (checkEmbeded) {
          let currentContent = this.quill.getContents();
          let newNumberOfFootnotes = findNumberOfFootnotes(currentContent);

          updateCitations(
            this.quill,
            editorName,
            newNumberOfFootnotes,
            1,
            false,
            newNumberOfFootnotes,
            1
          );
          updateCitationIndexes(this.quill, editorName, 0, 1, false);
        }
      }
    }
  });
};

Quill.register('modules/footnotes', FootnoteManager);

class FormTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { editorHtml: '' };
    this.quillRef = null;
    this.reactQuillRef = Quill;
  }

  componentDidMount() {
    this.registerFormats();
    this.setState({
      editorHtml: this.props.content, // trigger update
    });
  }

  componentDidUpdate() {
    this.registerFormats();
  }

  registerFormats = () => {
    // Ensure React-Quill references is available:
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    // Skip if Quill reference is defined:
    if (this.quillRef != null) return;
    const quillRef = this.reactQuillRef.getEditor(); // could still be null

    if (quillRef != null) {
      this.quillRef = quillRef;
    }
  };

  handleChange(html) {
    this.setState({ editorHtml: html });
  }

  addFootnote = () => {
    let source = prompt('Enter Citation');
    if (source) {
      // Calculate and add citation number
      let range = this.quillRef.getSelection();
      let index = range.index + range.length;
      let name = this.getName();

      let contentBefore = this.quillRef.getContents(0, index);
      let footNumber = findNumberOfFootnotes(contentBefore) + 1;
      let footLink = `${footNumber}`;

      this.quillRef.insertText(index, ' ', { 'link': false, 'script': false });

      this.quillRef.insertEmbed(index + 1, 'embeded', { 'value': footLink });
      this.quillRef.insertText(index + 2, ' ', {
        'link': false,
        'script': false,
      });

      this.quillRef.formatText(index + 1, 1, {
        'script': 'super',
        'link': `#foot-${footNumber}-${name}`,
        'bold': true,
      });
      this.quillRef.setSelection(index + 3, 0);

      // Update later citation number
      let footIndexAfter = updateCitationIndexes(
        this.quillRef,
        this.getName(),
        index + footLink.length + 2,
        footNumber,
        true
      );
      let citationsAfter = footIndexAfter[0];
      let citationsAfterNum = footIndexAfter[1];

      // Add Footnote
      let footnoteStart = -1;

      if (this.state.editorHtml) {
        footnoteStart = this.state.editorHtml.indexOf('id="foot-1');
      }

      if (footnoteStart === -1 || !citationsAfter) {
        if (footnoteStart === -1) {
          let sel = this.quillRef.getLength();
          this.quillRef.insertEmbed(sel + 1, 'divider', true);
        }
        let numText = `${footNumber}.`;
        let initialIndex = this.quillRef.getLength();
        this.quillRef.insertEmbed(initialIndex, 'embeded', {
          'value': numText,
        });
        source = ` ${source}`;
        this.quillRef.insertText(initialIndex + 1, source);
        this.quillRef.formatText(initialIndex, source.length + 1, {
          'foot': `foot-${footNumber}-${name}`,
          'size': 'small',
        });
      } else {
        let numberOfFootnotes = findNumberOfFootnotes(
          this.quillRef.getContents()
        );
        let lastNewLine = updateCitations(
          this.quillRef,
          this.getName(),
          citationsAfterNum,
          footNumber,
          true,
          numberOfFootnotes
        )[0];

        this.quillRef.insertText(lastNewLine + 1, '\n', {
          'foot': `foot-${footNumber}-${name}`,
          'size': 'small',
        });
        lastNewLine += 1;
        let numberText = `${footNumber}.`;
        this.quillRef.insertEmbed(lastNewLine, 'embeded', {
          'value': numberText,
        });
        source = ` ${source}`;
        this.quillRef.insertText(lastNewLine + 1, source, {
          'foot': `foot-${footNumber}-${name}`,
          'size': 'small',
        });
        this.quillRef.formatText(lastNewLine, source.length + 1, {
          'foot': `foot-${footNumber}-${name}`,
          'size': 'small',
        });
      }
    }
  };

  getName() {
    if (this.props.name !== 'description') {
      let fieldName = this.props.name;
      let id = '';
      while (fieldName.includes('sections')) {
        if (id) {
          id += '_';
        }
        let endIndex = fieldName.indexOf(']');
        id += fieldName.substring(9, endIndex);
        fieldName = fieldName.substring(endIndex + 1);
      }
      return id;
    } else {
      return this.props.name;
    }
  }

  modules = {
    footnotes: this.getName(),
    toolbar: {
      container: `#tooltip-${this.getName()}`,
      handlers: {
        'script': value => {
          if (value) {
            this.addFootnote();
          }
        },
      },
    },
    history: {
      delay: 1000,
      maxStack: 500,
      userOnly: false,
    },
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
  ];

  render() {
    return (
      <div>
        <div id={`tooltip-${this.getName()}`}>
          <span className='ql-formats'>
            <button className='ql-bold' title='Bold' />
            <button className='ql-italic' title='Italics' />
            <button className='ql-underline' title='Underline' />
            <button className='ql-blockquote' title='Blockquote' />
          </span>

          <span className='ql-formats'>
            <button className='ql-list' value='ordered' title='Numbering' />
            <button className='ql-list' value='bullet' title='Bullets' />
          </span>

          <span className='ql-formats'>
            <button
              title='Undo'
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.quillRef.history.undo();
              }}>
              <UndoArrowIcon />
            </button>
            <button
              title='Redo'
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.quillRef.history.redo();
              }}>
              <RedoArrowIcon />
            </button>
          </span>

          <span className='ql-formats'>
            <button className='ql-link' title='Link' />
          </span>

          <span className='ql-formats'>
            <button
              title='Insert Footnote'
              className='ql-script'
              value='super'
            />
          </span>
        </div>
        <ReactQuill
          ref={el => {
            this.reactQuillRef = el;
          }}
          {...this.props}
          onChange={content => {
            this.props.onChange(content);
            this.handleChange(content);
          }}
          modules={this.modules}
          theme={'snow'} // pass false to use minimal theme
        />
      </div>
    );
  }
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
FormTextEditor.formats = [
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'link',
  'super',
  'size',
];

/*const mapStateToProps = (state) => {
  return ({
    content: state.wikiPageReducer.content
  });
}*/

export default FormTextEditor;
