import { wikiPageActions } from '../actions/wikiPageAction';

const initialState = {
  all_subjects: 0,
  subject_tree: 0,
  displaySubject: -1,
  currentSubject: {},
  parentSubject: 0,
  children: [],
  originalChildren: [],
  title: '',
  errors: '',
  fail: '',
  success: '',
  deleteModal: false,
  isLoading: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case wikiPageActions.LOAD_ROOT_SUBJECT:
      return { ...state };

    case wikiPageActions.STORE_ORDER_ROOT_SUBJECTS:
      return {
        ...state,
        children: action.subjects.results,
        originalChildren: action.subjects.results,
      };

    case wikiPageActions.STORE_ORDER_CHILD_SUBJECTS:
      return { ...state, originalChildren: action.subjects };

    case wikiPageActions.REORDER_CHILD_SUBJECTS:
      return { ...state, children: action.subjects };

    case wikiPageActions.UPDATE_SUBJECT:
      return { ...state };

    case wikiPageActions.LOAD_ALL_SUBJECTS:
      return { ...state };

    case wikiPageActions.STORE_ALL_SUBJECTS:
      return { ...state, all_subjects: action.all_subjects };

    case wikiPageActions.STORE_FLAT_SUBJECT_TREE:
      return { ...state, subject_tree: action.flatTree };

    case wikiPageActions.STORE_LOCK_STATUS:
      return { ...state, parentSubject: action.lock };

    case wikiPageActions.DISPLAY_SUBJECT_UPDATE:
      return { ...state, displaySubject: action.subject };

    case wikiPageActions.STORE_CURRENT_SUBJECT:
      return { ...state, currentSubject: action.subject };

    case wikiPageActions.SUBJECT_FORM_FAIL:
      return { ...state, fail: action.message };

    case wikiPageActions.SUBJECT_FORM_SUCCESS:
      return { ...state, success: action.message };

    case wikiPageActions.DELETE_SUBJECT_MODAL:
      return { ...state, deleteModal: action.display };

    case wikiPageActions.SUBJECT_FORM_LOAD:
      return { ...state, isLoading: action.status };

    default:
      return state;
  }
}
