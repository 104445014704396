import React, { useState, useEffect } from 'react';
import styles from '../../styles/proclamation-request/Calendar.module.scss';
import {
  isWithinInterval,
  addDays,
  startOfDay,
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay,
  toDate,
  addMonths,
  subMonths,
  parse,
} from 'date-fns';
import { connect } from 'react-redux';
import * as actions from '../../actions/procRequestAction';
import ProcRequestServicePopup from './ProcRequestServicePopup';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
  ShortLeftArrowIcon,
  ShortRightArrowIcon,
  CloseButtonNormalIcon,
  FunnelFilterIcon,
  InfoCircleFilledIcon,
} from '../../services/SvgLibrary';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { generateSafeDateFromMonthYear } from '../../utils/helper';

ChartJS.register(ArcElement, Tooltip, Legend);

const Calendar = props => {
  const [currentMonth, setCurrentMonth] = useState(
    localStorage.getItem('procRequestSelectedMonth') !== null
      ? localStorage.getItem('procRequestSelectedMonth')
      : format(new Date(), 'MMM yyyy')
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [changeMonthYear, setChangeMonthYear] = useState(false);
  const [inputMonth, setInputMonth] = useState(1);
  const [inputYear, setInputYear] = useState(null);

  // States pertaining to statuses
  const [pending, setPending] = useState(true);
  const [approved, setApproved] = useState(true);
  const [inProgress, setInProgress] = useState(true);
  const [completed, setCompleted] = useState(true);
  const [delivered, setDelivered] = useState(true);
  const [withdrawn, setWithdrawn] = useState(true);

  const [sortByDateNeeded, setSortByDateNeeded] = useState(true);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // const [isCalendarFullScreen, setIsCalendarFullScreen] = useState(false)

  const [years, setYears] = useState([]);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // const toggleCalendarFullScreen = () => {
  //   setIsCalendarFullScreen(!isCalendarFullScreen)
  // }

  useEffect(() => {
    let yearsArr = [];
    const today = new Date();
    let todayYear = today.getFullYear() + 5;
    for (let i = 2023; i <= todayYear; i++) {
      yearsArr.push(i);
    }

    const validTime = generateSafeDateFromMonthYear(currentMonth);
    setYears(yearsArr);
    setInputMonth(format(new Date(validTime), 'M') - 1);
    setInputYear(format(new Date(validTime), 'yyyy'));

    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    });
  }, []);

  // Send out new HTTP request when the end user edit target month and year
  const onSelectTime = (e, sortBy = sortByDateNeeded) => {
    e.preventDefault();
    let newDate = parse(
      `${Number(inputMonth) + 1} ${inputYear}`,
      'M yyyy',
      new Date()
    );
    setCurrentMonth(format(newDate, 'MMM yyyy'));
    setChangeMonthYear(false);

    localStorage.setItem(
      'procRequestSelectedMonth',
      format(newDate, 'MMM yyyy')
    );
    let month = parseInt(inputMonth) + 1;
    if (month > 12) {
      month = 1;
    }
    props.getProcRequestCurrentMonthAndYear(
      Number(inputMonth) + 1,
      inputYear,
      sortBy
    );
  };

  const getNewCalendarData = e => {
    onSelectTime(e, !sortByDateNeeded);
    if (sortByDateNeeded === true) {
      setSortByDateNeeded(!sortByDateNeeded);
    } else {
      setSortByDateNeeded(!sortByDateNeeded);
    }
  };

  // format(date, format)
  const renderHeader = () => {
    const formattedDate = format(
      new Date(generateSafeDateFromMonthYear(currentMonth)),
      'MMMM yyyy'
    ); // FYI, I tried changing the formatting from 'MMM' to 'MMMM' on lines 41, 107, 112, but it did not work
    const dateArray = formattedDate.split(' ');
    const month = dateArray[0];
    const year = dateArray[1];

    return (
      <div className={styles['calendarHeader']}>
        {!changeMonthYear && (
          <div className='d-flex align-items-center'>
            <button
              outline='true'
              className={`btn ${styles['calendar-view-toggle']}`}
              onClick={() => updateCalendar()}>
              Today
            </button>
            <ShortLeftArrowIcon
              classNameProp={styles['arrow-month-selection']}
              onClickFunction={() => updateCalendar('previous')}
            />
            <ShortRightArrowIcon
              classNameProp={styles['arrow-month-selection']}
              onClickFunction={() => updateCalendar('next')}
            />
            <div
              className={`ms-1 d-flex align-items-center ${styles['current-month-container']}`}
              onClick={() => setChangeMonthYear(true)}>
              <span className={styles['current-month-wrapper']}>{month}</span>
              &nbsp;
              <span className={styles['current-year-wrapper']}>{year}</span>
            </div>
          </div>
        )}
        {changeMonthYear && (
          <div className={styles['change-monthYear-container']}>
            <select
              className={styles['select-box']}
              value={inputMonth}
              onChange={e => setInputMonth(e.target.value)}>
              {months.map((m, idx) => (
                <option value={idx} key={idx}>
                  {m}
                </option>
              ))}
            </select>
            <select
              className={styles['select-box']}
              value={inputYear}
              onChange={e => setInputYear(e.target.value)}>
              {years.map((y, idx) => (
                <option value={y} key={idx}>
                  {y}
                </option>
              ))}
            </select>
            <button className={styles['submit']} onClick={onSelectTime}>
              Go
            </button>
            <button
              className={`btn ${styles['close-monthYear-input-fields']}`}
              onClick={() => setChangeMonthYear(false)}>
              <CloseButtonNormalIcon />
            </button>
          </div>
        )}
        <div className='d-flex ms-auto align-items-center'>
          <button
            className={`btn ${styles['dropdown-filter-toggle']}`}
            data-bs-toggle='dropdown'
            aria-expanded='false'
            data-bs-auto-close='outside'
            title='Status Filter'
            type='button'>
            <FunnelFilterIcon />
          </button>
          <ul className={`dropdown-menu ${styles['filter-dropdown-menu']}`}>
            <li>
              <p className='dropdown-header'>Select stage</p>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['pending']}`}>
                Pending
                <input
                  type='checkbox'
                  checked={pending}
                  onChange={e => setPending(!pending)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['pending']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='pending'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['approved']}`}>
                Approved
                <input
                  type='checkbox'
                  checked={approved}
                  onChange={e => setApproved(!approved)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['approved']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='approved'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['in-progress']}`}>
                In Progress
                <input
                  type='checkbox'
                  checked={inProgress}
                  onChange={e => setInProgress(!inProgress)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['in-progress']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='in-progress'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['completed']}`}>
                Completed
                <input
                  type='checkbox'
                  checked={completed}
                  onChange={e => setCompleted(!completed)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='completed'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['delivered']}`}>
                Delivered
                <input
                  type='checkbox'
                  checked={delivered}
                  onChange={e => setDelivered(!delivered)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['delivered']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='delivered'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['withdrawn']}`}>
                Withdrawn
                <input
                  type='checkbox'
                  checked={withdrawn}
                  onChange={e => setWithdrawn(!withdrawn)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['withdrawn']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='withdrawn'
                />
              </label>
            </li>
            {!rolesUnderCouncilMembers.includes(props.userProfile.role) && (
              <div>
                <li>
                  <hr className='dropdown-divider'></hr>
                </li>
                <li>
                  <p className='dropdown-header'>View Proclamations By</p>
                </li>
                <li>
                  <label
                    className={`${styles['color-filter-container']} ${styles['completed']}`}>
                    Date Proclamation is Needed
                    <input
                      type='checkbox'
                      checked={sortByDateNeeded}
                      onChange={e => getNewCalendarData(e)}
                    />
                    <span
                      className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                  </label>
                </li>
                <li>
                  <label
                    className={`${styles['color-filter-container']} ${styles['completed']}`}>
                    Date of Event
                    <input
                      type='checkbox'
                      checked={!sortByDateNeeded}
                      onChange={e => getNewCalendarData(e)}
                    />
                    <span
                      className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                  </label>
                </li>
              </div>
            )}
          </ul>
          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='pending'>
            <PopoverBody>
              <div>
                <b>Pending:</b> Proclamation request is under review for
                approval.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='approved'>
            <PopoverBody>
              <div>
                <b>Approved:</b> All required information has been received and
                the honoree has cleared vetting.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='in-progress'>
            <PopoverBody>
              <div>
                <b>In Progress:</b> Drafting of Proclamation Request in
                progress.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='completed'>
            <PopoverBody>
              <div>
                <b>Completed:</b> The Proclamation Request is completed.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='delivered'>
            <PopoverBody>
              <div>
                <b>Delivered:</b> The Proclamation Request has been delivered.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='withdrawn'>
            <PopoverBody>
              <div>
                <b>Withdrawn:</b> Proclamation Request withdrawn.
              </div>
            </PopoverBody>
          </UncontrolledPopover>
          <button
            className='ms-2 btn'
            style={{
              backgroundColor: '#566fb1',
              color: '#FFF',
            }}
            onClick={() => {
              props.changeProcRequestDisplay('create');
              props.changeProcRequestPopupWindow(true);
            }}>
            + New
          </button>
        </div>
      </div>
    );
  };
  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(parse(currentMonth, 'MMM yyyy', new Date()));
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={styles['daysCol']} key={i}>
          {format(addDays(startDate, i), 'eee')}
        </div>
      );
    }
    return <div className={styles['daysRows']}>{days}</div>;
  };

  const renderProcRequestsForTheDay = (procRequestsForTheDay, day) => {
    const currentDate = parse(currentMonth, 'MMM yyyy', new Date());
    currentDate.setDate(day);
    const pendingPR = pending
      ? procRequestsForTheDay.filter(procRequest => {
          return (
            procRequest.status.toLowerCase() === 'pending' ||
            procRequest.status === 'pending'
          );
        })
      : [];

    const approvedPR = approved
      ? procRequestsForTheDay.filter(procRequest => {
          return (
            procRequest.status.toLowerCase() === 'approved' ||
            procRequest.status === 'approved'
          );
        })
      : [];
    const inProgressPR = inProgress
      ? procRequestsForTheDay.filter(procRequest => {
          return (
            procRequest.status.toLowerCase() === 'in-progress' ||
            procRequest.status === 'in-progress'
          );
        })
      : [];
    const completedPR = completed
      ? procRequestsForTheDay.filter(procRequest => {
          return (
            procRequest.status.toLowerCase() === 'completed' ||
            procRequest.status === 'completed'
          );
        })
      : [];
    const deliveredPR = delivered
      ? procRequestsForTheDay.filter(procRequest => {
          return (
            procRequest.status.toLowerCase() === 'delivered' ||
            procRequest.status === 'delivered'
          );
        })
      : [];
    const withdrawnPR = withdrawn
      ? procRequestsForTheDay.filter(procRequest => {
          return (
            procRequest.status.toLowerCase() === 'withdrawn' ||
            procRequest.status === 'withdrawn'
          );
        })
      : [];

    let totalProcRequests = 0;
    if (pending) {
      totalProcRequests += pendingPR.length;
    }
    if (approved) {
      totalProcRequests += approvedPR.length;
    }
    if (inProgress) {
      totalProcRequests += inProgressPR.length;
    }
    if (completed) {
      totalProcRequests += completedPR.length;
    }
    if (delivered) {
      totalProcRequests += deliveredPR.length;
    }
    if (withdrawn) {
      totalProcRequests += withdrawnPR.length;
    }

    // For Doughnut chart
    const data = {
      labels: [
        'Pending',
        'Approved',
        'In Progress',
        'Completed',
        'Delivered',
        'Withdrawn',
      ],
      datasets: [
        {
          label: 'Proclamation Requests',
          data: [
            pendingPR.length,
            approvedPR.length,
            inProgressPR.length,
            completedPR.length,
            deliveredPR.length,
            withdrawnPR.length,
          ],
          backgroundColor: [
            '#5a70ac',
            '#009688',
            '#ff9800',
            '#636d77',
            '#2196f3',
            '#f44336',
          ],
          borderColor: [
            '#5a70ac',
            '#009688',
            '#ff9800',
            '#636d77',
            '#2196f3',
            '#f44336',
          ],
          borderWidth: 0,
          outerRadius: '100%',
          innerRadius: '99%',
          cornerRadius: '7%',
          padAngle: '10',
        },
      ],
    };
    const options = {
      cutout: 45,
      plugins: {
        legend: false,
      },
      responsive: false,
      maintainAspectRatio: false,
    };

    return (
      <div
        className={styles['proclamation-request-calendar-day-container']}
        style={{ padding: '3px' }}>
        {screenWidth > 460 && screenHeight > 570 && (
          <div className={styles['proc-num-container']}>
            <p
              className={styles['total-proc-requests-num']}
              style={{ marginBottom: '0' }}>
              {totalProcRequests}
            </p>
            <p
              style={{ fontSize: '0.8rem', marginBottom: '0' }}
              className={styles['']}>
              {totalProcRequests === 1 ? 'Request' : 'Requests'}
            </p>
          </div>
        )}
        <Doughnut
          width={110}
          height={110}
          type='doughnut'
          data={data}
          options={options}
        />
      </div>
    );
  };
  const renderCells = () => {
    const monthStart = startOfMonth(
      parse(currentMonth, 'MMM yyyy', new Date())
    );
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';
    const { procRequests } = props;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const numOfProcRequests = procRequests[formattedDate]
          ? procRequests[formattedDate].length
          : 0;
        const upcomingReports = isWithinInterval(cloneDay, {
          start: startOfDay(new Date()),
          end: addDays(new Date(), 7),
        });
        days.push(
          <div
            style={numOfProcRequests > 0 ? { cursor: 'pointer' } : {}}
            className={`${
              !isSameMonth(day, monthStart)
                ? styles['colCellDisabled']
                : isSameDay(day, selectedDate)
                ? styles['colCellSelected']
                : styles['colCell']
            }`}
            key={day}
            onClick={() => onDateClick(toDate(cloneDay))}>
            <span className={styles['cellNumber']}>{formattedDate}</span>
            <span className={styles['bg']}>{formattedDate}</span>
            {day >= monthStart &&
              day <= monthEnd &&
              numOfProcRequests > 0 &&
              renderProcRequestsForTheDay(
                procRequests[formattedDate],
                upcomingReports,
                formattedDate
              )}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={styles['temp-row']} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return (
      <div
        className={styles['row-container']}
        style={{ height: props.rowContainerHeight }}>
        {rows}
      </div>
    );
  };

  const onDateClick = day => {
    setSelectedDate(day);
    const idx = format(day, 'd');
    const shownStatuses = {
      pending: pending,
      approved: approved,
      'in-progress': inProgress,
      completed: completed,
      delivered: delivered,
      withdrawn: withdrawn,
    };
    const filteredProcRequests = props.procRequests[idx].filter(
      ele =>
        shownStatuses[ele.status] || shownStatuses[ele.status.toLowerCase()]
    );

    if (filteredProcRequests.length > 0) {
      const currentDate = parse(currentMonth, 'MMM yyyy', new Date());
      currentDate.setDate(idx);
      props.selectTargetDayForProcRequests(filteredProcRequests);
    }
    if (filteredProcRequests.length === 1) {
      props.selectProcRequest(filteredProcRequests[0]);
      props.changeProcRequestDisplay('detail');
    }
    if (filteredProcRequests.length > 1) {
      props.changeProcRequestDisplay('list');
    }
  };

  const onProcRequestFilterClick = (filteredProcRequest, e) => {
    // Declared but nerver read, should this be removed?
    props.selectTargetDayForProcRequests(filteredProcRequest);
    e.stopPropagation();
  };

  const updateCalendar = (action = 'today') => {
    let newCurrentMonth;
    switch (action) {
      case 'previous':
        newCurrentMonth = subMonths(
          startOfMonth(parse(currentMonth, 'MMM yyyy', new Date())),
          1
        );
        break;
      case 'next':
        newCurrentMonth = addMonths(
          startOfMonth(parse(currentMonth, 'MMM yyyy', new Date())),
          1
        );
        break;
      default:
        newCurrentMonth = new Date();
        break;
    }
    props.getProcRequestCurrentMonthAndYear(
      newCurrentMonth.getMonth() + 1,
      newCurrentMonth.getFullYear(),
      sortByDateNeeded
    );
    const formattedNext = format(newCurrentMonth, 'MMM yyyy');
    localStorage.setItem('procRequestSelectedMonth', formattedNext);
    setCurrentMonth(formattedNext);
    setInputMonth(newCurrentMonth.getMonth());
    setInputYear(newCurrentMonth.getFullYear());
  };

  const { isLoading } = props;
  const shownStatuses = {
    pending: pending,
    approved: approved,
    'in-progress': inProgress,
    completed: completed,
    delivered: delivered,
    withdrawn: withdrawn,
  };

  return (
    <>
      {isLoading === false && (
        <div className={styles['proclamation-request-filters']}>
          {renderHeader()}
          {renderDays()}
          <div>{renderCells()}</div>
        </div>
      )}
      <ProcRequestServicePopup
        selectedDay={selectedDate}
        shownStatuses={shownStatuses}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    procRequests: state.procRequestReducer.allProcRequestsForDays,
    isLoading: state.procRequestReducer.isCalendarLoading,
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(Calendar);
