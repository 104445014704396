import React, { useEffect, useRef, useState } from 'react';

const TwoFactorAuthModal = ({ message, handleTwoFALogin }) => {
  const inputRefs = useRef([]);
  const [backspaceTimeout, setBackspaceTimeout] = useState(null); // handle a timeout when user hits backspace in the inputs

  const authInput = (e, id = null) => {
    const acceptedKeystrokes = [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
    ];
    let nextFocus;
    let idNum = parseInt(e.target.id.split('-')[2]);

    if (e.key === 'v' && (e.ctrlKey || e.metaKey)) {
      // Allow for copy & paste
    } else if (!acceptedKeystrokes.includes(e.key)) {
      return e.preventDefault();
    }

    // ----- Backspace/delete function ----- //
    if (
      e.key === 'Backspace' &&
      e.type === 'keydown' &&
      e.target.value === '' &&
      !backspaceTimeout
    ) {
      setBackspaceTimeout(
        setTimeout(() => {
          nextFocus = document.getElementById(
            `auth-field-${idNum ? idNum - 1 : idNum}`
          );
          if (nextFocus) {
            nextFocus.value = '';
            nextFocus.focus();
          }
          setBackspaceTimeout(null);
        }, 50)
      );
    } else if (
      e.key === 'Backspace' &&
      e.type === 'keyup' &&
      backspaceTimeout
    ) {
      clearTimeout(backspaceTimeout);
      setBackspaceTimeout(null);
      // ----- Arrow navigation ----- //
    } else if (e.key === 'ArrowLeft') {
      if (e.type === 'keyup') {
        nextFocus = document.getElementById(
          `auth-field-${idNum ? idNum - 1 : idNum}`
        );
        if (nextFocus) nextFocus.focus();
      } else {
        e.preventDefault();
      }
    } else if (e.key === 'ArrowRight') {
      if (e.type === 'keyup') {
        nextFocus = document.getElementById(`auth-field-${id}`);
        if (nextFocus) nextFocus.focus();
      } else {
        e.preventDefault();
      }
      // ----- Typing numbers ----- //
    } else if (e.target.value && e.type === 'keyup') {
      nextFocus = id
        ? document.getElementById(`auth-field-${id}`)
        : document.getElementById('auth-verify-button');
      if (nextFocus) nextFocus.focus();
    }
  };

  const moveInputToFirstEmpty = value => {
    const inputs = document.getElementsByClassName('auth-field');
    for (let i = 0; i < inputs.length; i++) {
      if (!inputs[i].value) {
        inputs[i].value = value;
        break;
      }
    }
  };

  const handleInput = e => {
    const idNum = parseInt(e.target.id.split('-')[2]);
    if (idNum !== 0) {
      const inputs = document.getElementsByClassName('auth-field');
      for (let i = 0; i < idNum; i++) {
        if (!inputs[i].value) {
          moveInputToFirstEmpty(e.target.value);
          e.target.value = '';
          break;
        }
      }
    }
  };

  const verifyClick = () => {
    let inputs = document.getElementsByClassName('auth-field');
    let code = '';
    for (let i = 0; i < inputs.length; i++) {
      code += inputs[i].value;
    }
    handleTwoFALogin(code);
  };

  //  It takes the pasted data, removes any non-numeric characters, and assigns
  //  the remaining digits to the input fields from left to right.
  const handlePaste = e => {
    e.preventDefault();
    let paste = e.clipboardData.getData('text'); // Retrieves the data that was just pasted from the clipboard
    paste = paste.replace(/\D/g, '').split(''); // This removes all non-numeric characters (all occurrences)
    for (let i = 0; i < 6; i++) {
      if (inputRefs.current[i] && paste[i]) {
        // Checks if the input element at index i exists and if there is a character at index i in the pasted data
        inputRefs.current[i].value = paste[i];
      }
    }
    document.getElementById('auth-verify-button').focus(); // Sets the focus to the element with the id auth-verify-button
  };

  // Sets both the start and end of the selection to the length of the input value.
  // Since the start and end of the selection are the same, there's no actual text
  // selection, and the cursor gets moved to the end of the input value
  const handleClick = e => {
    e.target.select();
  };

  useEffect(() => {
    let inputs = document.getElementsByClassName('auth-field');
    const focusHandlers = [];
    const keyDownHandlers = [];
    const keyUpHandlers = [];
    const clickHandlers = [];
    for (let i = 0; i < inputs.length; i++) {
      inputRefs.current[i] = inputs[i];

      focusHandlers[i] = () => inputs[i].select();
      inputs[i].addEventListener('focus', focusHandlers[i]);

      keyDownHandlers[i] = e => authInput(e);
      inputs[i].addEventListener('keydown', keyDownHandlers[i]);

      keyUpHandlers[i] = e =>
        authInput(e, i === 5 ? null : parseInt(inputs[i].id.split('-')[2]) + 1);
      inputs[i].addEventListener('keyup', keyUpHandlers[i]);

      inputs[i].addEventListener('input', handleInput);

      inputs[i].addEventListener('paste', handlePaste);

      clickHandlers[i] = handleClick;
      inputs[i].addEventListener('click', clickHandlers[i]);
    }
    return () => {
      // Cleans up those side-effects to prevent memory leak
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].removeEventListener('paste', handlePaste);
        inputs[i].removeEventListener('focus', focusHandlers[i]);
        inputs[i].removeEventListener('keydown', keyDownHandlers[i]);
        inputs[i].removeEventListener('keyup', keyUpHandlers[i]);
        inputs[i].removeEventListener('input', handleInput);
        inputs[i].removeEventListener('click', clickHandlers[i]);
      }
    };
  }, [backspaceTimeout]);

  const generateAuthInputs = () => {
    const elements = [0, 1, 2, 3, 4, 5].map(i => (
      <input
        key={i}
        type='text'
        maxLength={1}
        className={`auth-field form-control border-0 text-center ${
          i !== 5 ? 'me-2' : ''
        } ${i === 0 || i === 5 ? 'rounded-0' : ''}`}
        id={`auth-field-${i}`}
        autoFocus={i === 0 ? 'autofocus' : null}
      />
    ));
    return elements;
  };
  return (
    <div
      id='2faModal'
      aria-hidden={!Boolean(message)}
      aria-modal={Boolean(message)}
      className={`modal fade ${Boolean(message) ? 'show' : ''}`}
      role='dialog'
      style={{
        display: Boolean(message) ? 'block' : 'none',
        backgroundColor: Boolean(message) ? 'rgba(0,0,0,.4)' : 'transparent',
      }}
      tabIndex='-1'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div
            className='modal-body pt-0 mb-4 justify-content-center'
            style={{ marginTop: '1rem' }}>
            <img
              src='/img/Login/authentication.png'
              className='rounded mx-auto d-block'
              alt='2-Factor Authentication Icon'
            />
            <h1 className='mt-4 mb-0 text-center fw-bold'>
              Authenticate Your Account
            </h1>
            <form className='row' autoComplete='off'>
              <div className='col mb-4'>
                <p
                  className='text-center p-3 mb-0 fw-light'
                  style={{ fontSize: '.9rem' }}>
                  Protecting your account is our top priority. Please confirm
                  your identity by entering the verification code sent to your
                  Council email.
                </p>
                <div
                  className={`input-group mx-auto twoFA-fields`}
                  style={{ width: '70%' }}>
                  {generateAuthInputs()}
                </div>
              </div>
              {message.two_factor_auth_status === 'failure' && (
                <p
                  className='text-danger text-center mb-0'
                  role='alert'
                  style={{ fontSize: '.8rem' }}>
                  {message.two_factor_auth_message}
                </p>
              )}
            </form>
          </div>

          <div className='modal-footer border-top-0 p-4'>
            <div className='container-fluid m-0 p-0'>
              <div className='row align-items-center'>
                <div className='col-8'>
                  <p className='mb-0 lh-sm' style={{ fontSize: '0.8rem' }}>
                    Please wait as email servers may sometimes be delayed by 15
                    min. Thank you for your patience.
                  </p>
                </div>
                <div className='col-4 text-end'>
                  <button
                    type='button'
                    onClick={verifyClick}
                    className='submit-button'
                    id='auth-verify-button'>
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthModal;
