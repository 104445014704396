import React, { useEffect } from 'react';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { getBillHistory } from '../../actions/lsRequestAction';
import { formatDateTime } from '../../utils/helper';
import File from '../library/File';
import { connect } from 'react-redux';

const BillHistory = props => {
  // useEffect hook for component did mount
  useEffect(() => {
    let ls_number = props.updateTargetLs.ls_number;
    props.getBillHistory(ls_number);
  }, []);

  const { billHistoryList } = props;
  const billDisplay =
    billHistoryList &&
    billHistoryList.map((bill, idx) => (
      <tr key={idx}>
        <td>{formatDateTime(bill.timestamp)}</td>
        <td>
          <File file={bill} getFileAndDownload={props.getFileAndDownload} />
        </td>
      </tr>
    ));

  return (
    <>
      <div>
        <img
          src='/img/back.png'
          className='back-icon'
          alt='Go back'
          onClick={props.onBack}
        />
        <img
          src='/img/close.png'
          className='close-icon'
          alt='Close bill history window'
          onClick={props.onClose}
        />
        <h2 className='popup-detail-title'>Bill History</h2>
      </div>
      <table className='list-table'>
        <thead>
          <tr className='table-headers'>
            <th>Timestamp</th>
            <th>Bill</th>
          </tr>
        </thead>
        <tbody>{billDisplay}</tbody>
      </table>
    </>
  );
};

const mapStateToProps = state => {
  return {
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    billHistoryList: state.lsRequestsReducer.billHistoryList,
  };
};

const mapDispatchToProps = {
  getBillHistory,
  getFileAndDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillHistory);
