import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/Wiki.module.scss';
import WikiDisclaimerPopup from './WikiDisclaimerPopup';
import WikiSearch from './WikiSearch';
import { fullDisclaimer } from '../../services/constants';
import { Link } from 'react-router-dom';
import * as actions from '../../actions/wikiPageAction';
import FeaturedCarousel from './FeaturedCarousel';
import {
  ArrowCircleFillLeftIcon,
  ArrowCircleFillRightIcon,
  PlusIcon,
} from '../../services/SvgLibrary';

const alphabetArray = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const WikiHome = props => {
  const {
    all_subjects,
    loadAllSubjects,
    loadRecentPages,
    recent_pages,
    userProfile,
  } = props;
  //Load all subjects on mount
  useEffect(() => {
    loadAllSubjects(false);
    loadRecentPages();
  }, [loadAllSubjects, loadRecentPages]);

  const [alphaState, setAlphaState] = useState({
    selectedLetter: '',
    displayedSubjects: [],
  });
  const alphabetButtons = alphabetArray.map((letter, idx) => {
    return (
      <button
        key={idx}
        className={`${
          letter.toUpperCase()[0] === alphaState.selectedLetter
            ? styles['selected-button']
            : ''
        } ${styles['alphabet-buttons']}`}
        onClick={() => {
          let filteredLetters = all_subjects.results.filter(
            subject => subject.title.toUpperCase()[0] === letter.toUpperCase()
          );
          setAlphaState({
            selectedLetter: letter.toUpperCase(),
            displayedSubjects: filteredLetters,
          });
        }}>
        {letter}
      </button>
    );
  });

  const changeLetter = offset => {
    let nextLetter;
    let changedButton;
    let currentButton = document.getElementsByClassName(
      styles['selected-button']
    )[0];
    if (alphaState.selectedLetter === 'A' && offset < 0) {
      nextLetter = 'Z';
      changedButton = document.getElementsByClassName(
        styles['alphabet-buttons']
      )[25];
    } else if (alphaState.selectedLetter === 'Z' && offset > 0) {
      nextLetter = 'A';
      changedButton = document.getElementsByClassName(
        styles['alphabet-buttons']
      )[0];
    } else {
      nextLetter =
        offset > 0
          ? alphabetArray[alphabetArray.indexOf(alphaState.selectedLetter) + 1]
          : alphabetArray[alphabetArray.indexOf(alphaState.selectedLetter) - 1];
      changedButton =
        offset > 0
          ? currentButton.nextElementSibling
          : currentButton.previousElementSibling;
    }
    let filteredLetters = all_subjects.results.filter(
      subject => subject.title.toUpperCase()[0] === nextLetter.toUpperCase()
    );
    setAlphaState({
      selectedLetter: nextLetter,
      displayedSubjects: filteredLetters,
    });
    currentButton.className.replace(styles['selected-button'], '');
    changedButton.className += ` ${styles['selected-button']}`;
  };

  const recentArticles = recent_pages.map((page, id) => {
    return (
      <li key={`page-${id}`}>
        <div className={`${styles['card-body-gray-topics']} card h-100`}>
          <Link to={`/CLEX/subject/${page.subject[0]}/page/${page.id}`}>
            {page.title}
          </Link>
        </div>
      </li>
    );
  });

  return (
    <>
      <div className='container-fluid mb-4'>
        <div className='row align-items-center justify-content-center mb-4'>
          <div className='col-lg-8 col-xs-12'>
            <img
              className='mx-auto my-4 d-block'
              src='/img/Logo/cLEX logo.png'
              alt='Council Legislative Exchange logo'
              width='200'
            />
            <WikiSearch placement='top' className='mb-4' />
          </div>
        </div>
        <div className='row mb-4 justify-content-center align-items-center'>
          <div className='col-12'>
            {alphaState.selectedLetter && (
              <div
                className={`${styles['arrow-container']} ${styles['left']}`}
                onClick={() => changeLetter(-1)}>
                <ArrowCircleFillLeftIcon />
              </div>
            )}
            <div className={styles['alphabet-buttons-container']}>
              {alphabetButtons}
            </div>
            {alphaState.selectedLetter && (
              <div
                className={`${styles['arrow-container']} ${styles['right']}`}
                onClick={() => changeLetter(1)}>
                <ArrowCircleFillRightIcon />
              </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col-12 px-0'>
            <div className={styles['subject-results-wrapper']}>
              {alphaState.displayedSubjects !== 0 && (
                <ul>
                  {alphaState.displayedSubjects.map((subject, idx) => (
                    <li key={idx}>
                      <Link
                        to={`/CLEX/subject/${subject.id}`}
                        style={{ color: '#FFF', textDecoration: 'none' }}>
                        {subject.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='container overflow-hidden my-4'>
        <div className='row align-items-center gx-5'>
          <div className='col-xs-12 col-md-6 order-md-2'>
            <FeaturedCarousel />
          </div>
          <div className='col-xs-12 col-md-6 order-md-1'>
            <h1 className={styles['recent-article-heading']}>
              Most Recent Articles
            </h1>
            <ul className={styles['pages-list']}>{recentArticles}</ul>
          </div>
        </div>
      </div>
      <div className='dropdown dropup'>
        <button
          className={`dropdown-toggle ${styles['clex-create-new-button']}`}
          type='button'
          id='dropdownMenuButton1'
          data-bs-toggle='dropdown'
          aria-expanded='false'>
          <PlusIcon />
        </button>
        <ul
          className={`dropdown-menu ${styles['create-dropdown-menu']}`}
          aria-labelledby='dropdownMenuButton1'>
          {userProfile.permissions.wiki.add_subject && (
            <li>
              <Link
                className={`dropdown-item ${styles['create-dropdown-menu-item']}`}
                to='/CLEX/subject/create'>
                Create New Topic
              </Link>
            </li>
          )}
          {userProfile.permissions.wiki.add_page && (
            <li>
              <Link
                className={`dropdown-item ${styles['create-dropdown-menu-item']}`}
                to='/CLEX/page/create'>
                Add New Article
              </Link>
            </li>
          )}
        </ul>
      </div>
      <footer className={styles['footer-clex-home']}>
        {fullDisclaimer}
        <div className={styles['footer-logo']}>
          <img
            src='/img/Logo/clex logo white.png'
            alt='Council Legislative Exchange logo'
            width='150px'
          />
        </div>
      </footer>
      <WikiDisclaimerPopup />
    </>
  );
};

const mapStateToProps = state => {
  return {
    all_subjects: state.subjectFormReducer.all_subjects,
    recent_pages: state.wikiReducer.recentPages,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(WikiHome);
