export const procRequestActions = {
  CHANGE_PROC_REQUEST_POPUP_WINDOW: 'CHANGE_PROC_REQUEST_POPUP_WINDOW',
  CHANGE_DISPLAY: 'CHANGE_DISPLAY',
  GET_PROC_REQUEST_CURRENT_MONTH_AND_YEAR:
    'GET_PROC_REQUEST_CURRENT_MONTH_AND_YEAR',
  SAVE_PROC_REQUESTS_CURRENT_MONTH_AND_YEAR:
    'SAVE_PROC_REQUESTS_CURRENT_MONTH_AND_YEAR',
  SELECT_TARGET_DAY_FOR_PROC_REQUESTS: 'SELECT_TARGET_DAY_FOR_PROC_REQUESTS',
  IS_LOADING: 'IS_LOADING',
  IS_CALENDAR_LOADING: 'IS_CALENDAR_LOADING',
  SAVE_PROGRESS: 'SAVE_PROGRESS',
  CREATE_NEW_PROC_REQUEST: 'CREATE_NEW_PROC_REQUEST',
  CREATE_NEW_PROC_REQUEST_STATUS: 'CREATE_NEW_PROC_REQUEST_STATUS',
  UPDATE_PROC_REQUEST: 'UPDATE_PROC_REQUEST',
  UPDATE_PROC_REQUEST_STATUS: 'UPDATE_PROC_REQUEST_STATUS',
  SAVE_TARGET_PROC_REQUESTS: 'SAVE_TARGET_PROC_REQUESTS',
  GET_ALL_PROC_REQUESTS: 'GET_ALL_PROC_REQUESTS',
  SAVE_ALL_PROC_REQUESTS: 'SAVE_ALL_PROC_REQUESTS',
  SELECT_PROC_REQUEST: 'SELECT_PROC_REQUEST',
  FILTER_PROC_REQUESTS: 'FILTER_PROC_REQUESTS',
  SET_SEARCH_STRING: 'SET_SEARCH_STRING',
  DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
  DELETE_UPLOAD: 'DELETE_UPLOAD',
  DELETE_ATTACHMENT_FROM_DB: 'DELETE_ATTACHMENT_FROM_DB',
  APPLY_SORT: 'APPLY_SORT',
  SAVE_NEXT_API: 'SAVE_NEXT_API',
};

export const selectTargetDayForProcRequests = procRequests => {
  return {
    type: procRequestActions.SELECT_TARGET_DAY_FOR_PROC_REQUESTS,
    procRequests,
  };
};

export const saveTargetProcRequests = procRequests => {
  return { type: procRequestActions.SAVE_TARGET_PROC_REQUESTS, procRequests };
};

export const saveProgress = procRequest => {
  return { type: procRequestActions.SAVE_PROGRESS, procRequest };
};

export const getProcRequestCurrentMonthAndYear = (
  month,
  year,
  sortBy = true
) => {
  return {
    type: procRequestActions.GET_PROC_REQUEST_CURRENT_MONTH_AND_YEAR,
    month,
    year,
    sortBy,
  };
};

export const getProcRequests = (nextAPI, params) => {
  return { type: procRequestActions.GET_ALL_PROC_REQUESTS, nextAPI, params };
};

// Action for changing the detail pop up window status to open/close
export const changeProcRequestPopupWindow = status => {
  return { type: procRequestActions.CHANGE_PROC_REQUEST_POPUP_WINDOW, status };
};
export const changeProcRequestDisplay = displayString => {
  return { type: procRequestActions.CHANGE_DISPLAY, displayString };
};

// Action for getting the proc requests for current month and year
export const saveProcRequestCurrentMonthAndYear = procRequests => {
  return {
    type: procRequestActions.SAVE_PROC_REQUESTS_CURRENT_MONTH_AND_YEAR,
    procRequests,
  };
};

export const setIsLoading = isLoading => {
  return { type: procRequestActions.IS_LOADING, isLoading };
};

export const setIsCalendarLoading = isLoading => {
  return { type: procRequestActions.IS_CALENDAR_LOADING, isLoading };
};

export const createNewProcRequest = procRequest => {
  return { type: procRequestActions.CREATE_NEW_PROC_REQUEST, procRequest };
};

// Action for checking is the creating has succeed or not
export const createNewProcRequestStatus = status => {
  return { type: procRequestActions.CREATE_NEW_PROC_REQUEST_STATUS, status };
};

export const updateProcRequest = procRequest => {
  return { type: procRequestActions.UPDATE_PROC_REQUEST, procRequest };
};

export const updateProcRequestStatus = status => {
  return { type: procRequestActions.UPDATE_PROC_REQUEST_STATUS, status };
};

// Action for saving all the proc requests
export const saveAllProcRequests = (procRequests, nextAPI) => {
  return {
    type: procRequestActions.SAVE_ALL_PROC_REQUESTS,
    procRequests,
    nextAPI,
  };
};

export const selectProcRequest = procRequest => {
  return { type: procRequestActions.SELECT_PROC_REQUEST, procRequest };
};
export const filterProcRequest = (procRequests, nextAPI) => {
  return {
    type: procRequestActions.FILTER_PROC_REQUESTS,
    procRequests,
    nextAPI,
  };
};

export const setSearchString = searchString => {
  return { type: procRequestActions.SELECT_PROC_REQUEST, searchString };
};

//Action to delete individual report file attached in the Report Tracker
export const deleteAttachmentAction = id => {
  return { type: procRequestActions.DELETE_ATTACHMENT, id };
};

//Action to delete an individual reporting bill file in the Report Tracker
export const deleteUploadAction = id => {
  return { type: procRequestActions.DELETE_UPLOAD, id };
};

export const deleteAttachmentFromDB = (id, field) => {
  return { type: procRequestActions.DELETE_ATTACHMENT_FROM_DB, id, field };
};

export const applySort = (sortBy, sortDirection) => {
  return { type: procRequestActions.APPLY_SORT, sortBy, sortDirection };
};

export const saveNextAPI = nextApi => {
  return { type: procRequestActions.SAVE_NEXT_API, nextApi };
};
