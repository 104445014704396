import React from 'react';
import PageForm from './PageForm';
import SubjectForm from './SubjectForm';
import TagForm from './TagForm';

const WikiFormContainer = () => {
  return (
    <div>
      {window.location.pathname === '/CLEX/subject/create' && <SubjectForm />}
      {window.location.pathname === '/CLEX/subject/edit' && <SubjectForm />}
      {window.location.pathname === '/CLEX/subject/delete' && <SubjectForm />}
      {window.location.pathname === '/CLEX/page/create' && <PageForm />}
      {window.location.pathname === '/CLEX/page/edit' && <PageForm />}
      {window.location.pathname === '/CLEX/page/delete' && <PageForm />}
      {window.location.pathname === '/CLEX/tag/create' && <TagForm />}
      {window.location.pathname === '/CLEX/tag/edit' && <TagForm />}
      {window.location.pathname === '/CLEX/tag/delete' && <TagForm />}
    </div>
  );
};

export default WikiFormContainer;
