import React, { useState, useEffect } from 'react';
import {
    updateUser,
    updateUserBio,
  } from '../../../../actions/userAction';
import { connect } from 'react-redux';
import { EditPageIcon } from '../../../../services/SvgLibrary';
import styles from '../../../../styles/user/UserProfile.module.scss';


const UserBioWidget = props => {
  const {
    updateUser,
    updateUserBio,
    userProfile,
  } = props;

  const [editingBio, setEditingBio] = useState(false);
  const [bioForm, setBioForm] = useState('');
  const [oldBio, setOldBio] = useState('');
    
  useEffect(() => {
    setBioForm(userProfile.bio);
    setOldBio(userProfile.bio);
  }, []);

  useEffect(() => {
    if (editingBio === true) {
        document.getElementById('bio-edit').focus();
    }
  }, [editingBio]);

  const submitBio = e => {
    if (bioForm !== oldBio) {
      updateUser(userProfile.id, { bio: bioForm, is_updating_bio: true }, true);
    }
    setEditingBio(false);
  };

  const handleBioChange = e => {
    const newBio = e.target.value.slice(0, 500);
    setBioForm(newBio);
    updateUserBio(newBio);
  };

  return (
    <div className='card border-0 rounded-3'>
      <div className='card-body'>
        <h2>Bio</h2>
        {!userProfile.permissions.auth.change_user && (
          <>
            {!editingBio && (
              <>
                <button
                  type='button'
                  className={styles['edit-profile-button']}
                  style={{
                    position: 'absolute',
                    top: '8px',
                    right: '16px',
                  }}
                  onClick={() => setEditingBio(true)}>
                  <EditPageIcon />
                </button>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                  {bioForm.length ? bioForm : 'Add a short bio'}
                </p>
              </>
            )}
            {editingBio && (
              <>
                <textarea
                  id='bio-edit'
                  style={{
                    width: '100%',
                    zIndex: '2',
                    resize: 'none',
                    whiteSpace: 'pre-wrap',
                  }}
                  rows='5'
                  placeholder={
                    bioForm === ''
                      ? `"Hi, my name is Bob and I like long walks on the beach. I am counsel to the committee on culinary arts and eating food is my passion. I'm cranky when I'm hungry, so keep that in mind when timing our meetings."`
                      : ''
                  }
                  value={bioForm}
                  onChange={handleBioChange}
                  onBlur={e => submitBio(e)}></textarea>
                <span className={styles['char-remaining']}>{`${
                  500 - bioForm.length
                } characters remaining`}</span>
              </>
            )}
          </>
        )}
        {userProfile.permissions.auth.change_user && (
          <p style={{ whiteSpace: 'pre-wrap' }}>
            {bioForm.length ? bioForm : 'Edit your profile to add a bio'}
          </p>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  updateUser,
  updateUserBio,
};
  
const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBioWidget);