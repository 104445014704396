import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/userAction';
import { councilMembers } from '../../utils/helper';
import UserForm from './UserForm';

const initFormData = {
  inputUsername: '',
  inputFirstName: '',
  inputMiddleName: '',
  inputLastName: '',
  inputSuffix: '',
  inputEmail: '',
  inputAgency: '',
  inputPhone: '',
  inputAlternativePhone: '',
  inputRole: '',
  inputCommittee: '',
  inputDivision: '',
  inputBio: '',
  underCouncilMember: '',
};

const districtRoles = [
  'District Chief of Staff',
  'District Legislative Director',
  'District Legislative Fellow',
  'District Legislative/Policy Associate',
  'District Proc/Citation Liaison',
];

const NewUserCreation = props => {
  const [formData, setFormData] = useState(initFormData);
  const [warningMessage, setWarningMessage] = useState('');
  const { committees, creatingNewUser, division, getAllAgencies, usersList } =
    props;
  const getAllAgenciesOnLoad = useCallback(
    () => getAllAgencies(),
    [getAllAgencies]
  );

  /** component mount */
  useEffect(() => {
    getAllAgenciesOnLoad();
  }, [getAllAgenciesOnLoad]);

  // For detecting the input box
  function onDetectInputChange(e) {
    switch (e.target.name) {
      case 'inputRole':
        if (districtRoles.includes(e.target.value)) {
          let unassignedDivision = division.filter(
            div => div.division_name === 'Unassigned'
          );
          // If the role is changed to a CM related role, division should be auto-set to 'Unassigned'
          setFormData({ ...formData, [e.target.name]: e.target.value, inputDivision: unassignedDivision[0].id.toString()});
        } else {
          // Otherwise, the Associated CM field should be cleared
          setFormData({ ...formData, [e.target.name]: e.target.value, underCouncilMember: null });
        };
        break;
      default:
        setFormData({ ...formData, [e.target.name]: e.target.value});
    }
  }

  function onUserClick(e) {
    setFormData({ ...formData, underCouncilMember: e.target.innerText });
  }

  // On submit to create a new user
  function onSubmit(e) {
    e.preventDefault();
    const {
      inputFirstName,
      inputLastName,
      inputEmail,
      inputPhone,
      inputAlternativePhone,
      inputRole,
      inputCommittee,
      inputDivision,
      inputBio,
      inputMiddleName,
      inputSuffix,
      inputAgency,
      underCouncilMember,
    } = formData;

    let cleanedPhone = inputPhone.replace(/[^0-9]/g, '');
    let cleanedAlternativePhone = inputAlternativePhone.replace(/[^0-9]/g, '');
    if (inputEmail === '') {
      setWarningMessage('Email cannot be empty!');
    } else if (inputFirstName === '') {
      setWarningMessage('First name cannot be empty!');
    } else if (inputLastName === '') {
      setWarningMessage('Last name cannot be empty!');
    } else if (
      inputRole !== 'Agency' &&
      !inputEmail.match(
        /^(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._-]+(?<![_.-])@(?:council|advocate|pubadvocate)\.nyc\.gov$/
      )
    ) {
      setWarningMessage('Must be a Council or Public Advocate email!');
    } else if (
      inputRole === 'Agency' &&
      !inputEmail.match(
        /^(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._-]+(?<![_.-])@(?![_.-])(?!.*[_.-]{2})[a-zA-Z0-9._-]+(?<![_.-])\.nyc\.gov$/
      )
    ) {
      setWarningMessage('Must be a government email!');
    } else if (inputPhone === '' && inputRole !== 'Agency') {
      setWarningMessage('Phone number cannot be empty!');
    } else if (inputRole === '') {
      setWarningMessage('Role cannot be empty!');
    } else if (
      inputCommittee === '' &&
      inputRole !== 'Agency' &&
      !districtRoles.includes(inputRole)
    ) {
      setWarningMessage('Committee cannot be empty!');
    } else if (
      inputDivision === '' &&
      inputRole !== 'Agency' &&
      !districtRoles.includes(inputRole)
    ) {
      setWarningMessage('Division cannot be empty!');
    } else if (
      inputPhone.replace(/\D/g, '').length !== 10 &&
      inputRole !== 'Agency'
    ) {
      setWarningMessage('Phone number must be 10 digits!');
    } else if (
      inputAlternativePhone !== '' &&
      inputAlternativePhone.replace(/\D/g, '').length !== 10 &&
      inputRole !== 'Agency'
    ) {
      setWarningMessage('Alternative phone number must be 10 digits!');
    } else if (districtRoles.includes(inputRole) && underCouncilMember === '') {
      setWarningMessage('This role must be under a Council Member!');
    } else if (
      districtRoles.includes(inputRole) &&
      !councilMembers(usersList)
        .map(user => user.full_name)
        .includes(underCouncilMember)
    ) {
      setWarningMessage('Not a valid council Member!');
    } else if (inputRole === 'Agency' && inputAgency === '') {
      setWarningMessage('Agency not input');
    } else {
      setWarningMessage('');

      const user = {
        first_name: inputFirstName,
        last_name: inputLastName,
        middle_name: inputMiddleName,
        suffix: inputSuffix,
        committees: inputCommittee,
        division: inputDivision,
        bio: inputBio,
        email: inputEmail,
        phone: cleanedPhone,
        alternative_phone: cleanedAlternativePhone,
        role: inputRole,
      };
      
      if (inputRole === 'Agency') {
        let unassignedCommittee = committees.filter(
          com => com.committee_name === 'Unassigned'
        );
        let unassignedDivision = division.filter(
          div => div.division_name === 'Unassigned'
        );
        user.committees = unassignedCommittee[0].id.toString();
        user.division = unassignedDivision[0].id.toString();
        user.agency = inputAgency;
      }
      if (underCouncilMember) {
        user.under_council_member = usersList.find(
          u => u.full_name === underCouncilMember
        ).id;
      } else {
        user.under_council_member = null;
      }
      creatingNewUser(user);
    }
  }

  function resetFields() {
    setFormData(initFormData);
  }
  // On detect multiple options for committee
  // The committees are seprated by comma
  function onDetectCommittee(e) {
    const options = e.target.options;
    let ids = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        ids.push(options[i].value);
      }
    }
    setFormData({ ...formData, inputCommittee: ids.join(',') });
  }

  function onDetectDivision(e) {
    const options = e.target.options;
    let ids = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        ids.push(options[i].value);
      }
    }
    setFormData({ ...formData, inputDivision: ids.join(',') });
  }

  return (
    <UserForm
      formTitle={'Create a new user'}
      inputFirstName={formData.inputFirstName}
      inputMiddleName={formData.inputMiddleName}
      inputLastName={formData.inputLastName}
      inputSuffix={formData.inputSuffix}
      inputEmail={formData.inputEmail}
      warningMessage={warningMessage}
      inputPhone={formData.inputPhone}
      inputAlternativePhone={formData.inputAlternativePhone}
      inputRole={formData.inputRole}
      inputCommittee={formData.inputCommittee}
      inputDivision={formData.inputDivision}
      inputBio={formData.inputBio}
      inputAgency={formData.inputAgency}
      


      onDetectInputChange={onDetectInputChange}
      onSubmit={onSubmit}
      fieldReset={resetFields}
      onDetectCommittee={onDetectCommittee}
      onDetectDivision={onDetectDivision}
      search={formData.underCouncilMember}
      handleClick={onUserClick}
      onUserChange={onDetectInputChange}
    />
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.userReducer.userProfile.role,
    newUserID: state.userReducer.newUserID,
    usersList: state.userReducer.userList,
    committees: state.userReducer.committees,
    division: state.userReducer.division,
  };
};

export default connect(mapStateToProps, actions)(NewUserCreation);
