import { myTasksActionType } from '../actions/myTasksAction';

const initialState = {
  allMyLs: null,
  myActiveLs: null,
  isLoading: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case myTasksActionType.SAVE_MY_LS:
      return { ...state, allMyLs: action.ls };

    case myTasksActionType.IS_LOADING_LS:
      return { ...state, isLoading: action.status };

    case myTasksActionType.SAVE_MY_ACTIVE_LS:
      return { ...state, myActiveLs: action.ls };

    default:
      return state;
  }
}
