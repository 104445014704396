export const citationRequestActions = {
  CHANGE_CITATION_REQUEST_POPUP_WINDOW: 'CHANGE_CITATION_REQUEST_POPUP_WINDOW',
  CHANGE_DISPLAY: 'CHANGE_DISPLAY',
  GET_CITATION_REQUEST_CURRENT_MONTH_AND_YEAR:
    'GET_CITATION_REQUEST_CURRENT_MONTH_AND_YEAR',
  SAVE_CITATION_REQUESTS_CURRENT_MONTH_AND_YEAR:
    'SAVE_CITATION_REQUESTS_CURRENT_MONTH_AND_YEAR',
  SELECT_TARGET_DAY_FOR_CITATION_REQUESTS:
    'SELECT_TARGET_DAY_FOR_CITATION_REQUESTS',
  IS_LOADING: 'IS_LOADING',
  IS_CALENDAR_LOADING: 'IS_CALENDAR_LOADING',
  SAVE_PROGRESS: 'SAVE_PROGRESS',
  CREATE_NEW_CITATION_REQUEST: 'CREATE_NEW_CITATION_REQUEST',
  CREATE_NEW_CITATION_REQUEST_STATUS: 'CREATE_NEW_CITATION_REQUEST_STATUS',
  UPDATE_CITATION_REQUEST: 'UPDATE_CITATION_REQUEST',
  UPDATE_CITATION_REQUEST_STATUS: 'UPDATE_CITATION_REQUEST_STATUS',
  SAVE_TARGET_CITATION_REQUESTS: 'SAVE_TARGET_CITATION_REQUESTS',
  GET_ALL_CITATION_REQUESTS: 'GET_ALL_CITATION_REQUESTS',
  SAVE_ALL_CITATION_REQUESTS: 'SAVE_ALL_CITATION_REQUESTS',
  SELECT_CITATION_REQUEST: 'SELECT_CITATION_REQUEST',
  FILTER_CITATION_REQUESTS: 'FILTER_CITATION_REQUESTS',
  SET_SEARCH_STRING: 'SET_SEARCH_STRING',
  DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
  DELETE_ATTACHMENT_FROM_DB: 'DELETE_CITAION_ATTACHMENT_FROM_DB',
  APPLY_SORT: 'APPLY_SORT',
  SAVE_NEXT_API: 'SAVE_NEXT_API',
};

export const selectTargetDayForCitationRequests = citationRequests => {
  return {
    type: citationRequestActions.SELECT_TARGET_DAY_FOR_CITATION_REQUESTS,
    citationRequests,
  };
};

export const saveTargetCitationRequests = citationRequests => {
  return {
    type: citationRequestActions.SAVE_TARGET_CITATION_REQUESTS,
    citationRequests,
  };
};

export const saveProgress = citationRequest => {
  return { type: citationRequestActions.SAVE_PROGRESS, citationRequest };
};

export const getCitationRequestCurrentMonthAndYear = (month, year) => {
  return {
    type: citationRequestActions.GET_CITATION_REQUEST_CURRENT_MONTH_AND_YEAR,
    month,
    year,
  };
};

export const getCitationRequests = (nextAPI, params) => {
  return {
    type: citationRequestActions.GET_ALL_CITATION_REQUESTS,
    nextAPI,
    params,
  };
};

// Action for changing the detail pop up window status to open/close
export const changeCitationRequestPopupWindow = status => {
  return {
    type: citationRequestActions.CHANGE_CITATION_REQUEST_POPUP_WINDOW,
    status,
  };
};
export const changeCitationRequestDisplay = displayString => {
  return { type: citationRequestActions.CHANGE_DISPLAY, displayString };
};

// Action for getting the citation requests for current month and year
export const saveCitationRequestCurrentMonthAndYear = citationRequests => {
  return {
    type: citationRequestActions.SAVE_CITATION_REQUESTS_CURRENT_MONTH_AND_YEAR,
    citationRequests,
  };
};

export const setIsLoading = isLoading => {
  return { type: citationRequestActions.IS_LOADING, isLoading };
};

export const setIsCalendarLoading = isLoading => {
  return { type: citationRequestActions.IS_CALENDAR_LOADING, isLoading };
};

export const createNewCitationRequest = citationRequest => {
  return {
    type: citationRequestActions.CREATE_NEW_CITATION_REQUEST,
    citationRequest,
  };
};

// Action for checking is the creating has succeed or not
export const createNewCitationRequestStatus = status => {
  return {
    type: citationRequestActions.CREATE_NEW_CITATION_REQUEST_STATUS,
    status,
  };
};

export const updateCitationRequest = citationRequest => {
  return {
    type: citationRequestActions.UPDATE_CITATION_REQUEST,
    citationRequest,
  };
};

export const updateCitationRequestStatus = status => {
  return {
    type: citationRequestActions.UPDATE_CITATION_REQUEST_STATUS,
    status,
  };
};

// Action for saving all the citation requests
export const saveAllCitationRequests = (citationRequests, nextAPI) => {
  return {
    type: citationRequestActions.SAVE_ALL_CITATION_REQUESTS,
    citationRequests,
    nextAPI,
  };
};

export const selectCitationRequest = citationRequest => {
  return {
    type: citationRequestActions.SELECT_CITATION_REQUEST,
    citationRequest,
  };
};
export const filterCitationRequest = (citationRequests, nextAPI) => {
  return {
    type: citationRequestActions.FILTER_CITATION_REQUESTS,
    citationRequests,
    nextAPI,
  };
};

export const setSearchString = searchString => {
  return { type: citationRequestActions.SELECT_CITATION_REQUEST, searchString };
};

export const deleteAttachmentFromDB = (id, field) => {
  return { type: citationRequestActions.DELETE_ATTACHMENT_FROM_DB, id, field };
};

export const applySort = (sortBy, sortDirection) => {
  return { type: citationRequestActions.APPLY_SORT, sortBy, sortDirection };
};

export const saveNextAPI = nextApi => {
  return { type: citationRequestActions.SAVE_NEXT_API, nextApi };
};
