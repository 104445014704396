import { fileDownloadAction } from '../actions/fileDownloadAction';

const initialState = {
  downloadStatus: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fileDownloadAction.DOWNLOAD_FILE_STATUS:
      return { ...state, downloadStatus: action.status };

    default:
      return state;
  }
}
