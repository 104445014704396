import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from '../../styles/user/Login.module.scss';
import * as actions from '../../actions/userAction';

const PasswordReset = props => {
  const [inputUsername, setInputUsername] = useState('');
  const [validWarningMessage, setValidWarningMessage] = useState('');
  const [captcha, setCaptcha] = useState(null);
  const [returnToLogin, setReturnToLogin] = useState(false);

  // Explore changes of the input username
  function onGetInputUsername(e) {
    setInputUsername(e.target.value);
  }

  // Send out login api
  // Detect whether username and password is empty or not
  function onSubmit(e) {
    e.preventDefault();
    let message = '';
    if (inputUsername === '') {
      message = 'Username cannot be empty!';
    } else if (!captcha) {
      message = 'Must check the CAPTCHA!';
    } else {
      props.emailPasswordReset(inputUsername);
    }
    setValidWarningMessage(message);
    setReturnToLogin(true);
  }

  function onBack(e) {
    props.checkingLoginStatus('fail');
    props.gettingSuccessMessage('');
  }

  // Handle event when the end user press enter
  function onHandlePressEnter(e) {
    if (e.key === 'Enter') {
      onSubmit(e);
    }
  }

  return (
    <form onKeyUp={onHandlePressEnter} className={styles['input-wrapper']}>
      <h1>
        {props.userLoginStatus === 'forgotPassword'
          ? 'Reset your password'
          : 'Activate your account'}
      </h1>
      <p>
        {`Enter your username or email address and an email with instructions on how to 
				${
          props.userLoginStatus === 'forgotPassword'
            ? 'reset your password'
            : 'activate your account'
        }
				will be sent. If you do not receive an email, contact an administrator.`}
      </p>

      <div className='form-floating mb-3'>
        <input
          type='email'
          className='form-control'
          id='outlined-full-width'
          placeholder='Enter Username'
          maxLength='256'
          value={inputUsername}
          onChange={onGetInputUsername}
        />
        <label htmlFor='outlined-full-width'>Enter Username or Email</label>
      </div>

      {validWarningMessage && (
        <div className='alert alert-danger' role='alert'>
          {validWarningMessage}
        </div>
      )}
      {props.userLoginStatus === 'fail' && props.errorMessage && (
        <div className='alert alert-danger' role='alert'>
          {props.errorMessage}
        </div>
      )}
      {props.successMessage && (
        <div className='alert alert-success' role='alert'>
          {props.successMessage}
        </div>
      )}
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        onChange={value => setCaptcha(value)}
      />
      {returnToLogin ? (
        <button
          className={`submit-button ${styles['login-button']}`}
          onClick={onBack}>
          Login
        </button>
      ) : (
        <div className='d-flex gap-4'>
          <button
            type='button'
            className={`btn btn-link ${styles['override-cancel-button']}`}
            onClick={onBack}>
            Back
          </button>
          <button
            className={`submit-button ${styles['login-button']}`}
            onClick={onSubmit}
            disabled={props.emailLoad}>
            {props.emailLoad && (
              <span
                className='spinner-border'
                style={{
                  'width': '1.2rem',
                  'height': '1.2rem',
                  'marginRight': '0.5rem',
                }}
                role='status'
              />
            )}
            {props.userLoginStatus === 'forgotPassword' ? 'Reset' : 'Activate'}
          </button>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = state => {
  return {
    userLoginStatus: state.userReducer.userLoginStatus,
    errorMessage: state.userReducer.errorMessage,
    successMessage: state.userReducer.successMessage,
    emailLoad: state.userReducer.emailLoad,
  };
};

export default connect(mapStateToProps, actions)(PasswordReset);
