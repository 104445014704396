import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'bootstrap';
import '@popperjs/core';
import * as actions from '../../actions/userAction';
import styles from '../../styles/user/UserProfile.module.scss';
import { connect } from 'react-redux';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {
  PencilFillIcon,
  ExpandImageArrowsIcon,
  UploadImageIcon,
  WebcamIcon,
  CameraCaptureIcon,
  ChevronCompactLeftIcon,
} from '../../services/SvgLibrary';

const EditPhoto = props => {
  const {
    userProfile: { profile_pic: profPic },
  } = props;
  const [formState, setFormState] = useState('selectImage');
  const [errors, setErrors] = useState([]);

  // image state
  const [selectedImage, setSelectedImage] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // crop logic state
  const [square, setSquare] = useState([]);
  const [crop, setCrop] = useState({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [loadedPhoto, setLoadedPhoto] = useState(true);

  // webcam capture state
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [isWebcamLoading, setIsWebcamLoading] = useState(false);

  // Scale photo and keep information for backend on upload
  const [xScale, setXScale] = useState(1);
  const [yScale, setYScale] = useState(1);
  const [imageDisplayHeight, setImageDisplayHeight] = useState(0);
  const [imageDisplayWidth, setImageDisplayWidth] = useState(0);

  useEffect(() => {
    if (selectedImage) {
      props.findFaceFromPhoto(selectedImage);
    }
  }, [selectedImage]);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll(
        '[data-bs-toggle="tooltip"]:not([data-bs-toggle="dropdown"])'
      )
    );
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  useEffect(() => {
    if (props.square.length && loadedPhoto === false) {
      const photoScale = xScale * yScale;
      let [x, y, width, height] = props.square.map(e =>
        Math.floor(e * photoScale)
      );

      let scale = width;
      while (width < scale * 1.7) {
        x--;
        y--;
        width += 2;
        height += 2;
        if (
          width + x >= imageDisplayWidth ||
          height + y >= imageDisplayHeight ||
          x <= 0 ||
          y <= 0
        ) {
          x++;
          y++;
          width -= 2;
          height -= 2;
          break;
        }
        setCrop({
          unit: 'px',
          x: Math.floor(x),
          y: Math.floor(y),
          width: Math.floor(width),
          height: Math.floor(height),
        });
      }
      setLoadedPhoto(true);
    } else {
      setLoadedPhoto(false);
    }
  }, [props.square]);

  const closeModal = () => {
    setFormState('newForm');
    setSelectedImage(null);
    clearImage();
  };

  const imgSize = () => {
    const profPic = document.getElementById('profile-pic');
    let realWidth = profPic.naturalWidth;
    let realHeight = profPic.naturalHeight;
    let newHeight = realHeight,
      newWidth = realWidth,
      scaleX,
      scaleY;

    if (realHeight < 400 || realWidth < 400) {
      setErrors([
        ...errors,
        'Image size is too small, must be at least 400px by 400px',
      ]);
      return;
    }

    if (realHeight > 450) {
      scaleY = 450 / realHeight;
      setYScale(scaleY);
      newWidth = newWidth * scaleY;
      newHeight = newHeight * scaleY;
    } else {
      setYScale(1);
    }

    if (newWidth > 600) {
      scaleX = 600 / newWidth;
      setXScale(scaleX);
      newWidth = newWidth * scaleX;
      newHeight = newHeight * scaleX;
    } else {
      setXScale(1);
    }

    setImageDisplayWidth(newWidth);
    setImageDisplayHeight(newHeight);
  };

  const loadImage = e => {
    setImageLoaded(true);
    setCrop({
      unit: 'px',
      x: imageDisplayWidth / 2,
      y: imageDisplayHeight / 2,
      width: Math.min(imageDisplayWidth / 4, imageDisplayHeight / 4),
      height: Math.min(imageDisplayWidth / 4, imageDisplayHeight / 4),
    });
    setErrors([]);

    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    if (!allowedTypes.includes(file.type)) {
      setErrors([...errors, 'Only JPG, JPEG, and PNG files are allowed']);
      return;
    }

    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = function () {
      if (image.width < 400 || image.height < 400) {
        setErrors([
          ...errors,
          'Image size is too small, must be at least 400px by 400px',
        ]);
        return;
      }
      setSelectedImage(file);
    };
  };

  const clearImage = (startCapture = false) => {
    setSelectedImage(null);
    setCrop({ unit: '%', x: 25, y: 25, width: 50, height: 50 });
    setErrors([]);
    setSquare([]);
    props.saveFaceSquare([]);
    setLoadedPhoto(false);
    capturing && stopWebcam();
    startCapture && startWebcam();
    setImageLoaded(false);
  };

  const sleep = ms => new Promise(res => setTimeout(res, ms));

  const submitImage = async () => {
    const scaled = [crop.y, crop.x, crop.width, crop.height].map(e =>
      Math.floor(e / (xScale * yScale))
    );
    props.saveProfilePic(selectedImage, scaled, props.userProfile.username);
    await sleep(1000);
    clearImage();
    closeModal();
    window.location.reload();
  };

  const startWebcam = async () => {
    setCapturing(true);
    setIsWebcamLoading(true);
    setCrop({ unit: 'px', x: 160, y: 80, width: 320, height: 320 });
    const constraints = { video: true };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
    } catch (err) {
      console.error('Error: ' + err);
      setCapturing(false);
      setIsWebcamLoading(false);
    }
  };

  const stopWebcam = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setCapturing(false);
  };

  const captureImage = () => {
    const ctx = canvasRef.current.getContext('2d');
    ctx.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    canvasRef.current.toBlob(blob => {
      setSelectedImage(blob);
    }, 'image/jpeg');
    stopWebcam();
  };

  const onVideoLoaded = () => {
    setIsWebcamLoading(false);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      className='modal fade'
      id='edit-profile-picture'
      tabIndex='-1'
      aria-labelledby='editProfilePicture'
      aria-hidden='true'
      data-bs-backdrop='static'
      data-bs-keyboard='false'>
      <div
        className={`modal-dialog modal-dialog-centered ${styles['usedr-profile-image-modal']}`}>
        <div className='modal-content overflow-hidden'>
          <div className='modal-header border-bottom-0'>
            <h5 className='modal-title' id='edit-profile-picture-title'>
              {props.square.length === 0 && !selectedImage && !capturing && (
                <>Select an image</>
              )}
              {capturing && <>Say cheese!</>}
              {selectedImage && <>Edit image</>}
            </h5>
            <button
              type='button'
              className='btn-close  btn-close-white'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={closeModal}></button>
          </div>
          <div className='modal-body d-flex justify-content-center pt-0 pb-4'>
            {/* Display the user's current profile picture */}
            {!selectedImage && !capturing && (
              <div style={{ position: 'relative' }}>
                <img
                  src={
                    profPic
                      ? profPic.file
                      : '/img/profile-image-placeholder.png'
                  }
                  alt='Current profile'
                  className='rounded-1'
                  style={{
                    width: '600px',
                    height: '450px',
                    objectFit: 'cover',
                  }}
                />
                {/* <div className={`${styles["profile-image-action-btns"]} ${styles["bottom-right"]}`} data-bs-toggle="tooltip"  data-bs-placement="top" title="Edit image">
                    <PencilFillIcon width="16" height="16" alt="Edit profile image" />
                  </div> */}
                <div
                  className={styles['profile-image-action-btns']}
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  title='Fullscreen'
                  onClick={toggleFullscreen}>
                  <ExpandImageArrowsIcon
                    width='16'
                    height='16'
                    alt='Enlarge profile image'
                  />
                </div>
              </div>
            )}
            {isFullscreen && (
              <div
                className={styles['fullscreen-image-container']}
                onClick={toggleFullscreen}>
                <img
                  src={props.userProfile.profile_pic.file}
                  alt='Current profile'
                  className={`rounded-2 ${styles['fullscreen-image']}`}
                />
                <button
                  className={`btn-close btn-close-white ${styles['close-fullscreen-btn']}`}
                  type='button'
                  aria-label='Close'
                  onClick={toggleFullscreen}></button>
              </div>
            )}
            {selectedImage && formState !== 'cropImage' && (
              <div
                className='d-flex justify-content-center'
                id='edit-profile-pic-container'>
                {props.square.length ? (
                  <ReactCrop
                    crop={crop}
                    aspect={1}
                    circularCrop
                    minHeight={50}
                    minWidth={50}
                    onChange={c => setCrop(c)}>
                    <img
                      style={{ position: 'relative', height: 'fit-content' }}
                      id='profile-pic'
                      alt='New profile'
                      width={imageDisplayWidth}
                      height={imageDisplayHeight}
                      name='profile_pic'
                      src={URL.createObjectURL(selectedImage)}
                      onLoad={imgSize}
                    />
                  </ReactCrop>
                ) : (
                  <ReactCrop
                    crop={crop}
                    aspect={1}
                    circularCrop
                    minHeight={50}
                    minWidth={50}
                    onChange={c => setCrop(c)}>
                    <img
                      style={{ position: 'relative' }}
                      id='profile-pic'
                      alt='not found'
                      width={imageDisplayWidth}
                      height={imageDisplayHeight}
                      name='profile_pic'
                      src={URL.createObjectURL(selectedImage)}
                      onLoad={imgSize}
                    />
                  </ReactCrop>
                )}
              </div>
            )}
            {editImage && (
              <div
                className='d-flex justify-content-center'
                id='edit-profile-pic-container'>
                <ReactCrop
                  crop={crop}
                  aspect={1}
                  circularCrop
                  minHeight={50}
                  minWidth={50}
                  onChange={c => setCrop(c)}>
                  <img
                    style={{ position: 'relative', height: 'fit-content' }}
                    id='profile-pic'
                    alt='New profile'
                    width={imageDisplayWidth}
                    height={imageDisplayHeight}
                    name='profile_pic'
                    src={props.userProfile.profile_pic.file}
                    onLoad={imgSize}
                  />
                </ReactCrop>
              </div>
            )}
            {/* Handle errors */}
            {errors.length > 0 && (
              <div>
                {errors.map((ele, idx) => {
                  return <p>{`${ele}${idx}`}</p>;
                })}
              </div>
            )}
            {isWebcamLoading && (
              <div
                className={`rounded-3 ${styles['skeleton-loader']}`}
                style={{ width: '600px', height: '450px' }}></div>
            )}
            {capturing && (
              <video
                ref={videoRef}
                className='rounded-3'
                autoPlay
                width='600'
                height='450'
                onLoadedMetadata={onVideoLoaded}
                style={{
                  transform: 'scaleX(-1)',
                  display: isWebcamLoading ? 'none' : 'block',
                }}></video>
            )}
            <canvas
              ref={canvasRef}
              width='600'
              height='450'
              style={{ display: 'none' }}></canvas>
          </div>
          {/* Footer section with all the action buttons */}
          <div className='modal-footer border-0'>
            {selectedImage && formState !== 'cropImage' && (
              <>
                <button
                  className='cancel-button-text white'
                  onClick={() => {
                    imageLoaded ? clearImage(false) : clearImage(true);
                  }}>
                  <ChevronCompactLeftIcon /> Back
                </button>
                <button className='submit-button' onClick={submitImage}>
                  Apply
                </button>
              </>
            )}
            {/* Select Image Button */}
            {!selectedImage && !capturing && (
              <>
                <button
                  className='cancel-button-text white'
                  onClick={startWebcam}>
                  
                  <WebcamIcon style={{ marginRight: '5px' }} />
                  Use Webcam
                </button>
                <label htmlFor='myImage' className='submit-button'>
                  <UploadImageIcon style={{ marginRight: '5px' }} />
                  Upload new image
                  <input
                    type='file'
                    id='myImage'
                    name='myImage'
                    accept='image/png, image/jpeg'
                    style={{ display: 'none' }}
                    onChange={event => loadImage(event)}
                  />
                </label>
              </>
            )}
            {isWebcamLoading && (
              <div className='container d-flex justify-content-center m-0 p-0'>
                <div
                  className={`rounded-circle ${styles['skeleton-loader']}`}
                  style={{ width: '90px', height: '90px' }}></div>
              </div>
            )}
            {capturing && (
              <div className={styles['picture-capture-button-footer']}>
                <div className={styles['button-capture-container']}>
                  <button
                    className={`btn ${styles['cancel-webcam-capture']}`}
                    onClick={stopWebcam}
                    style={{
                      display: isWebcamLoading ? 'none' : 'inline-block',
                    }}>
                    <ChevronCompactLeftIcon />
                    Cancel
                  </button>
                  <button
                    className={styles['picture-capture-button']}
                    onClick={captureImage}
                    style={{
                      display: isWebcamLoading ? 'none' : 'inline-block',
                    }}>
                    <CameraCaptureIcon width='28' height='28' />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isAdmin: state.userReducer.userProfile.role,
    newUserCreationStatus: state.userReducer.newUserCreationStatus,
    committees: state.userReducer.committees,
    userProfile: state.userReducer.userProfile,
    usersList: state.userReducer.userList,
    allUsersList: state.userReducer.allUserList,
    square: state.userReducer.square,
  };
};

export default connect(mapStateToProps, actions)(EditPhoto);
