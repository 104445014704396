import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/CreateNewReportDdl.module.scss';
import ReportFields from '../../services/ReportFields';
import * as actions from '../../actions/reportTrackingAction';
import TagsDisplay from './TagsDisplay';
import File from '../library/File';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { format } from 'date-fns';
import { acceptedFileTypes, capitalizeWords } from '../../utils/helper';
import {
  ToolTipIcon,
  ShortLeftArrowIcon,
  ExclamationPointDiamondIcon,
  LargeCheckMarkIcon,
} from '../../services/SvgLibrary';

function UpdateDueReport(props) {
  const [state, setState] = useState({
    opReq: {
      agency_contact: [],
      assigned_contact: [],
      attachment: [],
      committees: [],
      completed: false,
      current_report_past_due: false,
      division: '',
      end_date: null,
      errors: '',
      frequency_quantity: 0,
      frequency_unit: '',
      general_notes: '',
      id: 0,
      last_received: null,
      local_law: [],
      legacy_local_law: [],
      ls_requests: [],
      milestone_attachment: [],
      next_due: '',
      notes_on_due_date: '',
      start_date: '',
      submitting_agency: [],
      title: '',
      tracker_type: '',
    },
    currentBill: '',
    displayDropdown: '',
    end_date_required: false,
    errorField: {},
    errorsPresent: '',
    file_error_message: '',
    file_limit_exceeded: '',
    initial_next_due: '',
    page: 0,
    refs: {},
    searchCommittee: '',
    searchContact: '',
    selectedReportDate: props.selectedDay
      ? props.selectedDay.toLocaleDateString()
      : '',
    uploadDeleted: false,
    uploadedMilestoneAttachment: [],
    uploadedReport: [],
  });

  const refs = useRef({});

  // create the reference for each of the field in the map
  useEffect(() => {
    registerRefs();
    let trackerId = '';
    if (props.params) {
      trackerId = props.params.id;
    }

    let opReqToUpdate = props.currentReport;
    if (trackerId && !props.currentReport.id) {
      opReqToUpdate = props.selectedDayDueReports[0];
    }
    setState(prevState => ({
      ...prevState,
      opReq: {
        ...opReqToUpdate,
        frequency_unit: opReqToUpdate['frequency_unit']
          ? capitalizeWords(opReqToUpdate['frequency_unit'].replace(/_/g, ' '))
          : '',
        tracker_type: capitalizeWords(
          opReqToUpdate['tracker_type'].replace(/_/g, ' ')
        ),
        ls_requests: opReqToUpdate['ls_requests']
          ? opReqToUpdate['ls_requests']
          : [],
      },
      initial_next_due: opReqToUpdate['next_due'],
      end_date_required: opReqToUpdate.end_date ? true : false,
    }));
    return () => {
      if (state.uploadDeleted) {
        window.location.assign('/opReqs');
      }
    };
  }, [
    props.params,
    props.currentReport,
    props.selectedDayDueReports,
    props.selectedDay,
    state.uploadDeleted,
  ]);

  // arr.reduce((accumulator, current)) to generate an object of refs
  const registerRefs = () => {
    refs.current = ReportFields.reduce((acc, current) => {
      const ref = React.createRef();
      acc[current.field] = ref;
      return acc;
    }, {});
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetCommittee = targetIdx => {
    const committees = state.opReq['committees'];
    let newCommittees = [];
    for (let i = 0; i < committees.length; i++) {
      if (i !== targetIdx) {
        newCommittees.push(committees[i]);
      }
    }
    setState(prevState => ({
      ...prevState,
      opReq: { ...prevState.opReq, committees: newCommittees },
    }));
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedCommittee = selectedCommittee => {
    setState(prevState => {
      const committees = [...prevState.opReq.committees];
      const hasDuplicate = committees.some(
        committee =>
          committee.committee_name === selectedCommittee.committee_name
      );

      if (!hasDuplicate) {
        committees.push(selectedCommittee);
      }

      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          committees: committees,
        },
      };
    });
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetContact = targetIdx => {
    setState(prevState => {
      const contacts = prevState.opReq['assigned_contact'];
      const newContacts = contacts.filter((_, index) => index !== targetIdx);
      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          assigned_contact: newContacts,
        },
      };
    });
  };

  // Deletes the attached report file from the database
  const onDeleteMilestoneAttachment = (e, id) => {
    props.deleteMilestoneAttachmentAction(id);
    setState(prevState => ({
      ...prevState,
      opReq: {
        ...prevState.opReq,
        milestone_attachment: prevState.opReq.milestone_attachment.filter(
          item => item.id !== id
        ),
      },
      // uploadDeleted: true,
    }));
    e.stopPropagation();
  };

  // Deletes the attached report file from the database
  const onDeleteAttachment = (e, id) => {
    props.deleteAttachmentAction(id);
    
    setState(prevState => {
      prevState.opReq.attachment.forEach(item => {
        if(item.id == id) {
          item.file = null;
        }
      })
      return {...prevState,
        opReq: {
          ...prevState.opReq,
          attachment: prevState.opReq.attachment,
        }
      }
      // uploadDeleted: true,
    });
    e.stopPropagation();
  };

  // Deleted unsubmitted files in report if its a report tracker attachment
  const onDeleteNewAttachment = (e, id, field) => {
    setState(prevState => {
      if (field === 'attachment') {
        const updatedUploadedReport = [...prevState.uploadedReport];
        updatedUploadedReport.splice(id, 1);
        return { ...prevState, uploadedReport: updatedUploadedReport };
      } else {
        const updatedUploadedMilestoneAttachment = [
          ...prevState.uploadedMilestoneAttachment,
        ];
        updatedUploadedMilestoneAttachment.splice(id, 1);
        return {
          ...prevState,
          uploadedMilestoneAttachment: updatedUploadedMilestoneAttachment,
        };
      }
    });
    e.stopPropagation();
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedContact = selectedContact => {
    setState(prevState => {
      const contacts = prevState.opReq['assigned_contact'];
      const hasDuplicate = contacts.some(
        contact => contact.full_name === selectedContact.full_name
      );

      if (!hasDuplicate) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            assigned_contact: [...contacts, selectedContact],
          },
        };
      }

      return prevState;
    });
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetAgency = targetIdx => {
    setState(prevState => {
      const agencies = [...prevState.opReq['submitting_agency']];
      agencies.splice(targetIdx, 1);
      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          submitting_agency: agencies,
        },
      };
    });
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedAgency = selectedAgency => {
    setState(prevState => {
      const agencies = prevState.opReq['submitting_agency'];
      const hasDuplicate = agencies.some(
        agency => agency.name === selectedAgency.name
      );
      if (!hasDuplicate) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            submitting_agency: [...agencies, selectedAgency],
          },
        };
      }
      return prevState;
    });
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedAgencyContact = selectedContact => {
    setState(prevState => {
      const contacts = prevState.opReq['agency_contact'];
      const hasDuplicate = contacts.some(
        contact => contact.full_name === selectedContact.full_name
      );

      if (!hasDuplicate) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            agency_contact: [...contacts, selectedContact],
          },
        };
      }
      return prevState;
    });
  };

  const onDeleteTargetAgencyContact = targetIdx => {
    setState(prevState => {
      const contacts = prevState.opReq['agency_contact'];
      const newContacts = contacts.filter((_, index) => index !== targetIdx);
      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          agency_contact: newContacts,
        },
      };
    });
  };

  // Add the selected Local Law
  const onAddSelectedLocalLaw = selectedLocalLaw => {
    setState(prevState => {
      const localLaws = prevState.opReq['local_law'];
      let hasDuplicate = false;
      for (let i = 0; i < localLaws.length; i++) {
        let check = false;
        if (localLaws[i]['link']) {
          check = selectedLocalLaw['law_number'].includes(localLaws[i]['link']);
        } else {
          check =
            localLaws[i]['law_number'].includes(
              selectedLocalLaw['law_number']
            ) ||
            selectedLocalLaw['law_number'].includes(localLaws[i]['law_number']);
        }
        if (check) {
          hasDuplicate = true;
          return prevState; // No need to update state if duplicate is found
        }
      }
      if (!hasDuplicate) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            local_law: [...localLaws, selectedLocalLaw],
          },
        };
      }
      return prevState; // Return the previous state if no update is needed
    });
    return true;
  };

  // Delete the selected Local Law
  const onDeleteTargetLocalLaw = targetIdx => {
    setState(prevState => {
      const localLaw = prevState.opReq['local_law'];
      const newLocalLaws = localLaw.filter((_, index) => index !== targetIdx);

      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          local_law: newLocalLaws,
        },
      };
    });
  };

  // Add the selected Local Law
  const onAddSelectedLegacyLocalLaw = selectedLocalLaw => {
    setState(prevState => {
      const legacyLocalLaws = prevState.opReq['legacy_local_law'];
      let hasDuplicate = false;
      for (let i = 0; i < legacyLocalLaws.length; i++) {
        let check = false;
        if (legacyLocalLaws[i]['law_number']) {
          check = legacyLocalLaws[i]['law_number'] === selectedLocalLaw['law_number']
        }
        if (check) {
          hasDuplicate = true;
          return prevState; // No need to update state if duplicate is found
        }
      }
      if (!hasDuplicate) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            legacy_local_law: [...legacyLocalLaws, selectedLocalLaw],
          },
        };
      }
      return prevState; // Return the previous state if no update is needed
    });
    return true;
  };

  // Delete the selected Local Law
  const onDeleteTargetLegacyLocalLaw = targetIdx => {
    setState(prevState => {
      const legacyLocalLaw = prevState.opReq['legacy_local_law'];
      const newLocalLaws = legacyLocalLaw.filter((_, index) => index !== targetIdx);

      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          legacy_local_law: newLocalLaws,
        },
      };
    });
  };

  // Add the selected LSR to report
  const onAddSelectedLSR = selectedLSR => {
    setState(prevState => {
      const lsrs = prevState.opReq['ls_requests'];
      if (!lsrs.includes(selectedLSR)) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            ls_requests: [...lsrs, selectedLSR],
          },
        };
      }
      return prevState;
    });
    return true;
  };

  // Delete the selected LSR to report
  const onDeleteTargetLSR = targetIdx => {
    setState(prevState => {
      const lsrs = prevState.opReq['ls_requests'];
      const newLSRs = lsrs.filter((_, index) => index !== targetIdx);
      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          ls_requests: newLSRs,
        },
      };
    });
  };

  const generateFields = pageReports => {
    // Creates a dictionary to separate the files by due_date
    let dueDateDict = {};
    state.opReq.attachment.forEach(report => {
      let repDueDate = new Date(report.due_date).toLocaleDateString();
      if (dueDateDict[repDueDate] && report.file) {
        if (report.file) {
          dueDateDict[repDueDate].push(report);
        } else {

        }
      } else {
        if (report.file) {
          dueDateDict[repDueDate] = [report];
        } else {
          dueDateDict[repDueDate] = [];
        }
      }
    });

    let getOrderedDates = Object.keys(dueDateDict).sort((a, b) => {
      a = new Date(a);
      b = new Date(b);
      return a > b ? -1 : a < b ? 1 : 0;
    });

    const nonAgencyUsers = props.userList.filter(
      user => user.role !== 'Agency'
    );
    const obj = {
      committees: {
        inputTitle: 'Committee*',
        param: 'committee_name',
        warningMessage: 'No committee has been selected',
        onAddTarget: onAddSelectedCommittee,
        onDeleteTarget: onDeleteTargetCommittee,
        dropdownList: props.committees,
      },
      assigned_contact: {
        inputTitle: 'Assigned Contact*',
        param: 'full_name',
        warningMessage: 'No contact has been selected',
        onAddTarget: onAddSelectedContact,
        onDeleteTarget: onDeleteTargetContact,
        dropdownList: nonAgencyUsers,
      },
      submitting_agency: {
        inputTitle: 'Submitting Agency*',
        param: 'name',
        warningMessage: 'No agency has been selected',
        onAddTarget: onAddSelectedAgency,
        onDeleteTarget: onDeleteTargetAgency,
        dropdownList: props.allAgencies,
      },
      agency_contact: {
        inputTitle: 'Agency Contact',
        param: 'full_name',
        warningMessage: 'No agency Contact has been selected',
        onAddTarget: onAddSelectedAgencyContact,
        onDeleteTarget: onDeleteTargetAgencyContact,
        dropdownList: props.allAgencyUsers,
      },
      local_law: {
        inputTitle: 'Local Laws',
        param: 'law_number',
        warningMessage: 'No Local Law has been selected',
        onAddTarget: onAddSelectedLocalLaw,
        onDeleteTarget: onDeleteTargetLocalLaw,
        dropdownList: [],
        help: '(e.g., https://legistar.council.nyc.gov/LegislationDetail.aspx?...)',
        noList: true,
      },
      legacy_local_law: {
        inputTitle: 'Legacy Local Laws (pre-1998)',
        param: 'law_number',
        warningMessage: 'No Legacy Local Law has been selected',
        onAddTarget: onAddSelectedLegacyLocalLaw,
        onDeleteTarget: onDeleteTargetLegacyLocalLaw,
        dropdownList: [],
        help: '(e.g., LL 72/1992)',
        noList: true,
      },
      ls_requests: {
        inputTitle: 'LS Requests',
        param: 'ls_number',
        warningMessage: 'No LSR has been selected',
        onAddTarget: onAddSelectedLSR,
        onDeleteTarget: onDeleteTargetLSR,
        dropdownList: [],
        help: '(e.g., 5667)',
        noList: true,
      },
    };
    const userGroups = props.userProfile.groups.map(group => group.name);

    const displayReport = pageReports.map((field, idx) => {
      if (field.type === 'file') {
        return (
          <div className='mb-3' key={idx}>
            <p className={styles['input-text-title']}>
              {field.title}
              {field.required && <span>*</span>}
            </p>
            <br />
            <span className={styles['file-size-text']}>
              File Size Limit 250MB
            </span>
            <br />
            {state.file_limit_exceeded === field.field && (
              <span className={styles['file-error']}>
                {state.file_error_message}
              </span>
            )}
            <div>
              {/* Dropdown for due dates created for attachment field */}
              {Array.isArray(state.opReq[field.field]) && (
                <>
                  {field.field === 'attachment' &&
                    state.opReq['tracker_type'] === 'Report' && (
                      <div>
                        <select
                          value={state.selectedReportDate}
                          className={styles['file-dropdown']}
                          onChange={e => {
                            setState(prevState => ({
                              ...prevState,
                              selectedReportDate: e.target.value,
                            }));
                          }}>
                          <option value=''>Select Due Date</option>
                          {getOrderedDates.map((date, index) => {
                            let empty =
                              dueDateDict[date].length > 0 ? '' : ' (empty)';
                            return (
                              <option value={date} key={index}>
                                {format(new Date(date), 'M/d/yyyy') + empty}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  {state.selectedReportDate &&
                    dueDateDict[state.selectedReportDate] &&
                    field.field === 'attachment' &&
                    dueDateDict[state.selectedReportDate].map((file, idx) => {
                      return (
                        <File
                          key={idx}
                          file={file}
                          id={`old-${field.field}-${idx}`}
                          date={file.timestamp}
                          getFileAndDownload={() => {}}
                          onDelete={onDeleteAttachment}
                          deletePermission={props.deletePermission}
                        />
                      );
                    })}
                  {field.field === 'milestone_attachment' &&
                    state.opReq['tracker_type'] === 'Milestone' &&
                    state.opReq['milestone_attachment'].map((file, idx) => {
                      return (
                        <File
                          key={idx}
                          file={file}
                          id={`old-${field.field}-${idx}`}
                          date={file.timestamp}
                          getFileAndDownload={() => {}}
                          onDelete={onDeleteMilestoneAttachment}
                          deletePermission={props.deleteMilestoneAttachmentPermission}
                        />
                      );
                    })}
                </>
              )}
              {field.field === 'milestone_attachment' &&
                state.uploadedMilestoneAttachment.length !== 0 &&
                state.uploadedMilestoneAttachment.map((report, idx) => {
                  return (
                    <File
                      key={idx}
                      file={report}
                      id={`new-${field.field}-${idx}`}
                      getFileAndDownload={() => {}}
                      onDelete={e => onDeleteNewAttachment(e, idx, field.field)}
                      deletePermission={props.deleteMilestoneAttachmentPermission}
                    />
                  );
                })}
              {field.field === 'attachment' &&
                state.uploadedReport.length !== 0 &&
                state.uploadedReport.map((report, idx) => {
                  return (
                    <File
                      key={idx}
                      file={report}
                      id={`new-${field.field}-${idx}`}
                      getFileAndDownload={() => {}}
                      onDelete={e => onDeleteNewAttachment(e, idx, field.field)}
                      deletePermission={props.deletePermission}
                    />
                  );
                })}
            </div>
            {/* Allow user to upload multiple reports to multiple due dates*/}
            {!(
              field.field === 'attachment' &&
              state.opReq[field.field].length &&
              state.selectedReportDate === ''
            ) && (
              <>
                <p className={styles['file-padding']}>Upload a new file</p>
                <input
                  type={field.type}
                  name={field.field}
                  accept={acceptedFileTypes}
                  multiple
                  disabled={
                    state.initial_next_due !== state.opReq['next_due'] &&
                    state.opReq.frequency_unit === 'As Needed'
                  }
                  onChange={e => {
                    let error_messages = [];
                    for (let i = 0; i < e.target.files.length; i++) {
                      // If the file size is more than 250MB do not input
                      if (e.target.files[i].size > 250000000) {
                        error_messages.push(
                          e.target.files[i].name + ' is too large'
                        );
                      }
                    }
                    if (error_messages.length) {
                      e.target.value = null;
                      setState(prevState => ({
                        ...prevState,
                        file_limit_exceeded: field.field,
                        file_error_message: error_messages.join(', '),
                      }));
                    } else {
                      let files = e.target.files;
                      if (field.field === 'attachment') {
                        setState(prevState => {
                          let newUploadedReport = [...prevState.uploadedReport];
                          for (let i = 0; i < files.length; i++) {
                            files[i].due_date = prevState.selectedReportDate;
                            files[i].file_name = files[i].name;
                            newUploadedReport.push(files[i]);
                          }
                          return {
                            ...prevState,
                            uploadedReport: newUploadedReport,
                          };
                        });
                      } else if (field.field === 'milestone_attachment') {
                        setState(prevState => {
                          let newUploadedMilestoneAttachment = [
                            ...prevState.uploadedMilestoneAttachment,
                          ];
                          for (let i = 0; i < files.length; i++) {
                            files[i].file_name = files[i].name;
                            newUploadedMilestoneAttachment.push(files[i]);
                          }
                          return {
                            ...prevState,
                            uploadedMilestoneAttachment:
                              newUploadedMilestoneAttachment,
                          };
                        });
                      }
                    }
                  }}
                />
              </>
            )}
          </div>
        );
      } else if (
        [
          'committees',
          'assigned_contact',
          'agency_contact',
          'submitting_agency',
          'local_law',
          'legacy_local_law',
          'ls_requests',
        ].includes(field.field)
      ) {
        let helperText = null;
        if (['local_law', 'ls_requests', 'legacy_local_law'].includes(field.field)) {
          helperText = (
            <span style={{ 'color': 'gray' }}>
              <sup>*</sup>At LEAST one <span style={{ textDecoration: 'underline' }}>local Law (Legistar link)</span>
              , <span style={{ textDecoration: 'underline' }}>legacy local law number</span>
              , or <span style={{ textDecoration: 'underline' }}>LSR number</span> must be added.
            </span>
          );
        }
        return (
          <div className='mb-3' key={idx}>
            <TagsDisplay
              allowNew={field.field === 'submitting_agency'}
              className={
                !state.errorField[field.field]
                  ? styles['input-text-area']
                  : styles['input-text-area-warning']
              }
              currentList={state.opReq[field.field]}
              dropdownList={obj[field.field].dropdownList}
              inputTitle={obj[field.field].inputTitle}
              localLawHelper={helperText}
              noList={obj[field.field].noList}
              onAddTarget={obj[field.field].onAddTarget}
              onDeleteTarget={obj[field.field].onDeleteTarget}
              param={obj[field.field].param}
              placeholder={obj[field.field].help}
              reference={React.createRef()}
              warningMessage={obj[field.field].warningMessage}
            />
            {state.errorField[field.field] && (
              <p className={styles['warning-message']}>
                {state.errorField[field.field]['error_message']}
              </p>
            )}
          </div>
        );
      } else if (field.type === 'checkbox') {
        return (
          !(
            field.field === 'completed' &&
            !userGroups.includes('Compliance Team')
          ) && (
            <div className='mb-3' key={idx}>
              <p className={styles['input-text-title']}>
                {field.title}
                {field.required && <span>*</span>}
              </p>
              <div className={styles['radio-buttons']}>
                <input
                  type='radio'
                  name={field.field}
                  id={`yes-${field.field}`}
                  checked={state.opReq[field.field]}
                  onChange={e => {
                    setState(prevState => ({
                      ...prevState,
                      opReq: {
                        ...prevState.opReq,
                        [field.field]: true,
                      },
                    }));
                  }}
                  className={styles['checkbox']}
                />
                <label htmlFor={`yes-${field.field}`}>Yes</label>
                <input
                  type='radio'
                  name={field.field}
                  id={`no-${field.field}`}
                  checked={!state.opReq[field.field]}
                  onChange={e => {
                    setState(prevState => ({
                      ...prevState,
                      opReq: {
                        ...prevState.opReq,
                        [field.field]: false,
                      },
                    }));
                  }}
                  className={styles['checkbox']}
                />
                <label htmlFor={`no-${field.field}`}>No</label>
              </div>
            </div>
          )
        );
      } else if (field.field === 'tracker_type') {
        let options = [...field.options];
        return (
          <div className='mb-3' key={idx}>
            <p className={styles['input-text-title']}>{field.title}</p>
            <select
              className={
                !state.errorField[field.field]
                  ? 'form-control'
                  : styles['input-text-area-warning']
              }
              value={capitalizeWords(
                props.currentReport[field.field].toString()
              )}
              onChange={e => {
                setState(prevState => ({
                  ...prevState,
                  opReq: { ...prevState.opReq, [field.field]: e.target.value },
                }));
              }}
              disabled={true}
              multiple={field.multiple}>
              <option value=''>Select {field.title}</option>
              {field.options &&
                options.map((op, idx1) => (
                  <option key={idx1} value={op}>
                    {op.toString()}
                  </option>
                ))}
            </select>
          </div>
        );
      } else if (field.type === 'select') {
        let disable = false;
        if (field.field === 'frequency_quantity') {
          disable = ['Once', 'As Needed'].includes(
            state.opReq['frequency_unit']
          );
        }
        let options = [...field.options];
        if (
          field.field === 'frequency_unit' &&
          props.currentReport['frequency_unit'] !== 'once'
        ) {
          options = [...field.options.filter(field => field !== 'Once')];
        }

        return (
          <div className='mb-3' key={idx}>
            <p className={styles['input-text-title']}>
              {field.title}
              {field.required && <span>*</span>}
            </p>
            {field.field === 'frequency_unit' && (
              <>
                <ToolTipIcon
                  classNameProp={styles['user-guide-field-icon']}
                  idProp='unit'
                />
                <UncontrolledPopover
                  innerClassName={styles['popover-container']}
                  className={styles['popover-outer-container']}
                  fade={false}
                  trigger='hover'
                  placement='bottom'
                  target='unit'>
                  <PopoverBody>
                    Reports will be requested by the system every Period unit.
                    <br></br>
                    <br></br>
                    The <b>Unit</b> specifies the time-unit used under the
                    <em>Period</em> field.
                    <em>
                      For example, a "Unit” of <b>Months</b>, and a “Period” of
                      <b>3</b> indicates that a report is due every 3 months.
                    </em>
                    The system accepts “Once” or “As needed” as unit options as
                    well.
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
            <select
              className={
                !state.errorField[field.field]
                  ? 'form-control'
                  : styles['input-text-area-warning']
              }
              value={
                ['frequency_unit'].includes(field.field)
                  ? disable
                    ? 0
                    : capitalizeWords(state.opReq[field.field].toString())
                  : state.opReq[field.field]
              }
              onChange={e => {
                setState(prevState => ({
                  ...prevState,
                  opReq: { ...prevState.opReq, [field.field]: e.target.value },
                }));
              }}
              disabled={disable}
              multiple={field.multiple}>
              <option value=''>Select {field.title}</option>
              {field.options &&
                options.map((op, idx1) => (
                  <option key={idx1} value={op}>
                    {op.toString()}
                  </option>
                ))}
            </select>
            {state.errorField[field.field] && (
              <p className={styles['warning-message']}>
                {state.errorField[field.field]['error_message']}
              </p>
            )}
          </div>
        );
      } else if(field.type === 'textarea') {
        return (
          <div className='mb-3' key={idx}>
            <p className={styles['input-text-title']}>{field.title}</p>
            <textarea
              className={`form-control field-size ${
                  !state.errorField[field.field]
                    ? styles['input-text-area']
                    : styles['input-text-area-warning']
                }`}
              rows='6'
              name={field.field}
              value={state.opReq[field.field] || ''}
              onChange={e => {
                setState(prevState => ({
                  ...prevState,
                  opReq: {
                    ...prevState.opReq,
                    [field.field]: e.target.value,
                  },
                }));
              }}
            />
          </div>
        )
      } else {
        let disable = false;
        if (field.field === 'end_date') {
          disable = state.opReq['frequency_unit'] === 'Once';
        } else if (field.field === 'frequency_quantity') {
          disable = ['Once', 'As Needed', ''].includes(
            state.opReq['frequency_unit']
          );
        }
        let check = !(
          ['end_date', 'next_due', 'frequency_quantity'].includes(
            field.field
          ) && state.opReq['frequency_unit'] === 'Once'
        );

        return (
          <div className='mb-3' key={idx}>
            {check && (
              <>
                {field.field === 'end_date' && (
                  <div className={styles['end_date_question']}>
                    <label htmlFor='end_date_req'>
                      <input
                        type='checkbox'
                        id='end_date_req'
                        checked={state.end_date_required}
                        onChange={() => {
                          setState(prevState => ({
                            ...prevState,
                            end_date_required: !prevState.end_date_required,
                            opReq: { ...prevState.opReq, ['end_date']: '' },
                          }));
                        }}
                      />
                      Does this Operational Requirement have an end date?
                    </label>
                  </div>
                )}
                {!(!state.end_date_required && field.field === 'end_date') && (
                  <>
                    <p className={styles['input-text-title']}>
                      {field.field === 'start_date' &&
                      state.opReq.tracker_type === 'Milestone'
                        ? 'Due Date'
                        : field.title}
                      {field.required && <span>*</span>}
                    </p>
                    {field.field === 'frequency_quantity' && (
                      <>
                        <ToolTipIcon
                          classNameProp={styles['user-guide-field-icon']}
                          idProp='period'
                        />
                        <UncontrolledPopover
                          innerClassName={styles['popover-container']}
                          className={styles['popover-outer-container']}
                          fade={false}
                          trigger='hover'
                          placement='bottom'
                          target='period'>
                          <PopoverBody>
                            Reports will be requested by the system every Period
                            unit.<br></br>
                            <br></br>
                            The <b>Unit</b> specifies the time-unit used under
                            the <em>Period</em> field.
                            <em>
                              For example, a "Unit” of <b>Months</b>, and a
                              “Period” of <b>3</b> indicates that a report is
                              due every 3 months.
                            </em>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </>
                    )}
                    <input
                      className={`form-control ${
                        !state.errorField[field.field]
                          ? styles['input-text-area']
                          : styles['input-text-area-warning']
                      }`}
                      type={field.type}
                      name={field.field}
                      value={state.opReq[field.field] || ''}
                      onChange={e => {
                        setState(prevState => ({
                          ...prevState,
                          opReq: {
                            ...prevState.opReq,
                            [field.field]: e.target.value,
                          },
                        }));
                      }}
                      disabled={
                        disable ||
                        (field.field === 'next_due' &&
                          state.uploadedMilestoneAttachment.length > 0 &&
                          state.opReq.frequency_unit === 'As Needed')
                      }
                    />
                    {state.errorField[field.field] && (
                      <p className={styles['warning-message']}>
                        {state.errorField[field.field]['error_message']}
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      }
    });

    return displayReport;
  };

  // Validate input fields
  const checkFieldValidation = fields => {
    let isValid = true;
    let errors = {};
    let isOnce = state.opReq['frequency_unit'] === 'Once';
    let isMilestone = state.opReq.tracker_type === 'Milestone';
    let start,
      next,
      end = false;

    if (!isOnce && !isMilestone) {
      start = state.opReq['start_date'] <= state.opReq['next_due'];
      next = start;
      end = state.opReq['end_date'] ? true : false;

      if (end) {
        if (start && state.opReq['end_date'] < state.opReq['start_date']) {
          start = false;
          end = false;
        }
        if (next && state.opReq['end_date'] < state.opReq['next_due']) {
          next = false;
          end = false;
        }
      }
    }

    //Date Error Validation start <= next <= end

    for (let i = 0; i < fields.length; i++) {
      const curt = fields[i];
      //If the current field is required and if it is empty or an empty array
      if (
        curt.required &&
        (state.opReq[curt.field] === 'None' ||
          (typeof state.opReq[curt.field] !== 'boolean' &&
            !state.opReq[curt.field]) ||
          (Array.isArray(state.opReq[curt.field]) &&
            state.opReq[curt.field].length < 1))
      ) {
        let nonPeriodUnits = ['Once', 'As Needed'].includes(
          state.opReq['frequency_unit']
        );
        let removedRequired =
          (isOnce && curt.field === 'next_due') ||
          (isMilestone &&
            ['frequency_unit', 'frequency_quantity'].includes(curt.field));
        if (
          !(curt.field === 'frequency_quantity' && nonPeriodUnits) &&
          !removedRequired
        ) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message': 'Required fields (*) cannot be empty!',
          };
          isValid = false;
        }
      }
      if (!isOnce && !isMilestone) {
        if (curt.field === 'start_date' && !start) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'The start date is later than the next due and/or the end date.',
          };
          isValid = false;
        } else if (curt.field === 'next_due' && !next) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'The next due date is earlier than the start date and/or later than the end date.',
          };
          isValid = false;
        } else if (
          curt.field === 'end_date' &&
          state.opReq[curt.field] &&
          !end
        ) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'The end date is earlier than the start date and/or later than the next due date.',
          };
          isValid = false;
        }
      }
      if (['local_law', 'ls_requests', 'legacy_local_law'].includes(curt.field)) {
        if (
          state.opReq.local_law.length === 0 &&
          state.opReq.ls_requests.length === 0 &&
          state.opReq.legacy_local_law.length === 0
        ) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'At least one local law (Legistar link), legacy local law number or LSR number must be submitted.',
          };
          isValid = false;
        }
        state.opReq['local_law'].forEach(ll => {
          if (!ll['link']) {
            if (
              /^(http|https)\:\/\/legistar\.council\.nyc\.gov\/LegislationDetail\.aspx\?/im.test(
                ll['law_number']
              )
            ) {
              let legistar_link = new URL(ll['law_number']);
              if (
                !legistar_link.searchParams.get('ID') ||
                !legistar_link.searchParams.get('GUID')
              ) {
                isValid = false;
              }
            } else {
              errors['local_law'] = {
                'is_invalid': true,
                'error_message': 'Invalid legistar legistar link input.',
              };
              isValid = false;
            }
          }
        });
      }
    }
    setState(prevState => ({
      ...prevState,
      errorField: errors,
      errorsPresent: !isValid,
    }));
    return isValid;
  };

  const onUpdate = e => {
    e.preventDefault();
    let ReportFieldsFiltered = [];
    if (ReportFields) {
      ReportFieldsFiltered = ReportFields.filter(field => {
        return !['last_received', 'completed'].includes(field.field);
      });
    }
    let isValid = checkFieldValidation(ReportFieldsFiltered);
    if (isValid) {
      let validOpReq = state.opReq;

      let due = encodeURIComponent(state.selectedReportDate);
      if (validOpReq['tracker_type'] === 'Report') {
        validOpReq['attachment'] = state.uploadedReport;
        props.updatingReportDeadline(validOpReq, due);
      }
      if (validOpReq['tracker_type'] === 'Milestone') {
        validOpReq['milestone_attachment'] = state.uploadedMilestoneAttachment;
        props.updatingReportDeadline(validOpReq);
      }
    }
  };

  // Display all the fields within a report
  let ReportFieldsFiltered = [];
  const isCMStaff =
    props.userProfile.role.includes('Council Member') ||
    props.userProfile.under_council_member;
  if (ReportFields) {
    ReportFieldsFiltered = ReportFields.filter(field => {
      return (
        (field.editable &&
          !(field.field === 'errors' && isCMStaff) &&
          (field.tracker === 'Both' ||
            field.tracker.toLowerCase() ===
              props.currentReport.tracker_type)) ||
        field.field === 'tracker_type'
      );
    });
  }
  let halfOfFields = ReportFieldsFiltered.length / 2;
  let firstHalfOfFields = ReportFieldsFiltered.slice(0, halfOfFields);
  let secondHalfOfFields = ReportFieldsFiltered.slice(halfOfFields);

  return (
    <>
      <div className={styles['modal-header']}>
        <div>
          <span className={styles['back-button']}>
            {state.page !== 0 && (
              <div
                onClick={() => {
                  setState(prevState => ({
                    ...prevState,
                    page: prevState.page - 1,
                  }));
                }}>
                <ShortLeftArrowIcon width='24' height='24' />
                <span>Back</span>
              </div>
            )}
          </span>
          <span className='popup-detail-title'>
            Update Operational Requirement
          </span>
          <span className={styles['next-button']}></span>
        </div>
      </div>
      <form className={styles['modal-body']}>
        {props.reportUpdateStatus === 'fail' && (
          <div
            className={`alert alert-danger ${styles['alert-danger-banner']}`}
            role='alert'>
            <ExclamationPointDiamondIcon />
            <p>Failed to update this report tracker</p>
          </div>
        )}
        {state.errorsPresent && (
          <div
            className={`alert alert-danger ${styles['alert-danger-banner']}`}
            role='alert'>
            <ExclamationPointDiamondIcon />
            <div>
              <p>There was an error with your form submission.</p>
              <p>Please correct the highlighted field(s) below</p>
            </div>
          </div>
        )}
        {props.isLoading === true && (
          <img
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            className='loading-image'
          />
        )}
        {props.reportUpdateStatus !== 'success' && (
          <>
            <div className={styles['report-fields']}>
              <div className={styles['report-field-column']}>
                {generateFields(firstHalfOfFields)}
              </div>
              <div className={styles['report-field-column']}>
                {generateFields(secondHalfOfFields)}
              </div>
            </div>
            <div className={styles['modal-footer']}>
              <button
                className={`submit-button ${styles['cancel-button']}`}
                onClick={props.onBackToReport}
                type='button'>
                Cancel
              </button>
              <button
                className={`submit-button ${styles['footer-button']}`}
                onClick={onUpdate}
                type='button'>
                Update
              </button>
            </div>
          </>
        )}

        {props.reportUpdateStatus === 'success' && (
          <div
            className={`alert alert-success ${styles['alert-danger-banner']} ${styles['success']}`}
            role='alert'>
            <LargeCheckMarkIcon />
            <div>
              <p>The report has been successfully updated!</p>
              <p>Page will refresh shortly.</p>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

const mapStateToProps = state => {
  return {
    allAgencies: state.reportTrackingReducer.allAgencies,
    allAgencyUsers: state.reportTrackingReducer.allAgencyUsers,
    committees: state.userReducer.committees.filter(
      com => com.committee_name !== 'Unassigned'
    ),
    deletePermission:
      state.userReducer.userProfile.permissions.reportTracking
        .delete_attachment,
    deleteMilestoneAttachmentPermission:
      state.userReducer.userProfile.permissions.reportTracking
        .delete_milestoneattachment,
    isLoading: state.reportTrackingReducer.isLoading,
    reportUpdateStatus: state.reportTrackingReducer.reportUpdateStatus,
    selectedDayDueReports: state.reportTrackingReducer.selectedDayDueReports,
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(UpdateDueReport);
