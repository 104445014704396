import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import version from '../../package.json';
import MyActiveLs from '../components/user/dashboard/MyActiveLs';
import ReportsWidget from '../components/user/dashboard/ReportsWidget';
import ReviewList from '../components/user/dashboard/ReviewList';
import styles from '../styles/user/dashboard/MyTasks.module.scss';
import { rolesUnderCouncilMembers } from '../services/constants';
import { getAllEvents } from '../actions/eventAction';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animationCompleted: false,
    };
  }

  applyHeight = () => {
    this.setState({ animationCompleted: true });
  };
  componentDidMount() {
    this.heightTimeout = setTimeout(this.applyHeight, 10.8 * 1000);
    this.props.getAllEvents();
  }
  componentWillUnmount() {
    clearTimeout(this.heightTimeout);
  }

  toCardinal = num => {
    if (num < 11 || num > 20) {
      switch (num % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }
    return 'th';
  };

  render() {
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let today = new Date();
    let y = today.getFullYear();
    let d = today.getDate();
    let dayOfTheWeek = days[today.getDay()];
    let month = months[today.getMonth()];
    let { userProfile } = this.props;
    let greeting;

    if (userProfile.role === 'Speaker/Council Member') {
      greeting = `Hello Speaker ${userProfile.last_name}!`;
    } else if (userProfile.role === 'Council Member') {
      greeting = `Hello Council Member ${userProfile.last_name}!`;
    } else {
      greeting = `Hello ${userProfile.full_name}!`;
    }
    const iwommAnniversary = today.getMonth() === 4 && today.getDate() === 9;
    let anniversaryYear = today.getFullYear() - 2022;
    const reduceHeightStyle = this.state.animationCompleted
      ? { minHeight: '270px', transition: 'min-height 0.3s ease-in-out' }
      : {};
    const hideAnnimation = this.state.animationCompleted
      ? { display: 'none' }
      : {};

    const parseDate = dateString => {
      const [year, month, day] = dateString
        .split('T')[0]
        .split('-')
        .map(Number);
      // Note: Month is 0-indexed in JavaScript Date, so subtract 1
      return new Date(year, month - 1, day);
    };

    const formatDateRange = (startDateString, endDateString) => {
      const start = parseDate(startDateString);
      const startFormat = { month: 'long', day: 'numeric' };

      if (!endDateString || startDateString === endDateString) {
        return start.toLocaleDateString('en-US', startFormat);
      }

      const end = parseDate(endDateString);
      const endFormat = { ...startFormat };

      if (
        start.toLocaleDateString('en-US', startFormat) ===
        end.toLocaleDateString('en-US', endFormat)
      ) {
        return `${start.toLocaleDateString('en-US', startFormat)}`;
      }

      if (start.getMonth() === end.getMonth()) {
        endFormat.month = undefined;
      }

      return `${start.toLocaleDateString(
        'en-US',
        startFormat
      )} - ${end.toLocaleDateString('en-US', endFormat)}`;
    };

    const formatDateDiff = (startDateString, endDateString) => {
      const now = new Date();
      const start = parseDate(startDateString);
      const end = endDateString ? parseDate(endDateString) : start;

      // If the current date is within the event range

      if (now >= start && now <= end) {
        return 'Event is currently ongoing';
      }

      const diffTime = start.getTime() - now.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
        return 'Today';
      } else if (diffDays === 1) {
        return 'Tomorrow';
      } else if (diffDays < 0) {
        return `${Math.abs(diffDays)} days ago`;
      } else {
        return `In ${diffDays} days`;
      }
    };

    return (
      <div className='main-container'>
        <div className='container p-3 pt-5'>
          {/* {iwommAnniversary && (
            <section className='row mb-3'>
              <div className='col pt-5'>
                <h1 className={`text-center ${styles['hb-heading']}`}>
                  Happy {anniversaryYear}
                  <sup>{this.toCardinal(anniversaryYear)}</sup> Anniversary to
                  IWOMM!
                </h1>
              </div>
            </section>
          )} */}
          <div className='row'>
            <div className='col'>
              <h2 className='card-title fs-3'>{greeting}</h2>
              <h3 className='card-text fs-5'>
                Today is {dayOfTheWeek}&nbsp;
                <strong>
                  {month} {d}
                  <sup>{this.toCardinal(d)}</sup>, {y}
                </strong>
              </h3>
            </div>
          </div>
          <section className='row mt-4'>
            <div className='col'>
              <div
                style={reduceHeightStyle}
                className={`alert border-0 alert-dismissible fade show ${
                  styles['dashboard-welcome-container']
                } ${iwommAnniversary && styles['anniversary']}`}
                role='alert'>
                {iwommAnniversary && (
                  <lottie-player
                    style={hideAnnimation}
                    id={styles['hb-banner']}
                    preserveAspectRatio='xMidYMid slice'
                    src='https://assets8.lottiefiles.com/packages/lf20_8urn0qvp.json'
                    background='transparent'
                    speed='.8'
                    autoplay></lottie-player>
                )}
                <div className='row'>
                  <div className='col-12 pe-0'>
                    <h2 className='fs-5 mb-3 text-white'>Upcoming Events</h2>

                    <div className={`list-group gap-3 ${styles['list-group']}`}>
                      {this.props.events.length > 0 ? (
                        this.props.events.map((event, index) => (
                          <div
                            key={index}
                            className={`list-group-item border-0 list-group-item-action text-white d-flex gap-4 p-3 ${styles['event-list-item-wrapper']}`}
                            aria-current='true'>
                            <div
                              className={`card text-center ${styles['date-card-container']}`}>
                              <div
                                className={`card-head pt-3 ${styles['date-card-head']}`}>
                                <span>
                                  {formatDateRange(
                                    event['date_start'],
                                    event['date_end']
                                  )}
                                </span>
                              </div>
                              <div className='card-body fw-bold fs-5 pt-0'>
                                <span>
                                  {new Date(
                                    event['date_start']
                                  ).toLocaleTimeString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                  })}
                                </span>
                                <span className='fs-6'>{event.meridiem}</span>
                              </div>
                            </div>
                            <div className='d-flex w-100 justify-content-between align-items-center'>
                              <div className='d-inline-block w-75'>
                                <h5 className='mb-2 fs-5 fw-bold'>
                                  {event.title}
                                </h5>
                                <h4 className='fs-6'>{event.description}</h4>
                                <h4 className='fs-6 fst-italic'>
                                  {event.location}
                                </h4>
                              </div>
                              <small className='d-inline-block w-25 text-end'>
                                {formatDateDiff(
                                  event.date_start,
                                  event.date_end
                                )}
                              </small>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className='fs-6 mb-3 pt-5 text-white'>
                          There are no upcoming events at this time.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  type='button'
                  className='btn-close btn-close-white'
                  data-bs-dismiss='alert'
                  aria-label='Close'></button>
              </div>
            </div>
          </section>
          {this.props.userProfile.permissions.lsRequest_api.view_lsrequest && (
            <section className='row mt-4'>
              <div className='col-12 mb-5'>
                <MyActiveLs />
              </div>
            </section>
          )}
          <section className='row'>
            {this.props.userProfile.permissions.wiki.view_pagereview &&
              !rolesUnderCouncilMembers.includes(
                this.props.userProfile.role
              ) && (
                <div className='col-xs-12 col-md-6 mb-5'>
                  <ReviewList />
                </div>
              )}
            {this.props.userProfile.permissions.reportTracking.view_report &&
              !rolesUnderCouncilMembers.includes(
                this.props.userProfile.role
              ) && (
                <div className='col-12 mb-5'>
                  <div className='row'>
                    <h6>Operational Requirements</h6>
                  </div>
                  <div className='row'>
                    <div className='col-xs-12 col-sm-6'>
                      <ReportsWidget reportTypes='past_due' />
                    </div>
                    <div className='col-xs-12 col-sm-6'>
                      <ReportsWidget reportTypes='upcoming' />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <ReportsWidget reportTypes='recent' />
                    </div>
                  </div>
                </div>
              )}
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    trainingVideos: state.userReducer.trainingLinks,
    events: state.eventReducer.events,
  };
};

const mapDispatchToProps = {
  getAllEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
