import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import AllMyLs from '../components/user/myTasks/AllMyLs';
import MyActiveLs from '../components/user/myTasks/MyActiveLs';
import ReportsTable from '../components/report-tracking/ReportsTable';
import ReviewList from '../components/user/myTasks/ReviewList';
import ReviewPage from '../components/user/myTasks/ReviewPage';
import { PrivateRoute } from '../utils/router-helper';
import LsHistory from '../components/ls-requests/LsHistory';

const MyTasks = ({ userProfile }) => {
  return (
    <div>
      <Routes>
        <Route
          path='allMyLs/:ls_num?'
          element={
            <PrivateRoute
              condition={userProfile.permissions.lsRequest_api.view_lsrequest}>
              <AllMyLs />
            </PrivateRoute>
          }
        />
        <Route
          path='allMyLs/:ls_num/history'
          element={
            <PrivateRoute
              condition={userProfile.permissions.lsRequest_api.view_lsrequest}>
              <LsHistory />
            </PrivateRoute>
          }
        />
        <Route
          path='/myActiveLs/:ls_num?'
          element={
            <PrivateRoute
              condition={userProfile.permissions.lsRequest_api.view_lsrequest}>
              <MyActiveLs />
            </PrivateRoute>
          }
        />
        <Route
          path='myActiveLs/:ls_num/history'
          element={
            <PrivateRoute
              condition={userProfile.permissions.lsRequest_api.view_lsrequest}>
              <LsHistory />
            </PrivateRoute>
          }
        />
        <Route
          path='myOpReqs'
          element={
            <PrivateRoute
              condition={userProfile.permissions.reportTracking.view_report}>
              <ReportsTable />
            </PrivateRoute>
          }
        />
        <Route
          path='myCLEXReviews'
          element={
            <PrivateRoute
              condition={userProfile.permissions.wiki.view_pagereview}>
              <ReviewList />
            </PrivateRoute>
          }
        />
        <Route
          path='myCLEXReviews/:id'
          element={
            <PrivateRoute
              condition={userProfile.permissions.wiki.view_pagereview}>
              <ReviewPage />
            </PrivateRoute>
          }
        />
        {/* <Route path='*' element={<Navigate to='myOpReqs' replace />} /> */}
        {/* <Navigate to='allMyLs' /> */}
      </Routes>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(MyTasks);
