import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import styles from '../styles/citation-request/CitationTable.module.scss';
import Calendar from '../components/citation-request/Calendar';
import CitationTable from '../components/citation-request/CitationTable';
import {
  getCitationRequestCurrentMonthAndYear,
  changeCitationRequestDisplay,
  changeCitationRequestPopupWindow,
  getCitationRequests,
} from '../actions/citationRequestAction';
import { generateSafeDateFromMonthYear } from '../utils/helper';

const CitationRequestContainer = props => {
  // const [displayMode, setDisplayMode] = useState("calendar")
  const [isCalendarFullScreen, setIsCalendarFullScreen] = useState(true);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth) // screen width not used yet, will be later
  const [rowContainerHeight, setRowContainerHeight] = useState('40vh');

  // const toggleCalendarFullScreen = () => {
  //   setIsCalendarFullScreen(!isCalendarFullScreen)
  // }
  const resizeHandler = () => {
    // const { innerWidth } = window;
    // setScreenWidth(innerWidth)
    // if (innerWidth > 854) {
    //   if (isCalendarFullScreen) {
    //     setIsCalendarFullScreen(false)
    //     setRowContainerHeight('')
    //   }
    // } else {
    //   if (!isCalendarFullScreen) {
    //     setIsCalendarFullScreen(true)
    //     setRowContainerHeight('40vh')
    //   }
    // }
  };

  useEffect(() => {
    let currentDate;
    if (localStorage.getItem('citationRequestSelectedMonth') !== null) {
      currentDate = generateSafeDateFromMonthYear(
        localStorage.getItem('citationRequestSelectedMonth')
      );
    } else {
      currentDate = new Date();
    }

    props.getCitationRequestCurrentMonthAndYear(
      currentDate.getMonth() + 1,
      currentDate.getFullYear()
    );
    props.getCitationRequests(props.nextAPI, props.params);
    const trackerId = queryString.parse(window.location.search).id;
    if (trackerId) {
      props.openNotificationDetailReport(trackerId);
      props.changeReportTrackingDisplay('detail');
    }
    window.addEventListener('resize', resizeHandler);
    resizeHandler(); // call it initially to set the proper state

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  // const toCalendarView = () => {
  //   setDisplayMode('calendar')
  // }

  // const showCitationTable = !isCalendarFullScreen || screenWidth > 854;
  return (
    <div className='main-container'>
      <div
        style={{
          display: 'flex',
          flexDirection: window.innerWidth > 896 ? 'row' : 'column',
        }}>
        <div className='col flex-grow-1 p-0'>
          <Calendar
            // toggleCalendarFullScreen={toggleCalendarFullScreen}
            isCalendarFullScreen={isCalendarFullScreen}
            rowContainerHeight={rowContainerHeight}
          />
        </div>
        <div
          className={`col flex-grow-0 p-0 ${styles['citation-list-container']}`}>
          <CitationTable
            changeCitationRequestDisplay={props.changeCitationRequestDisplay}
            changeCitationRequestPopupWindow={
              props.changeCitationRequestPopupWindow
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    nextAPI: state.citationRequestReducer.nextAPI,
    params: {
      sort_direction: state.citationRequestReducer.citationRequestSortDirection,
      sort_by: state.citationRequestReducer.citationRequestSortedBy,
      filter: state.citationRequestReducer.citationRequestFilter,
    },
  };
};
const mapDispatchToProps = {
  getCitationRequestCurrentMonthAndYear,
  changeCitationRequestDisplay,
  changeCitationRequestPopupWindow,
  getCitationRequests,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CitationRequestContainer);
