import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const DuplicatesTooltip = props => {
  const [duplicateToolTipOpen, setDuplicateToolTipOpen] = useState(false);

  const toggleToolTipOpen = () => {
    setDuplicateToolTipOpen(!duplicateToolTipOpen);
  };

  return (
    <span>
      <div
        id={
          'ls-' +
          props.field.replace(' ', '-').toLowerCase() +
          '-' +
          props.index
        }>
        {props.dupes.length} {props.field.toUpperCase()}
      </div>
      <Tooltip
        placement='bottom'
        isOpen={duplicateToolTipOpen}
        target={
          'ls-' +
          props.field.replace(' ', '-').toLowerCase() +
          '-' +
          props.index
        }
        toggle={toggleToolTipOpen}>
        {props.dupes
          .sort((a, b) => {
            return parseInt(a) - parseInt(b);
          })
          .join(', ')}
      </Tooltip>
    </span>
  );
};

export default DuplicatesTooltip;
