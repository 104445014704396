import React, { useEffect } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import SideBar from '../components/wiki/SideBar';
import SubjectPage from '../components/wiki/SubjectPage';

import WikiPage from '../components/wiki/WikiPage';
import PageHistory from '../components/wiki/PageHistory';
import TagPage from '../components/wiki/TagPage';
import WikiSearchResults from '../components/wiki/WikiSearchResults';
import WikiFormContainer from '../components/wiki/WikiFormContainer';
import PrinterFriendly from '../components/wiki/PrinterFriendlyPage';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import styles from '../styles/wiki/Wiki.module.scss';
import * as actions from '../actions/wikiPageAction';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import WikiHome from '../components/wiki/WikiHome';

const Wiki = ({
  loadRootSubjects,
  loadSubjects,
  loadSidebarSubjects,
  rootSubjects,
  parents,
  tableOfContent,
  subject,
  subjectId,
  page,
  userProfile,
}) => {
  useEffect(() => {
    // Load root subjects on construction of the wiki
    loadRootSubjects('nav');
  }, [loadRootSubjects]);

  // Load subject when the breadcrumb is clicked
  // If the number of parents is more than one, the children of the grandparent should be displayed
  const onSubjectClick = subject => {
    if (subject.parents && subject.parents.length > 1) {
      loadSubjects(subject.parents[subject.parents.length - 2], 'breadcrumb');
    } else {
      loadSidebarSubjects(rootSubjects);
    }
  };
  const location = useLocation();
  const inIdPage = () => {
    let url = location.pathname;
    if (url.includes('page') || url.includes('subject')) {
      if (url.lastIndexOf('/') === url.length - 1) {
        url = url.slice(0, -1);
      }
      let index = url.lastIndexOf('/') + 1;
      let dir = url.slice(index);
      return !isNaN(dir);
    }
    return false;
  };

  // Initialize crumbs as an empty string and create breadcrumbs according to the parents
  // If the length is greater than 6, only the root and the last 5 subject parents will be shown
  let crumbs = '';
  let path = location.pathname.split('/');

  if (parents) {
    let links = [{ title: 'Home' }];
    if (parents.length > 6) {
      links.push(parents[0]);
      links.push({ title: '...' });
      links = links.concat(parents.slice(parents.length - 5));
    } else {
      links = [...links, ...parents];
    }
    crumbs = links.map((parent, idx) => {
      return (
        <BreadcrumbItem key={idx} className={styles['breadcrumb-subject']}>
          {parent.title !== '...' ? (
            <Link to={parent.id ? '/CLEX/subject/' + parent.id : '/CLEX/'}>
              {parent.title}
            </Link>
          ) : (
            '...'
          )}
        </BreadcrumbItem>
      );
    });
  }

  let parentSubjects;
  if (
    location.pathname.includes('subject') &&
    !location.pathname.includes('page')
  ) {
    parentSubjects = subject;
  } else if (
    location.pathname.includes('subject') &&
    location.pathname.includes('page')
  ) {
    parentSubjects = <Link to={'/CLEX/subject/' + subjectId}>{subject}</Link>;
  } else if (
    !location.pathname.includes('subject') &&
    location.pathname.includes('page')
  ) {
    parentSubjects = null;
  }

  const sideAndHeader = () => {
    let regex = new RegExp(
      path.filter(pathPart => pathPart !== '').join('|'),
      'i'
    );
    if (
      (regex.test('page') || regex.test('search') || regex.test('subject')) &&
      !regex.test('print')
    ) {
      return (
        <>
          {!/(?:\/history|\/edit|\/create|\/delete)/.test(
            location.pathname
          ) && (
            <SideBar
              table={tableOfContent}
              pid={
                new RegExp(/page/, 'i').test(location.pathname)
                  ? parseInt(location.pathname.split('/page/')[1])
                  : null
              }
            />
          )}
          <div className={styles['breadcrumb']}>
            {inIdPage() && (
              <Breadcrumb>
                {crumbs}
                {parentSubjects && (
                  <BreadcrumbItem>{parentSubjects}</BreadcrumbItem>
                )}
                {location.pathname.includes('page') && page && (
                  <BreadcrumbItem className={styles['breadcrumb-page']}>
                    {Parser(page)}
                  </BreadcrumbItem>
                )}
              </Breadcrumb>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className='main-container'>
      {location.pathname !== '/CLEX' && sideAndHeader()}
      <Routes>
        <Route path='/' element={<WikiHome />} />
        <Route path='/page/create' element={<WikiFormContainer />} />
        <Route path='/page/edit' element={<WikiFormContainer />} />
        <Route path='/page/delete' element={<WikiFormContainer />} />
        <Route path='/tag/create' element={<WikiFormContainer />} />
        <Route path='/tag/edit' element={<WikiFormContainer />} />
        <Route path='/tag/delete' element={<WikiFormContainer />} />
        <Route path='/subject/create' element={<WikiFormContainer />} />
        <Route path='/subject/edit' element={<WikiFormContainer />} />
        <Route path='/subject/delete' element={<WikiFormContainer />} />
        <Route path='/page/:pid' element={<WikiPage />} />
        <Route path='/subject/:sid/page/:pid' element={<WikiPage />} />
        <Route
          path='/subject/:sid/page/:pid/history'
          element={<PageHistory />}
        />
        <Route
          path='/subject/:sid/page/:pid/print'
          element={<PrinterFriendly />}
        />
        <Route path='/subject/:id' element={<SubjectPage />} />
        <Route path='/tag/:id' element={<TagPage />} />
        <Route
          path='/search/:keywords/:filters'
          element={<WikiSearchResults />}
        />
      </Routes>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    tableOfContent: state.wikiPageReducer.tableOfContent,
    subjects: state.wikiReducer.subjects,
    rootSubjects: state.wikiReducer.rootSubjects,
    subject: state.wikiSubjectReducer.title,
    subjectId: state.wikiSubjectReducer.subject_id,
    page: state.wikiPageReducer.title,
    pageId: state.wikiPageReducer.page_id,
    parents: state.wikiReducer.parents,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(Wiki);
