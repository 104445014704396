import { eventActions } from '../actions/eventAction';

const initialState = {
  events: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case eventActions.SAVE_ALL_EVENTS:
      return { ...state, events: action.events };
    default:
      return state;
  }
}
