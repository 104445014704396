//Action type constants
export const reportTrackingActions = {
  CHANGE_REPORT_TRACKING_POPUP_WINDOW: 'CHANGE_REPORT_TRACKING_POPUP_WINDOW',
  GET_REPORTS_DUE_CURRENT_MONTH_AND_YEAR:
    'GET_REPORTS_DUE_CURRENT_MONTH_AND_YEAR',
  SAVE_REPORTS_DUE_CURRENT_MONTH_AND_YEAR:
    'SAVE_REPORTS_DUE_CURRENT_MONTH_AND_YEAR',
  SELECT_TARGET_DAY_FOR_REPORTS: 'SELECT_TARGET_DAY_FOR_REPORTS',
  SAVE_TARGET_DUE_REPORTS: 'SAVE_TARGET_DUE_REPORTS',
  CREATING_NEW_REPORT_DEADLINE: 'CREATING_NEW_REPORT_DEADLINE',
  CHECKING_REQUEST_IS_LOADING: 'CHECKING_REQUEST_IS_LOADING',
  CREATING_NEW_REPORT_DEADLINE_STATUS: 'CREATING_NEW_REPORT_DEADLINE_STATUS',
  UPDATING_REPORT_DEADLINE: 'UPDATING_REPORT_DEADLINE',
  IS_LOADING_STATUS: 'IS_LOADING_STATUS',
  LOAD_MORE_REPORTS: 'LOAD_MORE_REPORTS',
  ADD_MORE_REPORTS: 'ADD_MORE_REPORTS',
  GET_REPORT_UPDATE_STATUS: 'GET_REPORT_UPDATE_STATUS',
  GET_ALL_REPORTS: 'GET_ALL_REPORTS',
  SAVE_ALL_REPORTS: 'SAVE_ALL_REPORTS',
  SEARCH_REPORTS: 'SEARCH_REPORTS',
  GET_ALL_AGENCIES: 'GET_ALL_AGENCIES',
  SAVE_ALL_AGENCIES: 'SAVE_ALL_AGENCIES',
  SAVE_ALL_AGENCY_USERS: 'SAVE_ALL_AGENCY_USERS',
  OPEN_NOTIFICATION_DETAIL_REPORT: 'OPEN_NOTIFICATION_DETAIL_REPORT',
  CHANGE_DISPLAY: 'CHANGE_DISPLAY',
  SAVE_PROGRESS: 'SAVE PROGRESS',
  SAVE_NEXT_API: 'SAVE_NEXT_API',
  SUBMIT_AGENCY_REPORT: 'SUBMIT_AGENCY_REPORT',
  REQUEST_NEW_REPORT: 'REQUEST_NEW_REPORT',
  SET_MESSAGE: 'SET_MESSAGE',
  DELETE_ATTACHMENT: 'DELETE_ATTACHMENT',
  DELETE_MILESTONE_ATTACHMENT: 'DELETE_MILESTONE_ATTACHMENT',
  DELETE_UPLOAD: 'DELETE_UPLOAD'
};

// Action for clicking on target day for operational requirement" on that day
export const selectTargetDayForReports = reports => {
  return { type: reportTrackingActions.SELECT_TARGET_DAY_FOR_REPORTS, reports };
};
export const setMessage = string => {
  return { type: reportTrackingActions.SET_MESSAGE, message: string };
};
export const saveProgress = report => {
  return { type: reportTrackingActions.SAVE_PROGRESS, report };
};
export const requestNewReport = data => {
  return { type: reportTrackingActions.REQUEST_NEW_REPORT, data };
};
export const saveNextAPI = url => {
  return { type: reportTrackingActions.SAVE_NEXT_API, url };
};
// Action for saving due reports on selected day
export const saveTargetDueReports = reports => {
  return { type: reportTrackingActions.SAVE_TARGET_DUE_REPORTS, reports };
};
export const submitAgencyReport = (reportID, attachment, dueDate) => {
  return {
    type: reportTrackingActions.SUBMIT_AGENCY_REPORT,
    reportID,
    attachment,
    dueDate,
  };
};
// Action for changing the detail pop up window status to open/close
export const changeReportTrackingPopupWindow = status => {
  return {
    type: reportTrackingActions.CHANGE_REPORT_TRACKING_POPUP_WINDOW,
    status,
  };
};
export const changeReportTrackingDisplay = displayString => {
  return { type: reportTrackingActions.CHANGE_DISPLAY, displayString };
};
export const loadMoreReports = url => {
  return { type: reportTrackingActions.LOAD_MORE_REPORTS, url };
};
export const addMoreReports = reports => {
  return { type: reportTrackingActions.ADD_MORE_REPORTS, reports };
};
// Action for getting the reports due current month and year
export const getReportsDueCurrentMonthAndYear = (month, year) => {
  return {
    type: reportTrackingActions.GET_REPORTS_DUE_CURRENT_MONTH_AND_YEAR,
    month,
    year,
  };
};

// Action for saving the reports due current month and year
export const saveReportsDueCurrentMonthAndYear = reports => {
  return {
    type: reportTrackingActions.SAVE_REPORTS_DUE_CURRENT_MONTH_AND_YEAR,
    reports,
  };
};
// Action for creating the a new report deadline
export const creatingNewReportDeadline = report => {
  return { type: reportTrackingActions.CREATING_NEW_REPORT_DEADLINE, report };
};

// Action for checking is the creating has succeed or not
export const creatingNewReportDeadlineStatus = status => {
  return {
    type: reportTrackingActions.CREATING_NEW_REPORT_DEADLINE_STATUS,
    status,
  };
};

// Action for sending out the updating request
export const updatingReportDeadline = (report, dueDate) => {
  return {
    type: reportTrackingActions.UPDATING_REPORT_DEADLINE,
    report,
    dueDate,
  };
};

// Action for isLoading status checking
export const isLoadingStatus = status => {
  return { type: reportTrackingActions.IS_LOADING_STATUS, status };
};

// Action for checking the final status of the report update
export const getReportUpdateStatus = status => {
  return { type: reportTrackingActions.GET_REPORT_UPDATE_STATUS, status };
};

// Action for getting all the reports
export const getAllReports = tasks => {
  return { type: reportTrackingActions.GET_ALL_REPORTS, tasks };
};

// Action for saving all the reports
export const saveAllReports = reports => {
  return { type: reportTrackingActions.SAVE_ALL_REPORTS, reports };
};
// Action for searching based on committee or keyword
export const searchReports = (
  sortBy,
  searchCommittee,
  searchTitle,
  searchDivision,
  searchAgency,
  searchLaw,
  searchAll,
  filter,
  tasks
) => {
  return {
    type: reportTrackingActions.SEARCH_REPORTS,
    sortBy,
    searchCommittee,
    searchTitle,
    searchDivision,
    searchAgency,
    searchLaw,
    searchAll,
    filter,
    tasks,
  };
};

// Action for getting all the agencies
export const getAllAgencies = () => {
  return { type: reportTrackingActions.GET_ALL_AGENCIES };
};

// Action for saving all the agencies
export const saveAllAgencies = agencies => {
  return { type: reportTrackingActions.SAVE_ALL_AGENCIES, agencies };
};

export const saveAllAgencyUsers = agencyUsers => {
  return { type: reportTrackingActions.SAVE_ALL_AGENCY_USERS, agencyUsers };
};

// Action for opening notification detail
export const openNotificationDetailReport = reportID => {
  return {
    type: reportTrackingActions.OPEN_NOTIFICATION_DETAIL_REPORT,
    reportID,
  };
};

//Action to delete individual report file attached in the Report Tracker
export const deleteAttachmentAction = id => {
  return { type: reportTrackingActions.DELETE_ATTACHMENT, id };
};

//Action to delete individual milestone report file attached in the Report Tracker
export const deleteMilestoneAttachmentAction = id => {
  return { type: reportTrackingActions.DELETE_MILESTONE_ATTACHMENT, id };
};
