export const eventActions = {
  GET_ALL_EVENTS: 'GET_ALL_EVENTS',
  SAVE_ALL_EVENTS: 'SAVE_ALL_EVENTS',
  CREATE_EVENT: 'CREATE_EVENT',
};

export const getAllEvents = () => {
  return {
    type: eventActions.GET_ALL_EVENTS,
  };
};
export const saveAllEvents = events => {
  return {
    type: eventActions.SAVE_ALL_EVENTS,
    events,
  };
};

export const createEvent = formData => {
  return {
    type: eventActions.CREATE_EVENT,
    formData,
  };
};
