import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/wiki/TagForm.module.scss';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import * as actions from '../../actions/wikiPageAction';

const TagForm = props => {
  const [title, setTitle] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const {
    addTag,
    deleteTag,
    fail,
    loadAllTags,
    selectTag,
    success,
    tag_id,
    tagFormFail,
    tagFormSuccess,
    tags,
    tagUpdate,
  } = props;

  /**
   * useEffect hook for component mount and unmount
   */
  useEffect(() => {
    loadAllTags();

    return () => {
      tagFormFail('');
      tagFormSuccess('');
    };
  }, [loadAllTags, tagFormFail, tagFormSuccess]);

  function disableStatus(type) {
    if (type === 'create') {
      return !title;
    } else {
      return !title || !tag_id;
    }
  }

  const path = window.location.pathname;
  const type = path.slice(path.lastIndexOf('/') + 1);
  const allTags = tags.map((tag, idx) => (
    <option key={idx} value={tag.id}>
      {tag.name}
    </option>
  ));

  return (
    <div className={styles['page']}>
      <h2>{type[0].toUpperCase() + type.substr(1)} Tag</h2>
      {fail && <Alert color='danger'>{fail}</Alert>}
      {success && <Alert color='success'>{success}</Alert>}
      {type !== 'create' && (
        <>
          <strong>Tag:</strong>
          <Input
            type='select'
            value={tag_id}
            onChange={e => {
              selectTag(e.target.value);
            }}>
            <option value=''>Select Tag</option>
            {allTags}
          </Input>
          {type === 'delete' && (
            <Button
              disabled={!tag_id}
              className={styles['select-button']}
              onClick={e => {
                e.preventDefault();
                setDeleteModal(true);
              }}>
              Delete Tag
              <Modal
                isOpen={deleteModal}
                backdrop='static'
                toggle={e => {
                  e.preventDefault();
                  setDeleteModal(true);
                }}>
                <ModalHeader>Delete Tag</ModalHeader>
                <ModalBody>Are you sure you want to delete this tag?</ModalBody>
                <ModalFooter>
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      deleteTag(tag_id);
                      setDeleteModal(false);
                    }}>
                    Delete
                  </Button>
                  <Button
                    onClick={e => {
                      setDeleteModal(false);
                    }}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Button>
          )}
        </>
      )}
      {type !== 'delete' && (
        <>
          <strong>Title:</strong>
          <Input
            type='text'
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <Button
            disabled={disableStatus(type)}
            className={styles['select-button']}
            onClick={e => {
              e.preventDefault();
              if (type === 'create') {
                addTag(title);
              } else {
                tagUpdate({
                  id: tag_id,
                  tag_name: title,
                });
              }
            }}>
            {type[0].toUpperCase() + type.substr(1)} Tag
          </Button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    fail: state.tagFormReducer.fail,
    success: state.tagFormReducer.success,
    tag_id: state.tagFormReducer.tag_id,
    tags: state.tagFormReducer.tags,
  };
};

export default connect(mapStateToProps, actions)(TagForm);
