import React from 'react';
import { Alert } from 'reactstrap';
import * as actions from '../../actions/wikiPageAction';
import { connect } from 'react-redux';

const ErrorPage = props => {
  let message = '';

  function getErrorMessage() {
    if (props.subject_error && props.type === 'subject') {
      message = 'Topic not found.';
    } else if (
      props.subject_error &&
      !props.page_error &&
      props.type === 'page'
    ) {
      message = 'Topic of page not found.';
    } else if (props.page_error && props.type === 'page') {
      message = 'Article not found.';
    } else if (props.type === 'page' && props.idError) {
      message = 'Article not found in topic.';
    } else {
      message = 'Something went wrong';
    }
    return message;
  }

  return (
    <>
      <br />
      <h5>
        <Alert color='danger'>Error: {getErrorMessage()}</Alert>
      </h5>
    </>
  );
};

const mapStateToProps = state => {
  return {
    subject_error: state.wikiSubjectReducer.subject_error,
    page_error: state.wikiPageReducer.page_error,
  };
};

export default connect(mapStateToProps, actions)(ErrorPage);
