import React, { useState, useEffect } from 'react';
import styles from '../../styles/citation-request/Calendar.module.scss';
import {
  isWithinInterval,
  setYear,
  addDays,
  setMonth,
  startOfDay,
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay,
  toDate,
  addMonths,
  subMonths,
  parse,
} from 'date-fns';
import { connect } from 'react-redux';
import * as actions from '../../actions/citationRequestAction';
import CitationRequestServicePopup from './CitationRequestServicePopup';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import {
  CalendarIcon,
  ShortLeftArrowIcon,
  ShortRightArrowIcon,
  CloseButtonNormalIcon,
  FunnelFilterIcon,
  InfoCircleFilledIcon,
  CalendarStackIcon,
} from '../../services/SvgLibrary';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { generateSafeDateFromMonthYear } from '../../utils/helper';

ChartJS.register(ArcElement, Tooltip, Legend);

const Calendar = props => {
  const [currentMonth, setCurrentMonth] = useState(
    localStorage.getItem('citationRequestSelectedMonth') !== null
      ? localStorage.getItem('citationRequestSelectedMonth')
      : format(new Date(), 'MMM yyyy')
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [changeMonthYear, setChangeMonthYear] = useState(false);
  const [inputMonth, setInputMonth] = useState(1);
  const [inputYear, setInputYear] = useState(null);

  // States pertaining to statuses
  const [pending, setPending] = useState(true);
  const [approved, setApproved] = useState(true);
  const [inProgress, setInProgress] = useState(true);
  const [completed, setCompleted] = useState(true);
  const [delivered, setDelivered] = useState(true);
  const [withdrawn, setWithdrawn] = useState(true);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [years, setYears] = useState([]);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    let yearsArr = [];
    const today = new Date();
    let todayYear = today.getFullYear() + 5;
    for (let i = 2023; i <= todayYear; i++) {
      yearsArr.push(i);
    }

    const validTime = generateSafeDateFromMonthYear(currentMonth);
    setYears(yearsArr);
    setInputMonth(format(new Date(validTime), 'M') - 1);
    setInputYear(format(new Date(validTime), 'yyyy'));

    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    });
  }, []);

  // Send out new HTTP request when the end user edit target month and year
  const onSelectTime = e => {
    e.preventDefault();
    let newDate = parse(
      `${Number(inputMonth) + 1} ${inputYear}`,
      'M yyyy',
      new Date()
    );
    setCurrentMonth(format(newDate, 'MMM yyyy'));
    setChangeMonthYear(false);

    localStorage.setItem(
      'citationRequestSelectedMonth',
      format(newDate, 'MMM yyyy')
    );
    let month = parseInt(inputMonth) + 1;
    if (month > 12) {
      month = 1;
    }
    props.getCitationRequestCurrentMonthAndYear(
      Number(inputMonth) + 1,
      inputYear
    );
  };
  // format(date, format)
  const renderHeader = () => {
    const formattedDate = format(
      new Date(generateSafeDateFromMonthYear(currentMonth)),
      'MMMM yyyy'
    ); // FYI, I tried changing the formatting from 'MMM' to 'MMMM' on lines 41, 107, 112, but it did not work
    const dateArray = formattedDate.split(' ');
    const month = dateArray[0];
    const year = dateArray[1];

    let toggle = true;
    const toggleCalendarFullScreen = () => {
      document.getElementsByClassName(styles['row-container'])[0].style.height =
        toggle ? '40vh' : '';
      if (
        document.getElementsByClassName(styles['citation-num-container'])
          .length > 0
      ) {
        for (
          let i = 0;
          i <
          document.getElementsByClassName(styles['citation-num-container'])
            .length;
          i++
        ) {
          document.getElementsByClassName(styles['citation-num-container'])[
            i
          ].style.display = toggle ? 'none' : '';
        }
      }
      toggle = !toggle;
    };

    return (
      <div className={styles['calendarHeader']}>
        {!changeMonthYear && (
          <div className='d-flex align-items-center'>
            <button
              outline='true'
              className={`btn ${styles['calendar-view-toggle']}`}
              onClick={() => updateCalendar()}>
              Today
            </button>
            <ShortLeftArrowIcon
              classNameProp={styles['arrow-month-selection']}
              onClickFunction={() => updateCalendar('previous')}
            />
            <ShortRightArrowIcon
              classNameProp={styles['arrow-month-selection']}
              onClickFunction={() => updateCalendar('next')}
            />
            <div
              className={`ms-1 d-flex align-items-center ${styles['current-month-container']}`}
              onClick={() => setChangeMonthYear(true)}>
              <span className={styles['current-month-wrapper']}>{month}</span>
              &nbsp;
              <span className={styles['current-year-wrapper']}>{year}</span>
            </div>
          </div>
        )}
        {changeMonthYear && (
          <div className={styles['change-monthYear-container']}>
            <select
              className={styles['select-box']}
              value={inputMonth}
              onChange={e => setInputMonth(e.target.value)}>
              {months.map((m, idx) => (
                <option value={idx} key={idx}>
                  {m}
                </option>
              ))}
            </select>
            <select
              className={styles['select-box']}
              value={inputYear}
              onChange={e => setInputYear(e.target.value)}>
              {years.map((y, idx) => (
                <option value={y} key={idx}>
                  {y}
                </option>
              ))}
            </select>
            <button className={styles['submit']} onClick={onSelectTime}>
              Go
            </button>
            <button
              className={`btn ${styles['close-monthYear-input-fields']}`}
              onClick={() => setChangeMonthYear(false)}>
              <CloseButtonNormalIcon />
            </button>
          </div>
        )}
        <div style={{ 'marginLeft': 'auto', 'display': 'flex' }}>
          {/* {
            screenWidth < 1071 &&
            <button
            type='button' className={`btn ${styles['calendar-stack-btn']}`} data-bs-toggle='button'
            onClick={() => { props.toggleCalendarFullScreen(); toggleCalendarFullScreen(); }}
            title='Display/Hide list'
            >
              <CalendarStackIcon />
            </button>
          } */}

          <button
            className={`btn dropdown-center ${styles['dropdown-filter-toggle']}`}
            type='button'
            data-bs-toggle='dropdown'
            aria-expanded='false'
            data-bs-auto-close='outside'
            title='Status Filter'>
            <FunnelFilterIcon />
          </button>
          <ul className={`dropdown-menu ${styles['filter-dropdown-menu']}`}>
            <li>
              <h6 className='dropdown-header'>Select stage</h6>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['pending']}`}>
                Pending
                <input
                  type='checkbox'
                  checked={pending}
                  onChange={e => setPending(!pending)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['pending']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='pending'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['approved']}`}>
                Approved
                <input
                  type='checkbox'
                  checked={approved}
                  onChange={e => setApproved(!approved)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['approved']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='approved'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['in-progress']}`}>
                In Progress
                <input
                  type='checkbox'
                  checked={inProgress}
                  onChange={e => setInProgress(!inProgress)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['in-progress']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='in-progress'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['completed']}`}>
                Completed
                <input
                  type='checkbox'
                  checked={completed}
                  onChange={e => setCompleted(!completed)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='completed'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['delivered']}`}>
                Delivered
                <input
                  type='checkbox'
                  checked={delivered}
                  onChange={e => setDelivered(!delivered)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['delivered']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='delivered'
                />
              </label>
            </li>
            <li>
              <label
                className={`${styles['color-filter-container']} ${styles['withdrawn']}`}>
                Withdrawn
                <input
                  type='checkbox'
                  checked={withdrawn}
                  onChange={e => setWithdrawn(!withdrawn)}
                />
                <span
                  className={`${styles['color-filter-checkmark']} ${styles['withdrawn']}`}></span>
                <InfoCircleFilledIcon
                  classNameProp={styles['filter-popover-user-guide']}
                  idProp='withdrawn'
                />
              </label>
            </li>
          </ul>
          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='pending'>
            <PopoverBody>
              <div>
                <b>Pending:</b> Citation request is under review for approval.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='approved'>
            <PopoverBody>
              <div>
                <b>Approved:</b> All required information has been received and
                the honoree has cleared vetting.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='in-progress'>
            <PopoverBody>
              <div>
                <b>In Progress:</b> Drafting of Citation Request in progress.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='completed'>
            <PopoverBody>
              <div>
                <b>Completed:</b> The Citation Request is completed.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='delivered'>
            <PopoverBody>
              <div>
                <b>Delivered:</b> The Citation Request has been delivered.
              </div>
            </PopoverBody>
          </UncontrolledPopover>

          <UncontrolledPopover
            fade={false}
            trigger='hover'
            placement='bottom'
            target='withdrawn'>
            <PopoverBody>
              <div>
                <b>Withdrawn:</b> Citation Request withdrawn.
              </div>
            </PopoverBody>
          </UncontrolledPopover>
          <button
            outline='true'
            className={`btn ${styles['create-report-button']}`}
            onClick={() => {
              props.changeCitationRequestDisplay('create');
              props.changeCitationRequestPopupWindow(true);
            }}>
            + New
          </button>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(parse(currentMonth, 'MMM yyyy', new Date()));
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={styles['daysCol']} key={i}>
          {format(addDays(startDate, i), 'eee')}
        </div>
      );
    }
    return <div className={styles['daysRows']}>{days}</div>;
  };

  const renderCitationRequestsForTheDay = (citationRequestsForTheDay, day) => {
    const currentDate = parse(currentMonth, 'MMM yyyy', new Date());
    currentDate.setDate(day);
    const pendingPR = pending
      ? citationRequestsForTheDay.filter(citationRequest => {
          return (
            citationRequest.status.toLowerCase() === 'pending' ||
            citationRequest.status === 'pending'
          );
        })
      : [];
    const approvedPR = approved
      ? citationRequestsForTheDay.filter(citationRequest => {
          return (
            citationRequest.status.toLowerCase() === 'approved' ||
            citationRequest.status === 'approved'
          );
        })
      : [];
    const inProgressPR = inProgress
      ? citationRequestsForTheDay.filter(citationRequest => {
          return (
            citationRequest.status.toLowerCase() === 'in-progress' ||
            citationRequest.status === 'in-progress'
          );
        })
      : [];
    const completedPR = completed
      ? citationRequestsForTheDay.filter(citationRequest => {
          return (
            citationRequest.status.toLowerCase() === 'completed' ||
            citationRequest.status === 'completed'
          );
        })
      : [];
    const deliveredPR = delivered
      ? citationRequestsForTheDay.filter(citationRequest => {
          return (
            citationRequest.status.toLowerCase() === 'delivered' ||
            citationRequest.status === 'delivered'
          );
        })
      : [];
    const withdrawnPR = withdrawn
      ? citationRequestsForTheDay.filter(citationRequest => {
          return (
            citationRequest.status.toLowerCase() === 'withdrawn' ||
            citationRequest.status === 'withdrawn'
          );
        })
      : [];

    let totalCitationRequests = 0;
    if (pending) {
      totalCitationRequests += pendingPR.length;
    }
    if (approved) {
      totalCitationRequests += approvedPR.length;
    }
    if (inProgress) {
      totalCitationRequests += inProgressPR.length;
    }
    if (completed) {
      totalCitationRequests += completedPR.length;
    }
    if (delivered) {
      totalCitationRequests += deliveredPR.length;
    }
    if (withdrawn) {
      totalCitationRequests += withdrawnPR.length;
    }

    // For Doughnut chart
    const data = {
      labels: [
        'Pending',
        'Approved',
        'In Progress',
        'Completed',
        'Delivered',
        'Withdrawn',
      ],
      datasets: [
        {
          label: 'Citation Requests',
          data: [
            pendingPR.length,
            approvedPR.length,
            inProgressPR.length,
            completedPR.length,
            deliveredPR.length,
            withdrawnPR.length,
          ],
          backgroundColor: [
            '#5a70ac',
            '#009688',
            '#ff9800',
            '#636d77',
            '#2196f3',
            '#f44336',
          ],
          borderColor: [
            '#5a70ac',
            '#009688',
            '#ff9800',
            '#636d77',
            '#2196f3',
            '#f44336',
          ],
          borderWidth: 0,
          outerRadius: '100%',
          innerRadius: '99%',
          cornerRadius: '7%',
          padAngle: '10',
        },
      ],
    };
    const options = {
      cutout: 45,
      plugins: {
        legend: false,
      },
      responsive: false,
      maintainAspectRatio: false,
    };

    return (
      <div
        className={styles['citation-request-calendar-day-container']}
        style={{ padding: '3px' }}>
        {screenWidth > 460 && screenHeight > 570 && (
          <div className={styles['citation-num-container']}>
            <p
              className={styles['total-citation-requests-num']}
              style={{ marginBottom: '0' }}>
              {totalCitationRequests}
            </p>
            <p
              style={{ fontSize: '0.8rem', marginBottom: '0' }}
              className={styles['']}>
              {totalCitationRequests === 1 ? 'Request' : 'Requests'}
            </p>
          </div>
        )}
        <Doughnut
          width={110}
          height={110}
          type='doughnut'
          data={data}
          options={options}
        />
      </div>
    );
  };

  const renderCells = () => {
    const monthStart = startOfMonth(
      parse(currentMonth, 'MMM yyyy', new Date())
    );
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';
    const { citationRequests } = props;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const numOfCitationRequests = citationRequests[formattedDate]
          ? citationRequests[formattedDate].length
          : 0;
        const upcomingReports = isWithinInterval(cloneDay, {
          start: startOfDay(new Date()),
          end: addDays(new Date(), 7),
        });
        days.push(
          <div
            style={numOfCitationRequests > 0 ? { cursor: 'pointer' } : {}}
            className={`${
              !isSameMonth(day, monthStart)
                ? styles['colCellDisabled']
                : isSameDay(day, selectedDate)
                ? styles['colCellSelected']
                : styles['colCell']
            }`}
            key={day}
            onClick={() => onDateClick(toDate(cloneDay))}>
            <span className={styles['cellNumber']}>{formattedDate}</span>
            <span className={styles['bg']}>{formattedDate}</span>
            {day >= monthStart &&
              day <= monthEnd &&
              numOfCitationRequests > 0 &&
              renderCitationRequestsForTheDay(
                citationRequests[formattedDate],
                upcomingReports,
                formattedDate
              )}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={styles['temp-row']} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className={styles['row-container']}>{rows}</div>;
  };

  const onDateClick = day => {
    setSelectedDate(day);
    const idx = format(day, 'd');
    const shownStatuses = {
      pending: pending,
      approved: approved,
      'in-progress': inProgress,
      completed: completed,
      delivered: delivered,
      withdrawn: withdrawn,
    };
    const filteredCitationRequests = props.citationRequests[idx].filter(
      ele =>
        shownStatuses[ele.status] || shownStatuses[ele.status.toLowerCase()]
    );
    if (filteredCitationRequests.length > 0) {
      const currentDate = parse(currentMonth, 'MMM yyyy', new Date());
      currentDate.setDate(idx);
      props.selectTargetDayForCitationRequests(filteredCitationRequests);
    }
    if (filteredCitationRequests.length === 1) {
      props.selectCitationRequest(filteredCitationRequests[0]);
      props.changeCitationRequestDisplay('detail');
    }
    if (filteredCitationRequests.length > 1) {
      props.changeCitationRequestDisplay('list');
    }
  };

  const onCitationRequestFilterClick = (filteredCitationRequest, e) => {
    props.selectTargetDayForCitationRequests(filteredCitationRequest);
    e.stopPropagation();
  };

  const updateCalendar = (action = 'today') => {
    let newCurrentMonth;
    switch (action) {
      case 'previous':
        newCurrentMonth = subMonths(
          startOfMonth(parse(currentMonth, 'MMM yyyy', new Date())),
          1
        );
        break;
      case 'next':
        newCurrentMonth = addMonths(
          startOfMonth(parse(currentMonth, 'MMM yyyy', new Date())),
          1
        );
        break;
      default:
        newCurrentMonth = new Date();
        break;
    }
    props.getCitationRequestCurrentMonthAndYear(
      newCurrentMonth.getMonth() + 1,
      newCurrentMonth.getFullYear()
    );
    const formattedNext = format(newCurrentMonth, 'MMM yyyy');
    localStorage.setItem('citationRequestSelectedMonth', formattedNext);
    setCurrentMonth(formattedNext);
    setInputMonth(newCurrentMonth.getMonth());
    setInputYear(newCurrentMonth.getFullYear());
  };

  // const toggle = () => {
  //   setDropdownOpen(!prevState.dropdownOpen)
  // };

  const { isLoading } = props;
  const shownStatuses = {
    pending: pending,
    approved: approved,
    'in-progress': inProgress,
    completed: completed,
    delivered: delivered,
    withdrawn: withdrawn,
  };

  return (
    <>
      {isLoading === false && (
        <div className={styles['citation-request-filters']}>
          {renderHeader()}
          {renderDays()}
          <div>{renderCells()}</div>
        </div>
      )}
      <CitationRequestServicePopup
        selectedDay={selectedDate}
        shownStatuses={shownStatuses}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    citationRequests: state.citationRequestReducer.allCitationRequestsForDays,
    isLoading: state.citationRequestReducer.isCalendarLoading,
    userList: state.userReducer.userList,
  };
};

export default connect(mapStateToProps, actions)(Calendar);
