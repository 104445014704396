import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openNotificationDetailReport } from '../../../actions/reportTrackingAction';
import { loadMoreReports } from '../../../actions/userAction';
import styles from '../../../styles/user/UserProfile.module.scss';

const NotificationsHistory = props => {
  const {
    dueReportsNotification,
    loading,
    history,
    loadMoreReports,
    openNotificationDetailReport,
  } = props;
  const navigate = useNavigate();
  /** component mount */
  useEffect(() => {
    function onScroll(e) {
      const scrollPosition =
        document.documentElement.scrollHeight -
        document.documentElement.scrollTop;
      if (scrollPosition < 6000 && dueReportsNotification.next && !loading) {
        loadMoreReports(dueReportsNotification.next);
      }
    }
    window.addEventListener('scroll', onScroll, false);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [dueReportsNotification.next, loadMoreReports, loading]);

  const notificationsDisplay = dueReportsNotification.results.map(
    (report, idx) => (
      <div key={idx}>
        <div
          className={styles['notification-history-item']}
          onClick={() => {
            openNotificationDetailReport(report.id);
            navigate('/opReqs');
          }}>
          <div className={styles['notification-index']}>
            <span>{idx + 1}</span>
          </div>
          <div className={styles['notification-content']}>
            <p className={styles['notification-type']}>
              Operation Requirements
            </p>
            <p
              className={
                styles['notification-title']
              }>{`Title: ${report.title}`}</p>
            <p
              className={
                styles['notification-title']
              }>{`Due Date: ${report.next_due}`}</p>
          </div>
          <p className={styles['notification-key']}>Past Due</p>
        </div>
        <hr className={styles['notification-separator']} />
      </div>
    )
  );

  return (
    <div className={styles['user-profile-container']}>
      <h1 className={styles['reports-past-due-header']}>Reports Past Due</h1>
      <div className={styles['user-profile-notifications']}>
        {notificationsDisplay}
      </div>
      {loading && (
        <div className='loading-container'>
          <img
            className='loading-image'
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dueReportsNotification: state.userReducer.dueReportsNotification,
    loading: state.userReducer.loadingReports,
  };
};

const mapDispatchToProps = {
  loadMoreReports,
  openNotificationDetailReport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsHistory);