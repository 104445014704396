import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/proclamation-request/ProcTile.module.scss';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { connect } from 'react-redux';

const ProcRequestTile = props => {
  const { procRequest, onTileClick } = props;
  let tileClass;
  if (procRequest.status) {
    switch (procRequest.status.toLowerCase()) {
      case 'approved':
        tileClass = styles['approved'];
        break;
      case 'delivered':
        tileClass = styles['delivered'];
        break;
      case 'pending':
        tileClass = styles['pending'];
        break;
      case 'in-progress':
        tileClass = styles['progress'];
        break;
      case 'completed':
        tileClass = styles['completed'];
        break;
      case 'withdrawn':
        tileClass = styles['withdrawn'];
        break;
      default:
        tileClass = '';
        break;
    }
  }

  return (
    <div
      onClick={() => {
        onTileClick(procRequest);
      }}
      className={styles['proclamation-requests-list-box']}>
      <div className={styles['proclamation-requests-list-idx']}>
        <div className={`${styles['report-sidelabel-container']} ${tileClass}`}>
          <p className={`${styles['report-sidelabel-text']}`}>
            {procRequest.status}
          </p>
        </div>
      </div>
      <div className={styles['proclamation-requests-list-content']}>
        <p className={styles['proclamation-requests-list-title']}>
          {procRequest.honoree}
        </p>
        <p className={styles['proclamation-requests-list-locallaw']}>
          {procRequest.reason}
        </p>
        {rolesUnderCouncilMembers.includes(props.userProfile.role) ? (
          <p className={styles['proclamation-requests-list-subtitle']}>
            Council Member: {procRequest.council_member.full_name}
          </p>
        ) : (
          <p className={styles['proclamation-requests-list-subtitle']}>
            Requested by: {procRequest.requested_by.full_name}
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
  };
};

ProcRequestTile.propTypes = {
  procRequest: PropTypes.shape({
    honoree: PropTypes.string.isRequired,
    reason: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.number,
  onTileClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ProcRequestTile);
